import React, { useEffect, useMemo } from 'react';

import { activityLogsPageSize } from 'app-constants/activityLogs';
import { Spinner } from 'components';
import { AuditLogCard, Collapse, Option, Panel } from 'components-antd';
import { MultiSelect } from 'components-antd/MultiSelect';
import { Minus, Plus } from 'components/Icons';
import { cloneDeep, keys } from 'lodash-es';
import { constructPropertyAddress } from 'pages/Clarity/Components/Activity/components/ActivityFiltersDrawer';
import { useWorkshopActivityLogSection } from 'pages/Clarity/Components/Activity/components/WorkshopActivityLogSection/useWorkshopActivityLogSection';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from 'settings/constants/roles';
import {
  requestGetActivityLogsEffect,
  resetActivityLogsItemsListEffect,
} from 'store/effects/activityLogsWorkshop';
import {
  setActivityFiltersDrawerPropertiesFieldEffect,
  setActivityFiltersDrawerStartDateFieldEffect,
  setActivityFiltersDrawerUsersFieldEffect,
} from 'store/effects/drawers/activityFilters';
import { getRelatedEntitiesCompleteListState } from 'store/selectors/relatedEntities';
import { formatLogsData, getFormattedFilterObject } from 'utils/activityLogsHelper';
import { RadarHeader } from '../components/RadarHeader';
import styles from './styles.module.scss';
import {
  resetActivityFiltersDrawerFieldsAction,
  setActivityFiltersDrawerActivityTypesAction,
} from 'store/actions/drawers/activityFilters';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { ActivityLogsType } from 'types/activityLogs';
import { requestGetTransactionsEffect } from 'store/effects';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import classNames from 'classnames';
import { getAggregatePageTransactionsSelector } from 'store/selectors/transactions';

export const RadarLogs: React.FC = () => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const isProperties = pathname === routes.propertiesActivity;
  const dispatch = useDispatch();
  const { activities, getDayAndDateTitleStr, categories, isPending, loadingRef, fieldsPayload } =
    useWorkshopActivityLogSection({
      activitiesType: isProperties ? ActivityLogsType.Pinpoint : ActivityLogsType.Radar,
    });
  const { transactions } = useSelector(getAggregatePageTransactionsSelector);
  const relatedTransactions = transactions?.map((p) => ({
    Id: p.Id,
    Address: p.Property.Address,
  }));

  const relatedEntities = useSelector(getRelatedEntitiesCompleteListState);
  useEffect(() => {
    dispatch(requestGetTransactionsEffect());
    return () => {
      dispatch(resetActivityFiltersDrawerFieldsAction());
    };
  }, []);

  const renderActivities = () => {
    const formatted: any = [];
    activities?.items?.length > 0 &&
      activities?.items?.forEach((document) => {
        if (document.ActionTimestamp) {
          const date = getDayAndDateTitleStr(document.ActionTimestamp);
          const index = formatted.findIndex((f) => f[date] !== undefined);
          index === -1
            ? formatted.push({ [date]: [document] })
            : formatted[index][date].push(document);
        }
      });
    const taskedGroupedData = formatLogsData(activities?.items, {
      taskGroup: true,
      categories: categories,
    });

    if (taskedGroupedData.length) {
      return (
        <>
          {taskedGroupedData.map((item, idx) => (
            <AuditLogCard
              key={idx}
              activityLogs={item[`${keys(item)}`]}
              DayAndDate={keys(item)[0]}
              activityLogCardClassName={styles.clarityActivityLogCard}
            />
          ))}
        </>
      );
    }

    if (!isPending && !formatted.length) return <p className={styles.noDataFound}>No data found</p>;
  };

  const onDateChangeHandler = (_from, _to) => {
    const startDate = _to && !_from ? _to : _from;
    const endDate = _to;

    dispatch(
      setActivityFiltersDrawerStartDateFieldEffect({
        data: {
          ...(startDate ? { startDate } : { startDate: undefined }),
          ...(endDate ? { endDate } : { endDate: undefined }),
        },
      }),
    );
    onDone({
      ...fieldsPayload,
      ...(startDate ? { startDate } : { startDate: undefined }),
      ...(endDate ? { endDate } : { endDate: undefined }),
    });
  };

  const onActivityTypeHandler = (value: string) => {
    let activityTypes = cloneDeep(fieldsPayload?.activityTypes);
    const index = activityTypes.findIndex((v) => v === value);
    if (index !== -1) {
      activityTypes.splice(index, 1);
    } else {
      activityTypes = [...activityTypes, value];
    }
    dispatch(
      setActivityFiltersDrawerActivityTypesAction({
        data: {
          activityTypes,
        },
      }),
    );
    onDone({
      ...fieldsPayload,
      activityTypes,
    });
  };

  const onUserSelectHandleChange = (value) => {
    dispatch(
      setActivityFiltersDrawerUsersFieldEffect({
        data: { users: value },
      }),
    );
    onDone({
      ...fieldsPayload,
      users: value,
    });
  };

  const clientsList = useMemo(
    () =>
      relatedEntities?.length
        ? relatedEntities?.filter((entity) => entity.Type === 'Contact' && entity.Role === 'Client')
        : [],
    [relatedEntities],
  );

  const teamMembersList = useMemo(
    () =>
      relatedEntities?.length
        ? relatedEntities?.filter(
            (entity) => entity.Type === 'Contact' && entity.Role === Role.Agent,
          )
        : [],
    [relatedEntities],
  );

  const renderTeamMemberList = useMemo(() => {
    const list: any = [];
    teamMembersList?.forEach((p) => {
      const name = `${p.FirstName} ${p.LastName}`;
      list.push(<Option value={p.ContactUserId}>{name}</Option>);
    });

    return list;
  }, [relatedEntities]);

  const renderTransactionsList = useMemo(() => {
    const list: any = [];
    relatedTransactions?.length &&
      relatedTransactions?.forEach((p) => {
        list.push(
          <Option key={p.Id} value={p.Id}>
            {constructPropertyAddress(p.Address)}
          </Option>,
        );
      });

    return list;
  }, [relatedTransactions]);

  const onPropertiesSelectHandleChange = (value) => {
    dispatch(
      setActivityFiltersDrawerPropertiesFieldEffect({
        ...(value?.length
          ? { data: { entityTypes: ['PropertyTransaction'], entityIds: value } }
          : { data: { entityTypes: [], entityIds: [] } }),
      }),
    );
    onDone({
      ...fieldsPayload,
      ...(value?.length
        ? { entityTypes: ['PropertyTransaction'], entityIds: value }
        : { entityTypes: [], entityIds: [] }),
    });
  };

  const onDone = (payload?: any) => {
    dispatch(resetActivityLogsItemsListEffect());
    dispatch(
      requestGetActivityLogsEffect(
        getFormattedFilterObject(
          payload || fieldsPayload,
          1,
          activityLogsPageSize,
          undefined,
          isProperties ? ActivityLogsType.Pinpoint : ActivityLogsType.Radar,
        ),
      ),
    );
  };

  const activityTypes = [
    { label: 'Wizard Completed', value: 'CriteriaWizardCompleted' },
    { label: 'Wizard Skipped', value: 'CriteriaWizardSkipped' },
    { label: 'New Search Created', value: 'NewSearchInstanceCreated' },
    { label: 'Search Renamed', value: 'SearchInstanceRenamed' },
    { label: 'Search Deactivated', value: 'SearchInstanceDeactivated' },
    { label: 'Search Activated', value: 'SearchInstanceActivated' },
    { label: 'Free Search', value: 'FreeSearchTriggered' },
    { label: 'Criteria Edited', value: 'SearchInstanceCriteriaEdited' },
    { label: 'Added to Favorites', value: 'PropertyAddedToFavorites' },
    { label: 'Removed from Favorites', value: 'PropertyRemovedFromFavorites' },
    { label: 'Property Viewed', value: 'PropertyViewed' },
    { label: 'Tour Requested', value: 'TourRequested' },
    { label: 'Message Received', value: 'MessageReceived' },
    { label: 'Ad Hoc Search', value: 'NewAdHocSearch' },
    { label: 'View Time Spent', value: 'PropertyDetailsViewTimeSpent' },
    { label: 'Property Comment', value: 'PropertyComment' },
    { label: 'Tour Cancelled', value: 'TourCancelled' },
  ];

  return (
    <div className={styles.pageWrapper}>
      <RadarHeader title={isProperties ? 'Activity' : 'Logs'} />
      <div className={styles.pageContent}>
        <div className={styles.activityWrapper}>
          {renderActivities()}
          <div ref={loadingRef} style={{ height: '150px', margin: '25px' }}>
            <span style={{ display: isPending ? 'block' : 'none' }}>
              <Spinner />
            </span>
          </div>
        </div>
        <div className={styles.filterWrapper}>
          <FilterSection title="Date">
            <div>
              <DateRangePicker
                disableTitle
                onDatesChange={onDateChangeHandler}
                dateRangePickerWrapperClass={styles.dataRangeWrapper}
              />
            </div>
          </FilterSection>
          <FilterSection title="Clients">
            <MultiSelectSection
              placeholder={'Clients'}
              entityList={clientsList?.map((p) => ({
                label: `${p.FirstName} ${p.LastName}`,
                value: p.ContactUserId,
              }))}
              entity={fieldsPayload?.users.filter((id) =>
                clientsList?.find((p) => p.ContactUserId === id),
              )}
              setEntity={onUserSelectHandleChange}
              avatarPrefix
              multiSelectWrapperClassName={styles.select}
            />
          </FilterSection>
          {isProperties ? (
            <FilterSection title="Activity Type">
              <div className={styles.activityTypes}>
                {activityTypes.map(({ label, value }) => {
                  const isActive = fieldsPayload?.activityTypes.includes(value);
                  return (
                    <div
                      className={classNames(styles.activity, { [styles.active]: isActive })}
                      onClick={() => {
                        onActivityTypeHandler(value);
                      }}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </FilterSection>
          ) : (
            <>
              <FilterSection title="Team Members">
                <div>
                  <MultiSelect
                    size="large"
                    title="Team Members"
                    placeholderText="All Team Members"
                    variant="user"
                    onHandleChange={onUserSelectHandleChange}
                    propClass={styles.activityDrawerMultiselect}
                    optionFilterProp="children"
                    values={fieldsPayload?.users.filter((id) =>
                      teamMembersList?.find((p) => p.ContactUserId === id),
                    )}
                    wrapperClass={styles.multiSelectWrapper}
                  >
                    {renderTeamMemberList}
                  </MultiSelect>
                </div>
              </FilterSection>
              <FilterSection title="Transactions">
                <div>
                  <MultiSelect
                    size="large"
                    title="Transactions"
                    placeholderText="All Transactions"
                    onHandleChange={onPropertiesSelectHandleChange}
                    propClass={styles.activityDrawerMultiselect}
                    optionFilterProp="children"
                    values={fieldsPayload?.entityIds}
                    wrapperClass={styles.multiSelectWrapper}
                  >
                    {renderTransactionsList}
                  </MultiSelect>
                </div>
              </FilterSection>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

type FilterSectionProps = {
  title: string;
  children: React.ReactNode;
};

const FilterSection: React.FC<FilterSectionProps> = ({ title, children }) => {
  const getCollapseIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <Minus className={styles.btnWrapper} />
    ) : (
      <Plus className={styles.btnWrapper} />
    );
  };

  return (
    <Collapse
      ghost
      expandIcon={getCollapseIcon}
      expandIconPosition="end"
      className={styles.filterSection}
    >
      <Panel key="radarLogsFilter" header={<div className={styles.title}>{title}</div>}>
        {children}
      </Panel>
    </Collapse>
  );
};
