import Api from '../core/api';

import { getAISessions, createAISession, getAISessionMessages, generateAIReport } from 'api/ai';
import {
  requestAISessionsAction,
  createAISessionAction,
  requestSessionMessagesAction,
  generateAIReportAction,
} from 'store/actions/ai';

export const requestAISessionsEffect = (cfg, options, cb) => {
  const requestParams = { action: requestAISessionsAction, method: getAISessions };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, {}, cb);
};

export const createAISessionEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: createAISessionAction, method: createAISession };

  let sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, {}, cb);
};

export const getSessionMessagesEffect = (sessionsId, options = {}) => {
  const requestParams = { action: requestSessionMessagesAction, method: getAISessionMessages };

  let sendRequest = Api.execBase(requestParams);

  return sendRequest(sessionsId, {});
};

export const generateAIReportEffect = (cfg, options = {}, cb) => {
  const requestParams = { action: generateAIReportAction, method: generateAIReport };

  let sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, {}, cb);
};
