import { Button, Modal } from 'components-antd';
import { ServiceIconTypes, ServiceIcons } from 'pages/Services/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  openLocationModalEffect,
  updateConciergeLocationLocalEffect,
} from 'store/effects/concierge';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { ServiceLocation } from '../ServiceLocation';
import {
  updateConciergeCategoryConfiguration,
  updateConciergeSelectedTab,
} from 'store/actions/concierge';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import { Location } from 'types';
import { CLIENT } from 'settings/constants/roles';

import styles from './styles.module.scss';
import { getUserRoleSelector } from 'store/selectors/user';

export const LocationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { openLocationModal, areasOfOperation, category } = useSelector(getConciergeSearchSelector);
  const userRole = useSelector(getUserRoleSelector);
  const [locations, setLocations] = useState<Location[]>([]);

  const handleClose = () => {
    if (!locations.length && userRole !== CLIENT) dispatch(updateConciergeSelectedTab(0));

    if (!locations.length && areasOfOperation.length)
      dispatch(updateConciergeLocationLocalEffect([]));

    if (
      areasOfOperation.length &&
      !isNaN(Number(category)) &&
      Number(category) === ConciergeDefaultCategories.Draft
    )
      dispatch(updateConciergeCategoryConfiguration(''));

    dispatch(openLocationModalEffect(false));
  };

  return (
    <Modal
      title={<ServiceIcons icon={ServiceIconTypes.filledLocation} className={styles.modalIcon} />}
      open={openLocationModal || false}
      destroyOnClose={true}
      footer={null}
      closable={true}
      width={675}
      onCancel={handleClose}
      className={styles.modalContainer}
    >
      <span className={styles.modalTitle}>{`What area(s) are you looking in?`}</span>
      <span
        className={styles.modalSubTitle}
      >{`We'll use this to display vendors who service the area(s). You can change this anytime.`}</span>
      <ServiceLocation
        className={styles.locationInput}
        handleClose={handleClose}
        buttonClassName={styles.modalButton}
        locations={locations}
        setLocations={setLocations}
      />
    </Modal>
  );
};
