import { apiServer } from 'settings/web-services/api';
import { CLIENT } from 'settings/constants/roles';
import { getState } from 'store';
import { orderBy, toLower } from 'lodash-es';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';

// TODO: drop after open houses are implemented
export function getAllProps(cfg) {
  return apiServer.get('/listings/feed/all', { params: cfg });
}

export function getAgentClientHighlights(cfg) {
  const { userId, ...config } = cfg;

  return new Promise((resolve) => {
    apiServer
      .get(`/user/client/${userId}/highlights`, {
        params: config,
      })
      .then((response) => {
        resolve({
          data: {
            result: response?.data?.result,
          },
          meta: { params: config },
        });
      });
  });
}

export function getClientFavorites(cfg) {
  const { userId, ...config } = cfg;

  return new Promise((resolve) => {
    apiServer
      .get(`/user/client/${userId}/favorites`, {
        params: config,
      })
      .then((response) => {
        resolve({
          data: {
            result: response?.data?.result,
          },
          meta: { params: config },
        });
      });
  });
}

export function getAgentClientssWithFavoritesCount() {
  return apiServer.get('/user/agent/clients/favorites');
}

export function getClientCount(id) {
  return apiServer.get(`/listings/v3/interactions-count/client/${id}`);
}

export function sendShareProps(cfg) {
  return apiServer.post('/user/recommendProperty', cfg);
}

export function getCompareProperties(cfg) {
  return apiServer.get(`/listings?ids=${cfg.ids.join(',')}`);
}

export function getClientSearchInstancesByAgent() {
  return apiServer.get('/agent/clients-instances');
}

export function getClientPreferencesForComparison(cfg) {
  return apiServer.get(`/listings/searchInstance/${cfg.instanceId}?ids=${cfg.ids.join(',')}`);
}

export function initiateImageAnalysisMethod(cfg) {
  return apiServer.post('/imageai/v2/process-images', cfg);
  // return apiServer.post('/imageai/initiate', cfg);
}
