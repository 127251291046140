import { Button, DatePicker } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { showSuccessMessage } from 'helpers';
import { requestEditUpdateAddressEffect } from 'store/effects/intel';
import moment from 'moment';

export const ClosingDateForm = ({ value, handleClose, addressId, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { handleSubmit, handleChange } = useFormik({
    initialValues: { closingDate: value },
    onSubmit: async ({ closingDate }) => {
      const clDate = moment(closingDate[0]).startOf('day').toISOString();
      setLoading(true);
      dispatch(
        requestEditUpdateAddressEffect({ Id: addressId, closingDate: clDate }, {}, (err) => {
          if (!err) {
            onUpdate?.(clDate);
            showSuccessMessage('Closing Date updated successfully');
            setLoading(false);
            handleClose();
          }
        }),
      );
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <DatePicker
        name={'closingDate'}
        label="Closed"
        variant={DatePicker.ROUNDED}
        options={{ enableTime: false }}
        onChange={handleChange}
        allowTypedInput={true}
        allowPlugin={false}
        format={'m/d/Y'}
        value={value}
      />
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
