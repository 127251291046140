import { useDrop } from 'react-dnd';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import { FieldRenderer } from '..';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormSelector,
} from 'store/selectors/requestFormProcess';
import {
  selectDynamicFormFieldEffect,
  updateDynamicFormQuestionEffect,
} from 'store/effects/formProcess';

import styles from './styles.module.scss';
import { DynamicFormEditorQuestion } from 'types';

import { getDefaultFieldSize } from '../../helper';
import { VALIDATION_TYPES } from '../ValidationSettingsPopover/helper';
import { DefaultSystemFont, SignatureResponseType } from 'pages/FormBuilder/components';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

export const GROUP_PADDING = 2;
export const BORDER_WIDTH = 2.5;
export interface PDFEditorPageProps {
  pageIndex: number;
  documentIndex?: number;
  scale: number;
  signatories?: any[];
}

export const getGroupOptionSize = () => {
  return {
    width: 21,
    height: 21,
  };
};

let listenerAttached = false;

export const EditorPage = ({ pageIndex, documentIndex = -1, scale }: PDFEditorPageProps) => {
  const ref = useRef<HTMLSpanElement>(null);

  const dispatch = useDispatch();

  const {
    selectedRole,
    questions: formQuestions,
    formRoles,
  } = useSelector(getDynamicFormEditorConfigSelector);

  const { bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);

  const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;
  const isTemplate = Boolean(dynamicManager.isTemplate() || dynamicManager.isBundleTemplate());

  const questions = isCohesiveFlow
    ? bundleDocumentsDetails?.[documentIndex]?.questions || {}
    : formQuestions;

  const getFields = () => {
    let fieldsData: DynamicFormEditorQuestion[] = [];

    formRoles.forEach((role) => {
      const documentQuestions = questions;

      const roleQuestions = documentQuestions?.[pageIndex]?.[role.roleId]?.map((item, index) => ({
        ...item,
        customRole: role,
        customIndex: index,
      }));

      fieldsData = [...fieldsData, ...(roleQuestions || [])];
    });

    return fieldsData;
  };

  useEffect(() => {
    if (listenerAttached) return;

    const eventName = 'click';

    const clickHandler = (event) => {
      const target = event.target;

      const fields = document.getElementsByName('Form-Field');

      let outsideClicked = true;

      fields.forEach((field) => {
        if (target === field || field.contains(target)) {
          outsideClicked = false;
        }
      });

      if (outsideClicked) {
        dispatch(selectDynamicFormFieldEffect({}));
      }
    };

    document.addEventListener(eventName, clickHandler);

    listenerAttached = true;

    return () => {
      document.removeEventListener(eventName, clickHandler);
      listenerAttached = false;
    };
  }, []);

  const [{ isOver }, dropRef] = useDrop({
    accept: Object.values(DYNAMIC_QUESTION_TYPE),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();

      if (ref.current && clientOffset) {
        const dropTargetXy = ref.current.getBoundingClientRect();

        const type: DYNAMIC_QUESTION_TYPE = (item as any).type;
        const { width, height } = getDefaultFieldSize(type, scale);

        const x = (clientOffset.x - dropTargetXy.left - width / 2) / scale;
        const y = (clientOffset.y - dropTargetXy.top - height / 2) / scale;

        const fieldX = x * scale;
        const fieldY = y * scale;

        const isOverflowX = fieldX < 0 || width + fieldX > dropTargetXy.width;
        const isOverflowY = fieldY < 0 || height + fieldY > dropTargetXy.height;

        if (isOverflowX || isOverflowY) return;
        const isSignature =
          type === DYNAMIC_QUESTION_TYPE.Signature || type === DYNAMIC_QUESTION_TYPE.Initials;
        const isStrikeThrough = type === DYNAMIC_QUESTION_TYPE.StrikeThrough;
        const isRadioGroup = type === DYNAMIC_QUESTION_TYPE.RadioGroup;
        const isFullName = type === DYNAMIC_QUESTION_TYPE.FullName;

        const fieldUUID = uuidv4();

        const newQuestion = {
          roleName: selectedRole.roleId,
          pageNumber: pageIndex,
          documentIndex,
          isCohesiveFlow,
          isNew: true,
          UUID: fieldUUID,
          type,
          width: width / scale,
          height: height / scale,
          x,
          y,
          required: isSignature,

          ...(isRadioGroup && {
            isNewGroup: true,
            options: [
              {
                UUID: uuidv4(),
                width: getGroupOptionSize().width,
                height: getGroupOptionSize().height,
                x: 0,
                y: 0,
              },
            ],
            validation: {
              type: VALIDATION_TYPES.AtMost,
              value: 1,
            },
          }),

          ...(isStrikeThrough && {
            strokeWidth: 2,
            strokeColor: { r: 0, g: 0, b: 0, a: 1 },
          }),

          ...(isFullName &&
            !isTemplate && {
              fieldAnswer: {
                answer: JSON.stringify({
                  fullName: selectedRole.userName,
                  type: SignatureResponseType.Text,
                  font: DefaultSystemFont,
                }),
                UUID: fieldUUID,
                fontSize: 12,
                documentIndex,
              },
            }),
        };

        dispatch(updateDynamicFormQuestionEffect(newQuestion));
      }
    },
  });

  const questionFields = getFields();

  return (
    <div className={styles.pageContainer} ref={dropRef}>
      <span
        className={styles.pageContainerDrop}
        ref={ref}
        id={`pageContainerDrop-${documentIndex}-${pageIndex}`}
      >
        {questionFields.map((field, index) => {
          return (
            <FieldRenderer
              field={field}
              scale={scale}
              key={`field-render-${field.UUID}-${index}`}
              index={field.customRole ? field.customIndex : index}
              documentIndex={documentIndex}
              isCohesiveFlow={isCohesiveFlow}
              isTemplate={isTemplate}
            />
          );
        })}
      </span>
    </div>
  );
};
