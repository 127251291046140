import classNames from 'classnames';

import styles from './styles.module.scss';

export const OverdueLabel = (props) => {
  const { className } = props;

  return (
    <div testid="overdue_label" className={classNames(styles.overdueLabel, className)}>
      <div className={styles.icon}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
            stroke="#FF576D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 5.33398V8.00065"
            stroke="#FF576D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.666H8.00667"
            stroke="#FF576D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.text}>This task is overdue</div>
    </div>
  );
};
