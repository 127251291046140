export const Clock = ({ stroke = '', className = '' }) => {
  return (
    <div className={className} style={{ display: 'flex' }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_66434_27132)">
          <circle cx="8" cy="8" r="6" stroke="#FF576D" strokeWidth="2" />
          <path
            d="M8 5V8L10.5 9.5"
            stroke="#FF576D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_66434_27132">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
