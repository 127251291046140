import { DYNAMIC_QUESTION_TYPE, getDynamicFormColor } from 'app-constants';
import {
  SignatureField,
  TextField,
} from 'pages/FormProcess/DynamicForm/Containers/DynamicViewer/components/FieldRenderer/Fields';
import {
  textFieldsType,
  signatureFieldType,
} from 'pages/FormProcess/DynamicForm/Containers/DynamicViewer/components/FieldRenderer/Fields/types';

import { useSelector } from 'react-redux';
import { getDynamicFormEditorConfigSelector } from 'store/selectors/requestFormProcess';

export const FieldItem = (props) => {
  const {
    field,
    isCohesiveFlow,
    isHighlighted,
    disabled,
    documentIndex,
    otherSignatoryField = false,
    isSequentialDisabled = false,
    onFieldClick,
    setDisableDrag,
    onFieldUpdate,
    answer,
    responses,
    onUpdateAnswer,
    isTemplate,
  } = props;

  const { formRoles: allFormRoles } = useSelector(getDynamicFormEditorConfigSelector);

  const fieldWithCustomPermission = { ...field, CustomPermissions: [field.customRole.roleId] };
  const color = getDynamicFormColor(allFormRoles, fieldWithCustomPermission);

  const fieldDisabled = disabled || field.disabled;

  const questionType = field.type as DYNAMIC_QUESTION_TYPE;

  if (textFieldsType.includes(questionType)) {
    return (
      <TextField
        question={field}
        answer={answer}
        color={color}
        disabled={fieldDisabled}
        isHighlighted={isHighlighted}
        handleFieldClick={onFieldClick}
        documentIndex={documentIndex}
        isCohesiveFlow={isCohesiveFlow}
        otherSignatoryField={otherSignatoryField}
        questionFieldType={questionType}
        questionField={field}
        questionWidget={field}
        editorViewProps={{
          editorField: field,
          onFieldUpdate,
          isTemplate,
        }}
        responses={responses}
        onUpdateAnswer={onUpdateAnswer}
      />
    );
  } else if (signatureFieldType.includes(questionType)) {
    return (
      <SignatureField
        question={field}
        answer={answer || field.fieldAnswer?.answer}
        color={color}
        disabled={fieldDisabled || otherSignatoryField || isSequentialDisabled}
        isOtherSignatory={otherSignatoryField}
        isSequentialDisabled={isSequentialDisabled}
        isHighlighted={isHighlighted}
        documentIndex={documentIndex}
        isCohesiveFlow={isCohesiveFlow}
        editorViewProps={{ editorField: field, onFieldUpdate, isTemplate }}
        setDisableDrag={setDisableDrag}
        onUpdateAnswer={onUpdateAnswer}
      />
    );
  }

  return <></>;
};
