import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Checkbox, Dropdown, Menu, Panel } from 'components-antd';
import { Cross, Dots, Edit } from 'components/Icons';
import { Marker } from 'components/Icons';

import styles from './styles.module.scss';
import {
  getAgentName,
  getClientName,
  getFormattedDateTime,
  getNotesCount,
  getTime,
} from '../../helper';
import { CardActions, TourSort, TourStatus } from 'app-constants/enums/tours';
import moment from 'moment';
import { getCleanedText } from 'utils/pinpointHelpers';

export const TimelineItem = (props) => {
  const {
    onCancelTour,
    selectedKey,
    action,
    groupedKey,
    onCheckboxChange,
    checkboxChecked,
    onEditTour,
    children,
    className,
    onEdit,
    infoWrapClassName,
    tour,
    sort,
  } = props;
  const [showNotes, setShowNotes] = useState(true);
  const notesCount = getNotesCount(tour);
  const location = useLocation();

  const getStreetTwo = () => {
    return getCleanedText(
      tour?.PropertyAddress?.City,
      tour?.PropertyAddress?.State,
      tour?.PropertyAddress?.Zip,
    );
  };
  return (
    <div className={classNames(styles.item, className)}>
      <p className={styles.date}>{getTime(tour.ScheduleDateTime)}</p>
      <span className={styles.separator}>
        <Marker className={styles.icon} />
      </span>

      <div className={classNames(styles.info, infoWrapClassName)}>
        <Link
          className={styles.streetInfo}
          to={{
            pathname: `/properties/search/listing-detail/${tour.PropertyId}`,
            state: { backUrl: location.pathname },
          }}
        >
          <span className={styles.streetOne}>
            {tour.PropertyAddress.Line2
              ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
              : `${tour.PropertyAddress.Line1},`}{' '}
          </span>
          <span className={styles.streetTwo}>{getStreetTwo()}</span>
        </Link>
        {sort === TourSort.Date && (
          <p className={styles.agentInfo}>
            <span>Client:</span> {getClientName(tour)}
          </p>
        )}
        {sort === TourSort.AgentName ? (
          <p className={styles.agentInfo}>
            <span>Client: </span> {getClientName(tour)}
          </p>
        ) : (
          <p className={styles.agentInfo}>
            <span>{tour.TourAssignee.length > 1 ? 'Agents:' : 'Agent:'}</span> {getAgentName(tour)}
          </p>
        )}
        {tour.Status === TourStatus.Canceled ? (
          <>
            {!showNotes && (
              <>
                <p className={styles.agentInfo}>
                  <span>Cancelled</span>{' '}
                  {getFormattedDateTime(tour.CancelledAt, 'MM/DD/YYYY, hh:mm A')}
                </p>
                <p className={styles.agentInfo}>
                  <span>Cancelled By</span> {tour.CanceledBy.FirstName} {tour.CanceledBy.LastName}
                </p>
              </>
            )}
            <div className={classNames(styles.notes, { [styles.show]: !showNotes })}>
              <p className={styles.note}>
                <span>Notes: </span>
                {tour.ClientNotes}
              </p>
            </div>
            <span className={styles.notesToggler} onClick={() => setShowNotes(!showNotes)}>
              {showNotes ? 'View' : 'Hide'} Details
            </span>
          </>
        ) : (
          notesCount > 0 && (
            <>
              <div className={classNames(styles.notes, { [styles.show]: !showNotes })}>
                {tour.ClientNotes && (
                  <p className={styles.note}>
                    <span>Notes: </span>
                    {tour.ClientNotes}
                  </p>
                )}
                {tour.PrivateNotes && (
                  <p className={classNames(styles.note, styles.privateNote)}>
                    <span>Private Notes: </span>
                    {tour.PrivateNotes}
                  </p>
                )}
              </div>
              <span className={styles.notesToggler} onClick={() => setShowNotes(!showNotes)}>
                {showNotes ? 'View' : 'Hide'} Notes {showNotes && `${notesCount}`}
              </span>
            </>
          )
        )}
      </div>
      {tour.Status === TourStatus.Approved && moment(tour.ScheduleDateTime) > moment() ? (
        sort === TourSort.ClientName &&
        action === CardActions.Multiple &&
        groupedKey === selectedKey ? (
          <div className={styles.requestActions}>
            <Checkbox
              className={classNames(styles.sortCheckbox, 'mosaikCheckbox')}
              key={tour.Id}
              onChange={(e) => {
                onCheckboxChange(e.target.checked, tour);
              }}
              checked={checkboxChecked(tour)}
            ></Checkbox>
          </div>
        ) : (
          <div className={styles.action}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    className={styles.actionItem}
                    key={0}
                    onClick={() => {
                      onEditTour(tour);
                    }}
                  >
                    <Edit className={styles.icon} />
                    <span>Edit</span>
                  </Menu.Item>
                  <Menu.Item
                    className={styles.actionItem}
                    key={1}
                    onClick={() => {
                      onCancelTour(tour);
                    }}
                  >
                    <Cross className={styles.icon} />
                    <span>Cancel</span>
                  </Menu.Item>
                </Menu>
              }
              getPopupContainer={(node) => node}
              overlayClassName={styles.actionsDropdown}
              trigger={['click']}
              placement="bottomRight"
              className={styles.actions}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Dots />
              </a>
            </Dropdown>
          </div>
        )
      ) : (
        <div className={styles.action}>{children}</div>
      )}
    </div>
  );
};
