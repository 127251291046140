import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import {
  DeleteIcon,
  MinusIcon,
  PlusIcon,
} from 'pages/ServicesCategory/components/AddVendorModal/icons';

type ServiceAreaActionsPropsType = {
  setNewVendorPayload: Function;
  serviceArea;
};

const ServiceAreaActions = (props: ServiceAreaActionsPropsType) => {
  const { setNewVendorPayload, serviceArea } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      let targetId = '';
      if (event.target) {
        targetId = (event.target as any).id;
      }

      if (
        targetId === 'vendorModalServiceAreaMenuIcon' ||
        targetId === 'vendorModalServiceAreaMenu'
      )
        return;

      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getHamburgerDots = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.hbIcon}
      id="vendorModalServiceAreaMenuIcon"
    >
      <circle cx="5" cy="12" r="2" />
      <circle cx="12" cy="12" r="2" />
      <circle cx="19" cy="12" r="2" />
    </svg>
  );

  return (
    <div
      className={styles.serviceAreaActions}
      onClick={() => {
        setShowMenu((prev) => !prev);
      }}
      id="vendorModalServiceAreaMenu"
    >
      {getHamburgerDots()}
      {showMenu && (
        <div className={styles.menu} ref={menuRef}>
          <div
            className={styles.item}
            onClick={() => {
              setNewVendorPayload((prev) => {
                return {
                  ...prev,
                  ServiceAreas: prev.ServiceAreas.map((area) =>
                    area.uuid === serviceArea.uuid
                      ? { ...area, hasRadius: !serviceArea.hasRadius }
                      : area,
                  ),
                };
              });
            }}
          >
            <span className={styles.icon}>
              {serviceArea.hasRadius ? <MinusIcon /> : <PlusIcon />}
            </span>
            <div className={styles.label}>{`${
              serviceArea.hasRadius ? 'Remove' : 'Add'
            } Radius`}</div>
          </div>
          <div
            className={styles.item}
            onClick={() => {
              setNewVendorPayload((prev) => {
                return {
                  ...prev,
                  ServiceAreas: prev.ServiceAreas.filter((item) => item.uuid !== serviceArea.uuid),
                };
              });
            }}
          >
            <span className={styles.icon}>
              <DeleteIcon />
            </span>
            <div className={styles.label}>Delete</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceAreaActions;
