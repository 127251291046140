import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'components';
import { LocationService } from 'services';
import { appOpenModalEffect, getSearchInstancesEffect } from 'store/effects';
import { getModalSelector } from 'store/selectors/app';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';

import styles from './styles.module.scss';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { SavedSearches } from './SavedSearches';

const SearchesModal = ({ setShowSearch }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const dispatch = useDispatch();
  const modal = useSelector(getModalSelector);
  const { savedSearchTab } = useSelector(getFeedMetaSelectorV3);

  const { data: savedSearches, isLoading } = useSelector(getSearchInstancesFormattedSelector);

  const onClose = () => {
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const getTab = () => {
    switch (savedSearchTab) {
      case 1:
        return (
          <SavedSearches data={savedSearches} isPending={isLoading} setShowSearch={setShowSearch} />
        );

      default:
        return null;
    }
  };

  if (modal.id !== SearchesModal.id) return null;

  const labels = {
    1: 'My Searches',
  };

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      headerClassName={styles.header}
      label={labels?.[savedSearchTab]}
      className={styles.searchModalWrapper}
    >
      <div className={styles.drawerInner}>{getTab()}</div>
    </Modal>
  );
};

export default SearchesModal;

SearchesModal.id = 'clientSearchesModal';
