import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { handleDynamicFormResponse } from './helper';
import { IDLE, READY } from 'settings/constants/apiState';
import {
  DynamicErrorConfig,
  DynamicFormEditorQuestionUpdate,
  DynamicFormQuestions,
  DynamicFormSelectedField,
  DynamicFormType,
  DynamicSignatureConfig,
  FormDocumentType,
  PDFEditorConfig,
  RequestFormProcessType,
} from 'types';
import {
  createNewFormAction,
  requestFormMetaAction,
  requestSmartFormDataAction,
  requestPublishedFormsAction,
  resetPreFormAction,
  updatePreFormResponseAction,
  requestFormProcessDocumentAction,
  resetFormProcessAction,
  setFormShowArchiveAction,
  updateFormProcessDocumentResponseAction,
  requestPostFormDataAction,
  updateFormAction,
  setFormLibraryModalVisibleAction,
  requestSendFormProcessAction,
  unlockFormDocumentAuthAction,
  unlockFormDocumentAnonAction,
  getDraftFormsAction,
  deleteDraftFormAction,
  getFormDetailsAction,
  resetFormDetailsAction,
  getAllFormProcessAction,
  getAllUnlinkFormProcessAction,
  getArchiveFormsAction,
  resetSmartFormDataAction,
  requestDeferredQuestionsAction,
  deleteDeferredQuestionAction,
  resetDeferredQuestionsAction,
  requestSignatureAction,
  resetPostFormEffectAction,
  requestPublishedFormAction,
  getAllTransactionFormProcessAction,
  requestFormTemplatesAction,
  updateFormRoleAction,
  requestDynamicFormDocumentAction,
  resetDynamicFormAction,
  updateDynamicFormScaleAction,
  selectDynamicFormRoleAction,
  updateDynamicFormQuestionAction,
  selectDynamicFormFieldAction,
  deleteDynamicFormQuestionAction,
  updateDynamicFormActiveQuestionAction,
  updateDynamicFormResponseAction,
  updateDynamicFormAction,
  requestDynamicPostForm,
  updateDynamicRequiredQuestionAction,
  updateHideQuestionRoleAction,
  updateDynamicFormDocumentQuestionAction,
  updateDynamicFormEditorConfigAction,
  updateFormsFiltersAction,
  requestFormRecentUploadsAction,
  requestAgentFormRolesAction,
  selectDynamicFormAction,
  updateDynamicFormSignatureConfigAction,
  updateLazyLoadedDocumentsAction,
  updateEditorConfigQuestionsAction,
  requestSignatoriesAction,
} from 'store/actions/formProcess';
import { DEFAULT_PDF_SCALE } from 'components/PrintPDF';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';

const initialData: RequestFormProcessType = {
  state: IDLE,
  isArchive: false,
  preForm: {
    isSequentialSignature: false,
  },
  publishedForms: {
    state: IDLE,
    data: {
      page: 0,
      pageSize: 0,
      total: 0,
      forms: [],
    },
  },
  formTemplates: {
    state: IDLE,
    data: {
      results: [],
      page: 0,
      pageSize: 0,
      total: 0,
      forms: [],
    },
  },
  formRecentUploads: {
    state: IDLE,
    data: {
      results: [],
      page: 0,
      pageSize: 0,
      total: 0,
      documents: [],
    },
  },
  meta: {},
  formRoles: [],
  agentFormRoles: [],
  formName: '',
  isFormLibraryModalVisible: false,
  allFormProcesses: {
    recentlyCompletedForms: [],
    finalizingForms: [],
    waitingOnYou: [],
    waitingOnOthers: [],
    voidedForms: [],
    draftForms: [],
    canceledForms: [],
  },
  allUnlinkFormProcesses: [],
  allArchiveForms: {
    archiveForms: [],
    clientDataMap: {},
  },
  formDetails: {
    state: IDLE,
  },
  draftForms: {
    state: IDLE,
  },
  deferredQuestions: {
    state: IDLE,
  },
  currentUserSignature: undefined,
  publishedForm: {
    state: IDLE,
  },
  filters: {},
  isPending: false,
  formSignatories: {},
};

export default handleActions(
  {
    [requestPublishedFormsAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      publishedForms: {
        state: payload?.state,
        data: get(payload, 'data.value', initialData.publishedForms),
      },
    }),
    [requestFormTemplatesAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      formTemplates: {
        state: payload?.state,
        data: get(payload, 'data.value.formTemplates', initialData.formTemplates),
      },
    }),
    [requestFormRecentUploadsAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      formRecentUploads: {
        state: payload?.state,
        data: get(payload, 'data.value', initialData.formRecentUploads),
      },
    }),
    [requestFormMetaAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      meta: get(payload, 'data.value', initialData.meta),
    }),
    [requestSmartFormDataAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      const dataPath = get(payload, 'data.value.response.FormRoles')
        ? 'data.value.response'
        : 'data.value';

      return {
        ...state,
        state: payload?.state,
        formRoles: get(payload, `${dataPath}.FormRoles`, initialData.formRoles),
        signatoriesPresent: get(payload, `${dataPath}.signatoriesPresent`, true),
        defaultEditors: get(payload, `${dataPath}.defaultEditors`, []),
        editorsPresent: get(payload, 'data.value.editorsPresent', true),
        formName: get(payload, 'data.value.FormName', initialData.formName),
        clientFieldsPresent: get(payload, 'data.value.clientFieldsPresent', true),
        showClientEditorScreen: get(payload, 'data.value.showClientEditorScreen', false),
        editorsRoles: get(payload, 'data.value.editorsRoles', false),
      };
    },

    [requestAgentFormRolesAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      agentFormRoles: get(payload, 'data.result', initialData.agentFormRoles),
    }),

    [resetSmartFormDataAction as any]: (state: RequestFormProcessType): RequestFormProcessType => ({
      ...initialData,
    }),
    [updatePreFormResponseAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      preForm: {
        ...state.preForm,
        ...payload,
      },
    }),
    [resetPreFormAction as any]: (state: RequestFormProcessType): RequestFormProcessType => ({
      ...state,
      preForm: initialData.preForm,
    }),
    [createNewFormAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
    }),
    [updateFormAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
    }),
    [requestSendFormProcessAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
    }),
    [requestFormProcessDocumentAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      formDocument:
        get(payload, 'data.value.0', initialData.formDocument) ||
        get(payload, 'data.value', initialData.formDocument),
    }),
    [resetFormProcessAction as any]: (state: RequestFormProcessType): RequestFormProcessType => ({
      ...state,
      formDocument: initialData.formDocument,
    }),
    [setFormShowArchiveAction as any]: (
      state: RequestFormProcessType,
      { payload }: { payload: Partial<RequestFormProcessType> },
    ): RequestFormProcessType => {
      return {
        ...state,
        isArchive: payload.isArchive,
      };
    },
    [resetFormDetailsAction as any]: (state: RequestFormProcessType): RequestFormProcessType => ({
      ...state,
      formDetails: initialData.formDetails,
    }),
    [updateFormProcessDocumentResponseAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      const answers = state.formDocument?.answers || {};
      const updatedResponse = (answers || {})[payload?.UUID];

      if (updatedResponse) {
        answers[payload?.UUID] = {
          ...answers[payload?.UUID],
          DefaultAnswer: payload?.DefaultAnswer,
          Answer: payload?.Answer,
        };
      }

      return {
        ...state,
        formDocument: {
          ...((state.formDocument as FormDocumentType) || {}),
          answers,
        },
      };
    },
    [resetPostFormEffectAction as any]: (
      state: RequestFormProcessType,
    ): RequestFormProcessType => ({
      ...state,
      postForm: undefined,
    }),
    [requestPostFormDataAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      postForm: {
        editorParticipants: get(payload, 'data.value.editorParticipants', []),
        signatoryParticipants: get(payload, 'data.value.signatoryParticipants', []),
        isSequentialSigning: get(payload, 'data.value.isSequentialSigning', false),
        editorsPresent: get(payload, 'data.value.editorsPresent', false),
        signatoriesPresent: get(payload, 'data.value.signatoriesPresent', false),
        remainingQuestions: get(payload, 'data.value.remainingQuestions', false),
      },
    }),
    [setFormLibraryModalVisibleAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      const newValue = payload as unknown as boolean; // types are wrong here. TO FIX.

      return {
        ...state,
        isFormLibraryModalVisible: newValue,
      };
    },
    [unlockFormDocumentAuthAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
    }),
    [unlockFormDocumentAnonAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
    }),
    [getDraftFormsAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      draftForms: { state: payload?.state, drafts: get(payload, 'data.value.result') },
    }),
    [deleteDraftFormAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      draftForms: {
        ...state.draftForms,
        state: payload.state,
      },
    }),
    [getFormDetailsAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      formDetails: {
        state: payload?.state,
        data: get(payload, 'data.value'),
      },
    }),
    [getAllFormProcessAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      allFormProcesses: get(payload, 'data.value', initialData.allFormProcesses),
    }),
    [getAllUnlinkFormProcessAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      allUnlinkFormProcesses: get(payload, 'data.value', initialData.allUnlinkFormProcesses),
    }),
    [getArchiveFormsAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      state: payload?.state,
      allArchiveForms: get(payload, 'data.value', initialData.allArchiveForms),
    }),
    [requestDeferredQuestionsAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      deferredQuestions: {
        state: payload?.state,
        data: get(payload, 'data.result'),
      },
    }),
    [deleteDeferredQuestionAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      deferredQuestions: {
        ...state.deferredQuestions,
        state: payload?.state,
      },
    }),
    [resetDeferredQuestionsAction as any]: (
      state: RequestFormProcessType,
    ): RequestFormProcessType => ({
      ...state,
      deferredQuestions: initialData.deferredQuestions,
    }),
    [requestSignatureAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      currentUserSignature: get(payload, 'data.result', initialData.currentUserSignature),
    }),
    [requestPublishedFormAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      publishedForm: {
        state: payload?.state,
        data: get(payload, 'data.value', initialData.publishedForm),
      },
    }),
    [updateFormRoleAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      formRoles: payload?.formRoles || [],
    }),
    // Dynamic Forms

    [requestDynamicFormDocumentAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      dynamicForm: handleDynamicFormResponse(payload, state.dynamicForm),
    }),

    [resetDynamicFormAction as any]: (state: RequestFormProcessType): RequestFormProcessType => ({
      ...state,
      dynamicForm: undefined,
    }),

    [updateDynamicFormScaleAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      dynamicForm: {
        ...(state.dynamicForm || ({} as DynamicFormType)),
        scale: get(payload, 'scale', DEFAULT_PDF_SCALE),
      },
    }),
    [selectDynamicFormRoleAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      dynamicForm: {
        ...(state.dynamicForm || ({} as DynamicFormType)),
        editorConfig: {
          ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
          selectedRole: get(payload, 'role', ''),
          selectedField: undefined,
        },
      },
    }),
    [updateDynamicFormQuestionAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      const {
        pageNumber,
        roleName,
        isNew,
        newRole,
        index,
        templateEditMode,
        roleId,
        documentIndex,
        isCohesiveFlow,
        isGroupField,
        ...questionData
      } = payload as unknown as DynamicFormEditorQuestionUpdate;

      delete questionData.customRole;

      const bundleDocumentsDetails = state.dynamicForm?.bundleDocumentsDetails || [];

      let questions = state.dynamicForm?.editorConfig?.questions || ({} as DynamicFormQuestions);

      questions = isCohesiveFlow ? bundleDocumentsDetails[documentIndex]?.questions : questions;

      const questionIndexKey = templateEditMode ? roleId : roleName;

      if (pageNumber >= 0 && questionIndexKey) {
        if (!questions?.[pageNumber]) {
          questions[pageNumber] = {};
        }
        if (!questions[pageNumber][questionIndexKey]) {
          questions[pageNumber][questionIndexKey] = [];
        }

        let selectedField = {
          UUID: questionData.UUID,
          pageNumber,
          roleName: newRole ?? roleName,
          roleId,
        };

        const roleData = newRole
          ? state.dynamicForm?.editorConfig?.formRoles?.find((role) => role.roleName === newRole)
          : undefined;

        if (isNew) {
          questions[pageNumber][questionIndexKey].push({
            ...questionData,
            pageNumber: pageNumber,
            roleName,
          });
        } else if (
          index !== undefined &&
          index >= 0 &&
          questions[pageNumber][questionIndexKey][index]
        ) {
          if (newRole) {
            const fieldData = { ...questions[pageNumber][questionIndexKey][index] };

            questions[pageNumber][questionIndexKey].splice(index, 1);

            if (!questions[pageNumber][newRole]) {
              questions[pageNumber][newRole] = [];
            }

            questions[pageNumber][newRole].push({
              ...fieldData,
              ...(fieldData.id && { isUpdated: true }),
              roleName: newRole,
              ...(questionData.strokeColor && { strokeColor: questionData.strokeColor }),
              ...(questionData.fieldAnswer && { fieldAnswer: questionData.fieldAnswer }),
              ...(isGroupField && {
                options: fieldData.options?.map((item) => ({
                  ...item,
                  isRoleModified: !!fieldData.id,
                })),
              }),
            });
          } else {
            questions[pageNumber][questionIndexKey][index] = {
              ...questions[pageNumber][questionIndexKey][index],
              ...questionData,
              ...(questions[pageNumber][questionIndexKey][index].id ? { isUpdated: true } : {}),
            };
          }
        }

        // Setting question for bundle document
        const dynamicFormDocuments = state?.dynamicForm?.dynamicFormDocuments || [];

        if (state.dynamicForm && dynamicFormDocuments.length) {
          dynamicFormDocuments[documentIndex].questions = questions;

          if (isCohesiveFlow) {
            bundleDocumentsDetails[documentIndex].questions = questions;
          }
        }

        return {
          ...state,
          dynamicForm: {
            ...(state.dynamicForm || ({} as DynamicFormType)),
            editorConfig: {
              ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
              questions: questions,
              ...(roleData ? { selectedRole: roleData } : {}),
              selectedField,
              lastUpdated: new Date(),
            },
            dynamicFormDocuments,
            ...(isCohesiveFlow && { bundleDocumentsDetails }),
          },
        };
      } else {
        return state;
      }
    },
    [updateDynamicFormDocumentQuestionAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          editorConfig: {
            ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
            lastSave: new Date(),
          },
        },
      };
    },

    [selectDynamicFormFieldAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      dynamicForm: {
        ...(state.dynamicForm || ({} as DynamicFormType)),
        editorConfig: {
          ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
          selectedField: get(payload, 'field', ''),
        },
      },
    }),

    [selectDynamicFormAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => ({
      ...state,
      dynamicForm: {
        ...(state.dynamicForm || ({} as DynamicFormType)),
        editorConfig: {
          ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
          selectedForm: get(payload, 'form', ''),
        },
      },
    }),
    [deleteDynamicFormQuestionAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      const questions = state.dynamicForm?.editorConfig?.questions || ({} as DynamicFormQuestions);
      const { pageNumber, roleName, index, templateEditMode, roleId } =
        payload as unknown as DynamicFormEditorQuestionUpdate;

      const questionIndexKey = templateEditMode ? roleId : roleName;

      if (pageNumber >= 0 && questionIndexKey && index !== undefined) {
        if (questions[pageNumber][questionIndexKey][index]) {
          if (!questions[pageNumber][questionIndexKey][index].id) {
            questions[pageNumber][questionIndexKey].splice(index, 1);
            if (!questions[pageNumber][questionIndexKey].length) {
              delete questions[pageNumber][questionIndexKey];
            }

            if (!Object.keys(questions[pageNumber]).length) {
              delete questions[pageNumber];
            }
          } else {
            questions[pageNumber][questionIndexKey][index].isDeleted = true;
          }

          return {
            ...state,
            dynamicForm: {
              ...(state.dynamicForm || ({} as DynamicFormType)),
              editorConfig: {
                ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
                questions: questions,
                selectedField: undefined,
              },
            },
          };
        }
      }

      return state;
    },
    [updateDynamicFormEditorConfigAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          editorConfig: {
            ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
            ...(payload as unknown as PDFEditorConfig),
          },
        },
      };
    },
    [updateDynamicFormActiveQuestionAction as any]: (
      state: RequestFormProcessType,
      { payload },
    ): RequestFormProcessType => {
      const uuid = get(payload, 'uuid');

      if (!uuid) {
        return state;
      }

      const isCohesiveFlow = get(payload, 'isCohesiveFlow');
      const documentIndex = get(payload, 'documentIndex');

      const bundleDocumentsDetails = state.dynamicForm?.bundleDocumentsDetails || [];
      let isOtherSignatory = false;

      const questions = isCohesiveFlow
        ? bundleDocumentsDetails[documentIndex].activeQuestions
        : state?.dynamicForm?.signatureConfig?.activeQuestions;

      let index = questions?.findIndex((question) => question.UUID === uuid);

      if (index === undefined || index === -1) {
        const otherQuestions = isCohesiveFlow
          ? bundleDocumentsDetails[documentIndex].otherSignatoriesQuestions
          : state?.dynamicForm?.signatureConfig?.otherSignatoriesQuestions;

        index = otherQuestions?.findIndex((question) => question.UUID === uuid);

        if (index === undefined) {
          index = -1;
        } else {
          isOtherSignatory = true;
        }
      }

      if (index === -1) {
        if (state?.dynamicForm && state?.dynamicForm?.signatureConfig) {
          state.dynamicForm.signatureConfig.isOtherSignatory = false;
        }

        return state;
      }

      if (isCohesiveFlow) {
        bundleDocumentsDetails[documentIndex].activeQuestionIndex = index;
        bundleDocumentsDetails[documentIndex].activeQuestionUUID = uuid;
      }

      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          ...(isCohesiveFlow && { bundleDocumentsDetails }),

          signatureConfig: {
            ...(state.dynamicForm?.signatureConfig || ({} as DynamicSignatureConfig)),
            activeQuestionIndex: index,
            activeQuestionUUID: uuid,
            isOtherSignatory,
          },
        },
      };
    },
    [updateDynamicFormResponseAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      let answers = state.dynamicForm?.signatureConfig?.responses || {};

      const bundleDocumentsDetails = state.dynamicForm?.bundleDocumentsDetails || [];
      const isCohesiveFlow = bundleDocumentsDetails.length > 0 && payload.documentIndex >= 0;

      answers = isCohesiveFlow
        ? bundleDocumentsDetails[payload.documentIndex]?.answers || {}
        : answers;

      if (payload?.UUID) {
        answers[payload.UUID] = {
          ...(answers[payload.UUID] || {}),
          Answer: payload.Answer || payload.answer,
          UUID: payload.UUID,
        };
      }

      if (isCohesiveFlow) {
        bundleDocumentsDetails[payload.documentIndex].answers = answers;
      }

      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          ...(isCohesiveFlow && { bundleDocumentsDetails }),
          signatureConfig: {
            ...(state.dynamicForm?.signatureConfig || ({} as DynamicSignatureConfig)),
            responses: answers,
            wizardStarted: true,
          },
        },
      };
    },
    [updateDynamicFormAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          ...(payload || {}),
        },
      };
    },
    [updateEditorConfigQuestionsAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          editorConfig: {
            ...(state.dynamicForm?.editorConfig || ({} as PDFEditorConfig)),
            questions: payload,
          },
        },
      };
    },
    [updateLazyLoadedDocumentsAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        lazyLoadedDocuments: {
          ...(payload || {}),
        },
      };
    },
    [updateDynamicFormSignatureConfigAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          signatureConfig: {
            ...(state.dynamicForm?.signatureConfig || ({} as DynamicSignatureConfig)),
            responses: payload.responses,
          },
        },
      };
    },
    [requestDynamicPostForm as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          postForm: {
            editorParticipants: get(payload, 'data.value.editorParticipants', []),
            signatoryParticipants: get(payload, 'data.value.signatoryParticipants', []),
            isSequentialSigning: get(payload, 'data.value.isSequentialSigning', false),
            editorsPresent: get(payload, 'data.value.editorsPresent', false),
            signatoriesPresent: get(payload, 'data.value.signatoriesPresent', false),
            remainingQuestions: get(payload, 'data.value.remainingQuestions', false),
            isReady: payload.state === READY,
          },
        },
      };
    },
    [updateDynamicRequiredQuestionAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          errorConfig: {
            ...(state.dynamicForm?.errorConfig || ({} as DynamicErrorConfig)),
            requiredQuestion: payload.requiredQuestion || [],
          },
        },
      };
    },
    [updateHideQuestionRoleAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => {
      return {
        ...state,
        dynamicForm: {
          ...(state.dynamicForm || ({} as DynamicFormType)),
          signatureConfig: {
            ...(state.dynamicForm?.signatureConfig || ({} as DynamicSignatureConfig)),
            hideQuestionRoles: payload.hideQuestions,
          },
        },
      };
    },

    [updateFormsFiltersAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => ({
      ...state,
      filters: payload,
    }),
    [requestSignatoriesAction as any]: (
      state: RequestFormProcessType,
      { payload }: any,
    ): RequestFormProcessType => ({
      ...state,
      formSignatories: get(payload, 'data.value', initialData.formSignatories),
    }),
  },

  initialData,
);
