import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { IDLE } from 'settings/constants/apiState';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';

import { getStateAndDataFlag } from 'store/selectors/clientsList';
import {
  getConnectionsSearchValue,
  getClientDrawerActiveTab,
  getConnectionTypeSelector,
} from 'store/selectors/app';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';
import { appSetClientsSearchValueAction, appSetClientDrawerActiveTab } from 'store/actions/app';
import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { requestGetCollaboratorsListEffect } from 'store/effects/collaboratorsList';
import { requestGetTeamListEffect } from 'store/effects/teamList';

import { Input, Tabs, Spinner } from 'components';
import ClientsList from './ClientsList';
import { Cross } from 'components/Icons';

import styles from './styles.module.scss';

const ViewClients = () => {
  const { state, isData } = useSelector(getStateAndDataFlag);
  const searchValue = useSelector(getConnectionsSearchValue);
  const activeTabIndex = useSelector(getClientDrawerActiveTab);
  const connectionType = useSelector(getConnectionTypeSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetClientsListEffect());
    dispatch(requestGetCollaboratorsListEffect());
    dispatch(requestGetTeamListEffect());
  }, []);

  useEffect(() => {
    if (state === IDLE && !isData) {
      dispatch(requestGetClientsListEffect());
    }
  }, [state, isData, dispatch]);

  const tabs = [
    {
      id: AGENT_CONNECTION_STATUSES.ACTIVE,
      label: 'Active',
      Component: ClientsList,
      testid: 'active_tab',
    },
    ...(connectionType === AGENT_CONNECTIONS.Clients
      ? [
          {
            id: AGENT_CONNECTION_STATUSES.RETAINED,
            label: 'Retention',
            Component: ClientsList,
            testid: 'retained_tab',
          },
        ]
      : []),

    {
      id: AGENT_CONNECTION_STATUSES.PENDING,
      label: 'Pending',
      Component: ClientsList,
      testid: 'pending_tab',
    },
    {
      id: AGENT_CONNECTION_STATUSES.INACTIVE,
      label: 'Inactive',
      Component: ClientsList,
      testid: 'inactive_tab',
    },
  ];

  const handleRemoveSearch = () => {
    dispatch(appSetClientsSearchValueAction(''));
  };

  return (
    <div testid="view_clients" className={styles.viewClientsContainer}>
      {!isData ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <>
          <div
            className={classNames(styles.searchLineContainer, {
              [styles.fullWidth]: searchValue,
            })}
          >
            <Input
              type="search"
              variant={Input.FULL}
              name="clients"
              value={searchValue}
              onChange={(event, inputVal) => dispatch(appSetClientsSearchValueAction(inputVal))}
              placeholder="Search name"
              testid="search_name"
              removeHandler={searchValue ? handleRemoveSearch : false}
              removeIcon={<Cross color="#515151" />}
            />
          </div>
          <Tabs
            tabs={tabs}
            animation="default"
            wrapperClassName={styles.tabsContainer}
            tabsClassName={classNames(styles.clientTabsSelect, {
              [styles.visibilityHidden]: searchValue,
            })}
            selectClassName={styles.clientTabsSelect}
            dropdownClassName={styles.clientTabsDropdown}
            contentClassName={styles.contentContainer}
            showSelectDropdown={true}
            placement={'bottomRight'}
            activeTabIndex={activeTabIndex}
            showMenuItemSelectedIcon={true}
            onChange={(tabIndex) => {
              dispatch(appSetClientDrawerActiveTab(tabIndex));
            }}
            enableQueryParams={false}
          />
        </>
      )}
    </div>
  );
};

export default ViewClients;
