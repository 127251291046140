import { EmailTemplate, TaskTemplate } from 'types/transactionTemplate';

export enum TaskEditableFormValuesKeys {
  Documents,
  Name,
  Title,
  DueDate,
  Assignee,
  Description,
  Links,
  Category,
  customTag,
  ControlOperatorOffset,
  Offset,
  People,
  Tasks,
  AssigneeRoles,
  AssigneeList,
  CcRoles,
  CcList,
  SaveAsTemplate,
  Checklists,
  MilestonePublicId,
  MilestoneId,
  Hours,
  Minutes,
  Timezone,
  IsBusinessDays,
  RepeatOption,
  CustomRepeatOption,
  RepeatCount,
  RepeatOn,
  RepeatOccurrence,
  RepeatUntilDate,
  EndsOnClosing,
  EndsOnListingExpiration,
}

type CheckLists = {
  Name: string;
  Required: boolean;
};

export type UpdateTaskRequest = {
  Title?: string;
  Description?: string;
  Category?: string;
  DueDate?: string | Date;
  AssigneeIds?: number[];
  CcIds?: number[];
  Links?: string[];
  Status?: string;
  Documents?: any;
  Checklists?: CheckLists[];
  EmailTemplate?: EmailTemplate;
};

export type TaskEditableFormValuesType = Partial<
  Record<keyof typeof TaskEditableFormValuesKeys, any>
>;
