import { SVGProps } from 'react';

export const GoBack = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <div className={className}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 21L6 12L15 3"
        stroke="#262626"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
