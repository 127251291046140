import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick, color, svgClass }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.NEW_SEARCH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M25 36C31.0751 36 36 31.0751 36 25C36 18.9249 31.0751 14 25 14C18.9249 14 14 18.9249 14 25C14 31.0751 18.9249 36 25 36Z"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M42 42L33 33"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.MY_SEARCH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M18.6667 17.5V42L28 36.1667L37.3334 42V17.5C37.3334 15.567 35.7664 14 33.8334 14H22.1667C20.2337 14 18.6667 15.567 18.6667 17.5Z"
              stroke="#928CDA"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.TOURS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M34.7999 9.59961H13.1999C11.2117 9.59961 9.59991 11.2114 9.59991 13.1996V34.7996C9.59991 36.7878 11.2117 38.3996 13.1999 38.3996H34.7999C36.7881 38.3996 38.3999 36.7878 38.3999 34.7996V13.1996C38.3999 11.2114 36.7881 9.59961 34.7999 9.59961Z"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.2 7.2002V12.0002"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.8 7.2002V12.0002"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.59991 19.2002H38.3999"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.4 28.7998L27.6 28.7998"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.TOP_MATCHES: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M12 29V32C12 33.6568 13.3431 35 15 35H33C34.6569 35 36 33.6568 36 32V18.9504C36 17.9707 35.5216 17.0527 34.7186 16.4915L30 13.1935"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30 12.9504V26C30 27.6569 28.6569 29 27 29H9C7.34315 29 6 27.6569 6 26V13.7035C6 12.7718 6.43286 11.893 7.17149 11.3251L16.256 4.34083C17.2941 3.54273 18.7299 3.51011 19.8031 4.26024L28.7186 10.4915C29.5216 11.0527 30 11.9707 30 12.9504Z"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.AGENT_HIGHLIGHTS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M28.3905 37.7859C28.1477 37.6583 27.8577 37.6583 27.6149 37.7859L19.2107 42.2045C18.5993 42.526 17.8848 42.0068 18.0015 41.326L19.607 31.9672C19.6533 31.6968 19.5637 31.421 19.3673 31.2295L12.5686 24.6024C12.074 24.1203 12.347 23.2803 13.0305 23.181L22.4258 21.8167C22.6972 21.7772 22.9319 21.6068 23.0533 21.3608L27.2554 12.8462C27.5611 12.2268 28.4443 12.2268 28.75 12.8462L32.9521 21.3608C33.0735 21.6068 33.3082 21.7772 33.5797 21.8167L42.9749 23.181C43.6584 23.2803 43.9314 24.1203 43.4368 24.6024L36.6381 31.2295C36.4417 31.421 36.3521 31.6968 36.3985 31.9672L38.0039 41.326C38.1207 42.0068 37.4061 42.526 36.7948 42.2045L28.3905 37.7859Z"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.FAVORITES: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
          >
            <path
              d="M7.66297 23.23C6.16852 21.6926 5.25 19.6051 5.25 17.3056C5.25 12.5805 9.12833 8.75 13.9125 8.75C16.8422 8.75 19.4324 10.1865 21 12.3852C22.5676 10.1865 25.1578 8.75 28.0875 8.75C32.8716 8.75 36.75 12.5805 36.75 17.3056C36.75 19.718 35.7392 21.8971 34.1129 23.4523L23.4917 34.2232C22.1212 35.613 19.8786 35.6132 18.5079 34.2237L7.66297 23.23Z"
              stroke="#FF576D"
              strokeWidth="3"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.NEW_SEARCH = 'newSearch';
Icons.MY_SEARCH = 'mySearch';
Icons.TOURS = 'tours';
Icons.TOP_MATCHES = 'topMatches';
Icons.AGENT_HIGHLIGHTS = 'agentHighlights';
Icons.FAVORITES = 'favorites';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  svgClass: PropTypes.string,
};

Icons.defaultProps = {
  svgClass: '',
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
