import { routes } from 'settings/navigation/routes';
import { RadarLogs } from 'pages';
import { AGENT } from 'settings/constants/roles';

export default [
  {
    path: routes.propertiesActivity,
    component: RadarLogs,
    exact: true,
    roles: [AGENT],
  },
];
