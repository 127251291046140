import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getCancelAvmAdjustmentAction, getEditAvmAdjustmentAction } from 'store/actions/intel';
import { getAvmAdjustmentAction } from 'store/actions/radarPortfolio';

const initialState = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [getAvmAdjustmentAction as any]: (state, { payload }) => ({
      state: get(payload, 'state'),
      data: get(payload, 'data', null),
      meta: get(payload, 'meta'),
    }),
    [getEditAvmAdjustmentAction.toString()]: (state, { payload }: any) => {
      const body = payload?.meta?.body;
      const id = payload?.meta?.id;
      if (!body || !id) return state;
      const { AdjustmentValuation, Operation } = body;
      const newData: any = cloneDeep(state?.data) || [];
      const index = newData.findIndex((item) => item.Id === id);
      if (index === -1) return state;
      newData[index].AdjustmentValuation = AdjustmentValuation;
      newData[index].Operation = Operation;
      return {
        ...state,
        data: newData,
      };
    },
    [getCancelAvmAdjustmentAction.toString()]: (state, { payload }: any) => {
      const id = payload?.options?.id;
      if (!id) return state;
      const newData: any = cloneDeep(state?.data) || [];
      const index = newData.findIndex((item) => item.Id === id);
      if (index === -1) return state;
      newData.splice(index, 1);
      return {
        ...state,
        data: newData,
      };
    },
  },
  initialState,
);
