export const Briefcase = ({ stroke = '', className = '' }) => {
  return (
    <div className={className} style={{ display: 'flex' }}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8125 5.5625H4.1875C3.42811 5.5625 2.8125 6.17811 2.8125 6.9375V13.125C2.8125 13.8844 3.42811 14.5 4.1875 14.5H13.8125C14.5719 14.5 15.1875 13.8844 15.1875 13.125V6.9375C15.1875 6.17811 14.5719 5.5625 13.8125 5.5625Z"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.25 5.5625V4.1875C6.25 3.82283 6.39487 3.47309 6.65273 3.21523C6.91059 2.95737 7.26033 2.8125 7.625 2.8125H10.375C10.7397 2.8125 11.0894 2.95737 11.3473 3.21523C11.6051 3.47309 11.75 3.82283 11.75 4.1875V5.5625"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9V9.01341"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.8125 9.6875C4.73171 10.6546 6.8509 11.1584 9 11.1584C11.1491 11.1584 13.2683 10.6546 15.1875 9.6875"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
