import { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import classnames from 'classnames';
import { SearchFieldWithDebounce, Spinner } from 'components';
import { Button } from 'components-antd';

import { announcementType } from 'settings/constants/offer';
import {
  getOfferAggregateDataSelector,
  getOfferFiltersSelector,
  getOfferIsArchiveSelector,
} from 'store/selectors/offerAggregate';
import { Icons } from '../../TransactionShowings/Icons';
import {
  getOfferAggregateByUserIdEffect,
  setOffersFiltersEffect,
  setOffersIsArchiveEffect,
} from 'store/effects/offerAggregate';
import { getOfferByUUID } from 'store/effects/offers';
import { OfferModal } from '../../TransactionOffers/OfferModal';
import { MegaPhoneModal } from '../MegaPhoneModal';
import { OfferStatus } from 'types';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { OfferDetailsModal } from 'pages/Workshop/Offers/components/OfferDetailsModal';
import { CompareOffersModal } from '../CompareOffersModal';
import { setSelectedOffersForComparison } from 'store/effects';
import { getCompareOffers } from 'api/offers';
import { getUserId, getUserRolesSelector } from 'store/selectors/user';
import { CLIENT } from 'settings/constants/roles';
import { showSuccessMessage } from 'helpers/success';

import styles from './styles.module.scss';
import { OffersTable } from 'pages/Workshop/Offers/components/OffersTable';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { OfferSelectionFooter } from '../CompareOffersModal/components/OfferSelectionFooter';
import classNames from 'classnames';
import { link } from 'settings/navigation/link';
import { Filter } from 'pages/Workshop/Offers/Filter';

interface OfferListProps {
  isOffersTab?: boolean;
  transactionId?: string;
  offerUUID?: string;
  setReloadOffer?: any;
  loading?: boolean;
  isAcceptedOffer?: boolean;
}

interface locationState {
  offerId?: number;
  offerIterationUUID?: string | number;
  triggerOfferDetail?: boolean;
}

export const OffersList = ({
  isOffersTab,
  transactionId,
  offerUUID,
  setReloadOffer,
  loading,
  isAcceptedOffer,
}: OfferListProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const historyState = location.state as locationState;
  const [defaultTab, setDefaultTab] = useState('1');
  const { isArchive } = useSelector(getOfferIsArchiveSelector);
  const { offersFilter } = useSelector(getOfferFiltersSelector);

  const offerAggregate = useSelector(getOfferAggregateDataSelector);
  const [offersAggregateWithIterations, setOffersAggregateWithIterations] = useState([]);
  const { transaction } = useSelector(getTransactionSelector);
  const loggedinUserRoles = useSelector(getUserRolesSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const [offers, setOffers] = useState<any>(null);
  const [offerModal, setOfferModal] = useState(false);
  const [megPhoneModal, setMegaPhoneModal] = useState(false);
  const [offerDetailsModal, setOfferDetailsModal] = useState<any>(undefined);
  const [offerCompareModalIsOpen, setOfferCompareModalIsOpen] = useState(false);
  const userId = useSelector(getUserId);
  const [showSelection, setShowSelection] = useState(false);
  const [selectedOffers, setSelectedOffers] = useState<any>([]);
  const [totalOffersSelected, setTotalOffersSelected] = useState(selectedOffers.length);
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get('offerId');
  const active = searchParams.get('active');

  const getOfferDetails = async (UUID) => {
    if (UUID) {
      const offerDetails: any = await dispatch(getOfferByUUID(UUID));

      setOfferDetailsModal(offerDetails.OfferIteration?.[0]);
    }
  };

  useEffect(() => {
    getOfferDetails(offerUUID);
  }, [offerUUID]);

  const onOfferSelection = (offer, checked) => {
    let sOffers: any = [];

    if (!checked) {
      if (offer?.isChild) sOffers = selectedOffers.filter((item) => item?.key !== offer?.key);
      else {
        sOffers = selectedOffers.filter(
          (item) => item?.LatestOfferIteration?.Id !== offer?.LatestOfferIteration?.Id,
        );
      }
    } else {
      sOffers = [...selectedOffers, { ...offer, selected: true }];
    }

    setSelectedOffers(sOffers);
    dispatch(setSelectedOffersForComparison(sOffers));
    setTotalOffersSelected(sOffers.length);
  };

  const handleSelectOffers = (offer, checked) => {
    onOfferSelection(offer, checked);
  };

  const onArchiveModeChange = () => dispatch(setOffersIsArchiveEffect(!isArchive));

  const updateSelectedOffer = (data) => {
    const updatedOffers: any = [];

    offersAggregateWithIterations.forEach((item: any) => {
      if (item?.LatestOfferIteration?.UUID === data?.UUID) {
        updatedOffers.push({
          ...item,
          LatestOfferIteration: { ...item.LatestOfferIteration, IsViewed: true },
        });
      } else updatedOffers.push(item);
    });

    setOffersAggregateWithIterations(updatedOffers);
  };

  const onDetailsClose = () => {
    if (offerDetailsModal?.userId) {
      dispatch(
        getOfferAggregateByUserIdEffect({
          Id: userId,
          filters: {
            transactionIds: [transactionId],
          },
        }),
      );
    }
    searchParams.delete('offerId');
    searchParams.delete('active');
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
      state: historyState,
    });
    updateSelectedOffer(offerDetailsModal);
    setOfferDetailsModal(undefined);
    if (offerUUID) {
      history.push(link.toTransactionOffers(transactionId));
    }
  };

  const onOpenDetails = (data) => {
    setDefaultTab('1');
    setOfferDetailsModal(data);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(transaction?.OfferLink?.Link);
    showSuccessMessage('Link Copied');
  };

  useEffect(() => {
    dispatch(setSelectedOffersForComparison([]));
    fetchOffersForComparison(transactionId);
  }, [transactionId]);

  useEffect(() => {
    if (offerAggregate && offers) setOfferAggregateChildren(offers);
  }, [offerAggregate, offers]);

  const fetchOffersForComparison = useCallback(async (TransactionId) => {
    const { data } = await getCompareOffers({ transactionId: TransactionId });
    if (data?.value) {
      const { Pending, Rejected, Withdrawn } = data.value;
      setOffers({ Pending, Rejected, Withdrawn });

      // Setting offers aggregate children (foldable rows) -> offer iterations.
      if (offerAggregate) setOfferAggregateChildren(data?.value);
    }
  }, []);

  const setOfferAggregateChildren = (offerIterations) => {
    let updateOffersAggregate: any = [];

    if (offerAggregate && offerIterations) {
      offerAggregate?.forEach((item, key) => {
        const { OfferId } = item?.LatestOfferIteration || {};
        // NOTE: Comparing Counter Sent/Recieved offers from Pending offer
        // iterations array below, since BE groups these offers to status Pending.

        const offerStatus =
          item.OfferStatus === OfferStatus.CounterSent ||
          item.OfferStatus === OfferStatus.CounterReceived
            ? OfferStatus.Pending
            : item.OfferStatus;

        if (
          [OfferStatus.Pending, OfferStatus.Rejected, OfferStatus.Withdrawn].includes(offerStatus)
        ) {
          const transactionOffer = offerIterations?.[offerStatus]?.find(
            (i) => parseInt(i.Id) == OfferId,
          );

          if (transactionOffer) {
            const iterationsWithoutLatestIteration = transactionOffer?.OfferIteration?.filter(
              (y) => y.Id !== item?.LatestOfferIteration?.Id,
            );
            updateOffersAggregate.push(
              iterationsWithoutLatestIteration?.length > 0
                ? {
                    key,
                    ...item,
                    children: iterationsWithoutLatestIteration?.map((x, idx) => ({
                      key: `child-${x?.UUID}`,
                      title:
                        x?.IterationCount > 0
                          ? `Counter-Offer#${transactionOffer.OfferCount}-${x?.IterationCount}`
                          : `Offer#${transactionOffer.OfferCount}`,
                      OfferCount: transactionOffer.OfferCount,
                      isChild: true,
                      Amount: x?.PurchasePrice || 0,
                      BuyingAgent: { Name: transactionOffer?.BuyingAgentName },
                      ReceivingDate: x.CreatedDate,
                      index: idx + 1,
                      selected: false,
                      Buyer: transactionOffer?.Buyer,
                      ...x,
                    })),
                    OfferIteration: transactionOffer?.OfferIteration,
                    selected: false,
                    Buyer: transactionOffer?.Buyer,
                    UUID: transactionOffer?.UUID,
                    Id: transactionOffer?.Id,
                  }
                : {
                    ...item,
                    key,
                    selected: false,
                    OfferIteration: [item?.LatestOfferIteration],
                    Buyer: transactionOffer?.Buyer,
                    UUID: transactionOffer?.UUID,
                    Id: transactionOffer?.Id,
                  },
            );
          } else {
            updateOffersAggregate.push({
              ...item,
              key,
              OfferIteration: [item?.LatestOfferIteration],
              selected: false,
            });
          }
        } else
          updateOffersAggregate.push({
            ...item,
            key,
            OfferIteration: [item?.LatestOfferIteration],
            selected: false,
          });
      });
      setOffersAggregateWithIterations(updateOffersAggregate);
    }
  };

  const renderActions = () => {
    return !loggedinUserRoles?.includes(CLIENT) && fullAccess ? (
      <div className={styles.actions}>
        {transaction?.OfferLink?.Id ? (
          <>
            {transaction?.OfferLink?.EnableLink && !isAcceptedOffer && (
              <Button
                variant="hollow-bordered"
                className={classnames(styles.actionBtn, styles.copyBtn)}
                onClick={copyLink}
              >
                Copy Link
              </Button>
            )}
            <Icons
              variant={Icons.MEGA_PHONE}
              className={styles.actionBtn}
              onClick={() => setMegaPhoneModal(true)}
            />

            {!isAcceptedOffer && (
              <Icons
                variant={Icons.SETTINGS}
                className={styles.actionBtn}
                onClick={() => setOfferModal(true)}
              />
            )}
          </>
        ) : (
          <Button
            variant="hollow-bordered"
            className={classnames(styles.actionBtn, styles.enableBtn)}
            onClick={() => setOfferModal(true)}
          >
            Enable
          </Button>
        )}
      </div>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (historyState?.triggerOfferDetail === true) {
      let offerIteration = offerAggregate?.find(
        (item) => item?.LatestOfferIteration.UUID === historyState?.offerIterationUUID,
      );
      if (offerIteration?.LatestOfferIteration) {
        offerIteration = {
          ...offerIteration,
          LatestOfferIteration: {
            ...offerIteration?.LatestOfferIteration,
            IsViewed: true,
          },
        };
      }
      if (
        offerIteration?.LatestOfferIteration?.IterationStatus === OfferStatus.Withdrawn ||
        offerIteration?.LatestOfferIteration?.IterationStatus === OfferStatus.Cancelled ||
        offerIteration?.LatestOfferIteration?.IterationStatus === OfferStatus.Rejected
      ) {
        dispatch(setOffersIsArchiveEffect(true));
      }

      setOfferDetailsModal(offerIteration?.LatestOfferIteration);
    }
  }, []);

  useEffect(() => {
    if (offersAggregateWithIterations && offerId && active === 'comment') {
      const offer = offersAggregateWithIterations.find(
        (item: { Id: string }) => item?.Id && item.Id === offerId,
      );
      if (offer && (offer as any).LatestOfferIteration) {
        setOfferDetailsModal((offer as any)?.LatestOfferIteration);
        searchParams.delete('offerId');
        searchParams.delete('active');
        history.replace({
          pathname: location.pathname,
          search: searchParams.toString(),
          state: historyState,
        });
        setDefaultTab('2');
      }
    }
  }, [offersAggregateWithIterations, active, offerId]);

  return (
    <PageWrapper isBgWhite isContainer={false} isHideFooter>
      <div className={classNames(styles.offers, { [styles.offersWithFooter]: showSelection })}>
        <h2 className={styles.pageTitle}>{!isArchive ? 'Offers' : 'Archived Offers'}</h2>
        {renderActions()}

        <div className={styles.header}>
          <div className={styles.headerSection}>
            <div className={styles.searchWrapper}>
              <SearchFieldWithDebounce
                className={styles.inputWrap}
                classNameInput={styles.searchInput}
                iconClassName={styles.icon}
                resetAction={() => setOffersFiltersEffect({ ...offersFilter, searchString: '' })}
                sendAction={({ search }) =>
                  setOffersFiltersEffect({ ...offersFilter, searchString: search })
                }
                isLoading={false}
                placeholder="Search"
                testid="search"
                isTransactionAggregate={true}
                isExpandedVersion
                otherSearchParams={{ offersFilter }}
              />
            </div>
            {!isAcceptedOffer ? (
              <Fragment>
                <div className="seperator" />

                <div
                  className={classnames(styles.btn, { [styles.multselectSelected]: showSelection })}
                  onClick={() => setShowSelection(!showSelection)}
                >
                  <Icons className={styles.btnIcon} variant={Icons.MULTI_SELECT} />
                </div>
              </Fragment>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.headerSection}>
            <Filter transactionRoomId={transactionId} />

            <div className="seperator" />
            <div
              className={classnames(styles.btn, { [styles.selected]: isArchive })}
              onClick={onArchiveModeChange}
            >
              <Icons
                className={styles.btnIcon}
                variant={Icons.ARCHIVE}
                color={isArchive ? '#FFFFFF' : ''}
              />
            </div>
          </div>
        </div>

        <div>
          <OffersTable
            isTransactionRoom={true}
            onRowClick={(data) => onOpenDetails(data?.LatestOfferIteration)}
            offerAggregate={offersAggregateWithIterations}
            className={`collapsibleRowsTable ${styles.offersCollapsibleTable}`}
            showSelection={showSelection}
            setOffersSelected={handleSelectOffers}
            loading={loading}
          />
        </div>
      </div>
      <OfferModal
        isOpen={offerModal}
        onClose={() => setOfferModal(false)}
        transaction={transaction}
        // setReloadOffer={setReloadOffer}
      />

      <OfferDetailsModal
        isOpen={!!offerDetailsModal}
        onClose={onDetailsClose}
        offerData={offerDetailsModal}
        defaultTabOpen={defaultTab}
        transactionId={transactionId}
        setOfferCompareModalIsOpen={setOfferCompareModalIsOpen}
        setReloadOffer={setReloadOffer}
      />

      {!loggedinUserRoles?.includes(CLIENT) && (
        <>
          <MegaPhoneModal
            isOpen={megPhoneModal}
            onClose={() => setMegaPhoneModal(false)}
            type={announcementType.PUBLIC}
            offerLinkId={offerAggregate?.[0]?.LatestOfferIteration?.offerLinkId}
          />
        </>
      )}

      {transactionId && offerCompareModalIsOpen && (
        <CompareOffersModal
          data={offers}
          open={offerCompareModalIsOpen}
          transactionId={transactionId}
          onCloseModal={() => setOfferCompareModalIsOpen(false)}
        />
      )}

      {showSelection && (
        <OfferSelectionFooter
          selectedOffers={totalOffersSelected}
          handleContinueOfferUpdates={() =>
            history.push(`/workshop/compare-offers/${transactionId}`)
          }
        />
      )}
    </PageWrapper>
  );
};
