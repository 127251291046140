import { createSelector } from 'reselect';
import { get, filter, includes, upperCase, map, find, forEach } from 'lodash-es';

import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';

import { PENDING, READY } from 'settings/constants/apiState';

const localState = ({ partners }) => partners;

export const getLoadingPartnersData = createSelector(localState, ({ state, data, meta }) => ({
  isData: !!data,
  isPending: state === PENDING,
  metaId: get(meta, ['categories', '0']),
  isError: state === 'error',
}));

export const getLoadingPartnersSearchData = createSelector(localState, ({ state, meta }) => ({
  isPending: state === PENDING,
  isSearch: !!meta?.name,
}));

export const isLoadingPartnerRecommendation = createSelector(
  localState,
  ({ recommendState }) => recommendState === PENDING,
);

export const getAllPartnersList = createSelector(localState, ({ data, search }) =>
  filter(data || [], ({ BusinessName }) => includes(upperCase(BusinessName), upperCase(search))),
);

export const getAgentPreparedPartnersList = createSelector(
  getAllPartnersList,
  getUserId,
  getAgentTeamIdSelector,
  (list, userId, teamId) =>
    map(list, (partner) => ({
      ...partner,
      isMyRecommended: !!find(
        partner?.RecommendingAgents,
        ({ Id, TeamId }) => Id === userId || (teamId && TeamId === teamId),
      ),
    })).filter((partner) => partner?.ThirdParty?.User?.CompletedProfile),
);

export const getSearchPartnersList = createSelector(
  localState,
  getUserRolesMapSelector,
  getUserId,
  ({ data }, { isAgent }, userId) => {
    if (isAgent) {
      return map(data || [], (partner) => ({
        ...partner,
        isMyRecommended: !!find(partner?.RecommendingAgents, ({ Id }) => Id === userId),
      }));
    }
    return data || [];
  },
);

export const getAgentRecommendedPartnersList = createSelector(
  getAgentPreparedPartnersList,
  (list) => filter(list, ({ isMyRecommended }) => isMyRecommended),
);

export const getRecommendedByMosaikAndAgentLists = createSelector(localState, ({ state, data }) => {
  const recommendedByAgent = [];
  const recommendedByMosaik = [];
  forEach(data || [], (partner) => {
    if (partner?.IsRecommendedByAgent) {
      recommendedByAgent.push(partner);
    } else {
      recommendedByMosaik.push(partner);
    }
  });

  return {
    isReady: state === READY,
    recommendedByMosaik,
    recommendedByAgent,
  };
});

export const getRecommendedByAgentPartnersListForBuyer = createSelector(localState, ({ data }) =>
  filter(data || [], ({ IsRecommendedByAgent }) => IsRecommendedByAgent),
);

export const getRecommendedByMosaikPartnersListForBuyer = createSelector(localState, ({ data }) =>
  filter(data || [], ({ IsRecommendedByMosaik }) => IsRecommendedByMosaik),
);

export const getLoadingRecommendedPartnersByAgent = createSelector(
  localState,
  ({ recommendations }) => ({
    isData: !!recommendations.data,
    isPending: recommendations.state === PENDING,
  }),
);

export const getRecommendedPartnersByAgentList = createSelector(localState, ({ recommendations }) =>
  recommendations.data
    ?.map((recommendation) => ({ ...recommendation, isMyRecommended: true }))
    ?.sort((partner1, partner2) => {
      const partner1EmailVerified = get(partner1, 'ThirdParty.User.IsEmailVerified');
      const partner2EmailVerified = get(partner2, 'ThirdParty.User.IsEmailVerified');
      // sort by IsEmailVerified boolean false first
      if (partner1EmailVerified === partner2EmailVerified) {
        return 0;
      }
      if (partner1EmailVerified && !partner2EmailVerified) {
        return 1;
      }
      return -1;
    }),
);

export const showRequestQuoteBtnSelector = createSelector(
  localState,
  ({ showRequestQuoteBtn }) => showRequestQuoteBtn,
);

export const selectVendorEditModeId = createSelector(
  localState,
  ({ vendorEditModeId }) => vendorEditModeId,
);

export const selectVendorDraftEditModeId = createSelector(
  localState,
  ({ draftEditModeId }) => draftEditModeId,
);

export const selectVendorEditInviteMode = createSelector(
  localState,
  ({ vendorEditInviteMode }) => vendorEditInviteMode,
);

export const selectVendorEditStrictlyProfile = createSelector(
  localState,
  ({ vendorEditStrictlyProfile }) => vendorEditStrictlyProfile,
);
