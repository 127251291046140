import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Radio, Select } from 'components-antd';

import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import styles from './styles.module.scss';
import { VALIDATION_TYPES, fieldValidationTypes } from './helper';
import { Fragment, useState } from 'react';
import { Input } from 'components';

export const ValidationSettingsPopover = ({ field, handleFieldUpdate }) => {
  const { options, validation, id } = field;
  let { type, min, max, value: fieldValidationValue } = validation;
  const isDefaultInRange = type === VALIDATION_TYPES.InRange;

  const numberOfOptions = options.length;

  const [validationType, setValidationType] = useState(type);
  const [validationValue, setValidationValue] = useState(
    !isDefaultInRange ? fieldValidationValue : 1,
  );

  const [rangeValue, setRangeValue] = useState({
    min: isDefaultInRange ? min : 1,
    max: isDefaultInRange ? max : numberOfOptions,
  });

  const isRange = validationType === VALIDATION_TYPES.InRange;

  const onChangeValidationType = (value) => {
    const range = { min: 1, max: numberOfOptions };

    setValidationValue(1);
    setRangeValue({ min: 1, max: numberOfOptions });
    setValidationType(value);

    const isRangeValue = value === VALIDATION_TYPES.InRange;
    onFieldUpdate(value, isRangeValue ? range : null, !isRangeValue ? 1 : null);
  };

  const onFieldUpdate = (type, range?, value?) => {
    const updatedField = JSON.parse(JSON.stringify(field));
    updatedField.validation.type = type;

    if (range) {
      fieldValidationValue && delete updatedField.validation.value;

      updatedField.validation.min = +range.min;
      updatedField.validation.max = +range.max;
    } else {
      if (min || max) {
        delete updatedField.validation.min;
        delete updatedField.validation.max;
      }

      updatedField.validation.value = +value;
    }

    if (id) {
      updatedField.isUpdated = true;
      updatedField.validationUpdated = true;
    }

    handleFieldUpdate(updatedField);
  };

  const menu = (
    <Radio.Group className={styles.optionsItems}>
      <Menu>
        <MenuItem key={1}>
          <div onClick={(e) => e.stopPropagation()}>
            <div className={styles.inputBlock}>
              <div className={styles.label}>Select</div>
              <Select
                className={styles.inputHolder}
                options={fieldValidationTypes}
                value={validationType}
                onSelect={onChangeValidationType}
                placeholder={'Select'}
                getPopupContainer={(node) => node}
                popupClassName={styles.inputHolderDropdown}
                onBlur={({ target }) => {}}
              />
            </div>

            <div
              className={classNames(styles.validationValue, { [styles.rangeValidation]: isRange })}
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                type="number"
                isNumberFormat={true}
                className={styles.input}
                placeholder={''}
                inputHolderClassName={styles.inputHolder}
                variant={Input.LIGHT_ROUND}
                value={isRange ? rangeValue.min : validationValue}
                minNumber={1}
                onChange={({ target }) => {
                  if (!isRange) {
                    setValidationValue(
                      target.value > numberOfOptions ? numberOfOptions : target.value,
                    );
                  }
                }}
                onBlur={({ target }) => {
                  let value = target.value;
                  if (isRange) {
                    const range = { ...rangeValue };

                    if (value >= rangeValue.max) {
                      value = rangeValue.max - 1;
                    }

                    range.min = value <= 0 ? 1 : +value;
                    setRangeValue(range);
                    onFieldUpdate(validationType, range);
                  } else {
                    const validationTypeValue = value > numberOfOptions ? numberOfOptions : value;
                    setValidationValue(validationTypeValue);
                    onFieldUpdate(validationType, undefined, validationTypeValue);
                  }
                }}
              />

              {isRange && (
                <Fragment>
                  <div className={styles.separator}> to </div>

                  <Input
                    type="number"
                    isNumberFormat={true}
                    className={styles.input}
                    placeholder={''}
                    inputHolderClassName={styles.inputHolder}
                    variant={Input.LIGHT_ROUND}
                    value={rangeValue.max}
                    onBlur={(e) => {
                      let value = e.target.value;
                      const range = { ...rangeValue };

                      if (value <= rangeValue.min) {
                        value = rangeValue.min + 1;
                      }

                      range.max = +value > numberOfOptions ? numberOfOptions : +value;

                      setRangeValue(range);
                      onFieldUpdate(validationType, range);
                    }}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </MenuItem>
      </Menu>
    </Radio.Group>
  );

  return (
    <div
      className={classNames(styles.settingsDropdownWrapper)}
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown
        overlay={menu}
        placement="bottomCenter"
        trigger={['click']}
        overlayClassName={styles.settingPopover}
      >
        <div className={styles.settingInformation}>
          <Icons variant={ICON_VARIANT_TYPE.Setting} />
        </div>
      </Dropdown>
    </div>
  );
};
