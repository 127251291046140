import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Select, Option } from 'components-antd';
import Spinner from 'components/Spinner';
import InputLabel from 'components/Form/InputLabel';
import { getEmailTemplates } from 'api/templates/email';
import { showErrorMessage } from 'helpers/errors';
import { Check, OpenBook } from 'components/Icons';
import { EmailTemplateModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/EmailTemplateModal';

import styles from './styles.module.scss';

interface TaskEmailTemplateProps {
  onSelect: (value) => void;
  value?: EmailTemplateType | undefined;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showAsterisk?: boolean;
  addEmailTemplate?: boolean;
  showOptional?: boolean;
  selectClassName?: string;
  className?: string;
}

type EmailTemplateOptionsType = {
  value: number;
  name: string;
  label: string;
  Id: number;
  CurrentVersion: number;
  Subject: string;
  Body: string;
  PublishToTeam: boolean;
  EmailTemplateId: number;
  EmailTemplateVersionId: number;
};

type EmailTemplateType = {
  Id: number;
  CurrentVersion: number;
  Subject: string;
  Body: string;
  PublishToTeam: boolean;
};

export const EmailTemplateInput = ({
  onSelect,
  value,
  label = 'Email Template',
  placeholder = 'Select an Email Template',
  disabled = false,
  showAsterisk = false,
  addEmailTemplate = true,
  showOptional = false,
  className,
}: TaskEmailTemplateProps) => {
  const [emailTemplateOptions, setEmailTemplateOptions] = useState<EmailTemplateOptionsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [viewEmailTemplate, setViewEmailTemplate] = useState(false);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    EmailTemplateOptionsType | undefined
  >(undefined);

  const fetchEmailTemplates = async () => {
    setLoading(true);
    try {
      const resp = await getEmailTemplates();
      const emailTemplates = resp?.data?.value;
      const emailTemplatesOptions = emailTemplates.map((template) => ({
        value: template.Id,
        label: template.Subject,
        name: template.Subject,
        EmailTemplateId: template.Id,
        EmailTemplateVersionId: template.CurrentVersion,
        ...template,
      }));
      setEmailTemplateOptions(emailTemplatesOptions);
    } catch (e) {
      showErrorMessage(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  useEffect(() => {
    if (value) {
      setSelectedEmailTemplate({
        value: value.Id,
        label: value.Subject,
        name: value.Subject,
        EmailTemplateId: value.Id,
        EmailTemplateVersionId: value.CurrentVersion,
        ...value,
      });
    } else {
      setSelectedEmailTemplate(undefined);
    }
  }, [value]);

  return (
    <div className={classNames(styles.emailTemplateSelectorContainer, className)}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {addEmailTemplate && !loading ? (
            <>
              <InputLabel
                label={label}
                className={styles.label}
                showAsterisk={showAsterisk}
                showOptional={showOptional}
              />
              <div className={styles.selectionContainer}>
                <Select
                  className={classNames(styles.emailTemplate, className)}
                  placeholder={placeholder}
                  disabled={disabled}
                  options={emailTemplateOptions}
                  onSelect={(e, selected) => {
                    onSelect(selected);
                    setSelectedEmailTemplate(selected);
                  }}
                  value={selectedEmailTemplate}
                  filterOption={(input, option) =>
                    String((option?.label as unknown as string) || '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  menuItemSelectedIcon={<Check color="#04A451" />}
                  showArrow={true}
                  large={true}
                  popupClassName={styles.emailtTemplateSelectDropdown}
                  getPopupContainer={(triggerNode) => triggerNode}
                  placement="bottomLeft"
                  dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
                  showSearch
                >
                  {emailTemplateOptions ? (
                    emailTemplateOptions.map((el, i) => (
                      <Option value={el.value} key={`email-template-${el.value}`}>
                        {el.name}
                      </Option>
                    ))
                  ) : (
                    <Option value={''} key={`email-template-${0}`}>
                      Loading...
                    </Option>
                  )}
                </Select>
                <div
                  className={classNames(styles.viewEmailTemplate, {
                    [styles.disabled]: !value?.Subject,
                  })}
                  onClick={() => setViewEmailTemplate(true)}
                >
                  <OpenBook />
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
      <EmailTemplateModal
        open={viewEmailTemplate}
        onClose={() => setViewEmailTemplate(false)}
        onRemove={() => {
          onSelect({
            EmailTemplateId: null,
            EmailTemplateVersionId: null,
          });
          setSelectedEmailTemplate(undefined);
          setViewEmailTemplate(false);
        }}
        subjectText={value?.Subject}
        bodyText={value?.Body}
      />
    </div>
  );
};
