import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'components-antd';
import { TransactionCloseComponentIds } from 'app-constants';
import { getTransactionSelector } from 'store/selectors/transaction';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';
import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Checkbox, Spinner } from 'components';
import { getClientDetailsEffect } from 'store/effects';
import { TableColumnsType } from 'antd';
import { addClientToCampaignsEffect, requestGetCampaignsEffect } from 'store/effects/radarKits';
import { getCampaignsSelector } from 'store/selectors/radarKits';
import { Table } from 'components-antd';
import { retainClient } from 'api/user';
import { KitActivationDataType as DataType } from './types';
import { getAddressFormattedCassAddress } from 'helpers/cass';

interface KitsActivationProps {
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
  clientAddress: {
    ClientId: number;
    Address: Record<string, any>;
  } | null;
}

export const KitsActivation = ({ previousStage, setStage, clientAddress }: KitsActivationProps) => {
  const dispatch = useDispatch();
  const [clientDetails, setClientDetails] = useState<unknown>(null);
  const [clientAddressDetails, setClientAddressDetails] = useState<any>(null);
  const { transaction } = useSelector(getTransactionSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: campaignsList } = useSelector(getCampaignsSelector);
  const [selectedCampaigns, setSelectedCampaigns] = useState<number[]>([]);

  const selectCampaignHandler = useCallback((campaignId: number) => {
    setSelectedCampaigns((prev) => {
      if (prev.includes(campaignId)) {
        return prev.filter((id) => id !== campaignId);
      }
      return [...prev, campaignId];
    });
  }, []);

  const setClientCampaigns = useCallback(async () => {
    if (clientAddress?.ClientId && clientAddressDetails?.Id && selectedCampaigns?.length) {
      retainClient(clientAddress.ClientId, { retain: true });
      dispatch(
        addClientToCampaignsEffect(
          {
            ClientId: clientAddress.ClientId,
            AddressId: clientAddressDetails.Id,
            CampaignIds: selectedCampaigns,
          },
          { silent: true },
          (err, res) => {
            if (!err) {
              setStage(TransactionCloseComponentIds.CloseConfirmation);
            }
          },
        ),
      );
    } else {
      setStage(TransactionCloseComponentIds.CloseConfirmation);
    }
  }, [selectedCampaigns, clientAddress?.ClientId, clientAddressDetails?.Id]);

  useEffect(() => {
    if (clientAddress?.ClientId && clientAddress?.Address?.address) {
      setLoading(true);
      dispatch(
        requestGetCampaignsEffect({}, { silent: true }, (err, res) => {
          if (!err) {
            const campaigns = res?.data?.result || [];
            const hasActiveCampaigns = campaigns.some((campaign) => campaign.Active);
            if (!hasActiveCampaigns) {
              setStage(TransactionCloseComponentIds.CloseConfirmation);
            }
          }
        }),
      );
      dispatch(
        getClientDetailsEffect({ id: clientAddress?.ClientId }, { silent: true }, (err, res) => {
          if (!err) {
            setClientDetails(res.data.result);
            const clientAddressDetails = res.data.result?.Client?.Address.find(
              (address) =>
                address?.address?.delivery_line_1 ===
                  clientAddress?.Address?.address?.delivery_line_1 &&
                address?.address?.last_line === clientAddress?.Address?.address?.last_line,
            );
            setClientAddressDetails(clientAddressDetails ?? null);
          } else {
            setStage(TransactionCloseComponentIds.CloseConfirmation);
          }
          setLoading(false);
        }),
      );
    } else {
      if (previousStage === TransactionCloseComponentIds.ClientProfileAddAddress) {
        setStage(TransactionCloseComponentIds.CloseConfirmation);
      } else if (previousStage === TransactionCloseComponentIds.CloseConfirmation) {
        setStage(TransactionCloseComponentIds.ClientProfileAddAddress);
      }
    }
  }, [clientAddress]);

  const campaignListDataSource = useMemo(() => {
    if (!campaignsList) return { active: [], inactive: [] };
    const list = campaignsList?.map<DataType>((campaign) => ({
      key: campaign.Id,
      agentId: campaign.AgentId,
      teamId: campaign.TeamId,
      name: {
        title: campaign.Name,
        info: campaign.Description,
      },
      clients: {
        count: campaign.CampaignMembers.length,
      },
      selectedMembers: campaign.CampaignMembers.map(({ ClientId }) => ClientId),
      isActive: campaign.Active,
    }));

    return {
      active: list.filter((item) => item.isActive),
      inactive: list.filter((item) => !item.isActive),
    };
  }, [campaignsList]);

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Sequence',
      dataIndex: 'name',
      key: 'name',
      className: 'nameColumn',
      sorter: (a, b) => a.name.title.localeCompare(b.name.title),
      width: '90%',
      render: (group: any) => (
        <div className={styles.tableColNameWrapper}>
          <div className={styles.detailsWrapper}>
            <div className={styles.title}>{group.title}</div>
            <div className={styles.info}>{group.info}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Enable',
      dataIndex: 'isActive',
      key: 'isActive',
      className: styles.tableCol,
      render: (isActive: boolean, record: DataType) => (
        <div>
          <Checkbox
            checked={selectedCampaigns.includes(record.key)}
            className={styles.checkbox}
            type="radio"
            hasOuterClick={true}
            onChange={(e) => {
              e.preventDefault();
              selectCampaignHandler(record.key);
            }}
          />
        </div>
      ),
    },
  ];

  const formattedAddress = useMemo(() => {
    const address = clientAddress?.Address?.address;
    const { address1, address2 } = getAddressFormattedCassAddress(address);
    if (!address1) return '';
    return `${address1}, ${address2}`;
  }, [clientAddress]);

  return clientAddress?.ClientId ? (
    <div className={styles.kitsActivation}>
      <Question>Do you want to enable a KIT sequence?</Question>
      {loading ? (
        <Spinner loaderClassName={styles.loader} />
      ) : (
        <>
          <AnswersContainer className={styles.answers}>
            <Space size="middle" className={styles.spacing} direction="vertical">
              <p className={styles.text}>
                Based on the selected sequence, associated KIT activities will go into effect for:
                <br />
                <span className={styles.info}>{formattedAddress}.</span>
                <br />
                You can change or disable the sequence at any time in Radar.
              </p>
              <div className={styles.KitsTable}>
                <Table
                  dataSource={campaignListDataSource.active}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 600 }}
                />
              </div>
            </Space>
          </AnswersContainer>
          <ButtonsContainer>
            <Continue onClick={() => setClientCampaigns()} className={styles.submit} />
          </ButtonsContainer>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};
