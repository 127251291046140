import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { deleteMultipleTasks, updateMultipleTasks } from 'api/transactions';
import { showErrorMessage } from 'helpers';
import {
  MultiTaskUpdateModal,
  UpdatedTaskEntities,
} from 'pages/Workshop/Transactions/components/MultiTaskUpdateModal';
import { TaskSelectionFooter } from 'pages/Workshop/Transactions/components/TaskSelectionFooter';
import {
  getTransactionEffect,
  setTransactionTaskParamsEffect,
  setTransactionTasksLastStateEffect,
} from 'store/effects/transactions';
import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { Actions, TasksList, Title } from './components';
import { SearchFieldWithDebounce } from 'components';

import { PageWrapper } from 'pages/Workshop/PageWrapper';
import styles from './styles.module.scss';
import {
  resetTransactionsTasksSearchEffect,
  searchTransactionsTasksEffect,
  getTaskAggregateEffect,
} from 'store/effects/taskAggregate';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';

interface TasksProps {
  className?: string;
}

const Tasks = ({ className }: TasksProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params: { id?: string } = useParams();
  const history = useHistory();
  const { isTaskForm } = useSelector(getTransactionTaskParamsSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const [showSelection, setShowSelection] = useState<boolean>(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);
  const [showTaskUpdateModal, setShowTaskUpdateModal] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get('active');
  const taskId = searchParams.get('taskId');
  useEffect(() => {
    setSelectedTaskIds([]);
  }, [showSelection]);

  useEffect(() => {
    dispatch(
      getTaskAggregateEffect({
        filters: {
          transactionRoomId: params.id,
          isProject: transaction.IsProject || location?.pathname?.includes('projects'),
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (activeTab === 'comment' && taskId && params.id) {
      searchParams.delete('active');
      searchParams.delete('taskId');
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: location.state,
      });
      dispatch(
        setTransactionTaskParamsEffect({
          taskId,
          transactionId: Number(params.id),
          campaignId: null,
          isTaskDetail: true,
        }),
      );
    }
  }, [activeTab, taskId]);
  const handleSelectTask = (taskId: number | number[], checked: boolean) => {
    let selectedTasks = [...selectedTaskIds];
    const isArray = Array.isArray(taskId);
    if (checked) {
      isArray ? selectedTasks.push(...taskId) : selectedTasks.push(taskId);
    } else {
      if (!isArray) {
        const deselectedTaskIndex = selectedTasks.indexOf(taskId);
        selectedTasks.splice(deselectedTaskIndex, 1);
      } else {
        selectedTasks = selectedTasks.filter((id) => !taskId.includes(id));
      }
    }

    setSelectedTaskIds(selectedTasks);
  };

  const handleContinueTaskUpdates = () => {
    setShowTaskUpdateModal(true);
  };

  const handleUpdateTasks = async (
    updatedTaskEntities: UpdatedTaskEntities,
    setLoading: (loading: boolean) => void,
  ) => {
    setLoading(true);
    const tasks = selectedTaskIds?.map((taskId) => ({ Id: taskId, ...updatedTaskEntities }));
    try {
      await updateMultipleTasks({ tasks });
      dispatch(getTransactionEffect({ id: params.id }, { silent: true }));
      dispatch(
        getTaskAggregateEffect(
          {
            filters: {
              transactionRoomId: params.id,
              isProject: transaction.IsProject || location?.pathname?.includes('projects'),
            },
          },
          { silent: true },
        ),
      );
    } catch (err) {
      showErrorMessage(err);
    }
    setShowTaskUpdateModal(false);
    setShowSelection(false);
    setLoading(false);
  };

  const handleDeleteTasks = async (
    setLoading: (loading: boolean) => void,
    setShowModal: (open: boolean) => void,
  ) => {
    setLoading(true);
    try {
      await deleteMultipleTasks(selectedTaskIds);
      dispatch(getTransactionEffect({ id: params.id }, { silent: true }));
      setShowModal(false);
      setShowTaskUpdateModal(false);
      setShowSelection(false);
    } catch (err) {
      showErrorMessage(err);
    }
    setLoading(false);
  };

  return (
    <PageWrapper isBgWhite isContainer={false} isHideFooter>
      <MultiTaskUpdateModal
        fullAccess={fullAccess}
        showTaskUpdateModal={showTaskUpdateModal}
        setShowTaskUpdateModal={setShowTaskUpdateModal}
        handleUpdateTasks={handleUpdateTasks}
        handleDeleteTasks={handleDeleteTasks}
      />

      <div className={classNames(styles.tasks, { [styles.disable]: isTaskForm }, className)}>
        <Title className={styles.title}>{showSelection ? 'Select Tasks' : 'Tasks'}</Title>
        <div className={styles.head}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={resetTransactionsTasksSearchEffect}
              sendAction={searchTransactionsTasksEffect}
              isLoading={false}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
            />
          </div>
          <Actions showSelection={showSelection} setShowSelection={setShowSelection} />
        </div>

        <div
          className={classNames({
            [styles.taskList]: !showSelection,
            [styles.multiSelectTaskList]: showSelection,
          })}
        >
          <TasksList
            showSelection={showSelection}
            handleSelectTask={handleSelectTask}
            selectedTasks={selectedTaskIds}
            showSelectAll
          />
        </div>
      </div>

      {showSelection && (
        <TaskSelectionFooter
          isTransactionTaskFooter
          selectedTasks={selectedTaskIds?.length}
          handleContinueTaskUpdates={handleContinueTaskUpdates}
        />
      )}
    </PageWrapper>
  );
};

export default Tasks;
