import React from 'react';
import classNames from 'classnames';

type KitReplyInnerIconProps = {
  className?: string;
  color?: string;
};

export const KitReplyInnerIcon: React.FC<KitReplyInnerIconProps> = (props) => {
  const { className, color = '#51BFE1' } = props;
  return (
    <div className={classNames(className)}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" fill="white" />
        <circle cx="10" cy="10" r="9.5" stroke="black" strokeOpacity="0.1" />
        <path
          d="M13.0008 13.6002V11.8002C13.0008 10.4747 11.9263 9.4002 10.6008 9.4002H5.80078M5.80078 9.4002L8.50078 6.7002M5.80078 9.4002L8.50078 12.1002"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
