import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPropertyAddress, convertNameToAvatarPlaceholder } from 'helpers';
import { openNotificationsDrawerEffect } from 'store/effects/drawers/notifications';
import { link } from 'settings/navigation/link';
import { setTransactionTasksLastStateEffect } from 'store/effects/transactions';
import styles from './styles.module.scss';
import BaseNotification from '../../../BaseNotification';

const NewNoteComment = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, Ids, IsRead, CreatedDate, Payload, groupType, title, SeverityLevel } = props;
  const {
    SenderFirstName,
    FirstName,
    LastName,
    TransactionId,
    SenderLastName,
    Address,
    CommentId,
  } = Payload || {};
  const getSubtitle = () => getPropertyAddress(Address);
  const SentBy = `${SenderFirstName || FirstName} ${SenderLastName || LastName}`;

  const onClickHandler = () => {
    if (TransactionId) {
      dispatch(openNotificationsDrawerEffect({ open: false }));

      history.replace(`${link.toTransactionOverview(TransactionId)}?active=note`);
    }
  };

  return (
    <div className={classNames(styles.notificationType, className)}>
      <BaseNotification
        ids={Ids}
        isRead={IsRead}
        title={title}
        subtitle={getSubtitle()}
        date={CreatedDate}
        groupType={groupType}
        onClick={onClickHandler}
        avatarPlaceholder={convertNameToAvatarPlaceholder(SentBy)}
        severityLevel={SeverityLevel}
      />
    </div>
  );
};

NewNoteComment.propTypes = {
  Ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  Payload: PropTypes.shape({
    SenderFirstName: PropTypes.string,
    SenderLastName: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    TransactionId: PropTypes.number,
    Address: PropTypes.shape({
      Line1: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  SeverityLevel: PropTypes.string,
};

NewNoteComment.defaultProps = {
  className: '',
};

export default NewNoteComment;
