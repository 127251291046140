import { useCallback } from 'react';

export const useReplyKitModal = () => {
  const parsedDate = useCallback((textBody: string | undefined) => {
    const datePattern = /On \w{3}, (\w{3} \d{1,2}, \d{4}) at (\d{1,2}):(\d{2})\s?(AM|PM)/i;
    const match = (textBody || '').match(datePattern);

    if (!match?.length) {
      return null;
    }

    const [_, dateStr, hour, minute, period] = match;

    let date = new Date(`${dateStr} ${hour}:${minute} ${period}`);

    let hours = date?.getHours()?.toString()?.padStart(2, '0');
    let minutes = date?.getMinutes()?.toString()?.padStart(2, '0');
    let seconds = date?.getSeconds()?.toString()?.padStart(2, '0');

    let formattedDate = `${date?.getDate()?.toString()?.padStart(2, '0')}/${(date?.getMonth() + 1)
      ?.toString()
      ?.padStart(2, '0')}/${date?.getFullYear()}, ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }, []);

  const cleanBodyText = useCallback((textBody: string | undefined) => {
    if (!textBody) return '';
    return textBody.split(/\nOn\s\w{3},\s\w{3}\s\d{1,2},\s\d{4}\sat\s\d{1,2}:\d{2}/)[0].trim();
  }, []);

  const extractNameFromEmail = useCallback((from: string) => {
    if (!from) return '';
    const name = from.split('<')[0];
    return name.replace(/"/g, '').trim();
  }, []);

  const extractEmailViaFrom = useCallback((from: string) => {
    if (!from) return '';
    return from?.split?.('<')?.[1]?.split?.('>')?.[0]?.trim?.();
  }, []);

  return {
    cleanBodyText,
    extractNameFromEmail,
    extractEmailViaFrom,
    parsedDate,
  };
};
