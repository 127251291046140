import { cloneDeep, get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { requestGetTopRatedPropertiesAction } from 'store/actions/feedv3';
import { appLogoutAction } from 'store/actions/app';
import { requestToggleFavoriteListingAction } from 'store/actions/listingDetail';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetTopRatedPropertiesAction as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestToggleFavoriteListingAction as any]: (state, { payload }) => {
      const id = get(payload, 'meta.id');
      const isFavorite = get(payload, 'options.IsFavorite');
      const newData: any = cloneDeep(state?.data) || { items: [] };
      if (Array.isArray(id)) {
        id.forEach((_id) => {
          const favoriteIndex = newData?.items.findIndex((item) => item?.Id === _id);

          if (favoriteIndex !== -1) {
            newData.items[favoriteIndex].IsFavorite = isFavorite;
          }
        });
      } else {
        const favoriteIndex = newData?.items.findIndex((item) => item?.Id === id);

        if (favoriteIndex !== -1) {
          newData.items[favoriteIndex].IsFavorite = isFavorite;
        }
      }

      return {
        ...state,
        data: newData,
      };
    },
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
