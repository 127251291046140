import { Col, Row } from 'components-antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getFavoritesPropertiesV3Effect,
  getHighlightPropertiesV3Effect,
  getTopMatchesPropertiesV3Effect,
} from 'store/effects/feedv3';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getFeedSortSelector, getSubFilterTypeSelector } from 'store/selectors/feed';
import {
  getFavoritesPropertiesList,
  getFavoritesPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
  getHighlightsPropertiesList,
  getHighlightsPropertiesMarkersSelector,
  getSavedSearchesPropertiesList,
  getSavedSearchesPropertiesMarkersSelector,
  getTopMatchesPropertiesMarkersSelector,
  getTopMatchPropertiesList,
} from 'store/selectors/feedv3';
import ClientProperties from './components/ClientProperties';
import Map from '../common/Map';
import { Spinner } from 'components';

import { EDIT } from 'settings/constants/mode';
import {
  selectedSearchAction,
  setCriteriaAction,
  setCriteriaModeAction,
  setIsSimpleHeaderTitleVisibleAction,
} from 'store/actions/feedv3';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import ClientSearch from '../ClientSearch';
import { FilterDrawer } from '../Feed/Drawers';
import { ClientHeader } from './components/ClientHeader';
import { ViewSwitcher } from './ViewSwitcher/ViewSwitcher';
import { useSearchInstanceV3Effect } from './hooks/useSearchInstanceV3Effect';
import styles from './styles.module.scss';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import {
  getSearchInstancesEffect,
  sortFeedEffect,
  updateSearchInstanceEffect,
} from 'store/effects';
import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import classNames from 'classnames';
import { SORT_DESC } from 'settings/constants/sort';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { useHistory } from 'react-router-dom';

const ClientFeed = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;
  const isFeed = pathname === '/properties/feed';
  const urlParams = new URLSearchParams(window.location.search);
  const subFilter = urlParams.get('subFilter');
  const searchId = urlParams.get('searchId');
  const sort = useSelector(getFeedSortSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { selectedSearch, isSimpleHeaderTitleVisible } = useSelector(getFeedMetaSelectorV3);
  const searchContext = useSelector(getCurrentContextSelector);
  const getSearchInstanceV3 = useSearchInstanceV3Effect();
  const [showSavedSearches, setShowSavedSearches] = useState<boolean>(false);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const [viewType, setViewType] = useState('Split View');
  const {
    properties,
    isPending: savedPropertiesLoading,
    totalCount,
  } = useSelector(getSavedSearchesPropertiesList);
  const {
    properties: favProperties,
    isPending: favPropertiesLoading,
    totalCount: favTotalCount,
  } = useSelector(getFavoritesPropertiesList);
  const {
    properties: topMatchProperties,
    isPending: topMatchPropertiesLoading,
    totalCount: topMatchTotalCount,
  } = useSelector(getTopMatchPropertiesList);
  const {
    properties: highlightsProperties,
    isPending: highlightsPropertiesLoading,
    totalCount: highlightsTotalCount,
  } = useSelector(getHighlightsPropertiesList);
  const propertiesMarkers = useSelector(getSavedSearchesPropertiesMarkersSelector);
  const favPropertiesMarkers = useSelector(getFavoritesPropertiesMarkersSelector);
  const highlightPropertiesMarkers = useSelector(getHighlightsPropertiesMarkersSelector);
  const topMatchPropertiesMarkers = useSelector(getTopMatchesPropertiesMarkersSelector);

  const { isIdle, data: savedSearches, loaded } = useSelector(getSearchInstancesFormattedSelector);

  const isFavorites =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites';
  const isHighlights =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Highlights';
  const isTopMatches =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Top Matches';

  const setIsSimpleHeaderTitleVisible = (state) => {
    dispatch(setIsSimpleHeaderTitleVisibleAction({ isSimpleHeaderTitleVisible: state }));
  };

  const noActiveSavedSearches = useMemo(() => {
    return (
      savedSearches?.filter((item) => item?.status === SEARCH_INSTANCE_STATUS.ACTIVE)?.length < 1
    );
  }, [savedSearches]);

  useEffect(() => {
    if (
      searchContext &&
      !(isFirstRender && isLoading) &&
      !(isFirstRender && isIdle) &&
      !(isFirstRender && subFilter && subFilter !== 'Recommended')
    ) {
      isFavorites
        ? fetchFavoriteProperties()
        : isTopMatches
        ? fetchTopMatchProperties()
        : isHighlights || (isFirstRender && subFilter && subFilter === 'Recommended')
        ? fetchHighlightProperties()
        : getSearchInstanceV3(selectedSearch);
    }
  }, [
    subFilterType,
    JSON.stringify(sort),
    JSON.stringify(selectedSearch),
    JSON.stringify(searchContext),
  ]);

  useEffect(() => {
    if (!isLoading) {
      if (subFilter) {
        if (subFilter === 'Recommended') {
          dispatch(
            setIsSimpleHeaderTitleVisibleAction({
              isSimpleHeaderTitleVisible: { value: true, text: 'Highlights' },
            }),
          );
        } else {
          dispatch(
            setIsSimpleHeaderTitleVisibleAction({
              isSimpleHeaderTitleVisible: { value: false, text: '' },
            }),
          );
          dispatch(setSubFilterTypeAction(subFilter));
        }
      }
      dispatch(setCriteriaModeAction({ mode: EDIT }));
      dispatch(sortFeedEffect({ order: SORT_DESC }));
    }
  }, []);

  const fetchFavoriteProperties = () => {
    dispatch(
      getFavoritesPropertiesV3Effect({
        queryParams: {
          sortField: sort?.fields?.[0],
          sortOrder: sort?.order,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
  };

  const fetchTopMatchProperties = () => {
    dispatch(
      getTopMatchesPropertiesV3Effect({
        queryParams: {
          sortField: sort?.fields?.[0],
          sortOrder: sort?.order,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
  };

  const fetchHighlightProperties = () => {
    dispatch(
      getHighlightPropertiesV3Effect({
        queryParams: {
          sortField: sort?.fields?.[0],
          sortOrder: sort?.order,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
  };

  const isLoading =
    favPropertiesLoading ||
    savedPropertiesLoading ||
    highlightsPropertiesLoading ||
    topMatchPropertiesLoading;

  let propertyProps = {
    isPending: isLoading,
    properties: isFavorites
      ? favProperties
      : isHighlights
      ? highlightsProperties
      : isTopMatches
      ? topMatchProperties
      : properties,
  };

  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const cfg = { contextKey: searchContext?.ContextKey };

  const fetchSavedSearches = ({ fetchActiveSearch }) => {
    dispatch(
      getSearchInstancesEffect(cfg, {}, (err, result) => {
        setIsFirstRender(false);
        if (!err && fetchActiveSearch) {
          const savedSearches = result?.data?.result?.filter(
            (item) => item?.Status === SEARCH_INSTANCE_STATUS.ACTIVE,
          );
          if (savedSearches?.length > 0) {
            const data = searchId
              ? savedSearches.find((s) => s?.Id === Number(searchId))
              : savedSearches?.[0];
            const item = {
              id: data?.Id,
              name: data?.Name,
              criterias: data?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
          }
        }
      }),
    );
  };

  useEffect(() => {
    if (searchContext) {
      fetchSavedSearches({
        fetchActiveSearch:
          !isLoading &&
          !(isFavorites || isHighlights || isTopMatches) &&
          (subFilter ? subFilter !== 'Recommended' : true),
      });
    }
  }, [searchContext?.ContextKey]);

  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const getViewType = (value) => {
    setViewType(value);
  };

  const editModeCallback = (err) => {
    if (!err) {
      dispatch(
        getSearchInstancesEffect(cfg, {}, (err, result) => {
          if (!err) {
            const searchActive = result?.data?.result?.find(
              (item) => item?.Id === selectedSearch?.id,
            );
            const item = {
              id: searchActive?.Id,
              name: searchActive?.Name,
              criterias: searchActive?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
          }
        }),
      );
    }
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = {
      ...criteria,
      Locations: [polygon],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err)));
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  let mapProps = {
    disableDraw: isFavorites || isHighlights || isTopMatches,
    markers: isFavorites
      ? favPropertiesMarkers
      : isHighlights
      ? highlightPropertiesMarkers
      : isTopMatches
      ? topMatchPropertiesMarkers
      : propertiesMarkers,
    properties: isFavorites
      ? favProperties
      : isHighlights
      ? highlightsProperties
      : isTopMatches
      ? topMatchProperties
      : properties,
    totalCount: isFavorites
      ? favTotalCount
      : isHighlights
      ? highlightsTotalCount
      : isTopMatches
      ? topMatchTotalCount
      : totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
  };

  return (
    <div className={styles.clientFeedWrapper}>
      {!isFirstRender ? (
        <>
          {loaded && noActiveSavedSearches ? (
            <ClientSearch
              onClose={() => setIsOpenSearchModal(false)}
              hideCloseButton={noActiveSavedSearches}
              showSavedSearches={showSavedSearches}
              setShowSavedSearches={setShowSavedSearches}
            />
          ) : (
            <>
              <ClientHeader
                isSimpleHeaderTitleVisible={isSimpleHeaderTitleVisible}
                setIsSimpleHeaderTitleVisible={setIsSimpleHeaderTitleVisible}
                setIsOpenSearchModal={setIsOpenSearchModal}
                setShowSavedSearches={setShowSavedSearches}
                setShowBlurBg={(value) => setShowBlurBg(value)}
                isPending={propertyProps?.isPending}
              />
              <Row className={styles.clientFeed}>
                {showBlurBg && <div className={styles.backdropBlur} />}
                {viewType !== 'List Only' && (
                  <Col
                    md={viewType === 'Split View' ? 11 : 24}
                    sm={24}
                    xs={24}
                    className={styles.mapContainer}
                  >
                    <Map {...mapProps} />
                  </Col>
                )}
                {viewType !== 'Map Only' && (
                  <Col
                    md={viewType === 'Split View' ? 13 : 24}
                    sm={24}
                    xs={24}
                    className={styles.propertiesContainer}
                  >
                    <ClientProperties
                      {...propertyProps}
                      viewType={viewType}
                      searchInstanceId={
                        isFeed && selectedSearch?.id ? selectedSearch.id : undefined
                      }
                      isFavorites={isFavorites}
                    />
                    <FilterDrawer />
                  </Col>
                )}
              </Row>
              <div className={styles.mapSwitchers}>
                <ViewSwitcher getView={getViewType} />
              </div>
              {isOpenSearchModal && (
                <ClientSearch
                  onClose={() => setIsOpenSearchModal(false)}
                  showSavedSearches={showSavedSearches}
                  setShowSavedSearches={setShowSavedSearches}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className={styles.spinner}>
            <Spinner loaderClassName={classNames(styles.loader)} />
          </div>
        </>
      )}
    </div>
  );
};

export default ClientFeed;
