import styles from './styles.module.scss';
import { Rnd } from 'react-rnd';
import { GROUP_PADDING } from '../../../EditorPage';

import { calculateGroupSize } from '../../helper';
import { useDispatch } from 'react-redux';
import { selectDynamicFormFieldEffect } from 'store/effects/formProcess';
import { DynamicFormSelectedField } from 'types';

import { RadioItem } from './RadioItem';
import { AddRadioIcon } from './AddRadioIcon';
import { RemoveRadioIcon } from './RemoveRadioIcon';
import { useMemo, useState } from 'react';
import { getGroupFieldAnswers } from './helper';
import { RadioIcon } from './RadioIcon';

export const GroupField = (props) => {
  const { field, color, scale, handleFieldUpdate, documentIndex, fieldItemProps, isTemplate } =
    props;

  const {
    options,
    isHighlighted,
    customRole,
    groupOptionUUID,
    pageNumber,
    groupBorderWidth,
    UUID: groupFieldUUID,
  } = field;

  const { responses: answers } = fieldItemProps;

  const dispatch = useDispatch();

  const [groupAnswers, setGroupAnswers] = useState(getGroupFieldAnswers(field, answers));

  const getFieldStyle = (fieldItem, isOptionHighlighted) => {
    const width = fieldItem.width * scale;
    const height = fieldItem.height * scale;

    return {
      border: `2px solid ${color?.border}`,
      background: isOptionHighlighted ? '#fff' : color?.background,
      width,
      height,
      borderRadius: '6px',
    };
  };

  const updateGroupField = (groupOptions, fieldProps = {}) => {
    const groupField = { ...field, ...fieldProps };

    const { width, height }: any = calculateGroupSize({ options: groupOptions }, scale);

    const resizedWidth = width / scale;
    const resizedHeight = height / scale;

    const isGroupResized = resizedWidth !== field.width || resizedHeight !== field.height;

    groupField.width = resizedWidth;
    groupField.height = resizedHeight;

    handleFieldUpdate({
      ...groupField,
      options: groupOptions,
      ...(field.id && { isGroupResized }),
    });
  };

  const handleOptionUpdate = (updatedProps, index) => {
    const groupOptions = [...options];

    let { x, y, ...restUpdatedProps } = updatedProps;
    const groupField = { ...field };

    const { x: optionX, y: optionY, id: savedOptionId } = groupOptions[index];

    const scaledX = x / scale;
    const scaledY = y / scale;

    if (x < 0 && y < 0) {
      groupField.x = groupField.x + scaledX - GROUP_PADDING;
      groupField.x = groupField.x < 0 ? 0 : groupField.x;

      groupField.y = groupField.y + scaledY - GROUP_PADDING;
      groupField.y = groupField.y < 0 ? 0 : groupField.y;

      x = optionX;
      y = optionY;
    } else if (x < 0) {
      groupField.x = groupField.x + scaledX - GROUP_PADDING;
      groupField.x = groupField.x < 0 ? 0 : groupField.x;
      x = 0;
    } else if (y < 0) {
      groupField.y = groupField.y + scaledY - GROUP_PADDING;
      groupField.y = groupField.y < 0 ? 0 : groupField.y;
      y = 0;
    }

    groupOptions[index] = {
      ...groupOptions[index],
      x,
      y,
      ...restUpdatedProps,
      ...(savedOptionId && { isUpdated: true }),
    };

    updateGroupField(groupOptions, groupField);
  };

  const handleHighLightField = (optionUUID) => {
    dispatch(
      selectDynamicFormFieldEffect({
        field: {
          pageNumber: field.pageNumber,
          roleName: field.roleName,
          UUID: field.UUID,
          roleId: customRole?.roleId,
          groupOptionUUID: optionUUID,
        } as DynamicFormSelectedField,
      }),
    );
  };

  const filledAnswersCount = useMemo(() => {
    return options.filter(
      ({ UUID, fieldAnswer }) => groupAnswers[UUID]?.Answer || fieldAnswer?.answer,
    )?.length;
  }, [options, groupAnswers]);

  return (
    <div className={styles.groupField} key={groupFieldUUID}>
      {options.map((item, index) => {
        const { width, height } = item;
        let scaledWidth = width * scale;
        let scaledHeight = height * scale;

        const x = item.x;
        const y = item.y;

        const isRemovable = options.length > 1;

        const isOptionHighlighted = groupOptionUUID === item.UUID;
        const grabPointStyle = isOptionHighlighted ? styles.grabPoint : undefined;

        if (item.isDeleted) return <></>;

        return (
          <Rnd
            key={item.UUID}
            default={{
              x,
              y,
              width: scaledWidth,
              height: scaledHeight,
            }}
            style={getFieldStyle(item, isOptionHighlighted)}
            size={{ width: scaledWidth, height: scaledHeight }}
            minWidth={21}
            minHeight={21}
            position={{ x, y }}
            bounds={`#pageContainerDrop-${documentIndex}-${pageNumber}`}
            onResizeStart={() => handleHighLightField(item.UUID)}
            onDragStart={() => handleHighLightField(item.UUID)}
            onDragStop={(e, d) => {
              const dx = d.x + groupBorderWidth;
              const dy = d.y + groupBorderWidth;

              if (dx === d.lastX && dy === d.lastY) return;

              handleOptionUpdate({ x: d.x, y: d.y }, index);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              const parsedWidth = parseInt(ref.style.width) / scale;
              const parsedHeight = parseInt(ref.style.height) / scale;

              handleOptionUpdate(
                {
                  width: parsedWidth,
                  height: parsedHeight,
                  x: position.x,
                  y: position.y,
                },
                index,
              );
            }}
            lockAspectRatio={true}
            dragGrid={[1, 1]}
            resizeGrid={[1, 1]}
            resizeHandleClasses={{
              bottomLeft: grabPointStyle,
              bottomRight: grabPointStyle,
              topLeft: grabPointStyle,
              topRight: grabPointStyle,
            }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <RadioItem
              index={index}
              item={item}
              color={color}
              field={field}
              scale={scale}
              groupAnswers={groupAnswers}
              documentIndex={documentIndex}
              setGroupAnswers={setGroupAnswers}
              handleFieldUpdate={handleFieldUpdate}
              filledAnswersCount={filledAnswersCount}
            />

            {isRemovable && isOptionHighlighted && (
              <RemoveRadioIcon
                index={index}
                UUID={item.UUID}
                options={options}
                groupAnswers={groupAnswers}
                setGroupAnswers={setGroupAnswers}
                updateGroupField={updateGroupField}
              />
            )}
          </Rnd>
        );
      })}

      {isHighlighted && (
        <AddRadioIcon
          color={color}
          scale={scale}
          options={options}
          updateGroupField={updateGroupField}
        />
      )}
    </div>
  );
};
