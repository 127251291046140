import { useState } from 'react';

import { Options } from 'components';
import { Button } from 'antd';
import { Repeat, Clock, Briefcase, Plus } from 'components/Icons';

import styles from './styles.module.scss';

export const DueDateOptions = ({
  isAddTime,
  setIsAddTime,
  isRepeat,
  setIsRepeat,
  isBusinessDays,
  setIsBusinessDays,
  hideRepeat = false,
}) => {
  const DUE_DATE_OPTIONS = [
    {
      label: isAddTime ? 'Remove Time' : 'Add Time',
      icon: <Clock />,
      onClick: () => setIsAddTime(!isAddTime),
    },
    ...(hideRepeat
      ? []
      : [
          {
            label: isRepeat ? 'Remove Repeat' : 'Repeat',
            icon: <Repeat />,
            onClick: () => setIsRepeat(!isRepeat),
          },
        ]),
    {
      label: isBusinessDays ? 'Calendar Days' : 'Business Days',
      icon: <Briefcase />,
      onClick: () => setIsBusinessDays(!isBusinessDays),
    },
  ];

  return (
    <div className={styles.dueDateOptionsContainer}>
      <Options
        title={
          <>
            <Button type="text" className={styles.optionsBtn}>
              <Plus className={styles.plusIcon} /> Options
            </Button>
          </>
        }
        placement="bottom"
        options={DUE_DATE_OPTIONS}
        popoverClassName={styles.dueDateOptionsPopover}
        titleClassName={styles.optionsTitle}
      />
    </div>
  );
};
