import React from 'react';

import { CampaignEntityType, CampaignTaskTemplate } from 'store/reducers/radarKits/types';
import { SelectedKit } from '../../ViewSequence';
import { formatFrequency } from '../../helpers';
import { DropdownMenu, DropdownMenuOptions } from '../DropdownMenu';
import { ChevronDownIcon } from '../icons';
import styles from './styles.module.scss';
import moment from 'moment';
import { getKitFrequencyString } from 'pages/Dashboard/Kits/utils';

type CardItemProps = {
  type: CampaignEntityType;
  item: SelectedKit | CampaignTaskTemplate;
  icon: React.ReactNode;
  editMode?: boolean;
  options: DropdownMenuOptions[];
  isSequenceOwner?: boolean;
  dropdownOnSelect: (
    kitId: number,
    frequencyId: string,
    newFrequencyName: string,
    prevFrequenceName: string,
  ) => void;
};

const getDueDateText = (task): string => {
  const { Initial, DueDate, Operator } = task;
  const isAfter = Operator === '+';
  const timingText = isAfter ? 'after' : 'before';

  if (Initial === 'A') {
    return `Due: ${DueDate} days ${timingText} KIT sequence enrollment`;
  } else if (Initial === 'CL') {
    return `Due: ${DueDate} days ${timingText} closing`;
  } else {
    return `Due: ${DueDate} days ${timingText} ${Initial}`;
  }
};

export const CardItem: React.FC<CardItemProps> = ({
  type,
  item,
  icon,
  editMode = true,
  options,
  dropdownOnSelect,
  isSequenceOwner,
}) => {
  const renderKits = (item: SelectedKit) => {
    const currentFrequency = item?.Kits?.EligibleFrequencies.find(
      (eligibleFreq) => eligibleFreq.Id === item.FrequencyId,
    );
    const optionsFrequency =
      item?.Kits?.EligibleFrequencies.map((item) => {
        return {
          label: getKitFrequencyString(item),
          id: item.Id.toString(),
          prevFrequenceName: getKitFrequencyString(currentFrequency),
        };
      }) || [];
    const kitSlug = item?.Kits?.Slug;
    return (
      <div className={styles.cardItem}>
        <div className={styles.contentWrapper}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div>
            <div className={styles.title}>{item?.Kits?.Name || ''}</div>
            <div className={styles.description}>
              {formatFrequency(currentFrequency) && (
                <>
                  <div>{formatFrequency(currentFrequency, kitSlug)}</div>
                  <div className={styles.dot}></div>
                </>
              )}
              {currentFrequency && isSequenceOwner && (
                <div className={styles.frequenceWrapper}>
                  {optionsFrequency?.length > 1 ? (
                    <DropdownMenu
                      onSelect={(frequencyId, frequenceName, prevFrequenceName) =>
                        dropdownOnSelect(item.KitId, frequencyId, frequenceName, prevFrequenceName)
                      }
                      currentValue={currentFrequency.Id.toString()}
                      options={optionsFrequency}
                      showValue={true}
                      btnIcon={<ChevronDownIcon />}
                      id={item.KitId.toString()}
                    />
                  ) : (
                    currentFrequency.FrequencyName
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {isSequenceOwner && <DropdownMenu options={options} id={item.KitId.toString()} />}
      </div>
    );
  };

  const renderTasks = (item: CampaignTaskTemplate) => {
    return (
      <div className={styles.cardItem}>
        <div className={styles.contentWrapper}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div>
            <div className={styles.title}>{item?.Name || ''}</div>
            <div className={styles.description}>
              <div>
                <span> {getDueDateText(item)}</span>
              </div>
            </div>
          </div>
        </div>
        {isSequenceOwner && <DropdownMenu options={options} id={item.Id.toString()} />}
      </div>
    );
  };

  return (
    <div>
      {type === CampaignEntityType.Kit
        ? renderKits(item as SelectedKit)
        : renderTasks(item as CampaignTaskTemplate)}
    </div>
  );
};
