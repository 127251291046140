import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  transactionPreFormQuestionsIds,
  TransactionReasonsForCancellation,
} from 'settings/constants/transaction';
import { updateTransactionEffect } from 'store/effects/transactions';

import { Question } from 'pages/RequestQuote/components';
import { Autocomplete, Select, Button } from 'components';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';

import styles from './styles.module.scss';
import { ProjectReasonsForCancellation } from 'settings/constants/projectCancellation';
import useIsProjectRoute from 'hooks/use-is-project-route';

const ReasonForCancellation = (props) => {
  const { className, onNext, currentQuestionId, transactionId } = props;
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState({ name: '', value: '' });
  const isProject = useIsProjectRoute();

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.reasonForCancellation) {
      const data = { id: transactionId, ReasonForCancellation: selectedReason.value };
      if (isProject) {
        event.ReasonForCancellation = selectedReason.value;
        onNext(event);
        return;
      }
      dispatch(
        updateTransactionEffect(data, {}, (err) => {
          if (!err) {
            onNext(event);
          }
        }),
      );
    }
  };

  const reasonForCancellation = useMemo(() => {
    if (isProject) return ProjectReasonsForCancellation;
    return TransactionReasonsForCancellation;
  }, [isProject]);

  return (
    <div testid="what_reason_for_cancellation" className={classNames(styles.container, className)}>
      <Question>What is the reason for cancellation?</Question>
      <AnswersContainer>
        <Select
          testid="cancel_reason_input"
          placeholder="Select cancellation reason"
          defaultValue={selectedReason}
          variant={Autocomplete.LIGHT}
          onSelect={(val) => {
            setSelectedReason(val.target.value);
          }}
          options={reasonForCancellation?.map((val) => ({
            name: val,
            value: val,
            key: `tags-${val}`,
          }))}
        ></Select>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} />
      </ButtonsContainer>
    </div>
  );
};

ReasonForCancellation.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string,
  transactionId: PropTypes.string,
};

ReasonForCancellation.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
  transactionId: null,
};

export default ReasonForCancellation;
