import React from 'react';
import PropTypes from 'prop-types';

const Minus = ({ className, onClick = () => {} }) => (
  <div className={className} onClick={onClick}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 8H13" stroke="#163C5A" strokeWidth="2" strokeLinecap="round" />
    </svg>
  </div>
);

Minus.propTypes = {
  className: PropTypes.string,
};

Minus.defaultProps = {
  className: '',
};

export default Minus;
