import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Table } from 'components-antd';
import styles from './styles.module.scss';
import { SpinProps } from 'antd';

interface GroupByTableProps {
  loading?: boolean | SpinProps | undefined;
  dataSource: any;
  onRow?: any;
  columns: any[];
  rowClassName?: any;
  tableClassName?: string;
}

export const GroupByTable = ({
  loading = false,
  dataSource,
  onRow = null,
  columns,
  rowClassName = '',
  tableClassName = '',
}: GroupByTableProps) => {
  if (dataSource?.length === 0) return null;

  const sortedColumns = columns.map((col) => {
    if (col.dataIndex === 'Property') {
      return {
        ...col,
        sorter: (a, b) => {
          const addressA = a.Property?.Address?.Line1 || '';
          const addressB = b.Property?.Address?.Line1 || '';
          return addressA.localeCompare(addressB);
        },
      };
    }
    return col;
  });

  return (
    <div className={styles.quotationsList}>
      <Table
        className={classNames(styles.quotationsTable, tableClassName)}
        loading={loading}
        bordered={false}
        columns={sortedColumns}
        dataSource={dataSource}
        pagination={false}
        rowKey="id"
        onRow={onRow}
        rowClassName={rowClassName}
      />
    </div>
  );
};
