import React, { memo, useMemo } from 'react';

interface DraftsIconProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'File' | 'Drawer';
}

const Drafts = memo(({ className, variant = 'File' }: DraftsIconProps) =>
  useMemo(() => {
    if (variant === 'Drawer')
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.54415 6.70153L4.05132 17.18C4.01733 17.282 4 17.3888 4 17.4963V24.6673C4 25.7719 4.89543 26.6673 6 26.6673H26C27.1046 26.6673 28 25.7719 28 24.6673V17.4963C28 17.3888 27.9827 17.282 27.9487 17.18L24.4558 6.70153C24.1836 5.88484 23.4193 5.33398 22.5585 5.33398H9.44152C8.58066 5.33398 7.81638 5.88484 7.54415 6.70153Z"
            stroke="#262626"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66797 13.334H11.3346V14.6673C11.3346 16.8765 13.1255 18.6673 15.3346 18.6673H16.668C18.8771 18.6673 20.668 16.8765 20.668 14.6673V13.334H25.3346"
            stroke="#262626"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    return (
      <div className={className}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 4.00085L16 8.82939C16 9.13984 16.1045 9.43757 16.2905 9.65709C16.4765 9.87662 16.7287 9.99994 16.9917 9.99994L21 10.0009"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.7087 8.70884L16.3043 4.29386C16.1167 4.1058 15.8627 4.00085 15.5971 4.00085H10.4916C9.66644 4.00085 8.99636 4.66386 8.99248 5.48898C8.97873 8.42184 8.9534 14.9726 8.98175 18.5348C8.98825 19.3518 9.65393 20.0009 10.4709 20.0009H19.5C20.3284 20.0009 21 19.3293 21 18.5009L21 9.41436C21 9.14956 20.8957 8.8963 20.7087 8.70884Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.00004 4H5.5C4.67157 4 4 4.67157 4 5.5L4 18.5C4 19.3284 4.67157 20 5.5 20H9.00004"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }, [className]),
);

export default Drafts;
