import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TextArea } from 'components-antd';
import { FullArrowUp } from 'components/Icons';

import { generateAIReportEffect } from 'store/effects';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';

export const ChatInput = ({ activeSessionTabId, fetchSessionMessages }) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getAgentTeamIdSelector);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const onClickSend = () => {
    if (inputValue?.trim() !== '') {
      onSendPress(inputValue);
    }
  };

  const onSendPress = (userQuery) => {
    setLoading(true);
    dispatch(
      generateAIReportEffect(
        {
          userQuery,
          sessionId: activeSessionTabId,
          teamId,
          isReportRequired: true,
        },
        {},
        (err) => {
          if (!err) {
            fetchSessionMessages();
          }
          setInputValue('');
          setLoading(false);
        },
      ),
    );
  };

  return (
    <div className={styles.chatInputContainer}>
      <div className={styles.chatAreaContainer}>
        <TextArea
          className={styles.chatTextArea}
          placeholder="Ask Clarity AI to give insights or generate reports..."
          maxLength={500}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onClickSend();
          }}
          disabled={!activeSessionTabId}
        />
        <Button
          className={styles.sendBtn}
          variant="secondary"
          onClick={onClickSend}
          loading={loading}
          icon={<FullArrowUp className={styles.flexIcon} />}
        />
      </div>
    </div>
  );
};
