export const getKitFrequencyString = (item) => {
  if (!item) return '';
  let frequencyString = item.FrequencyName;
  if (item.ClosingDateRelated) {
    frequencyString += ' (CD';
    if (item.OffsetOperator) frequencyString += item.OffsetOperator;
    if (item.OffsetValue) frequencyString += item.OffsetValue;
    if (item.OffsetUnit) frequencyString += item.OffsetUnit;
    frequencyString += ' )';
  }
  if (item.HasSpecificDates && item.SpecificDates?.length) {
    frequencyString += ' (';
    frequencyString += item.SpecificDates.join();
    frequencyString += ' )';
  }
  if (item.EnrollmentDateRelated) {
    frequencyString += ' (E';
    if (item.OffsetOperator) frequencyString += item.OffsetOperator;
    if (item.OffsetValue) frequencyString += item.OffsetValue;
    if (item.OffsetUnit) frequencyString += item.OffsetUnit;
    frequencyString += ' )';
  }
  // if (item.HasDayPreference) {
  //   frequencyString += ' (';
  //   const occurrences = Array.isArray(item.OccurrencePreference) ? item.OccurrencePreference : [];
  //   const days = Array.isArray(item.DayPreference) ? item.DayPreference : [];
  //   const pairs = days.map((day, index) => {
  //     return `${occurrences[index] || ''} ${day}`.trim();
  //   });
  //   frequencyString += pairs.join(', ');
  //   frequencyString += ')';
  // }
  return frequencyString;
};
