import React from 'react';
import classNames from 'classnames';

type DisclaimerIconProps = {
  className?: string;
  color?: string;
};

export const DisclaimerIcon: React.FC<DisclaimerIconProps> = (props) => {
  const { className, color = '#AAABAB' } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_73132_115467)">
          <path
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 5.33398V8.00065"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="8" cy="11" r="1" fill={color} />
        </g>
        <defs>
          <clipPath id="clip0_73132_115467">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
