import { apiServer } from 'settings/web-services/api';

export function getPartners(cfg) {
  return apiServer.post('/partner/partners/directory', cfg);
}

export function getPartnerById({ id, IsStrictlyProfile }) {
  return apiServer.get(`/partner/partners/${id}${IsStrictlyProfile ? '?IsStrictlyProfile=1' : ''}`);
}

export function putPartnerData(cfg, { id }) {
  return apiServer.put(`/partner/partners/${id}`, cfg);
}

export function putDeactivatePartner({ id }) {
  return apiServer.put(`/partner/partners/${id}`, {
    IsActive: false,
  });
}

export function postPartnerData(cfg) {
  return apiServer.post('/partner/partners', cfg);
}

export function postPartnerDatabyAgent(cfg) {
  return apiServer.post('/partner/agent/partner', cfg);
}

export function putActivatePartner({ id }) {
  return apiServer.put(`/partner/partners/${id}`, {
    IsActive: true,
  });
}
export function getSendInviteById({ id }) {
  return apiServer.get(`/partner/partners/invite/${id}`);
}

export function postBulkPartners(cfg) {
  return apiServer.post('/partner/bulk/partners', cfg);
}

export function getExistingPartnerEmails(cfg) {
  return apiServer.post('/partner/emails', cfg);
}

export function postPartnerDataFromThirdParty(cfg, { id }) {
  return apiServer.post(`/partner/thirdparty/partner/${id}`, cfg);
}

export function getPartnerInviteEmailTemplate() {
  return apiServer.get(`/emailTemplate/template/PartnerInvitation`);
}
