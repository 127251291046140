import { ModalProps } from 'components-antd';
import { PropertyLocation } from 'types';
import {
  Areas,
  Description,
  Notes,
  Avatar,
  Organization,
  Phone,
  World,
  Pin,
} from 'components/Icons';
import { AvatarMini } from './icons';

export const maxLogoSizeInBytes = 5242880; //5mb

export interface Props extends ModalProps {
  handleClose: Function;
}

export type AddVendorPayloadType = {
  BusinessName: string;
  Published: boolean;
  Email: string;
  Category: { name: string; value: number } | undefined;
  Phone?: string;
  Website?: string;
  Address?: PropertyLocation;
  ServiceAreas?: { uuid: string; hasRadius: boolean; radius: number; location: any }[];
  ServicesOffered?: { name: string; value?: number; uuid: string }[];
  PointOfContactName?: string;
  PointOfContactEmail?: string;
  AgentNote?: string;
  Description?: string;
  LogoFile?:
    | {
        ContentType: 'image/jpeg' | 'image/jpg' | 'image/png';
        Data: string;
        Filename: string;
        Size: number;
      }
    | undefined;
  LogoUrl?: string;
};

export enum AddNewVendorStages {
  InviteOrPublish = 'InviteOrPublish',
  AddVendorDetails = 'AddVendorDetails',
  PriorToInvitation = 'PriorToInvitation',
  EmailTemplate = 'EmailTemplate',
}

export const addVendorPayloadInitVals: AddVendorPayloadType = {
  BusinessName: '',
  Published: false,
  Email: '',
  Category: undefined,
  ServiceAreas: [],
  ServicesOffered: [],
};

export enum VendorDetailType {
  General = 'General',
  PhoneNumber = 'PhoneNumber',
  ServiceAreas = 'ServiceAreas',
  Address = 'Address',
  ServicesOffered = 'ServicesOffered',
  Website = 'Website',
  AgentNote = 'AgentNote',
  PointOfContact = 'PointOfContact',
  Description = 'Description',
}

export type DetailsMenuItemsType = {
  label: string;
  key: VendorDetailType;
  icon;
  iconBG: string;
  subText?: string;
};

export const detailsMenuItems: DetailsMenuItemsType[] = [
  {
    label: 'Phone Number',
    key: VendorDetailType.PhoneNumber,
    icon: <Phone color="#4673D1" />,
    iconBG: 'rgba(70 ,115, 209,0.1)',
  },
  {
    label: 'Service Areas',
    key: VendorDetailType.ServiceAreas,
    icon: <Areas color="#51BFE1" />,
    iconBG: 'rgba(81, 191, 225,0.1)',
  },
  {
    label: 'Address',
    key: VendorDetailType.Address,
    icon: <Pin color="#04A451" />,
    iconBG: 'rgba(4, 164, 81,0.1)',
  },
  {
    label: 'Services Offered',
    key: VendorDetailType.ServicesOffered,
    icon: <Organization color="#928CDA" />,
    iconBG: 'rgba(146, 140, 218,0.1)',
  },
  {
    label: 'Website',
    key: VendorDetailType.Website,
    icon: <World color="#FF576D" />,
    iconBG: 'rgba(255, 87, 109,0.1)',
  },
  {
    label: 'Agent Note',
    key: VendorDetailType.AgentNote,
    icon: <Notes color="#4673D1" />,
    iconBG: 'rgba(70 ,115, 209,0.1)',
    subText: '(visible only to clients)',
  },
  {
    label: 'Point of Contact',
    key: VendorDetailType.PointOfContact,
    icon: <AvatarMini />,
    iconBG: 'rgba(251, 145, 58,0.1)',
  },
  {
    label: 'Description',
    key: VendorDetailType.Description,
    icon: <Description color="#04A451" />,
    iconBG: 'rgba(4, 164, 81,0.1)',
    subText: '(visible to vendor & clients)',
  },
];

export type VendorValidationErrors = {
  businessNameError?: string;
  categoryError?: string;
  businessEmailError?: string;
  phoneError?: string;
  websiteError?: string;
  POCEmailError?: string;
};
