import { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { link } from 'settings/navigation/link';
import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';

import { Modal, Row, Col } from 'components-antd';
import { Wrapper as PendingWrapper, TruncatedContent, GenerateHtml } from 'components';
import { getUserId } from 'store/selectors/user';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { getTransactionTaskMessagesSelector } from 'store/selectors/transactionTaskMessages';
import { Actions } from '../View/components';
import { Header, TaskSectionContainer, AssignInfo, TaskDueDate } from '../ViewRevamped/components';
import { Icons as TaskIcons } from '../ViewRevamped/Icons';
import { OpenBook } from 'components/Icons';
import { EmailTemplateModal } from '../../TaskModalForm/EmailTemplateModal';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import {
  socketsGetTransactionTaskMessagesByThreadIdEffect,
  resetTransactionTaskMessagesEffect,
} from 'store/effects/sockets/transactionTaskMessages';
import { updateUnReadCommentsEffect } from 'store/effects/taskAggregate';
import { updateUnReadCommentsForTransactionEffect } from 'store/effects/transactions';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import {
  getIsBundleFormTaskSelector,
  getFormTaskDetailsSelector,
  getFormDocumentsSelector,
  getFormTaskLinkedFormsSelector,
} from 'store/selectors/transactionTask';
import { useLocation } from 'react-router-dom';
import { LinkFormModal } from 'pages/Workshop/Forms/components/LinkFormModal';

import Icons from 'pages/Main/Pulse/components/Icons';
import { AGENT } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import { updatePreFormResponseEffect } from 'store/effects/formProcess';
import { FormProcessTypes } from 'types/formProcess/requestFormProcess';
import { getTransactionTaskEffect } from 'store/effects';
import { getAdminOwnerAccessSelector } from 'store/selectors/transaction';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import { FormTaskActions, RightSectionTabs } from './components';

import styles from './styles.module.scss';

const Form = (props) => {
  const { className, isOpen, onCloseModal } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { transaction } = useSelector(getTransactionSelector);

  const userId = useSelector(getUserId);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { isPending, task, isError, status } = useSelector(getTransactionTaskSelector);
  const { threadsList, messages } = useSelector(getTransactionTaskMessagesSelector);

  const [addComments, setAddComments] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [openLinkFormsModal, setOpenLinkFormsModal] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [bundleFormNames, setBundleFormNames] = useState([]);
  const [linkedFormsList, setLinkedFormsList] = useState([]);
  const { taskId, transactionId } = useSelector(getTransactionTaskParamsSelector);
  const { IsBundle } = useSelector(getIsBundleFormTaskSelector);
  const { formTask } = useSelector(getFormTaskDetailsSelector);
  const { formDocuments } = useSelector(getFormDocumentsSelector);
  const { linkedForms } = useSelector(getFormTaskLinkedFormsSelector);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);
  const isNotFound = isError && status && status === 404;
  const [showDescriptionView, setShowDescriptionView] = useState(false);
  const [viewEmailTemplate, setViewEmailTemplate] = useState(false);
  const [editId, setEditId] = useState(null);

  const { CcList } = task || {};

  const containerRef = useRef();

  const checkAndGetThread = () =>
    threadsList.find(
      (thread) =>
        thread?.EntityId === `${task?.Id}` && thread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK,
    );

  useEffect(() => {
    const showCommentsFromState = location.state?.showComments;
    if (showCommentsFromState) {
      setShowComments(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (isOpen) {
      const existThread = checkAndGetThread();
      if (existThread) {
        dispatch(socketsGetTransactionTaskMessagesByThreadIdEffect({ Id: existThread?.Id }));
      } else {
        dispatch(resetTransactionTaskMessagesEffect());
      }
      setAddComments(!!existThread);

      task?.Comments?.forEach((comment) => {
        if (comment.SenderUserId !== userId) {
          comment.MessageReceipts.some((receipt) => {
            if (receipt.UserId === userId && !receipt.IsRead) {
              setUnreadMessagesCount((unreadMessagesCount) => unreadMessagesCount + 1);
              return true;
            }
          });
        }
      });
    } else {
      setUnreadMessagesCount(0);
    }
  }, [task?.Id, isOpen]);

  useEffect(() => {
    if (showComments) {
      if (task?.Id) {
        dispatch(updateUnReadCommentsEffect({ taskId: task?.Id, userId: userId }));
      }
      if (task?.Id && task?.TransactionId) {
        dispatch(updateUnReadCommentsForTransactionEffect({ taskId: task?.Id, userId: userId }));
      }
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setUnreadMessagesCount(0);
  }, [showComments]);

  useEffect(() => {
    if (IsBundle) {
      let formNames;
      if (formTask) {
        formNames = formTask?.FormBundleTemplate?.FormBundleTemplateRelation.map(
          (bundle) => bundle.FormTemplate.Name,
        );
      } else {
        formNames = task?.formDocuments?.Documents?.map(({ FormName }) => FormName);
      }
      setBundleFormNames(formNames);
    }
  }, []);

  const beginSigning = () => {
    const dataToSend = {
      type: formTask.FormType,
      isTemplate: formTask.FormType === AGENT,
      isLibraryTemplate: true,
      propertyTransactionId: transactionId,
      taskId,
      ...(IsBundle && {
        bundleId: formTask.FormBundleTemplate.Id,
        formBundle: true,
        bundleTemplateName: formTask.FormBundleTemplate.Name,
      }),
      ...(!IsBundle && {
        formId: formTask?.FormTemplate ? formTask.FormTemplate.FormId : formTask.Form.Id,
        versionId: formTask?.FormTemplate
          ? formTask.FormTemplate.FormVersionId
          : formTask.Form.CurrentFormVersion,
        templateId: formTask.FormTemplate?.Id || undefined,
      }),
    };

    history.push({ pathname: routes.workshopFormProcessRequest, state: { ...dataToSend } });

    if (transactionId) {
      dispatch(
        updatePreFormResponseEffect({
          transactionId,
          isProject: transaction.isProject,
          formName:
            'FormBundleTemplate' in formTask
              ? formTask.FormBundleTemplate.Name
              : formTask?.FormTemplate
              ? formTask.FormTemplate.Name
              : formTask.Form.CurrentFormVersion.Name,
        }),
      );
    }
  };

  const gotToForm = () => {
    if (IsBundle)
      history.push({
        pathname: link.toWorkshopDynamicFormBundle({
          formProcessPublicId: task?.EntityId,
        }),
        state: {
          formBundle: true,
          allDocuments: formDocuments.Documents,
          bundleId: formDocuments.BundleId,
          bundleName: formDocuments.BundleName || '',
          redirectionLink: location.pathname,
        },
      });
    else {
      formDocuments.Type === FormProcessTypes.DynamicForm
        ? history.push({
            pathname: link.toWorkshopDynamicFormDocument({
              formProcessPublicId: task?.EntityId,
              formDocumentPublicId: formDocuments.FormDocuments[0].PublicId,
            }),
            state: { redirectionLink: location.pathname },
          })
        : history.push({
            pathname: link.toWorkshopFormProcessDocument({
              formProcessPublicId: task?.EntityId,
              formDocumentPublicId: formDocuments.FormDocuments[0].PublicId,
            }),
            state: { redirectionLink: location.pathname },
          });
    }
  };

  const formatLinkedForms = (linkedForms) => {
    const {
      recentlyCompletedForms,
      finalizingForms,
      waitingOnYou,
      waitingOnOthers,
      canceledForms,
      draftForms,
      voidedForms,
    } = linkedForms;

    const recentlyCompletedFormsList = recentlyCompletedForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#04A451',
        bgColor: '#04A45114',
        status: 'Completed',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const finalizingFormsList = finalizingForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#4673D1',
        bgColor: '#4673D114',
        status: 'Finalizing',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const waitingOnYouList = waitingOnYou.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#EC455E',
        bgColor: '#EC455E14',
        status: 'Waiting On You',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const waitingOnOthersList = waitingOnOthers.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#FB913A',
        bgColor: '#FB913A14',
        status: 'Waiting On Others',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const canceledFormsList = canceledForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#AAABAB',
        bgColor: '#AAABAB14',
        status: 'Cancelled',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const draftFormsList = draftForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#515151',
        bgColor: '#51515114',
        status: 'Draft',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const voidedFormsList = voidedForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#AAABAB',
        bgColor: '#AAABAB14',
        status: 'Voided',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });

    return [
      ...recentlyCompletedFormsList,
      ...finalizingFormsList,
      ...waitingOnYouList,
      ...waitingOnOthersList,
      ...canceledFormsList,
      ...draftFormsList,
      ...voidedFormsList,
    ];
  };

  useEffect(() => {
    if (linkedForms?.draftForms) setLinkedFormsList(formatLinkedForms(linkedForms));
  }, [linkedForms]);

  // Sections disable boolean on editting Due Date/Assignee List
  const isDisableSection = useMemo(() => editId !== null, [editId]);

  const showOverDueLabel = () =>
    ![tasksStatusesIds.done, tasksStatusesIds.na].includes(task?.Status);

  return (
    <Modal
      open={isOpen}
      width={1000}
      footer={null}
      onCancel={() => {
        dispatch(resetTransactionTaskMessagesEffect());
        onCloseModal();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.taskViewModal}
    >
      <div testid="task_view" className={classNames(styles.view, className)}>
        <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
          {isNotFound ? (
            <div className={styles.notFoundMessage}>The task has already been deleted</div>
          ) : (
            <Fragment>
              <div ref={containerRef} className={styles.content}>
                <Header
                  taskTransactionId={task?.TransactionId}
                  title={task?.Title}
                  transaction={transaction}
                  category={task?.Category}
                  showDescriptionView={showDescriptionView}
                  hideDescriptionView={() => setShowDescriptionView(false)}
                />
                {(isAdminOrOwner || fullAccess) && (
                  <div className={styles.subtitle}>
                    {task?.IsRequired ? (
                      <span className={styles.required}>Required</span>
                    ) : (
                      <span className={styles.notRequired}>If Applicable</span>
                    )}
                  </div>
                )}

                {showDescriptionView ? (
                  <div className={styles.descriptionView}>
                    <GenerateHtml content={task?.Description} />
                  </div>
                ) : (
                  <>
                    <Actions
                      assignor={task?.Assignor}
                      transactionId={task?.TransactionId}
                      dueDate={task?.DueDate}
                      className={styles.taskActions}
                    />
                    <Row gutter={24} className={styles.sectionsRow}>
                      <Col lg={12} className={styles.leftSection}>
                        <TaskDueDate
                          task={task}
                          disabled={isDisableSection && editId !== 'task-due-date'}
                          setEditId={setEditId}
                        />
                        <TaskSectionContainer
                          disabled={isDisableSection && editId !== 'assign-info'}
                        >
                          <AssignInfo
                            task={task}
                            disabled={isDisableSection && editId !== 'assign-info'}
                            setEditId={setEditId}
                          />
                        </TaskSectionContainer>
                        <TaskSectionContainer
                          title="Description"
                          icon={<TaskIcons variant={TaskIcons.DESCRIPTION} />}
                          hideSection={!task?.Description}
                          disabled={isDisableSection}
                        >
                          <TruncatedContent
                            content={<GenerateHtml content={task?.Description} />}
                            showMoreText="Read All"
                            showMoreOnClick={() => setShowDescriptionView(true)}
                          />
                        </TaskSectionContainer>
                        {task?.EmailTemplate ? (
                          <TaskSectionContainer
                            title="Email Template"
                            icon={<TaskIcons variant={TaskIcons.EMAIL_TEMPLATE} />}
                            disabled={isDisableSection}
                          >
                            <div className={styles.containerEmailTemplate}>
                              <span>{task?.EmailTemplate?.Subject}</span>
                              <OpenBook
                                className={styles.iconEmailTemplate}
                                onClick={() => !isDisableSection && setViewEmailTemplate(true)}
                              />
                            </div>
                          </TaskSectionContainer>
                        ) : (
                          <></>
                        )}
                        {IsBundle && bundleFormNames?.length ? (
                          <TaskSectionContainer disabled={isDisableSection}>
                            <div className={styles.bundleDescription}>
                              <p>This includes the following forms</p>
                              {bundleFormNames.map((formName, index) => (
                                <div className={styles.formNameContainer} key={index}>
                                  <Icons className={styles.docIcon} variant="document" />
                                  <span className={styles.formName}>{formName}</span>
                                </div>
                              ))}
                            </div>
                          </TaskSectionContainer>
                        ) : (
                          <></>
                        )}
                        <FormTaskActions
                          task={task}
                          setOpenLinkFormsModal={setOpenLinkFormsModal}
                          beginSigning={beginSigning}
                          gotToForm={gotToForm}
                          disabled={isDisableSection}
                        />
                      </Col>
                      <Col lg={12}>
                        <RightSectionTabs
                          task={task}
                          linkedFormsList={linkedFormsList}
                          transactionId={transactionId}
                          disabled={isDisableSection}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Fragment>
          )}
        </PendingWrapper>
        <LinkFormModal
          open={openLinkFormsModal}
          onClose={() => {
            setOpenLinkFormsModal(false);
          }}
          taskId={taskId}
          cb={() => dispatch(getTransactionTaskEffect({ transactionId, taskId }))}
        />
        <EmailTemplateModal
          open={viewEmailTemplate}
          onClose={() => setViewEmailTemplate(false)}
          subjectText={task?.EmailTemplate?.Subject}
          bodyText={task?.EmailTemplate?.Body}
        />
      </div>
    </Modal>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

Form.defaultProps = {
  className: '',
};

export default Form;
