import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { TransactionTemplate } from 'types';

const localState = ({ templates: { transaction } }) => transaction;

export const getTransactionTemplatesSelector = createSelector(
  localState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getTransactionTemplatesDataSelector = createSelector(localState, (state) => {
  const data = get(state, 'data') || [];

  return data.map((d) => {
    const timelineTemplate = get(d, 'TimelineTemplate', {});
    const milestones = get(timelineTemplate, 'Milestones', []);
    return {
      ...d,
      TimelineTemplate: {
        ...timelineTemplate,
        Milestones: milestones.map((m) => ({
          Title: m.Title,
          DueDate: m.Offset,
          Initial: m.Initial || undefined,
          Operator: m.Operator || undefined,
          ControlOperatorOffset: m.ControlOperatorOffset || undefined,
          IsPrivate: !!m.IsPrivate,
          PublicId: m.PublicId,
        })),
      },
    };
  }) as TransactionTemplate[];
});

export const getGroupedProjectTemplatesSelector = createSelector(localState, (state) => {
  const data = get(state, 'data', []);

  const groupedTemplates = data?.reduce((acc, d) => {
    const category = get(d, 'TransactionCategory.Category', 'General');

    const timelineTemplate = get(d, 'TimelineTemplate', {});
    const milestones = get(timelineTemplate, 'Milestones', []);

    const formattedTemplate = {
      ...d,
      TimelineTemplate: {
        ...timelineTemplate,
        Milestones: milestones.map((m) => ({
          Title: m.Title,
          DueDate: m.Offset,
          Initial: m.Initial || undefined,
          Operator: m.Operator || undefined,
          ControlOperatorOffset: m.ControlOperatorOffset || undefined,
          IsPrivate: !!m.IsPrivate,
          PublicId: m.PublicId,
        })),
      },
    };

    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(formattedTemplate);

    return acc;
  }, {} as Record<string, TransactionTemplate[]>);

  return groupedTemplates;
});

export const getGroupedTaskTemplatesByNameSelector = createSelector(
  getTransactionTemplatesSelector,
  ({ data }) => {
    if (!data || !data?.length) return {};
    const groupedTemplates = {};

    data?.forEach((item) => {
      const name = item?.Name || 'Unnamed';
      const templates = item?.TaskTemplates?.sort((a, b) => a?.Order - b?.Order) || [];

      groupedTemplates[name] = templates;
    });

    return groupedTemplates;
  },
);
