import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick, color, svgClass }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.NEW_SEARCH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M25 36C31.0751 36 36 31.0751 36 25C36 18.9249 31.0751 14 25 14C18.9249 14 14 18.9249 14 25C14 31.0751 18.9249 36 25 36Z"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M42 42L33 33"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.MY_SEARCH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M18.6667 17.5V42L28 36.1667L37.3334 42V17.5C37.3334 15.567 35.7664 14 33.8334 14H22.1667C20.2337 14 18.6667 15.567 18.6667 17.5Z"
              stroke="#928CDA"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.TOURS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M34.7999 9.59961H13.1999C11.2117 9.59961 9.59991 11.2114 9.59991 13.1996V34.7996C9.59991 36.7878 11.2117 38.3996 13.1999 38.3996H34.7999C36.7881 38.3996 38.3999 36.7878 38.3999 34.7996V13.1996C38.3999 11.2114 36.7881 9.59961 34.7999 9.59961Z"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.2 7.2002V12.0002"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.8 7.2002V12.0002"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.59991 19.2002H38.3999"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.4 28.7998L27.6 28.7998"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.MATCHES: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M30.5 20.5C30.5 24.6421 27.1421 28 23 28C18.8579 28 15.5 24.6421 15.5 20.5C15.5 16.3579 18.8579 13 23 13C27.1421 13 30.5 16.3579 30.5 20.5Z"
              stroke="#04A451"
              strokeWidth="3"
            />
            <path
              d="M34.25 13.1055C37.7971 13.7006 40.5 16.7855 40.5 20.5018C40.5 24.218 37.7971 27.303 34.25 27.8981"
              stroke="#04A451"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M11.75 42.375C12.4511 38.3685 15.6747 34.875 19.2498 34.875H26.75C30.3251 34.875 33.5489 38.3685 34.25 42.375"
              stroke="#04A451"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.75 34.875C40.3251 34.875 43.5489 38.3685 44.25 42.375"
              stroke="#04A451"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.CLIENT_SEARCHES: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M31.5 20.5C31.5 24.6421 28.1421 28 24 28C19.8579 28 16.5 24.6421 16.5 20.5C16.5 16.3579 19.8579 13 24 13C28.1421 13 31.5 16.3579 31.5 20.5Z"
              stroke="#FB913A"
              strokeWidth="3"
            />
            <path
              d="M11.75 42.375C12.4511 38.3685 15.6747 34.875 19.2498 34.875L24 34.875"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.8393 41.9286C39.2023 41.9286 41.9286 39.2023 41.9286 35.8393C41.9286 32.4763 39.2023 29.75 35.8393 29.75C32.4763 29.75 29.75 32.4763 29.75 35.8393C29.75 39.2023 32.4763 41.9286 35.8393 41.9286Z"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M45.2497 45.2517L40.2676 40.2695"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.ACTIVITY: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M47 28.0002H37.7808C37.3219 28.0002 36.9219 28.3125 36.8106 28.7576L33.0786 43.6857C32.8198 44.7207 31.3371 44.6854 31.1279 43.6393L24.8722 12.3608C24.6629 11.3147 23.1802 11.2794 22.9214 12.3143L19.1894 27.2427C19.0781 27.6879 18.6781 28.0002 18.2192 28.0002H9"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.COMMENTS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill="#928CDA"
            />
          </svg>
        );
      }

      case Icons.FAVORITES: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4.37884 13.2743C3.52487 12.3958 3 11.2029 3 9.88889C3 7.18883 5.21619 5 7.95 5C9.62414 5 11.1042 5.82084 12 7.07726C12.8958 5.82084 14.3759 5 16.05 5C18.7838 5 21 7.18883 21 9.88889C21 11.2674 20.4224 12.5126 19.4931 13.4013L13.4238 19.5561C12.6407 20.3503 11.3592 20.3504 10.5759 19.5564L4.37884 13.2743Z"
              stroke="#FF576D"
              strokeWidth="2"
            />
          </svg>
        );
      }

      case Icons.HIGHLIGHTS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g clipPath="url(#clip0_70781_56924)">
              <path
                d="M12.7343 17.8723C12.5886 17.7957 12.4146 17.7957 12.2689 17.8723L7.22641 20.5235C6.85959 20.7164 6.43086 20.4049 6.50093 19.9964L7.46418 14.3811C7.49201 14.2189 7.43823 14.0534 7.32039 13.9385L3.24118 9.96224C2.94441 9.67296 3.10819 9.16895 3.51833 9.10939L9.15546 8.29078C9.31833 8.26713 9.45914 8.16484 9.53197 8.01725L12.0533 2.90851C12.2367 2.53688 12.7666 2.53688 12.95 2.90851L15.4713 8.01725C15.5441 8.16484 15.6849 8.26713 15.8478 8.29078L21.4849 9.10939C21.8951 9.16895 22.0589 9.67296 21.7621 9.96224L17.6829 13.9385C17.565 14.0534 17.5112 14.2189 17.5391 14.3811L18.5023 19.9964C18.5724 20.4049 18.1437 20.7164 17.7768 20.5235L12.7343 17.8723Z"
                stroke="#FB913A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_70781_56924">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.NEW_SEARCH = 'newSearch';
Icons.MY_SEARCH = 'mySearch';
Icons.TOURS = 'tours';
Icons.MATCHES = 'matches';
Icons.CLIENT_SEARCHES = 'clientSearches';
Icons.ACTIVITY = 'activity';
Icons.COMMENTS = 'comments';
Icons.FAVORITES = 'favorites';
Icons.HIGHLIGHTS = 'highlights';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  svgClass: PropTypes.string,
};

Icons.defaultProps = {
  svgClass: '',
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
