import moment from 'moment';
import styles from './styles.module.scss';

type PromotionPropsType = {
  promotionInfo:
    | {
        isPromotionActive: boolean;
        noOfCustomers: number;
        offPercentage: number;
        expiryDate: string;
      }
    | undefined;
};

const Promotion = (props: PromotionPropsType) => {
  const { promotionInfo } = props;

  if (!promotionInfo || !promotionInfo.isPromotionActive) return null;

  const hasDatePassed = moment(promotionInfo.expiryDate).isBefore(moment(), 'day');

  if (hasDatePassed) return null;

  return (
    <div className={styles.promotion}>
      <div
        className={styles.heading}
      >{`First ${promotionInfo.noOfCustomers} customers get ${promotionInfo.offPercentage}% off!`}</div>
      <div className={styles.text}>
        {`Don’t miss this limited-time offer—be among the first ${promotionInfo.noOfCustomers} to save big on your next loan!`}
      </div>
      <div className={styles.expiry}>{`Expires on ${promotionInfo.expiryDate}`}</div>
    </div>
  );
};

export default Promotion;
