import React, { useMemo, useState } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { Modal } from 'antd';
import { ModalClose } from 'components/Icons';
import { PastDataType } from 'pages/Radar/RadarKits/AssignedClientsView/useCampaignClientExecInfo.hook';
import { RadarSortIcon } from 'pages/Radar/RadarKits/components/icons/RadarSortIcon';
import { DisclaimerIcon } from 'pages/Radar/RadarKits/components/icons/DisclaimerIcon';
import { useReplyKitModal } from 'pages/Radar/RadarKits/ReplyKitsModal/useReplyKitModal';
import { ReplyAvatar } from 'pages/Radar/RadarKits/ReplyKitsModal/components/ReplyKitAvatar';
import { ReplyKitContent } from 'pages/Radar/RadarKits/ReplyKitsModal/components/ReplyKitContent';
import { PlusRoundedIcon } from 'pages/Radar/RadarKits/components/icons/PlusRoundedIcon';
import classNames from 'classnames';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  replyRecord: PastDataType;
  client: Record<string, any>;
};

export const ReplyKitsModal: React.FC<Props> = ({ isOpen, closeModal, client, replyRecord }) => {
  const [sortDesc, setSortDesc] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [overlayReplyIndex, setOverlayReplyIndex] = useState(0);

  const isSingleReply = replyRecord?.replies?.length === 1;
  const sortedReplies = useMemo(() => {
    return replyRecord?.replies?.sort((a, b) => {
      if (sortDesc) {
        return new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime();
      }
      return new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime();
    });
  }, [sortDesc]);

  const latestReplyDate = useMemo(() => {
    const date = replyRecord?.completed?.on;
    return moment(date).format('MM/DD/YYYY');
  }, [replyRecord?.replies]);

  const clientName = `${client?.FirstName ? client.FirstName : ''} ${
    client?.LastName ? client.LastName : ''
  }`;

  const { cleanBodyText, extractNameFromEmail } = useReplyKitModal();

  const showOverlay = (i) => {
    setOverlayReplyIndex(i);
    setIsOverlayVisible(true);
  };
  const closeOverlay = () => setIsOverlayVisible(false);

  const showInlineCard = isOverlayVisible && sortedReplies.length <= 3;
  const showSingleContent = isSingleReply || showInlineCard;
  const showOverlayContent = isOverlayVisible && sortedReplies.length > 3;

  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.60)',
        backdropFilter: 'blur(20px)',
      }}
      closable
      footer={null}
      destroyOnClose
      onCancel={closeModal}
      closeIcon={<ModalClose />}
      title={replyRecord?.name?.title || ''}
      wrapClassName={styles.wrapper}
      width={765}
    >
      <div className={styles.heading}>
        <div className={styles.subtitle}>
          Client: {clientName}
          &nbsp;&nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="4"
            viewBox="0 0 3 4"
            fill="none"
          >
            <circle opacity="0.4" cx="1.5" cy="2" r="1.5" fill="#262626" />
          </svg>
          &nbsp;&nbsp;Kit Sent: {latestReplyDate}
        </div>
      </div>
      <div className={classNames(styles.mainContent)}>
        {!showInlineCard && (
          <div
            className={classNames(styles.kitReplies, {
              [styles.mainContentBlur]: isOverlayVisible,
            })}
          >
            <div className={styles.kitRepliesText}>Replies</div>
            {!isSingleReply && (
              <div className={styles.kitRepliesSort}>
                <RadarSortIcon asc={sortDesc} onClick={() => setSortDesc((s) => !s)} />
              </div>
            )}
          </div>
        )}
        {showSingleContent ? (
          <>
            <ReplyKitContent
              reply={sortedReplies[overlayReplyIndex]}
              onMinimize={showInlineCard ? closeOverlay : undefined}
              totalCount={sortedReplies.length}
            />
          </>
        ) : (
          <div
            className={classNames(styles.kitReplyMultiContentWrapper, {
              [styles.mainContentBlur]: isOverlayVisible,
            })}
          >
            {sortedReplies.length > 0 ? (
              sortedReplies.map((reply, index) => (
                <div className={styles.replyHeader}>
                  <span>
                    <ReplyAvatar clientName={clientName} />
                  </span>
                  <div className={styles.senderInfoWrapper}>
                    <div className={styles.senderInfo}>
                      <strong className={styles.senderInfoFrom}>
                        {extractNameFromEmail(reply.From)}
                      </strong>
                      <span className={styles.senderInfoDate}>
                        {moment(reply.CreatedDate).format('MM/DD/YYYY [at] h:mm A')}
                      </span>
                    </div>
                    <div
                      className={styles.kitPreviewIconWrapper}
                      onClick={() => showOverlay(index)}
                    >
                      <PlusRoundedIcon className={styles.kitPreviewIcon} />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No replies found.</p>
            )}
          </div>
        )}
        {showOverlayContent && (
          <div className={styles.replyOverlay}>
            <ReplyKitContent
              reply={sortedReplies?.[overlayReplyIndex]}
              onMinimize={closeOverlay}
              totalCount={sortedReplies.length}
            />
          </div>
        )}
      </div>
      <div className={styles.kitReplyDisclaimer}>
        <div>
          <DisclaimerIcon className={styles.disclaimerIcon} />
        </div>
        <div className={styles.disclaimerText}>
          Replies to this KIT are only tracked if the tracking email is retained on cc by the
          replying party. Accordingly, this may not be a comprehensive record of replies and it is
          recommended that you also check your primary email (the email associated with your Mosaik
          account).
        </div>
      </div>
    </Modal>
  );
};
