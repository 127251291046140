import classNames from 'classnames';
import { Input, SingleLocation } from 'components';
import { PropertyLocation } from 'types';
import styles from './styles.module.scss';
import { createAddress } from 'helpers/utilsTS';
import { Cross } from 'components/Icons';

interface PropertyAddressProps {
  className?: string;
  unitClassName?: string;
  location?: PropertyLocation;
  valuesWrapperClassName?: string;
  searchWrapperClassName?: string;
  dropdownClassName?: string;
  address?: PropertyLocation;
  showUnit?: boolean;
  variant?: string;
  unitVariant?: string;
  updateLocation: (location) => void;
  placeholder?: string;
  unitFieldPlaceholderText?: string;
  disabled?: boolean;
  unitRemoveHandler?: () => void;
  overrideLocationsSearchStyles?: boolean;
}

export const PropertyAddress = ({
  showUnit = true,
  className,
  updateLocation,
  unitClassName,
  address,
  dropdownClassName,
  searchWrapperClassName,
  valuesWrapperClassName,
  location,
  variant,
  placeholder,
  unitFieldPlaceholderText,
  disabled,
  unitRemoveHandler,
  unitVariant,
  overrideLocationsSearchStyles,
}: PropertyAddressProps) => {
  const onAddressChange = (result) => {
    const location = result?.result?.[0];
    const { data, success } = createAddress(location, result);

    if (success && data) {
      const { city, state, zip, line1, providerPlaceId, placeName } = data;

      const updatedAddress: PropertyLocation = {
        ...(address ?? {}),
        City: city,
        State: state,
        Zip: zip,
        Line1: line1,
        ProviderPlaceId: providerPlaceId,
        PlaceName: placeName,
      };

      updateLocation(updatedAddress);
    }
  };

  const onClearAddress = () => {
    updateLocation(undefined);
  };

  const onUnitAptChange = (_, val) => {
    const updatedAddress: PropertyLocation = {
      ...(address ?? {}),
      Line2: val ?? undefined,
    };

    updateLocation(updatedAddress);
  };

  return (
    <div className={classNames(styles.propertyAddressInput, className)}>
      <SingleLocation
        placeholder={placeholder || 'Enter address...'}
        inputLabel={'Address'}
        types={['address']}
        variant={variant || Input.LIGHT_FULL}
        onResult={onAddressChange}
        testid="address"
        value={location}
        valuesWrapperClassName={classNames(styles.locationValues, valuesWrapperClassName)}
        searchWrapperClassName={
          overrideLocationsSearchStyles && searchWrapperClassName
            ? searchWrapperClassName
            : classNames(styles.locationsSearch, searchWrapperClassName)
        }
        dropdownClassName={classNames(styles.locationsDropdown, dropdownClassName)}
        onClear={onClearAddress}
        searchIcon={false}
        disabled={disabled}
      />
      {showUnit && (
        <Input
          className={classNames(styles.unit, unitClassName)}
          placeholder={unitFieldPlaceholderText || '#000'}
          label="Unit/Apt"
          altLabel="(Optional)"
          classAltLabel={styles.altLabel}
          variant={unitVariant || variant || Input.LIGHT_ROUNDED}
          onChange={onUnitAptChange}
          value={address?.Line2 || undefined}
          testid="unit_apt"
          removeHandler={unitRemoveHandler || undefined}
          removeIcon={<Cross color="#747475" />}
        />
      )}
    </div>
  );
};
