import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import { requestGetClientHighlightsPropsAction } from 'store/actions/feed';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetClientHighlightsPropsAction]: (state, { payload }) => {
      const data =
        payload?.options && payload?.options?.add
          ? [...state.data, ...get(payload, 'data.result', [])]
          : get(payload, 'data.result', initialData.data);
      return {
        state: payload.state,
        data: (data || []).filter((d) => !!d),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
