//All propertiesTabIds items MUST have a corresponding propertiesTabDescriptions definition
export const propertiesTabsIds = {
  all: 'allProps',
  new: 'newProps',
  savedSearches: 'savedSearchesProps',
  statusChanges: 'statusChangesProps',
  agentClients: 'agentClients',
  recommended: 'recommendedProps',
  clientFavorites: 'clientFavoriteProps',
  priceReductions: 'priceReductionsProps',
  watchlist: 'watchlistProps',
  backOnMarket: 'backOnMarketProps',
  suggestions: 'suggestionsProps',
  openHouses: 'openHousesProps', // Hidden from UI, until BE is up
  searchInstance: 'searchInstanceProps',
};

export const propertiesTabsDescriptions = {
  allProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties.',
  },
  newProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any new properties.',
  },
  savedSearchesProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties.',
  },
  statusChangesProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties with recent status changes.',
  },
  agentClients: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties.',
  },
  recommendedProps: {
    title: 'No Properties',
    subtitle: 'There are no recommended properties right now.',
  },
  clientFavoriteProps: {
    title: 'No Properties',
    subtitle: 'No favorites have been saved.',
  },
  priceReductionsProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties with recent price reductions.',
  },
  watchlistProps: {
    title: 'No Properties',
    subtitle: 'There are no properties on your watch list.',
  },
  backOnMarketProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties that are back on the market.',
  },
  suggestionsProps: {
    title: 'No Properties',
    subtitle: 'There are no suggested properties right now.',
  },
  openHousesProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties with upcoming open houses.',
  },
  searchInstanceProps: {
    title: 'No Properties',
    subtitle: 'We didn’t find any properties for saved search.',
  },
};

export const propertyStatus = {
  active: 'Active',
  activeUnderContract: 'ActiveUnderContract',
  pending: 'Pending',
  closed: 'Closed',
  comingSoon: 'ComingSoon',
  acceptingBackups: 'AcceptingBackups',
};

export const subFilterTypes = {
  ALL: 'All',
  NEW: 'New',
  RECOMMENDED: 'Recommended',
  OPEN_HOUSES: 'OpenHouses',
  PRICE_REDUCTIONS: 'PriceReductions',
  BACK_ON_THE_MARKET: 'BackOnTheMarket',
  STATUS_CHANGES: 'StatusChanges',
  SUGGESTIONS: 'Suggestions',
  COMMENTS: 'Comments',
};

export const propertiesSubFeed = [
  { id: subFilterTypes.ALL, label: 'All' },
  { id: subFilterTypes.NEW, label: 'New' },
  { id: subFilterTypes.STATUS_CHANGES, label: 'Status Changes' },
  { id: subFilterTypes.PRICE_REDUCTIONS, label: 'Price Reductions' },
  { id: subFilterTypes.BACK_ON_THE_MARKET, label: 'Back on the Market' },
  { id: subFilterTypes.OPEN_HOUSES, label: 'Open Houses' },
  { id: subFilterTypes.SUGGESTIONS, label: 'Suggestions' },
];

export const allPropertyStatusOptions = [
  { name: 'Active', value: propertyStatus.active },
  { name: 'Coming Soon', value: propertyStatus.comingSoon },
  { name: 'Active Under Contract', value: propertyStatus.activeUnderContract },
  { name: 'Pending', value: propertyStatus.pending },
  { name: 'Closed', value: 'Closed' },
];

export const propertyStatusOptions = [
  { name: 'Active', value: propertyStatus.active },
  { name: 'Coming Soon', value: propertyStatus.comingSoon },
  { name: 'Pending/Under Contract', value: propertyStatus.pending },
  { name: 'Closed', value: 'Closed' },
];

export const propertyStatusOptionsWithoutClosed = [
  { name: 'Active', value: propertyStatus.active },
  { name: 'Coming Soon', value: propertyStatus.comingSoon },
  { name: 'Pending/Under Contract', value: propertyStatus.pending },
];

export const propertySoldOptions = [
  { name: 'Last 1 Week', value: '1W' },
  { name: 'Last 1 Month', value: '1M' },
  { name: 'Last 3 Months', value: '3M' },
  { name: 'Last 6 Months', value: '6M' },
  { name: 'Last 1 Year', value: '1Y' },
  { name: 'Last 2 Years', value: '2Y' },
  { name: 'Last 3 Years', value: '3Y' },
  { name: 'Last 5 Years', value: '5Y' },
];

export const defaultPropertySoldOption = { name: 'Last 1 Year', value: '1Y' };

export const pendingPropertyStatus = [
  { name: 'Active Under Contract', value: propertyStatus.activeUnderContract },
  { name: 'Pending', value: propertyStatus.pending },
  { name: 'Accepting Backups', value: propertyStatus.acceptingBackups },
];

export const defaultPropertyStatusToFetch = [
  propertyStatusOptions.find(({ value }) => value === propertyStatus.active),
  propertyStatusOptions.find(({ value }) => value === propertyStatus.comingSoon),
];
export const nonActivePropertyStatus = [{ name: 'Closed', value: 'Closed' }];

export const activePropertyStatus = [{ name: 'Active', value: propertyStatus.active }];

export const propertAttribute = {
  ForSale: 'For Sale',
  Closed: 'Closed',
};

export const propertyTypes = {
  Single: 'Single Family',
  Condo: 'Condo',
  Townhouse: 'Townhouse',
  MultiFamily: 'Multi Family',
  Land: 'Land',
  Other: 'Other',
  Commercial: 'Commercial',
  CommercialLease: 'Commercial Lease',
  // ResidentialLease: 'Rental (Residential Lease)',
  'RL-Other': 'Other',
  'RL-Single': 'Single',
  'RL-Condo': 'Condo',
  'RL-Townhouse': 'Townhouse',
  'RL-Apartment': 'Apartment',
  'RI-MultiFamily': 'Multi Family',
  'Commercial-Land': 'Land',
  'CommercialLease-Land': 'Land',
};
