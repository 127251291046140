import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { Actions } from '..';

import styles from './styles.module.scss';

const Header = (props) => {
  const { className, onSave, onCancel, isPending, newFormValues } = props;
  const { formValues } = useSelector(getTransactionTaskParamsSelector);

  const getTitle = () => (!formValues ? 'New Task' : 'Edit Task');

  return (
    <div className={classNames(styles.header, className)}>
      <div className={styles.title}>
        <Icon variant={!formValues ? Icon.LIST : Icon.EDIT} />
        <div testid="header_title" className={styles.text}>
          {getTitle()}
        </div>
      </div>
      <Actions
        onSave={onSave}
        onCancel={onCancel}
        isPending={isPending}
        newFormValues={newFormValues}
      />
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isPending: PropTypes.bool,
  newFormValues: PropTypes.shape({}),
};

Header.defaultProps = {
  className: '',
  onSave: () => {},
  onCancel: () => {},
  isPending: false,
  newFormValues: null,
};

export default Header;
