import { useDrag } from 'react-dnd';
import { forwardRef, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Sidebar, UserAvatar } from 'components-antd';
import { Icons } from 'pages/FormProcess/Icons';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormSelector,
} from 'store/selectors/requestFormProcess';
import { selectDynamicFormRoleEffect } from 'store/effects/formProcess';
import { dynamicManager } from '../../../../DynamicManager/index';
import { SmartFormIcons, Spinner } from 'components';
import List from './List';
import {
  QuestionConfig,
  MoreQuestionConfigs,
  StrikeThroughQuestionConfigs,
} from 'pages/FormProcess/DynamicForm/helper';
import { useOutsideClick, useResize } from 'hooks';

import styles from './styles.module.scss';
import { MoreQuestionPopover } from './MoreQuestionPopover';
import { DocumentNameInfo } from './DocumentNameInfo';
import { EditSignatoryModal } from './EditSignatoryModal';

import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { FileNameInfo } from './FileNameInfo';
import { SignatoryModal } from './SignatoryModal';

const QUESTIONS_MAX_WIDTH = 1515;

const RenderListItem = forwardRef(
  ({ item, color, className, dragContentClass, ...props }: any, ref) => (
    <div {...props} className={classNames(styles.item, 'prevent-user-select', className)} ref={ref}>
      <div className={`${styles.itemContent} ${dragContentClass || ''}`}>
        <Icons
          className={item.icon === 'TextBox' ? styles.iconItem : ''}
          variant={item.icon}
          color={color}
        />
        <div>{item.name}</div>
      </div>
    </div>
  ),
);

const DragItem = (props) => {
  const { item, index, color, dragItemClass = '', dragContentClass = '' } = props;
  const [{ isDragging }, ref] = useDrag(
    {
      type: item.type,
      item: {
        type: item.type,
        item: item,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    },
    [item],
  );

  const isStrikeThrough = item.type === DYNAMIC_QUESTION_TYPE.StrikeThrough;
  const isTextField = item.type === DYNAMIC_QUESTION_TYPE.TextBox;

  return (
    <RenderListItem
      style={{
        opacity: isDragging ? '0.6' : '1',
      }}
      key={`${item.name}-${index}`}
      item={item}
      color={color}
      ref={ref}
      className={classNames(dragItemClass, {
        [styles.strikeThroughItem]: isStrikeThrough,
      })}
      dragContentClass={classNames(dragContentClass, { [styles.textFieldItem]: isTextField })}
    />
  );
};

export const Topbar = (props) => {
  const {
    isBundle,
    bundleName,
    openBundleList,
    setOpenBundleList,
    isWebView,
    isTemplate,
    fetchSignatories,
  } = props;

  const { formRoles, selectedRole } = useSelector(getDynamicFormEditorConfigSelector);
  const { meta: { documentName } = {} } = useSelector(getDynamicFormSelector);

  const [showSignatoryModal, setShowSignatoryModal] = useState(false);

  const [signerInfo, setSignerInfo] = useState({
    signerName: selectedRole?.userName || '',
    signerIcon: selectedRole?.avatar || '',
    signerId: selectedRole?.roleId || '',
  });

  const [openList, setOpenList] = useState(false);
  const { signerName, signerIcon, signerId } = signerInfo;

  const listRef = useRef();
  const dispatch = useDispatch();
  const [templateSaving, setTemplateSaving] = useState(false);

  const {
    screen: { width },
  } = useResize();
  const canAddRoles = dynamicManager.canAddRoles();

  const formRoleOptions = formRoles;
  useOutsideClick([listRef], () => setOpenList(false));

  useEffect(() => {
    setSignerInfo({
      signerName: selectedRole?.userName,
      signerIcon: selectedRole?.avatar || '',
      signerId: selectedRole?.roleId || '',
    });
  }, [selectedRole]);

  const onClick = (event) => {
    event.stopPropagation();
    setOpenList(!openList);
  };

  const getSignerInfo = () => {
    return (
      <div className={styles.signerWrap} onClick={onClick}>
        <div className={styles.signerContent}>
          <div className={styles.signerContentWrap}>
            <UserAvatar
              name={signerName}
              className={styles.userAvatar}
              avatar={signerIcon}
              minimizeAvatar
              shortName
            />
            <div className={styles.signerNameWrap}>
              <p className={styles.title}>Signer</p>
              <p className={styles.userName}> {signerName}</p>
            </div>
          </div>

          <div className={styles.arrowIconWrap}>
            {openList ? <Icons variant={'UpArrow'} /> : <Icons variant={'DownArrow'} />}
          </div>

          <List
            ref={listRef}
            open={openList}
            userOptions={formRoleOptions}
            selectedValue={signerId}
            onChange={handleRoleSelect}
            className={styles.usersList}
            extraOptions={
              canAddRoles
                ? () => (
                    <div
                      onClick={() => setShowSignatoryModal(true)}
                      className={styles.AddMoreSigner}
                    >
                      <p className={styles.optionUsername}>+ Add more</p>
                    </div>
                  )
                : ''
            }
          />
        </div>
      </div>
    );
  };

  const getFileInfo = () => {
    if (isBundle) {
      return (
        <DocumentNameInfo
          bundleName={bundleName || documentName}
          openList={openBundleList}
          setOpenList={setOpenBundleList}
        />
      );
    }

    return <FileNameInfo documentName={documentName} />;
  };

  const handleRoleSelect = (role) => {
    if (role) {
      dispatch(selectDynamicFormRoleEffect({ role }));
      setSignerInfo({
        signerName: role?.userName,
        signerIcon: role?.avatar || '',
        signerId: role?.roleId || '',
      });
    }
  };

  const onCloseAndMoveToForm = () => {
    setTemplateSaving(true);
    dynamicManager.handleEditorSave(null, true, () => {
      dynamicManager.progressForm();
      dynamicManager.handleFormExit();
    });
  };

  const renderRightHeader = () => {
    return (
      <div className={classNames(styles.navigationIcons)} onClick={onCloseAndMoveToForm}>
        {templateSaving ? (
          <Spinner loaderClassName={styles.loadingSpinner} />
        ) : (
          <SmartFormIcons variant="cancel" />
        )}
      </div>
    );
  };

  const getQuestions = () => {
    const configs: any = QuestionConfig(width);

    return configs;
  };

  const getMoreQuestionConfig = () => {
    return [...MoreQuestionConfigs(width), ...StrikeThroughQuestionConfigs];
  };

  const questionsConfiguration = getQuestions();

  const showMore = width <= QUESTIONS_MAX_WIDTH;

  return (
    <Sidebar
      className={classNames(styles.topBarContainer, {
        [styles.webViewTopBarContainer]: isWebView,
      })}
    >
      {canAddRoles &&
        (isTemplate ? (
          <SignatoryModal open={showSignatoryModal} onClose={() => setShowSignatoryModal(false)} />
        ) : (
          <EditSignatoryModal
            open={showSignatoryModal}
            refetchSignatories={fetchSignatories}
            onClose={() => setShowSignatoryModal(false)}
          />
        ))}

      <div className={styles.topBarWrap}>
        {getFileInfo()}
        {getSignerInfo()}

        <div className={classNames(styles.dynamicFormTopbar)}>
          {questionsConfiguration.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className={classNames(styles.groupSection, {
                [styles.strikeThroughGroup]: group.groupName === 'Strike Through Fields',
              })}
            >
              {group.items.map((item, index) => (
                <DragItem
                  key={index}
                  item={item}
                  index={index}
                  color={selectedRole.color?.border}
                />
              ))}

              {groupIndex === questionsConfiguration.length - 1 && showMore ? (
                <MoreQuestionPopover
                  DragItem={DragItem}
                  selectedRole={selectedRole}
                  questions={getMoreQuestionConfig()}
                  dragItemClass={styles.moreDragItem}
                  dragContentClass={styles.moreDragContent}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        {renderRightHeader()}
      </div>
    </Sidebar>
  );
};
