const IdeaBulb = ({ color }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 24H8M24 6V8M40 24H42M11.2 11.2L12.6 12.6M36.8 11.2L35.4 12.6M19.4 34H28.6M18 32C16.3209 30.7407 15.0807 28.985 14.4549 26.9817C13.8291 24.9783 13.8495 22.8288 14.5132 20.8377C15.1769 18.8466 16.4502 17.1148 18.1529 15.8876C19.8556 14.6604 21.9012 14 24 14C26.0988 14 28.1444 14.6604 29.8471 15.8876C31.5498 17.1148 32.8231 18.8466 33.4868 20.8377C34.1505 22.8288 34.1709 24.9783 33.5451 26.9817C32.9193 28.985 31.6791 30.7407 30 32C29.2191 32.773 28.6312 33.7189 28.2837 34.7612C27.9363 35.8036 27.8391 36.9131 28 38C28 39.0609 27.5786 40.0783 26.8284 40.8284C26.0783 41.5786 25.0609 42 24 42C22.9391 42 21.9217 41.5786 21.1716 40.8284C20.4214 40.0783 20 39.0609 20 38C20.1609 36.9131 20.0637 35.8036 19.7163 34.7612C19.3688 33.7189 18.7809 32.773 18 32Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IdeaBulb;
