import { createAction } from 'redux-actions';

export const requestClientDashboardNewMatchesAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_NEW_MATCHES',
);

export const requestClientDashboardAgentSuggestionsAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_AGENT_SUGGESTIONS',
);

export const requestClientDashboardAgentCommentsAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_AGENT_COMMENTS',
);

export const requestClientDashboardOpenHousesAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_OPEN_HOUSES',
);

export const requestClientDashboardPriceReductionsAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_PRICE_REDUCTIONS',
);

export const requestClientDashboardBackOnMarketsAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_BACK_ON_MARKETS',
);

export const requestClientDashboardMyTransactionsAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_MY_TRANSACTIONS',
);

export const requestClientDashboardUpcomingShowingsAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_UPCOMING_SHOWINGS',
);

export const requestClientDashboardNeedsAttentionAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_NEEDS_ATTENTION',
);

export const requestClientDashboardAddressesValuationAction = createAction(
  'REQUEST/CLIENT_DASHBOARD_ADDRESSES_VALUATION',
);
