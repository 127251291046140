import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import RequestCategoriesWrapper from 'pages/Services/components/RequestCategoriesWrapper';
import CategoriesList from 'pages/Services/components/CategoriesList';
import { Plus, Minus } from 'components/Icons';
import { Row } from 'components-antd';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT, TEAM_BASIC } from 'settings/constants/roles';
import styles from './styles.module.scss';
import { useKitsQuoteRedirect } from 'pages/Services/hooks/use-kits-quote-redirect';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import {
  conciergeAgentDefaultItems,
  conciergeBasicAgentDefaultItems,
} from 'pages/Services/constants';

interface ConciergeCategoriesListProps {
  setIsSearch: (arg0: boolean) => void;
}

export const ConciergeCategoriesList = ({ setIsSearch }: ConciergeCategoriesListProps) => {
  const list = useSelector(getAllServicesCategoriesList);
  const userRole = useSelector(getUserRoleSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const [showAllCategories, setShowAllCategories] = useState<boolean>(false);

  useKitsQuoteRedirect();

  const handleCategoryClick = () => {
    setIsSearch(true);

    return;
  };

  const sortedList = list.sort((a: { Title: string }, b: { Title: string }) =>
    a.Title.localeCompare(b.Title),
  );

  const itemsToShow = showAllCategories ? sortedList : sortedList.slice(0, 9);

  return (
    <RequestCategoriesWrapper className={styles.wrapper}>
      {userRole === AGENT ? (
        <CategoriesList
          items={
            agentRole === TEAM_BASIC ? conciergeBasicAgentDefaultItems : conciergeAgentDefaultItems
          }
          onClick={handleCategoryClick}
        />
      ) : null}
      <Row justify="start" className={styles.dividerWrapper}>
        Browse
      </Row>
      <CategoriesList items={itemsToShow} onClick={handleCategoryClick} />
      <Row justify="center" className={styles.showMoreButtonWrapper}>
        {sortedList.length > 9 && (
          <button
            className={styles.showMoreButton}
            onClick={() => setShowAllCategories((prev) => !prev)}
          >
            <div>
              <div>{showAllCategories ? <Minus /> : <Plus />}</div>
              <div>{showAllCategories ? 'Hide' : 'Show All Categories'}</div>
            </div>
          </button>
        )}
      </Row>
    </RequestCategoriesWrapper>
  );
};
