import { FormsTableStatus, TransactionFormsStatus } from 'app-constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFormProcessEffect } from 'store/effects/formProcess';
import { getAllFormProcessesSelector } from 'store/selectors/requestFormProcess';
import { getTransactionSelector } from 'store/selectors/transaction';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';
import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';

export const useFetchForms = (props) => {
  const { filterPayload } = props;

  const [fetching, setFetching] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const [formsDataSource, setFormDataSource] = useState<any[]>([]);

  const dispatch = useDispatch();

  const getForms = (options?) => {
    !options?.silent && setFetching(true);
    const { statuses } = filterPayload || { statuses: '' };

    dispatch(
      getAllFormProcessEffect(
        {
          filters: {
            ...addProOrTransFilterParam(AggregatedPageTypes, {
              transactions: [transaction?.Id],
              isArchive: false,
              statuses: statuses || Object.keys(TransactionFormsStatus),
            }),
          },
        },
        options,
        (error, response) => {
          if (!error) {
            setFetching(false);
            prepareAndSetDataSource(response);
          }
        },
      ),
    );
  };

  const prepareAndSetDataSource = (response) => {
    const { waitingOnYou = [], waitingOnOthers = [], draftForms = [] } = response.data.value;

    let formsDataSource = [
      ...waitingOnYou.map((data) => ({
        ...data,
        key: data.formDocumentId,
        status: FormsTableStatus.WaitingOnYou,
      })),

      ...waitingOnOthers.map((data) => ({
        ...data,
        key: data.formDocumentId,
        status: FormsTableStatus.WaitingOnOthers,
      })),

      ...draftForms.map((data) => ({
        ...data,
        key: data.formDocumentId,
        status: FormsTableStatus.Draft,
      })),
    ];

    setFormDataSource(formsDataSource);
  };

  useEffect(() => {
    if (transaction?.Id) {
      getForms();
    }
  }, [filterPayload, transaction]);

  return { formsDataSource, fetching, getForms };
};
