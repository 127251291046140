import { VALIDATION_TYPES } from '../../../ValidationSettingsPopover/helper';

export const getGroupFieldAnswers = (field, answers) => {
  const { options } = field;
  let optionAnswers = {};

  options.forEach(({ UUID }) => {
    if (answers[UUID]) {
      optionAnswers = { ...optionAnswers, [UUID]: answers[UUID] };
    }
  });

  return optionAnswers;
};

export const canFillNextField = (field, allowOneAnswer, filledAnswersCount) => {
  const { validation } = field;

  let canFill = true;

  if (!filledAnswersCount || allowOneAnswer) return canFill;

  const { value, max, type } = validation;

  if (type === VALIDATION_TYPES.AtLeast) return true;
  else if (type === VALIDATION_TYPES.Exactly) return value !== filledAnswersCount;
  else {
    const maximumValue = max || value;
    return filledAnswersCount <= maximumValue - 1;
  }
};
