import { useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Menu } from 'components-antd';
import { getHeaderLinksByRoles } from 'settings/navigation/linksByRoles';
import {
  getUserRolesSelector,
  getUserDataSelector,
  getUserRolesMapSelector,
} from 'store/selectors/user';
import { routes } from 'settings/navigation/routes';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { requestGetServicesCategoriesEffect } from 'store/effects/servicesCategories';

interface CommonHeaderProps {
  mode?: 'horizontal' | 'vertical';
}

export const CommonHeader = ({ mode = 'horizontal' }: CommonHeaderProps) => {
  const dispatch = useDispatch();
  const roles = useSelector(getUserRolesSelector);
  const user = useSelector(getUserDataSelector);
  const addresses = user?.Client?.Address || [];
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const categories = useSelector(getAllServicesCategoriesList);

  useEffect(() => {
    if (isClient) {
      dispatch(requestGetServicesCategoriesEffect());
    }
  }, []);

  const links = useMemo(
    () => getHeaderLinksByRoles([...(roles || []), agentRole], { user }, categories),
    [roles, agentRole, user, categories],
  );
  const { pathname } = useLocation();

  const getPath = () => {
    if (pathname.includes(routes.clientProfile)) return null;
    if (pathname.includes(routes.properties)) return routes.properties;
    if (pathname.includes(routes.servicesQuotes)) return routes.servicesQuotes;
    if (pathname.includes(routes.services)) return routes.services;
    if (pathname.includes(routes.workshop)) return routes.transactions;
    if (pathname.includes(routes.clarity)) return routes.clarity;
    if (pathname.includes(routes.radar)) return routes.radar;
    if (pathname.includes(routes.vault)) return routes.vault;
    if (pathname.includes(routes.symphony)) return routes.symphony;
    if (pathname.includes(routes.intel)) return routes.intel;
    if (
      (pathname.includes(routes.clientDashboard) || pathname.includes(routes.index)) &&
      roles?.includes(CLIENT)
    )
      return routes.clientDashboard;
    if (pathname.includes(routes.index) && roles?.includes(AGENT)) return routes.index;

    return pathname;
  };

  return (
    <span className={styles.linkContainer}>
      <Menu
        data-testid="header-links"
        mode={mode}
        inlineCollapsed={false}
        className={classNames(
          styles.links,
          { [styles.linksHorizontal]: mode === 'horizontal' },
          { [styles.linksVertical]: mode === 'vertical' },
        )}
        getPopupContainer={(triggerNode) => triggerNode}
        activeKey={getPath()}
      >
        {map(links, ({ link, title }) => {
          if (link === '/intel' && addresses?.length < 1) return null;
          return (
            <Menu.Item key={link}>
              <NavLink
                className={styles.link}
                title={title}
                data-testid={`header-link-${title}`}
                to={link}
              >
                {title}
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    </span>
  );
};
