import { createAction } from 'redux-actions';

export const requestPostListingFiltersAction = createAction('REQUEST/POST_LISTING_FILTERS');

// Clarity AI Actions

export const requestAISessionsAction = createAction('REQUEST/AI_SESSIONS');
export const createAISessionAction = createAction('CREATE/AI_SESSION');
export const requestSessionMessagesAction = createAction('REQUEST/AI_SESSION_MESSAGES');
export const generateAIReportAction = createAction('GENERATE/AI_REPORT');
