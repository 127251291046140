import React from 'react';

export const StarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_314_1197)">
      <path
        d="M10.0014 14.792L4.85802 17.4962L5.84052 11.7687L1.67969 7.71283L7.42969 6.87783L10.0014 1.66699L12.573 6.87783L18.323 7.71283L14.1622 11.7687L15.1447 17.4962L10.0014 14.792Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_314_1197">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const OpenFolderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 6V4.5C16 3.67157 15.3284 3 14.5 3H9.25C9.09262 3 8.94443 2.9259 8.85 2.8L7.95 1.6C7.66672 1.22229 7.22214 1 6.75 1H2.5C1.67157 1 1 1.67157 1 2.5V12.5C1 13.3284 1.67157 14 2.5 14H14.019C14.6149 14 15.1543 13.6472 15.3932 13.1012L17.8871 7.40082C18.1762 6.74013 17.6921 6 16.971 6H4.95975C4.37467 6 3.84299 6.34019 3.59781 6.87141L1 12.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const MessageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 15V5.25C2 4.65326 2.23705 4.08097 2.65901 3.65901C3.08097 3.23705 3.65326 3 4.25 3H11.75C12.3467 3 12.919 3.23705 13.341 3.65901C13.7629 4.08097 14 4.65326 14 5.25V9.75C14 10.3467 13.7629 10.919 13.341 11.341C12.919 11.7629 12.3467 12 11.75 12H5L2 15Z"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12V13.9375C7 14.4845 7.2173 15.0091 7.60409 15.3959C7.99089 15.7827 8.51549 16 9.0625 16H15.25L18 18.5V10.0625C18 9.51549 17.7827 8.99089 17.3959 8.60409C17.0091 8.2173 16.4845 8 15.9375 8H14"
      stroke="#AAABAB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TransactionMessageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M5.68229 16.0581L6.60236 16.4498L6.80022 15.9851L6.54365 15.5501L5.68229 16.0581ZM4.04135 19.912L3.12128 19.5203H3.12128L4.04135 19.912ZM4.68134 20.5744L5.04126 21.5074H5.04126L4.68134 20.5744ZM8.72693 19.0137L9.20902 18.1376L8.80125 17.9132L8.36701 18.0807L8.72693 19.0137ZM19.5781 12C19.5781 15.866 16.4441 19 12.5781 19V21C17.5487 21 21.5781 16.9706 21.5781 12H19.5781ZM12.5781 5C16.4441 5 19.5781 8.13401 19.5781 12H21.5781C21.5781 7.02944 17.5487 3 12.5781 3V5ZM5.57811 12C5.57811 8.13401 8.71212 5 12.5781 5V3C7.60755 3 3.57811 7.02944 3.57811 12H5.57811ZM6.54365 15.5501C5.93029 14.51 5.57811 13.2977 5.57811 12H3.57811C3.57811 13.6647 4.03101 15.2267 4.82093 16.5661L6.54365 15.5501ZM4.96142 20.3038L6.60236 16.4498L4.76222 15.6663L3.12128 19.5203L4.96142 20.3038ZM4.32142 19.6414C4.73373 19.4824 5.13454 19.8972 4.96142 20.3038L3.12128 19.5203C2.60191 20.7401 3.80435 21.9846 5.04126 21.5074L4.32142 19.6414ZM8.36701 18.0807L4.32142 19.6414L5.04126 21.5074L9.08685 19.9467L8.36701 18.0807ZM12.5781 19C11.3548 19 10.2075 18.687 9.20902 18.1376L8.24483 19.8898C9.53123 20.5977 11.0092 21 12.5781 21V19Z"
      fill="#515151"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0586 12C10.0586 12.8284 9.38702 13.5 8.55859 13.5C7.73017 13.5 7.05859 12.8284 7.05859 12C7.05859 11.1716 7.73017 10.5 8.55859 10.5C9.38702 10.5 10.0586 11.1716 10.0586 12ZM14.0586 12C14.0586 12.8284 13.387 13.5 12.5586 13.5C11.7302 13.5 11.0586 12.8284 11.0586 12C11.0586 11.1716 11.7302 10.5 12.5586 10.5C13.387 10.5 14.0586 11.1716 14.0586 12ZM16.5586 13.5C17.387 13.5 18.0586 12.8284 18.0586 12C18.0586 11.1716 17.387 10.5 16.5586 10.5C15.7302 10.5 15.0586 11.1716 15.0586 12C15.0586 12.8284 15.7302 13.5 16.5586 13.5Z"
      fill="#515151"
    />
  </svg>
);

export const ArchiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.59435 4.76745L2.62651 10.671C2.59252 10.7729 2.5752 10.8797 2.5752 10.9872V14.5999C2.5752 15.7045 3.47063 16.5999 4.57519 16.5999H15.4252C16.5298 16.5999 17.4252 15.7045 17.4252 14.5999V10.9872C17.4252 10.8797 17.4079 10.7729 17.3739 10.671L15.406 4.76745C15.1338 3.95076 14.3695 3.3999 13.5087 3.3999H6.49171C5.63085 3.3999 4.86658 3.95076 4.59435 4.76745Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.2251 8.3501H7.1126V8.7626C7.1126 10.3573 8.40538 11.6501 10.0001 11.6501V11.6501C11.5948 11.6501 12.8876 10.3573 12.8876 8.7626V8.3501H15.7751"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
