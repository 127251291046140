import React, { memo, useMemo } from 'react';

const Organization = memo(
  ({ className, color = '#AAABAB' }: React.HTMLAttributes<HTMLDivElement>) =>
    useMemo(
      () => (
        <div className={className}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.4167 7.41602H5.58333C4.57081 7.41602 3.75 8.23683 3.75 9.24935V17.4993C3.75 18.5119 4.57081 19.3327 5.58333 19.3327H18.4167C19.4292 19.3327 20.25 18.5119 20.25 17.4993V9.24935C20.25 8.23683 19.4292 7.41602 18.4167 7.41602Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.33301 7.41667V5.58333C8.33301 5.0971 8.52616 4.63079 8.86998 4.28697C9.2138 3.94315 9.68011 3.75 10.1663 3.75H13.833C14.3192 3.75 14.7856 3.94315 15.1294 4.28697C15.4732 4.63079 15.6663 5.0971 15.6663 5.58333V7.41667"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 12V12.0119"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.75 12.916C6.30895 14.2055 9.13453 14.8772 12 14.8772C14.8655 14.8772 17.6911 14.2055 20.25 12.916"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      [className],
    ),
);

export default Organization;
