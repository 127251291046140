import { useDispatch, useSelector } from 'react-redux';

import MozzieLg from 'images/mozzie-lg.png';
import { getUserSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import { useState } from 'react';
import { createAISessionEffect } from 'store/effects';
import { Button } from 'components-antd';

export const EmptyMessages = ({ sessions, fetchSessions }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(getUserSelector);
  const [loading, setLoading] = useState(false);

  const createNewSession = () => {
    setLoading(true);
    dispatch(
      createAISessionEffect({}, {}, () => {
        fetchSessions(true);
        setLoading(false);
      }),
    );
  };

  return (
    <div className={styles.emptyMessagesContainer}>
      <img src={MozzieLg} alt="mozzieLg" />
      <p>
        Hi {data?.FirstName}! 👋 Need insights, reports, or ideas?
        <br />
        I&apos;m here to help—just ask!
      </p>
      {!sessions?.length && (
        <div className={styles.createSession}>
          <Button variant={'hollow-bordered'} loading={loading} onClick={createNewSession}>
            Start New Session ...
          </Button>
        </div>
      )}
    </div>
  );
};
