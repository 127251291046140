import { ServiceIcons, ServiceIconTypes } from 'pages/Services/icons';

export enum ConciergeDefaultCategories {
  Draft = -1,
  Pending = -2,
  All = 0,
}

export const conciergeAgentDefaultItems = [
  {
    Id: ConciergeDefaultCategories.Draft,
    SvgIcon: <ServiceIcons icon={ServiceIconTypes.drafts} />,
    Title: 'Drafts',
  },
  {
    Id: ConciergeDefaultCategories.Pending,
    SvgIcon: <ServiceIcons icon={ServiceIconTypes.pending} />,
    Title: 'Pending',
  },
  {
    Id: ConciergeDefaultCategories.All,
    SvgIcon: <ServiceIcons icon={ServiceIconTypes.all} />,
    Title: 'All',
  },
];

export const conciergeBasicAgentDefaultItems = [
  {
    Id: ConciergeDefaultCategories.Pending,
    SvgIcon: <ServiceIcons icon={ServiceIconTypes.pending} />,
    Title: 'Pending',
  },
  {
    Id: ConciergeDefaultCategories.All,
    SvgIcon: <ServiceIcons icon={ServiceIconTypes.all} />,
    Title: 'All',
  },
];
