import { useEffect, useState } from 'react';
import { EmptyTemplates, TemplateItem } from '..';

import styles from './styles.module.scss';

export const TabContent = ({ searchString, emailTemplates, refetchEmailTemplates }) => {
  const [localEmailTemplates, setLocalEmailTemplates] = useState(emailTemplates);

  useEffect(() => {
    // Filter email templates based on search string
    if (searchString) {
      const filteredTemplates = emailTemplates.filter(
        (template) =>
          template?.Subject?.toLowerCase().includes(searchString.toLowerCase()) ||
          template?.Body?.toLowerCase().includes(searchString.toLowerCase()),
      );
      setLocalEmailTemplates(filteredTemplates);
    } else setLocalEmailTemplates(emailTemplates);
  }, [searchString, emailTemplates]);

  return (
    <div className={styles.myEmailTemplates}>
      {localEmailTemplates?.length > 0 ? (
        localEmailTemplates?.map((item) => (
          <TemplateItem
            key={item?.Id}
            emailTemplateId={item?.Id}
            createdBy={item?.CreatedBy}
            subject={item?.Subject}
            body={item?.Body}
            isPublishedToTeam={item?.PublishToTeam}
            refetchEmailTemplates={refetchEmailTemplates}
          />
        ))
      ) : (
        <EmptyTemplates />
      )}
    </div>
  );
};
