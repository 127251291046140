import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  getFormattedListingDetailBuyerMatch,
  getLoadingListingData,
} from 'store/selectors/listingDetail';
import {
  getAdditionalFeatures,
  getMlsScoreByIdWithStatus,
  getPropertyInsights,
} from 'store/selectors/mlsScores';
import {
  requestGetMatchedClientsWithScoresByMlsEffect,
  requestGetPropertyAdditionalFeaturesEffect,
  requestGetPropertyInsightsEffect,
} from 'store/effects/mlsScores';

import { Accordion, Wrapper, CircularProgressBar } from 'components';
import { map } from 'lodash-es';
import MatchUserListItem from './MatchUserListItem';
import MatchItemsList from './MatchItemsList';
import { getUserEmail, getUserInsight } from 'store/selectors/user';
import { insightUpdateData } from 'store/effects';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { featureFlags } from 'utils/featureFlags';

const Title = ({ count }) => (
  <div className={styles.titleContainer}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#219653" />
      <path
        d="M17 12.166C17 14.099 15.433 15.666 13.5 15.666C11.567 15.666 10 14.099 10 12.166C10 10.233 11.567 8.66602 13.5 8.66602C15.433 8.66602 17 10.233 17 12.166Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M20 15.2422C20.7076 15.0818 21.3806 14.672 21.8355 14.081C22.2903 13.49 22.5386 12.7535 22.5391 11.9938C22.5395 11.2342 22.2921 10.4973 21.838 9.90575C21.3839 9.31417 20.7074 8.90355 20 8.74219"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 23C7.87394 21.1303 9.59315 19.5 11.4999 19.5H15.5C17.4067 19.5 19.1261 21.1303 19.5 23"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 19.5C24.5 20.1667 25.5 23 25.5 23"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <p className={styles.title}>{`Potential Match: ${count} Clients`}</p>
  </div>
);

Title.propTypes = {
  count: PropTypes.number.isRequired,
};

export const AgentMatchScoreTitle = ({ match, insight, onChange }) => (
  <div className={styles.container}>
    <div className={styles.titleContainer}>
      <p className={styles.header}>Property Analysis</p>
      <div className={styles.insights}>
        <span>Insights: </span>
        <div
          className={styles.container}
          onClick={(e) => {
            e.stopPropagation();
            onChange(!insight);
          }}
        >
          <span>{insight ? 'On' : 'Off'}</span>
        </div>
      </div>
    </div>
    <div className={styles.matchPercentage}>
      <CircularProgressBar size="32" percentage={match} />
      <div testid="match_title" className={styles.title}>{`${match}% Match`}</div>
    </div>
  </div>
);

const Match = () => {
  const dispatch = useDispatch();
  const { dataId } = useSelector(getLoadingListingData);
  const { isData, buyers, isLoading } = useSelector(getMlsScoreByIdWithStatus(dataId));
  const { propertyDetails, propertyDetailsWhitelist } = featureFlags;
  const { propertyInsightsData, isLoading: isLoadingPropertyInsight } =
    useSelector(getPropertyInsights);
  const { additionalFeaturesData, isLoading: isLoadingAdditionalFeaturesData } =
    useSelector(getAdditionalFeatures);
  const userEmail = useSelector(getUserEmail);

  const { match, mustHave, wants, matchedCriterias, additionalFeatures } = useSelector(
    getFormattedListingDetailBuyerMatch({
      propertyInsights: {
        ...propertyInsightsData?.Needs,
        ...propertyInsightsData?.Wants,
      },
      additionalFeatures: additionalFeaturesData,
    }),
  );

  const { additionalMustHave, additionalWants } = additionalFeatures || {};

  const insight = useSelector(getUserInsight);
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    if (!isData) {
      dispatch(requestGetMatchedClientsWithScoresByMlsEffect({ ids: [dataId] }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // AI-feature testing
  // useEffect(() => {
  //   if (propertyDetails && propertyDetailsWhitelist.includes(userEmail)) {
  //     const searchCriteria = JSON.parse(localStorage.getItem('searchCriteria'));
  //     if (match) {
  //       dispatch(
  //         requestGetPropertyInsightsEffect({
  //           id: dataId,
  //           MatchScore: match,
  //           MatchedCriteria: matchedCriterias,
  //         }),
  //       );
  //     }
  //     if (searchCriteria?.KeywordPrefs?.length) {
  //       dispatch(
  //         requestGetPropertyAdditionalFeaturesEffect({
  //           id: dataId,
  //           matchCriteria: searchCriteria?.KeywordPrefs ?? [],
  //         }),
  //       );
  //     }
  //   }
  // }, [match]);

  const onInsightChange = (value) => {
    dispatch(insightUpdateData(value));
  };

  return (
    <div className={styles.matchContainer}>
      {query?.showscore !== 'false' && match !== undefined && match !== null ? (
        <Wrapper
          isPending={isLoadingPropertyInsight || isLoadingAdditionalFeaturesData}
          className={styles.matchBlock}
        >
          <AgentMatchScoreTitle match={match} insight={insight} onChange={onInsightChange} />
          <div className={styles.matchContent}>
            {!!mustHave?.length && (
              <div testid="match_needs_block" className={styles.needsContainer}>
                <p className={styles.matchItemsTitle}>NEEDS</p>
                <MatchItemsList items={mustHave} insight={insight} />
              </div>
            )}
            {!!additionalMustHave?.length && (
              <div className={styles.needsContainer}>
                <p className={styles.matchItemsTitle}>Additional Features (Needs)</p>
                <MatchItemsList items={additionalMustHave} />
              </div>
            )}
            {!!wants?.length && (
              <div testid="match_wants_block" className={styles.wantsContainer}>
                <p className={styles.matchItemsTitle}>WANTS</p>
                <MatchItemsList items={wants} insight={insight} />
              </div>
            )}
            {!!additionalWants?.length && (
              <div className={styles.needsContainer}>
                <p className={styles.matchItemsTitle}>Additional Features (Wants)</p>
                <MatchItemsList items={additionalWants} />
              </div>
            )}
            {propertyInsightsData?.Summary && (
              <>
                <p className={styles.matchItemsTitle}>Summary</p>
                <p>{propertyInsightsData?.Summary}</p>
              </>
            )}
          </div>
        </Wrapper>
      ) : null}
      {!!buyers?.length && (
        <Wrapper isPending={isLoading} className={styles.matchBlock}>
          <p className={styles.sub1}>Client Searches</p>
          <p className={styles.sub2}>
            This property overlaps with active searches for the following clients:
          </p>
          <div className={styles.matchContent}>
            <div className={styles.buyersList}>
              {map(buyers, (item, id) => (
                <MatchUserListItem item={item} key={id} />
              ))}
            </div>
          </div>
        </Wrapper>
      )}
    </div>
  );
};

export default Match;
