import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { TitleValueTable, PdfViewModal } from 'components';
import { getDateTime, getPropertyAddress, getPropertyTwoLinesAddress } from 'helpers';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import Actions from 'pages/Quotes/components/QuoteDetails/components/Content/ThirdPartyQuote/Details/Actions';
import { CLIENT } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const YourRequest = () => {
  const [expand, setExpand] = useState(false);
  const { quote } = useSelector(getQuoteDetailsSelector);

  const onDownload = () => {
    axios.get(quote.RequestQuoteFile?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, `${getPropertyAddress(quote?.Property?.Address)}.pdf`);
    });
  };

  const onExpand = () => {
    setExpand(true);
  };

  const getClientName = () =>
    quote?.Client ? `${(quote.Client.FirstName ?? '') + (' ' + quote.Client.LastName)}` : undefined;

  const requestDetails = [
    {
      title: 'Property:',
      value: quote?.Property?.Address ? getPropertyTwoLinesAddress(quote.Property.Address) : '-',
    },
    {
      title: 'Client:',
      value: getClientName() || '-',
    },
    {
      title: 'Date & Time:',
      value: getDateTime(quote?.UpdatedDate) || '-',
    },
  ];

  return (
    <>
      <div className={styles.requestHeader}>
        <Actions onExpand={onExpand} onDownload={onDownload} />
      </div>
      {quote?.RequestQuoteFile && (
        <>
          <TitleValueTable items={requestDetails} />
          {expand && (
            <PdfViewModal
              isOpen={expand}
              onClose={() => setExpand(false)}
              file={{
                Url: quote?.RequestQuoteFile?.Url,
                Filename: `${
                  quote?.Property?.Address
                    ? getPropertyAddress(quote?.Property?.Address)
                    : 'Requested Quote'
                }.pdf`,
              }}
            />
          )}
        </>
      )}
    </>
  );
};
