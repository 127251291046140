import { Button, Input, PropertyType } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { showSuccessMessage } from 'helpers';
import { requestEditUpdateAddressEffect } from 'store/effects/intel';

export const TypeForm = ({ value, handleClose, addressId, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    type: Yup.array().of(Yup.string().required()).min(1).required(),
  });

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: { type: value },
    validationSchema,
    onSubmit: async ({ type }) => {
      setLoading(true);
      dispatch(
        requestEditUpdateAddressEffect({ Id: addressId, propertyType: type }, {}, (err) => {
          if (!err) {
            onUpdate?.(type);
            showSuccessMessage('Type updated successfully');
            setLoading(false);
            handleClose();
          }
        }),
      );
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <span className={styles.input}>
        <PropertyType
          onChange={(value) => {
            setFieldValue('type', value);
          }}
          value={values?.type}
          type="dropdown"
          label={'Type'}
        />
      </span>
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
