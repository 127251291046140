import classNames from 'classnames';
import styles from './styles.module.scss';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';

export const RemoveRadioIcon = (props) => {
  const { options, updateGroupField, groupAnswers, setGroupAnswers, UUID, index } = props;

  const handleRemoveRadioField = () => {
    let groupOptions = [...options];
    const optionItem = groupOptions[index];

    if (optionItem.id) {
      groupOptions[index].isDeleted = true;
      groupOptions[index].isUpdated = true;
    } else {
      groupOptions = groupOptions.filter((item) => item.UUID !== UUID);
    }

    updateGroupField(groupOptions, {});

    if (optionItem.id) {
      const answers = { ...groupAnswers };
      delete answers[UUID];
      setGroupAnswers(answers);
    }
  };

  return (
    <Icons
      variant={ICON_VARIANT_TYPE.Remove}
      className={styles.removeRadio}
      onClick={handleRemoveRadioField}
    />
  );
};
