import styles from './styles.module.scss';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Cross, Search } from 'components/Icons';
import { AutoComplete, Option } from 'components-antd';
import { caseInsensitiveFilter, showErrorMessage } from 'helpers';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { AddVendorPayloadType } from 'pages/ServicesCategory/components/AddVendorModal/constants';

type ServicesOfferedPropsType = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
  partnerTags: any[] | undefined;
};

const ServicesOffered = (props: ServicesOfferedPropsType) => {
  const { newVendorPayload, setNewVendorPayload, partnerTags } = props;
  const [inputVal, setInputVal] = useState<string>('');
  const [lengthError, setLengthError] = useState<boolean>(false);
  const [categorizedPartnerTags, setCategorizedPartnerTags] = useState<
    { Id: number; Name: string; uuid: string }[]
  >([]);
  const [usedPartnerTags, setUsedPartnerTags] = useState<
    { Id: number; Name: string; uuid: string }[]
  >([]);
  const divRef = useRef<any>(null);

  useEffect(() => {
    if (divRef.current) {
      const secondChild = divRef.current.children[1];
      if (secondChild) {
        secondChild.style.flexGrow = '1';
      }
    }
  }, [divRef?.current]);

  useEffect(() => {
    if (newVendorPayload.Category?.value && partnerTags?.length) {
      const targetCategory = partnerTags.find(
        (category) => category.Id === newVendorPayload.Category?.value,
      );
      if (targetCategory)
        setCategorizedPartnerTags(
          targetCategory.Tags.map((tag) => {
            return { Id: tag.Id, Name: tag.Name, uuid: uuid() };
          }),
        );
    }
  }, [partnerTags, newVendorPayload.Category]);

  const handleSelect = (value) => {
    if (lengthError || !value) return;

    const found = categorizedPartnerTags.find((tag) => tag.Id === value);
    const exists = newVendorPayload.ServicesOffered?.find(
      (ser) => ser.value && ser.value === found?.Id,
    );

    if (found && !exists) {
      setNewVendorPayload((prev) => {
        return {
          ...prev,
          ServicesOffered: [
            ...prev.ServicesOffered,
            {
              name: found.Name,
              value: found.Id,
              uuid: found.uuid,
            },
          ],
        };
      });
      setUsedPartnerTags((prev) => [...prev, found]);
    } else {
      showErrorMessage('Service already added.');
    }

    setInputVal('');
  };

  const onKeyPress = (e: any) => {
    if (lengthError) return;

    if (e.key === 'Enter' && e.target.value) {
      const found = categorizedPartnerTags.find((tag) => tag.Name === e.target.value);
      const exists = newVendorPayload.ServicesOffered?.find(
        (ser) => (ser.value && ser.value === found?.Id) || ser.name === e.target.value,
      );

      if (exists) {
        showErrorMessage('Service already added.');
      } else {
        setNewVendorPayload((prev) => {
          return {
            ...prev,
            ServicesOffered: [
              ...prev.ServicesOffered,
              {
                name: e.target.value,
                value: found ? found.Id : undefined,
                uuid: uuid(),
              },
            ],
          };
        });
        if (found) setUsedPartnerTags((prev) => [...prev, found]);
      }

      setInputVal('');
    }
  };

  const removeOffering = (uuid) => {
    setNewVendorPayload((prev) => {
      return {
        ...prev,
        ServicesOffered: [...prev.ServicesOffered.filter((ser) => ser.uuid !== uuid)],
      };
    });
    const exists = usedPartnerTags.find((tag) => tag.uuid === uuid);
    if (exists) setUsedPartnerTags((prev) => [...prev.filter((tag) => tag.uuid !== uuid)]);
  };

  const getFinalList = useCallback((): { Id: number; Name: string; uuid: string }[] => {
    const finalList: { Id: number; Name: string; uuid: string }[] = [];

    categorizedPartnerTags.forEach((tag) => {
      const found = usedPartnerTags.find(
        (usedTag) =>
          usedTag.Id === tag.Id && usedTag.uuid === tag.uuid && usedTag.Name === tag.Name,
      );
      if (!found) finalList.push(tag);
    });

    const sortedList = finalList.sort((a: { Name: string }, b: { Name: string }) =>
      a.Name.localeCompare(b.Name),
    );

    return sortedList;
  }, [categorizedPartnerTags, usedPartnerTags]);

  return (
    <>
      <div className={styles.servicesInput} ref={divRef}>
        <Search />
        <AutoComplete
          onChange={(val) => {
            setInputVal(val);
            if (val.length > 255) setLengthError(true);
            else setLengthError(false);
          }}
          popupClassName={classNames(styles.autoCompleteTagDropdown)}
          placeholder={'Add Services...'}
          value={inputVal}
          onSelect={handleSelect}
          className={styles.servicesAutoComplete}
          onKeyDown={onKeyPress}
          filterOption={(inputValue, option) =>
            caseInsensitiveFilter(inputValue, option?.label as string)
          }
          clearIcon={<Cross color="#747475" />}
          allowClear
          onClear={() => {
            setInputVal('');
            setLengthError(false);
          }}
        >
          {getFinalList().length ? (
            getFinalList().map((tag) => (
              <Option key={tag.Id} label={tag.Name} value={tag.Id}>
                <div className={classNames(styles.autoCompleteOption)}>
                  <div className={classNames(styles.name)}>{tag.Name}</div>
                </div>
              </Option>
            ))
          ) : (
            <Option label={'No Member Found'} value={''} className={styles.noMemberOption}>
              <div className={classNames(styles.autoCompleteOption)}>
                <div className={styles.noResultsText}>No results found.</div>
              </div>
            </Option>
          )}
        </AutoComplete>
      </div>
      {lengthError ? <div className={styles.validation}>Max allowed characters: 255</div> : null}
      {newVendorPayload.ServicesOffered?.length ? (
        <div className={styles.servicesOffered}>
          {newVendorPayload.ServicesOffered.map((ser) => (
            <div className={styles.offering}>
              <div className={styles.text}>{ser.name}</div>
              <div
                onClick={() => {
                  removeOffering(ser.uuid);
                }}
              >
                <Cross color="#747475" variant="small" />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ServicesOffered;
