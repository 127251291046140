// eslint-disable-next-line
import { apiServer } from 'settings/web-services/api';

export function getEmailTemplates() {
  return apiServer.get('/template/email-templates');
}

export function getEmailTemplateDetails(id) {
  return apiServer.get(`/template/email-template/${id}`);
}

export function createEmailTemplate(cfg) {
  return apiServer.post('/template/email-template', cfg);
}

export function editEmailTemplate(cfg) {
  const { id, ...config } = cfg;
  return apiServer.patch(`/template/email-template/${id}`, config);
}

export function deleteEmailTemplate(id) {
  return apiServer.delete(`/template/email-template/${id}`);
}
