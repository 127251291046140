import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'components-antd';
import { Button, DatePicker, Input, Locations, PropertyType } from 'components';

import styles from './styles.module.scss';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { map, pick } from 'lodash-es';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { Add, TooltipIcon } from 'components/Icons';
import { Icons } from '../../../icons';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';
import { schema } from './validation';
import { Tooltip } from 'components-antd';
import { requestPropertyReferenceDataEffect } from 'store/effects/radarPortfolio';
import moment from 'moment';

export const AddressModal = ({ isOpen, onClose, addresses, clientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const prevAddresses = addresses.map((address) =>
    pick(address, [
      'Id',
      'ClientId',
      'closingDate',
      'isPrimary',
      'neighbourhoodRadius',
      'notes',
      'address',
    ]),
  );

  const initialAddressValues = {
    address: { delivery_line_1: null },
    notes: '',
    radius: 1,
    closingDate: undefined,
    propertyType: [],
    isPrimary: false,
  };

  const formik = useFormik({
    initialValues: initialAddressValues,
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: async ({ address, closingDate, radius, propertyType, notes, isPrimary }) => {
      const newAddress = {
        address,
        closingDate: closingDate && moment(closingDate[0]).startOf('day').toISOString(),
        neighbourhoodRadius: Number(radius),
        ...(propertyType?.length ? { propertyType } : {}),
        notes,
        isPrimary,
      };
      setLoading(true);
      const Client = { Address: [...prevAddresses, newAddress] };
      dispatch(
        updateClientDataEffect({ clientId, Client }, (err) => {
          if (!err) {
            showSuccessMessage('Addresses updated successfully');
          }
          dispatch(requestPropertyReferenceDataEffect());
          setLoading(false);
          formik.resetForm();
          onClose();
        }),
      );
    },
  });

  const onClearAddress = () => {
    formik.setFieldValue('address', {});
  };

  const hidden = !formik?.values?.address?.delivery_line_1;

  return (
    <Modal
      open={isOpen}
      width={576}
      footer={null}
      onCancel={() => {
        onClose();
        formik.resetForm();
      }}
      maskClosable={false}
      destroyOnClose
    >
      <form onSubmit={formik.handleSubmit} className={styles.content}>
        <div className={styles.icon}>
          <Icons variant={Icons.ADDRESS} />
        </div>
        <p className={styles.heading}>Add Property</p>
        <Locations
          name="address"
          onResult={(r, preparedData) => {
            r.deleteItemId ? onClearAddress() : formik.setFieldValue('address', preparedData[0]);
          }}
          placeholder="Enter Address"
          value={formik.values?.address?.delivery_line_1 ? [formik.values?.address] : []}
          multiple={false}
          variant={Locations.ROUND}
          placeholderClassName={styles.border}
          searchWrapperClassName={styles.border}
          valuesWrapperClassName={styles.border}
          rounded={true}
          shouldFilterCityLevelResults={true}
          allowedSmartyAddress={true}
          showOnlyCassAddresses={true}
          setFullCassAddress={true}
        />
        {!hidden && (
          <>
            <Checkbox
              type="checkbox"
              name="isPrimary"
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
              value={formik.values?.isPrimary}
              onChange={(e) => {
                formik.setFieldValue('isPrimary', e.target.checked);
              }}
              checked={formik.values?.isPrimary}
            >
              Primary Address
            </Checkbox>
            <div className={styles.row}>
              <DatePicker
                name={'closingDate'}
                className={styles.inputContainer}
                label="Date of Closing"
                variant={DatePicker.ROUNDED}
                options={{ enableTime: false }}
                onChange={formik.handleChange}
                allowTypedInput={true}
                allowPlugin={false}
                format={'m/d/Y'}
              />
              <Input
                className={styles.inputContainer}
                name={'radius'}
                isNumberFormat={true}
                minNumber={0}
                maxNumber={10}
                variant={Input.LIGHT_ROUND}
                onChange={(e) => {
                  let newValue = parseFloat(e.target.value);
                  if (newValue < 0.1) {
                    newValue = 0.1;
                  }
                  formik.setFieldValue('radius', newValue);
                }}
                value={formik.values.radius}
                label="Neighborhood Activity Radius"
                placeholder="0"
                icon={<p className={styles.iconRight}>Miles</p>}
                decimalScale={2}
                allowLeadingZeros={false}
                labelRightIcon={
                  <Tooltip
                    placement="top"
                    overlayClassName={classNames(styles.radiusTooltip, 'mosaikTooltip')}
                    title={
                      'This is the radius that will be used to provide neighborhood activity updates to the client (for example: nearby sales, price reductions, new listings, etc.).'
                    }
                  >
                    <span>
                      <TooltipIcon className={styles.tooltipIcon} size={'17'} color={'#AAABAB'} />
                    </span>
                  </Tooltip>
                }
              />
            </div>
            <PropertyType
              onChange={(value) => {
                formik.setFieldValue('propertyType', value);
              }}
              value={formik.values?.propertyType}
              type="dropdown"
              label={'Property Type (optional)'}
            />
            <Input
              className={styles.inputContainer}
              name={'notes'}
              variant={Input.LIGHT_ROUND}
              onChange={formik.handleChange}
              value={formik.values.notes}
              label="Notes"
              placeholder=""
              textArea={true}
            />
          </>
        )}
        <Button
          title={'Add'}
          className={styles.submit}
          type="submit"
          isPending={loading}
          disabled={!formik.isValid || hidden}
        />
      </form>
    </Modal>
  );
};
