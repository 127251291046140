import React, { useState } from 'react';
import { Avatar, Checkbox } from 'components';
import { getInitials } from 'utils';
import styles from './styles.module.scss';
import { CampaignMemberClient } from 'pages/Radar/RadarKits/AssignClientItem/types';
import { ArrowKitIcon } from 'pages/Radar/RadarKits/components/icons/ArrowKitIcon';
import classNames from 'classnames';
import { getAddressFormattedCassAddress } from 'helpers/cass';

type AssignClientItemType = {
  client?: CampaignMemberClient;
  selected?: { ClientId: number; AddressId: number }[];
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    ClientId: number | null,
    AddressId: number | null,
  ) => void;
  all?: boolean;
  allSelected?: boolean;
  isAgentView?: boolean;
};

export const AssignClientItem: React.FC<AssignClientItemType> = ({
  client,
  onChange,
  selected = [],
  all = false,
  allSelected = false,
  isAgentView = false,
}) => {
  const [collapsed, setIsCollapsed] = useState(false);
  const isSelected = (addressId: number) =>
    selected.some((item) => item.ClientId === client?.clientId && item?.AddressId === addressId);

  function onCollapseClick() {
    setIsCollapsed(!collapsed);
  }

  if (all) {
    return (
      <div className={styles.clientItem}>
        <div className={styles.leftSection}>
          <Avatar
            avatarClassName={styles.avatar}
            placeholder={<div className={styles.avatarPlaceholder}>{getInitials('All Users')}</div>}
          />
          <div className={styles.clientInfo}>
            <div>
              <span className={styles.clientName}>{'ALL'}</span>
            </div>
          </div>
        </div>
        <Checkbox checked={allSelected} onChange={(e) => onChange(e, null, null)} />
      </div>
    );
  }

  return (
    <>
      <div className={classNames(styles.clientItem, { [styles.agentView]: isAgentView })}>
        <div className={styles.leftSection}>
          {client && (
            <Avatar
              avatarClassName={styles.avatar}
              src={client.avatarUrl}
              placeholder={
                <div className={styles.avatarPlaceholder}>{getInitials(client.clientName)}</div>
              }
            />
          )}
          <div className={styles.clientInfo}>
            {client?.clientName && (
              <p className={styles.clientName} onClick={onCollapseClick}>
                {client.clientName}{' '}
                {client.addresses?.length > 0 && (
                  <span className={styles.countText}>({client.addresses.length})</span>
                )}
              </p>
            )}
          </div>
        </div>
        <div className={styles.rightSection} onClick={onCollapseClick}>
          <ArrowKitIcon className={collapsed ? styles.rotated : ''} />
        </div>
      </div>
      {!collapsed && client && (
        <div className={styles.addressList}>
          {client.addresses.map((address) => {
            const { address1, address2 } = getAddressFormattedCassAddress(address?.address);
            return (
              <div key={address.addressId} className={styles.addressRow}>
                <span className={styles.clientAddress}>
                  {address1}, {address2}
                </span>
                <Checkbox
                  checked={isSelected(address.addressId)}
                  onChange={(e) => onChange(e, client.clientId, address.addressId)}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
