import classNames from 'classnames';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import styles from './styles.module.scss';
import { RolesPopover } from '../../RolesPopover';
import { StrokeColorPopover } from '../../StrokeColorPopover';
import { StrokePopover } from '../../StrokePopover';
import { Switch } from 'components-antd';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { useState } from 'react';
import { ValidationSettingsPopover } from '../../ValidationSettingsPopover';

export const ActionButtons = (props) => {
  const {
    isHighlighted,
    onCopy,
    field,
    color,
    fieldActionProps,
    selectedFieldIndex,
    onRequiredField,
    onDeleteField,
    isRequiredPopupVisible,
    popupRef,
    setIsDisabledDrag,
    selectedField,
    onRequiredFieldPopupClick,
    handleFieldUpdate,
    onInserClauseClick,
    canChangeRole,
    isTemplate,
    updateAnswer,
    responses,
    documentIndex,
  } = props;

  const isStrikeThrough = field.type === DYNAMIC_QUESTION_TYPE.StrikeThrough;
  const isRadioGroup = field.type === DYNAMIC_QUESTION_TYPE.RadioGroup;
  const isTextbox = field.type === DYNAMIC_QUESTION_TYPE.TextBox;
  const showSettings = isRadioGroup && field.options.length > 1;

  if (!isHighlighted) return <></>;

  const actionButtons = [
    {
      variant: ICON_VARIANT_TYPE.Copy,
      onClick: onCopy,
    },
    ...(isTextbox
      ? [
          {
            variant: ICON_VARIANT_TYPE.InsertClauseWhite,
            onClick: onInserClauseClick,
          },
        ]
      : []),

    ...(showSettings
      ? [
          {
            variant: ICON_VARIANT_TYPE.Setting,
            color: '#fff',
          },
        ]
      : []),

    ...(!isStrikeThrough
      ? [
          {
            variant: ICON_VARIANT_TYPE.Required,
            onClick: onRequiredFieldPopupClick,
            color: '#fff',
          },
        ]
      : []),

    {
      variant: ICON_VARIANT_TYPE.Delete,
      onClick: onDeleteField,
    },
  ];

  return (
    <div>
      <div
        className={classNames(styles.actionButtons, {
          [styles.strikeThroughActionButtons]: isStrikeThrough,
        })}
        style={{ marginLeft: isRadioGroup && field.width < 100 ? -140 : 0 }}
      >
        {!isStrikeThrough ? (
          <RolesPopover
            field={field}
            color={color}
            selectedFieldIndex={selectedFieldIndex}
            fieldActionProps={fieldActionProps}
            canChangeRole={canChangeRole}
            isTemplate={isTemplate}
            updateAnswer={updateAnswer}
            responses={responses}
            documentIndex={documentIndex}
          />
        ) : (
          <></>
        )}

        {isStrikeThrough ? (
          <>
            <StrokePopover
              field={field}
              selectedFieldIndex={selectedFieldIndex}
              fieldActionProps={fieldActionProps}
            />

            <StrokeColorPopover
              defaultColor={field.strokeColor}
              field={field}
              selectedFieldIndex={selectedFieldIndex}
              fieldActionProps={fieldActionProps}
              setIsDisabledDrag={setIsDisabledDrag}
            />
          </>
        ) : (
          <></>
        )}
        {actionButtons.map((button) =>
          button.variant === ICON_VARIANT_TYPE.Setting ? (
            <div key={button.variant}>
              <ValidationSettingsPopover field={field} handleFieldUpdate={handleFieldUpdate} />{' '}
            </div>
          ) : (
            <div key={button.variant} className={styles.actionButton} onClick={button.onClick}>
              <Icons variant={button.variant} color={button.color || ''} />
            </div>
          ),
        )}
      </div>

      {isRequiredPopupVisible && (
        <div ref={popupRef} className={styles.actionRequiredWrap}>
          <div className={styles.actionRequiredTextWrap}>
            <Icons variant={'Required'} color={'#ff576d'} />
            <span className={styles.actionRequiredText}>Required</span>
          </div>
          <Switch defaultChecked={!!selectedField?.required} onChange={onRequiredField} />
        </div>
      )}
    </div>
  );
};
