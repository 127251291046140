export const Notes = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 9.16667V14.8611C15.0002 15.2295 14.8538 15.5827 14.5934 15.8432C14.3329 16.1037 13.9796 16.25 13.6113 16.25H5.97239C5.60403 16.25 5.25076 16.1037 4.99029 15.8432C4.72982 15.5827 4.5835 15.2295 4.5835 14.8611V5.13889C4.5835 4.77053 4.72982 4.41726 4.99029 4.1568C5.25076 3.89633 5.60403 3.75 5.97239 3.75H11.6668L13.0557 5.13889"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 12.5H10.943L17.793 5.64998C18.1391 5.30394 18.3335 4.8346 18.3335 4.34522C18.3335 3.85584 18.1391 3.3865 17.793 3.04045C17.447 2.69441 16.9777 2.5 16.4883 2.5C15.9989 2.5 15.5296 2.69441 15.1835 3.04045L8.3335 9.89047V12.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5312 3.69336L17.1408 6.30289"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
