import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AgentHeader, PropertiesList } from './components';
import Map from '../common/Map';
import { ViewSwitcher } from './components/ViewSwitcher/ViewSwitcher';
import { routes } from 'settings/navigation/routes';

import { Col, Row } from 'components-antd';

import { useAgentSearchInstance } from './hooks/useAgentSearchInstance';
import {
  getAgentPropertiesList,
  getAgentPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
  getFeedMetaSelectorV3,
} from 'store/selectors/feedv3';
import {
  getClientFavoriteMarkersSelector,
  getClientFavoritePropsSelector,
  getClientHighlightsMarkersSelector,
  getClientHighlightsSelector,
  getFeedSortSelector,
  getSubFilterTypeSelector,
} from 'store/selectors/feed';
import {
  getAgentClientsWithFavoritesCountEffect,
  getClientFavoritesPropsEffect,
  getClientHighlightsEffect,
  getSavedSearchesEffect,
  resetClientFavoritesPropsEffect,
  sortFeedEffect,
  updateSearchInstanceEffect,
} from 'store/effects';

import styles from './styles.module.scss';
import ClientSearch from '../ClientSearch';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { SORT_ASC } from 'settings/constants/sort';
import { subFilterTypes } from 'settings/constants/properties';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import {
  selectedSearchAction,
  setCriteriaAction,
  setCriteriaModeAction,
  setIsClientMatchesAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';
import { CREATE, EDIT } from 'settings/constants/mode';
import { PENDING } from 'settings/constants/apiState';
import { push } from 'connected-react-router';

const AgentFeed = () => {
  const locationService = new LocationService();
  const dispatch = useDispatch();
  const location: any = useLocation();
  locationService.setLocation(location);
  const query = locationService.setLocation(location).getQuery();
  const getAgentSearchInstance = useAgentSearchInstance();
  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const {
    agentClientMode,
    isClientMatches,
    selectedClientSavedSearch,
    selectedSearch,
    isSimpleHeaderTitleVisible,
  } = useSelector(getFeedMetaSelectorV3);

  const isHighlights =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Highlights';
  const isFavorites =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites';
  const isUnreadComments =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Comments';
  const { properties, isPending, totalCount } = useSelector(getAgentPropertiesList);
  const agentPropertiesMarkers = useSelector(getAgentPropertiesMarkersSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const sort = useSelector(getFeedSortSelector);
  const clientFavoritesProps = useSelector(getClientFavoritePropsSelector);
  const clientHighlightProps = useSelector(getClientHighlightsSelector);
  const favouriteMarkers = useSelector(getClientFavoriteMarkersSelector);
  const highlightMarkers = useSelector(getClientHighlightsMarkersSelector);
  const [viewType, setViewType] = useState('Split View');
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const { isPending: isSavedSearchPending, isIdle } = useSelector(
    getSavedSearchesFormattedSelector,
  );
  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);

  const handleMatchesClick = useCallback(() => {
    dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
    dispatch(setCriteriaModeAction({ mode: CREATE }));
    dispatch(setIsClientMatchesAction({ isClientMatches: true }));
    dispatch(setSubFilterTypeAction(subFilterTypes.NEW));
    dispatch(
      selectedSearchAction({
        selectedSearch: {
          id: undefined,
          name: 'Matches',
        },
      }),
    );
    getAgentSearchInstance(
      { criterias: {} },
      undefined,
      undefined,
      true,
      undefined,
      subFilterTypes.NEW,
    );
    dispatch(push(routes.feed));
  }, [dispatch]);

  useEffect(() => {
    if ('option' in query && firstRender) {
      switch (query.option) {
        case 'newMatches':
          handleMatchesClick();
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (location?.state?.openNewSearch) {
      setIsOpenSearchModal(true);
    }
  }, []);

  const fetchMySearchProperties = () => {
    if (criteria) {
      const searchInstance = {
        criterias: criteria,
        id: selectedSearch?.id,
      };
      getAgentSearchInstance(
        searchInstance,
        undefined,
        selectedClientSavedSearch,
        isClientMatches,
        isUnreadComments,
      );
    }
  };

  const fetchClientFavorites = () => {
    dispatch(getClientFavoritesPropsEffect({ userId: agentClientMode?.user?.Id }));
  };

  const fetchClientHighlights = () => {
    dispatch(getClientHighlightsEffect({ userId: agentClientMode?.user?.Id }));
  };

  const fetchProperties = () => {
    isFavorites
      ? fetchClientFavorites()
      : isHighlights
      ? fetchClientHighlights()
      : fetchMySearchProperties();
  };

  const isLoading =
    clientFavoritesProps.state === PENDING ||
    clientHighlightProps.state === PENDING ||
    isPending ||
    isSavedSearchPending;

  useEffect(() => {
    if ('option' in query && firstRender) {
      setFirstRender(false);
      return;
    }
    if (firstRender && (query?.option || query?.clientId) && !isLoading) {
      if (query?.clientId) {
        dispatch(
          getAgentClientsWithFavoritesCountEffect({}, {}, (err) => {
            if (!err) {
              dispatch(getClientFavoritesPropsEffect({ userId: query?.clientId }));
            }
          }),
        );
        dispatch(resetClientFavoritesPropsEffect());
      }
      return;
    }
    if (firstRender && (!isIdle || isLoading)) return;
    fetchProperties();
  }, [subFilterType, JSON.stringify(sort)]);

  useEffect(() => {
    if ('option' in query && firstRender) {
      setFirstRender(false);
      return;
    }
    if (firstRender && isIdle && !isLoading)
      dispatch(
        getSavedSearchesEffect(undefined, undefined, (err, res) => {
          if (!err) {
            if (firstRender && (query?.option || query?.clientId)) return;
            const mySearch = res?.data?.result?.find(
              (search) => search?.Name === 'My Primary Search' && !search?.ClientId,
            );
            if (mySearch && !selectedSearch?.name) {
              dispatch(setCriteriaModeAction({ mode: EDIT }));
              dispatch(
                selectedSearchAction({
                  selectedSearch: { id: mySearch.Id, name: mySearch?.Name },
                }),
              );
              getAgentSearchInstance({
                id: mySearch.Id,
                criterias: mySearch?.DefaultPropertySearchPreferences,
              });
            }
          }
        }),
      );
  }, []);

  useEffect(() => {
    if ('option' in query && firstRender) {
      setFirstRender(false);
      return;
    }
    if (subFilterType !== SORT_ASC || sort !== subFilterTypes.ALL) {
      if (isIdle) dispatch(sortFeedEffect({ order: SORT_ASC }));
      if (query?.option && query?.option !== 'newMatches')
        dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    } else if (firstRender && isIdle) {
      fetchProperties();
    }
    setFirstRender(false);
  }, []);

  let propertyProps = {
    isPending: isLoading,
    properties: isFavorites
      ? clientFavoritesProps.data
      : isHighlights
      ? clientHighlightProps.data
      : properties,
  };

  const editModeCallback = (err, criterias) => {
    if (!err) {
      getAgentSearchInstance(
        { criterias, id: selectedSearch?.id },
        undefined,
        selectedClientSavedSearch,
        isClientMatches,
        isUnreadComments,
      );
      if (selectedClientSavedSearch) {
        dispatch(getClientsSearchInstancesEffect());
      } else {
        dispatch(getSavedSearchesEffect());
      }
    }
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = {
      ...criteria,
      Locations: [polygon],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(
      updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err, newCriteria)),
    );
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  let mapProps = {
    markers: isFavorites
      ? favouriteMarkers
      : isHighlights
      ? highlightMarkers
      : agentPropertiesMarkers,
    properties: isFavorites
      ? clientFavoritesProps.data
      : isHighlights
      ? clientHighlightProps.data
      : properties,
    totalCount: isFavorites
      ? clientFavoritesProps.totalCount
      : isHighlights
      ? clientHighlightProps.totalCount
      : totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
    disableDraw: isFavorites || isUnreadComments || isHighlights,
  };

  const getViewType = (value) => {
    setViewType(value);
  };

  return (
    <div className={styles.agentFeedWrapper}>
      {isOpenSearchModal && (
        <ClientSearch onClose={() => setIsOpenSearchModal(false)} isAgent={true} />
      )}
      <AgentHeader
        setShowBlurBg={(value) => setShowBlurBg(value)}
        setIsOpenSearchModal={setIsOpenSearchModal}
      />
      <Row className={styles.agentFeed}>
        {showBlurBg && <div className={styles.backdropBlur} />}
        {viewType !== 'List Only' && (
          <Col
            md={viewType === 'Split View' ? 10 : 24}
            sm={24}
            xs={24}
            className={styles.mapContainer}
          >
            <Map {...mapProps} />
          </Col>
        )}
        {viewType !== 'Map Only' && (
          <Col
            md={viewType === 'Split View' ? 14 : 24}
            sm={24}
            xs={24}
            className={styles.propertiesContainer}
          >
            <PropertiesList
              {...propertyProps}
              isAgentType={!isFavorites}
              agentClientMode={agentClientMode}
              viewType={viewType}
            />
          </Col>
        )}
      </Row>
      <div className={styles.mapSwitchers}>
        <ViewSwitcher getView={getViewType} />
      </div>
    </div>
  );
};

export default AgentFeed;
