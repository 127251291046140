import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { Toggle } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestGetCampaignByIdEffect,
  requestGetCampaignMembersByCampaignIdEffect,
  requestPutCampaignMasterSwitchEffect,
  requestUpdateCampaignEffect,
} from 'store/effects/radarKits';
import {
  selectedSequenceCampaignMembers,
  selectedSequenceSelector,
  selectedSequenceStateSelector,
} from 'store/selectors/radarKits';
import { AssignClientsModal } from '../../AssignClientsModal';
import { PencilIcon, UsersIcon } from '../icons';
import styles from './styles.module.scss';
import { READY } from 'settings/constants/apiState';
import { isEqual } from 'lodash-es';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { useSequenceMeta } from 'pages/Radar/RadarKits/hooks/useSequenceMeta.hook';

type Props = {
  editMode?: boolean;
  handleClick?: (e) => void;
  sequenceId: string;
  isActive: boolean;
  setCampaignMembersMasterSwitch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SequenceAssignedClients: React.FC<Props> = ({
  editMode = true,
  handleClick,
  sequenceId,
  isActive: initialIsActive,
  setCampaignMembersMasterSwitch,
}) => {
  const dispatch = useDispatch();
  const campaignMembers = useSelector(selectedSequenceCampaignMembers);
  const selectedSequenceState = useSelector(selectedSequenceStateSelector);
  const selectedSequence = useSelector(selectedSequenceSelector);
  const teamRole = useSelector(getAgentTeamRoleSelector);
  const isOwnerOrAdmin = [TEAM_OWNER, TEAM_ADMIN].includes(teamRole);
  const { isSequenceOwner } = useSequenceMeta();

  const [isAssignClientsModalOpen, setIsAssignClientsModalOpen] = useState<boolean>(false);
  const isInitialized = useRef(false);
  const getSelectedFromCampaignMembers = useCallback((members) => {
    return (members || []).flatMap((client) =>
      client.Addresses.map((address) => ({
        ClientId: client.ClientId,
        AddressId: address.AddressId,
      })),
    );
  }, []);
  const [selectedClients, setSelectedClients] = useState<{ ClientId: number; AddressId: number }[]>(
    getSelectedFromCampaignMembers(campaignMembers),
  );

  const [isActive, setIsActive] = useState<boolean>(initialIsActive);
  const [isPublishedByTeam, setIsPublishedByTeam] = useState<boolean>(false);

  useEffect(() => {
    const campaignSelectedClients = getSelectedFromCampaignMembers(campaignMembers);
    if (
      !isInitialized.current &&
      selectedSequenceState === READY &&
      campaignSelectedClients?.length &&
      !selectedClients?.length
    ) {
      setSelectedClients(campaignSelectedClients);
      isInitialized.current = true;
    }
  }, [selectedSequenceState]);

  // update the state as parent take a render more to update isActive
  useEffect(() => {
    setIsActive(initialIsActive);
  }, [initialIsActive]);

  useEffect(() => {
    if (selectedSequenceState === READY && selectedSequence?.IsPublishedByTeam !== undefined) {
      setIsPublishedByTeam(selectedSequence.IsPublishedByTeam);
    }
  }, [selectedSequenceState, selectedSequence]);

  const countAssignedClients = useMemo(() => {
    if (!selectedClients.length) return 0;
    const clientIds = new Set(selectedClients?.map((client) => client.ClientId));
    return clientIds.size;
  }, [selectedClients]);

  return (
    <>
      <div className={styles.assignedClientWrapper}>
        <div className={styles.assignedClientContainer}>
          <div className={classNames(styles.iconWrapper, styles.bluebg)}>
            <UsersIcon />
          </div>
          <div className={styles.text}>
            Enrolled:{' '}
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (handleClick) {
                  handleClick(e);
                }
              }}
            >
              {countAssignedClients} Client{countAssignedClients !== 1 && 's'}
            </span>
          </div>
        </div>
        <div className={styles.toggleOuterWrapper}>
          <div className={styles.toogleWrapper}>
            <div>Active</div>
            <Toggle
              disabled={!isSequenceOwner}
              checked={isActive}
              className={styles.toggle}
              onChange={(e) => {
                e.stopPropagation();
                setIsActive((prev) => !prev);
                dispatch(
                  requestPutCampaignMasterSwitchEffect(
                    {
                      CampaignId: Number(sequenceId),
                      Active: !isActive,
                    },
                    {},
                    (err, res) => {
                      if (!err && res.data.result.Id) {
                        setCampaignMembersMasterSwitch(!isActive);
                      }
                      if (err) {
                        setIsActive(initialIsActive);
                      }
                      setIsAssignClientsModalOpen(false);
                    },
                  ),
                );
              }}
            />
          </div>
          {isOwnerOrAdmin && (
            <div className={styles.toogleWrapper}>
              <div className={styles.teamPublishText}>Publish to team</div>
              <Toggle
                checked={isPublishedByTeam}
                className={styles.toggle}
                onChange={(e) => {
                  e.stopPropagation();
                  if (selectedSequence?.Id) {
                    dispatch(
                      requestUpdateCampaignEffect({
                        Id: selectedSequence?.Id,
                        IsPublishedByTeam: !isPublishedByTeam,
                      }),
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      {isAssignClientsModalOpen && (
        <AssignClientsModal
          isOpen={isAssignClientsModalOpen}
          handleClose={() => {
            dispatch(
              requestUpdateCampaignEffect(
                {
                  Id: Number(sequenceId),
                  CampaignMembers: selectedClients,
                },
                {},
                (err, res) => {
                  dispatch(
                    requestGetCampaignByIdEffect(sequenceId, { silent: false }, (err, res) => {
                      if (!err) {
                        const sequence = res?.data?.result;
                        const members = sequence?.CampaignMembers;
                        if (Array.isArray(members)) {
                          setSelectedClients(getSelectedFromCampaignMembers(members));
                        }
                      }
                    }),
                  );
                  setIsAssignClientsModalOpen(false);
                },
              ),
            );
          }}
          closeDialog={() => {
            setSelectedClients(getSelectedFromCampaignMembers(campaignMembers));
            setIsAssignClientsModalOpen(false);
          }}
          subHeading="Selected clients will be enrolled in this KIT sequence."
          selected={selectedClients}
          setSelected={setSelectedClients}
        />
      )}
    </>
  );
};
