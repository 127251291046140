import { createAction } from 'redux-actions';

export const requestFillFormAuth = createAction('REQUEST/FILL_SMART_FORM_AUTH');

export const requestFillTemplateFormAuth = createAction('REQUEST/FILL_TEMPLATE_FORM_AUTH');

export const requestFillFormAnon = createAction('REQUEST/FILL_SMART_FORM_ANON');

export const agreeToTermsAuth = createAction('POST/AGREE_TO_TERMS_AUTH');

export const agreeToTermsAnon = createAction('POST/AGREE_TO_TERMS_ANON');

export const declineSignatureAuthAction = createAction('POST/DECLINE_SIGNATURE_AUTH');

export const declineSignatureAnonAction = createAction('POST/DECLINE_SIGNATURE_ANON');

export const unlockFormDocumentAuthAction = createAction('POST/UNLOCK_FORM_DOCUMENT_AUTH');

export const unlockFormDocumentAnonAction = createAction('POST/UNLOCK_FORM_DOCUMENT_ANON');

export const requestSignatureAction = createAction('REQUEST/GET_SIGNATURE');

export const saveSignatureAction = createAction('POST/SAVE_SIGNATURE');

export const updateStrikeThroughAction = createAction('UPDATE/UPDATE_STRIKE_THROUGH');

export const linkToFormProcessAction = createAction('UPDATE/UPDATE_LINK_TO_FORM_PROCESS');

export const unlinkFormProcessAction = createAction('UPDATE/UNLINK_TO_FORM_PROCESS');
