import Api from 'store/effects/core/api';
import {
  getMatchedClientsWithScoresByMls,
  getPropertyInsight,
  getAdditionalFeatures,
} from 'api/mlsScores';
import {
  requestGetMatchedClientsWithScoresByMls,
  requestGetPropertyInsights,
  requestGetAdditionalFeatures,
} from 'store/actions/mlsScores';

export const requestGetMatchedClientsWithScoresByMlsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetMatchedClientsWithScoresByMls,
    method: getMatchedClientsWithScoresByMls,
  });

  return sendRequest(cfg, options, cb);
};

export const requestGetPropertyInsightsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetPropertyInsights,
    method: getPropertyInsight,
  });
  return sendRequest(cfg, options, cb);
};

export const requestGetPropertyAdditionalFeaturesEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetAdditionalFeatures,
    method: getAdditionalFeatures,
  });
  return sendRequest(cfg, options, cb);
};
