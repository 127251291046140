import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { TagInput } from 'components/Transactions';
import { TaskModalHeader } from './TaskModalHeader';
import { TaskName } from './TaskName';
import { AssignedTo } from './AssignedTo';
import { CcTo } from './CcTo';
import { DueDate } from './DueDate';
import { Description } from './Description';
import { Links } from './Links';
import { Checklists } from './Checklists';
import { Files } from './Files';
import { Modal, ModalProps, Row, Col } from 'components-antd';
import { Button } from 'components-antd';
import { Button as OptionButton } from 'antd';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Icons as TaskIcons } from './../Task/ViewRevamped/Icons';
import { EditCircle } from 'components/Icons';
import { TaskTemplate } from 'types';
import { MoreOptionItem } from './MoreOptionItem/MoreOptionItem';
import { getTransactionRolesEffect } from 'store/effects';
import { AssignedToMilestone } from './AssignedToMilestone';
import { TimelineMilestonesInterface } from './AssignedToMilestone/types/TransactionMilestone';
import { convertToInitialOffset } from 'utils/templatesHelper';
import { TaskSectionContainer } from '../Task/ViewRevamped/components';
import { CollapsedTaskDetails } from './CollapsedTaskDetails';
import { EmailTemplateInput } from 'components/Transactions/EmailTemplateInput';
import { InputLabel, isEmptyHTML } from 'components';
import useIsProjectRoute from 'hooks/use-is-project-route';

import styles from './styles.module.scss';
import { getUserRolesMapSelector } from 'store/selectors/user';

interface TransactionTemplateTaskModalProps {
  task?: TaskTemplate;
  onSave: (task) => void;
  modelProps: ModalProps;
  isViewMode: boolean;
  data: TimelineMilestonesInterface[];
}

export const TransactionTemplateTaskModal = ({
  task,
  onSave,
  modelProps: { className, ...modelProps },
  isViewMode = false,
  data = [],
}: TransactionTemplateTaskModalProps) => {
  const { formValues } = useSelector(getTransactionTaskParamsSelector);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);
  const [values, setNewFormValues] = useState(task ?? formValues);

  const [showCC, setShowCC] = useState(!!task?.CcRoles?.length);
  const [open, setOpen] = useState(false);
  const [addChecklists, setAddChecklists] = useState(isViewMode);
  const [addLink, setAddLinks] = useState(isViewMode);
  const [addFiles, setAddFiles] = useState(isViewMode);
  const [addTag, setAddTag] = useState(isViewMode);
  const [addDescription, setAddDescription] = useState(isViewMode);
  const [showMdDropdown, setShowMdDropdown] = useState(false);
  const [isShowTaskSummary, setShowTaskSummary] = useState(false);
  const [addEmailTemplate, setAddEmailTemplate] = useState(isViewMode);

  const buttonRef = useRef<any>();
  const optionsRef = useRef<any>();
  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));
  const dispatch = useDispatch();
  const onFormValueChange = (values) => {
    setNewFormValues(values);
  };

  useEffect(() => {
    dispatch(getTransactionRolesEffect());
  }, []);

  useEffect(() => {
    setShowMdDropdown(values?.Initial === 'MD');
  }, [values?.Initial]);

  const onChangeField = (val, fieldName) => {
    onFormValueChange({ ...(values || {}), [fieldName]: val });
  };

  const onChangeManyFields = (data) => {
    onFormValueChange({ ...(values || {}), ...data });
  };

  const setCustomTag = (val) => {
    if (val?.trim()) {
      onChangeField(val, 'customTag');
    }
  };

  const handleSave = () => {
    onSave({ ...values });
  };

  const checkDisabled = () => {
    if (values) {
      if (showMdDropdown && !values.MilestonePublicId) {
        return true;
      }

      const hasDueDate = values.DueDate || values.DueDate === 0;

      let isDisabled = !(values?.Name && values.AssigneeRoles?.length && hasDueDate) || isViewMode;

      if (isDisabled && !isViewMode && values.ControlOperatorOffset) {
        const { DueDate }: any = convertToInitialOffset(
          values.ControlOperatorOffset,
          () => {},
          undefined,
          true,
        );

        return isDisabled && !(DueDate || DueDate === 0);
      } else {
        return isDisabled;
      }
    }

    return true;
  };

  const getTitle = (text: string) => (isViewMode ? `View ` : `Add `) + text;

  const onEditTaskDetails = () => {
    setShowTaskSummary(false);
    setAddDescription(false);
    setAddTag(false);
    setAddLinks(false);
    setAddFiles(false);
    setAddChecklists(false);
    setAddEmailTemplate(false);
  };

  const areLinksAdded = useMemo(
    () => values?.Links?.some((item) => item?.link.trim() !== '' || item?.label.trim() !== ''),
    [values?.Links],
  );

  const showMoreOptions = () =>
    (!addDescription && isEmptyHTML(values?.Description)) ||
    (!addTag && !values?.Categories?.length) ||
    (!addLink && !areLinksAdded) ||
    (!addFiles && !values?.Documents?.length) ||
    (!addChecklists && !values?.Checklists?.length) ||
    (!addEmailTemplate && !values?.EmailTemplate);

  useEffect(() => {
    if (values?.EmailTemplate) {
      const { Id, CurrentVersion } = values.EmailTemplate;
      values.EmailTemplate = {
        ...values.EmailTemplate,
        EmailTemplateId: Id,
        EmailTemplateVersionId: CurrentVersion,
      };
    }
  }, []);

  return (
    <Modal
      width={675}
      footer={null}
      maskClosable={false}
      destroyOnClose
      className={classNames(className, styles.taskModal)}
      {...modelProps}
    >
      <div className={styles.taskModalForm}>
        <TaskModalHeader
          isTransactionTask={true}
          isTemplate={true}
          isNew={!!task}
          showSubheading={false}
        />
        <div className={styles.scrollable}>
          {isShowTaskSummary ? (
            <CollapsedTaskDetails isTemplate={true} task={values} onEdit={onEditTaskDetails} />
          ) : (
            <div className={classNames(styles.content, styles.taskModalBody)}>
              <InputLabel label={'Details'} className={styles.label} />
              <TaskName
                isTemplate={true}
                values={values}
                onChangeField={onChangeField}
                hideAsterisk={true}
                isViewMode={isViewMode}
              />
              <div>
                <AssignedTo
                  isTemplate={true}
                  values={values}
                  onChangeField={onChangeField}
                  className={''}
                  isAssignTask={false}
                  hideAsterisk={true}
                  label="Assign To"
                  isViewMode={isViewMode}
                  setShowCC={setShowCC}
                  showCC={showCC}
                />
                {showCC ? (
                  <CcTo
                    isTemplate={true}
                    values={values}
                    onChangeField={onChangeField}
                    className={''}
                    isAssignTask={false}
                    showOptional={true}
                    isViewMode={isViewMode}
                  />
                ) : (
                  <></>
                )}
              </div>

              <DueDate
                isTemplate={true}
                values={values}
                onChangeManyFields={onChangeManyFields}
                hideAsterisk={true}
                isViewMode={isViewMode}
                showMdDropdown={showMdDropdown}
                isProjectTask={useIsProjectRoute()}
                mdDropdownLayout={
                  <div className={styles.mdDropdownContainer}>
                    <AssignedToMilestone
                      isTemplate={true}
                      values={values}
                      onChangeField={onChangeField}
                      className={''}
                      isAssignTask={false}
                      hideAsterisk={true}
                      label="Milestone"
                      isViewMode={isViewMode}
                      data={data}
                    />
                  </div>
                }
              />
            </div>
          )}
          <TaskSectionContainer hideSection={!addTag} className={styles.spacing}>
            <TagInput
              onSelect={(val) =>
                onFormValueChange({ ...(values || {}), Categories: val, customTag: '' })
              }
              onSelectCustomTag={setCustomTag}
              value={values}
              disabled={isViewMode}
              className={styles.tagSelectorContainer}
              selectClassName={styles.tagSelect}
              addTag={addTag}
              label="Tags"
              multiple
            />
          </TaskSectionContainer>

          {!addTag && values?.Categories?.length > 0 && (
            <TaskSectionContainer
              title="Tags"
              titleClassName={styles.title}
              icon={<TaskIcons variant={TaskIcons.TAGS} />}
              className={styles.spacing}
            >
              <div className={styles.viewTaskSectionContent}>
                <span>
                  {values?.Categories?.length
                    ? values?.Categories?.map((item) => item?.Name || item)?.join(', ')
                    : ''}
                </span>
                <EditCircle
                  onClick={() => {
                    setAddTag(true);
                    setAddLinks(false);
                    setShowTaskSummary(true);
                    setAddDescription(false);
                    setAddFiles(false);
                    setAddChecklists(false);
                    setAddEmailTemplate(false);
                  }}
                  className={styles.editIcon}
                />
              </div>
            </TaskSectionContainer>
          )}
          <Description
            values={values}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addDescription={addDescription}
            onEdit={() => {
              setShowTaskSummary(true);
              setAddDescription(true);
              setAddLinks(false);
              setAddTag(false);
              setAddFiles(false);
              setAddChecklists(false);
              setAddEmailTemplate(false);
            }}
            viewContentClassname={styles.viewTaskSectionContent}
          />
          <Checklists
            values={values}
            editData={task}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addChecklists={addChecklists}
            viewContentClassname={styles.viewTaskSectionContent}
            onEdit={() => {
              setAddTag(false);
              setShowTaskSummary(true);
              setAddDescription(false);
              setAddLinks(false);
              setAddChecklists(true);
              setAddFiles(false);
              setAddEmailTemplate(false);
            }}
          />
          <Links
            values={values}
            editData={task}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addLink={addLink}
            viewContentClassname={styles.viewTaskSectionContent}
            onEdit={() => {
              setAddTag(false);
              setShowTaskSummary(true);
              setAddDescription(false);
              setAddLinks(true);
              setAddFiles(false);
              setAddChecklists(false);
              setAddEmailTemplate(false);
            }}
          />
          <Files
            isAssignTask={false}
            isTemplate={true}
            values={values}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addFiles={addFiles}
            viewContentClassname={styles.viewTaskSectionContent}
            onEdit={() => {
              setAddTag(false);
              setAddLinks(false);
              setShowTaskSummary(true);
              setAddDescription(false);
              setAddFiles(true);
              setAddChecklists(false);
              setAddEmailTemplate(false);
            }}
          />
          {!isSuperUser && (
            <>
              <TaskSectionContainer hideSection={!addEmailTemplate} className={styles.spacing}>
                <EmailTemplateInput
                  onSelect={(val) => onChangeField(val, 'EmailTemplate')}
                  value={values?.EmailTemplate}
                  disabled={isViewMode}
                />
              </TaskSectionContainer>
              {!addEmailTemplate && values?.EmailTemplate && (
                <TaskSectionContainer
                  title="Email Template"
                  titleClassName={styles.title}
                  icon={<TaskIcons variant={TaskIcons.EMAIL_TEMPLATE} />}
                  className={styles.spacing}
                >
                  <div className={styles.viewTaskSectionContent}>
                    <span>{values?.EmailTemplate?.name || values?.EmailTemplate?.Subject}</span>
                    <EditCircle
                      onClick={() => {
                        setAddTag(false);
                        setAddLinks(false);
                        setShowTaskSummary(true);
                        setAddDescription(false);
                        setAddFiles(false);
                        setAddChecklists(false);
                        setAddEmailTemplate(true);
                      }}
                      className={styles.editIcon}
                    />
                  </div>
                </TaskSectionContainer>
              )}
            </>
          )}
          <div className={styles.moreOptionsContainer}>
            {showMoreOptions() && (
              <OptionButton
                onClick={() => setOpen(!open)}
                className={styles.moreOptionsBtn}
                icon={<Icon variant={Icon.ADD} />}
                type="text"
                ref={buttonRef}
              >
                Add Details
              </OptionButton>
            )}

            {open && (
              <div ref={optionsRef} className={styles.options}>
                <Row gutter={12}>
                  {!addDescription && isEmptyHTML(values?.Description) && (
                    <Col lg={12}>
                      <MoreOptionItem
                        title={getTitle('Description')}
                        onClick={() => {
                          setAddDescription(true);
                          setShowTaskSummary(true);
                          setAddTag(false);
                          setAddLinks(false);
                          setAddFiles(false);
                          setOpen(false);
                          setAddChecklists(false);
                          setAddEmailTemplate(false);
                        }}
                        iconVariant={TaskIcons.DESCRIPTION}
                      />
                    </Col>
                  )}
                  {!addTag && !values?.Categories?.length && (
                    <Col lg={12}>
                      <MoreOptionItem
                        title={getTitle('Tags')}
                        onClick={() => {
                          setAddTag(true);
                          setAddDescription(false);
                          setShowTaskSummary(true);
                          setAddLinks(false);
                          setAddFiles(false);
                          setOpen(false);
                          setAddChecklists(false);
                          setAddEmailTemplate(false);
                        }}
                        iconVariant={TaskIcons.TAGS}
                      />
                    </Col>
                  )}
                  {!addChecklists && !values?.Checklists?.length && (
                    <Col lg={12}>
                      <MoreOptionItem
                        title={getTitle('Checklists')}
                        onClick={() => {
                          setAddChecklists(true);
                          setAddTag(false);
                          setAddDescription(false);
                          setShowTaskSummary(true);
                          setAddLinks(false);
                          setAddFiles(false);
                          setOpen(false);
                          setAddEmailTemplate(false);
                        }}
                        iconVariant={TaskIcons.CHECKLIST}
                      />
                    </Col>
                  )}
                  {!addLink && !areLinksAdded && (
                    <Col lg={12}>
                      <MoreOptionItem
                        title={getTitle('Links')}
                        onClick={() => {
                          setAddLinks(true);
                          setAddTag(false);
                          setAddDescription(false);
                          setShowTaskSummary(true);
                          setAddFiles(false);
                          setAddChecklists(false);
                          setOpen(false);
                          setAddEmailTemplate(false);
                        }}
                        iconVariant={TaskIcons.LINKS}
                      />
                    </Col>
                  )}
                  {!addFiles && !values?.Documents?.length && (
                    <Col lg={12}>
                      <MoreOptionItem
                        title={getTitle('Files')}
                        onClick={() => {
                          setAddFiles(true);
                          setOpen(false);
                          setAddDescription(false);
                          setAddLinks(false);
                          setShowTaskSummary(true);
                          setAddChecklists(false);
                          setAddTag(false);
                          setAddEmailTemplate(false);
                        }}
                        iconVariant={TaskIcons.FILES}
                      />
                    </Col>
                  )}
                  {!isSuperUser && !addEmailTemplate && !values?.EmailTemplate && (
                    <Col lg={12}>
                      <MoreOptionItem
                        onClick={() => {
                          setAddFiles(false);
                          setOpen(false);
                          setAddDescription(false);
                          setAddLinks(false);
                          setShowTaskSummary(true);
                          setAddChecklists(false);
                          setAddTag(false);
                          setAddEmailTemplate(true);
                        }}
                        title="Email Template"
                        iconVariant={TaskIcons.EMAIL_TEMPLATE}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </div>

          {!isViewMode ? (
            <div className={styles.taskModalFooter}>
              <Button
                variant="secondary"
                onClick={handleSave}
                disabled={!!checkDisabled()}
                className={classNames(styles.footerButton, {
                  [styles.disabledButton]: checkDisabled(),
                })}
              >
                {task ? 'Save' : 'Create'}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Modal>
  );
};
