import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RadarHeader } from '../components/RadarHeader';
import { AntCard, PageWrapper } from 'components-antd';
import ListingDetailMap from 'pages/Properties/ListingDetail/components/ListingDetailMap';
import { map } from 'lodash-es';
import { Icons } from './Icons';
import { HistoryCard } from './components/HistoryCard';
import { NeighborhoodActivityCard } from './components/NeighborhoodActivityCard';
import {
  requestAvmAdjustmentEffect,
  requestPropertyDetailsEffect,
  requestPropertyNeighborhoodActivitiesEffect,
} from 'store/effects/radarPortfolio';
import { useHistory } from 'react-router-dom';
import {
  getPropertyReferenceDetailsSelector,
  getPropertyReferenceNeighborhoodActivitiesSelector,
} from 'store/selectors/radarPortfolio';
import { PENDING } from 'settings/constants/apiState';
import { Wrapper } from 'components';
import { formatNumber } from 'helpers';
import moment from 'moment';
import { ValuationCard } from 'pages/Intel/components/PropertyTab/components';
import { getValuationDataSelector } from 'store/selectors/intel';
import { requestGetAddressValuationDetailsEffect } from 'store/effects/intel';
import { NeighborhoodFiltersChangeHandler, NeighborhoodFilterTypes } from '../types';
import { RadiusForm } from './components/RadiusForm';
import classNames from 'classnames';
import { routes } from 'settings/navigation/routes';
import { updatePropertyAddressMetaAction } from 'store/actions/radarPortfolio';
import { ClosingDateForm } from './components/ClosingDateForm';
import { NotesForm } from './components/NotesForm';
import { BedsForm } from './components/BedsForm';
import { Categories } from 'pages/Intel/components/NeighborhoodTab/components/Filter/types';
import { BathsForm } from './components/BathsForm';
import { LotSizeForm } from './components/LotSizeForm';
import { SizeForm } from './components/SizeForm';
import { TypeForm } from './components/TypeForm';

export const getCategoryAndStatusFromFilter = (nextFilter: NeighborhoodFilterTypes) => {
  let category: string[] | undefined = undefined;
  const categories =
    nextFilter === NeighborhoodFilterTypes.All
      ? Object.values(Categories).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {} as Record<string, boolean>)
      : {
          [nextFilter]: true,
        };

  if (categories[Categories.NEW_LISTING]) {
    category = [...(category ?? []), 'New'];
  }
  if (categories[Categories.PRICE_REDUCTION]) {
    category = [...(category ?? []), 'Price Reductions'];
  }
  if (categories[Categories.PRICE_INCREASE]) {
    category = [...(category ?? []), 'Price Increase'];
  }
  if (categories[Categories.BACK_ON_THE_MARKET]) {
    category = [...(category ?? []), 'Back on Market'];
  }
  if (categories[Categories.WITHDRAWN]) {
    category = [...(category ?? []), Categories.WITHDRAWN];
  }
  if (categories[Categories.UNDER_CONTRACT]) {
    category = [...(category ?? []), Categories.UNDER_CONTRACT];
  }
  if (categories[Categories.EXPIRED]) {
    category = [...(category ?? []), Categories.EXPIRED];
  }
  if (categories[Categories.SOLD]) {
    category = [...(category ?? []), Categories.SOLD];
  }

  return {
    category,
  };
};

const typeOptions = [
  { name: 'Single Family', value: 'Single' },
  { name: 'Condo', value: 'Condo' },
  { name: 'Townhouse', value: 'Townhouse' },
  { name: 'Multi Family', value: 'MultiFamily' },
  { name: 'Land', value: 'Land' },
  { name: 'Other', value: 'Other' },
  { name: 'Commercial', value: 'Commercial' },
  { name: 'Commercial Lease', value: 'CommercialLease' },
  { name: 'Residential Lease', value: 'ResidentialLease' },
];

export const RadarProperty: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsState = {
    extractedAddress1: urlParams.get('address1'),
    extractedAddress2: urlParams.get('address2'),
    extractedStateAddressId: urlParams.get('addressId'),
    extractedClientId: urlParams.get('clientId'),
    extractedClientName: urlParams.get('clientName'),
  };
  const allParamsPresent = Object.values(urlParamsState).every((value) => value !== null);
  const [address1] = useState(urlParamsState?.extractedAddress1);
  const [address2] = useState(urlParamsState?.extractedAddress2);
  const [stateAddressId] = useState(Number(urlParamsState?.extractedStateAddressId));
  const [clientId] = useState(Number(urlParamsState?.extractedClientId));
  const [clientName] = useState(urlParamsState?.extractedClientName);
  const params = { address1, address2, addressId: stateAddressId, clientId };
  const { data, state } = useSelector(getPropertyReferenceDetailsSelector);
  const { data: neighborhoodActivitiesData, state: neighborhoodActivitiesState } = useSelector(
    getPropertyReferenceNeighborhoodActivitiesSelector,
  );
  const isPendingDetails = state === PENDING;
  const isPendingNeighborhood = neighborhoodActivitiesState === PENDING;
  const parsedData = data?.parsedData?.[0];
  const neighbourhoodRadius = data?.address?.neighbourhoodRadius ?? 1;
  const addressId = data?.address?.Id;
  const notes = data?.address?.notes ?? '';
  const type = data?.address?.propertyType?.length
    ? data?.address?.propertyType
        ?.map((prop) => typeOptions?.find((e) => e.value === prop)?.name)
        ?.join(', ')
    : '';
  const closingDate = data?.address?.closingDate
    ? moment(data?.address?.closingDate).format('M/D/YY')
    : '';
  const createdDate = data?.address?.CreatedDate
    ? moment(data?.address?.CreatedDate).format('M/D/YY')
    : '';
  const tax = data?.assessmentHistory;
  const sale = data?.saleHistory;
  const metadata = data?.address?.address?.metadata;
  const { data: valuationData, isPending: isPendingValuationData } =
    useSelector(getValuationDataSelector);
  const [editMode, setEditMode] = useState<string | null>(null);
  useEffect(() => {
    if (!allParamsPresent) history.push(routes.radarPortfolio);
    else {
      dispatch(requestAvmAdjustmentEffect(params?.addressId));
      dispatch(requestPropertyDetailsEffect(params));
      dispatch(requestGetAddressValuationDetailsEffect(params, { showError: false }));
    }
  }, []);

  useEffect(() => {
    if (isPendingDetails || !metadata || !allParamsPresent) {
      return;
    }

    dispatch(
      requestPropertyNeighborhoodActivitiesEffect({
        dayRange: 1,
        address: {
          lat: metadata.latitude,
          long: metadata.longitude,
          radius: neighbourhoodRadius ?? 10,
        },
        ...getCategoryAndStatusFromFilter(NeighborhoodFilterTypes.All),
      }),
    );
  }, [isPendingDetails, metadata, neighbourhoodRadius]);

  const mapOptions = {
    markers: {
      [`${metadata?.longitude} ${metadata?.latitude}`]: [
        {
          id: data?.address?._id,
          geometry: {
            coordinates: {
              lng: metadata?.longitude,
              lat: metadata?.latitude,
            },
          },
        },
      ],
    },
    zoom: 14,
    center: {
      lng: metadata?.longitude,
      lat: metadata?.latitude,
    },
  };

  const handleNeighborhoodActivitiesFiltersChange: NeighborhoodFiltersChangeHandler = ({
    category,
    activityStatus,
    dayRange,
  }) => {
    if (!metadata) {
      return;
    }

    dispatch(
      requestPropertyNeighborhoodActivitiesEffect({
        category,
        activityStatus,
        dayRange,
        address: {
          lat: metadata.latitude,
          long: metadata.longitude,
          radius: neighbourhoodRadius ?? 10,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(requestAvmAdjustmentEffect(params?.addressId));
  }, []);

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.pageWrapper}>
      <RadarHeader>
        <div className={styles.titleInputContainer}>
          <div className={styles.title}>{address1}</div>
          <div
            className={styles.subHeading}
            onClick={() => {
              history.push(`/client-profile/${clientId}`);
            }}
          >
            <p>
              Client: <span>{clientName}</span>
            </p>
            {/* <TooltipIcon size={'16px'} color={'#747475'} className={styles.tooltipIcon} /> */}
          </div>
        </div>
      </RadarHeader>
      <div className={styles.pageContent}>
        <Wrapper isPending={isPendingDetails} className={styles.container}>
          <div className={styles.detailsRow}>
            <AntCard className={styles.detailsCard}>
              <p className={styles.heading}>Details</p>
              <ListingDetailMap options={mapOptions} />
              {map(
                [
                  {
                    icon: Icons.CLOSED,
                    label: 'Closed',
                    value: closingDate,
                    edit: true,
                  },
                  { icon: Icons.CLOCK, label: 'Added to Mosaik', value: createdDate },
                  {
                    icon: Icons.BEDS,
                    label: 'Beds',
                    value: data?.address?.beds || parsedData?.beds || '',
                    edit: true,
                  },
                  {
                    icon: Icons.BATHS,
                    label: 'Baths',
                    value: data?.address?.baths || parsedData?.baths || '',
                    edit: true,
                  },
                  {
                    icon: Icons.SIZE,
                    label: 'Size',
                    value:
                      data?.address?.size || parsedData?.size
                        ? formatNumber(data?.address?.size || parsedData?.size) + ' sq. ft.'
                        : '',
                    edit: true,
                  },
                  { icon: Icons.TYPE, label: 'Type', value: type ?? '', edit: true },
                  {
                    icon: Icons.LOT_SIZE,
                    label: 'Lot Size',
                    value:
                      data?.address?.lotSize || parsedData?.lotSize
                        ? formatNumber(data?.address?.lotSize || parsedData?.lotSize) + ' sq. ft.'
                        : '',
                    edit: true,
                  },
                  {
                    icon: Icons.NEIGHBORHOOD,
                    label: 'Neighborhood Activity Radius',
                    value: neighbourhoodRadius,
                    edit: true,
                  },
                  {
                    icon: Icons.NOTES,
                    label: 'Notes:',
                    value: notes,
                    directionColumn: true,
                    edit: true,
                  },
                ],
                ({ label, value, icon, edit = false, directionColumn = false }) => {
                  const handleClose = () => {
                    setEditMode(null);
                  };
                  const renderForm = () => {
                    switch (label) {
                      case 'Type':
                        return (
                          <TypeForm
                            value={data?.address?.propertyType || []}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(type) => {
                              if (type?.length) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    propertyType: type,
                                  }),
                                );
                              }
                            }}
                          />
                        );
                      case 'Lot Size':
                        return (
                          <LotSizeForm
                            value={data?.address?.lotSize || parsedData?.lotSize}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(lotSize) => {
                              if (!isNaN(Number(lotSize))) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    lotSize: Number(lotSize),
                                  }),
                                );
                              }
                            }}
                          />
                        );
                      case 'Size':
                        return (
                          <SizeForm
                            value={data?.address?.size || parsedData?.size}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(size) => {
                              if (!isNaN(Number(size))) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    size: Number(size),
                                  }),
                                );
                              }
                            }}
                          />
                        );
                      case 'Beds':
                        return (
                          <BedsForm
                            value={value}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(beds) => {
                              if (!isNaN(Number(beds))) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    beds: Number(beds),
                                  }),
                                );
                              }
                            }}
                          />
                        );
                      case 'Baths':
                        return (
                          <BathsForm
                            value={value}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(baths) => {
                              if (!isNaN(Number(baths))) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    baths: Number(baths),
                                  }),
                                );
                              }
                            }}
                          />
                        );
                      case 'Notes:':
                        return (
                          <NotesForm
                            value={value}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(notes) => {
                              if (notes) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    notes,
                                  }),
                                );
                              }
                            }}
                          />
                        );

                      case 'Neighborhood Activity Radius':
                        return (
                          <RadiusForm
                            value={Number(value)}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(radius) => {
                              if (!isNaN(Number(radius))) {
                                dispatch(
                                  updatePropertyAddressMetaAction({
                                    neighbourhoodRadius: Number(radius),
                                  }),
                                );
                              }
                            }}
                          />
                        );
                      case 'Closed':
                        return (
                          <ClosingDateForm
                            value={data?.address?.closingDate}
                            handleClose={handleClose}
                            addressId={Number(addressId)}
                            onUpdate={(closingDate) => {
                              if (closingDate) {
                                dispatch(updatePropertyAddressMetaAction({ closingDate }));
                              }
                            }}
                          />
                        );

                      default:
                        return null;
                    }
                  };
                  return (
                    <>
                      {edit && editMode === label ? (
                        renderForm()
                      ) : (
                        <div
                          className={classNames(styles.tableRow, {
                            [styles.column]: directionColumn,
                          })}
                        >
                          <div className={styles.iconRow}>
                            <Icons variant={icon} />
                            <p className={styles.label}>{label}</p>
                          </div>
                          <div
                            className={classNames(styles.valueContainer, {
                              [styles.column]: directionColumn,
                            })}
                          >
                            <p
                              className={classNames(styles.value, {
                                [styles.column]: directionColumn,
                              })}
                            >
                              {value}
                            </p>
                            {edit && (
                              <span
                                className={classNames(styles.editIcon, {
                                  [styles.notes]: label === 'Notes:',
                                })}
                                onClick={() => {
                                  setEditMode(label);
                                }}
                              >
                                <Icons variant={Icons.EDIT} />
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  );
                },
              )}
            </AntCard>
          </div>
          <div className={styles.cardsRow}>
            <ValuationCard
              valuationData={valuationData ?? []}
              isPending={isPendingValuationData}
              params={params}
              rental={false}
            />
            <ValuationCard
              valuationData={valuationData ?? []}
              isPending={isPendingValuationData}
              params={params}
              rental={true}
            />
            <NeighborhoodActivityCard
              neighborhoodActivitiesData={neighborhoodActivitiesData}
              isPending={isPendingNeighborhood}
              onFiltersChange={handleNeighborhoodActivitiesFiltersChange}
              mapOptions={mapOptions}
            />
            {(tax ?? []).length || (sale ?? [])?.length ? (
              <HistoryCard tax={tax ?? []} sale={sale ?? []} />
            ) : null}
          </div>
        </Wrapper>
      </div>
    </PageWrapper>
  );
};
