import { Check, Copy } from 'components/Icons';
import styles from './styles.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

const EmailTemplate = () => {
  const [subjectCopied, setSubjectCopied] = useState<boolean>(false);
  const [bodyCopied, setBodyCopied] = useState<boolean>(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const subjectText = 'Adding you to my preferred vendor list';
  const bodyText = `We’re implementing a new platform at ______ called Mosaik. We’ll be using it to manage property search, transactions, and client experience.~
In Mosaik, there is a module called Concierge that allows us to showcase our preferred vendors to our clients, including our past clients and our entire database (think of it like our personal Angie’s List that’s accessible to clients 24/7).~
We included you in our list of preferred vendors, so you will be receiving an email from Mosaik to activate your account. Please keep an eye out for it.~
There is no cost to you whatsoever and you only need to log in to the system when responding to clients (you can set up email and text alerts when a client contacts you).~
We think this will be a great way to grow our businesses together and appreciate your support!~
If you have any questions or run into any issues with your account, you can email support@mosaik.io directly and they will assist you.`;

  const timeoutHandler = useCallback(() => {
    setSubjectCopied(false);
    setBodyCopied(false);
    if (timeoutId.current) clearTimeout(timeoutId.current);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  return (
    <div className={styles.emailTemplate}>
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.heading}>Subject</div>
          <div
            className={subjectCopied ? styles.copied : styles.copy}
            onClick={() => {
              navigator.clipboard.writeText(subjectText);
              timeoutId.current = setTimeout(timeoutHandler, 3000);
              setSubjectCopied(true);
            }}
          >
            {subjectCopied ? <Check color="#04a451" /> : <Copy color="#747475" />}
            <span>{subjectCopied ? 'Copied' : 'Copy'}</span>
          </div>
        </div>
        <div className={styles.note}>{subjectText}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.heading}>Body</div>
          <div
            className={bodyCopied ? styles.copied : styles.copy}
            onClick={() => {
              navigator.clipboard.writeText(bodyText.replaceAll('~', '\n'));
              timeoutId.current = setTimeout(timeoutHandler, 3000);
              setBodyCopied(true);
            }}
          >
            {bodyCopied ? <Check color="#04a451" /> : <Copy color="#747475" />}
            <span>{bodyCopied ? 'Copied' : 'Copy'}</span>
          </div>
        </div>
        <div className={classNames(styles.note, styles.noteBody)}>
          {bodyText.split('~').map((text) => (
            <>
              <div>{text}</div>
              <br />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
