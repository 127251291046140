import { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { TagInput } from 'components/Transactions';
import { TaskModalHeader } from './TaskModalHeader';
import { Transaction } from './Transaction';
import { TaskName } from './TaskName';
import { AssignedTo } from './AssignedTo';
import { CcTo } from './CcTo';
import { DueDate } from './DueDate';
import { Description } from './Description';
import { Links } from './Links';
import { Checklists } from './Checklists';
import { Files } from './Files';
import { Permissions } from './Permissions';
import { Options } from './Options';
import { Footer } from './Footer';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';
import { getUserRolesMapSelector, getUserSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import { Row, Col } from 'components-antd';
import useIsTranOrProFilterVisible from 'hooks/use-is-tran-pro-filter-visible';
import { EditCircle } from 'components/Icons';
import { Button } from 'antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Icons as TaskIcons } from './../Task/ViewRevamped/Icons';
import TransactionTaskIcons from '../../Icons';
import { useOutsideClick } from 'hooks';
import { MoreOptionItem } from './MoreOptionItem/MoreOptionItem';
import { getTransactionRolesEffect, requestGetTeamListEffect } from 'store/effects';
import { AssignedToMilestone } from './AssignedToMilestone';
import { getMilestoneDataSelector } from 'store/selectors/milestoneAggregate';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getMilestoneById } from 'helpers';
import { getCampaignTaskMembersEffect } from 'store/effects/radarKits';
import { InputLabel, isEmptyHTML } from 'components';
import { CollapsedTaskDetails } from './CollapsedTaskDetails';
import { TaskSectionContainer } from '../Task/ViewRevamped/components';
import { EmailTemplateInput } from 'components/Transactions/EmailTemplateInput';
import useIsProjectRoute from 'hooks/use-is-project-route';

enum SECTIONS_KEYS {
  DESCRIPTION = 'DESCRIPTION',
  TAGS = 'TAGS',
  LINKS = 'LINKS',
  FILES = 'FILES',
  EMAIL = 'EMAIL',
  CHECKLISTS = 'CHECKLISTS',
}

export const TaskModalForm = (props) => {
  const {
    isNew,
    showSubheading,
    isTemplate,
    editData,
    templateId,
    isAssignTask,
    assignTask,
    onCancelModal,
    isTransactionTask,
    onUpdate,
    className,
    onRemove,
    taskLength,
    isAggregatedTasks,
    isViewMode,
    isFormTask,
    isCampaignTask,
    campaignId,
    onSaveTaskAsTemplate,
    isTranactionRoom,
  } = props;

  const { formValues } = useSelector(getTransactionTaskParamsSelector);
  const { data } = useSelector(getUserSelector);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);
  const [updatedFormValues, setUpdatedFormValues] = useState(formValues);
  const [isProFilterVisible, isTranFilterVisible] = useIsTranOrProFilterVisible();
  const [addDescription, setAddDescription] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [open, setOpen] = useState(false);
  const [addChecklists, setAddChecklists] = useState(false);
  const [addLink, setAddLinks] = useState(false);
  const [addFiles, setAddFiles] = useState(false);
  const [addTag, setAddTag] = useState(false);
  const [addEmailTemplate, setAddEmailTemplate] = useState(false);
  const [showMdDropdown, setShowMdDropdown] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const [milestoneDueDate, setMilestoneDueDate] = useState(null);
  const [changedFormvalues, setNewFormValues] = useState({
    taskId: formValues?.Id,
  } as TaskEditableFormValuesType);
  const [isShowTaskSummary, setShowTaskSummary] = useState(false);
  const [sections, setSections] = useState<any[]>([]);

  const [isProjectTask, setIsProjectTask] = useState(false);

  const buttonRef = useRef<any>();
  const optionsRef = useRef<any>();

  const dispatch = useDispatch();

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  useEffect(() => {
    if (
      ((isTemplate && templateId) || assignTask || isTransactionTask || isCampaignTask) &&
      editData
    ) {
      setNewFormValues({ ...editData });
      setUpdatedFormValues({ ...editData });
      if (editData?.Description) {
        addDetailSection(SECTIONS_KEYS.DESCRIPTION);
      }
      if (editData?.Links?.length) {
        addDetailSection(SECTIONS_KEYS.LINKS);
      }
      if (editData?.Checklists?.length) {
        addDetailSection(SECTIONS_KEYS.CHECKLISTS);
      }
      if (editData?.Documents?.length) {
        addDetailSection(SECTIONS_KEYS.FILES);
      }
      if (editData?.Categories?.length) {
        addDetailSection(SECTIONS_KEYS.TAGS);
      }
      if (editData?.EmailTemplate) {
        addDetailSection(SECTIONS_KEYS.EMAIL);
      }
    } else if (isCampaignTask && campaignId) {
      onChangeField(campaignId, 'CampaignId');
    }
  }, [isTemplate, templateId, assignTask, editData, isTransactionTask, isCampaignTask, campaignId]);

  useEffect(() => {
    if (updatedFormValues?.Description) addDetailSection(SECTIONS_KEYS.DESCRIPTION);
    if (updatedFormValues?.Links?.length) addDetailSection(SECTIONS_KEYS.LINKS);
    if (updatedFormValues?.Checklists?.length) addDetailSection(SECTIONS_KEYS.CHECKLISTS);
    if (updatedFormValues?.Documents?.length) addDetailSection(SECTIONS_KEYS.FILES);
    if (updatedFormValues?.Categories?.length) addDetailSection(SECTIONS_KEYS.TAGS);
    if (updatedFormValues?.EmailTemplate) addDetailSection(SECTIONS_KEYS.EMAIL);
  }, []);

  useEffect(() => {
    dispatch(getTransactionRolesEffect());
  }, []);

  const onFormValueChange = (values) => {
    setNewFormValues(values);
    setUpdatedFormValues({ ...(updatedFormValues || {}), ...values });
    onUpdate(updatedFormValues);
  };

  const onChangeField = (val, fieldName) => {
    if (fieldName === 'MilestoneId') {
      setMilestoneDueDate(getMilestoneById(transaction?.Milestones, val)?.DueDate);
    }
    onFormValueChange({ ...(changedFormvalues || {}), [fieldName]: val });
  };

  const onChangeManyFields = (data) => {
    onFormValueChange({ ...(changedFormvalues || {}), ...data });
  };
  useEffect(() => {
    if (changedFormvalues?.ControlOperatorOffset?.startsWith('MD') && !changedFormvalues?.DueDate) {
      onChangeField(null, 'MilestoneId');
      setUpdatedFormValues({ ...updatedFormValues, DueDate: null, MilestoneId: null });
      setShowMdDropdown(true);
    } else {
      setShowMdDropdown(false);
    }
  }, [changedFormvalues?.ControlOperatorOffset, changedFormvalues?.DueDate]);

  useEffect(() => {
    if (isCampaignTask) {
      dispatch(getCampaignTaskMembersEffect());
    }
  }, [isCampaignTask]);

  const setCustomTag = (val) => {
    if (val?.trim()) {
      onChangeField(val, 'customTag');
    }
  };

  useEffect(() => {
    if (updatedFormValues?.EmailTemplate) {
      const { Id, CurrentVersion } = updatedFormValues.EmailTemplate;
      updatedFormValues.EmailTemplate = {
        ...updatedFormValues.EmailTemplate,
        EmailTemplateId: Id,
        EmailTemplateVersionId: CurrentVersion,
      };
    }
  }, []);

  const showMoreOptions = () =>
    isFormTask
      ? (!addDescription && isEmptyHTML(updatedFormValues?.Description)) ||
        (!addFiles && !updatedFormValues?.Documents?.length) ||
        (!addEmailTemplate && !updatedFormValues?.EmailTemplate)
      : (!addDescription && isEmptyHTML(updatedFormValues?.Description)) ||
        (!addTag && !updatedFormValues?.Categories?.length) ||
        (!addLink && !areLinksAdded) ||
        (!addFiles && !updatedFormValues?.Documents?.length) ||
        (!addChecklists && isTemplate && !updatedFormValues?.Checklists?.length) ||
        (!addEmailTemplate && !updatedFormValues?.EmailTemplate);

  const onEditTaskDetails = () => {
    setShowTaskSummary(false);
    setAddDescription(false);
    setAddTag(false);
    setAddLinks(false);
    setAddFiles(false);
    setAddChecklists(false);
    setAddEmailTemplate(false);
  };

  const areLinksAdded = useMemo(
    () =>
      updatedFormValues?.Links?.some(
        (item) => item?.link.trim() !== '' || item?.label.trim() !== '',
      ),
    [updatedFormValues?.Links],
  );

  const addDetailSection = (sectionTitle) => {
    setSections((oldSections) => [
      ...(oldSections?.filter((item) => item !== sectionTitle) || []),
      sectionTitle,
    ]);
  };

  const renderSection = (sectionTitle) => {
    if (sectionTitle === SECTIONS_KEYS.DESCRIPTION) {
      return (
        <Description
          values={updatedFormValues}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addDescription={addDescription}
          onEdit={() => {
            setShowTaskSummary(true);
            setAddDescription(true);
            setAddLinks(false);
            setAddTag(false);
            setAddFiles(false);
            setAddChecklists(false);
            setAddEmailTemplate(false);
          }}
          viewContentClassname={styles.viewTaskDescriptionSectionContent}
        />
      );
    } else if (sectionTitle === SECTIONS_KEYS.TAGS) {
      return (
        <>
          <TaskSectionContainer hideSection={!addTag} className={styles.spacing}>
            <TagInput
              onSelect={(val) =>
                onFormValueChange({ ...(changedFormvalues || {}), Categories: val, customTag: '' })
              }
              onSelectCustomTag={setCustomTag}
              label="Tags"
              value={updatedFormValues as ValueObject}
              disabled={isViewMode}
              className={styles.tagSelectorContainer}
              addTag={addTag}
              selectClassName={styles.tagSelect}
              multiple
            />
          </TaskSectionContainer>
          {!addTag && updatedFormValues?.Categories?.length > 0 && (
            <TaskSectionContainer
              title="Tags"
              titleClassName={styles.title}
              icon={<TaskIcons variant={TaskIcons.TAGS} />}
              className={styles.spacing}
            >
              <div className={styles.viewTaskSectionContent}>
                <span>
                  {updatedFormValues?.Categories?.length
                    ? updatedFormValues?.Categories?.map((item) => item?.Name || item)?.join(', ')
                    : ''}
                </span>
                <EditCircle
                  onClick={() => {
                    setAddTag(true);
                    setAddLinks(false);
                    setShowTaskSummary(true);
                    setAddDescription(false);
                    setAddFiles(false);
                    setAddChecklists(false);
                    setAddEmailTemplate(false);
                  }}
                  className={styles.editIcon}
                />
              </div>
            </TaskSectionContainer>
          )}
        </>
      );
    } else if (sectionTitle === SECTIONS_KEYS.LINKS) {
      return (
        <Links
          values={updatedFormValues}
          editData={editData}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addLink={addLink}
          viewContentClassname={styles.viewTaskSectionContent}
          onEdit={() => {
            setAddTag(false);
            setShowTaskSummary(true);
            setAddDescription(false);
            setAddLinks(true);
            setAddFiles(false);
            setAddChecklists(false);
            setAddEmailTemplate(false);
          }}
        />
      );
    } else if (sectionTitle === SECTIONS_KEYS.FILES) {
      return (
        <Files
          values={updatedFormValues}
          isAssignTask={isAssignTask}
          isTemplate={isTemplate}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addFiles={addFiles}
          viewContentClassname={styles.viewTaskSectionContent}
          isCampaignTask={isCampaignTask}
          onEdit={() => {
            setAddTag(false);
            setAddLinks(false);
            setShowTaskSummary(true);
            setAddDescription(false);
            setAddFiles(true);
            setAddChecklists(false);
            setAddEmailTemplate(false);
          }}
        />
      );
    } else if (sectionTitle === SECTIONS_KEYS.EMAIL && !isSuperUser) {
      return (
        <>
          <TaskSectionContainer hideSection={!addEmailTemplate} className={styles.spacing}>
            <EmailTemplateInput
              onSelect={(val) => onChangeField(val, 'EmailTemplate')}
              value={updatedFormValues?.EmailTemplate}
              disabled={isViewMode}
            />
          </TaskSectionContainer>
          {!addEmailTemplate && updatedFormValues?.EmailTemplate && (
            <TaskSectionContainer
              title="Email Template"
              titleClassName={styles.title}
              icon={<TaskIcons variant={TaskIcons.EMAIL_TEMPLATE} />}
              className={styles.spacing}
            >
              <div className={styles.viewTaskSectionContent}>
                <span>
                  {updatedFormValues?.EmailTemplate?.name ||
                    updatedFormValues?.EmailTemplate?.Subject}
                </span>
                <EditCircle
                  onClick={() => {
                    setAddTag(false);
                    setAddLinks(false);
                    setShowTaskSummary(true);
                    setAddDescription(false);
                    setAddFiles(false);
                    setAddChecklists(false);
                    setAddEmailTemplate(true);
                  }}
                  className={styles.editIcon}
                />
              </div>
            </TaskSectionContainer>
          )}
        </>
      );
    } else if (sectionTitle === SECTIONS_KEYS.CHECKLISTS) {
      return (
        <Checklists
          values={updatedFormValues}
          editData={editData}
          onChangeField={onChangeField}
          isViewMode={isViewMode || !isTemplate}
          addChecklists={addChecklists}
          viewContentClassname={styles.viewTaskSectionContent}
          onEdit={() => {
            setAddTag(false);
            setShowTaskSummary(true);
            setAddDescription(false);
            setAddLinks(false);
            setAddChecklists(true);
            setAddFiles(false);
            setAddEmailTemplate(false);
          }}
        />
      );
    }
  };

  return (
    <div
      className={classNames(styles.taskModalForm, {
        [styles.transactionTaskForm]: isTransactionTask,
      })}
    >
      <TaskModalHeader
        isTransactionTask={isTransactionTask}
        isTemplate={isTemplate}
        isNew={isNew || !updatedFormValues?.Id}
        showSubheading={showSubheading}
      />
      <div className={styles.scrollable}>
        {/* Collapsed Task Main Details */}
        {isShowTaskSummary ? (
          <CollapsedTaskDetails
            isTemplate={isTemplate}
            task={updatedFormValues}
            onEdit={onEditTaskDetails}
          />
        ) : (
          <div className={classNames(styles.content, className)}>
            {isTransactionTask && taskLength > 1 ? (
              <Options isTransactionTask={isTransactionTask} onRemove={onRemove} />
            ) : null}

            {isAggregatedTasks ? (
              <>
                {isTranFilterVisible ? (
                  <Transaction
                    values={updatedFormValues}
                    setValues={({ isProject, ...values }) => {
                      setNewFormValues(values);
                      setIsProjectTask(isProject);
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : null}
            <InputLabel label={'Details'} className={styles.label} />
            <TaskName
              isTemplate={isTemplate}
              values={updatedFormValues}
              onChangeField={onChangeField}
              isViewMode={isViewMode}
            />
            {!(isCampaignTask && isTemplate) && (
              <div className={classNames({ [styles.rolesWrapper]: isTransactionTask })}>
                <AssignedTo
                  isCampaignTask={isCampaignTask}
                  isTemplate={isTemplate}
                  values={updatedFormValues}
                  onChangeField={onChangeField}
                  isAssignTask={isAssignTask}
                  className={isTransactionTask && styles.fieldHalf}
                  isViewMode={isViewMode}
                  showRole={true}
                  setShowCC={setShowCC}
                  showCC={showCC}
                  isNew={isNew}
                />
                {showCC && (
                  <CcTo
                    isCampaignTask={isCampaignTask}
                    isTemplate={isTemplate}
                    values={updatedFormValues}
                    onChangeField={onChangeField}
                    isAssignTask={isAssignTask}
                    className={isTransactionTask && styles.fieldHalf}
                    isViewMode={isViewMode}
                  />
                )}
              </div>
            )}
            <DueDate
              isTemplate={isTemplate}
              isCampaignTask={isCampaignTask}
              values={updatedFormValues}
              onChangeManyFields={onChangeManyFields}
              isViewMode={isViewMode}
              scrollToBottom={true}
              customDueDate={milestoneDueDate}
              isProjectTask={isProjectTask || useIsProjectRoute()}
              hideRepeat={!(isNew || !updatedFormValues?.Id) && !isTemplate}
              showMdDropdown={showMdDropdown}
              mdDropdownLayout={
                <div className={styles.mdDropdownContainer}>
                  <AssignedToMilestone
                    isTemplate={false}
                    values={updatedFormValues}
                    onChangeField={onChangeField}
                    className={''}
                    isAssignTask={false}
                    hideAsterisk={true}
                    label="Milestone"
                    isViewMode={isViewMode}
                    data={transaction?.Milestones}
                  />
                </div>
              }
            />
            {isCampaignTask && isTemplate && (
              <div className={styles.mdDisclaimer}>
                <TransactionTaskIcons
                  className={styles.mdDisclaimerIcon}
                  variant={TransactionTaskIcons.SMALL_DISCLAIMER}
                />
                <span className={styles.mdDisclaimerText}>
                  The assignee for the task will be the team member the applicable client is
                  assigned to.
                </span>
              </div>
            )}
          </div>
        )}

        {sections?.map((item, index) => (
          <Fragment key={index}>{renderSection(item)}</Fragment>
        ))}

        <Permissions
          values={updatedFormValues}
          templateId={templateId}
          isTemplate={isTemplate}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
        />

        <div className={styles.moreOptionsContainer}>
          {showMoreOptions() && (
            <Button
              onClick={() => setOpen(!open)}
              className={styles.moreOptionsBtn}
              icon={<Icon variant={Icon.ADD} />}
              type="text"
              ref={buttonRef}
            >
              Add Details
            </Button>
          )}
          {open && (
            <div ref={optionsRef} className={styles.options}>
              <Row gutter={12}>
                {!addDescription && isEmptyHTML(updatedFormValues?.Description) && (
                  <Col lg={12}>
                    <MoreOptionItem
                      onClick={() => {
                        setAddDescription(true);
                        setShowTaskSummary(true);
                        setAddTag(false);
                        setAddLinks(false);
                        setAddFiles(false);
                        setOpen(false);
                        setAddChecklists(false);
                        setAddEmailTemplate(false);
                        addDetailSection(SECTIONS_KEYS.DESCRIPTION);
                      }}
                      title="Description"
                      iconVariant={TaskIcons.DESCRIPTION}
                    />
                  </Col>
                )}
                {!addTag && !isFormTask && !updatedFormValues?.Categories?.length && (
                  <Col lg={12}>
                    <MoreOptionItem
                      onClick={() => {
                        setAddTag(true);
                        setAddDescription(false);
                        setShowTaskSummary(true);
                        setAddLinks(false);
                        setAddFiles(false);
                        setOpen(false);
                        setAddChecklists(false);
                        setAddEmailTemplate(false);
                        addDetailSection(SECTIONS_KEYS.TAGS);
                      }}
                      title="Tags"
                      iconVariant={TaskIcons.TAGS}
                    />
                  </Col>
                )}
                {isTemplate && !addChecklists && !updatedFormValues?.Checklists?.length && (
                  <Col lg={12}>
                    <MoreOptionItem
                      onClick={() => {
                        setAddChecklists(true);
                        setAddTag(false);
                        setAddDescription(false);
                        setShowTaskSummary(true);
                        setAddLinks(false);
                        setAddFiles(false);
                        setOpen(false);
                        setAddEmailTemplate(false);
                        addDetailSection(SECTIONS_KEYS.CHECKLISTS);
                      }}
                      title="Checklists"
                      iconVariant={TaskIcons.CHECKLIST}
                    />
                  </Col>
                )}
                {!addLink && !isFormTask && !areLinksAdded && (
                  <Col lg={12}>
                    <MoreOptionItem
                      onClick={() => {
                        setAddLinks(true);
                        setAddTag(false);
                        setAddDescription(false);
                        setShowTaskSummary(true);
                        setAddFiles(false);
                        setAddChecklists(false);
                        setOpen(false);
                        setAddEmailTemplate(false);
                        addDetailSection(SECTIONS_KEYS.LINKS);
                      }}
                      title="Links"
                      iconVariant={TaskIcons.LINKS}
                    />
                  </Col>
                )}
                {!addFiles && !updatedFormValues?.Documents?.length && (
                  <Col lg={12}>
                    <MoreOptionItem
                      onClick={() => {
                        setAddFiles(true);
                        setOpen(false);
                        setAddDescription(false);
                        setAddLinks(false);
                        setShowTaskSummary(true);
                        setAddChecklists(false);
                        setAddTag(false);
                        setAddEmailTemplate(false);
                        addDetailSection(SECTIONS_KEYS.FILES);
                      }}
                      title="Files"
                      iconVariant={TaskIcons.FILES}
                    />
                  </Col>
                )}
                {!isSuperUser &&
                  !addEmailTemplate &&
                  !updatedFormValues?.EmailTemplate &&
                  !isCampaignTask && (
                    <Col lg={12}>
                      <MoreOptionItem
                        onClick={() => {
                          setAddFiles(false);
                          setOpen(false);
                          setAddDescription(false);
                          setAddLinks(false);
                          setShowTaskSummary(true);
                          setAddChecklists(false);
                          setAddTag(false);
                          setAddEmailTemplate(true);
                          addDetailSection(SECTIONS_KEYS.EMAIL);
                        }}
                        title="Email Template"
                        iconVariant={TaskIcons.EMAIL_TEMPLATE}
                      />
                    </Col>
                  )}
              </Row>
            </div>
          )}
        </div>
      </div>
      <Footer
        isCampaignTask={isCampaignTask}
        isTransactionTask={isTransactionTask}
        isNew={isNew}
        isTemplate={isTemplate}
        isAssignTask={isAssignTask}
        templateId={templateId}
        values={updatedFormValues}
        changedValues={changedFormvalues}
        setNewFormValues={setNewFormValues}
        onCancelModal={onCancelModal}
        assignTask={assignTask}
        isAggregatedTasks={isAggregatedTasks}
        isViewMode={isViewMode}
        onSaveTaskAsTemplate={onSaveTaskAsTemplate}
        isTranactionRoom={isTranactionRoom}
      />
    </div>
  );
};

TaskModalForm.propTypes = {
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  showSubheading: PropTypes.bool,
  isTemplate: PropTypes.bool,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editData: PropTypes.object,
  isAssignTask: PropTypes.bool,
  assignTask: PropTypes.func,
  onCancelModal: PropTypes.func,
  isTransactionTask: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onFieldChange: PropTypes.func,
  className: PropTypes.string,
  taskLength: PropTypes.number,
  taskData: PropTypes.object,
  isAggregatedTasks: PropTypes.bool,
  isViewMode: PropTypes.bool,
  isFormTask: PropTypes.bool,
  isCampaignTask: PropTypes.bool,
  campaignId: PropTypes.number,
  onSaveTaskAsTemplate: PropTypes.func,
  isTranactionRoom: PropTypes.bool,
};

TaskModalForm.defaultProps = {
  isOpen: false,
  isNew: false,
  showSubheading: false,
  isTemplate: false,
  templateId: null,
  editData: null,
  isAssignTask: false,
  assignTask: () => {},
  onCancelModal: () => {},
  isTransactionTask: false,
  onUpdate: () => {},
  onRemove: () => {},
  onFieldChange: () => {},
  className: '',
  taskLength: null,
  taskData: {},
  isAggregatedTasks: false,
  isViewMode: false,
  isFormTask: false,
  isCampaignTask: false,
  campaignId: null,
  onSaveTaskAsTemplate: () => {},
  isTranactionRoom: false,
};
