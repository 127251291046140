import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Name is a required field')
    .test(
      'has-space',
      'Enter full name',
      (value) => !!value && value.trim().split(' ').length > 1, // Ensure at least two words
    )
    .matches(/^[a-zA-Z0-9\sÉé-]*$/, 'Name cannot contain special characters'),
  Role: Yup.string().required('Role is a required field'),
  Email: Yup.string().email('Invalid Email').nullable(),
  CompanyName: Yup.string().nullable(),
  Phone: Yup.string().nullable(),
});
