import classNames from 'classnames';
import { MosaikLogo } from 'components/Icons';
import styles from './styles.module.scss';

export interface HeaderProps {
  className?: string;
  logoClassName: string;
}

export const Header = ({ className, logoClassName }: HeaderProps) => {
  return (
    <header className={classNames(styles.header, className)}>
      <MosaikLogo className={classNames(styles.logo, logoClassName)} />
    </header>
  );
};
