import { useState, useRef, ReactNode } from 'react';
import classNames from 'classnames';

import { Popover } from 'components-antd';
import { useOutsideClick } from 'hooks';
import { TooltipPlacement } from 'antd/es/tooltip';
import { ArrowDown2 } from 'components/Icons';

import styles from './styles.module.scss';

interface OptionsType {
  title: string | ReactNode;
  options: any[];
  placement?: TooltipPlacement;
  className?: string;
  titleClassName?: string;
  popoverClassName?: string;
  optionLabelClassName?: string;
  getPopupContainer?: any;
  disabled?: boolean;
  isArrow?: boolean;
}

export const Options = ({
  title,
  options,
  placement,
  className = '',
  titleClassName = '',
  popoverClassName = '',
  optionLabelClassName = '',
  disabled = false,
  isArrow = false,
}: OptionsType) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    if (disabled) return;

    e.stopPropagation();
    setOpen(!open);
  };

  const PopoverContent = () => (
    <div ref={optionsRef} className={styles.options}>
      <ul>
        {options.map(({ label, icon, onClick }, idx) => (
          <li
            key={idx}
            className={styles.item}
            onClick={() => {
              setOpen(false);
              onClick();
            }}
          >
            <div className={styles.icon}>{icon}</div>
            <span className={optionLabelClassName}>{label}</span>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className={classNames(styles.optionsWrapper, className)}>
      <div testid="options" ref={buttonRef} onClick={onClickOptions}>
        <span className={classNames(styles.title, titleClassName)}>
          {title}{' '}
          {isArrow && (
            <div className={styles.arrowContainer}>
              <ArrowDown2 className={classNames({ [styles.rotate]: open })} />
            </div>
          )}
        </span>
      </div>
      <Popover
        open={open}
        placement={placement}
        overlayClassName={classNames(styles.optionsPopover, popoverClassName)}
        content={<PopoverContent />}
      ></Popover>
    </div>
  );
};
