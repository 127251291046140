import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Dropdown, Radio, Select, Button } from 'components-antd';

import { SortIcon } from 'components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { Switcher } from 'pages/Properties/LocalHeader/Switcher';
import { TransactionsFilter } from 'pages/Workshop/Transactions/components/Actions/QuickFilters';

import styles from './styles.module.scss';
import { TourSort, TourStatus, TeamFilter } from 'app-constants/enums/tours';
import { TeamAgentType } from 'types';
import { Icons } from 'pages/Workshop/Icons';
import { routes } from 'settings/navigation/routes';

export const LocalHeader = (props) => {
  const history = useHistory();

  const userRole = useSelector(getAgentTeamRoleSelector);
  const {
    onSortChange,
    onFilterChange,
    selectedSort,
    selectedFilter,
    selectedTeamFilter,
    onTeamFilterChange,
  } = props;

  const filterOptions = [
    { value: TourStatus.Requested, label: 'Requests' },
    { value: TourStatus.Approved, label: 'Upcoming' },
    { value: TourStatus.Canceled, label: 'Canceled' },
    { value: TourStatus.Past, label: 'Past' },
  ];
  const teamOptions = [
    { value: TeamFilter.JustMe, label: 'Just Me' },
    { value: TeamFilter.EntireTeam, label: 'Entire Team' },
  ];

  const sortDropdown = (
    <div className={styles.sortDropdown}>
      <p className={styles.title}>Sort By</p>
      <Radio.Group className={styles.sdOptions} onChange={onSortChange} value={selectedSort}>
        <Radio className={styles.sdOption} value={TourSort.ClientName}>
          Client Name
        </Radio>
        {userRole == TeamAgentType.Owner || userRole == TeamAgentType.Admin ? (
          <Radio className={styles.sdOption} value={TourSort.AgentName}>
            Agent Name
          </Radio>
        ) : (
          ''
        )}
        <Radio className={styles.sdOption} value={TourSort.Date}>
          Date
        </Radio>
      </Radio.Group>
    </div>
  );
  return (
    <div className={styles.toursHeader}>
      <h3 className={styles.header}>Tours</h3>
      <div className={styles.headerActions}>
        <div className={styles.thLeft}>
          {userRole == TeamAgentType.Owner || userRole == TeamAgentType.Admin ? (
            <>
              <Select
                className={classNames(styles.toursFilter, 'mosaikDropdown')}
                value={selectedTeamFilter}
                onChange={onTeamFilterChange}
                options={teamOptions}
                getPopupContainer={(node) => node}
                popupClassName={classNames(
                  styles.toursFilterDropdown,
                  'mosaikDropdownPopover withCheck',
                )}
                placement={'bottomRight'}
              />
            </>
          ) : (
            ''
          )}

          <Select
            className={classNames(styles.toursFilter, 'mosaikDropdown')}
            value={selectedFilter}
            onChange={onFilterChange}
            options={filterOptions}
            getPopupContainer={(node) => node}
            popupClassName={classNames(
              styles.toursFilterDropdown,
              'mosaikDropdownPopover withCheck',
            )}
            placement={'bottomRight'}
          />
        </div>
        <Dropdown
          overlay={sortDropdown}
          getPopupContainer={(node) => node}
          overlayClassName={styles.toursFilterDropdown}
          trigger={['click']}
          className={styles.tourSort}
        >
          <a onClick={(e) => e.preventDefault()}>
            <SortIcon className={styles.sortIcon} />
          </a>
        </Dropdown>
        <Button className={styles.closeBtn} onClick={() => history.push(routes.properties)}>
          <Icons className={styles.btnIcon} variant={Icons.CLOSE} color={'#303030'} />
        </Button>
      </div>
    </div>
  );
};
