import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getTransactionParticipantsSelector } from 'store/selectors/transaction';
import { Button, SearchInput } from 'components-antd';
import { Checkbox, Row } from 'components-antd';
import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { processOption } from 'pages/Workshop/Transactions/TransactionCreate/helpers';

import styles from './styles.module.scss';

export interface AssignToProps {
  pending?: boolean;
  onSubmit?: (e) => void;
}

interface Assignee {
  id: string | number;
  name?: string;
  value?: string;
  role?: string;
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const AssignTo = ({ onSubmit, pending }: AssignToProps) => {
  const { transactionParticipants } = useSelector(getTransactionParticipantsSelector);

  const [selected, setSelected] = useState<Assignee[]>([]);
  const [query, setQuery] = useState('');

  const getParticipantName = (p) => {
    const firstName = p?.FirstName || p?.firstName;
    const lastName = p?.LastName || p?.lastName;
    const name = [firstName, lastName].filter((n) => !!n);

    const email = p?.Email || p?.email;

    if (name?.length) return name.join(' ');

    return email || '';
  };

  const filterParticipants = (participant) => {
    if (participant?.id || participant?.Id) {
      if (query?.length) {
        const name = (getParticipantName(participant) as string)?.toLocaleLowerCase();
        return name?.includes(query?.toLocaleLowerCase());
      }
      return true;
    }

    return false;
  };

  const getOptions = () => {
    const participants = [...(transactionParticipants ?? [])];

    const options = participants.filter(filterParticipants);
    return options.map((p) => processOption(p)).filter((p) => !!p);
  };

  const getValues = () => {
    if (transactionParticipants) {
      return getOptions() || [];
    } else return [];
  };

  const assignToList = getValues() || [];

  const handleSelect = (assignee: Assignee) => {
    const newSelected = [...selected];
    if (newSelected.some((user) => user.id === assignee.id))
      setSelected(selected.filter((user) => user.id !== assignee.id));
    else setSelected([...newSelected, assignee]);
  };

  const checkSelected = (assignee: Assignee) => selected.some((user) => user.id === assignee.id);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(selected);
    }
  };

  return (
    <div className={styles.assignToContainer}>
      <div className={styles.searchContainer}>
        <SearchInput
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          maxLength={100}
          className={styles.searchInput}
        />
      </div>
      <div className={styles.assignToList}>
        {assignToList.map((option: Assignee, idx: number) => (
          <>
            <Row
              className={styles.assignToRow}
              key={option.id}
              onClick={() => handleSelect(option)}
            >
              <div className={styles.userData}>
                <Avatar
                  avatarClassName={styles.avatar}
                  src={option.avatarUrl}
                  placeholder={
                    <div className={styles.avatarPlaceholder}>
                      {convertNameToAvatarPlaceholder(`${option.name}`)}
                    </div>
                  }
                />
                <div className={styles.userName}>{getParticipantName(option)}</div>
              </div>
              <div>
                <Checkbox checked={checkSelected(option)} className="mosaikCheckbox" />
              </div>
            </Row>
            {idx < assignToList?.length - 1 && <div className={styles.divider} />}
          </>
        ))}
      </div>
      <div className={styles.assignToFooter}>
        <Button
          variant="secondary"
          disabled={!selected?.length || pending}
          className={styles.submitButton}
          onClick={handleSubmit}
        >
          Done
        </Button>
      </div>
    </div>
  );
};
