import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { sortDetails } from 'pages/FormProcess/helper';
import { getGroupOptionSize } from '../../../../EditorPage';

export const AddRadioIcon = ({ color, updateGroupField, options, scale }) => {
  const onAddNewOption = () => {
    const sortedOptions = sortDetails(options, 'y');
    const maxYItem = sortedOptions[sortedOptions.length - 1];

    const newOption = {
      UUID: uuidv4(),
      width: getGroupOptionSize().width,
      height: getGroupOptionSize().height,
      x: 0,
      y: maxYItem.y + maxYItem.height * scale + 5,
      isNewOption: true,
    };

    updateGroupField([...options, newOption]);
  };

  return (
    <Icons
      variant={ICON_VARIANT_TYPE.Plus}
      color={color?.border}
      className={styles.addOptionIcon}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        onAddNewOption();
      }}
    />
  );
};
