import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { getAgentName, getClientName, getFormattedDateTime, getTimeDifference } from '../../helper';
import { TourSort, TourStatus } from 'app-constants/enums/tours';
import { useState } from 'react';
import classNames from 'classnames';
import { getCleanedText } from 'utils/pinpointHelpers';

export const NoTimeline = (props) => {
  const { children, tour, sort, hideSeparator } = props;
  const [showDetails, setShowDetails] = useState(true);

  const getStreetTwo = () => {
    return getCleanedText(
      tour?.PropertyAddress?.City,
      tour?.PropertyAddress?.State,
      tour?.PropertyAddress?.Zip,
    );
  };

  return (
    <div className={styles.item}>
      <span
        className={
          hideSeparator ? classNames([styles.separator, styles.lastSeparator]) : styles.separator
        }
      ></span>
      <div className={styles.info}>
        <Link
          className={styles.streetInfo}
          to={{
            pathname: `/properties/search/listing-detail/${tour.PropertyId}`,
            state: { backUrl: location.pathname },
          }}
        >
          <span className={styles.streetOne}>
            {tour.PropertyAddress.Line2
              ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
              : `${tour.PropertyAddress.Line1},`}{' '}
          </span>
          <span className={styles.streetTwo}>{getStreetTwo()}</span>
        </Link>
        {sort === TourSort.AgentName ? (
          <p className={styles.agentInfo}>
            <span>Client: </span> {getClientName(tour)}
          </p>
        ) : (
          <p className={styles.agentInfo}>
            <span>{tour.TourAssignee.length > 1 ? 'Agents:' : 'Agent:'}</span> {getAgentName(tour)}
          </p>
        )}
        {tour.Status === TourStatus.Canceled ? (
          <>
            {!showDetails && (
              <>
                {tour.ScheduleDateTime ? (
                  <p className={styles.agentInfo}>
                    <span>Scheduled: </span>{' '}
                    {getFormattedDateTime(tour.ScheduleDateTime, 'MM/DD/YYYY, hh:mm A')}
                  </p>
                ) : (
                  ''
                )}
                <p className={styles.agentInfo}>
                  <span>Cancelled: </span>{' '}
                  {getFormattedDateTime(tour.CancelledAt, 'MM/DD/YYYY, hh:mm A')}
                </p>
                <p className={styles.agentInfo}>
                  <span>Cancelled By: </span> {tour.CanceledBy.FirstName} {tour.CanceledBy.LastName}
                </p>
              </>
            )}
            <div className={classNames(styles.notes, { [styles.show]: !showDetails })}>
              <p className={styles.note}>
                <span>Notes: </span>
                {tour.ClientNotes}
              </p>
            </div>
            <span className={styles.notesToggler} onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? 'View' : 'Hide'} Details
            </span>
          </>
        ) : (
          <>
            <p className={styles.mlsInfo}>MLS # {tour.PropertyKey}</p>
            <p className={styles.mlsInfo}>
              {tour.Status === TourStatus.Requested &&
                `Pending (${getTimeDifference(tour.CreatedDate)})`}
            </p>
          </>
        )}
      </div>
      <div className={styles.action}>{children}</div>
    </div>
  );
};
