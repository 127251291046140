import { FC, memo, useEffect } from 'react';

import { ReminderCard, ValuationCard } from './components';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRemindersByIdSelector, getValuationDataSelector } from 'store/selectors/intel';
import { getAvmAdjustmentSelector } from 'store/selectors/radarPortfolio';

export const PropertyTab: FC<any> = memo(({ params, states }) => {
  const { data: valuationData, isPending: isPendingValuationData } =
    useSelector(getValuationDataSelector);
  const { isPending: isPendingAvmAdjustment } = useSelector(getAvmAdjustmentSelector);

  const { data } = useSelector(getRemindersByIdSelector);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.leftWrapper}>
        <ValuationCard
          valuationData={valuationData ?? []}
          isPending={isPendingValuationData || isPendingAvmAdjustment}
          params={params}
          rental={false}
        />
        <ValuationCard
          valuationData={valuationData ?? []}
          isPending={isPendingValuationData || isPendingAvmAdjustment}
          params={params}
          rental={true}
        />
      </div>
      <div className={styles.rightWrapper}>
        <ReminderCard data={data} states={states} />
      </div>
    </div>
  );
});
