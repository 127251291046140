import { useState } from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { getThreadsPerEntityId } from 'store/selectors/sockets/threads';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { setSharePropertyEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';
import { extractOrderedPhotos } from 'helpers';
import { uniqBy } from 'lodash-es';

import styles from './styles.module.scss';
import { getAgentPropertiesList } from 'store/selectors/feedv3';

const Comment = ({ className, propertyInfo, onlyReadMsg }) => {
  const threads = useSelector(getThreadsPerEntityId);
  const { PhotoUrls, Media, PhotoCount } = propertyInfo;
  const dispatch = useDispatch();
  const OrderedPhotos = extractOrderedPhotos(Media, PhotoCount);
  const ImagesToSend = !isEmpty(OrderedPhotos) ? OrderedPhotos : PhotoUrls;
  const { properties } = useSelector(getAgentPropertiesList);
  const [info] = useState({
    Id: propertyInfo.Id ? propertyInfo.Id : propertyInfo.id,
    ActivityStatus: propertyInfo.ActivityStatus,
    Address: propertyInfo?.address || propertyInfo?.Address,
    Neighborhood: propertyInfo?.Neighborhood,
    Geolocation: {
      Lat: propertyInfo?.Geolocation?.Lat || propertyInfo?.geometry?.coordinates?.lat,
      Long: propertyInfo?.Geolocation?.Long || propertyInfo?.geometry?.coordinates?.lng,
    },
    NumBathsTotal: propertyInfo?.NumBathsTotal,
    NumBeds: propertyInfo?.NumBeds,
    HomeType: propertyInfo?.HomeType,
    YearBuilt: propertyInfo?.YearBuilt,
    ExteriorAndLotDetails: propertyInfo?.ExteriorAndLotDetails,
    AssociationInfo: propertyInfo?.AssociationInfo,
    Parking: propertyInfo?.Parking,
    ListingDate: propertyInfo?.ListingDate,
    ListingInfo: propertyInfo?.ListingInfo,
    BuildingInfo: propertyInfo?.BuildingInfo,
    PhotoUrls: ImagesToSend ? ImagesToSend : [propertyInfo?.image],
    SellingPrice: propertyInfo?.SellingPrice || propertyInfo?.price,
    SquareFeet: propertyInfo?.SquareFeet,
    AddedToFavoritesBy: propertyInfo?.AddedToFavoritesBy,
    CustomKitchenFeatures: propertyInfo?.CustomKitchenFeatures,
    MatchedClientsCount: propertyInfo?.MatchedClientsCount,
    MatchedCriterias: propertyInfo?.MatchedCriterias,
    OpenHouses: propertyInfo?.OpenHouses,
    MatchScore: propertyInfo?.MatchScore,
    PropertyHistory: propertyInfo?.PropertyHistory || {},
  });

  const onComment = (e) => {
    e.stopPropagation();
    dispatch(setSharePropertyEffect(info));
    dispatch(openCommentsDrawerEffect({ open: true, params: { onlyReadMsg } }));
  };

  const propertyThreads = threads?.[propertyInfo?.Id] || [];
  const uniqueThreads = uniqBy(propertyThreads, 'Id');

  const countThreads = uniqueThreads.reduce((count, { PropertyComments }) => {
    return (
      count +
        PropertyComments.filter(
          ({ IsDeleted, MessageMeta }) =>
            !IsDeleted &&
            (isArray(MessageMeta)
              ? MessageMeta?.some(({ EntityId }) => EntityId === propertyInfo?.Id)
              : MessageMeta.EntityId === propertyInfo?.Id),
        )?.length || 0
    );
  }, 0);

  const countUnreadThreads = uniqueThreads.reduce((count, { PropertyComments, IsUnread }) => {
    return (
      count +
      (PropertyComments.filter(
        ({ IsDeleted, MessageMeta }) =>
          !IsDeleted &&
          (isArray(MessageMeta)
            ? MessageMeta?.some(({ EntityId }) => EntityId === propertyInfo?.Id)
            : MessageMeta.EntityId === propertyInfo?.Id),
      )?.length > 0 && IsUnread
        ? 1
        : 0)
    );
  }, 0);

  const isUnreadThread = countUnreadThreads > 0;

  return (
    <div onClick={onComment} className={classNames(styles.commentWrapper, className)}>
      <Icon
        className={classNames(styles.icon, {
          [styles.isUnread]: isUnreadThread,
        })}
        testid="comment_icon"
        variant={Icon.COMMENT}
      />
      {!!countThreads && <span className={styles.count}>{countThreads}</span>}
    </div>
  );
};

Comment.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  onlyReadMsg: PropTypes.bool,
};

Comment.defaultProps = {
  className: '',
  onlyReadMsg: false,
};

export default Comment;
