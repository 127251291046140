import React from 'react';
import PropTypes from 'prop-types';

const Archive = ({ className, onClick, color, size, strokeWidth }) => (
  <div testid="archive_icon" onClick={onClick} className={className}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59435 4.76745L2.62651 10.671C2.59252 10.7729 2.5752 10.8797 2.5752 10.9872V14.5999C2.5752 15.7045 3.47063 16.5999 4.57519 16.5999H15.4252C16.5298 16.5999 17.4252 15.7045 17.4252 14.5999V10.9872C17.4252 10.8797 17.4079 10.7729 17.3739 10.671L15.406 4.76745C15.1338 3.95076 14.3695 3.3999 13.5087 3.3999H6.49171C5.63085 3.3999 4.86658 3.95076 4.59435 4.76745Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.2251 8.3501H7.1126V8.7626C7.1126 10.3573 8.40538 11.6501 10.0001 11.6501V11.6501C11.5948 11.6501 12.8876 10.3573 12.8876 8.7626V8.3501H15.7751"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Archive.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  strokeWidth: PropTypes.string,
};

Archive.defaultProps = {
  className: '',
  onClick: () => {},
  color: '#303030',
  size: '24',
  strokeWidth: '1',
};

export default Archive;
