import { cloneDeep } from 'lodash-es';

import { Button } from 'components-antd';
import { InputLabel } from 'components';
import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';
import { Documents } from 'pages/Workshop/Transactions/TransactionTasks/components';
import { getUserFirstName, getUserId, getUserLastName } from 'store/selectors/user';
import { Icons } from './../../Task/ViewRevamped/Icons';
import { TaskSectionContainer } from '../../Task/ViewRevamped/components';
import { EditCircle, Plus } from 'components/Icons';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';

interface FilesProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  isAssignTask?: boolean | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  addFiles?: boolean | null;
  viewContentClassname?: string;
  onEdit: () => void;
  isCampaignTask?: boolean;
}

export const Files = ({
  values,
  isAssignTask,
  isTemplate,
  onChangeField,
  isViewMode,
  showOptional,
  viewContentClassname,
  addFiles,
  onEdit,
  isCampaignTask,
}: FilesProps) => {
  // const [addFiles, setAddFiles] = useState(false);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userId = useSelector(getUserId);

  const onSave = (newValues, cb) => {
    const extendedValues = (newValues || []).map((val) => ({
      ...val,
      Uploader: { Id: userId, FirstName: userFirstName, LastName: userLastName },
    }));
    onChangeField([...(values?.Documents || []), ...extendedValues], 'Documents');
    if (typeof cb === 'function') {
      cb();
    }
  };

  const onDeleteDocument = (event, index) => {
    if (isViewMode) return;
    const copyDocuments = cloneDeep(values?.Documents);
    copyDocuments.splice(index, 1);
    onChangeField(copyDocuments, 'Documents');
  };

  return (
    <div className={styles.files}>
      {addFiles || values?.Documents?.length ? (
        addFiles ? (
          <TaskSectionContainer className={styles.spacing}>
            <InputLabel label="Files" className={styles.label} showOptional={showOptional} />

            <div className={styles.fileListing}>
              <Documents
                value={values?.Documents}
                onDelete={onDeleteDocument}
                isCampaignTask={isCampaignTask}
                isTemplate={isTemplate}
              />
            </div>
            <CommonUploadDocuments
              onSave={onSave}
              uploadButton={
                <Button
                  variant="hollow-bordered"
                  className={styles.addFilesBtn}
                  icon={<Plus className={styles.btnIcon} />}
                >
                  Upload Files
                </Button>
              }
              withCategory
              withPermissions={!isTemplate}
              isCategoryOptional
              isAssignTask={isAssignTask}
              isViewMode={isViewMode}
              fillDocumentName={true}
            />
          </TaskSectionContainer>
        ) : (
          <TaskSectionContainer
            icon={<Icons variant={Icons.FILES} />}
            title="Files"
            titleClassName={styles.title}
            className={styles.spacing}
          >
            <div className={viewContentClassname}>
              <span>
                {values?.Documents?.map((item) => item?.filename || item?.Meta?.Filename)?.join(
                  ', ',
                )}
              </span>
              <EditCircle onClick={onEdit} className={styles.editIcon} />
            </div>
          </TaskSectionContainer>
        )
      ) : null}
    </div>
  );
};
