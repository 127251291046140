import { createSelector } from 'reselect';
import { get, groupBy } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ templates: { task } }) => task;

export const getTaskTemplatesSelector = createSelector(localState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getTaskTemplatesDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);

export const getGroupedTaskTemplatesSelector = createSelector(
  getTaskTemplatesDataSelector,
  (templates) => groupBy(templates, 'Category.Name'),
);

export const getFilteredGroupedTaskTemplatesSelector = createSelector(
  getTaskTemplatesDataSelector,
  localState,
  (templates, { query }) => {
    const search = query?.toLowerCase() || '';

    const filteredTemplates = templates?.filter((item) => {
      const name = item?.Name?.toLowerCase();
      const categoryNames = item?.Categories?.map((cat) => cat.Name.toLowerCase()) || ['general'];

      return name.includes(search) || categoryNames.some((category) => category.includes(search));
    });

    const groupedTemplates = {};
    const templatesWithoutCategory = [];

    filteredTemplates?.forEach((template) => {
      if (template?.Categories?.length) {
        template.Categories.forEach((category) => {
          const categoryName = category.Name;
          if (!groupedTemplates[categoryName]) {
            groupedTemplates[categoryName] = [];
          }
          groupedTemplates[categoryName].push(template);
        });
      } else {
        templatesWithoutCategory.push(template);
      }
    });

    return {
      ...groupedTemplates,
      General: templatesWithoutCategory,
    };
  },
);
