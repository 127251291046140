import { apiServer } from 'settings/web-services/api';

export function postListingFilter(body) {
  return apiServer.post('/ai/listingFilters', body);
}

// CLARITY AI

export function getAISessions() {
  return apiServer.get('/ai/sessions');
}

export function createAISession() {
  return apiServer.post('/ai/create-session');
}

export function getAISessionMessages(sessionId) {
  return apiServer.get(`/ai/sessions/${sessionId}/messages`);
}

export function generateAIReport(cfg) {
  return apiServer.post(`/ai/generateClarityReport`, cfg);
}
