import { FC, Fragment, ReactNode, useState } from 'react';

import { Drawer } from 'components';
import { Button, Collapse, Panel } from 'components-antd';
import { Icons } from 'pages/Workshop/Icons';
import { Plus, Minus } from 'components/Icons';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { isNumber } from 'lodash-es';

interface FilterSectionProps {
  title: string;
  children: ReactNode;
  count?: number;
}

export const FilterSection: FC<FilterSectionProps> = ({ title, children, count }) => {
  const getCollapseIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <Minus className={styles.btnWrapper} />
    ) : (
      <Plus className={styles.btnWrapper} />
    );
  };

  return (
    <Collapse
      ghost
      expandIcon={getCollapseIcon}
      expandIconPosition="end"
      className={styles.filterSection}
    >
      <Panel
        key="timeline"
        header={
          <div className={styles.title}>
            {title}
            {isNumber(count) && <span className={styles.count}>{' (' + count + ')'}</span>}
          </div>
        }
      >
        {children}
      </Panel>
    </Collapse>
  );
};

const Header = ({ close }) => (
  <div className={styles.drawerHeader}>
    <div>
      <Icons className={styles.icon} variant={Icons.FILTER} />
      <span className={styles.heading}>Filters</span>
    </div>
    <Icons className={styles.closeIcon} variant={Icons.CLOSE_BG} onClick={close} />
  </div>
);

export const FilterDrawer = ({
  children,
  isFiltersApplied,
  className,
  onDone,
  onReset,
  iconOnly = false,
  doneDisable = true,
  onDiscard = () => {},
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  function handleDoneClick() {
    onDone();
    setOpenDrawer(false);
  }

  function handleResetClick() {
    onReset();
    setOpenDrawer(false);
  }

  function handleDiscard() {
    setOpenDrawer(false);
    if (onDiscard) {
      onDiscard();
    }
  }

  return (
    <Fragment>
      <div
        className={classNames(
          styles.filterWrapper,
          styles.aggregateWrapper,
          { [styles.iconOnly]: iconOnly },
          className,
          {
            [styles.filtersAppliedWrapper]: isFiltersApplied,
          },
        )}
        onClick={() => setOpenDrawer(true)}
      >
        <div className={styles.icon}>
          <Icons variant={Icons.FILTER} />
        </div>
        {!iconOnly && <span>All Filters</span>}
      </div>

      <Drawer
        isOpen={openDrawer}
        onClose={handleDiscard}
        header={<Header close={handleDiscard} />}
        className={styles.filterDrawer}
        position={'right'}
        zIndex={1000}
      >
        <div className={styles.drawerContent}>{children}</div>
        <div className={styles.drawerFooter}>
          <div className={styles.resetBtn} onClick={handleResetClick}>
            <Icons variant={Icons.RESET} />
            <span className={styles.resetText}>Reset All</span>
          </div>
          <Button
            variant="secondary"
            className={classNames(styles.doneBtn, {
              [styles.disabled]: !isFiltersApplied && doneDisable,
            })}
            onClick={handleDoneClick}
            disabled={!isFiltersApplied && doneDisable}
          >
            Done
          </Button>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default FilterDrawer;
