import { useSelector } from 'react-redux';

import {
  getListingDetailPropertyData,
  getListingDetailData,
  getListingDetailFinancesData,
} from 'store/selectors/listingDetail';
import { getCommonProfileData, getUserRolesMapSelector } from 'store/selectors/user';

import { Accordion, TitleValueTable } from 'components';

import PropertyTabAgentInformation from './AgentInformation';
import BuyerCommutes from '../BuyerCommutes';
import styles from './styles.module.scss';
import QAReportTable from 'components/QAReportTable';
import { validatePropertyObject } from './util';

const PropertyTab = () => {
  const { description, keyDetails, listingInfo, property, interior, community, commutes } =
    useSelector(getListingDetailPropertyData);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);

  const listingDetailData = useSelector(getListingDetailData);
  const { isMosaikUser } = useSelector(getCommonProfileData);
  const { cost, priceHistory, salesHistory, taxHistory, financials, taxes } = useSelector(
    getListingDetailFinancesData,
  );

  const financial = [
    ...(cost?.length ? [{ label: 'Costs', value: cost }] : []),
    ...(financials?.length ? [{ label: 'Financials', value: financials }] : []),
    ...(taxes?.length ? [{ label: 'Taxes', value: taxes }] : []),
    ...(priceHistory?.length ? [{ label: 'Price History', value: priceHistory }] : []),
    ...(salesHistory?.length ? [{ label: 'Sales History', value: salesHistory }] : []),
    ...(taxHistory?.length ? [{ label: 'Tax History', value: taxHistory }] : []),
  ];

  return (
    <div className={styles.propertyTabContainer}>
      {!!keyDetails?.length && (
        <Accordion
          open
          testid="key_details_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Key Details"
        >
          <div className={styles.content}>
            <TitleValueTable items={keyDetails} />
          </div>
        </Accordion>
      )}
      {!!description?.text && (
        <Accordion
          testid="description_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Description"
        >
          <div testid="descr_content" className={styles.content}>
            {description.text}
          </div>
        </Accordion>
      )}
      {isClient && !!commutes?.length && (
        <Accordion
          testid="commute_times_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Commute Times"
        >
          <BuyerCommutes data={commutes} />
        </Accordion>
      )}
      {!!interior?.length && (
        <Accordion
          testid="interior_features_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Interior"
        >
          <div className={styles.content}>
            <TitleValueTable items={interior} />
          </div>
        </Accordion>
      )}
      {!!property?.length && (
        <Accordion
          testid="exterior_features_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Property Details"
        >
          <div className={styles.content}>
            <TitleValueTable items={property} />
          </div>
        </Accordion>
      )}
      {!!financial?.length && (
        <Accordion
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Financial Details"
        >
          <div className={styles.content}>
            {financial.map(({ label, value }, i) => (
              <>
                <p className={styles.heading}>{label}</p>
                <TitleValueTable items={value} last={i === financial.length - 1} />
              </>
            ))}
          </div>
        </Accordion>
      )}
      {!!community?.length && (
        <Accordion
          testid="building_info_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Community"
        >
          <div className={styles.content}>
            <TitleValueTable items={community} />
          </div>
        </Accordion>
      )}
      {!!listingInfo?.length && (
        <Accordion
          testid="listing_info_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Listing Information"
        >
          <div className={styles.content}>
            <TitleValueTable items={listingInfo} />
          </div>
        </Accordion>
      )}
      {isAgent && (
        <Accordion
          testid="agent_info_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Agent Information"
        >
          <PropertyTabAgentInformation />
        </Accordion>
      )}

      {isMosaikUser && (
        <Accordion
          testid="qa_block"
          className={styles.accordion}
          titleClassName={styles.titleClassName}
          previewClassName={styles.previewClassName}
          iconContainerClassName={styles.iconContainer}
          title="Quality Assurance Report"
        >
          <div className={styles.content}>
            <QAReportTable items={validatePropertyObject(listingDetailData)} />
          </div>
        </Accordion>
      )}
    </div>
  );
};

export default PropertyTab;
