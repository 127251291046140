import PropTypes from 'prop-types';

const VideoIcon = ({ className, color }) => (
  <div className={className}>
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.328346 2.72669C0 3.50757 0 4.45409 0 6.34713V9.66638C0 11.5184 0 12.4444 0.323614 13.2204C0.707856 14.1417 1.48698 14.9617 2.38747 15.3924C3.14588 15.7553 4.01519 15.7997 5.75382 15.8886C8.99834 16.0545 11.8879 16.0284 15.1411 15.8545H15.1411C16.9553 15.7576 17.8624 15.7091 18.619 15.3455C19.5296 14.908 20.289 14.1069 20.6774 13.1742C21 12.3993 21 11.4544 21 9.56467V6.42318C21 4.50367 21 3.54392 20.6725 2.7639C20.2767 1.82121 19.5118 1.0237 18.5865 0.588802C17.8209 0.228952 16.8912 0.190042 15.0318 0.112222C11.78 -0.0238732 8.98693 -0.0396408 5.88968 0.0839187C4.08733 0.15582 3.18616 0.191771 2.41899 0.550983C1.50108 0.980778 0.721209 1.79238 0.328346 2.72669ZM7.875 4.60846V11.276C7.875 11.6888 8.32943 11.9401 8.67907 11.7207L13.9914 8.38692C14.3193 8.18114 14.3193 7.70333 13.9914 7.49755L8.67907 4.16377C8.32943 3.94436 7.875 4.19568 7.875 4.60846Z"
        fill={color}
      />
    </svg>
  </div>
);

VideoIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

VideoIcon.defaultProps = {
  className: '',
  color: '#4673D1',
};

export default VideoIcon;
