import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import { getProjectsClientsSelector } from 'store/selectors/projects';
import { FormsArchiveFilterTableStatus, FormsFilterTableStatus } from 'app-constants';
import { isEqual } from 'lodash-es';
import _ from 'lodash';

let defaultFilters = {};
let initialFilter = true;
export const FilterSections = ({
  onDone,
  onReset,
  isArchive,
  formNames,
  clientNames,
  taskTransactions,
  projects,
  formOwners,
  isOwnerOrAdmin,
}) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const [previousFilters, setPreviousFilters] = useState({});
  const [forms, setForms] = useState<string[]>([]);
  const query = locationSrv.getQuery();
  const clientsData = useSelector(getProjectsClientsSelector);

  const [initialCache] = useState(taskTransactions);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [cachedTransactions, setCachedTransactions] = useState<any[]>([]);
  const [cachedProjects, setCachedProjects] = useState<any[]>([]);
  const [cachedForm, setCachedForm] = useState<any[]>([]);
  const [transactions, setTransactions] = useState<string[]>([]);
  const [cachedClientsData, setCachedClientsData] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(
    query.statuses ? query.statuses.split(',') : [],
  );

  const [cachedOwners, setCachedOwners] = useState<any[]>([]);
  const [owners, setOwners] = useState<number[]>([]);

  const [initialValues, setInitialValues] = useState<any>({});

  const StatusesData = isArchive ? FormsArchiveFilterTableStatus : FormsFilterTableStatus;

  const getFilters = () => ({
    transactions,
    statuses,
    clients,
    forms,
    owners,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (!cachedTransactions.length && taskTransactions?.length) {
      setCachedTransactions(taskTransactions);
    }

    if (!cachedProjects.length && projects?.length && !isEqual(taskTransactions, initialCache)) {
      setCachedProjects(projects);
    }

    setCachedForm(formNames);
    setCachedClientsData(clientNames);
    setCachedOwners(formOwners);
  }, [taskTransactions, formNames, clientNames, projects, clientsData]);

  useEffect(() => {
    initialFilter = true;
    !_.isEmpty(initialValues) && setInitialValues({});
  }, [isArchive]);

  const submitHandler = () => {
    if (isFiltersApplied && hasEmptyValues()) {
      setIsFiltersApplied(false);
    } else if (!isFiltersApplied) {
      setIsFiltersApplied(true);
    }

    onDone({
      transactions: [...transactions],
      statuses,
      client: [...clients],
      forms,
      owners: [...owners],
    });

    if (initialFilter) {
      setInitialValues({ formOwners, clientNames, formNames });
      initialFilter = false;
    }

    setPreviousFilters(getFilters());
  };

  const resetHandler = (callReset = true) => {
    setStatuses([]);
    setTransactions([]);
    setForms([]);
    setClients([]);
    setOwners([]);
    setInitialValues({});
    setIsFiltersApplied(false);
    callReset && onReset();

    setPreviousFilters(defaultFilters);
    initialFilter = true;
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const { transactions, selectedProjects, statuses, clients, forms, owners } =
      previousFilters as any;

    if (transactions) setTransactions(transactions);
    if (statuses) setStatuses(statuses);
    if (clients) setClients(clients);
    if (forms) setForms(forms);
    if (owners) setOwners(owners);
  };

  const hasEmptyValues = () =>
    !transactions.length && !statuses.length && !clients.length && !forms.length && !owners.length;

  const formOwnersList = initialValues.formOwners || cachedOwners;
  const formClientNames = initialValues.clientNames || cachedClientsData;
  const filterFormNames = initialValues.formNames || cachedForm;

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
      doneDisable={hasEmptyValues()}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Statuses" count={statuses?.length}>
          <GroupCheckBoxesSection
            entityList={Object.keys(StatusesData).map((value) => ({
              name: StatusesData[value],
              value,
            }))}
            entityValues={statuses}
            setEntity={setStatuses}
          />
        </FilterSection>

        <FilterSection title="Form" count={forms?.length}>
          <MultiSelectSection
            placeholder={'Search by Form...'}
            entityList={filterFormNames.map((form) => ({
              label: form,
              value: form,
            }))}
            entity={forms}
            setEntity={setForms}
          />
        </FilterSection>

        <FilterSection title="Transactions" count={transactions?.length}>
          <MultiSelectSection
            placeholder={'Search by Transaction...'}
            entityList={cachedTransactions.map(({ Id, address }) => ({
              label: address.split(', ')[0],
              value: Id,
            }))}
            entity={transactions}
            setEntity={setTransactions}
            locationPrefix
          />
        </FilterSection>

        {isOwnerOrAdmin && (
          <FilterSection title="Owners" count={owners?.length}>
            <MultiSelectSection
              placeholder={'Search by Owner...'}
              entityList={formOwnersList.map((item) => item)}
              entity={owners}
              setEntity={setOwners}
            />
          </FilterSection>
        )}

        <FilterSection title="Clients" count={clients?.length}>
          <MultiSelectSection
            placeholder={'Search by Client...'}
            entityList={formClientNames.map((Name) => ({
              label: Name,
              value: Name,
            }))}
            entity={clients}
            setEntity={setClients}
            avatarPrefix
          />
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
