import classNames from 'classnames';
import styles from './styles.module.scss';
import { VALIDATION_TYPES } from '../../../../ValidationSettingsPopover/helper';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import _ from 'lodash';
import { canFillNextField } from '../helper';

export const RadioItem = (props) => {
  const {
    item,
    color,
    field,
    index,
    scale,
    groupAnswers,
    documentIndex,
    setGroupAnswers,
    handleFieldUpdate,
    filledAnswersCount,
  } = props;

  const { id: isExistingOption, UUID } = item;
  const { validation, options } = field;
  const { type, value } = validation;

  let response: any[] = [];
  let defaultChecked = false;
  let isDisabled = false;

  let answer = groupAnswers[UUID]?.Answer || options[index].fieldAnswer?.answer;

  const allowOneAnswer =
    (type === VALIDATION_TYPES.AtMost || type === VALIDATION_TYPES.Exactly) && value === 1;

  try {
    if (answer) response = JSON.parse(answer);
  } catch (e) {
    response = [];
  }

  if (response.includes(UUID)) {
    defaultChecked = true;
  } else {
    defaultChecked = false;
    isDisabled = !canFillNextField(field, allowOneAnswer, filledAnswersCount);
  }

  const managePreviousAnswer = (updatedAnswers) => {
    let previousId;

    const newAnswers = { ...updatedAnswers };

    previousId = Object.keys(groupAnswers).filter(
      (keyUUID) => keyUUID !== UUID && !!groupAnswers[keyUUID].Answer,
    )[0];

    if (previousId) {
      dynamicManager.handleQuestionResponse(previousId, '', documentIndex, 12, true);
      newAnswers[previousId].Answer = '';
    }

    return { previousId, newAnswers };
  };

  const onRadioClick = () => {
    if (isDisabled) return;

    const updatedAnswers = { ...groupAnswers };
    let defaultAnswer = !defaultChecked ? [UUID] : '';
    let fieldAnswers;

    const value = defaultAnswer ? JSON.stringify(defaultAnswer) : '';

    if (allowOneAnswer) {
      const { newAnswers } = managePreviousAnswer(updatedAnswers);
      fieldAnswers = { ...newAnswers };
    } else {
      fieldAnswers = { ...updatedAnswers };
    }

    if (isExistingOption) {
      dynamicManager.handleQuestionResponse(UUID, value, documentIndex, 12, true);

      fieldAnswers[UUID] = fieldAnswers[UUID] || {
        UUID,
        Answer: '',
        DefaultAnswer: '',
      };
      fieldAnswers[UUID].Answer = value;
      setGroupAnswers(fieldAnswers);
    } else {
      !_.isEmpty(fieldAnswers) && setGroupAnswers(fieldAnswers);

      const newOptions = [...options];

      newOptions[index] = {
        ...newOptions[index],
        fieldAnswer: { answer: value, UUID, fontSize: 12, documentIndex },
      };

      if (allowOneAnswer) {
        newOptions.forEach((option, optionIndex) => {
          if (option.fieldAnswer?.answer && optionIndex !== index) {
            option.fieldAnswer.answer = '';
          }
        });
      }

      const resizedWidth = field.width / scale;
      const resizedHeight = field.height / scale;

      handleFieldUpdate({
        ...field,
        width: resizedWidth,
        height: resizedHeight,
        options: newOptions,
      });
    }
  };

  return (
    <span className={classNames(styles.radioEditorField)} id={`radioOption-${UUID}`}>
      <input
        type="radio"
        className={classNames(styles.radioField, { [styles.disabledRadioInput]: isDisabled })}
        style={{ accentColor: color.border, '--border-color': color.border } as any}
        checked={defaultChecked}
        onClick={onRadioClick}
        onChange={() => {}}
      />
    </span>
  );
};
