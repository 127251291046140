/* eslint-disable react/jsx-indent */
import { map } from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { Col, Row } from 'components-antd';
import { Cross } from 'components/Icons';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { PartnerCard } from './components/PartnerCard/PartnerCard';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const PartnersList = ({ list, tabName }) => {
  const { category } = useSelector(getConciergeSearchSelector);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [viewPartnerDetails, setViewPartnerDetails] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [IsStrictlyProfile, setIsStrictlyProfile] = useState(false);

  const getEmptyListText = () => {
    const numericCategory = Number(category);
    let text = '';

    if (tabName === 'recommendations') {
      if (numericCategory === ConciergeDefaultCategories.Draft) text = 'You have no drafts.';
      else if (numericCategory === ConciergeDefaultCategories.Pending)
        text = 'You have no pending vendor invites.';
      else text = 'You have no recommended vendors.';
    } else if (tabName === 'all') text = 'There aren’t any vendors to choose from at this time.';
    else if (tabName === 'archive') text = 'You have no archived vendors.';
    return text;
  };

  const getFinalListBySelectedCategory = (conciergeCategory) => {
    const numericCategory = Number(category);

    if (numericCategory === ConciergeDefaultCategories.Draft)
      return conciergeCategory?.partners?.filter((partner) => partner.isDraft);
    else if (numericCategory === ConciergeDefaultCategories.Pending)
      return conciergeCategory?.partners?.filter(
        (partner) => !partner?.ThirdParty?.User?.CompletedProfile && !partner?.IsPublished,
      );
    else return conciergeCategory?.partners?.filter((partner) => !partner.isDraft);
  };

  const getGroupedList = () => {
    let listCopy = [...list];
    // For drafts only
    listCopy = listCopy.map((item) => {
      if (item?.PartnerCategories?.length) return item;
      return {
        ...item,
        PartnerCategories: [
          {
            Id: 0,
            Title: 'Others',
          },
        ],
      };
    });

    // Flatten and deduplicate categories
    const uniqueCategories = listCopy
      .flatMap((item) => item.PartnerCategories)
      .reduce((acc, cat) => {
        if (!acc.has(cat.Id)) {
          acc.set(cat.Id, { ...cat, partners: [] });
        }
        return acc;
      }, new Map());

    // Populate partners for each unique category
    listCopy.forEach((item) => {
      item.PartnerCategories.forEach((category) => {
        const uniqueCategory = uniqueCategories.get(category.Id);
        if (uniqueCategory && !uniqueCategory.partners.some((partner) => partner.Id === item.Id)) {
          uniqueCategory.partners.push(item);
        }
      });
    });

    // Convert the Map back to an array for rendering
    const groupedCategories = Array.from(uniqueCategories.values());

    if (
      !groupedCategories.some((cat) => (getFinalListBySelectedCategory(cat)?.length ? true : false))
    )
      return getEmptyComponent();

    return (
      <Collapse
        defaultActiveKey={groupedCategories.map((cat) => cat.Id.toString())}
        className={styles.antCollapse}
        collapsible="header"
        bordered={false}
      >
        {groupedCategories.map((cat, index) => {
          const finalList = getFinalListBySelectedCategory(cat);
          if (!finalList.length) return null;

          return (
            <Panel
              className={styles.antCollapsePanel}
              key={cat.Id}
              showArrow={false}
              header={
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  className={styles.categoryHeader}
                  style={index === 0 ? { marginTop: 4 } : {}}
                >
                  <div className={styles.categoryHeaderContainer}>
                    <div className={styles.title}>{cat.Title}</div>
                    <div className={styles.count}>{`(${cat?.partners?.length})`}</div>
                  </div>
                </Col>
              }
            >
              <Row gutter={[0, 20]}>
                {finalList.map((partner) => (
                  <PartnerCard
                    partner={partner}
                    key={partner.Id}
                    setViewPartnerDetails={setViewPartnerDetails}
                    setSelectedPartnerId={setSelectedPartnerId}
                    setIsStrictlyProfile={setIsStrictlyProfile}
                  />
                ))}
              </Row>
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  const getEmptyComponent = () => {
    return (
      <div testid="no_partners" className={styles.noPartners}>
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.2881 51.9894L32.0036 44.7783L45.7192 51.9894L43.0992 36.7161L54.1947 25.9005M20.9081 36.7161L9.8125 25.9005L25.1458 23.6739L32.0036 9.77832C34.6817 15.2049 36.1833 18.2473 38.8614 23.6739"
            stroke="#E8E9EA"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.95081 48.6789L55.0622 12.8036"
            stroke="#E8E9EA"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
        <span className={styles.noPartnersText}>
          {getEmptyListText()}
          <br />
        </span>
      </div>
    );
  };

  return (
    <>
      <PartnerProfile
        partnerId={selectedPartnerId}
        open={viewPartnerDetails}
        setModalVisible={setViewPartnerDetails}
        setSelectedPartnerId={setSelectedPartnerId}
        IsStrictlyProfile={IsStrictlyProfile}
        setIsStrictlyProfile={setIsStrictlyProfile}
        onCancel={() => {
          setViewPartnerDetails(false);
          setSelectedPartnerId(null);
          setIsStrictlyProfile(false);
        }}
      />
      {list?.length ? (
        <div className={styles.partners}>
          <Row gutter={[0, 20]}>
            {tabName === 'all' && showMessage && (
              <Col xs={24} sm={24} md={24}>
                <div className={styles.directoryNote}>
                  <div className={styles.text}>
                    This directory includes vendors who were invited to Mosaik by other agents in
                    the area who recommend their services. This directory is for informational
                    purposes and does not constitute any endorsement or guarantee from Mosaik.
                  </div>
                  <div className={styles.icon} onClick={() => setShowMessage(false)}>
                    <Cross color="#747475" />
                  </div>
                </div>
              </Col>
            )}
            {Number(category) <= 0
              ? getGroupedList()
              : map(
                  list?.filter((partner) => !partner.isDraft),
                  (partner) => (
                    <PartnerCard
                      partner={partner}
                      key={partner.Id}
                      setViewPartnerDetails={setViewPartnerDetails}
                      setSelectedPartnerId={setSelectedPartnerId}
                      setIsStrictlyProfile={setIsStrictlyProfile}
                    />
                  ),
                )}
          </Row>
        </div>
      ) : (
        getEmptyComponent()
      )}
    </>
  );
};

PartnersList.propTypes = {
  isAllTab: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
  ),
};

export default PartnersList;
