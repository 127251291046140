import { get } from 'lodash-es';
import { push } from 'connected-react-router';

import {
  getUserData,
  putUserData,
  login,
  signUpUser,
  verifyEmail,
  updateNotificationsSettings,
  updateUserData,
  emailExists,
  markProfileAsComplete,
} from 'api/user';
import { ClientAddress } from 'app-constants';
import { appGetNotificationMessagesEffect } from 'store/effects/app';
import { getClientInstancesEffect } from 'store/effects/clientInstances';

import Api from 'store/effects/core/api';
import {
  requestGetUserDataAction,
  signUpAction,
  requestPutUserDataAction,
  requestUpdateBuyerImportantFeaturesAction,
  requestUpdateBuyerCommutesAction,
  requestVerifyEmailAction,
  requestNotificationsSettingsAction,
  requestPutUserInsightAction,
  requestPutUserIsMfaEnabledAction,
  emailExistsActions,
  requestGetMarkAgentProfileAsCompleteAction,
} from 'store/actions/user';
import { uploadAvatar, onBoardingUser } from 'api/onBoarding';
import { appLoginAction, appInitAction } from 'store/actions/app';

import {
  getUserId,
  getEditProfileAgentSendData,
  getEditProfileClientSendData,
  getEditProfileThirdPartySendData,
  getEditBrokerageAgentSendData,
  getEditProfilePartnerSendData,
  getEditPartnerProfileSendData,
} from 'store/selectors/user';
import { LocalStorage } from 'services';
import {
  AGENT,
  CLIENT,
  mapRoles,
  mapRouteRoles,
  THIRD_PARTY,
  SUPER_USER,
} from 'settings/constants/roles';
import { showErrorMessage } from 'helpers/errors';
import { setHeaders } from 'settings/web-services/api';

import { getState } from 'store';
import {
  AMENITIES_OPTIONS,
  convertPrefs,
  HOME_PREFS_OPTIONS,
  NEIGHBORHOOD_OPTIONS,
  prefsIds,
} from 'settings/constants/preferences';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { link } from 'settings/navigation/link';

import { socketsInitEffect, socketsCloseEffect } from 'store/effects/sockets';
import { getAgentTeamDetailEffect } from '../agentTeamDetail';
import { SessionStorage } from 'services/sessionStorage';
import { requestUpdateClientDetailsAction } from 'store/actions/clientDetail';
import { redirectToPaywallOrOnboardingIfNeeded } from 'store/effects/app';
import { getCurrentPlanLevelEffect } from '../subscription';
import { getClientContextsEffect } from '../context';

export const updateUserProfile = ({
  userId,
  state,
  values,
  cb,
  isThirdParty,
  isClient,
  isAgent,
  sendRequest,
  dispatch,
  isPartner,
}) => {
  if (isAgent) {
    const sendData = getEditProfileAgentSendData(state, values);
    return dispatch(sendRequest(sendData, { userId, urlRole: 'agent' }, cb));
  }
  if (isClient) {
    const sendData = getEditProfileClientSendData(state, values);
    return dispatch(sendRequest(sendData, { userId, urlRole: 'client' }, cb));
  }
  if (isPartner) {
    const sendData = getEditProfileThirdPartySendData(state, values);
    return dispatch(sendRequest(sendData, { userId, urlRole: 'thirdParty' }, cb));
  }
  if (isThirdParty) {
    const sendData = getEditProfilePartnerSendData(state, values);
    return dispatch(sendRequest(sendData, { userId, urlRole: 'thirdParty' }, cb));
  }
  throw new Error('userUpdateDataEffect - no role for data');
};

export const updateUserFullProfile = ({
  userId,
  state,
  values,
  cb,
  isThirdParty,
  isClient,
  isAgent,
  sendRequest,
  dispatch,
  isPartner,
}) => {
  let profileData = { Values: {} };
  let urlRole = 'error';
  if (isAgent) {
    profileData = getEditProfileAgentSendData(state, values);
    urlRole = 'agent';
  }
  if (isClient) {
    profileData = getEditProfileClientSendData(state, values);
    urlRole = 'client';
  }
  if (isPartner) {
    profileData = getEditProfileThirdPartySendData(state, values);
    urlRole = 'thirdParty';
  }
  if (isThirdParty) {
    profileData = getEditProfilePartnerSendData(state, values);
    urlRole = 'thirdParty';
  }
  if (urlRole === 'error') {
    throw new Error('userUpdateDataEffect - no role for data');
  }
  const brokerageData = getEditBrokerageAgentSendData(state, values);
  const sendData = {
    Values: { ...(isAgent ? brokerageData.Values : undefined), ...profileData.Values },
  };
  return dispatch(sendRequest(sendData, { userId, urlRole }, cb));
};

export const updatePartnerProfileEffect = (values, cb) => (dispatch) => {
  const state = getState();
  const userId = getUserId(state);

  const sendRequest = Api.execBase({ action: requestPutUserDataAction, method: putUserData });
  const sendData = getEditPartnerProfileSendData(state, values);
  return dispatch(sendRequest(sendData, { userId, urlRole: 'thirdParty' }, cb));
};

export const updatePartnerProfileDirectoryPermissionEffect = (sendData, cb) => (dispatch) => {
  const state = getState();
  const userId = getUserId(state);

  const sendRequest = Api.execBase({ action: requestPutUserDataAction, method: putUserData });
  return dispatch(sendRequest(sendData, { userId, urlRole: 'thirdParty' }, cb));
};

export const userUpdateDataEffect =
  ({ isAgent, isThirdParty, isClient, values, isPartner }, cb) =>
  (dispatch) => {
    const state = getState();
    const userId = getUserId(state);

    const sendRequest = Api.execBase({ action: requestPutUserDataAction, method: putUserData });
    const sendAvatar = Api.execBase({ action: requestPutUserDataAction, method: uploadAvatar });
    if (values.file instanceof File) {
      const formData = new FormData();
      formData.append('file', values.file);

      return dispatch(
        sendAvatar(formData, {}, (err) => {
          if (!err) {
            return updateUserProfile({
              dispatch,
              state,
              userId,
              sendRequest,
              isAgent,
              isThirdParty,
              isClient,
              cb,
              values,
              isPartner,
            });
          }

          return null;
        }),
      );
    }
    return updateUserProfile({
      dispatch,
      state,
      userId,
      sendRequest,
      isAgent,
      isThirdParty,
      isClient,
      cb,
      values,
      isPartner,
    });
  };

export const updateClientDataEffect =
  ({ clientId, email = '', phone = '', Client = {}, dob = '', ...rest }, cb) =>
  (dispatch) => {
    const sendRequest = Api.execBase({
      action: requestUpdateClientDetailsAction,
      method: putUserData,
    });
    const sendData = {
      Values: {
        Client: Client?.Address ? Client : undefined,
        ...(email ? { Email: email } : {}),
        ...(dob ? { DOB: dob } : {}),
        ...(phone
          ? {
              Phones: [
                {
                  PhoneNumber: phone,
                  PhoneType: 'Mobile',
                  IsPrimary: true,
                },
              ],
            }
          : {}),
        ...(rest?.firstName && {
          FirstName: rest.firstName,
        }),
        ...(rest?.lastName && {
          LastName: rest.lastName,
        }),
      },
    };

    return dispatch(sendRequest(sendData, { userId: clientId, urlRole: 'client' }, cb));
  };

export const userFullUpdateDataEffect =
  ({ isAgent, isThirdParty, isClient, values, isPartner }, cb) =>
  (dispatch) => {
    const state = getState();
    const userId = getUserId(state);

    const sendRequest = Api.execBase({ action: requestPutUserDataAction, method: putUserData });
    const sendAvatar = Api.execBase({ action: requestPutUserDataAction, method: uploadAvatar });

    if (values.file instanceof File) {
      const formData = new FormData();
      formData.append('file', values.file);

      return dispatch(
        sendAvatar(formData, {}, (err) => {
          if (!err) {
            return updateUserFullProfile({
              dispatch,
              state,
              userId,
              sendRequest,
              isAgent,
              isThirdParty,
              isClient,
              cb,
              values,
              isPartner,
            });
          }

          return null;
        }),
      );
    }

    return updateUserFullProfile({
      dispatch,
      state,
      userId,
      sendRequest,
      isAgent,
      isThirdParty,
      isClient,
      cb,
      values,
      isPartner,
    });
  };

export const brokerageUpdateData =
  ({ values }, cb) =>
  (dispatch) => {
    const state = getState();
    const userId = getUserId(state);

    const sendRequest = Api.execBase({ action: requestPutUserDataAction, method: putUserData });

    const sendData = getEditBrokerageAgentSendData(state, values);
    return dispatch(sendRequest(sendData, { userId, urlRole: 'agent' }, cb));
  };

export const userGetDataEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: requestGetUserDataAction, method: getUserData });

  return sendRequest(cfg, options, cb);
};

export const userGetDataOnBackgroundEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({ action: requestGetUserDataAction, method: getUserData });

  return sendRequest(cfg, options, cb);
};

export const loginEffect = (cfg, options = {}, cb, redirectUrl) => {
  //clear SessionStorage
  SessionStorage.clearSessionStorage();

  const deviceToken = LocalStorage.getDeviceToken();

  if (redirectUrl && !redirectUrl.startsWith('/')) redirectUrl = '/' + redirectUrl;

  const sendRequest = Api.execBase({ action: appLoginAction, method: login });
  const authParams = {
    user: cfg.email,
    password: cfg.password,
    code: cfg.code || undefined,
    deviceToken: deviceToken || undefined,
  };

  return async (dispatch) => {
    dispatch(
      sendRequest(authParams, { showError: false, ...options }, async (err, response) => {
        const state = getState();
        const notificationsMap = state?.app?.notificationMessages?.notificationMessagesMap;

        if (response && !err) {
          let selfSignup = false;
          const { data: { tokens, user, deviceToken } = {} } = response;
          const { accessToken, refreshToken } = tokens;
          response.data = user;

          if (accessToken && refreshToken) {
            LocalStorage.setChatAdviceStatus(false);
            LocalStorage.setTokens({
              accessToken,
              refreshToken,
            });
            /*
             * Whenever user logs in, we need to set both LocalStorage TokenContext
             * and SessionStorage LoginContext to ensure that other log ins from
             * the same browser session do not create race conditions
             */
            LocalStorage.setTokenContextFromEmail(user.Email);
            SessionStorage.setLoginContextFromEmail(user.Email);

            setHeaders({ accessToken });
            dispatch(appInitAction({ auth: true, chatAdviceStatus: false }));
            dispatch(appGetNotificationMessagesEffect({}));

            dispatch(socketsCloseEffect());
            dispatch(socketsInitEffect());

            if (user?.Roles?.includes(CLIENT)) {
              dispatch(getClientInstancesEffect());
            }

            if (user?.Roles?.includes(AGENT)) {
              await dispatch(getAgentTeamDetailEffect());
            }

            //DeviceToken for Device Recognition is only passed when it needs to be reset after expiration
            if (deviceToken) {
              LocalStorage.setDeviceToken(deviceToken);
            }

            const onboardingRoles = [CLIENT, AGENT, THIRD_PARTY];
            const isOnBoardingRole = onboardingRoles.some((role) => user?.Roles?.includes(role));
            const isRedirected = await redirectToPaywallOrOnboardingIfNeeded(user, dispatch, state);
            if (!isRedirected) {
              if (isOnBoardingRole && options?.isNewUser) {
                const userRole = user?.Roles?.[0];
                dispatch(
                  push(`${link.toOnBoarding(mapRoles[userRole])}?redirectUrl=${redirectUrl}`),
                );
              } else {
                if (user?.Roles?.includes(THIRD_PARTY)) {
                  dispatch(push(redirectUrl ? redirectUrl : '/'));
                } else if (user?.Roles?.includes(AGENT)) {
                  dispatch(push(redirectUrl ? redirectUrl : '/'));
                } else if (user?.Roles?.includes(SUPER_USER)) {
                  dispatch(push(redirectUrl ? redirectUrl : '/'));
                } else if (user?.Roles?.includes(CLIENT)) {
                  dispatch(push(redirectUrl ? redirectUrl : '/'));
                } else {
                  dispatch(push('/properties/feed'));
                }
              }
            }
          }
          // dispatch(appGetNotificationMessagesEffect({}));
        }

        if (typeof cb === 'function') {
          cb(err, response);
        }
      }),
    );
  };
};
export const emailExistsEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: emailExistsActions,
    method: emailExists,
  });

  return sendRequest(cfg, {}, cb);
};

export const signUpEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: signUpAction, method: signUpUser });
  const config = {
    user: {
      email: cfg.email,
      password: cfg.password,
      role: mapRouteRoles[cfg.role],
      FirstName: cfg.firstName,
      LastName: cfg.lastName,
      SelectedPlan: cfg?.selectedPlan,
    },
  };

  return sendRequest(config, options, (err, response, dispatch) => {
    if (response && !err) {
      const { tokens, user } = get(response, 'data.loginResult') || {};
      const { accessToken, refreshToken } = tokens;
      response.data = user;

      if (accessToken && refreshToken) {
        LocalStorage.setChatAdviceStatus(false);
        LocalStorage.setTokens({
          accessToken,
          refreshToken,
        });
        /*
         * Whenever user logs in, we need to set both LocalStorage TokenContext
         * and SessionStorage LoginContext to ensure that other log ins from
         * the same browser session do not create race conditions
         */
        LocalStorage.setTokenContextFromEmail(user.Email);
        SessionStorage.setLoginContextFromEmail(user.Email);

        setHeaders({ accessToken });
        dispatch(appInitAction({ auth: true, chatAdviceStatus: false }));

        dispatch(socketsCloseEffect());
        dispatch(socketsInitEffect());

        // dispatch(push('/'));
      }
    } else if (err?.response?.status === 400) {
      showErrorMessage('Wrong login or password');
    } else {
      showErrorMessage(err);
    }

    if (typeof cb === 'function') {
      cb(err, response);
    }
  });
};

export const updateClientImportantFeaturesEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateBuyerImportantFeaturesAction,
    method: onBoardingUser,
  });

  const getMustPrefs = (prefOptions) =>
    cfg[SEARCH_CRITERIA_IMPORTANCE.MUST].filter(
      (prefItem) => !!prefOptions.find(({ id }) => id === prefItem),
    );

  const getSomeWhatPrefs = (prefOptions) =>
    cfg[SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT].filter(
      (prefItem) => !!prefOptions.find(({ id }) => id === prefItem),
    );

  const joinPrefs = (must, somewhat) => {
    if (!must.length && !somewhat.length) return undefined;

    return [...must, ...somewhat];
  };

  const mustHomePrefs = convertPrefs(
    getMustPrefs(HOME_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatHomePrefs = convertPrefs(
    getSomeWhatPrefs(HOME_PREFS_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  const mustAmenitiesPrefs = convertPrefs(
    getMustPrefs(AMENITIES_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatAmenitiesPrefs = convertPrefs(
    getSomeWhatPrefs(AMENITIES_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  const mustNeighborHoodsPrefs = convertPrefs(
    getMustPrefs(NEIGHBORHOOD_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  );
  const someWhatNeighborHoodsPrefs = convertPrefs(
    getSomeWhatPrefs(NEIGHBORHOOD_OPTIONS),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  );

  let mustParkingPref = convertPrefs(
    getMustPrefs([{ id: prefsIds.parking }]),
    SEARCH_CRITERIA_IMPORTANCE.MUST,
  )?.[0];

  let someWhatParkingPref = convertPrefs(
    getSomeWhatPrefs([{ id: prefsIds.parking }]),
    SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  )?.[0];

  const { user, clientInstances } = getState();
  const userId = get(user, 'data.Id');
  const parkingSpaces = get(
    clientInstances,
    'data.searchInstances[0].DefaultPropertySearchPreferences.Parking.NumOfParkingSpaces',
    //TODO Support Context Switching
  );
  const defaultUserPropertySearchPreferences =
    get(clientInstances, 'data.searchInstances[0].DefaultPropertySearchPreferences') || {};
  //TODO Support Context Switching

  if (mustParkingPref) {
    mustParkingPref = {
      ...mustParkingPref,
      NumOfParkingSpaces: parkingSpaces || 1,
    };
    delete mustParkingPref?.Preference;
  }

  if (someWhatParkingPref) {
    someWhatParkingPref = {
      ...someWhatParkingPref,
      NumOfParkingSpaces: parkingSpaces || 1,
    };
    delete someWhatParkingPref?.Preference;
  }

  // TODO: This should support multiple contexts
  const defaultSearchInstanceId = clientInstances.data.searchInstances[0].Id;

  const config = {
    id: userId,
    role: 'client',
    Values: {
      Client: {
        SearchInstance: {
          Id: defaultSearchInstanceId,
          DefaultPropertySearchPreferences: {
            ...defaultUserPropertySearchPreferences,
            HomePrefs: joinPrefs(mustHomePrefs, someWhatHomePrefs),
            AmenitiesPrefs: joinPrefs(mustAmenitiesPrefs, someWhatAmenitiesPrefs),
            NeighborhoodPrefs: joinPrefs(mustNeighborHoodsPrefs, someWhatNeighborHoodsPrefs),
            Parking: mustParkingPref || someWhatParkingPref,
          },
        },
      },
    },
  };

  return sendRequest(config, options, cb);
};

export const updateBuyerCommutesEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestUpdateBuyerCommutesAction,
    method: onBoardingUser,
  });

  const { user, clientInstances } = getState();
  const userId = get(user, 'data.Id');

  //TODO Support Context Switching
  const defaultSearchInstance = get(clientInstances, 'data.searchInstances[0]');

  const config = {
    id: userId,
    role: 'client',
    Values: {
      Client: {
        SearchInstance: {
          Id: defaultSearchInstance.Id,
          DefaultPropertySearchPreferences: {
            ...(defaultSearchInstance?.DefaultPropertySearchPreferences ?? {}),
            CommutePrefs: cfg?.map((commute) => ({
              ...commute,
              MaxCommuteTimeInMinutes: +commute?.MaxCommuteTimeInMinutes,
            })),
          },
        },
      },
    },
  };

  return sendRequest(config, options, cb);
};

export const verifyEmailEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: requestVerifyEmailAction, method: verifyEmail });

  return sendRequest(cfg, options, cb);
};

export const updateNotificationsSettingsEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: requestNotificationsSettingsAction,
    method: updateNotificationsSettings,
  });

  // const group = cfg;

  // let config = {
  //   [groupType]: {
  //     [notificationType]: {
  //       [variant]: value,
  //     },
  //   },
  // };
  // if (groupType === 'Global') {
  //   config = {
  //     [groupType]: { [variant]: value },
  //   };
  // }

  return sendRequest(cfg, options, cb);
};

export const insightUpdateData = (value, cb) => (dispatch) => {
  const state = getState();
  const userId = getUserId(state);
  const sendRequest = Api.execBase({ action: requestPutUserInsightAction, method: updateUserData });

  return dispatch(sendRequest({ Values: { Insight: value } }, { userId }, cb));
};

export const updateIsMfaEnabledEffect = (value, cb) => (dispatch) => {
  const state = getState();
  const userId = getUserId(state);
  const sendRequest = Api.execBase({
    action: requestPutUserIsMfaEnabledAction,
    method: updateUserData,
  });

  //Clear DeviceRecognition on any change
  LocalStorage.removeDeviceToken();

  return dispatch(sendRequest({ Values: { IsMfaEnabled: value } }, { userId }, cb));
};

export const markProfileAsCompleteEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetMarkAgentProfileAsCompleteAction,
    method: markProfileAsComplete,
  });

  return sendRequest(cfg, {}, cb);
};
