import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trim } from 'lodash-es';
import { getOpenedChatThreadInfo } from 'store/selectors/sockets/threads';

import {
  getMessageTime,
  getWidgetMessageTime,
  getShortedChatParticipantsNames,
  convertNameToAvatarPlaceholder,
} from 'helpers/formatters';

import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

import { Avatars } from 'components';

import styles from './styles.module.scss';

const UsersMessage = (props) => {
  const {
    thread,
    className,
    openHandler,
    onClick,
    userId,
    isAvatarPlaceholder,
    withLabel,
    namesTitle,
    formatText,
    unreadClassName,
    isWidget,
    ...rest
  } = props;
  const dateTime = useMemo(
    () => getMessageTime(thread?.LastMessageCreatedDate),
    [thread?.LastMessageCreatedDate],
  );
  const onlineStatus =
    thread?.Participants?.length === 1 ? thread?.Participants[0].LastOnlineTime : null;
  const widgetDateTime = useMemo(
    () => getWidgetMessageTime(thread?.LastMessageCreatedDate),
    [thread?.LastMessageCreatedDate],
  );

  const isOffer = thread.Type === SOCKET_THREAD_TYPES.OFFER;
  const isPersonal = thread.Type === SOCKET_THREAD_TYPES.CHAT;

  const { title, subTitle, avatars } = useMemo(() => {
    const avatarsList = [];
    const namesList = [];

    if (thread?.Participants?.length) {
      thread?.Participants.forEach(({ Id, AvatarUrl, FirstName = '', LastName = '' }) => {
        if (Id !== userId) {
          const name = trim(FirstName + ' ' + LastName); // eslint-disable-line prefer-template
          namesList.push(name);
          avatarsList.push({
            url: AvatarUrl,
            placeholder: isAvatarPlaceholder && convertNameToAvatarPlaceholder(name),
          });
        }
      });
    }

    return {
      title: getShortedChatParticipantsNames(namesList),
      subTitle:
        namesTitle &&
        ((formatText ? formatText(thread?.TopicName) : thread?.TopicName) || '(no subject)'),
      avatars: avatarsList,
    };
  }, [
    thread?.Participants,
    userId,
    namesTitle,
    thread?.TopicName,
    formatText,
    isAvatarPlaceholder,
  ]);

  const lastMessage = useMemo(() => {
    if (thread?.LastMessage === null || thread?.LastMessage === undefined) {
      return 'No messages...';
    }

    if (thread?.LastMessage === '') {
      return 'Attachment';
    }

    return formatText ? formatText(thread?.LastMessage) : thread?.LastMessage;
  }, [thread?.LastMessage, formatText]);
  const onClickHandler = (...args) => {
    openHandler?.(...args);
    onClick?.(...args);
  };

  const getTagMarkup = (tag) => {
    let tagText = '';
    let tagNode = <></>;
    if (tag.Type === 'Property') {
      tagText = `@${tag.Address.Line1}`;

      tagNode = (
        <>
          <div className={styles.tagHyperLink}>
            <span>{`@${tag?.Address?.Line1 || 'Private Address'}`}</span>
          </div>
        </>
      );
    } else if (tag.Type === 'Contact') {
      tagText = `@${tag.FirstName} ${tag.LastName}`;

      tagNode = (
        <>
          <div className={styles.tagHyperLink}>
            <span>{`@${tag.FirstName} ${tag.LastName}`}</span>
          </div>
        </>
      );
    }

    return [tagText, tagNode];
  };

  const getText = () => {
    const textParts = [];
    let curserPosition = 0;
    thread?.LastMessageMeta?.Tags?.forEach((tag, index) => {
      let [tagText, tagNode] = getTagMarkup(tag);

      const tagPosition = lastMessage.indexOf(tagText);

      if (tagPosition > -1) {
        textParts.push(lastMessage.slice(curserPosition, tagPosition));
        curserPosition = tagPosition;
        textParts.push(tagNode);
        curserPosition = curserPosition + tagText.length;

        if (thread?.LastMessageMeta?.Tags.length - 1 === index) {
          textParts.push(lastMessage.slice(curserPosition));
        }
      } else {
        textParts.push(lastMessage.slice(curserPosition));
      }
    });
    return textParts.length ? textParts : lastMessage;
  };

  return (
    <div
      className={classNames(styles.item, className, {
        [styles.unread]: thread.IsUnread,
        [unreadClassName]: thread.IsUnread,
        [styles.offer]: isOffer,
      })}
      onClick={onClickHandler}
      testid="thread_item"
      {...rest}
    >
      <div className={styles.avatarContainer}>
        <Avatars
          avatars={avatars}
          className={styles.avatars}
          avatarClassName={styles.avatar}
          overflowClassName={styles.overflow}
          avatarWrapperClassName={styles.avatarWrapper}
          isNotSingleClassName={styles.isNotSingle}
        />
        {onlineStatus === 'Online' ? (
          <div className={styles.onlineStatus}>
            <div></div>
          </div>
        ) : null}
      </div>
      <div className={styles.itemContent}>
        <div className={styles.itemContentTitle}>
          <div className={styles.itemContentTitleName}>
            <span testid="item_title" className={styles.name}>
              {formatText ? formatText(title) : title}
            </span>
            {withLabel && (
              <span testid="item_dm" className={styles.personalTitleSpan}>
                DM
              </span>
            )}
            {isOffer && (
              <span testid="item_offer" className={styles.offerTitleSpan}>
                Offer
              </span>
            )}
            {subTitle && (
              <div testid="item_subtitle" className={styles.itemContentSubTitle}>
                {subTitle}
              </div>
            )}
          </div>
          <div testid="item_date" className={styles.itemContentTitleTime}>
            {isWidget ? widgetDateTime : dateTime}
          </div>
        </div>
        <div testid="item_message" className={styles.itemContentText}>
          {getText()}
        </div>
      </div>
    </div>
  );
};

UsersMessage.propTypes = {
  thread: PropTypes.shape({
    Type: PropTypes.string,
    TopicName: PropTypes.string,
    LastMessage: PropTypes.string,
    IsUnread: PropTypes.bool,
    LastMessageCreatedDate: PropTypes.string,
    Participants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  withLabel: PropTypes.bool,
  namesTitle: PropTypes.bool,
  className: PropTypes.string,
  unreadClassName: PropTypes.string,
  userId: PropTypes.number,
  openHandler: PropTypes.func,
  formatText: PropTypes.func,
  onClick: PropTypes.func,
  isAvatarPlaceholder: PropTypes.bool,
};

UsersMessage.defaultProps = {
  className: null,
  unreadClassName: null,
  userId: null,
  withLabel: false,
  namesTitle: false,
  openHandler: null,
  formatText: null,
  onClick: () => {},
  isAvatarPlaceholder: true,
};

export default UsersMessage;
