import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Avatar, Button, Checkbox } from 'components';
import { Edit, GreenCheckMark, Hourglass } from 'components/Icons';
import { Col, Tooltip } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { getAgentTypeSelector, getUserId } from 'store/selectors/user';
import { AGENT_TYPE, DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { TEAM_BASIC, THIRD_PARTY } from 'settings/constants/roles';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { getPartnerBusinessNameAndId } from 'store/selectors/singlePartner';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { useParams } from 'react-router-dom';
import { cloneDeep, get } from 'lodash-es';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { MessageIcon } from '../../../../icons';
import { ResendIcon } from './icons/ResendIcon';
import { sendInviteEffect } from 'store/effects';
import styles from './styles.module.scss';
import { LocationType } from 'types';
import moment from 'moment';
import { setVendorDraftEditModeId } from 'store/actions/partners';
import {
  convertNameToAvatarPlaceholder,
  getIfExistThread,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { vendorActionsById } from 'api/partners';

type Props = {
  partner: any;
  setSelectedPartnerId: Dispatch<SetStateAction<string>>;
  setViewPartnerDetails: Dispatch<SetStateAction<boolean>>;
  setIsStrictlyProfile: Dispatch<SetStateAction<boolean>>;
};

export const PartnerCard: React.FC<Props> = ({
  partner,
  setViewPartnerDetails,
  setSelectedPartnerId,
  setIsStrictlyProfile,
}) => {
  const dispatch = useDispatch();
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const { id } = useSelector(getPartnerBusinessNameAndId);
  const userId = useSelector(getUserId);
  const threads = useSelector(getThreadsListSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const [sendingInvite, setSendingInvite] = useState<boolean>(false);
  const { categoryId: partnerCategoryId } = useParams<any>();
  const { category } = useSelector(getConciergeSearchSelector);
  const getIsPartnerChecked = (partner) =>
    (multiple?.partners || []).some((part) => part?.Id === partner?.Id);
  const isPendingTab = Number(category) === ConciergeDefaultCategories.Pending;

  const isCompletedProfile = useMemo(
    () => get(partner, 'ThirdParty.User.CompletedProfile'),
    [partner],
  );

  const initiateSendingMessageToPartner = useCallback(
    (partner) => {
      const thread = getIfExistThread(
        threads,
        [id || partner?.Id, userId],
        ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
      );

      dispatch(openMessagesDrawerAction(true));

      if (!thread) {
        const firstName = partner?.ThirdParty?.User?.FirstName;
        const lastName = partner?.ThirdParty?.User?.LastName;

        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
            params: {
              threadId: thread?.Id || null,
              participants: [
                {
                  id: partner?.Id,
                  name: `${firstName || ''} ${lastName || ''} (${partner?.BusinessName || ''})`,
                  value: partner?.Id,
                  role: THIRD_PARTY,
                  avatarUrl: partner?.LogoUrl,
                  firstName: partner?.FirstName,
                  lastName: partner?.LastName,
                },
              ],
            },
          }),
        );
      } else {
        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.CHAT,
            params: {
              threadId: thread.Id,
            },
          }),
        );
      }
    },
    [dispatch, threads, id, userId, partner],
  );

  const handleMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToPartner,
    {
      categoryId: partnerCategoryId,
    },
  );

  const onSelectPartner = (partner, checked) => {
    const newPartners = cloneDeep(multiple?.partners) || [];
    if (checked) {
      newPartners.push(partner);
    } else {
      const removedPartnerIndex = newPartners.findIndex((part) => part?.Id === partner?.Id);

      if (removedPartnerIndex !== -1) {
        newPartners.splice(removedPartnerIndex, 1);
      }
    }
    dispatch(setMultipleRequestQuotesEffect({ partners: newPartners }));
  };

  const getMultipleActions = (partner) =>
    partner?.isMyRecommended ? (
      <div className={styles.multipleActions}>
        <Checkbox
          labelClassName={styles.checkboxLabelWrapper}
          onChange={(event, val, checked) => {
            onSelectPartner(partner, checked);
          }}
          checked={getIsPartnerChecked(partner)}
          testid="check_partner"
          id="cb1"
        />
      </div>
    ) : (
      <Checkbox
        onChange={(event, val, checked) => {
          onSelectPartner(partner, checked);
        }}
        checked={getIsPartnerChecked(partner)}
        id="cb2"
      />
    );

  const handleResendClick = async () => {
    try {
      const response = await vendorActionsById(partner?.Id, {
        IsStrictlyProfile: false,
        ResendInvite: true,
      });
      if (response.status === 200) showSuccessMessage('The invitation was successfully resent.');
      else showErrorMessage('Unable to resend invite.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Unable to resend invite.');
    }
  };

  const getAreaName = (area): string => {
    let formattedName: string = '-';
    if (area.Type === LocationType.Zipcode) {
      if (area.PlaceName) {
        const splitted = area.PlaceName.split(',');
        if (splitted.length > 1)
          formattedName = `${splitted[0].trim()}, ${splitted[1].trim().replace(/\d+/g, '')}`;
        formattedName = splitted[0];
      } else formattedName = area.Zipcode || '-';
    } else if (area.Type === 'State' || area.Type === 'County')
      formattedName = `${area.PlaceName.split(',')[0]}, ${area.State}`;
    else if (area.City && area.State) formattedName = `${area.City}, ${area.State}`;
    else if (area.City) formattedName = area.City;
    else if (area.State) formattedName = area.State;
    return formattedName;
  };

  const getActions = () => {
    if (
      !partner?.IsStrictlyProfile &&
      !isCompletedProfile &&
      !partner?.isDraft &&
      partner?.IsInvited &&
      !partner?.IsArchived &&
      agentType === AGENT_TYPE.Team &&
      agentRole !== TEAM_BASIC
    )
      return (
        <div className={styles.invitePendingWrapper}>
          <div
            className={classNames(styles.actionIcon, styles.resendIcon)}
            onClick={(e) => {
              e.stopPropagation();
              handleResendClick();
            }}
          >
            <ResendIcon />
          </div>
        </div>
      );
    else if (
      !partner?.IsStrictlyProfile &&
      isCompletedProfile &&
      !multiple?.modeEnable &&
      !partner?.isDraft &&
      partner?.IsInvited &&
      !partner?.IsArchived
    )
      return (
        <Tooltip
          placement="top"
          overlayClassName={classNames(styles.clientMessageTooltip, 'mosaikTooltip')}
          title={
            'This vendor activated their profile in Mosaik and accepts messages, inquiries, and quote requests directly through Mosaik.'
          }
          align={{
            offset: [-48, 0],
          }}
        >
          <Button
            className={styles.messages}
            onClick={(e) => {
              e.stopPropagation();
              //handleMessageButtonClick(partner);
            }}
            iconLeft={<MessageIcon />}
            testid="message_button"
          />
        </Tooltip>
      );
    else if (isTrueDraft())
      return (
        <div className={styles.invitePendingWrapper}>
          <div
            className={classNames(styles.actionIcon, styles.editIcon)}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setVendorDraftEditModeId(partner?.Id));
            }}
          >
            <Edit stroke={'#747475'} />
          </div>
        </div>
      );
    else if (multiple?.modeEnable) return getMultipleActions(partner);
    else return null;
  };

  const isTrueDraft = (): boolean => {
    if (!partner?.isDraft || (partner?.isDraft && partner?.IsSpecial)) return false;
    return true;
  };

  return (
    <>
      <Col xs={24} sm={24} md={24} key={partner?.Id} style={{ backgroundColor: '#f8f9fa' }}>
        <div
          testid="partner_item"
          id="ppc"
          className={classNames(styles.partner, {
            [styles.checked]: getIsPartnerChecked(partner),
          })}
          onClick={(e: any) => {
            if (isTrueDraft()) return;

            setIsStrictlyProfile(partner.IsStrictlyProfile);
            setSelectedPartnerId(partner?.Id);
            setViewPartnerDetails(true);
          }}
          style={{ cursor: isTrueDraft() ? 'default' : 'pointer' }}
        >
          <div
            className={classNames(styles.partnerDetails, isTrueDraft() ? styles.partnerDraft : '')}
          >
            <Avatar
              src={partner?.LogoUrl}
              placeholder={
                <div className={styles.avatarPlaceholder}>
                  {convertNameToAvatarPlaceholder(partner?.BusinessName)}
                </div>
              }
              avatarClassName={classNames(
                styles.avatar,
                !isCompletedProfile || partner?.IsArchived || isTrueDraft() ? styles.avatarDim : '',
              )}
              className={styles.avatarWrapper}
            />
            <div className={styles.partnerInfo}>
              <div testid="business_name" className={styles.partnerInfoTitle}>
                {partner?.BusinessName || '-'}
              </div>
              {(!partner?.IsArchived || isPendingTab) && partner?.AreasOfOperation?.length > 0 ? (
                <div
                  testid="description"
                  className={styles.partnerInfoDesc}
                  style={{ marginTop: 8, marginBottom: 4 }}
                >
                  <div className={styles.title}>
                    <GreenCheckMark width={16} height={16} /> Serves:
                  </div>
                  <div className={classNames(styles.itemsWrapper, styles.servicesOffered)}>
                    {partner.AreasOfOperation.map((area) => getAreaName(area)).join('; ')}
                  </div>
                </div>
              ) : null}
              {!partner?.IsArchived || isPendingTab ? (
                <div
                  testid="description"
                  className={styles.partnerInfoDesc}
                  style={partner?.AreasOfOperation?.length ? {} : { marginTop: 8 }}
                >
                  <div className={styles.title}>
                    <GreenCheckMark width={16} height={16} /> Offers:
                  </div>
                  <div className={classNames(styles.itemsWrapper, styles.servicesOffered)}>
                    {partner?.PartnerTags?.map(({ Name }) => Name).join(', ') || 'General Services'}
                  </div>
                </div>
              ) : null}
              {partner?.IsArchived && !isPendingTab ? (
                <div
                  testid="description"
                  className={classNames(styles.partnerInfoDesc, styles.inviteDate)}
                >
                  <div className={styles.title}>{`Removed from Recommendations ${
                    partner?.UnrecommendDate
                      ? `: ${moment(partner?.UnrecommendDate).format('MM/DD/YYYY')}`
                      : ''
                  }`}</div>
                </div>
              ) : null}
              {!isCompletedProfile &&
              !partner.IsPublished &&
              partner?.InvitedDate &&
              (!partner?.IsArchived || isPendingTab) ? (
                <div
                  testid="description"
                  className={classNames(styles.partnerInfoDesc, styles.inviteDate)}
                >
                  <div className={styles.title}>
                    <Hourglass /> Invitation Sent:
                  </div>
                  <div className={classNames(styles.itemsWrapper, styles.servicesOffered)}>
                    {moment(partner?.InvitedDate).format('MM/DD/YYYY')}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={styles.partnerButtons}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {getActions()}
          </div>
        </div>
      </Col>
    </>
  );
};
