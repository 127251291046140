import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getDataFor } from 'store/helpers';
import { cloneDeep, get, reduce } from 'lodash-es';

import { requestGetMatchedClientsWithScoresByMls } from 'store/actions/mlsScores';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: {},
  meta: {},
  agentScore: {},
};

export default handleActions(
  {
    [requestGetMatchedClientsWithScoresByMls]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: {
        ...state.data,
        ...reduce(
          get(payload, ['data', 'result']),
          (acc, item) => {
            const key = get(item, 'PropertyId');
            const mls = get(item, 'MatchScores');
            return { ...acc, [key]: mls };
          },
          {},
        ),
      },
      meta: getDataFor('meta', payload, initialData),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
