import React from 'react';
import classNames from 'classnames';

type ArrowKitIconProps = {
  className?: string;
  asc: boolean;
  onClick?: () => void;
  color?: string;
};

export const RadarSortIcon: React.FC<ArrowKitIconProps> = ({
  onClick,
  className,
  asc,
  color = '#747475',
}) => {
  return (
    <div
      className={classNames(className)}
      style={{
        transform: asc ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s ease-in-out',
      }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 17H8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 12H10.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 7L13 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 6V19M18 19L15 16M18 19L21 16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
