import classNames from 'classnames';

import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import workshop from 'settings/navigation/routes/workshop';

import styles from './styles.module.scss';
import { ArrowLeft } from 'components/Icons';

const SubtitleLeftSection = ({ transaction, transactionAddress }) => {
  const { IsProject } = transaction;

  return (
    <p className={styles.streetAddress}>
      {IsProject
        ? `${transaction?.Name}`
        : `${transactionAddress?.Line1}
        ${transactionAddress?.Line2 ? transactionAddress?.Line2 : ''}`}
    </p>
  );
};

const SubtitleCenterSection = ({ transaction }) => {
  const { IsProject } = transaction;
  const names = IsProject ? transaction?.AgentsOrOwners : transaction?.TransactionClients;

  return (
    <p className={styles.transactionClients}>
      <span className={styles.label}>{IsProject ? 'Owner(s)' : 'Client(s)'}:</span>
      {names?.length > 0 ? (
        <NamesContainer names={names} maxDisplayCount={1} tooltipClass={styles.marginLeft} />
      ) : (
        'None'
      )}
    </p>
  );
};

const SubtitleRightSection = ({ categories }) => (
  <p className={styles.category}>{categories?.join(', ')}</p>
);

export const Header = ({
  taskTransactionId,
  title,
  transaction,
  categories,
  showDescriptionView,
  hideDescriptionView,
}) => {
  const transactionAddress = transaction?.Property?.Address ?? {};
  const isAggregatedTasks = location?.pathname === workshop.tasks;

  const subtitleProps = {
    transaction,
    transactionAddress,
  };

  return (
    <div className={styles.taskTitle}>
      {showDescriptionView && (
        <div onClick={hideDescriptionView}>
          <ArrowLeft className={styles.arrowBackIcon} />
        </div>
      )}
      <p className={styles.titleText}>{title}</p>

      <div
        className={classNames(styles.subtitle, {
          [styles.isAggregratedSubtitle]: isAggregatedTasks,
        })}
      >
        {isAggregatedTasks && taskTransactionId ? (
          !showDescriptionView && (
            <>
              <SubtitleLeftSection {...subtitleProps} />
              <div className={styles.seperatorContainer}>
                <div className={styles.seperator} />
              </div>
              <SubtitleCenterSection {...subtitleProps} />
              {categories?.length ? (
                <>
                  <div className={styles.seperatorContainer}>
                    <div className={styles.seperator} />
                  </div>
                </>
              ) : (
                <></>
              )}
              <SubtitleRightSection categories={categories} />
            </>
          )
        ) : (
          <SubtitleRightSection categories={categories} />
        )}
      </div>
    </div>
  );
};
