import { useState, useEffect } from 'react';
import { orderBy } from 'lodash-es';
import { arrayMove } from 'helpers';

import { Row, Col, Button, DraggableMenuList } from 'components-antd';
import { Input, InputLabel, Select } from 'components';
import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';
import { TaskSectionContainer } from '../../Task/ViewRevamped/components';
import { Icons } from './../../Task/ViewRevamped/Icons';
import { EditCircle, MinusRounded, Plus } from 'components/Icons';

import styles from './styles.module.scss';

interface ChecklistsProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  className?: string | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  editData?: any;
  isSuperUser?: boolean;
  addChecklists?: boolean;
  viewContentClassname?: string;
  onEdit: () => void;
}

interface Checklist {
  Name: string;
  Required: boolean;
}

const initChecklist = {
  Name: '',
  Required: false,
};

const ChecklistOptions = [
  { name: 'Required', value: 'Required' },
  { name: 'Optional', value: 'Optional' },
];

export const Checklists = ({
  values,
  editData,
  onChangeField,
  isViewMode,
  showOptional,
  isSuperUser = false,
  viewContentClassname = '',
  addChecklists,
  onEdit,
}: ChecklistsProps) => {
  const [listValue, setListValue] = useState<Checklist[]>(values?.Checklists ?? []);
  const [activeDraggableId, setActiveDraggableId] = useState(0);

  const onAdd = () => {
    setListValue((prev) => [...prev, initChecklist]);
    onChangeField(listValue, 'Checklists');
  };

  const onChange = (value, field, index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, {
      ...listValue[index],
      [field]: value,
    });
    setListValue([...copyList]);
    onChangeField([...copyList], 'Checklists');
  };

  const onRemove = (index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1);
    setListValue(copyList);
    onChangeField(copyList, 'Checklists');
  };

  useEffect(() => {
    if (addChecklists && !listValue?.length) onAdd();
  }, [addChecklists]);

  const getItem = ({ index, item }) => {
    return (
      <div className={styles.checklistItem}>
        <Row gutter={24}>
          <Col lg={15}>
            <Input
              name={`${item.Name}-${index}`}
              value={item.Name}
              placeholder="Add checklist"
              variant={Input.LIGHT_ROUND}
              onChange={(e, value) => onChange(value, 'Name', index)}
              disabled={isViewMode}
            />
          </Col>
          <Col lg={7}>
            <Select
              options={ChecklistOptions}
              onSelect={(e) => {
                const { name } = e.target.value;
                onChange(name === 'Required', 'Required', index);
              }}
              variant={Select.LIGHT_ROUND}
              value={item?.Required ? 'Required' : 'Optional'}
              className={{
                input: styles.valueInput,
              }}
            />
          </Col>
          <Col lg={2}>
            <button
              testid="input_remove"
              className={styles.removeBtn}
              onClick={() => !isViewMode && onRemove(index)}
              type="button"
            >
              <MinusRounded />
            </button>
          </Col>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    const checklists = values?.Checklists || editData?.Checklists;
    if (checklists?.length) {
      setListValue(orderBy(checklists, 'Id', ['asc']));
    }
  }, []);

  const updateList = (data) => {
    const source = data?.source?.index || 0;
    const destination = data.destination?.index || 0;

    if (source !== destination) {
      const updatedList: Checklist[] = arrayMove([...listValue], source, destination);
      setListValue(updatedList);
      onChangeField([...updatedList], 'Checklists');
    }
  };

  if (isViewMode && !listValue?.length) return <></>;

  return (
    <div className={styles.checklists}>
      {addChecklists || listValue?.length ? (
        addChecklists ? (
          <TaskSectionContainer className={styles.spacing}>
            <Row align="middle">
              <Col xs={20} sm={20} md={22} lg={22} xl={22}>
                <InputLabel
                  label="Checklists"
                  altLabel={isViewMode ? ' (View only)' : ''}
                  className={styles.label}
                  showOptional={showOptional}
                />
              </Col>
            </Row>

            <DraggableMenuList
              activeItem={activeDraggableId}
              className={styles.dragMenuList}
              items={listValue.map((item, index) => ({
                key: `index-${index}`,
                content: getItem({ index, item }),
              }))}
              onDragEnd={updateList}
              disabled={false}
              onDraggableClick={setActiveDraggableId}
            />

            {!isViewMode && (
              <Button
                variant="hollow-bordered"
                className={styles.addChecklistsBtn}
                onClick={() => !isViewMode && onAdd()}
              >
                <Plus /> Add another
              </Button>
            )}
          </TaskSectionContainer>
        ) : (
          <TaskSectionContainer
            title="Checklist"
            icon={<Icons variant={Icons.CHECKLIST} />}
            className={styles.spacing}
            titleClassName={styles.title}
          >
            <div className={viewContentClassname}>
              <span>{listValue?.length} items</span>
              <EditCircle onClick={onEdit} className={styles.editIcon} />
            </div>
          </TaskSectionContainer>
        )
      ) : null}
    </div>
  );
};
