import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';
import Attachment from './Attachment';
import { Button } from 'components-antd';
import { Icons as FormIcons } from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components';

import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';
import { LinkFormModal } from 'pages/Workshop/Forms/components/LinkFormModal';
import { Delete } from 'components/Icons';

import styles from './styles.module.scss';
import { showErrorMessage } from 'helpers';
import { useEffect, useState } from 'react';

const UploadFiles = (props) => {
  const {
    className,
    showLinkForms,
    isAssignTask,
    currentQuestionId,
    question,
    onPreFormSubmit,
    loading,
  } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const [openLinkFormsModal, setOpenLinkFormsModal] = useState(false);
  const [formsToLink, setFormsToLink] = useState([]);

  useEffect(() => {
    const formProcessIds = formsToLink?.length ? formsToLink.map((item) => item.formProcessId) : [];

    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.formProcessIds]: formProcessIds,
      }),
    );
  }, [formsToLink]);

  const questionId =
    currentQuestionId === transactionPreFormQuestionsIds.purchaseAgreement
      ? transactionPreFormQuestionsIds.purchaseAgreement
      : transactionPreFormQuestionsIds.files;

  const uploadedFiles = cloneDeep(preForm?.[questionId]) || [];

  const updateStoredFiles = (values) => {
    dispatch(setTransactionPreFormQuestionsEffect({ [questionId]: values }));
  };

  const getTotalFileSize = (files) => {
    return files.reduce((total, file) => total + file.size, 0);
  };

  const onSave = (newValues, cb) => {
    const newTotalSize = getTotalFileSize([...uploadedFiles, ...newValues]);
    const maxSize = 190 * 1024 * 1024;
    if (newTotalSize > maxSize) {
      showErrorMessage('exceeds the maximum limit of 190 MB.');
      return;
    }

    updateStoredFiles([...uploadedFiles, ...newValues]);
    cb();
  };

  const onAttachmentRemove = (event, index) => {
    event.stopPropagation();
    uploadedFiles.splice(index, 1);
    updateStoredFiles(uploadedFiles);
  };

  const onFormRemove = (item) => {
    setFormsToLink(formsToLink?.filter((i) => i.formProcessId !== item?.formProcessId));
  };

  return (
    <div testid="upload_files" className={classNames(styles.container, className)}>
      <Question>{question}</Question>
      <AnswersContainer>
        <CommonUploadDocuments
          dropzone
          uploadButton={null}
          onSave={onSave}
          pendingNeeded={false}
          withPermissions
          isAssignTask={isAssignTask}
          isCategoryOptional
          fillDocumentName={true}
        />
        <div>
          {(uploadedFiles || []).map((file, index) => (
            <Attachment
              key={file?.id || index}
              onRemove={(event) => onAttachmentRemove(event, index)}
              {...file}
            />
          ))}
        </div>
        {formsToLink?.map((item, idx) => (
          <div className={styles.containerFormToLink} key={idx}>
            <div className={styles.left}>
              <FormIcons variant={FormIcons.FILE_2} />
              <span className={styles.formName}>{item?.formName}</span>
            </div>
            <div onClick={() => onFormRemove(item)}>
              <Delete className={styles.deleteIcon} />
            </div>
          </div>
        ))}
      </AnswersContainer>
      <ButtonsContainer>
        {showLinkForms && (
          <Button
            className={styles.linkFormsBtn}
            variant="hollow-bordered"
            onClick={() => setOpenLinkFormsModal(true)}
          >
            Link Forms
          </Button>
        )}
        <Continue onClick={onPreFormSubmit} isPending={loading} />
      </ButtonsContainer>

      <LinkFormModal
        open={openLinkFormsModal}
        onClose={() => setOpenLinkFormsModal(false)}
        onClickRow={(row) => setFormsToLink([...formsToLink, row])}
      />
    </div>
  );
};

UploadFiles.propTypes = {
  className: PropTypes.string,
  currentQuestionId: PropTypes.string,
  isAssignTask: PropTypes.bool,
  showLinkForms: PropTypes.bool,
  question: PropTypes.string,
  onPreFormSubmit: PropTypes.func,
};

UploadFiles.defaultProps = {
  className: '',
  currentQuestionId: null,
  isAssignTask: true,
  showLinkForms: false,
  question: 'Do you want to upload any files?',
  onPreFormSubmit: () => {},
};

export default UploadFiles;
