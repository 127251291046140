import { Modal } from 'components-antd';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import './addVendorModal.scss';
import InviteOrPublish from './components/InviteOrPublish';
import { Button, Spinner } from 'components';
import classNames from 'classnames';
import { ArrowLeft } from 'components/Icons';
import AddVendorDetails from './components/AddVendorDetails';
import { isValidURL, validateEmail } from 'utils';
import PriorToInvitation from './components/PriorToInvitation';
import EmailTemplate from './components/EmailTemplate';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { postPartnerDatabyAgent, getCategories, getPartnerById } from 'api/admin';
import VendorDrafts from './components/VendorDrafts';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import moment from 'moment';
import { isEqual } from 'lodash';
import { getUserByEmail } from 'api/user';
import UserFoundModal from '../UserFoundModal';
import { CloseModalIcon, CloseModalIconSmall, DraftIcon } from './icons';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import {
  updatePartnerById,
  postPartnerDraftByAgent,
  updateVendorDraftById,
  getVendorDraftById,
  deleteVendorDraftById,
} from 'api/partners';
import {
  deletePartnerDraftsAsRecommendations,
  replaceInRecommendationListAction,
  setPartnerDraftsAsRecommendations,
  setVendorDraftEditModeId,
  setVendorEditInviteMode,
  setVendorEditModeId,
  setVendorEditStrictlyProfile,
  vendorCreateAction,
  vendorDraftToInviteAction,
  vendorDraftUpdateAction,
  vendorProfileUpdateAction,
} from 'store/actions/partners';
import {
  Props,
  AddVendorPayloadType,
  AddNewVendorStages,
  addVendorPayloadInitVals,
  VendorDetailType,
  VendorValidationErrors,
} from './constants';
import {
  selectVendorDraftEditModeId,
  selectVendorEditModeId,
  getRecommendedPartnersByAgentList,
  selectVendorEditInviteMode,
  selectVendorEditStrictlyProfile,
} from 'store/selectors/partners';

export const AddVendorModal = (props: Props) => {
  const { open, handleClose, ...rest } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [draftSaving, setDraftSaving] = useState<boolean>(false);
  const [draftMode, setDraftMode] = useState<boolean>(false);
  const [viewDrafts, setViewDrafts] = useState<boolean>(false);
  const [editModePrepared, setEditModePrepared] = useState<boolean>(false);
  const [openFoundModal, setOpenFoundModal] = useState<boolean>(false);
  const [verifyingEmail, setVerifyingEmail] = useState<boolean>(false);
  const [vendorDetailTypes, setVendorDetailTypes] = useState<VendorDetailType[]>([]);
  const [partnerTags, setPartnerTags] = useState<any[] | undefined>(undefined);
  const [draftId, setDraftId] = useState<number>(0);
  const [userFound, setUserFound] = useState<any>(undefined);
  const [validationError, setValidationErrors] = useState<VendorValidationErrors>({});
  const vendorEditModeId = useSelector(selectVendorEditModeId);
  const vendorEditInviteMode = useSelector(selectVendorEditInviteMode);
  const vendorDraftEditModeId = useSelector(selectVendorDraftEditModeId);
  const selectedConciergeCategory = useSelector(getConciergeSearchSelector);
  const conciergeList = useSelector(getRecommendedPartnersByAgentList);
  const [newVendorPayloadCopy, setNewVendorPayloadCopy] = useState<any>(null);
  const [aopError, setAopError] = useState<boolean>(false);
  const IsStrictlyProfile = useSelector(selectVendorEditStrictlyProfile);
  const [invitingExistingPartnerId, setInvitingExistingPartnerId] = useState<number>(0);
  const [activeSection, setActiveSection] = useState<VendorDetailType | undefined>(
    VendorDetailType.General,
  );
  const [newVendorPayload, setNewVendorPayload] =
    useState<AddVendorPayloadType>(addVendorPayloadInitVals);
  const [currentStage, setCurrentStage] = useState<AddNewVendorStages>(
    AddNewVendorStages.InviteOrPublish,
  );
  const phoneSchema = Yup.string().phone('US', false, '');
  const draftModeAllowed = false;

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(setVendorEditInviteMode(false));
      dispatch(setVendorDraftEditModeId(undefined));
      dispatch(setVendorEditModeId(undefined));
      dispatch(setVendorEditStrictlyProfile(false));
    };
  }, []);

  useEffect(() => {
    if (
      (draftId || vendorEditModeId || vendorDraftEditModeId) &&
      !editModePrepared &&
      !loading &&
      hasPayloadAnyValue(newVendorPayload)
    ) {
      setInfoSectionsWhenEditing();
    }
  }, [
    draftId,
    vendorEditModeId,
    editModePrepared,
    newVendorPayload,
    vendorDraftEditModeId,
    loading,
  ]);

  useEffect(() => {
    if (
      (!newVendorPayload.Published && aopError) ||
      (newVendorPayload.Published &&
        newVendorPayload.ServiceAreas?.length &&
        newVendorPayload.ServiceAreas?.some((area) => area.location !== undefined))
    )
      setAopError(false);
  }, [newVendorPayload.Published, newVendorPayload.ServiceAreas]);

  const setInfoSectionsWhenEditing = () => {
    let vendorDetailTypesCopy = [...vendorDetailTypes];
    if (newVendorPayload.Phone && !vendorDetailTypesCopy.includes(VendorDetailType.PhoneNumber))
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.PhoneNumber];
    if (newVendorPayload.Website && !vendorDetailTypesCopy.includes(VendorDetailType.Website))
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.Website];
    if (newVendorPayload.Address && !vendorDetailTypesCopy.includes(VendorDetailType.Address))
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.Address];
    if (
      newVendorPayload.ServiceAreas?.length &&
      !vendorDetailTypesCopy.includes(VendorDetailType.ServiceAreas)
    )
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.ServiceAreas];
    if (
      newVendorPayload.ServicesOffered?.length &&
      !vendorDetailTypesCopy.includes(VendorDetailType.ServicesOffered)
    )
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.ServicesOffered];
    if (newVendorPayload.AgentNote && !vendorDetailTypesCopy.includes(VendorDetailType.AgentNote))
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.AgentNote];
    if (
      newVendorPayload.Description &&
      !vendorDetailTypesCopy.includes(VendorDetailType.Description)
    )
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.Description];
    if (
      (newVendorPayload.PointOfContactName || newVendorPayload.PointOfContactEmail) &&
      !vendorDetailTypesCopy.includes(VendorDetailType.PointOfContact)
    )
      vendorDetailTypesCopy = [...vendorDetailTypesCopy, VendorDetailType.PointOfContact];

    setVendorDetailTypes(vendorDetailTypesCopy);

    if (vendorEditInviteMode) {
      setCurrentStage(AddNewVendorStages.AddVendorDetails);
      setActiveSection(VendorDetailType.General);
    }

    setEditModePrepared(true);
  };

  const fetchData = async () => {
    setLoading(true);
    await fetchServicesOffered();
    if (vendorEditModeId && !vendorDraftEditModeId) await fetchVendorProfile();
    else if (!vendorEditModeId && vendorDraftEditModeId) await fetchVendorDraft();
    setLoading(false);
  };

  const fetchServicesOffered = async () => {
    try {
      const response = await getCategories({ active: true });
      if (response.status === 200) {
        setPartnerTags(response?.data?.result?.length ? [...response.data.result] : []);
      }
    } catch (e) {
      setPartnerTags([]);
    }
  };

  const fetchVendorProfile = async () => {
    try {
      const response = await getPartnerById({ id: vendorEditModeId, IsStrictlyProfile });

      if (response?.status === 200 && response?.data?.result) {
        const responseData = response.data.result;
        const data = convertResponseToStateObject(responseData);
        setNewVendorPayload(data);
      } else showErrorMessage('Unable to fetch vendor profile.');
    } catch (e) {
      showErrorMessage('Unable to fetch vendor profile.');
    }
  };

  const fetchVendorDraft = async () => {
    try {
      const response = await getVendorDraftById(vendorDraftEditModeId);
      if (response?.status === 200 && response?.data?.result) {
        const responseData = response.data.result;
        const data = convertResponseToStateObject(responseData);
        setNewVendorPayloadCopy(data);
        setNewVendorPayload(data);
      } else {
        dispatch(setVendorDraftEditModeId(undefined));
        showErrorMessage('Unable to fetch vendor draft.');
      }
    } catch (e) {
      dispatch(setVendorDraftEditModeId(undefined));
      showErrorMessage('Unable to fetch vendor draft.');
    }
  };

  const getModalHeaderText = () => {
    if (currentStage === AddNewVendorStages.InviteOrPublish) return 'Add Vendor';
    else if (!newVendorPayload.Published && currentStage === AddNewVendorStages.PriorToInvitation)
      return '';
    else if (!newVendorPayload.Published && currentStage === AddNewVendorStages.EmailTemplate)
      return 'Email Template';
    else if (newVendorPayload.Published) return 'Publish Profile';
    else if (!newVendorPayload.Published) return 'Invite Vendor';
    else '';
  };

  const backFillCountyName = (area) => {
    let County = undefined;

    if (area.Type !== 'County') return {};

    if (area.County) return { County: area.County };

    try {
      let placeName = area.PlaceName.split(',');
      if (placeName.length) County = placeName[0].replace('County', '').trim();
      return { County };
    } catch (e) {
      return { County: '' };
    }
  };

  const convertResponseToStateObject = (obj): AddVendorPayloadType => {
    const getPOCName = () => {
      if (draftId || vendorDraftEditModeId)
        return obj.ContactUser?.FirstName
          ? `${obj.ContactUser?.FirstName} ${
              obj.ContactUser?.LastName ? ` ${obj.ContactUser?.LastName}` : ''
            }`
          : undefined;
      else if (vendorEditModeId) {
        if (IsStrictlyProfile)
          return obj.ContactPersonFirstName
            ? `${obj.ContactPersonFirstName} ${
                obj.ContactPersonLastName ? ` ${obj.ContactPersonLastName}` : ''
              }`
            : undefined;

        return obj.ThirdParty?.User?.FirstName
          ? `${obj.ThirdParty?.User?.FirstName} ${
              obj.ThirdParty?.User?.LastName ? ` ${obj.ThirdParty?.User?.LastName}` : ''
            }`
          : undefined;
      } else return undefined;
    };

    const data: AddVendorPayloadType = {
      BusinessName: obj.BusinessName,
      Published: vendorEditInviteMode
        ? false
        : obj.IsStrictlyProfile
        ? true
        : !!obj.IsPublished && !obj.IsInvited,
      Email: obj.Email,
      Category: obj.PartnerCategories?.length
        ? {
            value: obj.PartnerCategories[0].Id,
            name: obj.PartnerCategories[0].Title,
          }
        : undefined,
      Phone: obj.PhoneNumber || undefined,
      Website: obj.Website || undefined,
      Address: obj.Address
        ? {
            City: obj.Address.City || undefined,
            State: obj.Address.State || undefined,
            Zip: obj.Address.Zip || undefined,
            Line1: obj.Address.Line1 || undefined,
            Line2: obj.SuiteUnit || obj.Address.Line2 || undefined,
            ProviderPlaceId: obj.Address.ProviderPlaceId || undefined,
            PlaceName: obj.Address.PlaceName || undefined,
          }
        : undefined,
      ServiceAreas: obj.AreasOfOperation?.length
        ? obj.AreasOfOperation.map((area) => {
            return {
              uuid: uuid(),
              hasRadius: area.Radius && area.Radius !== '0' && !isNaN(Number(area.Radius)),
              radius:
                area.Radius && area.Radius !== '0' && !isNaN(Number(area.Radius))
                  ? Number(area.Radius)
                  : 0,
              location: {
                ...area,
                ...(area.Type === 'County' && !area.County && backFillCountyName(area)),
                Radius: area.Radius || '0',
              },
            };
          })
        : [],
      ServicesOffered: obj.PartnerTags?.length
        ? obj.PartnerTags.map((tag) => {
            return { name: tag.Name, value: tag.Id, uuid: uuid() };
          })
        : [],
      PointOfContactName: getPOCName(),
      PointOfContactEmail: obj.ContactPersonEmail || undefined,
      AgentNote: obj.AgentNote || undefined,
      Description: obj.Description || undefined,
      LogoFile: undefined,
      LogoUrl: obj.LogoUrl,
    };
    return data;
  };

  const getRequestPayloadCfg = (deleteDraft: boolean, isDraftPayload: boolean = false) => {
    const cfg = {
      BusinessName: newVendorPayload.BusinessName.trim(),
      Email: newVendorPayload.Email ? newVendorPayload.Email.trim() : undefined,
      PhoneNumber: newVendorPayload.Phone?.trim() || undefined,
      Address: newVendorPayload.Address?.ProviderPlaceId
        ? { ...newVendorPayload.Address }
        : undefined,
      SuiteUnit:
        newVendorPayload.Address?.ProviderPlaceId && newVendorPayload?.Address?.Line2
          ? newVendorPayload?.Address?.Line2.trim()
          : undefined,
      Description: newVendorPayload.Description?.trim() || undefined,
      Website: newVendorPayload.Website?.trim() || undefined,
      PartnerCategoryIds: newVendorPayload?.Category ? [newVendorPayload?.Category.value] : [],
      AreasOfOperation: newVendorPayload?.ServiceAreas?.filter((area) => area.location)
        ?.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((o) => o.location.ProviderPlaceId === obj.location.ProviderPlaceId),
        )
        ?.map((area) => {
          return {
            City: area.location.City,
            PlaceName: area.location.PlaceName,
            ProviderPlaceId: area.location.ProviderPlaceId,
            Radius: `${area.radius}`,
            State: area.location.State,
            Type: area.location.Type,
            Zipcode: area.location.Zipcode,
            ...(area.location.County && { County: area.location.County }),
          };
        }),
      LogoUrl: newVendorPayload.LogoUrl
        ? {
            Url: newVendorPayload.LogoUrl,
          }
        : newVendorPayload.LogoFile
        ? {
            ContentType: newVendorPayload.LogoFile.ContentType,
            Filename: newVendorPayload.LogoFile.Filename,
            Data: newVendorPayload.LogoFile.Data.replace(/^data:image\/\w+;base64,/, ''),
          }
        : undefined,
      ContactUser:
        newVendorPayload.PointOfContactEmail || newVendorPayload.PointOfContactName
          ? {
              Email: newVendorPayload.PointOfContactEmail?.trim(),
              FirstName: newVendorPayload.PointOfContactName
                ? newVendorPayload.PointOfContactName.split(' ')[0]
                : undefined,
              LastName:
                newVendorPayload.PointOfContactName &&
                newVendorPayload.PointOfContactName.split(' ')?.length > 1
                  ? newVendorPayload?.PointOfContactName?.split(' ')
                      ?.slice(1)
                      ?.map((name) => name.trim())
                      ?.join(' ')
                  : undefined,
            }
          : undefined,
      PartnerServices: newVendorPayload.ServicesOffered?.length
        ? [...newVendorPayload.ServicesOffered.map((ser) => ser.value || ser.name.trim())]
        : undefined,
      AgentNote: newVendorPayload.AgentNote?.trim() || undefined,
      IsPublished: newVendorPayload.Published,
      ...(isDraftPayload
        ? {}
        : {
            IsInvited: !newVendorPayload.Published,
            IsStrictlyProfile: newVendorPayload.Published,
          }),
      ...(deleteDraft ? { DraftId: draftId || vendorDraftEditModeId || undefined } : {}),
      ...(invitingExistingPartnerId
        ? { InvitingExistingPartnerId: invitingExistingPartnerId }
        : {}),
      ...(vendorEditModeId && vendorEditInviteMode ? { DeleteProfileId: vendorEditModeId } : {}),
    };
    return cfg;
  };

  const addNewVendor = async () => {
    setSaving(true);

    try {
      const validated = runValidations();
      if (!validated) {
        setSaving(false);
        return;
      }
      const cfg = getRequestPayloadCfg(true);
      const response = await postPartnerDatabyAgent(cfg);
      let deleteStatus = 200;
      if (draftId || vendorDraftEditModeId) {
        const deleteResponse = await deleteVendorDraftById(draftId || vendorDraftEditModeId);
        deleteStatus = deleteResponse.status;
      }
      if (response.status === 200 && deleteStatus === 200) {
        if (vendorDraftEditModeId) dispatch(vendorDraftToInviteAction(vendorDraftEditModeId));
        showSuccessMessage(
          newVendorPayload.Published ? "Vendor's profile published" : 'Vendor invited.',
        );
        if (
          selectedConciergeCategory?.category &&
          !isNaN(Number(selectedConciergeCategory.category)) &&
          response?.data?.result?.PartnerCategories?.length &&
          response?.data?.result?.PartnerCategories[0]?.Id ===
            Number(selectedConciergeCategory.category)
        ) {
          if (vendorEditInviteMode)
            dispatch(
              replaceInRecommendationListAction({
                targetId: vendorEditModeId,
                updatedPartner: response?.data?.result,
              }),
            );
          else
            dispatch(
              vendorCreateAction(
                cfg.IsPublished
                  ? response?.data?.result
                  : { ...response?.data?.result, InvitedDate: moment() },
              ),
            );
        }

        handleClose();
      }
    } catch (e: any) {
      showErrorMessage(
        e.response?.data?.message
          ? e.response?.data?.message
          : newVendorPayload.Published
          ? "Unable to publish vendor's profile."
          : 'Unable to invite vendor.',
      );
    }

    setSaving(false);
  };

  const updateVendorDraftInConciergeList = () => {
    const found = conciergeList.find((item) => item.Id === vendorDraftEditModeId);
    if (found) {
      const modifiedObj = {
        ...found,
        BusinessName: newVendorPayload.BusinessName,
        AreasOfOperation: newVendorPayload.ServiceAreas?.length
          ? newVendorPayload.ServiceAreas.map((area) => area.location)
          : [],
        PartnerTags: newVendorPayload.ServicesOffered?.length
          ? newVendorPayload.ServicesOffered.map((service) => ({
              Id: service.value,
              TagId: service.value,
              Name: service.name,
            }))
          : [],
        IsPublished: newVendorPayload.Published,
      };
      dispatch(vendorDraftUpdateAction(modifiedObj));
    }
  };

  const saveDraft = async () => {
    if (!runDraftValidations()) return;

    setDraftSaving(true);

    try {
      const cfg = getRequestPayloadCfg(false, true);
      let response;
      if (draftId && !vendorDraftEditModeId) response = await updateVendorDraftById(draftId, cfg);
      else if (!draftId && vendorDraftEditModeId)
        response = await updateVendorDraftById(vendorDraftEditModeId, cfg);
      else response = await postPartnerDraftByAgent(cfg);
      if (response.status === 200) {
        if (vendorDraftEditModeId) updateVendorDraftInConciergeList();
        if (
          !vendorDraftEditModeId &&
          !draftId &&
          response?.data?.result &&
          selectedConciergeCategory?.category &&
          !isNaN(Number(selectedConciergeCategory.category)) &&
          Number(selectedConciergeCategory.category) === ConciergeDefaultCategories.Draft
        ) {
          dispatch(
            setPartnerDraftsAsRecommendations({
              isNew: true,
              drafts: [response.data.result],
            }),
          );
        }
        showSuccessMessage(`Draft ${draftId ? 'updated' : 'saved'} successfully`);
        setDraftMode(false);
        handleClose();
      }
    } catch (e: any) {
      showErrorMessage(
        e.response?.data?.message || `Unable to ${draftId ? 'update' : 'save'} draft.`,
      );
    }

    setDraftSaving(false);
  };

  const updateVendorProfile = async () => {
    setSaving(true);

    try {
      const validated = runValidations();
      if (!validated) {
        setSaving(false);
        return;
      }
      const cfg = getRequestPayloadCfg(false);
      const response = await updatePartnerById(vendorEditModeId || invitingExistingPartnerId, {
        ...cfg,
      });
      if (response.status === 200) {
        try {
          if (vendorEditInviteMode)
            dispatch(
              replaceInRecommendationListAction({
                targetId: vendorEditModeId,
                updatedPartner: response?.data?.result,
              }),
            );
          else {
            const strictProfile = !IsStrictlyProfile
              ? false
              : IsStrictlyProfile && !cfg.IsPublished && cfg.IsInvited
              ? false
              : true;
            const profileResponse = await getPartnerById({
              id: vendorEditModeId || invitingExistingPartnerId,
              IsStrictlyProfile: invitingExistingPartnerId ? false : strictProfile,
            });
            if (profileResponse?.status === 200 && profileResponse?.data?.result) {
              const responseData = profileResponse.data.result;
              dispatch(vendorProfileUpdateAction(responseData));
              if (Number(selectedConciergeCategory) === ConciergeDefaultCategories.Draft)
                dispatch(
                  deletePartnerDraftsAsRecommendations({ id: vendorEditModeId, IsSpecial: true }),
                );
            }
          }
        } catch (e) {
          console.error(e);
        }
        handleClose();
        showSuccessMessage(
          vendorEditInviteMode || invitingExistingPartnerId
            ? 'Vendor invited.'
            : 'Vendor profile updated.',
        );
      } else showErrorMessage('Unable to update vendor profile.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Unable to update vendor profile.');
    }

    setSaving(false);
  };

  const getStage = (
    stage: AddNewVendorStages,
    direction: 'next' | 'prev',
  ): AddNewVendorStages | null => {
    const stages = Object.values(AddNewVendorStages);
    const currentIndex = stages.indexOf(stage);

    if (direction === 'next' && currentIndex < stages.length - 1) return stages[currentIndex + 1];
    else if (direction === 'prev' && currentIndex > 0) return stages[currentIndex - 1];
    else return null;
  };

  const validatePhone = (phone) => {
    try {
      phoneSchema.validateSync(phone);
      return true;
    } catch (error) {
      return false;
    }
  };

  const runValidations = (verbose: boolean = true): boolean => {
    try {
      let errors: VendorValidationErrors = {};
      const maxAllowedCharsError = 'Max allowed characters: 255';
      if (!newVendorPayload.BusinessName)
        errors = { ...errors, businessNameError: 'Enter business name' };
      if (newVendorPayload?.BusinessName?.length > 255)
        errors = { ...errors, businessNameError: maxAllowedCharsError };
      if (!newVendorPayload.Category) errors = { ...errors, categoryError: 'Select category' };
      if (!newVendorPayload.Published && !newVendorPayload.Email)
        errors = { ...errors, businessEmailError: 'Enter email' };
      if (newVendorPayload.Email && !validateEmail(newVendorPayload.Email.trim()))
        errors = { ...errors, businessEmailError: 'Enter valid email' };
      if (newVendorPayload?.Email?.length > 255)
        errors = { ...errors, businessEmailError: maxAllowedCharsError };
      if (newVendorPayload.Website && !isValidURL(newVendorPayload.Website.trim()))
        errors = { ...errors, websiteError: 'Enter valid URL' };
      if (newVendorPayload?.Website && newVendorPayload?.Website?.length > 255)
        errors = { ...errors, websiteError: maxAllowedCharsError };
      if (newVendorPayload.Phone && !validatePhone(newVendorPayload.Phone))
        errors = { ...errors, phoneError: 'Must be a valid phone number for region US' };
      if (
        verbose &&
        newVendorPayload.PointOfContactEmail &&
        !validateEmail(newVendorPayload.PointOfContactEmail.trim())
      )
        errors = { ...errors, POCEmailError: 'Enter valid email' };
      if (
        newVendorPayload.PointOfContactEmail &&
        newVendorPayload.PointOfContactEmail?.length > 255
      )
        errors = { ...errors, POCEmailError: maxAllowedCharsError };

      if (!Object.keys(errors).length) return true;

      if (verbose) setValidationErrors(errors);

      if (
        verbose &&
        (errors.businessEmailError ||
          errors.categoryError ||
          errors.businessEmailError ||
          errors.websiteError ||
          errors.phoneError)
      )
        setActiveSection(VendorDetailType.General);
      else if (errors.POCEmailError) {
        if (!vendorDetailTypes.includes(VendorDetailType.PointOfContact))
          setVendorDetailTypes((prev) => [...prev, VendorDetailType.PointOfContact]);
        setActiveSection(VendorDetailType.PointOfContact);
      }

      if (verbose) setCurrentStage(AddNewVendorStages.AddVendorDetails);

      return false;
    } catch (e) {
      return false;
    }
  };

  const runDraftValidations = (): boolean => {
    try {
      let errors: VendorValidationErrors = {};
      const maxAllowedCharsError = 'Max allowed characters: 255';
      if (newVendorPayload?.BusinessName?.length > 255)
        errors = { ...errors, businessNameError: maxAllowedCharsError };
      if (newVendorPayload.Email && !validateEmail(newVendorPayload.Email.trim()))
        errors = { ...errors, businessEmailError: 'Enter valid email' };
      if (newVendorPayload?.Email?.length > 255)
        errors = { ...errors, businessEmailError: maxAllowedCharsError };
      if (newVendorPayload.Website && !isValidURL(newVendorPayload.Website.trim()))
        errors = { ...errors, websiteError: 'Enter valid URL' };
      if (newVendorPayload?.Website && newVendorPayload?.Website?.length > 255)
        errors = { ...errors, websiteError: maxAllowedCharsError };
      if (newVendorPayload.Phone && !validatePhone(newVendorPayload.Phone))
        errors = { ...errors, phoneError: 'Must be a valid phone number for region US' };
      if (
        newVendorPayload.PointOfContactEmail &&
        !validateEmail(newVendorPayload.PointOfContactEmail.trim())
      )
        errors = { ...errors, POCEmailError: 'Enter valid email' };
      if (
        newVendorPayload.PointOfContactEmail &&
        newVendorPayload.PointOfContactEmail?.length > 255
      )
        errors = { ...errors, POCEmailError: maxAllowedCharsError };

      if (!Object.keys(errors).length) return true;

      setValidationErrors(errors);

      if (
        errors.businessEmailError ||
        errors.categoryError ||
        errors.businessEmailError ||
        errors.websiteError ||
        errors.phoneError
      )
        setActiveSection(VendorDetailType.General);
      else if (errors.POCEmailError) {
        if (!vendorDetailTypes.includes(VendorDetailType.PointOfContact))
          setVendorDetailTypes((prev) => [...prev, VendorDetailType.PointOfContact]);
        setActiveSection(VendorDetailType.PointOfContact);
      }

      setCurrentStage(AddNewVendorStages.AddVendorDetails);

      return false;
    } catch (e) {
      return false;
    }
  };

  const verifyAOPWhenPublishing = (): boolean => {
    if (
      newVendorPayload.Published &&
      (!newVendorPayload.ServiceAreas?.length ||
        !newVendorPayload.ServiceAreas.some((area) => area.location !== undefined))
    ) {
      setAopError(true);
      setNewVendorPayload((prev) => {
        return {
          ...prev,
          ServiceAreas: [{ uuid: uuid(), hasRadius: true, radius: 0, location: undefined }],
        };
      });
      if (!vendorDetailTypes.includes(VendorDetailType.ServiceAreas))
        setVendorDetailTypes((prev) => [...prev, VendorDetailType.ServiceAreas]);
      setActiveSection(VendorDetailType.ServiceAreas);
      setCurrentStage(AddNewVendorStages.AddVendorDetails);
      return false;
    }

    return true;
  };

  const continueHandler = async () => {
    if (
      !newVendorPayload.Published &&
      currentStage === AddNewVendorStages.AddVendorDetails &&
      newVendorPayload.Email &&
      validateEmail(newVendorPayload.Email.trim())
    ) {
      const validated = await verifyEnteredEmail();
      if (!validated) return;
    }

    if (
      (currentStage === AddNewVendorStages.PriorToInvitation && !newVendorPayload.Published) ||
      (currentStage === AddNewVendorStages.AddVendorDetails && newVendorPayload.Published)
    ) {
      if (!verifyAOPWhenPublishing()) return;

      if (
        (!vendorEditModeId && !invitingExistingPartnerId) ||
        (vendorEditModeId && vendorEditInviteMode && !invitingExistingPartnerId)
      )
        addNewVendor();
      else updateVendorProfile();
    } else {
      const nextStage = getStage(currentStage, 'next');
      if (nextStage) {
        setCurrentStage(nextStage);
      }
    }
  };

  const backHandler = () => {
    const previousStage = getStage(currentStage, 'prev');
    if (previousStage) {
      setCurrentStage(previousStage);
    }
  };

  const getButtonText = (): string => {
    if (currentStage === AddNewVendorStages.PriorToInvitation && !newVendorPayload.Published)
      return vendorEditModeId && !vendorEditInviteMode ? 'Continue' : 'Send Invitation';
    else if (currentStage === AddNewVendorStages.AddVendorDetails && newVendorPayload.Published)
      return vendorEditModeId ? 'Update Profile' : 'Publish Profile';
    else return 'Continue';
  };

  const hasPayloadAnyValue = (obj: AddVendorPayloadType): boolean => {
    return Object.values(obj).some((value) => {
      if (value === undefined || value === null || typeof value === 'boolean') {
        return false;
      }
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return Object.keys(value).length > 0;
      }
      if (typeof value === 'string') {
        return value.trim().length > 0;
      }
      return true;
    });
  };

  const handleModalClose = () => {
    if (saving || draftSaving) return;
    else if (
      !vendorEditModeId &&
      !draftMode &&
      !viewDrafts &&
      hasPayloadAnyValue(newVendorPayload) &&
      !isEqual(newVendorPayload, newVendorPayloadCopy)
    )
      setDraftMode(true);
    else {
      dispatch(setVendorEditInviteMode(false));
      handleClose();
      setDraftId(0);
    }
  };

  const draftEditClickHandler = (draft) => {
    setDraftId(draft.Id);
    const data = convertResponseToStateObject(draft);
    setNewVendorPayloadCopy(data);
    setNewVendorPayload(data);
    setViewDrafts(false);
  };

  const clearStateOnDraftDelete = (id) => {
    if (draftId === id) {
      setDraftId(0);
      setNewVendorPayload(addVendorPayloadInitVals);
    }
  };

  const disableContinueButton = (): boolean => {
    const inviteStageInvalid =
      currentStage === AddNewVendorStages.InviteOrPublish &&
      (!newVendorPayload.BusinessName || newVendorPayload.BusinessName.length > 255);
    let infoStageInvalid = false;
    if (currentStage === AddNewVendorStages.AddVendorDetails) {
      const result = runValidations(false);
      infoStageInvalid = !result;
    }
    return saving || inviteStageInvalid || infoStageInvalid;
  };

  const verifyEnteredEmail = async (): Promise<boolean> => {
    if (newVendorPayload.Published) return true;

    setVerifyingEmail(true);
    try {
      const response = await getUserByEmail(newVendorPayload.Email.trim());
      if (response.status === 200 && response?.data?.result) {
        const user = response?.data?.result;
        if (user?.Id === vendorEditModeId) {
          setVerifyingEmail(false);
          return true;
        }

        if (user?.ThirdParty && !user?.IsEmailVerified && !user?.CompletedProfile) {
          setInvitingExistingPartnerId(user.Id);
          setVerifyingEmail(false);
          return true;
        }

        setInvitingExistingPartnerId(0);
        setUserFound(response.data.result);
        setOpenFoundModal(true);
        setVerifyingEmail(false);
        return false;
      }
    } catch (e) {
      setVerifyingEmail(false);
      showErrorMessage('Unable to verify email.');
      return false;
    }
    setVerifyingEmail(false);
    return true;
  };

  const viewProfileHandler = (id) => {
    handleClose(id, true);
    setDraftId(0);
  };

  return (
    <Modal
      className={loading ? styles.loaderModal : styles.vendorModal}
      footer={null}
      width={618}
      open={open}
      onCancel={handleModalClose}
      destroyOnClose
      closable={!loading}
      {...rest}
      zIndex={1000}
    >
      {/*---------->DRAFT SAVE MODAL<----------*/}
      <UserFoundModal
        open={openFoundModal}
        userFound={userFound}
        viewProfileHandler={viewProfileHandler}
        cancelHandler={() => {
          setOpenFoundModal(false);
          setUserFound(undefined);
          setNewVendorPayload((prev) => ({ ...prev, Email: '' }));
        }}
      />
      <Modal
        open={draftMode && !viewDrafts && !loading}
        closable={true}
        footer={null}
        zIndex={2000}
        width={420}
        onCancel={() => setDraftMode(false)}
        className={styles.saveDraftModal}
      >
        <div className={styles.closeDrafts} onClick={() => setDraftMode(false)}>
          <CloseModalIconSmall />
        </div>
        <div className={styles.draft}>
          <div className={styles.draftIcon}>
            <DraftIcon />
          </div>
          <div className={styles.draftHeader}>Save for later?</div>
          <div className={styles.draftText}>
            You can save this invitation and continue later from your drafts.
          </div>
          <div className={styles.draftFooter}>
            <Button
              testid="cancelDraft"
              type="submit"
              className={classNames(styles.footerButton, styles.buttonDiscard)}
              title={'Discard'}
              loaderClassName={styles.loader}
              onClick={() => {
                setDraftMode(false);
                handleClose();
              }}
              disabled={draftSaving}
            />
            <Button
              testid="saveDraft"
              type="submit"
              className={styles.footerButton}
              title={draftId || vendorDraftEditModeId ? 'Update' : 'Save'}
              isPending={draftSaving}
              loaderClassName={styles.loader}
              onClick={saveDraft}
              disabled={draftSaving}
            />
          </div>
        </div>
      </Modal>
      {/*---------->INVITE MODAL<----------*/}
      {!loading ? (
        <div className={styles.contentWrapper}>
          {viewDrafts ? (
            <>
              <div className={styles.header}>
                <div
                  className={styles.back}
                  onClick={() => {
                    setViewDrafts(false);
                  }}
                >
                  <ArrowLeft width={10} height={20} className={styles.icon} alterViewPort={false} />
                </div>
                <div>Drafts</div>
                <div></div>
              </div>
              <div className={styles.innerWrapper}>
                <VendorDrafts
                  setViewDrafts={setViewDrafts}
                  draftEditClickHandler={draftEditClickHandler}
                  clearStateOnDraftDelete={clearStateOnDraftDelete}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.header}>
                <div
                  className={classNames(
                    styles.back,
                    currentStage === AddNewVendorStages.InviteOrPublish ? styles.hidden : '',
                  )}
                  onClick={backHandler}
                >
                  <ArrowLeft width={10} height={20} className={styles.icon} alterViewPort={false} />
                </div>
                <div>{getModalHeaderText()}</div>
                <div className={styles.close} onClick={handleModalClose}>
                  <CloseModalIcon />
                </div>
              </div>
              <div className={styles.innerWrapper}>
                {currentStage === AddNewVendorStages.InviteOrPublish ? (
                  <InviteOrPublish
                    newVendorPayload={newVendorPayload}
                    setNewVendorPayload={setNewVendorPayload}
                    errors={validationError}
                    setErrors={setValidationErrors}
                  />
                ) : null}
                {currentStage === AddNewVendorStages.AddVendorDetails ? (
                  <AddVendorDetails
                    newVendorPayload={newVendorPayload}
                    setNewVendorPayload={setNewVendorPayload}
                    vendorDetailTypes={vendorDetailTypes}
                    setVendorDetailTypes={setVendorDetailTypes}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                    partnerTags={partnerTags}
                    errors={validationError}
                    setErrors={setValidationErrors}
                    aopError={aopError}
                  />
                ) : null}
                {currentStage === AddNewVendorStages.PriorToInvitation ? (
                  <PriorToInvitation setCurrentStage={setCurrentStage} />
                ) : null}
                {currentStage === AddNewVendorStages.EmailTemplate ? <EmailTemplate /> : null}
              </div>
            </>
          )}
          {viewDrafts ? null : (
            <div
              className={classNames(
                styles.footer,
                vendorEditModeId || currentStage === AddNewVendorStages.InviteOrPublish
                  ? styles.footerCentered
                  : '',
              )}
            >
              {draftModeAllowed &&
              !vendorEditModeId &&
              !vendorDraftEditModeId &&
              currentStage === AddNewVendorStages.InviteOrPublish ? (
                <div
                  className={styles.drafts}
                  onClick={() => {
                    setViewDrafts(true);
                  }}
                >
                  Drafts
                </div>
              ) : null}
              {!vendorEditModeId && currentStage !== AddNewVendorStages.InviteOrPublish ? (
                <div
                  className={classNames(
                    styles.drafts,
                    saving || draftSaving || verifyingEmail || !hasPayloadAnyValue(newVendorPayload)
                      ? styles.draftsDisabled
                      : '',
                  )}
                  onClick={() => {
                    if (
                      saving ||
                      draftSaving ||
                      verifyingEmail ||
                      !hasPayloadAnyValue(newVendorPayload)
                    )
                      return;

                    saveDraft();
                  }}
                >
                  {draftId || vendorDraftEditModeId ? 'Update Draft' : 'Save as Draft'}
                </div>
              ) : null}
              {currentStage !== AddNewVendorStages.EmailTemplate ? (
                <Button
                  testid="inviteVendor"
                  type="submit"
                  className={styles.addVendorButton}
                  title={getButtonText()}
                  isPending={saving || verifyingEmail || (!draftMode && draftSaving)}
                  loaderClassName={styles.loader}
                  onClick={continueHandler}
                  disabled={disableContinueButton()}
                />
              ) : null}
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
