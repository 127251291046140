import { TableColumnsType } from 'antd';
import { PlansIcon } from '../components';

import styles from '../styles.module.scss';
import classNames from 'classnames';
import { Icons as Icons2 } from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components';

export interface LibraryDataType {
  key: number;
  name: {
    title: string;
    info: string;
  };
  numberOfPlans: number;
  numberOfClients: number;
  sent: number;
  opened: number;
  status: boolean;
}

export const libraryColumns: (onPreview: any) => TableColumnsType<LibraryDataType> = (
  onPreview,
) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: styles.tableCol,
    sorter: (a, b) => a.name.title.localeCompare(b.name.title),
    render: (group: any) => (
      <div className={styles.tableColNameWrapper}>
        <div className={styles.iconWrapper}>
          <PlansIcon />
        </div>
        <div className={styles.detailsWrapper}>
          <div className={styles.title}>{group.title}</div>
          <div className={styles.info}>{group.info}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    className: styles.tableCol,
    sorter: (a, b) => (a.status ? 1 : -1),
    render: (status: boolean) => (
      <div>
        {status && <div className={styles.statusPillGreen}>Active</div>}
        {!status && <div className={styles.statusPillGray}>Inactive</div>}
      </div>
    ),
  },
  {
    title: '# of Plans',
    dataIndex: 'numberOfPlans',
    key: 'numberOfPlans',
    className: styles.tableCol,
    sorter: (a, b) => a.numberOfPlans - b.numberOfPlans,
  },
  {
    title: '# of Clients',
    dataIndex: 'numberOfClients',
    key: 'numberOfClients',
    className: styles.tableCol,
    sorter: (a, b) => a.numberOfClients - b.numberOfClients,
  },
  {
    title: 'Sent',
    dataIndex: 'sent',
    key: 'sent',
    className: styles.tableCol,
    sorter: (a, b) => a.sent - b.sent,
  },
  {
    title: 'Opened',
    dataIndex: 'opened',
    key: 'opened',
    className: styles.tableCol,
    sorter: (a, b) => a.opened - b.opened,
  },
  {
    title: '',
    dataIndex: 'key',
    key: 'key',
    className: classNames(styles.tableCol, styles.alignRight),
    render: (id: number) => (
      <div className={styles.actionIcons}>
        <Icons2
          variant={'filePreview'}
          onClick={(e) => {
            e.stopPropagation();
            onPreview(id);
          }}
        />
      </div>
    ),
  },
];
