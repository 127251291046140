import { apiServer } from 'settings/web-services/api';

export function postRecommendPartnerByAgent(cfg) {
  return apiServer.post(`/partner/partners/${cfg?.partnerId}/recommendation`);
}

export function deleteUnrecommendPartnerByAgent(cfg) {
  return apiServer.delete(`/partner/partners/${cfg?.partnerId}/recommendation`);
}

export function getRecommendedPartnerByAgentList(cfg) {
  return apiServer.post(`/partner/agent/partners/recommended`, cfg);
}

export function sendInviteByPartnerId(id) {
  return apiServer.get(`partner/partners/invite/${id}`);
}

export function updatePartnerById(id, cfg) {
  return apiServer.put(`partner/partners/${id}`, cfg);
}

export function vendorActionsById(id, cfg) {
  return apiServer.put(`partner/${id}/vendor-actions`, cfg);
}

export function postPartnerDraftByAgent(cfg) {
  return apiServer.post('/partner/draft', cfg);
}

export function getAllVendorDrafts() {
  return apiServer.get('/partner/draft');
}

export function getVendorDraftById(id) {
  return apiServer.get(`/partner/draft/${id}`);
}

export function deleteVendorDraftById(id) {
  return apiServer.delete(`/partner/draft/${id}`);
}

export function updateVendorDraftById(id, cfg) {
  return apiServer.put(`/partner/draft/${id}`, cfg);
}

export function completePartnerProfile() {
  return apiServer.get(`/partner/complete-profile`);
}
