import { createSelector } from 'reselect';
import { flow, isObject } from 'lodash-es';
import { PENDING } from 'settings/constants/apiState';

import {
  getActiveClientsList,
  getRetainedClientsList,
  getInactiveClientsList,
  getPendingClientsList,
  getDeclinedClientsList,
  connectionsSearchFilter,
  connectionsGroupByName,
} from './clientsList';
import { noRanges } from 'settings/constants/common';
import {
  getFormattedTeamList,
  getActiveTeamList,
  getInactiveTeamList,
  getPendingTeamList,
  getDeclinedTeamList,
} from './teamList';
import {
  getActiveCollaboratorsList,
  getInactiveCollaboratorsList,
  getPendingCollaboratorsList,
  getDeclinedCollaboratorsList,
} from './collaboratorsList';
import { CLIENT } from 'settings/constants/roles';
import { NUMBER_OF_STORIES, prefsIds, PREF_TYPE } from 'settings/constants/preferences';
import { YesNoOptions } from 'types/inviteClient';

const localState = ({ app }) => app;

export const getIsAuthLoadingSelector = createSelector(localState, ({ auth }) => auth === null);

export const getIsAuthSelector = createSelector(localState, ({ auth }) => !!auth);

export const getLastPropertyHeightSelector = createSelector(
  localState,
  ({ lastPropertyHeight }) => lastPropertyHeight,
);

export const getModalSelector = createSelector(localState, ({ modal }) => modal);

export const getChatAdviceStatusSelector = createSelector(
  localState,
  ({ chatAdviceStatus }) => chatAdviceStatus,
);

export const getClientDrawer = createSelector(localState, ({ clientsDrawer }) => clientsDrawer);

export const getInvitedClientData = createSelector(
  localState,
  ({ invitedClientData }) => invitedClientData,
);

export const getNotificationMessagesMapSelector = createSelector(
  localState,
  ({ notificationMessages: { notificationMessagesMap } }) => notificationMessagesMap,
);
export const getNotificationPluralMessagesMapSelector = createSelector(
  localState,
  ({ notificationMessages: { notificationPluralMessagesMap } }) => notificationPluralMessagesMap,
);
export const isNotificationMessagesLoadingSelector = createSelector(
  localState,
  ({ notificationMessages }) => notificationMessages?.state === PENDING,
);

export const getClientDrawerSendingInvite = createSelector(
  getClientDrawer,
  ({ sendingInvite }) => sendingInvite,
);

export const getConnectionTypeSelector = createSelector(
  localState,
  ({ clientsDrawer }) => clientsDrawer.connectionType,
);

export const getIsClientDrawerSelector = createSelector(
  localState,
  ({ clientsDrawer }) => clientsDrawer.open,
);

export const getClientDrawerActiveTab = createSelector(
  getClientDrawer,
  ({ activeTab }) => activeTab,
);

export const getInvitedClientValues = createSelector(
  getClientDrawer,
  ({ inviteClientValues }) => inviteClientValues,
);

export const getInvitedTeamMemberValues = createSelector(
  getClientDrawer,
  ({ inviteTeamMemberValues }) => inviteTeamMemberValues,
);

export const getActiveClientTransactionInstance = createSelector(
  getClientDrawer,
  ({ activeClientTransactionInstance }) => activeClientTransactionInstance,
);

export const getActiveClientSearchInstance = createSelector(
  getClientDrawer,
  ({ activeClientSearchInstance }) => activeClientSearchInstance,
);

export const getActiveClientPreApproval = createSelector(
  getClientDrawer,
  ({ activeClientPreApproval }) => activeClientPreApproval,
);

export const isInviteClientModalOpened = createSelector(
  getClientDrawer,
  ({ showInviteClient }) => showInviteClient,
);
export const isInviteClientCustomLocationModalOpened = createSelector(
  getClientDrawer,
  ({ showInviteCustomLocation }) => showInviteCustomLocation,
);
export const isInviteTeamMemberModalOpened = createSelector(
  getClientDrawer,
  ({ showInviteTeamMember }) => showInviteTeamMember,
);
export const isAssignConnectionModalOpened = createSelector(
  getClientDrawer,
  ({ connectionAssign: { showAssignConnection, connectionAssignType, isNewlyInvited } }) => ({
    showAssignConnection,
    connectionAssignType,
    isNewlyInvited,
  }),
);
export const isCriteriaSectionInviteFlowOPened = createSelector(
  getClientDrawer,
  ({ showCriteriaSection }) => showCriteriaSection,
);
export const isProfileExpandedSectionOPened = createSelector(
  getClientDrawer,
  ({ showExpandedSection }) => showExpandedSection,
);
export const getInvitedClientRole = createSelector(
  getInvitedClientValues,
  ({ clientType }) => clientType,
);

export const getClientIdFromDrawerSelector = createSelector(
  localState,
  ({ clientsDrawer }) => clientsDrawer?.clientId,
);

export const getAgentIdFromDrawerSelector = createSelector(
  localState,
  ({ clientsDrawer }) => clientsDrawer?.agentId,
);

export const getConnectionsSearchValue = createSelector(
  getClientDrawer,
  ({ searchValue }) => searchValue,
);

export const getActiveSearchAndGroupedClientsList = createSelector(
  getActiveClientsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getRetainedSearchAndGroupedClientsList = createSelector(
  getRetainedClientsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getInactiveSearchAndGroupedClientsList = createSelector(
  getInactiveClientsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getPendingSearchAndGroupedClientsList = createSelector(
  getPendingClientsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getDeclinedSearchAndGroupedClientsList = createSelector(
  getDeclinedClientsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const isEmailVerificationPopupOpen = createSelector(
  localState,
  ({ showEmailVerificationPopup }) => showEmailVerificationPopup,
);

export const getSearchAndGroupedTeamList = createSelector(
  getFormattedTeamList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getActiveSearchAndGroupedTeamList = createSelector(
  getActiveTeamList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getInactiveSearchAndGroupedTeamList = createSelector(
  getInactiveTeamList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getPendingSearchAndGroupedTeamList = createSelector(
  getPendingTeamList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getDeclinedSearchAndGroupedTeamList = createSelector(
  getDeclinedTeamList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getActiveSearchAndGroupedCollaboratorsList = createSelector(
  getActiveCollaboratorsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getInactiveSearchAndGroupedCollaboratorsList = createSelector(
  getInactiveCollaboratorsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getPendingSearchAndGroupedCollaboratorsList = createSelector(
  getPendingCollaboratorsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

export const getDeclinedSearchAndGroupedCollaboratorsList = createSelector(
  getDeclinedCollaboratorsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName),
);

// Aggregated selector for clients
export const getAllClientsList = createSelector(
  getActiveClientsList,
  getRetainedClientsList,
  getInactiveClientsList,
  getPendingClientsList,
  (...clientLists) => {
    const combined = clientLists.flat();

    // Ensure uniqueness based on `id`
    const uniqueClients = Array.from(
      new Map(combined.map((client) => [client.id, client])).values(),
    );

    return uniqueClients;
  },
);

export const getAllSearchAndGroupedClientsList = createSelector(
  getAllClientsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName), // Apply search and grouping on combined list
);

// Aggregated selector for team members
export const getAllTeamList = createSelector(
  getFormattedTeamList,
  getActiveTeamList,
  getInactiveTeamList,
  getPendingTeamList,
  (...teamLists) => {
    const combined = teamLists.flat();

    // Ensure uniqueness by `id`
    const uniqueTeamMembers = Array.from(
      new Map(combined.map((teamMember) => [teamMember.id, teamMember])).values(),
    );

    return uniqueTeamMembers;
  },
);

export const getAllSearchAndGroupedTeamList = createSelector(
  getAllTeamList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName), // Apply search and grouping on combined list
);

// Aggregated selector for collaborators
export const getAllCollaboratorsList = createSelector(
  getActiveCollaboratorsList,
  getInactiveCollaboratorsList,
  getPendingCollaboratorsList,
  (...collaboratorLists) => {
    const combined = collaboratorLists.flat();

    // Ensure uniqueness by `id`
    const uniqueCollaborators = Array.from(
      new Map(combined.map((collaborator) => [collaborator.id, collaborator])).values(),
    );

    return uniqueCollaborators;
  },
);

export const getAllSearchAndGroupedCollaboratorsList = createSelector(
  getAllCollaboratorsList,
  getConnectionsSearchValue,
  flow(connectionsSearchFilter, connectionsGroupByName), // Apply search and grouping on combined list
);

export const getSearchPreferencesSendValues = (values) => {
  return {
    ...(values.locations?.length
      ? {
          Locations: values.locations,
        }
      : {}),
    ...(values.priceMin || values.priceMax
      ? {
          PriceRange: {
            ...(values.priceMin ? { Min: +values.priceMin } : {}),
            ...(values.priceMax ? { Max: +values.priceMax } : {}),
          },
        }
      : {}),
    ...(values.propertyType?.length
      ? {
          HomeType: values.propertyType,
        }
      : {}),
    ...(values.squareFeetMin || values.squareFeetMax
      ? {
          SquareFeetRange: {
            ...(values.squareFeetMin ? { Min: +values.squareFeetMin } : {}),
            ...(values.squareFeetMax ? { Max: +values.squareFeetMax } : {}),
          },
        }
      : {}),
    ...(values.bedsMin || values.bedsMax
      ? {
          NumBedroomsRange: {
            ...(values.bedsMin ? { Min: +values.bedsMin } : {}),
            ...(values.bedsMax ? { Max: +values.bedsMax } : {}),
          },
        }
      : {}),
    ...(values.bathsMin || values.bathsMax
      ? {
          NumBathroomsRange: {
            ...(values.bathsMin ? { Min: +values.bathsMin } : {}),
            ...(values.bathsMax ? { Max: +values.bathsMax } : {}),
          },
        }
      : {}),
    ...(values.lotSizeMin || values.lotSizeMax
      ? {
          LotSizeRange: {
            ...(values.lotSizeMin ? { Min: +values.lotSizeMin } : {}),
            ...(values.lotSizeMax ? { Max: +values.lotSizeMax } : {}),
          },
        }
      : {}),
    ...((values.yearBuiltMin !== noRanges.noMin && values.yearBuiltMin) ||
    (values.yearBuiltMax !== noRanges.noMax && values.yearBuiltMax)
      ? {
          YearBuiltRange: {
            ...(values.yearBuiltMin !== noRanges.noMin && values.yearBuiltMin
              ? { Min: +values.yearBuiltMin }
              : {}),
            ...(values.yearBuiltMax !== noRanges.noMax && values.yearBuiltMax
              ? { Max: +values.yearBuiltMax }
              : {}),
          },
        }
      : {}),
    ...(values.stories && values.stories !== NUMBER_OF_STORIES.NotImportant
      ? {
          Stories: {
            StoriesRange: {
              ...(values.stories === NUMBER_OF_STORIES.Multiple ? { Min: 1 } : { Max: 1 }),
            },
            ImportanceAndWeight: {
              Importance: 'Somewhat',
            },
          },
        }
      : {}),
    ...(values.hoaType
      ? {
          HOAType: values.hoaType,
        }
      : {}),
    ...(values.hoaMin || values.hoaMax
      ? {
          HOARange: {
            ...(values.hoaMin ? { Min: +values.hoaMin } : {}),
            ...(values.hoaMax ? { Max: +values.hoaMax } : {}),
          },
        }
      : {}),
    ...(values.softCriteria
      ? {
          HomePrefs: inviteClientSearchSoftCriteria(values.softCriteria, PREF_TYPE.homePrefs) || [],
        }
      : {}),
    ...(values.softCriteria
      ? {
          ViewPrefs: inviteClientSearchSoftCriteria(values.softCriteria, PREF_TYPE.ViewPrefs) || [],
        }
      : {}),

    ...(values.softCriteria
      ? {
          InteriorStylesPrefs:
            inviteClientSearchSoftCriteria(values.softCriteria, PREF_TYPE.interiorStylesPrefs) ||
            [],
        }
      : {}),

    ...(values.softCriteria
      ? {
          NeighborhoodPrefs:
            inviteClientSearchSoftCriteria(values.softCriteria, PREF_TYPE.neighborhoodPrefs) || [],
        }
      : {}),
    ...(values.softCriteria
      ? {
          AmenitiesPrefs:
            inviteClientSearchSoftCriteria(values.softCriteria, PREF_TYPE.amenitiesPrefs) || [],
        }
      : {}),
    ...(values.softCriteria
      ? {
          CommutePrefs: [
            ...(values.softCriteria?.[prefsIds.commutes] || []),
            ...(values.softCriteria?.[prefsIds.proximityToImportantLocations] || []),
          ],
        }
      : {}),
    ...(values.softCriteria &&
    Object.keys(inviteClientSearchSoftCriteria(values.softCriteria, prefsIds.parking)).length
      ? {
          Parking: inviteClientSearchSoftCriteria(values.softCriteria, prefsIds.parking) || {},
        }
      : {}),
    ...(values.softCriteria
      ? {
          OutdoorPrefs:
            inviteClientSearchSoftCriteria(values.softCriteria, prefsIds.outdoorPrefs) || [],
        }
      : {}),
    ...(values.softCriteria
      ? {
          Schools: values.softCriteria?.[prefsIds.school] || undefined,
        }
      : {}),
    ...(values.softCriteria
      ? {
          KeywordPrefs: values.softCriteria?.[PREF_TYPE.keywordPrefs] || undefined,
        }
      : {}),
  };
};

const getSchoolsSoftCriteria = (data) => {
  const schoolTypes = data?.NonSpecificConfig?.SchoolTypes;
  return {
    ...data,
    ...(schoolTypes?.length
      ? {
          NonSpecificConfig: {
            SchoolTypes: schoolTypes.map((item) => item.value),
          },
        }
      : {}),
  };
};

export const getAgentInviteClientSendValues = (store, values) => {
  const clientValues = getInvitedClientValues(store);
  const clientAddresses = getInvitedClientAddresses(store);
  const activeClientPreApproval = getActiveClientPreApproval(store);
  const activeClientSearchInstance = getActiveClientSearchInstance(store);
  const searchInstance = !!activeClientSearchInstance?.locations?.length;
  const hasAddress = !!values?.address;
  const searchPrefs = searchInstance && getSearchPreferencesSendValues(activeClientSearchInstance);
  if (isObject(searchPrefs) && !searchPrefs?.Status?.length) {
    searchPrefs.Status = ['Active', 'ComingSoon'];
  }

  const Values = {
    Role: CLIENT,
    FirstName: clientValues?.firstName,
    LastName: clientValues?.lastName,
    Email: clientValues?.email,
    Client: {
      ...(values?.FromRetentionMode
        ? {
            FromRetentionMode: values?.FromRetentionMode || false,
          }
        : {}),
      ...(clientAddresses
        ? {
            Address: clientAddresses,
          }
        : {}),
      ...(searchInstance
        ? {
            SearchInstance: {
              DefaultPropertySearchPreferences: searchPrefs,
            },
          }
        : {}),
      ...(hasAddress
        ? {
            SellerAddress: values.address,
          }
        : {}),
    },
    ...(activeClientPreApproval?.isPreApproved === YesNoOptions.Yes
      ? {
          PreApproval: {
            Amount: activeClientPreApproval?.Amount || undefined,
            ApprovalDate: activeClientPreApproval?.ApprovalDate || undefined,
            ExpirationDate: activeClientPreApproval?.ExpirationDate || undefined,
            ApprovedBy: activeClientPreApproval?.ApprovedBy || undefined,
            PreApprovalDocuments: activeClientPreApproval?.isManualDataInput
              ? []
              : activeClientPreApproval?.PreApprovalDocuments?.map((document) => ({
                  Filename: document?.filename,
                  ContentType: document?.contentType,
                  Size: document?.size,
                  Data: btoa(document?.data),
                })),
          },
        }
      : {}),
    NotificationPreferences: values.NotificationPreferences,
  };

  return {
    Values,
  };
};

export const getAgentEditSearchInstanceSendValuesById = (store, values) => {
  const search = getSearchPreferencesSendValues(values);

  return {
    Values: {
      Client: {
        SearchInstance: {
          Id: values.instanceId,
          DefaultPropertySearchPreferences: search,
        },
      },
    },
  };
};

const inviteClientSearchSoftCriteria = (softCriteria, prefType) => {
  const formattedData = [];
  const getImportanceAndWeight = (value) => ({
    ImportanceAndWeight: {
      Importance: value.value === 'Need' ? 'Must' : 'Somewhat',
    },
  });

  for (const [key, value] of Object.entries(softCriteria)) {
    const importance = value?.value || '';
    if (value.type === prefType) {
      if (
        prefType === PREF_TYPE.commutePrefs &&
        [prefsIds.commutes, prefsIds.proximityToImportantLocations].includes(key)
      ) {
        softCriteria[key]?.data?.forEach((item) => {
          const { ImportanceAndWeight, address, locationLabel, by, minutes } = item;
          if (ImportanceAndWeight && ImportanceAndWeight !== 'N/A') {
            formattedData.push({
              DestinationAddress: address[0],
              Name: locationLabel,
              TransportationModePreference: [by],
              MaxCommuteTimeInMinutes: minutes && Number(minutes),
              IsImportantLocation: key === prefsIds.proximityToImportantLocations,
              ...getImportanceAndWeight({
                value: ImportanceAndWeight,
              }),
            });
          }
        });
      } else if (key === prefsIds.flooring) {
        value.data?.forEach((item, index) => {
          if (importance !== 'N/A') {
            formattedData.push({
              Preference: prefsIds.flooring + item.value,
              ...getImportanceAndWeight(value),
            });
          }
        });
      } else if (prefType === prefsIds.outdoorPrefs && key === prefsIds.outdoorPrefs) {
        value.data?.forEach((item, index) => {
          if (importance !== 'N/A') {
            formattedData.push({
              Preference: item.value,
              ...getImportanceAndWeight(value),
            });
          }
        });
      } else if (prefType === prefsIds.parking && key === prefsIds.parking) {
        return value.data?.count
          ? {
              NumOfParkingSpaces: Number(value.data.count),
              GarageOnly: value.data.garageOnly,
              GarageType: value.data.garageType || undefined,
              ...getImportanceAndWeight(value),
            }
          : {};
      } else {
        // Add generic pref-type (i.e. homePrefs, neighborhoodPrefs, amenitiesPrefs) criteria's
        if (importance !== 'N/A') {
          formattedData.push({
            Preference: key,
            ...getImportanceAndWeight(value),
          });
        }
      }
    }
  }

  return formattedData;
};

export const getInvitedClientAddresses = createSelector(
  getClientDrawer,
  ({ inviteClientAddresses }) => inviteClientAddresses,
);
