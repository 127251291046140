import { NavLink } from 'react-router-dom';
import {
  TaskList,
  Timelines,
  ArrowLeftRight,
  Info,
  Clipboard,
  SplitView,
  Email,
} from 'components/Icons';
import styles from './styles.module.scss';
import { routes } from 'settings/navigation/routes';
import classNames from 'classnames';

export const LeftPanel = () => {
  return (
    <div className={styles.LeftPanel}>
      <h5>Templates</h5>
      <ul className={styles.LeftPanelLinks}>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesTransactions}
          >
            <ArrowLeftRight className={styles.linkIcon} />
            <span className={styles.linkTitle}>Transactions</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesDetails}
          >
            <Info className={styles.linkIcon} />
            <span className={styles.linkTitle}>Details</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesTasks}
          >
            <TaskList className={styles.linkIcon} />
            <span className={styles.linkTitle}>Tasks</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesTimelines}
          >
            <Timelines className={styles.linkIcon} />
            <span className={styles.linkTitle}>Timelines</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesProjects}
          >
            <Clipboard className={styles.linkIcon} />
            <span className={styles.linkTitle}>Projects</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesClause}
          >
            <SplitView className={styles.linkIcon} />
            <span className={styles.linkTitle}>Clauses</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            activeClassName={styles.linkActive}
            to={routes.templatesEmail}
          >
            <div className={classNames(styles.linkIcon, styles.emailTemplateIcon)}>
              <Email />
            </div>
            <span className={styles.linkTitle}>Email</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
