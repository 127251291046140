import React from 'react';

const Avatar = ({ color = '#515151', circleColor = '#F4F5F6', ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill={circleColor} />
    <path
      d="M15.125 8.875C15.125 10.6009 13.7259 12 12 12C10.2741 12 8.875 10.6009 8.875 8.875C8.875 7.14911 10.2741 5.75 12 5.75C13.7259 5.75 15.125 7.14911 15.125 8.875Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M7 17.625C7.31162 15.9556 8.7443 14.5 10.3332 14.5H13.6667C15.2556 14.5 16.6884 15.9556 17 17.625"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Avatar;
