import { useState, useRef, useEffect, Fragment, useMemo, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'components-antd';
import { PropertyCard } from '../../../ClientFeed/components/PropertyCard';
import Pagination from 'components/Pagination';
import { Avatar, Wrapper as PendingWrapper } from 'components';
import styles from './styles.module.scss';
import { EmptyState } from '../../../ClientFeed/components/EmptyState';
import AntSkeleton from 'antd/lib/skeleton';
import Sort from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Sort';
import Filter from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Filter';
import Multiple from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Multiple';
import {
  getAgentClientsSelector,
  getClientFavoritePropsSelector,
  getMultipleModSelector,
  getPropertyToScrollToSelector,
} from 'store/selectors/feed';
import {
  getFeedCriteriaSelectorV3,
  getFeedMetaSelectorV3,
  getFeedPageInfoSelector,
} from 'store/selectors/feedv3';
import { appOpenModalEffect, setMultipleModeEffect } from 'store/effects';
import classNames from 'classnames';
import { setFeedV3CurrentPageInfoAction } from 'store/actions/feedv3';
import FilterModal from 'pages/Properties/FilterModal';
import { PropertySkeleton } from '../../../ClientFeed/components/PropertySkeleton';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { getInitials } from 'utils';
import { hasMlsAccessSelector } from 'store/selectors/mlsAccess';
import { NoMlsAccessAgentFiller } from 'features/mlsAccess/propertyListFiller/NoMlsAccessAgentFiller';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { cloneDeep } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { SORT_DESC } from 'settings/constants/sort';
import HighlightModal from 'pages/Properties/ListingDetail/components/HomeActions/HighlightModal';
import { getMlsDisclaimer, getMlsImage } from 'helpers/listingDisclaimer';

const FE_PAGE_SIZE = 40;
const MAX_PAGES = 9;

export const PropertiesList = ({
  isPending,
  properties,
  isAgentType = false,
  viewType,
  agentClientMode = { value: false, user: null },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;
  const isFeed = pathname === '/properties/feed';
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const propertyListRef = useRef<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const multiple = useSelector(getMultipleModSelector);
  const currentPageInfo = useSelector(getFeedPageInfoSelector);
  const propertyToScrollTo = useSelector(getPropertyToScrollToSelector);
  const [renderingIndex, setRenderingIndex] = useState(
    currentPageInfo?.currentFEPageInfo?.renderingIndex || -1,
  );
  const { filter } = useSelector(getFeedCriteriaSelectorV3);
  const agentClients = useSelector(getAgentClientsSelector);
  const clientFavoritesProps = useSelector(getClientFavoritePropsSelector);
  const { selectedSearch, selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);

  const [forceRerenderPagination, setForceRerenderPagination] = useState(-1);
  const [view, setView] = useState(viewType);
  const hasMlsAccess = useSelector(hasMlsAccessSelector);

  const { isSimpleHeaderTitleVisible } = useSelector(getFeedMetaSelectorV3);
  const isFavorites =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites';
  const isUnreadComments =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Comments';
  const isHighlights =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Highlights';

  useEffect(() => {
    setView(viewType);
  }, [viewType]);

  useEffect(() => {
    // reset the pagination state for feed after setting component state
    if (currentPageInfo?.currentFEPageInfo?.fromListingDetailPage) {
      dispatch(
        setFeedV3CurrentPageInfoAction({
          currentFEPageInfo: {
            pageNumber: 1,
            renderingIndex: -1,
            fromListingDetailPage: false,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
    setTotalPages(Math.ceil(properties?.length / FE_PAGE_SIZE));
  }, [properties]);

  useEffect(() => {
    if (propertyToScrollTo.propertyId && properties) {
      for (let i = 1; i <= MAX_PAGES; i++) {
        const currentListings = properties.slice((i - 1) * FE_PAGE_SIZE, i * FE_PAGE_SIZE);
        const selectedPropertyIndex = currentListings.findIndex(
          (property) => property?.Id === propertyToScrollTo.propertyId,
        );
        if (selectedPropertyIndex !== -1) {
          setPageNumber(i);
          setRenderingIndex(selectedPropertyIndex);
          setForceRerenderPagination(Math.ceil(Math.random() * 10000));
        }
      }
    }
  }, [propertyToScrollTo.propertyId]);

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber + 1);
    propertyListRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onClickMultiple = () => {
    if (!multiple) {
      dispatch(setMultipleModeEffect(true));
    } else {
      dispatch(setMultipleModeEffect(false));
    }
  };

  const getCurrentListings = () =>
    properties?.slice((pageNumber - 1) * FE_PAGE_SIZE, pageNumber * FE_PAGE_SIZE);

  const haveDisclaimers: Record<
    string,
    { disclaimer: string | ReactNode; logoURL: string; logoWidth: string }
  > = useMemo(() => {
    const listings = getCurrentListings() || [];
    const foundMarkets = new Set();
    const disclaimers = {};

    for (const item of listings) {
      if (['fmls'].includes(item.Market) && !foundMarkets.has(item.Market)) {
        foundMarkets.add(item.Market);
        const { mlsLogoURL, mlsLogoWidth } = getMlsImage(item.Market);
        disclaimers[item.Market] = {
          disclaimer: getMlsDisclaimer(item.Market),
          logoURL: mlsLogoURL,
          logoWidth: mlsLogoWidth,
        };

        if (foundMarkets.size === ['fmls'].length) break;
      }
    }

    return disclaimers;
  }, [properties]);

  const shouldScrollToProperty = (index) => {
    const shouldScroll = renderingIndex === index;
    setRenderingIndex(-1);
    return shouldScroll;
  };

  if (!hasMlsAccess) return <NoMlsAccessAgentFiller />;

  const sortCases = () => {
    const agent = isAgentType || isAgent;
    const clientSearches = isFavorites || isUnreadComments || isHighlights;
    return [
      ...(agent && !clientSearches
        ? [{ label: 'Client Match', value: 'ClientsMatched', order: SORT_DESC }]
        : []),
      ...(Sort.defaultProps?.sortCases?.filter(
        ({ value }) => !(value === 'MatchScore' && agent && clientSearches),
      ) || []),
    ];
  };

  const FavoritesHeader = () => {
    const client: any = agentClientMode?.user;
    const clientName = `${client?.FirstName} ${client?.LastName}`;

    return (
      <div className={styles.favHeader}>
        <Avatar
          avatarClassName={styles.avatar}
          placeholder={getInitials(clientName || '')}
          src={client?.AvatarUrl}
        />
        <div className={styles.textInfo}>
          <span className={styles.name}>{clientName || ''}</span>
          <span className={styles.count}>
            {selectedClientSavedSearch?.Id && !isHighlights && !isFavorites && !isUnreadComments
              ? selectedSearch?.name + ' - '
              : ''}
            {properties?.length}&nbsp;
            {properties?.length > 1 || properties?.length === 0 ? 'Properties' : 'Property'}
          </span>
        </div>
      </div>
    );
  };

  const getFilterCount = () => {
    const countFilters = cloneDeep(cleanSearchQueryObj(filter || {}));
    delete countFilters?.NoHOA;
    delete countFilters?.SoldDateRange;
    return countFilters
      ? Object.values(cleanSearchQueryObj(countFilters))?.filter((item) => Boolean(item))?.length
      : 0;
  };

  return (
    <Fragment>
      <div className={styles.actionsContainer}>
        {isPending ? (
          <AntSkeleton.Button active size="small" shape="round" className={styles.skeleton} block />
        ) : (
          <>
            {isFavorites || agentClientMode?.value ? (
              <FavoritesHeader />
            ) : (
              <span className={styles.total}>
                {properties?.length}&nbsp;
                {properties?.length > 1 || properties?.length === 0 ? 'Properties' : 'Property'}
              </span>
            )}
          </>
        )}
        <div className={styles.actions}>
          <Sort
            sortCases={sortCases() || []}
            customleft={0}
            className={styles.noBorder}
            offsetLeft={-40}
          />
          <Multiple
            className={classNames({ [styles.active]: multiple, [styles.multiple]: true })}
            onClick={onClickMultiple}
            popoverContent={() => <span>Multi Select</span>}
          />
          <FilterModal />
          <HighlightModal />

          <Filter
            className={styles.noBorder}
            onClick={() => {
              dispatch(appOpenModalEffect({ id: FilterModal.id, open: true }));
            }}
            count={getFilterCount()}
          />
        </div>
      </div>
      {isPending ? (
        <PendingWrapper
          isPending={isPending}
          className={styles.pendingWrapper}
          customLoader={<PropertySkeleton />}
        />
      ) : (
        <div
          key={forceRerenderPagination}
          className={styles.clientProperties}
          ref={propertyListRef}
        >
          {!isPending && getCurrentListings()?.length > 0 && (
            <>
              <Row gutter={24}>
                {getCurrentListings()?.map((item, idx) => (
                  <Col key={idx} xs={24} sm={24} md={12} lg={view === 'List Only' ? 8 : 12}>
                    <PropertyCard
                      countOnlyUnread={isUnreadComments}
                      data={item}
                      getPageNumber={() => pageNumber}
                      shouldScrollIntoView={() => shouldScrollToProperty(idx)}
                      isAgentType={isAgentType}
                      index={idx + 1}
                      isFavProperty={isFavorites || isHighlights}
                      searchInstanceId={
                        isFeed && selectedSearch?.id ? selectedSearch.id : undefined
                      }
                    />
                  </Col>
                ))}
              </Row>
              <div className={styles.pagination}>
                {totalPages > 1 && (
                  <Pagination
                    key={totalPages}
                    limit={FE_PAGE_SIZE}
                    onPageChange={onPageChange}
                    items={properties}
                    pagesLimit={MAX_PAGES}
                    pageAutoHandle={false}
                    // this component makes pages from 0 index
                    defaultPageNumber={pageNumber - 1}
                  />
                )}
              </div>
            </>
          )}
          {!isPending && getCurrentListings()?.length === 0 && (
            <EmptyState isSimpleHeaderTitleVisible={isSimpleHeaderTitleVisible} />
          )}
          <div className={styles.mlsDisclaimer}>
            {Object.values(haveDisclaimers).map((entry, idx) => (
              <p key={idx} className={styles.content}>
                {entry.logoURL && (
                  <div>
                    <img width={entry.logoWidth} src={entry.logoURL}></img>
                  </div>
                )}
                {entry.disclaimer}
              </p>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};
