import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { requestGetCampaignsEffect } from 'store/effects/radarKits';
import { RadarHeader } from '../components/RadarHeader';
import { LibraryIcon, PlansIcon } from './components';
import { Library } from './Library';
import { Plans } from './Plans';

import styles from './styles.module.scss';

export const RadarKits: React.FC = () => {
  const dispatch = useDispatch();

  // Fetch Campaigns
  useEffect(() => {
    dispatch(requestGetCampaignsEffect({}));
  }, []);

  const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <>
      <div className={styles.pageWrapper}>
        <RadarHeader title="KITs" />
        <div className={styles.tabWrapper}>
          <div
            className={classNames(styles.tabItem, { [styles.active]: activeTab === 0 })}
            onClick={() => setActiveTab(0)}
          >
            <div className={classNames(styles.iconWrapper)}>
              <PlansIcon />
            </div>
            Sequences
          </div>
          <div
            className={classNames(styles.tabItem, { [styles.active]: activeTab === 1 })}
            onClick={() => setActiveTab(1)}
          >
            <div className={styles.iconWrapper}>
              <LibraryIcon />
            </div>
            Library
          </div>
        </div>
        <div className={styles.pageContent}>
          {activeTab === 0 && <Plans />}
          {activeTab === 1 && <Library />}
        </div>
      </div>
    </>
  );
};
