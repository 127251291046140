import React, { useMemo, useState } from 'react';
import { Table } from '../Table';
import styles from './styles.module.scss';
import { DropdownCheck, Wrapper } from 'components';
import moment from 'moment';
import { AddressValuationDetails } from 'api/intel/types';
import NumberFormat from 'react-number-format';
import { appOpenModalEffect } from 'store/effects';
import ValuesModal from './ValuesModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { Icons, IconVariant } from '../../../icons';
import { AreaChart } from 'components/AreaChart';
import classNames from 'classnames';
import { getAvmAdjustmentSelector } from 'store/selectors/radarPortfolio';
import { cloneDeep } from 'lodash-es';

type Props = {
  valuationData: AddressValuationDetails[];
  isPending?: boolean;
  params: any;
  rental?: boolean;
};

enum SWITCHER {
  GRAPH = 'graph',
  CHART = 'chart',
}

export const ValuationCard: React.FC<Props> = ({
  valuationData,
  isPending = false,
  params,
  rental = false,
}) => {
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();
  const [switcher, setSwitcher] = useState(SWITCHER.GRAPH);
  const [dataType, setDataType] = useState('attom');
  const [dateFrom, setDateFrom] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 6)),
  );
  const { avmAdjustments } = useSelector(getAvmAdjustmentSelector);
  const avmData = avmAdjustments?.find(
    ({ IsRental, AddressId }) => IsRental === rental && AddressId === params.addressId,
  );

  const handleDateChange = (val) => {
    const today = new Date();
    let fromDate;

    switch (val) {
      case 'YTD':
        fromDate = new Date(today.getFullYear(), 0, 1);
        break;
      case '6M':
        fromDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case '1Y':
        fromDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case '2Y':
        fromDate = new Date(today.setFullYear(today.getFullYear() - 2));
        break;
      case 'MAX':
        fromDate = new Date(1970, 0, 1); // Or use another earliest date as needed
        break;
      default:
        fromDate = null;
    }

    setDateFrom(fromDate);
  };

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const handleSwitcher = () => {
    setSwitcher((prev) => (prev === SWITCHER.CHART ? SWITCHER.GRAPH : SWITCHER.CHART));
  };

  const homeValueData = useMemo(() => {
    if (!valuationData) return null;
    const getValue = (val) => {
      return rental
        ? val?.rentalAdjustedValue || val?.rentalValue
        : val?.homeAdjustedValue || val?.homeValue;
    };
    const notes = rental ? 'rentalNotes' : 'homeValueNotes';

    const filteredValuationData = valuationData
      ?.filter((obj) => obj?.source === dataType && getValue(obj))
      ?.sort((a, b) => {
        const dateA = new Date(a.year, a.month - 1);
        const dateB = new Date(b.year, b.month - 1);

        return (dateB as any) - (dateA as any);
      });

    const clientValuationData: any = valuationData
      ?.filter((obj) => obj?.['rentalValue'] || obj?.['homeValue'])
      ?.sort((a, b) => {
        const dateA = new Date(a.year, a.month - 1);
        const dateB = new Date(b.year, b.month - 1);

        return (dateB as any) - (dateA as any);
      });

    const estimatedValue = getValue(filteredValuationData[0]) ?? 0;

    // Calculate percentage change
    const prevValue = getValue(filteredValuationData[1]) ?? 0;
    const percChange: number = prevValue
      ? Number(
          (((Number(estimatedValue) - Number(prevValue)) / Number(prevValue)) * 100).toFixed(1),
        )
      : 0;

    const formattedPercChange = percChange > 0 ? `+${percChange}%` : `${percChange}%`;

    const changeType = percChange > 0 ? 'increase' : 'decrease';

    const transformData = (item, index, arr, time = { month: null, year: null }) => {
      const nextItem = arr[index + 1]; // Get the next item instead of the previous one
      const currentValue = getValue(item) ? parseFloat(getValue(item)) : undefined;
      const nextValue = nextItem ? parseFloat(getValue(nextItem) ?? '') : null;

      let change: string = '';
      let changeType: string = '';

      if (nextValue && currentValue) {
        const diff = currentValue - nextValue;
        change = ((diff / nextValue) * 100).toFixed(2);

        if (diff > 0) {
          changeType = 'increase';
          change = `+${change}%`;
        } else if (diff < 0) {
          changeType = 'decrease';
          change = `${change}%`;
        }
      }

      return {
        month: time?.month || item?.month,
        year: time?.year || item?.year,
        notes: item?.[notes],
        value: currentValue,
        change,
        changeType,
      };
    };

    const groupByMonthYearAndSource = (data: any) => {
      const groupedData: any = [];
      data.forEach((obj: any) => {
        const { month, year, source } = obj;
        let existingEntry: any = groupedData.find(
          (item: any) => item?.month === month && item?.year === year,
        );
        if (!existingEntry) {
          existingEntry = { month, year, attom: null, manual: null };
          groupedData.push(existingEntry);
        }
        if (source === 'attom') {
          existingEntry.attom = obj;
        } else if (source === 'manual') {
          existingEntry.manual = obj;
        }
      });
      return groupedData;
    };

    return {
      estimatedValue,
      percChange: formattedPercChange,
      changeType,
      historyAttom: filteredValuationData.map(transformData),
      historyAttomClient: groupByMonthYearAndSource(clientValuationData).map(
        (item, index, arr) => ({
          attom: transformData(item.attom, index, arr, { month: item?.month, year: item?.year }),
          manual: transformData(item.manual, index, arr, { month: item?.month, year: item?.year }),
        }),
      ),
    };
  }, [valuationData, dataType, dateFrom, rental]);

  const getSeries = () => {
    if (isAgent && dataType !== 'all') {
      return [
        {
          name: dataType === 'attom' ? 'Attom' : 'Manual',
          data: cloneDeep(homeValueData?.historyAttom.map(({ value }) => value))?.reverse() ?? [],
          notes: cloneDeep(homeValueData?.historyAttom.map(({ notes }) => notes))?.reverse() ?? [],
        },
      ];
    } else {
      const agentData =
        homeValueData?.historyAttomClient?.map((val) => val?.manual?.value || null)?.reverse() ??
        [];
      const disableAgent = agentData?.every((elm) => elm === null);
      return [
        {
          name: 'AVM',
          data:
            cloneDeep(
              homeValueData?.historyAttomClient?.map((val) => val?.attom?.value || null),
            )?.reverse() ?? [],
          notes:
            cloneDeep(
              homeValueData?.historyAttomClient.map((val) => val?.attom?.notes),
            )?.reverse() ?? [],
        },
        ...(!disableAgent
          ? [
              {
                name: 'Agent',
                data: agentData,
                notes:
                  cloneDeep(
                    homeValueData?.historyAttomClient.map((val) => val?.attom?.notes),
                  )?.reverse() ?? [],
              },
            ]
          : []),
      ];
    }
  };

  const categories = cloneDeep(
    homeValueData?.[isAgent && dataType !== 'all' ? 'historyAttom' : 'historyAttomClient'],
  )
    ?.reverse()
    ?.map((obj) => {
      const { month, year } = isAgent && dataType !== 'all' ? obj : obj?.attom || {};
      return (
        moment()
          .month(month - 1)
          .format('MMM') +
        ' ' +
        year
      );
    });

  function getAverage(data) {
    const attomValue = data?.attom?.value;
    const manualValue = data?.manual?.value;
    if (attomValue && manualValue) return (attomValue + manualValue) / 2;
    else return attomValue || manualValue || 0;
  }

  const getAllowedMonthsByYear = () => {
    // Convert array into an object of unique months per year
    const allowedMonthsByYear = valuationData
      ?.filter(({ source }) => source === 'attom')
      ?.reduce((acc, { year, month }) => {
        if (!acc[year]) {
          acc[year] = new Set();
        }
        acc[year].add(month);
        return acc;
      }, {});
    // Convert sets to arrays
    Object.keys(allowedMonthsByYear).forEach((year) => {
      allowedMonthsByYear[year] = Array.from(allowedMonthsByYear[year]);
    });
    return allowedMonthsByYear;
  };

  return (
    <div className={styles.valuationCard}>
      {isAgent && (
        <ValuesModal
          rental={rental}
          params={params}
          key={`${rental ? '1' : '2'}`}
          avmData={avmData}
          allowedMonthsByYear={getAllowedMonthsByYear()}
        />
      )}
      <Wrapper isPending={isPending}>
        <div className={classNames(styles.valuationCardHeader, { [styles.isAgent]: isAgent })}>
          <div className={styles.headingWrapper}>
            <div className={styles.title}>Estimated {rental ? 'Rental' : ''} Value</div>
            <div className={styles.value}>
              <NumberFormat
                thousandSeparator
                displayType="text"
                value={
                  dataType === 'all'
                    ? getAverage(cloneDeep(homeValueData?.historyAttomClient)?.reverse()?.[0])
                    : homeValueData?.estimatedValue
                }
                prefix="$"
              />
            </div>
            {homeValueData?.percChange &&
            homeValueData?.percChange !== '0%' &&
            homeValueData?.percChange !== 'NaN%' ? (
              <div className={styles.percChange}>
                <span className={styles[homeValueData?.changeType ?? '']}>
                  {homeValueData?.percChange}
                </span>{' '}
                since last month
              </div>
            ) : null}
          </div>
          <div className={styles.optionsWrapper}>
            {isClient && (
              <div className={styles.iconWrapper} onClick={handleSwitcher}>
                <Icons
                  variant={switcher === SWITCHER.CHART ? IconVariant.TABLE : IconVariant.GRAPH}
                />
              </div>
            )}
            {isAgent && (
              <DropdownCheck
                className={styles.dropdown}
                defaultValue={'attom'}
                dropdownFields={[
                  { value: 'attom', label: 'ATTOM' },
                  { value: 'manual', label: 'Manual' },
                  { value: 'all', label: 'All' },
                ]}
                onChange={(val) => setDataType(val)}
                prefixLabel={'Source: '}
              />
            )}
            {!!homeValueData?.[
              isClient || dataType === 'all' ? 'historyAttomClient' : 'historyAttom'
            ]?.length && (
              <DropdownCheck
                className={styles.dropdown}
                defaultValue={'6M'}
                dropdownFields={[
                  { value: 'YTD', label: 'YTD' },
                  { value: '6M', label: '6M' },
                  { value: '1Y', label: '1Y' },
                  { value: '2Y', label: '2Y' },
                  { value: 'MAX', label: 'MAX' },
                ]}
                onChange={handleDateChange}
              />
            )}
            {isAgent && dataType !== 'attom' ? (
              <div
                className={styles.addButton}
                onClick={() => {
                  openModal(rental ? 'valuesModalRental' : 'valuesModalHome');
                }}
              >
                <Icons variant={avmData ? IconVariant.EDIT : IconVariant.ADD} />{' '}
                {avmData ? 'Edit' : 'Add'}
              </div>
            ) : null}
          </div>
        </div>
        {isAgent || switcher === SWITCHER.GRAPH ? (
          <div id="chart">
            <AreaChart
              curve="straight"
              height={160}
              options={{
                chart: {
                  type: 'area',
                  width: '100%',
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                ...(isAgent
                  ? {}
                  : {
                      fill: {
                        opacity: 0,
                        type: 'solid',
                      },
                      legend: {
                        show: true,
                        showForSingleSeries: true,
                        position: 'top',
                        horizontalAlign: 'right',
                      },
                    }),
                tooltip: {
                  enabled: true,
                  custom: ({ dataPointIndex, w }) => {
                    const category = w.globals.categoryLabels[dataPointIndex];
                    let tooltip = '';
                    const series = getSeries();

                    series.forEach((s, index) => {
                      const notes = s.notes[dataPointIndex];
                      const value = w.globals.initialSeries[index].data[dataPointIndex];
                      const name = s.name;
                      const color = w.globals.colors[index];

                      tooltip += `
                        ${
                          index === 0
                            ? `<p style="color: #262626;
                            margin-bottom: 6px;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: -0.18px;">
                            ${category}
                        </p>`
                            : ''
                        }
                          <p style="color: #262626;
                            margin-bottom: 4px;
                            font-family: Inter;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px;
                            letter-spacing: -0.18px;">
                            ${
                              value || value === 0
                                ? `$${value.toLocaleString('en-US', {
                                    maximumFractionDigits: 0,
                                  })}`
                                : '-'
                            }
                          </p>
                        
                            ${
                              notes
                                ? `<p style="color: #515151;
                                      margin: 0;
                                      font-family: Inter;
                                      font-size: 13px;
                                      font-style: normal;
                                      font-weight: 400;
                                      line-height: 16px;
                                      letter-spacing: -0.18px;">
                                          Note: ${notes}
                                  </p>`
                                : ''
                            }
                          
                          <div style="margin-top: 8px; ${
                            series.length > 1 ? 'margin-bottom: 14px;' : ''
                          } display: flex; align-items: center; gap: 8px">
                            <div style="padding-top: 3px; width: 10px; height: 10px; border: 2px solid ${color}; border-radius: 3px;"></div>
                            <p style="color: #676767;
                              margin: 0;
                              font-family: Inter;
                              font-size: 13px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 16px;
                              letter-spacing: -0.18px;">
                              ${name}
                            </p>
                     </div>     
                          `;
                    });

                    return `<div style="background: #fff; padding: 16px; border-radius: 16px; min-width: 140px">
                              ${tooltip}
                            </div>`;
                  },
                },
                grid: {
                  show: true,
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                  strokeDashArray: 4,
                },
                xaxis: {
                  categories,
                  labels: {
                    show: true,
                    style: {
                      colors: '#000',
                      fontSize: '12px',
                    },
                  },
                  axisBorder: {
                    show: true,
                    color: '#000',
                  },
                  axisTicks: {
                    show: true,
                    color: '#000',
                  },
                },
              }}
              colors={dataType === 'manual' ? ['#51BFE1'] : ['#928CDA', '#51BFE1']}
              chartSeries={getSeries()}
            />
          </div>
        ) : null}
        {isAgent || switcher === SWITCHER.CHART ? (
          <Table
            historyAttom={
              isClient || dataType === 'all'
                ? cloneDeep(homeValueData?.historyAttomClient)?.reverse() ?? []
                : cloneDeep(homeValueData?.historyAttom)?.reverse() ?? []
            }
            isClient={isClient || dataType === 'all'}
          />
        ) : null}
      </Wrapper>
    </div>
  );
};
