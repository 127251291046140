import { TableColumnsType } from 'antd';
import { Popover } from 'components-antd';
import ActionsIcon from 'pages/Properties/Search/components/Icons/ActionsIcon';
import SmallDeleteIcon from 'pages/Properties/Search/components/Icons/SmallDeleteIcon';
import { Table } from 'pages/Radar/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { requestGetCampaignsEffect, requestUpdateCampaignEffect } from 'store/effects/radarKits';
import { Campaign } from 'store/reducers/radarKits/types';
import { getCampaignsSelector, getFilteredCampaigns } from 'store/selectors/radarKits';
import { PlansIcon } from '../components';
import styles from '../styles.module.scss';
import { ClietPlanDataType } from './helpers';

interface IPlans {
  clientId?: string;
  tableHeading?: string;
}

export const ClientPlans: React.FC<IPlans> = ({ clientId, tableHeading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);

  const { data } = useSelector(getCampaignsSelector);

  useEffect(() => {
    if (clientId) {
      const { addedKits } = getFilteredCampaigns(data, clientId);
      setCampaignsList(addedKits);
    }
  }, [data]);

  const handleRemove = (campaignId: number, clientId: number, addressId: number) => {
    const updatedCampaignMembers = campaignsList
      .find((campaign) => campaign.Id === campaignId)
      ?.CampaignMembers.map((cm) => ({
        ClientId: cm.ClientId,
        Addresses: cm.Addresses.filter((a) => a.AddressId !== addressId),
      }))
      .filter((cm) => cm.Addresses.length > 0);

    const payload = {
      Id: campaignId,
      CampaignMembers: updatedCampaignMembers?.flatMap((cm) =>
        cm.Addresses.map((addr) => ({
          ClientId: cm.ClientId,
          AddressId: addr.AddressId,
        })),
      ),
    };

    dispatch(
      requestUpdateCampaignEffect(payload, {}, (err, res) => {
        if (!err && res.data.result.Id) {
          setCampaignsList((prev) =>
            prev.map((campaign) =>
              campaign.Id === campaignId
                ? {
                    ...campaign,
                    CampaignMembers: campaign.CampaignMembers.map((cm) =>
                      cm.ClientId === clientId
                        ? {
                            ...cm,
                            Addresses: cm.Addresses.filter((a) => a.AddressId !== addressId),
                          }
                        : cm,
                    ),
                  }
                : campaign,
            ),
          );
          dispatch(requestGetCampaignsEffect({}, { silent: true }));
        }
      }),
    );
  };
  const campaignListDataSource = useMemo(() => {
    if (!campaignsList || !clientId) return { active: [], inactive: [] };

    const list = campaignsList.flatMap((campaign) =>
      campaign.CampaignMembers.filter((member) => member.ClientId === Number(clientId)).flatMap(
        (member) =>
          member.Addresses.map((address) => ({
            key: `${campaign.Id}-${member.ClientId}-${address.AddressId}`,
            id: campaign.Id,
            name: {
              title: campaign.Name,
              info: campaign.Description,
            },
            property: {
              addressLine: address?.ClientAddress?.address?.delivery_line_1,
              cityStateZip: address?.ClientAddress?.address?.last_line,
            },
            isActive: campaign.Active,
            onActiveChange: (isActive) =>
              dispatch(
                requestUpdateCampaignEffect(
                  {
                    Id: campaign.Id,
                    Active: isActive,
                  },
                  {},
                  (err, res) => {
                    if (!err && res.data.result.Id) {
                      dispatch(requestGetCampaignsEffect({}, { silent: true }));
                    }
                  },
                ),
              ),
            onRemove: () => handleRemove(campaign.Id, member.ClientId, address.AddressId),
          })),
      ),
    );

    return {
      active: list.filter((item) => item.isActive),
      inactive: list.filter((item) => !item.isActive),
    };
  }, [campaignsList, clientId]);

  const columns: TableColumnsType<ClietPlanDataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: styles.tableCol,
      width: 600,
      sorter: (a, b) => a.name.title.localeCompare(b.name.title),
      render: (group: any, record: ClietPlanDataType) => (
        <div className={styles.tableColNameWrapper} onClick={group?.onClick}>
          <div className={styles.iconWrapper}>
            <PlansIcon />
          </div>
          <div className={styles.detailsWrapper}>
            <div className={styles.title}>{group.title}</div>
            <div className={styles.info}>{group.info}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      className: styles.tableCol,
      sorter: (a, b) => {
        return a?.property?.addressLine?.localeCompare?.(b?.property?.addressLine);
      },
      render: (group: any, record: ClietPlanDataType) => (
        <div className={styles.tableColNameWrapper}>
          <div className={styles.detailsWrapper}>
            <div className={styles.title}>{record.property.addressLine}</div>
            {record.property.cityStateZip && (
              <div className={styles.info}>{record.property.cityStateZip}</div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'remove',
      key: 'remove',
      className: styles.tableCol,
      render: (isActive: boolean, record: ClietPlanDataType) => {
        return (
          <div className={styles.actionBtn}>
            <Popover
              overlayClassName={styles.popoverOverlay}
              placement="bottomRight"
              trigger="hover"
              content={
                <div className={styles.actionList}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      record.onRemove();
                    }}
                    className={styles.actionListBtn}
                  >
                    <SmallDeleteIcon className={styles.icon} />
                    <span>Remove</span>
                  </div>
                </div>
              }
            >
              <div>
                <ActionsIcon className={styles.actionBtnIcon} color={'#252d44'} />
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handleRowClick = ({ id }) => {
    history.push(link.toRadarKitSequence(id));
  };

  return (
    <div className={styles.tableContainer}>
      <div>
        <Table
          title={tableHeading || 'Active'}
          count={campaignListDataSource.active.length}
          data={campaignListDataSource.active}
          handleRowClick={handleRowClick}
          columns={columns}
          emptyText="This client isn't enrolled in any KIT sequences (yet)."
        />
      </div>
      <div>
        {campaignListDataSource?.inactive?.length > 0 ? (
          <Table
            title="Inactive"
            count={campaignListDataSource.inactive.length}
            data={campaignListDataSource.inactive}
            columns={columns}
            defaultCollapse={true}
            handleRowClick={handleRowClick}
          />
        ) : null}
      </div>
    </div>
  );
};
