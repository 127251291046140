const Hourglass = ({ color = '#AAABAB' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.4 7.9999C6.4 6.3999 4 6.3999 4 2.3999H12C12 6.3999 9.6 6.3999 9.6 7.9999C9.6 9.5999 12 9.5999 12 13.5999H4C4 9.5999 6.4 9.5999 6.4 7.9999Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.19922 2.3999H12.7992"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.19922 13.6001H12.7992"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00039 9.6001C7.60039 9.6001 6.40039 10.8001 6.40039 12.0001H9.60039C9.60039 10.8001 8.40039 9.6001 8.00039 9.6001Z"
        fill={color}
      />
      <path
        d="M7.99922 6.3999C7.79922 6.3999 7.19922 5.9999 7.19922 5.5999H8.79922C8.79922 5.9999 8.19922 6.3999 7.99922 6.3999Z"
        fill={color}
      />
    </svg>
  );
};

export default Hourglass;
