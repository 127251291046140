import { createSelector } from 'reselect';
import { PENDING, READY } from 'settings/constants/apiState';
import { getCurrentContextSelector } from 'store/selectors/context';

const localState = ({ clientInstances }) => clientInstances;

export const getClientInstancesSelector = createSelector(localState, ({ data, state }) => ({
  searchInstances: data?.searchInstances || [],
  transactionInstances: data?.transactionInstances || [],
  isLoading: state === PENDING && !data,
  loaded: state === READY,
}));

export const getClientSearchInstancesSelector = createSelector(
  getClientInstancesSelector,
  ({ searchInstances }) => searchInstances,
);

export const getClientActiveSearchInstancesByContext = createSelector(
  getClientInstancesSelector,
  getCurrentContextSelector,
  ({ searchInstances }, context) => {
    const activeInstances = (searchInstances || [])?.filter(
      (instance) => instance.Status === 'Active',
    );
    const agentIds = (context?.Agents || [])?.map((agent) => agent.Id);
    return activeInstances.length && agentIds.length
      ? activeInstances.filter((instance) => agentIds.includes(instance.AgentId))
      : [];
  },
);
