import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { deleteTaskTemplateEffect } from 'store/effects/template';
import { getAllTaskTemplatesEffect } from 'store/effects/templates';
import {
  getFilteredGroupedTaskTemplatesSelector,
  getTaskTemplatesSelector,
} from 'store/selectors/templates';
import { Wrapper, ConfirmationDialog } from 'components';
import { Collapse, Panel } from 'components-antd';
import { TemplateCard } from '../components/TemplateCard';
import { NoTemplatesFound } from '../components/NoTemplates';
import { TemplateHeader } from '../components/TemplateHeader';
import { TaskModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModal';
import { setTaskTemplatesQueryAction } from 'store/actions/templates';

import { getUserId, getUserRoleSelector } from 'store/selectors/user';
import { Role } from 'app-constants';
import { ArrowDown, ArrowUp } from 'components/Icons';

import styles from './styles.module.scss';

export const Tasks = (props) => {
  const { className } = props;
  const dispatch = useDispatch();

  const templates = useSelector(getFilteredGroupedTaskTemplatesSelector);
  const [templateToDelete, setTemplateToDelete] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNewModal, setIsNewModal] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [template, setTemplate] = useState(null);
  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);
  const [showAgentTemplates, setShowAgentTemplates] = useState<boolean>(false);
  const [activeCollapseKey, setActiveCollapseKey] = useState<string | string[]>('');

  const { isIdle: isTaskTemplatesIdle, isPending: isTaskTemplatesPending } =
    useSelector(getTaskTemplatesSelector);

  useEffect(() => {
    return (): any => dispatch(setTaskTemplatesQueryAction(''));
  }, []);

  const onTemplateView = (id, obj) => onTemplateEdit(id, obj, true);

  const onTemplateEdit = (id, obj, viewMode = false) => {
    setShowModal(true);
    setIsNewModal(false);
    setIsViewMode(viewMode);
    setTemplateId(id);
    setTemplate({ ...obj });
  };

  const onTemplateDelete = (template) => {
    setTemplateToDelete(template);
  };

  const onConfirmDeleteItem = () => {
    if (!templateToDelete) return;

    setIsDeleting(true);
    dispatch(
      deleteTaskTemplateEffect({ id: templateToDelete.Id }, {}, (err) => {
        if (!err) {
          setTemplateToDelete(null);
        }
        setIsDeleting(false);
      }),
    );
  };

  const onCloseDeleteItemModal = () => setTemplateToDelete(null);

  useEffect(() => {
    if (isTaskTemplatesIdle) {
      dispatch(
        getAllTaskTemplatesEffect({
          ...(showAgentTemplates && { agentTemplates: true }),
        }),
      );
    } else {
      dispatch(
        getAllTaskTemplatesEffect(
          { ...(showAgentTemplates && { agentTemplates: true }) },
          { silent: true },
        ),
      );
    }
  }, [showAgentTemplates]); // eslint-disable-line

  const deleteTemplateContent = useMemo(
    () =>
      templateToDelete ? (
        <div testid="modal_title">
          Are you sure that you want to <b>delete</b> the <b>{templateToDelete.Name}</b> task
          template?
        </div>
      ) : null,
    [templateToDelete],
  );

  const onCloseModal = () => {
    setShowModal(false);
    setIsNewModal(false);
    setTemplate(null);
    setTemplateToDelete(null);
    setIsViewMode(false);
  };

  const onToggleAgentTemplateView = () => setShowAgentTemplates((prev) => !prev);
  const filteredTemplates = useMemo(() => {
    if (showAgentTemplates && userRole === Role.SuperUser) {
      return filterObjectValues(templates, (template) => template.CreatorId !== userId);
    } else if (!showAgentTemplates && userRole === Role.SuperUser) {
      return filterObjectValues(templates, (template) => template.CreatorId === userId);
    }
    return templates;
  }, [templates, showAgentTemplates]);

  const isObjectEmptyOrHasEmptyArrays = (obj) => {
    if (Object.keys(obj).length === 0) return true; // Check if object is empty

    return Object.values(obj).every((value) => Array.isArray(value) && value.length === 0);
  };

  return (
    <Wrapper isPending={isTaskTemplatesPending}>
      <TemplateHeader
        label="Task Templates"
        onNew={() => {
          setShowModal(true);
          setIsNewModal(true);
        }}
        isTask={true}
        onToggleAgentTemplateView={onToggleAgentTemplateView}
        showAgentTemplates={showAgentTemplates}
      />
      <div className={classNames(styles.wrapper, className)}>
        {!isObjectEmptyOrHasEmptyArrays(filteredTemplates) ? (
          Object.keys(filteredTemplates).map((groupName, index) =>
            filteredTemplates?.[groupName]?.length ? (
              <Collapse
                key={index}
                bordered={false}
                expandIcon={({ isActive }) => (isActive ? <ArrowUp /> : <ArrowDown />)}
                expandIconPosition={'end'}
                className={styles.taskTemplatesCollapse}
                ghost={true}
                accordion
                activeKey={activeCollapseKey}
                onChange={(active: string | string[]) => setActiveCollapseKey(active)}
              >
                <Panel
                  header={
                    <>
                      {groupName}&nbsp;
                      <span className={styles.counterText}>
                        ({filteredTemplates?.[groupName]?.length})
                      </span>
                    </>
                  }
                  key={groupName}
                  className={styles.panelHeader}
                >
                  {filteredTemplates?.[groupName].map((template) => (
                    <TemplateCard
                      key={template?.Id}
                      onDelete={onTemplateDelete}
                      template={template}
                      onEdit={onTemplateEdit}
                      onView={onTemplateView}
                      isTaskTemplate
                    />
                  ))}
                </Panel>
                {index < Object.keys(filteredTemplates)?.length - 1 && (
                  <div className={styles.divider} />
                )}
              </Collapse>
            ) : null,
          )
        ) : (
          <NoTemplatesFound className={styles.noTemplates} templatesName="task" />
        )}
        <ConfirmationDialog
          onReject={onCloseDeleteItemModal}
          onConfirm={onConfirmDeleteItem}
          isOpen={!!templateToDelete}
          isPending={isDeleting}
        >
          {deleteTemplateContent}
        </ConfirmationDialog>
      </div>
      <TaskModal
        isOpen={showModal}
        isNew={isNewModal}
        isTemplate={true}
        templateId={templateId}
        editData={template}
        onCloseModal={onCloseModal}
        isViewMode={isViewMode}
      />
    </Wrapper>
  );
};

Tasks.propTypes = {
  className: PropTypes.string,
};

Tasks.defaultProps = {
  className: '',
};

function filterObjectValues(obj, condition) {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key].filter(condition);
    }
  }

  return result;
}
