import PropTypes from 'prop-types';
import React from 'react';

export const Icons = ({ testid, className, variant, onClick, color }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.ADD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 2.5V13.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 8H13.5"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ARCHIVE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.65811 3.69216L2.05132 8.51255C2.01733 8.61452 2 8.7213 2 8.82878V11.8332C2 12.6616 2.67157 13.3332 3.5 13.3332H12.5C13.3284 13.3332 14 12.6616 14 11.8332V8.82878C14 8.7213 13.9827 8.61452 13.9487 8.51255L12.3419 3.69216C12.1377 3.07965 11.5645 2.6665 10.9189 2.6665H5.08114C4.43549 2.6665 3.86228 3.07965 3.65811 3.69216Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 6.6665H5.66536V6.99984C5.66536 8.2885 6.71003 9.33317 7.9987 9.33317V9.33317C9.28736 9.33317 10.332 8.2885 10.332 6.99984V6.6665H12.6654"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PROFILE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
              stroke="#FF576D"
              strokeWidth="2"
            />
            <path
              d="M3 13.9985C3.5 12 5 11 7 11L9 11C11 11 12.5 12 13 13.9985"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ATTACHMENT: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_70781_59459)">
              <path
                d="M10.1788 4.36388L5.45149 9.09115C5.16216 9.38048 4.99962 9.77289 4.99962 10.1821C4.99962 10.5912 5.16216 10.9836 5.45149 11.273C5.74082 11.5623 6.13323 11.7248 6.5424 11.7248C6.95157 11.7248 7.34398 11.5623 7.63331 11.273L12.3606 6.5457C12.9392 5.96704 13.2643 5.18222 13.2643 4.36388C13.2643 3.54554 12.9392 2.76071 12.3606 2.18206C11.7819 1.6034 10.9971 1.27832 10.1788 1.27832C9.36042 1.27832 8.5756 1.6034 7.99694 2.18206L3.26967 6.90933C2.40169 7.77731 1.91406 8.95455 1.91406 10.1821C1.91406 11.4096 2.40169 12.5868 3.26967 13.4548C4.13765 14.3228 5.31489 14.8104 6.5424 14.8104C7.76991 14.8104 8.94714 14.3228 9.81512 13.4548L14.5424 8.72751"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_70781_59459">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ACTIVITY: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 5.3335V8.00016L9.33333 9.3335"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.03516 7.33338C2.18455 5.86675 2.86867 4.50645 3.95698 3.51202C5.04529 2.51759 6.46162 1.95864 7.93574 1.9418C9.40986 1.92496 10.8386 2.45142 11.9493 3.42074C13.0601 4.39005 13.7751 5.73436 13.9579 7.1972C14.1408 8.66003 13.7787 10.139 12.9407 11.3519C12.1028 12.5648 10.8476 13.4267 9.41468 13.7733C7.98177 14.1198 6.47143 13.9267 5.17182 13.2308C3.87221 12.5348 2.8743 11.3848 2.36849 10M2.03516 13.3334V10H5.36849"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.COMMENT: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M2.45355 9.70571L3.37363 10.0975L3.57149 9.63276L3.31492 9.19772L2.45355 9.70571ZM1.514 11.9124L2.43408 12.3041H2.43408L1.514 11.9124ZM2.154 12.5747L2.51392 13.5077H2.51392L2.154 12.5747ZM4.48331 11.6761L4.96541 10.8L4.55763 10.5756L4.12339 10.7432L4.48331 11.6761ZM11.3841 7.00033C11.3841 9.39356 9.444 11.3337 7.05077 11.3337V13.3337C10.5486 13.3337 13.3841 10.4981 13.3841 7.00033H11.3841ZM7.05077 2.66699C9.444 2.66699 11.3841 4.60709 11.3841 7.00033H13.3841C13.3841 3.50252 10.5486 0.666992 7.05077 0.666992V2.66699ZM2.71744 7.00033C2.71744 4.60709 4.65753 2.66699 7.05077 2.66699V0.666992C3.55297 0.666992 0.717435 3.50252 0.717435 7.00033H2.71744ZM3.31492 9.19772C2.93543 8.55425 2.71744 7.80427 2.71744 7.00033H0.717435C0.717435 8.17129 1.03615 9.27087 1.59219 10.2137L3.31492 9.19772ZM2.43408 12.3041L3.37363 10.0975L1.53348 9.31396L0.593932 11.5206L2.43408 12.3041ZM1.79408 11.6417C2.20638 11.4827 2.6072 11.8975 2.43408 12.3041L0.593932 11.5206C0.0745637 12.7404 1.27701 13.9849 2.51392 13.5077L1.79408 11.6417ZM4.12339 10.7432L1.79408 11.6417L2.51392 13.5077L4.84323 12.6091L4.12339 10.7432ZM7.05077 11.3337C6.29281 11.3337 5.5831 11.1399 4.96541 10.8L4.00122 12.5523C4.9068 13.0506 5.94722 13.3337 7.05077 13.3337V11.3337Z"
              fill="#FF576D"
            />
          </svg>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.ADD = 'add';
Icons.ARCHIVE = 'archive';
Icons.PROFILE = 'profile';
Icons.ATTACHMENT = 'attachment';
Icons.ACTIVITY = 'activity';
Icons.COMMENT = 'comment';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
