import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { routes } from 'settings/navigation/routes';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { AssignItem } from '../../../View/components';
import { Button } from 'components-antd';
import { AssignedTo } from '../../../../TaskModalForm/AssignedTo';
import { CcTo } from '../../../../TaskModalForm/CcTo';
import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import {
  getTransactionEffect,
  updateTransactionTaskDueDateEffect,
} from 'store/effects/transactions';
import { updateCampaignTaskEffect } from 'store/effects/radarKits';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getUserId } from 'store/selectors/user';
import { showSuccessMessage } from 'helpers';
import { getTaskFilters } from 'store/selectors/transactionTasks';

import styles from './styles.module.scss';

export const AssignInfo = ({ task, disabled, setEditId }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const { pathname } = useLocation();
  const [showCC, setShowCC] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const userId = useSelector(getUserId);
  const { formValues } = useSelector(getTransactionTaskParamsSelector);
  const [updatedFormValues, setUpdatedFormValues] = useState(formValues);
  const filters = useSelector(getTaskFilters);

  useEffect(() => {
    setUpdatedFormValues(task);
    setShowCC(!!task?.CcList?.length);
  }, [isEdit]);

  const onSave = () => {
    setIsPending(true);
    let effect = updateTransactionTaskDueDateEffect;
    let cfg: any = {
      Id: task.Id,
      AssigneeList: updatedFormValues?.AssigneeList,
      CcList: updatedFormValues?.CcList,
      Hours: task?.Hours,
      Minutes: task?.Minutes,
    };

    if (task?.campaignId) {
      effect = updateCampaignTaskEffect;
    }

    dispatch(
      effect(cfg, {}, (err) => {
        if (!err) {
          if (pathname === routes.tasks) {
            dispatch(getTaskAggregateEffect({}, { silent: true }));
          } else if (pathname !== routes.tasks && task?.TransactionId) {
            dispatch(getTransactionEffect({ id: task?.TransactionId }, { silent: true }));
            dispatch(
              getTaskAggregateEffect(
                {
                  filters: { ...filters, transactionRoomId: task?.TransactionId },
                },
                { silent: true },
              ),
            );
          }
          showSuccessMessage('Changes are saved succesfully');
          onCancel();
        }
        setIsPending(false);
      }),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditId(null);
  };

  const getName = () => {
    if (!task) return '';
    const { AssigneeList } = task || {};
    const assignee = AssigneeList?.[0] || {};
    const names = AssigneeList?.map((item) => `${item?.FirstName} ${item?.LastName}`);
    let name = `${assignee.FirstName} ${assignee.LastName} ${
      AssigneeList?.length > 1 ? ` +${AssigneeList?.length - 1}` : ''
    }`;

    const layout = (
      <div className={classNames(styles.nameHolder, { [styles.alignStart]: names?.length > 1 })}>
        <div testid="assignee">
          {names?.map((name, idx) => (
            <div key={idx} className={styles.name}>
              {name}
            </div>
          ))}
        </div>
      </div>
    );

    return { layout, text: name };
  };

  const getCCName = () =>
    task?.CcList?.map((item) => `${item.FirstName} ${item.LastName}`).join(', ') || '';

  const onFormValueChange = (values) => {
    setUpdatedFormValues({ ...(updatedFormValues || {}), ...values });
  };

  const onChangeField = (val, fieldName) => {
    onFormValueChange({ ...(updatedFormValues || {}), [fieldName]: val });
  };

  const hasEditAccess = useMemo(
    () => !(task?.Assignor?.Id !== userId && !fullAccess && !disabled),
    [disabled],
  );

  return (
    <div className={styles.assgnInfo}>
      {!isEdit ? (
        <>
          <AssignItem
            name={getName()}
            listItems={task?.AssigneeList}
            showLabel
            className={styles.marginBottom}
          />
          <p
            className={classNames(styles.assignLabel, {
              [styles.marginLeft]: task?.AssigneeList.length > 1,
            })}
          >
            Assigned By:{' '}
            <span className={styles.bold}>
              {task?.Assignor?.FirstName ?? ''} {task?.Assignor?.LastName ?? ''}
            </span>
          </p>
          {task?.CcList && task?.CcList?.length ? (
            <div>
              <p
                className={classNames(styles.assignLabel, {
                  [styles.marginLeft]: task?.AssigneeList.length > 1,
                })}
              >
                CC: <span className={styles.bold}>{getCCName()}</span>
              </p>
            </div>
          ) : null}
          {hasEditAccess && (
            <Icon
              testid="edit_icon"
              className={styles.editIcon}
              variant={Icon.EDIT_DARK}
              onClick={() => {
                setIsEdit(true);
                setEditId('assign-info');
              }}
            />
          )}
        </>
      ) : (
        <div className={styles.rolesWrapper}>
          <AssignedTo
            isCampaignTask={!!task?.campaignId}
            isTemplate={false}
            values={updatedFormValues}
            onChangeField={onChangeField}
            isAssignTask={false}
            className={styles.fieldHalf}
            isViewMode={false}
            showRole={true}
            setShowCC={setShowCC}
            showCC={showCC}
            isNew={false}
          />
          {showCC && (
            <CcTo
              isCampaignTask={!!task?.campaignId}
              isTemplate={false}
              values={updatedFormValues}
              onChangeField={onChangeField}
              isAssignTask={false}
              className={styles.fieldHalf}
              isViewMode={false}
            />
          )}
          <div className={styles.footerBtns}>
            <Button
              variant="secondary"
              size="large"
              disabled={isPending || !updatedFormValues?.AssigneeList?.length}
              onClick={onSave}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
