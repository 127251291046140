import React, { memo, useMemo } from 'react';

const Copy = memo(({ className, color = '#FF576D' }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 1H7.5C6.67157 1 6 1.67157 6 2.5V9.5C6 10.3284 6.67157 11 7.5 11H12.5C13.3284 11 14 10.3284 14 9.5V2.5C14 1.67157 13.3284 1 12.5 1Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 5H3.5C2.67157 5 2 5.67157 2 6.5V13.5C2 14.3284 2.67157 15 3.5 15H8.5C9.32843 15 10 14.3284 10 13.5V11"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Copy;
