import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { FormDocumentQuestionsType, FormDocumentQuestionType, FormQuestionType } from 'types';

export const textFieldsType = [
  DYNAMIC_QUESTION_TYPE.TextBox,
  DYNAMIC_QUESTION_TYPE.CheckBox,
  DYNAMIC_QUESTION_TYPE.Date,
  DYNAMIC_QUESTION_TYPE.Address,
  DYNAMIC_QUESTION_TYPE.Number,
  DYNAMIC_QUESTION_TYPE.Phone,
  DYNAMIC_QUESTION_TYPE.Currency,
  DYNAMIC_QUESTION_TYPE.FullName,
  DYNAMIC_QUESTION_TYPE.RadioGroup,
];

export type EditorViewPropsType = {
  editorField: any;
  onFieldUpdate: (fieldProps) => void;
  isTemplate?: any;
};

export interface TextFieldProps {
  question: FormDocumentQuestionType;
  disabled?: boolean;
  color: { border: string; background: string };
  isHighlighted?: boolean;
  answer: string;
  handleFieldClick: (e) => void;
  isCohesiveFlow?: boolean;
  documentIndex?: number;
  otherSignatoryField?: boolean;
  pageQuestions?: FormDocumentQuestionsType;
  responses: any;

  questionField?: any;
  questionWidget?: any;
  questionFieldType?: FormQuestionType | DYNAMIC_QUESTION_TYPE;
  editorViewProps?: EditorViewPropsType;
  onUpdateAnswer?: (answer) => void;
}

export const signatureFieldType = [
  DYNAMIC_QUESTION_TYPE.DateSigned,
  DYNAMIC_QUESTION_TYPE.Initials,
  DYNAMIC_QUESTION_TYPE.Signature,
];

export interface SignatureFieldProps {
  question: FormDocumentQuestionType;
  disabled?: boolean;
  color: { border: string; background: string };
  isHighlighted?: boolean;
  answer: string;
  isCohesiveFlow?: boolean;
  documentIndex?: number;
  isOtherSignatory?: boolean;
  editorViewProps?: EditorViewPropsType;
  setDisableDrag?: (value: boolean) => void;
  onUpdateAnswer?: (answer) => void;
  isSequentialDisabled?: boolean;
}
