import { Table } from 'pages/Radar/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCampaignPublishedKitsWithStatsEffect,
  requestGetPublishedKitsEffect,
} from 'store/effects/radarKits';
import { PlansViewModal } from '../PlansViewModal';
import styles from '../styles.module.scss';
import { libraryColumns, LibraryDataType } from './helpers';
import { getLibraryKitsSelector } from 'store/selectors/radarKits';
import { setSelectedPlanIdAction } from 'store/actions/radarKits';
import { PENDING } from 'settings/constants/apiState';
import { Wrapper } from 'components';
import { PreviewKitsModal } from '../PreviewKitsModal';

export const Library: React.FC = () => {
  const dispatch = useDispatch();

  const { data, state } = useSelector(getLibraryKitsSelector);
  const isPending = state === PENDING;

  const [isPlansViewModalOpen, setIsPlansViewModalOpen] = useState<boolean>(false);
  const [isPreviewKitsModalOpen, setIsPreviewKitsModalOpen] = useState<boolean>(false);
  const [previewKitId, setPreviewKitId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getCampaignPublishedKitsWithStatsEffect({}, { silent: !!data }));
  }, []);

  const dataSource = useMemo<LibraryDataType[]>(() => {
    return (
      data?.map((item: any) => ({
        key: item.KitId,
        name: {
          title: item.Kits.Name,
          info: item.Kits.Category,
        },
        status: item.Kits.Published,
        numberOfPlans: item.stats.numberOfPlans,
        numberOfClients: item.stats.numberOfClients,
        sent: item.stats.numberOfKitsSent,
        opened: item.stats.numberOfKitsOpened,
      })) || []
    );
  }, [data]);

  const onPreview = (id: number) => {
    setPreviewKitId(id);
    setIsPreviewKitsModalOpen(true);
  };

  useEffect(() => {
    dispatch(requestGetPublishedKitsEffect({}));
  }, []);

  return (
    <>
      {isPreviewKitsModalOpen && previewKitId && (
        <PreviewKitsModal
          isOpen={isPreviewKitsModalOpen}
          closeModal={() => {
            setIsPreviewKitsModalOpen(false);
            setPreviewKitId(null);
          }}
          kitId={previewKitId}
          title="Preview"
        />
      )}
      <Wrapper className={styles.tableContainer} isPending={isPending}>
        <div>
          <Table
            title="KITs"
            count={dataSource?.length || 0}
            data={dataSource}
            columns={libraryColumns(onPreview)}
            handleRowClick={(record: LibraryDataType) => {
              // history.push(link.toRadarKitSequence(record.key));
              dispatch(setSelectedPlanIdAction(record.key));
              setIsPlansViewModalOpen(true);
            }}
          />
        </div>
      </Wrapper>
      {isPlansViewModalOpen && (
        <PlansViewModal
          isOpen={isPlansViewModalOpen}
          closeModal={() => setIsPlansViewModalOpen(false)}
        />
      )}
    </>
  );
};
