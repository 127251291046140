import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getGroupedProjectTemplatesSelector,
  getTransactionTemplatesDataSelector,
  getTransactionTemplatesSelector,
} from 'store/selectors/templates';
import { getAllTransactionTemplatesEffect } from 'store/effects/templates';
import { deleteTransactionTemplateEffect } from 'store/effects/template';
import { TemplateHeader } from '../components/TemplateHeader';
import { TemplateCard } from './components/TemplateCard';
import { NoTemplatesFound } from '../components/NoTemplates';
import { ConfirmationDialog, Wrapper } from 'components';
import { Collapse, Panel } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';

import { getUserId, getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { RulesModal } from './Rules';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { link } from 'settings/navigation/link';
import { Role } from 'app-constants';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getAllTeamsEffect } from 'store/effects';

import styles from './styles.module.scss';

export const Transactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isProject = location.pathname.includes('project');

  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const [templateId, setTemplateId] = useState<number | undefined>(undefined);
  const templates = useSelector(getTransactionTemplatesDataSelector);
  const projectTemplates = useSelector(getGroupedProjectTemplatesSelector);

  const [rulesModalVisible, setRulesModalVisible] = useState(false);

  const [templateToDelete, setTemplateToDelete] = useState<any>();
  const [query, setQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAgentTemplates, setShowAgentTemplates] = useState<boolean>(false);

  const { teams: allTeams = [] } = useSelector(getFormMetaSelect);
  const { isIdle: isTransactionTemplatesIdle, isPending: isTransactionTemplatesPending } =
    useSelector(getTransactionTemplatesSelector);
  const [activeCollapseKey, setActiveCollapseKey] = useState<string | string[]>('');

  useEffect(() => {
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
  }, []);

  useEffect(() => {
    if (isTransactionTemplatesIdle) {
      dispatch(
        getAllTransactionTemplatesEffect({
          isProject,
          ...(showAgentTemplates && { agentTemplates: true }),
        }),
      );
    } else {
      dispatch(
        getAllTransactionTemplatesEffect(
          { isProject, ...(showAgentTemplates && { agentTemplates: true }) },
          { silent: true },
        ),
      );
    }
  }, [showAgentTemplates]);

  const getUpdatedTemplate = (templateId?: number) =>
    templates.find((template) => template.Id === templateId);

  const onSetTemplateRules = (templateId) => {
    setTemplateId(templateId);
    setRulesModalVisible(true);
  };

  const onViewTemplate = (templateId: number) => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? link.toDashboardProjectTemplateView(templateId)
          : link.toDashboardTransactionTemplateView(templateId),
      );
    } else if (userRole === Role.Agent) {
      history.push(
        isProject
          ? link.toProjectTemplateView(templateId)
          : link.toTransactionTemplateView(templateId),
      );
    }
  };

  const onEditTemplate = (templateId: number) => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? link.toDashboardProjectTemplateEdit(templateId)
          : link.toDashboardTransactionTemplateEdit(templateId),
      );
    } else if (userRole === Role.Agent) {
      history.push(
        isProject
          ? link.toProjectTemplateEdit(templateId)
          : link.toTransactionTemplateEdit(templateId),
      );
    }
  };

  const onNewTemplate = () => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? routes.dashboardProjectTemplateCreate
          : routes.dashboardTransactionTemplateCreate,
      );
    } else if (userRole === Role.Agent) {
      history.push(isProject ? routes.templatesProjectCreate : routes.templatesTransactionCreate);
    }
  };

  const onTemplateDelete = (templateId: number) => {
    setTemplateToDelete(getUpdatedTemplate(templateId));
  };

  const onConfirmDeleteItem = () => {
    if (!templateToDelete) return;
    setIsDeleting(true);
    dispatch(
      deleteTransactionTemplateEffect({ id: templateToDelete?.Id, isProject }, {}, (err) => {
        if (!err) {
          setTemplateToDelete(null);
        }
        setIsDeleting(false);
      }),
    );
  };

  const deleteTemplateContent = useMemo(
    () =>
      templateToDelete ? (
        <div testid="modal_title">
          Are you sure that you want to <b>delete </b> the <b>{templateToDelete.Name}</b>{' '}
          transaction template?
        </div>
      ) : null,
    [templateToDelete],
  );

  const onCloseDeleteItemModal = () => setTemplateToDelete(null);

  const onToggleAgentTemplateView = () => setShowAgentTemplates((prev) => !prev);
  const filteredTemplates = useMemo(() => {
    if (showAgentTemplates && userRole === Role.SuperUser) {
      return templates?.filter((template) => template.CreatorId !== userId);
    } else if (!showAgentTemplates && userRole === Role.SuperUser) {
      return templates?.filter((template) => template.CreatorId === userId);
    }
    return templates;
  }, [templates, showAgentTemplates]);

  const filteredProjectTemplates = useMemo(() => {
    if (showAgentTemplates && userRole === Role.SuperUser) {
      return filterObjectValues(projectTemplates, (template) => template.CreatorId !== userId);
    } else if (!showAgentTemplates && userRole === Role.SuperUser) {
      return filterObjectValues(projectTemplates, (template) => template.CreatorId === userId);
    }
    return projectTemplates;
  }, [templates, showAgentTemplates]);

  const isObjectEmptyOrHasEmptyArrays = (obj) => {
    if (Object?.keys(obj)?.length === 0) return true; // Check if object is empty

    return Object.values(obj).every((value) => Array.isArray(value) && value.length === 0);
  };

  return (
    <Wrapper isPending={isTransactionTemplatesPending} className={styles.transactions}>
      {isSuperUser && (
        <RulesModal
          rulesModalVisible={rulesModalVisible}
          transactionTemplate={getUpdatedTemplate(templateId)}
          onCancel={() => {
            setRulesModalVisible(false);
            setTemplateId(undefined);
          }}
        />
      )}
      <ConfirmationDialog
        onReject={onCloseDeleteItemModal}
        onConfirm={onConfirmDeleteItem}
        isOpen={!!templateToDelete}
        isPending={isDeleting}
      >
        {deleteTemplateContent}
      </ConfirmationDialog>

      <TemplateHeader
        label={`${isProject ? 'Project' : 'Transaction'} Templates`}
        onNew={onNewTemplate}
        value={query}
        onSearch={(val) => setQuery(val)}
        onClear={() => setQuery('')}
        onToggleAgentTemplateView={onToggleAgentTemplateView}
        showAgentTemplates={showAgentTemplates}
      />
      {!isProject && filteredTemplates?.length ? (
        filteredTemplates
          .filter((temp) => temp.Name.toLowerCase().includes(query) || temp.Name.includes(query))
          .map((template) => (
            <TemplateCard
              key={template?.Id}
              template={template}
              onSetRules={onSetTemplateRules}
              onView={onViewTemplate}
              onEdit={onEditTemplate}
              onDelete={onTemplateDelete}
            />
          ))
      ) : !isObjectEmptyOrHasEmptyArrays(filteredProjectTemplates || {}) ? (
        Object.keys(filteredProjectTemplates).map((groupName, index) =>
          filteredProjectTemplates?.[groupName]?.length ? (
            <Collapse
              key={index}
              bordered={false}
              expandIcon={({ isActive }) => (isActive ? <ArrowUp /> : <ArrowDown />)}
              expandIconPosition={'end'}
              className={styles.projectsTemplatesCollapse}
              ghost={true}
              accordion
              activeKey={activeCollapseKey}
              onChange={(active: string | string[]) => setActiveCollapseKey(active)}
            >
              <Panel
                header={
                  <>
                    {groupName}&nbsp;
                    <span className={styles.counterText}>
                      ({filteredProjectTemplates?.[groupName]?.length})
                    </span>
                  </>
                }
                key={groupName}
                className={styles.panelHeader}
              >
                {filteredProjectTemplates?.[groupName].map((template) => (
                  <TemplateCard
                    key={template?.Id}
                    template={template}
                    onSetRules={onSetTemplateRules}
                    onView={onViewTemplate}
                    onEdit={onEditTemplate}
                    onDelete={onTemplateDelete}
                  />
                ))}
              </Panel>
              {index < Object.keys(filteredProjectTemplates)?.length - 1 && (
                <div className={styles.divider} />
              )}
            </Collapse>
          ) : null,
        )
      ) : (
        <NoTemplatesFound
          className={styles.noTemplates}
          templatesName={isProject ? 'project' : 'transaction'}
        />
      )}
    </Wrapper>
  );
};

function filterObjectValues(obj, condition) {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key].filter(condition);
    }
  }

  return result;
}
