import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { v4 as uuidv4 } from 'uuid';
import { signatureFieldType } from '../../../DynamicViewer/components/FieldRenderer/Fields/types';

export const SIGNATURE_MIN_WIDTH = 88;
export const SIGNATURE_MIN_HEIGHT = 20;

export const INITIALS_MIN_WIDTH = 38;
export const INITIALS_MIN_HEIGHT = 15;

export const shouldHideType = (fieldType, width) => {
  const { Initials, DateSigned, Date, TextBox, FullName, Address, Phone, Number, Currency } =
    DYNAMIC_QUESTION_TYPE;

  if (fieldType === Initials && width < 75) return true;
  else if ((fieldType === DateSigned || fieldType === Date) && width < 126) return true;
  else if (fieldType === TextBox && width < 84) return true;
  else if (fieldType === FullName && width < 102) return true;
  else if (fieldType === Phone && width < 133) return true;
  else if (fieldType === Address && width < 85) return true;
  else if (fieldType === Number && width < 85) return true;
  else if (fieldType === Currency && width < 93) return true;
};

export const calculateGroupSize = (field, scale) => {
  const { options } = field as any;

  const maxRight = Math.max(
    ...options.map((opt) => !opt.isDeleted && (opt.x < 0 ? 0 : opt.x) + opt.width * scale),
  );

  const maxBottom = Math.max(
    ...options.map((opt) => !opt.isDeleted && (opt.y < 0 ? 0 : opt.y) + opt.height * scale),
  );
  return { width: maxRight + 20, height: maxBottom + 40 };
};

export const getCopiedRadioField = (field) => {
  const newOptions: any[] = [];
  const radioField = { ...field };

  const omittedKeys = [
    'isUpdated',
    'isGroupResized',
    'isHighlighted',
    'isDeleted',
    'questionValidationId',
    'groupOptionUUID',
    'groupDragged',
    'groupBorderWidth',
    'validationId',
  ];

  omittedKeys.forEach((key) => delete radioField[key]);

  radioField.options.forEach((optionItem) => {
    const { id, isUpdated, isDeleted, ...item } = optionItem;

    if (isDeleted) return;

    newOptions.push({
      ...item,
      UUID: uuidv4(),
    });
  });

  radioField.options = newOptions;

  return radioField;
};

export const canChangeRole = (type, answer) => {
  return !signatureFieldType.includes(type) || !answer;
};

export const getResizeProps = (isStrikeThrough) => {
  const rndResizeProps = {
    ...(isStrikeThrough && {
      enableResizing: {
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: true,
        right: true,
        top: false,
        topLeft: false,
        topRight: false,
      },
    }),
  };

  return rndResizeProps;
};

export const prepareTemplateAnswers = (answers) => {
  if (!answers?.length) return {};

  const fieldAnswers = [...answers];

  const documentAnswers = {};
  fieldAnswers.forEach((item) => {
    const { UUID, answer } = item;

    documentAnswers[UUID] = { Answer: answer, UUID, DefaultAnswer: '' };
  });

  return documentAnswers;
};
