import React, { useState } from 'react';
import { AssignClientItem } from '../AssignClientItem/AssignClientItem';
import styles from './styles.module.scss';
import { Avatar, Checkbox } from 'components';
import { CampaignMemberClient } from 'pages/Radar/RadarKits/AssignClientItem/types';
import { getInitials } from 'utils';

type AssignAgentItemType = {
  agent: {
    Id: number;
    FirstName: string;
    LastName: string;
    clients: CampaignMemberClient[];
  };
  selected: { ClientId: number; AddressId: number }[];
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    ClientId: number | null,
    AddressId: number | null,
  ) => void;
};

export const AssignAgentItem: React.FC<AssignAgentItemType> = ({ agent, selected, onChange }) => {
  const [collapsed, setCollapsed] = useState(true);

  const isClientSelected = (clientId: number, addressId: number) =>
    selected.some((item) => item.ClientId === clientId && item.AddressId === addressId);

  const areAllClientsSelected = () => {
    return agent?.clients?.every((client) =>
      client.addresses.every((address) => isClientSelected(client.clientId, address.addressId)),
    );
  };

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    agent?.clients?.forEach((client) => {
      client.addresses.forEach((address) => {
        onChange(e, client.clientId, address.addressId);
      });
    });
  };

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div className={styles.agentItem}>
      <div className={styles.agentHeader} onClick={toggleCollapse}>
        <div className={styles.agentInfo}>
          <Avatar
            avatarClassName={styles.avatar}
            placeholder={<div className={styles.avatarPlaceholder}>{getInitials('All Users')}</div>}
          />
          <div className={styles.agentDetails}>
            <div className={styles.agentName}>
              {agent.FirstName} {agent.LastName}
            </div>
            <div className={styles.clientsCount}>({agent.clients.length} Clients)</div>
          </div>
        </div>
        <Checkbox
          checked={areAllClientsSelected()}
          onChange={handleSelectAllChange}
          className={styles.agentCheckbox}
        />
      </div>

      {!collapsed && (
        <div className={styles.clientList}>
          {agent.clients.map((client) => (
            <AssignClientItem
              isAgentView={true}
              key={client.clientId}
              client={client}
              selected={selected}
              onChange={onChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};
