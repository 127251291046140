import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { getHintColor } from 'components/Match';

const CircularProgressBar = ({
  size,
  percentage,
  lineWidth,
  progressColorClass,
  textClassName,
  showText,
}) => {
  const viewBox = `0 0 ${size} ${size}`;
  const strokeWidth = lineWidth || Math.round(size / 5.3);
  const radius = (size - strokeWidth) / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        className={styles.circleBackground}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={classNames(styles[getHintColor(percentage)], progressColorClass)}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      {showText && (
        <text
          className={classNames(styles.progressText, textClassName)}
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
          testid="circle_value"
        >
          {percentage}
        </text>
      )}
    </svg>
  );
};

CircularProgressBar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  progressColorClass: PropTypes.string,
  textClassName: PropTypes.string,
  showText: PropTypes.bool,
};

CircularProgressBar.defaultProps = {
  size: 32,
  percentage: 0,
  lineWidth: null,
  progressColorClass: '',
  textClassName: '',
  showText: false,
};

export default CircularProgressBar;
