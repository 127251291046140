/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getUserId } from 'store/selectors/user';
import { sendMarkThreadAsReadEffect } from 'store/effects/sockets/threads';

import { Wrapper as PendingWrapper } from 'components';
import Message from './Message';

import styles from './styles.module.scss';
import { markThreadAsRead } from 'api/comments';

const CommentsView = (props) => {
  const {
    className,
    list,
    threadId,
    currentEditMessage,
    onEditMessage,
    onDeleteMessage,
    isTransactionDrawer,
    isModal,
    useRestApi,
    messageWrapperClassName = '',
    messageHolderClassName = '',
    messageSenderClassName = '',
    messageTextClassName = '',
    messageListWrapperClassName = '',
    showSenderAsTitle = false,
  } = props;
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const containerRef = useRef();

  const scrollToBottom = useCallback(() => {
    if (containerRef?.current) {
      const container = containerRef?.current;
      container?.scrollTo(0, containerRef?.current?.scrollHeight, { behavior: 'smooth' });
    }
  }, [containerRef]);

  useEffect(() => {
    scrollToBottom();
  }, [list?.length, containerRef?.scrollHeight]); // eslint-disable-line

  useEffect(() => {
    if (!threadId) return;
    if (useRestApi) {
      return markThreadAsRead({ threadId });
    }
    dispatch(sendMarkThreadAsReadEffect({ threadId }));
  }, [dispatch, threadId]);

  const extendedMessages = useMemo(
    () =>
      (list || []).map((message) => {
        return {
          ...message,
          IsOwnMessage: message?.SenderUserId === userId,
        };
      }),
    [list, userId],
  );

  return (
    <PendingWrapper
      ref={containerRef}
      isPending={list === null}
      className={classNames(styles.messages, className, {
        [styles.messagesTransactionPane]: isTransactionDrawer,
      })}
    >
      <div
        testid="messages"
        className={classNames(
          styles.innerHolder,
          {
            [styles.innerHolderPadd]: isTransactionDrawer || isModal,
          },
          messageListWrapperClassName,
        )}
      >
        {(extendedMessages || []).map((message) => (
          <Message
            key={message?.TempId || message?.Id}
            currentEditMessage={currentEditMessage}
            messageClassName={`${styles.message} ${messageTextClassName}`}
            onEdit={onEditMessage}
            onDelete={onDeleteMessage}
            className={messageWrapperClassName || ''}
            messageHolderClassName={messageHolderClassName || ''}
            messageSenderClassName={messageSenderClassName || ''}
            showSenderAsTitle={showSenderAsTitle}
            {...message}
          />
        ))}
      </div>
    </PendingWrapper>
  );
};

CommentsView.propTypes = {
  className: PropTypes.string,
  threadId: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  currentEditMessage: PropTypes.shape({}),
  onEditMessage: PropTypes.func,
  onDeleteMessage: PropTypes.func,
  isTransactionDrawer: PropTypes.bool,
  currentThread: PropTypes.shape({}),
  isModal: PropTypes.bool,
  useRestApi: PropTypes.bool,
  messageSenderClassName: PropTypes.string,
};

CommentsView.defaultProps = {
  className: '',
  list: null,
  isModal: false,
  currentEditMessage: null,
  onEditMessage: () => {},
  onDeleteMessage: () => {},
  isTransactionDrawer: false,
  currentThread: undefined,
  useRestApi: false,
  messageSenderClassName: '',
};

export default CommentsView;
