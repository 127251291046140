import { createAction } from 'redux-actions';

export const openActivityFiltersDrawerAction = createAction('DRAWERS/OPEN_ACTIVITY_FILTERS_DRAWER');
export const sortActivityListByOrderAction = createAction('SORT/ACTIVITY_LIST_BY_ORDER');
export const setActivityFiltersDrawerPropertiesFieldAction = createAction(
  'DRAWERS/SET_ACTIVITY_FILTERS_DRAWER_PROPERTIES_FIELD',
);
export const setActivityFiltersDrawerUsersFieldAction = createAction(
  'DRAWERS/SET_ACTIVITY_FILTERS_DRAWER_USERS_FIELD',
);
export const setActivityFiltersDrawerStartDateFieldAction = createAction(
  'DRAWERS/SET_ACTIVITY_FILTERS_DRAWER_START_DATE_FIELD',
);
export const setActivityFiltersDrawerEndDateFieldAction = createAction(
  'DRAWERS/SET_ACTIVITY_FILTERS_DRAWER_END_DATE_FIELD',
);
export const resetActivityFiltersDrawerFieldsAction = createAction(
  'DRAWERS/RESET_ACTIVITY_FILTERS_DRAWER_FIELDS',
);

export const setActivityFiltersDrawerActivityTypesAction = createAction(
  'DRAWERS/SET_ACTIVITY_FILTERS_DRAWER_ACTIVITY_TYPES',
);
