import {
  deleteTransactionTemplate,
  getTransactionTemplateById,
  postTransactionTemplate,
  putTransactionTemplate,
} from 'api/templates';
import { get, isNumber, orderBy } from 'lodash-es';
import {
  requestGetTransactionTemplateAction,
  requestPostTransactionTemplateAction,
  requestPutTransactionTemplateAction,
  requestDeleteTransactionTemplateAction,
} from 'store/actions/template';
import Api from 'store/effects/core/api';
import { getAllTransactionTemplatesEffect } from 'store/effects/templates';
import { getTaskCategoriesEffect } from 'store/effects/transactions';
import { v4 as uuidv4 } from 'uuid';

export const getTransactionTemplateEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestGetTransactionTemplateAction,
    method: getTransactionTemplateById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

const getTransactionTemplateSendData = (data) => {
  const timelineTemplate = data?.TimelineTemplate;
  const taskTemplates = data?.TaskTemplates || [];
  const formTemplates = data?.TransactionFormTemplate || [];
  return {
    IsProject: data?.IsProject,
    TransactionCategoryId: data?.TransactionCategoryId || undefined,
    Name: data?.Name || undefined,
    UpdatedDate: data?.UpdatedDate || undefined,
    PropertyType: data?.PropertyType || undefined,
    Financing: data?.Financing || undefined,
    Description: data?.Description || undefined,
    People: data?.People,
    Teams: typeof data?.Teams === 'number' ? [data.Teams] : undefined,
    Locations: data?.Locations,
    City: data?.City,
    State: data?.State,
    County: data?.County,
    TransactionFormTemplate: formTemplates
      .filter(
        (form) => form.Name && (form.DueDate || form.DueDate === 0) && form.AssigneeRoles.length,
      )
      .map((values) => ({
        Id: get(values, 'Id') || undefined,
        Name: get(values, 'Name') || undefined,
        Description: get(values, 'Description') || undefined,
        DueDate: values?.DueDate || values?.DueDate == 0 ? +values.DueDate : undefined,
        Initial: values?.Initial,
        Operator: values?.Operator,
        ControlOperatorOffset: values?.ControlOperatorOffset || undefined,
        AssigneeRoles: get(values, 'AssigneeRoles'),
        CcRoles: get(values, 'CcRoles') || [],
        IsBundle: get(values, 'IsBundle'),
        IsRequired: get(values, 'IsRequired'),
        TemplateId: get(values, 'TemplateId'),
        TransactionTemplateId: get(values, 'TransactionTemplateId'),
        FormType: get(values, 'FormType'),
        MilestonePublicId: get(values, 'MilestonePublicId') || undefined,
      })),
    DetailFields: data?.DetailFields.map(({ EditMode, ...fieldData }) => fieldData),
    TimelineTemplate: timelineTemplate?.Milestones
      ? {
          Name: timelineTemplate.title || timelineTemplate.Name || 'Timeline Template',
          Description: timelineTemplate.Description || undefined,
          Milestones:
            timelineTemplate?.Milestones?.length &&
            timelineTemplate.Milestones.every((m) => get(m, 'Title'))
              ? orderBy(
                  timelineTemplate.Milestones.map((m) => ({
                    Title: get(m, 'Title') || undefined,
                    Offset: get(m, 'DueDate') || m?.DueDate == 0 ? +m.DueDate : get(m, 'Offset'),
                    Initial: get(m, 'Initial'),
                    Operator: get(m, 'Operator'),
                    ControlOperatorOffset: get(m, 'ControlOperatorOffset') || undefined,
                    IsPrivate: get(m, 'IsPrivate') || undefined,
                    PublicId: get(m, 'PublicId', uuidv4()),
                  })),
                  (item) => +item.Offset,
                  ['asc'],
                )
              : [],
        }
      : undefined,
    TaskTemplates: taskTemplates
      .filter(
        (task) => task.Name && (task.DueDate || task.DueDate === 0) && task.AssigneeRoles.length,
      )
      .map((values, idx) => ({
        Id: get(values, 'Id') || undefined,
        Name: get(values, 'Name') || undefined,
        Description: get(values, 'Description') || undefined,
        DueDate: values?.DueDate || values?.DueDate == 0 ? +values.DueDate : undefined,
        Initial: values?.Initial,
        Operator: values?.Operator,
        ControlOperatorOffset: values?.ControlOperatorOffset || undefined,
        Categories: values?.Categories?.map((item) => item?.Name || item),
        Checklists: values?.Checklists
          ? (values?.Checklists || []).filter((checklist) => !!checklist.Name)
          : undefined,
        Links: get(values, 'Links') || undefined,
        Documents: values?.Documents?.map((document) => ({
          Title: document?.Title || document?.documentName,
          Category:
            document?.customTag ||
            document?.category?.name ||
            document?.category?.Name ||
            document?.category,
          File: {
            ContentType: document?.ContentType || document?.contentType || document?.Meta?.MimeType,
            Filename: document?.Filename || document?.filename || document?.Title,
            Size: document?.Size || document?.size,
            Url: document?.Url || document?.url,
            DocumentVaultUUID: document?.DocumentVaultUUID,
            Data:
              document?.Data || document?.data ? btoa(document?.Data || document?.data) : undefined,
          },
        })),
        AssigneeRoles: get(values, 'AssigneeRoles'),
        CcRoles: get(values, 'CcRoles'),
        MilestonePublicId: get(values, 'MilestonePublicId'),
        EmailTemplateId: values?.EmailTemplate?.EmailTemplateId,
        Order: idx + 1,
        Hours: isNumber(values?.Hours) ? values?.Hours : null,
        Minutes: isNumber(values?.Minutes) ? values?.Minutes : null,
        IsBusinessDays: values?.IsBusinessDays,
        Timezone: values?.Timezone,
        ...(values?.RepeatOption ? { RepeatOption: values?.RepeatOption } : {}),
        ...(values?.CustomRepeatOption ? { CustomRepeatOption: values?.CustomRepeatOption } : {}),
        ...(values?.RepeatCount ? { RepeatCount: values?.RepeatCount } : {}),
        ...(values?.RepeatOn ? { RepeatOn: values?.RepeatOn } : {}),
        ...(values?.RepeatOccurrence ? { RepeatOccurrence: values?.RepeatOccurrence } : {}),
        ...(values?.RepeatUntilDate ? { RepeatUntilDate: values?.RepeatUntilDate } : {}),
        ...(values?.RepeatOption ? { EndsOnClosing: values?.EndsOnClosing } : {}),
        ...(values?.RepeatOption
          ? { EndsOnListingExpiration: values?.EndsOnListingExpiration }
          : {}),
      })),
  };
};

export const postTransactionTemplateEffect = (data, options, cb) => {
  const requestParams = {
    action: requestPostTransactionTemplateAction,
    method: postTransactionTemplate,
  };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTransactionTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(
        getAllTransactionTemplatesEffect({ isProject: sendData?.IsProject }, { silent: true }),
      );
      dispatch(getTaskCategoriesEffect());
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const putTransactionTemplateEffect = (data, options, cb) => {
  const requestParams = {
    action: requestPutTransactionTemplateAction,
    method: putTransactionTemplate,
  };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTransactionTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(
        getAllTransactionTemplatesEffect({ isProject: sendData?.IsProject }, { silent: true }),
      );
      dispatch(getTaskCategoriesEffect());
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const deleteTransactionTemplateEffect = ({ id, isProject }, options, cb) => {
  const requestParams = {
    action: requestDeleteTransactionTemplateAction,
    method: deleteTransactionTemplate,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest({ id }, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTransactionTemplatesEffect({ isProject }, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};
