import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'components-antd';
import { Locations } from 'components';
import { sortLocations } from 'helpers';
import { ServiceIcons, ServiceIconTypes } from 'pages/Services/icons';
import { Location } from 'types';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { updateConciergeLocationLocalEffect } from 'store/effects/concierge';
import { AGENT } from 'settings/constants/roles';
import { CONCIERGE_LOCATIONS } from 'settings/constants/localStorage';

import styles from './styles.module.scss';

interface ServiceLocationProps {
  className?: string;
  handleClose: () => void;
  buttonClassName: string;
  locations: Location[];
  setLocations: Function;
}

export const ServiceLocation: React.FC<ServiceLocationProps> = ({
  className,
  handleClose,
  buttonClassName,
  locations,
  setLocations,
}) => {
  const dispatch = useDispatch();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const { areasOfOperation } = useSelector(getConciergeSearchSelector);
  const storedAreasOfOperation = window.localStorage.getItem(CONCIERGE_LOCATIONS);
  const userRole = useSelector(getUserRoleSelector);

  const updateConciergeLocation = () => {
    if (isClient) window.localStorage.setItem(CONCIERGE_LOCATIONS, JSON.stringify(locations));
    dispatch(updateConciergeLocationLocalEffect(locations));
  };

  const updateLocation = (result, prepareData) => {
    let updatedData = locations?.length ? [...locations] : undefined;

    let areasOfOperation = prepareData?.length
      ? (userRole === AGENT ? prepareData : [...prepareData, ...(updatedData || [])]) || []
      : updatedData || [];
    if (result?.deleteItemId) {
      areasOfOperation = areasOfOperation.filter(
        ({ ProviderPlaceId: itemId }) => itemId !== result.deleteItemId,
      );
    }
    areasOfOperation = uniqBy(areasOfOperation, 'ProviderPlaceId');
    areasOfOperation.sort(sortLocations);
    updatedData = [...areasOfOperation];

    if (updatedData) setLocations(updatedData?.length ? updatedData : []);
  };

  useEffect(() => {
    if (isClient && storedAreasOfOperation) setLocations(JSON.parse(storedAreasOfOperation));
    else setLocations(areasOfOperation);
  }, []);

  return (
    <>
      <span className={classNames(styles.locationWrapper, className)}>
        <Locations
          multiple
          allowStates={false}
          getStates={false}
          onResult={(result, prepareData) => updateLocation(result, prepareData)}
          value={locations?.length ? locations : undefined}
          placeholderIcon={<ServiceIcons icon={ServiceIconTypes.location} />}
          placeholder={'Enter state, zip code, city, or neighborhood'}
          activeInputClassName={styles.activeInputClassName}
          valuesWrapperClassName={styles.valuesWrapperClassName}
          placeholderClassName={styles.placeholderClass}
          valuesContainerClassName={styles.valuesContainer}
          searchIcon={!!locations?.length}
          variant={Locations.ROUND}
          shouldFilterStateLevelResults={true}
        />
      </span>
      <Button
        disabled={locations?.length ? false : true}
        onClick={() => {
          updateConciergeLocation();
          handleClose();
        }}
        className={buttonClassName}
        variant={'secondary'}
      >
        Continue
      </Button>
    </>
  );
};
