import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'components-antd';

import { map } from 'lodash-es';

import SearchBlockActions from '../SearchBlockActions';
import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import { Col } from 'antd';

import styles from './styles.module.scss';
import { Info } from 'components/Icons';
import moment from 'moment';
import SavedIcon from '../Icons/SavedIcon';

const SearchCard = ({
  title,
  updatedDate,
  status,
  agentName,
  info,
  fullInfo,
  footer,
  withActions,
  infoClassName,
  className,
  onClick,
  onEdit,
  onSearch,
  onRename,
  onDelete,
  onInactive,
  onActivate,
  photos,
  testid,
}) => {
  const editHandler = useCallback(() => {
    onEdit();
  }, [onEdit]);

  const searchHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onSearch();
    },
    [onSearch],
  );

  const inactiveHandler = useCallback(() => {
    onInactive();
  }, [onInactive]);

  const activateHandler = useCallback(() => {
    onActivate();
  }, [onActivate]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', width: '100%' }}>
      <div style={{ display: 'flex', gap: '18px', flex: '1', cursor: 'pointer' }} onClick={onClick}>
        <div testid={testid} className={classNames(styles.container, className)}>
          {photos.length > 0 ? (
            <div
              className={classNames(styles.images, {
                [styles.blurred]: status === SEARCH_INSTANCE_STATUS.INACTIVE,
              })}
            >
              {map(photos, (link, index) =>
                index < 4 ? <img key={index} alt="Search images" src={link} /> : '',
              )}
            </div>
          ) : (
            <SavedIcon size="24" color="#747475" className={styles.savedIcon} />
          )}
        </div>
        <div
          className={classNames(styles.infoBlock, infoClassName, {
            [styles.deactivated]: status === SEARCH_INSTANCE_STATUS.INACTIVE,
          })}
        >
          <div className={styles.infoBlockWrap}>
            <div
              className={classNames(
                styles.infoBlockLeft,
                status === SEARCH_INSTANCE_STATUS.INACTIVE
                  ? styles.infoBlockLeftInactive
                  : styles.infoBlockLeftActive,
              )}
            >
              {title ? (
                <div testid="search_title" className={styles.descTitle}>
                  {title}
                </div>
              ) : (
                <a>&nbsp;</a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.descFooter}>
        {withActions && title !== 'My Primary Search' ? (
          <SearchBlockActions
            popupPosition={'bottom'}
            onDelete={onDelete}
            onSearch={searchHandler}
            onRename={onRename}
            searchName={title}
            onEdit={editHandler}
            onActivate={status === SEARCH_INSTANCE_STATUS.INACTIVE ? activateHandler : null}
            onInactive={status === SEARCH_INSTANCE_STATUS.ACTIVE ? inactiveHandler : null}
          />
        ) : null}
      </div>
    </div>
  );
};

SearchCard.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  status: PropTypes.string,
  agentName: PropTypes.string,
  info: PropTypes.string,
  footer: PropTypes.string,
  className: PropTypes.string,
  infoClassName: PropTypes.string,
  testid: PropTypes.string,
  withActions: PropTypes.bool,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onSearch: PropTypes.func,
  onRename: PropTypes.func,
  searchName: PropTypes.string,
  onDelete: PropTypes.func,
  onInactive: PropTypes.func,
  onActivate: PropTypes.func,
};

SearchCard.defaultProps = {
  photos: null,
  withActions: true,
  className: '',
  infoClassName: '',
  testid: undefined,
  title: '',
  agentName: '',
  status: '',
  info: '',
  footer: '',
  onClick: null,
  searchName: '',
  onEdit: () => {},
  onSearch: () => {},
  onRename: () => {},
  onDelete: () => {},
  onActivate: () => {},
  onInactive: () => {},
  isInactive: false,
};

export default SearchCard;
