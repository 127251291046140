import { combineReducers } from 'redux';

import { propertiesTabsIds } from 'settings/constants/properties';
import recommendedProps from './recommendedProps';
import newProps from './newProps';
import clientFavoriteProps from './clientFavoriteProps';
import allProps from './allProps';
import savedSearchesProps from './savedSearchesProps';
import statusChangesProps from './statusChangesProps';
import agentClients from './agentClients';
import openHousesProps from './openHousesProps';
import sort from './sort';
import pageInfo from './pageInfo';
import multiple from './multiple';
import compares from './compares';
import filterDrawer from './filterDrawer';
import shareDrawer from './shareDrawer';
import compareModal from './compareModal';
import activeTab from './activeTab';
import shareProperty from './shareProperty';
import feedPrefs from './feedPrefs';
import viewType from './viewType';
import priceReductionsProps from './priceReductionsProps';
import watchlistProps from './watchlistProps';
import backOnMarketProps from './backOnMarketProps';
import suggestionsProps from './suggestionsProps';
import imageProcess from './imageProcess';
import seenOnMapProperties from './seenOnMapProperties';
import searchInstanceProps from './searchInstanceProps';
import subFilterType from './subFilterType';
import areaDrawing from './areaDrawing';
import propertyToScrollInList from './propertyToScrollInList';
import clientHighlights from './clientHighlights';

export default combineReducers({
  [propertiesTabsIds.recommended]: recommendedProps,
  [propertiesTabsIds.new]: newProps,
  [propertiesTabsIds.clientFavorites]: clientFavoriteProps,
  [propertiesTabsIds.all]: allProps,
  [propertiesTabsIds.savedSearches]: savedSearchesProps,
  [propertiesTabsIds.statusChanges]: statusChangesProps,
  [propertiesTabsIds.agentClients]: agentClients,
  [propertiesTabsIds.openHouses]: openHousesProps,
  [propertiesTabsIds.priceReductions]: priceReductionsProps,
  [propertiesTabsIds.watchlist]: watchlistProps,
  [propertiesTabsIds.backOnMarket]: backOnMarketProps,
  [propertiesTabsIds.suggestions]: suggestionsProps,
  [propertiesTabsIds.searchInstance]: searchInstanceProps,
  sort,
  pageInfo,
  multiple,
  compares,
  filterDrawer,
  shareDrawer,
  compareModal,
  activeTab,
  shareProperty,
  feedPrefs,
  viewType,
  imageProcess,
  seenOnMapProperties,
  propertyToScrollInList,
  subFilterType,
  areaDrawing,
  clientHighlights,
});
