import { routes } from 'settings/navigation/routes';
import { CLIENT, AGENT, THIRD_PARTY } from 'settings/constants/roles';
import { ListingDetail } from 'pages';

export default [
  {
    path: routes.searchListingDetail,
    component: ListingDetail,
    exact: true,
    roles: [CLIENT, AGENT, THIRD_PARTY],
  },
];
