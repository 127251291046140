import React, { memo, useMemo } from 'react';

const Dots = memo(({ className }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.40156 10.7992C4.84339 10.7992 5.20156 10.441 5.20156 9.99922C5.20156 9.55739 4.84339 9.19922 4.40156 9.19922C3.95973 9.19922 3.60156 9.55739 3.60156 9.99922C3.60156 10.441 3.95973 10.7992 4.40156 10.7992Z"
            fill="#303030"
            stroke="#303030"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99922 10.7992C10.441 10.7992 10.7992 10.441 10.7992 9.99922C10.7992 9.55739 10.441 9.19922 9.99922 9.19922C9.55739 9.19922 9.19922 9.55739 9.19922 9.99922C9.19922 10.441 9.55739 10.7992 9.99922 10.7992Z"
            fill="#303030"
            stroke="#303030"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.6008 10.7992C16.0426 10.7992 16.4008 10.441 16.4008 9.99922C16.4008 9.55739 16.0426 9.19922 15.6008 9.19922C15.159 9.19922 14.8008 9.55739 14.8008 9.99922C14.8008 10.441 15.159 10.7992 15.6008 10.7992Z"
            fill="#303030"
            stroke="#303030"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

export default Dots;
