import { Modal, Button, Switch, Select } from 'components-antd';
import styles from './styles.module.scss';
import { Icons } from 'pages/Clarity/Icons';
import { useState } from 'react';
import { Input, Locations } from 'components';
import { updateKitById } from 'api/kits';
import { CheckBox } from 'components/Icons';
import { QuillEditor } from '../QuillEditor';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type EditModalPropsType = {
  showModal: Function;
  open: boolean;
  targetKit;
  propertyTypes;
  updateKitHandler: Function;
};

const validationSchema = Yup.object().shape({
  VideoURL: Yup.string().optional().nullable().url('Invalid URL'),
});

function EditModal(props: EditModalPropsType) {
  const [savingKit, setSavingKit] = useState<boolean>(false);
  const [savingKitError, setSavingKitError] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      Notes: props?.targetKit?.Notes || '',
      InternalName: props?.targetKit?.InternalName || '',
      VideoURL: props?.targetKit?.VideoURL || '',
      InAppReminder: props?.targetKit?.InAppReminder || false,
      EligibleLocations: props?.targetKit?.EligibleLocations || [],
      RestrictedLocations: props?.targetKit?.RestrictedLocations || [],
      EligiblePropertyTypes:
        props?.targetKit?.EligiblePropertyTypes?.replace(/[{}"]/g, '')?.split(',') || [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setSavingKit(true);
        const kitsObject = {
          ...values,
          EligiblePropertyTypes: `{${values.EligiblePropertyTypes.join(',')}}`,
        };
        await updateKitById(props.targetKit.Id, kitsObject);
        props.updateKitHandler(props.targetKit.Id, kitsObject);
        setSavingKit(false);
        setSavingKitError(false);
      } catch (e) {
        setSavingKit(false);
        setSavingKitError(true);
      }
    },
  });

  const updateLocation = (result, prepareData, isEligible) => {
    if (isEligible) {
      formik.setFieldValue('EligibleLocations', prepareData);
    } else {
      formik.setFieldValue('RestrictedLocations', prepareData);
    }
  };

  return (
    <Modal
      footer={null}
      width={675}
      open={props.open}
      onCancel={() => {
        props.showModal(false);
      }}
      title={
        <span className={styles.headerTitle}>
          <Icons variant="kits" />
          <span className={styles.headerText}>{`${props.targetKit?.Name} KIT`}</span>
        </span>
      }
      className={styles.kitsEditModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.propertyTypes}>
          <p className={styles.sectionLabel}>Property Types:</p>
          <Select
            large
            disabled={false}
            showArrow={true}
            placeholder="Select Property Types"
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode}
            menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
            className={styles.selector}
            value={formik.values.EligiblePropertyTypes}
            options={props.propertyTypes.map((el) => ({
              label: el,
              value: el,
              key: `type-${el}`,
            }))}
            filterOption={(input, option) => {
              return String((option?.label as unknown as string) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            onChange={(val) => {
              if (val?.length) formik.setFieldValue('EligiblePropertyTypes', val);
            }}
          />
        </div>
        <div className={styles.inAppReminder}>
          <p className={styles.sectionLabel}>In-App Reminder:</p>
          <Switch
            checked={formik.values.InAppReminder}
            onChange={(checked) => formik.setFieldValue('InAppReminder', checked)}
          />
        </div>
        <div className={styles.eligibleLocations}>
          <p className={styles.sectionLabel}>Eligible Locations</p>
          <Locations
            multiple
            allowStates={true}
            getStates={true}
            onResult={(result, prepareData) => updateLocation(result, prepareData, true)}
            value={
              formik.values.EligibleLocations?.length ? formik.values.EligibleLocations : undefined
            }
            placeholder={'Enter City, State, or County'}
            activeInputClassName={styles.activeInputClassName}
            valuesWrapperClassName={styles.valuesWrapperClassName}
            placeholderClassName={styles.placeholderClass}
            valuesContainerClassName={styles.valuesContainer}
            searchIcon={!!formik.values.EligibleLocations?.length}
            variant={Locations.SQUARE}
            customPlaceName={({ address }) => address?.split?.(',')?.slice(0, 2)?.join(',')}
            showOnlyCityAndState={true}
          />
        </div>
        <div className={styles.restrictedLocations}>
          <p className={styles.sectionLabel}>Restricted Locations</p>
          <Locations
            multiple
            allowStates={true}
            getStates={true}
            onResult={(result, prepareData) => updateLocation(result, prepareData, false)}
            value={
              formik.values.RestrictedLocations?.length
                ? formik.values.RestrictedLocations
                : undefined
            }
            placeholder={'Enter City, State, or County'}
            activeInputClassName={styles.activeInputClassName}
            valuesWrapperClassName={styles.valuesWrapperClassName}
            placeholderClassName={styles.placeholderClass}
            valuesContainerClassName={styles.valuesContainer}
            searchIcon={!!formik.values.RestrictedLocations?.length}
            variant={Locations.SQUARE}
            customPlaceName={({ address }) => address?.split?.(',')?.slice(0, 2)?.join(',')}
            showOnlyCityAndState={true}
          />
        </div>
        <div className={styles.kitsFields}>
          <p className={styles.sectionLabel}>Internal Name</p>
          <Input
            className={styles.field}
            variant="lightRound"
            placeholder="Enter internal name"
            value={formik.values.InternalName}
            onChange={formik.handleChange}
            name="InternalName"
            testid="internal_name_input"
            maxLength={100}
          />
        </div>
        <div className={styles.kitsFields}>
          <p className={styles.sectionLabel}>Video URL</p>
          <Input
            className={styles.field}
            variant="lightRound"
            placeholder="Enter video URL"
            value={formik.values.VideoURL}
            onChange={formik.handleChange}
            name="VideoURL"
            testid="video_url_input"
            maxLength={300}
            error={formik.errors.VideoURL as string}
          />
        </div>
        <div className={styles.kitsFields}>
          <p className={styles.sectionLabel}>Details</p>
          <QuillEditor
            value={formik.values.Notes}
            onChange={(value) => formik.setFieldValue('Notes', value)}
            placeholder="Details about this kit..."
            editorClass={styles.detailsEditor}
          />
        </div>
        <div className={styles.submitBtn}>
          <Button
            className={styles.notesButton}
            loading={savingKit}
            disabled={savingKit}
            onClick={() => formik.handleSubmit()}
          >
            {savingKitError ? 'Try Again' : 'Save Kit'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default EditModal;
