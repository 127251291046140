import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from 'components/Grid';
import Pagination from 'components/Pagination';

import styles from './styles.module.scss';

const DashboardGrid = (props) => {
  const { items, columns: propsColumns, renderActions, getChildRowContent, keyProp } = props;
  const { className, isCollapsible, onRowClick, hidePagination } = props;

  const [currentItems, setCurrentItems] = useState([]);

  const columns = useMemo(
    () => [
      ...propsColumns,
      ...(renderActions
        ? [
            {
              title: 'Actions',
              propKey: 'Id',
              render: (Id, data) => <div className={styles.actions}>{renderActions(Id, data)}</div>,
            },
          ]
        : []),
    ],
    [propsColumns, renderActions],
  );

  const handlePageChange = useCallback(
    (page, newPageItems) => setCurrentItems(newPageItems),
    [setCurrentItems],
  );

  const getFooter = () => (
    <div className={styles.footer}>
      <Pagination limit={10} onPageChange={handlePageChange} items={items} pagesLimit={3} />
    </div>
  );

  return (
    <Grid
      items={hidePagination ? items : currentItems}
      columns={columns}
      className={className}
      headerColContentClassName={styles.headerColContent}
      collapsibleRows={isCollapsible}
      getChildRowContent={getChildRowContent}
      onRowClick={onRowClick}
      getFooter={!hidePagination ? getFooter : null}
      keyProp={keyProp}
    />
  );
};

DashboardGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.Element,
      className: PropTypes.string,
      onClick: PropTypes.func,
      isSortCase: PropTypes.bool,
    }),
  ).isRequired,
  className: PropTypes.string,
  keyProp: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  getChildRowContent: PropTypes.func,
  isCollapsible: PropTypes.bool,
  hidePagination: PropTypes.bool,
  onRowClick: PropTypes.func,
};
DashboardGrid.defaultProps = {
  className: '',
  isCollapsible: false,
  hidePagination: false,
  renderActions: null,
  onRowClick: () => {},
  getChildRowContent: () => null,
};

export default DashboardGrid;
