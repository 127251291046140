import Api from 'store/effects/core/api';
import { getProjectCategories, getProjectOwners } from 'api/projectCategories';
import {
  cancelProjectAction,
  requestGetProjectCategoriesAction,
  requestGetProjectOwnersAction,
} from 'store/actions/projects';
import { cancelProject } from 'api/project';

export const requestGetProjectCategories = (cfg, options = {}, cb) => {
  const requestParams = { action: requestGetProjectCategoriesAction, method: getProjectCategories };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, {}, cb);
};

export const cancelProjectEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: cancelProjectAction,
    method: cancelProject,
  });

  const config = {
    id: cfg?.id,
    AdditionalCancellationComments: cfg?.AdditionalCancellationComments,
    ReasonForCancellation: cfg?.ReasonForCancellation,
  };

  return sendRequest(config, options, cb);
};
