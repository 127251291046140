import classNames from 'classnames';
import { Rnd } from 'react-rnd';
import { useState, useRef, useEffect, Fragment } from 'react';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDynamicFormQuestionEffect,
  selectDynamicFormFieldEffect,
  updateDynamicFormQuestionEffect,
} from 'store/effects/formProcess';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormSelector,
  getFormsSignatoriesSelector,
} from 'store/selectors/requestFormProcess';
import {
  DynamicFormEditorQuestion,
  DynamicFormSelectedField,
  FormTemplateWizardType,
  PDFEditorColor,
} from 'types';

import { v4 as uuidv4 } from 'uuid';
import { useOutsideClick } from 'hooks';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

import {
  INITIALS_MIN_HEIGHT,
  INITIALS_MIN_WIDTH,
  SIGNATURE_MIN_HEIGHT,
  SIGNATURE_MIN_WIDTH,
  canChangeRole,
  getCopiedRadioField,
  getResizeProps,
  prepareTemplateAnswers,
} from './helper';

import { InsertClauseModal } from '../../../DynamicViewer/components';

import { ActionButtons } from './ActionButtons';
import { FormEditorField } from './FormEditorField';
import { GROUP_PADDING } from '../EditorPage';
import { getCohesiveFieldAnswers } from '../../../DynamicViewer/components/FieldRenderer/Fields/helper';
import { otherSignatoryField } from './FormEditorField/helper';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { signatureFieldType } from '../../../DynamicViewer/components/FieldRenderer/Fields/types';

interface PDFEditorFieldRendererProps {
  field: DynamicFormEditorQuestion;
  index: number | undefined;
  isCohesiveFlow: boolean;
  documentIndex: number;
  scale: number;
  isTemplate: boolean;
}

export const FieldRenderer = ({
  field,
  index,
  documentIndex,
  isCohesiveFlow,
  scale,
  isTemplate,
}: PDFEditorFieldRendererProps) => {
  const dispatch = useDispatch();
  const popupRef = useRef<HTMLDivElement>(null);
  const { state: formState } = useLocation<FormTemplateWizardType>();

  const { selectedField, selectedFieldIndex } = useSelector(getDynamicFormEditorConfigSelector);
  const {
    dynamicFormDocuments = [],
    bundleDocumentsDetails = [],
    Answers,
  } = useSelector(getDynamicFormSelector);

  const templateAnswers = isTemplate
    ? isCohesiveFlow
      ? bundleDocumentsDetails[documentIndex].Answers
      : Answers
    : undefined;

  let formDocumentAnswers = dynamicFormDocuments[documentIndex].Answers;

  const [documentAnswers, setDocumentAnswers] = useState(
    isTemplate ? prepareTemplateAnswers(templateAnswers) : formDocumentAnswers,
  );

  let responses = documentAnswers;

  const [isRequiredPopupVisible, setRequiredPopupVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDisabledDrag, setIsDisabledDrag] = useState(false);
  const [openClauseModal, setOpenClauseModal] = useState(false);

  const isCheckbox = field.type === DYNAMIC_QUESTION_TYPE.CheckBox;
  const isGroupField = field.type === DYNAMIC_QUESTION_TYPE.RadioGroup;
  const isStrikeThrough = field.type === DYNAMIC_QUESTION_TYPE.StrikeThrough;

  const signatureField = field.type === DYNAMIC_QUESTION_TYPE.Signature;
  const initialField = field.type === DYNAMIC_QUESTION_TYPE.Initials;

  let isHighlighted = selectedField?.UUID && selectedField?.UUID === field?.UUID;
  isHighlighted = isGroupField ? isHighlighted && !!selectedField?.groupOptionUUID : isHighlighted;

  const createdByUserId = dynamicFormDocuments[documentIndex].CreatedById;
  const answer = documentAnswers?.[field.UUID]?.Answer || field.fieldAnswer?.answer || '';

  const formSignatories = useSelector(getFormsSignatoriesSelector);

  const role = field.customRole;
  const { isOtherSignatoryField, isSequentialDisabled } = !isTemplate
    ? otherSignatoryField(createdByUserId, formSignatories, role)
    : { isOtherSignatoryField: false, isSequentialDisabled: false };

  const fieldActionProps = {
    ...(formState?.editMode && { templateEditMode: true, roleId: role?.roleId }),
    isCohesiveFlow,
    documentIndex,
  };

  const viewModeProps = {
    roleId: role?.roleId,
  };

  useOutsideClick([popupRef], () => setRequiredPopupVisible(false));

  useEffect(() => {
    setDocumentAnswers(isTemplate ? prepareTemplateAnswers(templateAnswers) : formDocumentAnswers);
  }, [formDocumentAnswers]);

  useEffect(() => {
    if (!isHighlighted && isDisabledDrag) {
      setIsDisabledDrag(false);
    }
  }, [isHighlighted]);

  const color = role?.color || ({} as PDFEditorColor);

  const width = field.width * scale;
  const height = field.height * scale;
  const x = field.x * scale;
  const y = field.y * scale;

  const getWidthHeight = (width, height) => {
    if (signatureField) {
      width = width < SIGNATURE_MIN_WIDTH ? SIGNATURE_MIN_WIDTH : width;
      height = height < SIGNATURE_MIN_HEIGHT ? SIGNATURE_MIN_HEIGHT : height;
    } else if (initialField) {
      width = width < INITIALS_MIN_WIDTH ? INITIALS_MIN_WIDTH : width;
      height = height < INITIALS_MIN_HEIGHT ? INITIALS_MIN_HEIGHT : height;
    }

    return { width, height };
  };

  const handleFieldUpdate = (updatedProps) => {
    dispatch(
      updateDynamicFormQuestionEffect({
        ...field,
        index,
        ...updatedProps,
        ...fieldActionProps,
        ...viewModeProps,
      }),
    );
  };

  const handleHighLightField = () => {
    if (isGroupField) return;

    dispatch(
      selectDynamicFormFieldEffect({
        field: {
          pageNumber: field.pageNumber,
          roleName: field.roleName,
          UUID: field.UUID,
          roleId: role?.roleId,
        } as DynamicFormSelectedField,
      }),
    );
  };

  const handleFieldDelete = () => {
    dispatch(
      deleteDynamicFormQuestionEffect({
        ...field,
        index,
        ...fieldActionProps,
      }),
    );
  };

  const handleFieldCopy = () => {
    let updatedField: any = { ...field };
    if (isGroupField) {
      updatedField = getCopiedRadioField(updatedField);
    }

    const newField = {
      ...updatedField,
      isNew: true,
      UUID: uuidv4(),
      y: updatedField.y + height + (isGroupField ? -50 : 6),
      ...fieldActionProps,
      ...(isGroupField && { isNewGroup: true }),
    };

    delete newField.id;
    delete newField.order;
    delete newField.fieldAnswer;

    dispatch(updateDynamicFormQuestionEffect(newField));
  };

  const handleFieldRequiredPopupClick = () => {
    setRequiredPopupVisible(!isRequiredPopupVisible);
  };

  const handleFieldRequired = (e) => {
    if (selectedField) {
      handleFieldUpdate({ ...selectedField, index: selectedFieldIndex, required: e });
    }
  };

  const toggleClauseModal = () => {
    setOpenClauseModal((prev) => !prev);
  };

  if (field.isDeleted) return <></>;

  const grabPointStyle = isHighlighted ? styles.grabPoint : undefined;
  const midGrabPointStyle = isHighlighted ? styles.line : undefined;

  const getFieldStyle = () => {
    const { strokeColor, strokeWidth } = field;

    const fieldBorder = `${
      isGroupField
        ? `2.5px dotted ${isHighlighted ? color?.border : 'transparent'}`
        : `2px solid ${color?.border}`
    }`;

    return {
      ...(!isCheckbox && {
        border: isStrikeThrough
          ? `${strokeWidth}px solid rgb(${strokeColor?.r}, ${strokeColor?.g}, ${strokeColor?.b})`
          : fieldBorder,
      }),

      background:
        (isHighlighted && !isStrikeThrough) || isGroupField ? undefined : color?.background,
      ...(isGroupField && {
        padding: `${GROUP_PADDING}px`,
        borderRadius: '6px',
        backgroundColor: 'transparent',
      }),
    };
  };

  return (
    <Rnd
      name="Form-Field"
      {...getResizeProps(isStrikeThrough)}
      default={{
        x,
        y,
        width,
        height,
      }}
      className={classNames(
        styles.fieldContainer,
        {
          [styles.highlightedField]: isHighlighted,
          [styles.strikeThroughFieldContainer]: isStrikeThrough,
          [styles.radioRequired]: field.required && isGroupField && !isHighlighted,
          [styles.highlightedRadioRequired]: field.required && isGroupField && isHighlighted,
        },
        'prevent-user-select',
      )}
      position={{ x, y }}
      size={{ height, width }}
      minHeight={isStrikeThrough ? 0 : isGroupField ? height : 20}
      minWidth={isGroupField ? width : 20}
      style={getFieldStyle()}
      onResizeStart={() => {
        if (isGroupField) return;
        handleHighLightField();
      }}
      onDragStart={() => {
        setIsDragging(true);
        handleHighLightField();
      }}
      bounds="parent"
      onDragStop={(e, d) => {
        const { x, y } = d;

        const updatedX = x / scale;
        const updatedY = y / scale;
        const isSame = updatedX === field.x && updatedY === field.y;

        setIsDragging(false);
        if (isGroupField && isSame) return;

        handleFieldUpdate({
          x: updatedX,
          y: updatedY,
          ...(isGroupField && field.id && { groupDragged: true }),
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        if (isGroupField) return;

        const parsedWidth = parseInt(ref.style.width) / scale;
        const parsedHeight = parseInt(ref.style.height) / scale;

        const { width, height } = getWidthHeight(parsedWidth, parsedHeight);

        handleFieldUpdate({
          width,
          height,
          x: position.x / scale,
          y: position.y / scale,
        });
      }}
      dragGrid={[1, 1]}
      resizeGrid={[1, 1]}
      lockAspectRatio={isCheckbox}
      resizeHandleClasses={{
        ...(!isGroupField && {
          bottomLeft: grabPointStyle,
          bottomRight: grabPointStyle,
          topLeft: grabPointStyle,
          topRight: grabPointStyle,
          ...(!isCheckbox && {
            top: midGrabPointStyle,
            bottom: midGrabPointStyle,
            left: midGrabPointStyle,
            right: midGrabPointStyle,
          }),
        }),
      }}
      disableDragging={isDisabledDrag}
      onTouchStart={(e) => {
        if (!e.target.id.includes('field')) {
          setIsDisabledDrag(true);
        } else {
          isDisabledDrag && setIsDisabledDrag(false);
        }
      }}
    >
      {!isStrikeThrough && (
        <FormEditorField
          isDragging={isDragging}
          role={role}
          color={color}
          scale={scale}
          documentIndex={documentIndex}
          setIsDisabledDrag={setIsDisabledDrag}
          handleFieldUpdate={handleFieldUpdate}
          isTemplate={isTemplate}
          field={{
            ...field,
            width,
            isHighlighted,
            ...(isGroupField && {
              height,
              groupOptionUUID: selectedField?.groupOptionUUID,
              groupBorderWidth: 2.5,
            }),
          }}
          fieldItemProps={{
            field,
            answer,
            responses,
            isTemplate,
            isHighlighted,
            documentIndex,
            isCohesiveFlow,
            isSequentialDisabled,
            onFieldUpdate: handleFieldUpdate,
            setDisableDrag: setIsDisabledDrag,
            onUpdateAnswer: setDocumentAnswers,
            onFieldClick: handleHighLightField,
            otherSignatoryField: isOtherSignatoryField,
            disabled: isTemplate && signatureFieldType.includes(field.type),
          }}
        />
      )}

      <ActionButtons
        field={field}
        color={color}
        popupRef={popupRef}
        isTemplate={isTemplate}
        updateAnswer={setDocumentAnswers}
        responses={responses}
        documentIndex={documentIndex}
        onCopy={handleFieldCopy}
        selectedField={selectedField}
        isHighlighted={isHighlighted}
        onDeleteField={handleFieldDelete}
        fieldActionProps={fieldActionProps}
        handleFieldUpdate={handleFieldUpdate}
        setIsDisabledDrag={setIsDisabledDrag}
        onRequiredField={handleFieldRequired}
        onInserClauseClick={toggleClauseModal}
        selectedFieldIndex={selectedFieldIndex}
        canChangeRole={canChangeRole(field.type, answer)}
        isRequiredPopupVisible={isRequiredPopupVisible}
        onRequiredFieldPopupClick={handleFieldRequiredPopupClick}
      />

      <InsertClauseModal
        isOpen={openClauseModal}
        onClose={toggleClauseModal}
        onSelectClause={(clause) => {
          const existingValue = answer;
          const clauseItem = `${existingValue || ''}${
            existingValue ? '\n' + clause.Description : clause.Description
          }`;

          toggleClauseModal();

          const questionUUID = field.UUID;
          if (!field.id) {
            handleFieldUpdate({
              fieldAnswer: { answer: clauseItem, UUID: questionUUID, fontSize: 12, documentIndex },
            });
          } else {
            const documentAnswers = { ...responses };
            documentAnswers[questionUUID].Answer = clauseItem;
            setDocumentAnswers(documentAnswers);

            dynamicManager.handleQuestionResponse(
              questionUUID,
              clauseItem,
              documentIndex,
              12,
              false,
              true,
            );
          }
        }}
      />
    </Rnd>
  );
};
