export const Repeat = ({ stroke = '', className = '' }) => {
  return (
    <div className={className} style={{ display: 'flex' }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66135 9.69151C3.0526 10.9266 3.86 11.9878 4.94598 12.6944C6.03196 13.4009 7.32932 13.7091 8.61702 13.5663C9.90472 13.4235 11.1031 12.8387 12.0079 11.9114C12.9127 10.9841 13.4681 9.77179 13.5792 8.48098C13.6904 7.19017 13.3506 5.90074 12.6177 4.8324C11.8847 3.76405 10.804 2.98289 9.55973 2.62201C8.31542 2.26114 6.98447 2.34288 5.79366 2.8533C4.60286 3.36373 3.62587 4.27126 3.02918 5.42126"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.3999 2.80078V6.40078H5.1999"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
