import { Link, useLocation } from 'react-router-dom';
import { Footer as AntFooter, BasicProps } from 'antd/lib/layout/layout';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const Footer = ({ className, ...props }: BasicProps) => {
  const location = useLocation();

  return (
    <div data-testid="footer" className={classNames(className, styles.footerContainer)}>
      <AntFooter
        className={classNames(styles.wrapper, {
          [styles.wrapperBackgroundAppointment]:
            location.pathname.includes('/showing/appointment') &&
            !location.pathname.includes('/feedback'),
        })}
        {...props}
      >
        <span className={styles.links}>
          <ul>
            <li>
              <Link to={{ pathname: 'https://www.mosaik.io/privacy-policy' }} target="_blank">
                Privacy
              </Link>
            </li>
            <li>
              <Link to={{ pathname: 'https://www.mosaik.io/terms-of-service' }} target="_blank">
                Terms
              </Link>
            </li>
          </ul>
        </span>
        <span className={styles.copyRight}>
          <span>&copy; {new Date().getFullYear()} Mosaik, Inc. All rights reserved</span>
        </span>
      </AntFooter>
    </div>
  );
};
