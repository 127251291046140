import { ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';

export const fillFormError = 'Please add/select at least one user for each of the fields';
export const signFormError = 'Please select all of the roles shown below';

export const termsAgreementStatement =
  'I agree to the Terms & Conditions and the use of e-signatures.';

interface HistoryLog {
  text: string;
  hideUser?: boolean;
  textBold?: boolean;
  showUserInfo?: boolean;
  iconVariant?: string;
  checkOwner?: boolean;
}

export const historyActionSentences = {
  ['Created']: { text: 'created the form' },
  ['Copied']: { text: 'copied the form' },
  ['Updated']: { text: 'updated the form' },
  ['Signed']: { text: 'signed the form' },
  ['Voided']: { text: 'voided the form' },
  ['Signature_Decline']: { text: 'declined the form signature' },
  ['Sent_For_Editing']: { text: 'sent the form for editing ' },
  ['Sent_For_Signing']: {
    text: 'sent the form for signing ',
    showUserInfo: true,
    checkOwner: true,
  },
  ['Viewed_For_Editing']: { text: 'viewed the document for editing' },
  ['Viewed_For_Signing']: { text: 'viewed the document for signing' },
  ['Canceled']: { text: 'canceled the form' },
  ['Unlocked']: { text: 'unlocked the form' },
  ['Completed']: {
    text: 'Form is complete',
    iconVariant: ICON_VARIANT_TYPE.COMPLETED_FORM_HISTORY,
    textBold: true,
  },
  ['Deferred']: { text: 'deferred question(s)' },
} as { [key: string]: HistoryLog | undefined };
