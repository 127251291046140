import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import bulletsSVG from './bulletin.svg';
import boldSVG from './bold.svg';
import italicPNG from './italic.svg';
import underlinePNG from './underline.svg';

import styles from './styles.module.scss';

export const RichTextEditor = ({
  value,
  placeholder = '',
  onChange,
  disabled = false,
  className = '',
}) => {
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    if (value) {
      const html = value;
      const contentBlock = htmlToDraft(html);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  return (
    <div className={classNames(styles.richTextEditor, className)}>
      <Editor
        editorState={editorState}
        wrapperClassName={classNames(styles.wrapper, className)}
        editorClassName={styles.editor}
        onEditorStateChange={onEditorStateChange}
        readOnly={disabled}
        toolbarHidden={disabled}
        toolbar={{
          options: ['list', 'inline'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
            bold: {
              icon: boldSVG,
            },
            italic: {
              icon: italicPNG,
            },
            underline: {
              icon: underlinePNG,
            },
          },
          list: {
            inDropdown: false,
            options: ['unordered'],
            unordered: {
              icon: bulletsSVG,
            },
          },
        }}
      />
    </div>
  );
};
