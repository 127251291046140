import { formatDate } from 'helpers';
import { DocumentOptions, PdfThumbnail } from './../';
import { PdfViewModal, Wrapper } from 'components';

import styles from './styles.module.scss';
import { useState } from 'react';

export const PropertyDocumentCard = ({
  file,
  Property = {},
  isPreapprovals = false,
  isArchives = false,
  refetch,
  viewType,
  isMisc = false,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  return (
    <div className={styles.propertyDocumentCard}>
      {isPreviewOpen && (
        <PdfViewModal
          isOpen={isPreviewOpen}
          file={file}
          onClose={() => setIsPreviewOpen(false)}
          showInCohesiveFlow={true}
        />
      )}
      <div className={styles.pdfCard} onClick={() => setIsPreviewOpen(true)}>
        <PdfThumbnail
          documentVaultUUID={file?.DocumentVaultUUID || file?.DocumentLink}
          size={file?.OriginalContentLength}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <p className={styles.docName}>{file?.Title || file?.Filename || file?.FormName}</p>
          <p className={styles.clientName}>
            {file?.UploaderFirstName} {file?.UploaderLastName}
          </p>
          <p className={styles.date}>{formatDate(file?.CreatedDate, 'M/D/YYYY')}</p>
        </div>
        <div className={styles.right}>
          <DocumentOptions
            file={file}
            Property={Property}
            isPreapprovals={isPreapprovals}
            isMisc={isMisc}
            isArchives={isArchives}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  );
};
