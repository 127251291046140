import styles from './styles.module.scss';
import { Modal } from 'components-antd';
import { Button, Avatar } from 'components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Info } from 'components/Icons';

type UserFoundModalPropsType = {
  open: boolean;
  userFound;
  cancelHandler: Function;
  viewProfileHandler: Function;
};

const UserFoundModal = (props: UserFoundModalPropsType) => {
  const { open, userFound, cancelHandler, viewProfileHandler } = props;
  const [isSimpleUSer, SetIsSimpleUser] = useState<boolean>(false);

  useEffect(() => {
    if (userFound && !userFound.ThirdParty?.Partner) SetIsSimpleUser(true);
  }, [userFound]);

  const getPlaceholder = (): string => {
    let placeHolder = '-';

    if (userFound?.ThirdParty?.Partner?.BusinessName) {
      const splitted = userFound?.ThirdParty?.Partner?.BusinessName.split(' ');
      placeHolder = splitted[0][0];

      if (splitted.length > 1) placeHolder += splitted[1][0];
    } else if (userFound?.FirstName) {
      placeHolder = userFound?.FirstName[0];

      if (userFound?.LastName) placeHolder += userFound?.LastName[0];
    }

    return placeHolder;
  };

  const getBusinessName = (): string => {
    let businessName = '-';

    if (userFound?.ThirdParty?.Partner?.BusinessName)
      businessName = userFound?.ThirdParty?.Partner?.BusinessName;
    else if (userFound?.FirstName) {
      businessName = userFound?.FirstName;

      if (userFound?.LastName) businessName += ` ${userFound?.LastName}`;
    }

    return businessName;
  };

  return (
    <Modal
      open={open}
      closable={false}
      footer={null}
      zIndex={2000}
      width={420}
      className={styles.userFoundModal}
    >
      <div className={styles.userFound}>
        {isSimpleUSer ? (
          <Info color="#ec455e" />
        ) : (
          <>
            <Avatar
              src={userFound?.ThirdParty?.Partner?.LogoUrl}
              placeholder={getPlaceholder()}
              avatarClassName={styles.userFoundAvatar}
              className={styles.avatarWrapper}
            />
            <div className={styles.userFoundHeader}>{getBusinessName()}</div>
          </>
        )}
        <div className={styles.userFoundText}>
          {isSimpleUSer
            ? 'The entered email address already exists.'
            : 'This vendor is already in Mosaik.'}
        </div>
        <div
          className={styles.userFoundFooter}
          style={{ justifyContent: isSimpleUSer ? 'center' : 'space-between' }}
        >
          {isSimpleUSer ? (
            <Button
              testid="saveDraft"
              type="submit"
              className={classNames(styles.button, styles.buttonView)}
              title={'Ok'}
              onClick={() => {
                SetIsSimpleUser(false);
                cancelHandler();
              }}
            />
          ) : (
            <>
              <Button
                testid="cancelDraft"
                type="submit"
                className={classNames(styles.button, styles.buttonCancel)}
                title={'Cancel'}
                onClick={() => {
                  cancelHandler();
                }}
              />
              <Button
                testid="saveDraft"
                type="submit"
                className={classNames(styles.button, styles.buttonView)}
                title={'View Profile'}
                onClick={() => {
                  viewProfileHandler(userFound?.Id);
                }}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UserFoundModal;
