import { DraggableCombineMenuList, DraggableCombineMenuListItem, Modal } from 'components-antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { Plus, RemoveFile } from '../../../../../../../../components/Icons';

import AutoComplete from 'antd/lib/auto-complete';
import { caseInsensitiveFilter, showSuccessMessage } from '../../../../../../../../helpers';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormSelector,
  getFormMetaSelector,
} from '../../../../../../../../store/selectors/requestFormProcess';
import {
  getDynamicFormDocumentAuthEffect,
  getFormMetaEffect,
  getSignatoriesEffect,
  updateSignatoriesEffect,
} from '../../../../../../../../store/effects/formProcess';

import { dynamicManager } from '../../../../../DynamicManager';
import { isEmail } from '../../../../../../../../utils';
import { incorrectEmail } from '../../../../../../../../app-constants';
import { FetchSignatoryType, SignatoryType } from '../../../../../../../../types';
import classNames from 'classnames';
import { Navigation } from '../../../../../../components/PreForm/components';
import { uniqBy } from 'lodash-es';

import { Spinner } from 'components';
import { prepareEditorConfig } from 'store/reducers/requestFormProcess/helper';
import { getQuestionsByRole } from './helper';
import { updateDynamicFormAction } from 'store/actions/formProcess';
import { v4 as uuidv4 } from 'uuid';
import { isValidEmail, sortOptions, validationToast } from 'pages/FormProcess/helper';

export const EditSignatoryModal = ({ open, onClose, refetchSignatories }) => {
  const [signatories, setSignatories] = useState<SignatoryType[]>([]);
  const [data, setData] = useState<FetchSignatoryType>();

  const { clients, agents } = useSelector(getFormMetaSelector);
  const signatoriesList = useMemo(() => [...(agents || []), ...(clients || [])], [clients, agents]);

  const dynamicForm = useSelector(getDynamicFormSelector);
  const { questions } = useSelector(getDynamicFormEditorConfigSelector);

  const [deletedSignatories, setDeletedSignatories] = useState<SignatoryType[]>([]);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  const [processing, setProcessing] = useState<boolean>(true);

  const dispatch = useDispatch();

  const [signatoryOptions, setSignatoryOptions] = useState(
    signatoriesList.map((signatory) => ({
      key: signatory.Id,
      label: `${signatory.FirstName} ${signatory.LastName}`,
      value: `${signatory.FirstName} ${signatory.LastName}`,
    })),
  );

  useEffect(() => {
    if (signatoriesList.length) {
      const selectedOptions = signatories
        .map((value) => value.Name && (value.UserId || value.userId))
        .filter((item) => item);

      const filteredOptions = signatoriesList.filter((item) => !selectedOptions.includes(item.Id));

      setSignatoryOptions(
        filteredOptions.map((signatory) => ({
          key: signatory.Id,
          label: `${signatory.FirstName} ${signatory.LastName}`,
          value: `${signatory.FirstName} ${signatory.LastName}`,
        })),
      );
    }
  }, [signatoriesList, signatories]);

  const fetchSignatoryDetails = async (formProcessId) => {
    try {
      setProcessing(true);

      const data: any = await dispatch(
        getSignatoriesEffect({
          formProcessPublicId: formProcessId,
        }),
      );

      const { value } = data.data;
      setData({ ...value });
      setSignatories(value.signatoryParticipants);

      await dispatch(getFormMetaEffect());

      setProcessing(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let formProcessId: string;

    try {
      formProcessId = dynamicManager?.getBundleData()?.formProcessPublicId;
    } catch (err) {
      formProcessId = dynamicManager?.getAuthData()?.formProcessPublicId;
    }

    if (open) {
      fetchSignatoryDetails(formProcessId);
    }
  }, [open]);

  const handleOnBlur = (signatoryIndex: number, smartForm = false) => {
    const signatory = signatories?.[signatoryIndex];

    if (signatory) {
      let updatedSignatories: SignatoryType[] = [...signatories];

      if (smartForm) {
        let isCustomName = !!signatory.Name?.trim().length && !signatory.UserId;

        if (isCustomName) {
          updatedSignatories[signatoryIndex].customName = true;
          updatedSignatories[signatoryIndex].isReplaced = !!signatory?.signatoryId;

          if (signatory?.signatoryId) {
            updatedSignatories[signatoryIndex].Email = '';
          }
        }
      } else if (signatory.Email && !isValidEmail(signatory.Email.trim())) {
        updatedSignatories[signatoryIndex].Email = '';
        validationToast(incorrectEmail);
      }

      setSignatories(updatedSignatories);
    }
  };

  const handleSignatoriesSelect = (value: string, index, option) => {
    const userId = option.key;
    const signatory = signatories?.[index];
    const details = signatoriesList.find((sig) => sig.Id === userId);
    if (signatory) {
      const updatedSignatories = [...signatories];
      const signatoryData: SignatoryType = {
        ...signatory,
        isReplaced: !!signatory?.signatoryId,
        UserId: userId,
        Name: value,
        Email: details?.Email,
      };

      signatoryData.customName && delete signatoryData.customName;

      updatedSignatories[index] = { ...signatoryData };

      const _deleted = deletedSignatories.filter(
        ({ UserId, userId }) => (userId || UserId) !== details?.Id,
      );

      setDeletedSignatories(_deleted);
      setSignatories(updatedSignatories);
    }
  };

  const handleSignatoriesChange = (
    key: string,
    value: string,
    index: number,
    smartForm = false,
  ) => {
    const signatory = signatories?.[index];

    if (signatory) {
      if (signatory.UserId) {
        const isExist = signatoryOptions.find(({ key }) => key === signatory.UserId);
        if (!isExist) {
          signatoryOptions.push({
            key: signatory.UserId,
            label: signatory.Name as string,
            value: signatory.Name as string,
          });
          setSignatoryOptions(signatoryOptions);
        }
      }

      const updatedSignatories = [...signatories];
      const signatoryData = {
        ...signatory,
        [key]: value,
      };

      signatoryData.UserId && delete signatoryData.Email;
      delete signatoryData.UserId;

      // if (smartForm && !value?.trim().length) {
      //   delete signatoryData.customName;
      //   delete signatoryData.Email;
      // }

      updatedSignatories[index] = { ...signatoryData };
      setSignatories(updatedSignatories);
    }
  };

  useEffect(() => {
    if (signatories?.some((signer) => signer.Email === '' && signer.Name)) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [signatories]);

  const handleRoleDelete = (signatoryIndex = -1, userId = -1) => {
    // Setting item back which got filtered to not show in list, so it will be shown again
    const listItem = signatoriesList.find((item) => item.Id === userId);

    if (listItem || userId !== -1) {
      if (listItem) {
        const { Id, FirstName, LastName } = listItem;

        const optionItem = {
          key: Id,
          label: `${FirstName} ${LastName}`,
          value: `${FirstName} ${LastName}`,
        };

        setSignatoryOptions((prev) => [...prev, optionItem]);
      }

      if (deletedSignatories?.length) {
        setDeletedSignatories([...deletedSignatories, signatories[signatoryIndex]]);
      } else {
        setDeletedSignatories([signatories[signatoryIndex]]);
      }
    }

    if (data?.isSequentialSigning) {
      setSignatories(
        [...signatories.filter((signer, index) => index !== signatoryIndex)].map((signer, idx) => {
          const oldSequence = signer.sequence as number;
          if (oldSequence - 1 !== idx) {
            signer.sequence = oldSequence - 1;
          }
          return signer;
        }),
      );
    } else setSignatories([...signatories.filter((signer, index) => index !== signatoryIndex)]);
  };

  const getNumberTag = (num: number) => {
    if (!data?.isSequentialSigning) return;
    return <div className={styles.numberTag}>{num}</div>;
  };

  const renderFormSignatory = (item: DraggableCombineMenuListItem) => {
    const signatoryIndex = signatories?.findIndex((signatory) => signatory.RoleId === item?.id);
    const signatory = signatories?.[signatoryIndex];

    return (
      <>
        <div className={classNames(styles.checkboxRow)} key={item.id}>
          {getNumberTag(item.sequence)}
          <div className={styles.field}>
            <label>Signer {signatoryIndex + 1}</label>
          </div>
          <AutoComplete
            placeholder={'Enter name or email...'}
            bordered={false}
            className={styles.autoCompleteInput}
            value={signatory?.Name}
            options={sortOptions(signatoryOptions, 'label')}
            filterOption={(inputValue, option) =>
              caseInsensitiveFilter(inputValue, option?.label as string)
            }
            onBlur={() => handleOnBlur(signatoryIndex, true)}
            onSelect={(value, option) => handleSignatoriesSelect(value, signatoryIndex, option)}
            onChange={(value) => handleSignatoriesChange('Name', value, signatoryIndex, true)}
            allowClear
            getPopupContainer={(node) => node}
            popupClassName={styles.autoCompleteDropdown}
          />

          {signatory?.customName ? (
            <div className={styles.field}>
              <Input
                className={styles.email}
                placeholder={'Enter email'}
                value={signatory.Email}
                disabled={!!signatory.UserId}
                onBlur={() => handleOnBlur(signatoryIndex)}
                onChange={({ target }) =>
                  !signatory?.UserId
                    ? handleSignatoriesChange('Email', target.value, signatoryIndex)
                    : null
                }
              />
            </div>
          ) : (
            <></>
          )}

          {signatories.length > 1 && (
            <div className={styles.removeButton}>
              <RemoveFile
                onClick={() => {
                  handleRoleDelete(signatoryIndex, signatory?.Id);
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const addNewSignatory = () => {
    const sequence = signatories.length
      ? signatories[signatories.length - 1].SignatureSequence + 1
      : 1;

    setSignatories([
      ...signatories,
      {
        RoleId: uuidv4(),
        Email: '',
        Name: '',
        SignatureSequence: sequence,
        sequence: sequence,
      },
    ]);
  };

  const onSequenceUpdate = (updatedSigners: DraggableCombineMenuListItem[]) => {
    if (signatories?.length) {
      setSignatories(
        signatories.map((signer) => {
          const updated = { ...signer };
          const item = updatedSigners.find((item) => item.id === signer.FormRole);
          if (item?.id) {
            updated.SignatureSequence = item.sequence;
          }

          return updated;
        }),
      );
    }
  };

  const getInitialItems = () =>
    signatories.map((signer) => ({
      id: signer.RoleId,
      sequence: signer.sequence,
    })) as DraggableCombineMenuListItem[];

  const items = useMemo(() => getInitialItems(), [signatories]);

  const updateSignatories = useCallback(() => {
    let formProcessId: string;

    try {
      formProcessId = dynamicManager?.getBundleData()?.formProcessPublicId;
    } catch (err) {
      formProcessId = dynamicManager?.getAuthData()?.formProcessPublicId;
    }

    const signatoryParticipantsEmails = data?.signatoryParticipants.map(({ Email }) => Email);
    const isSequential = data?.isSequentialSigning;

    let payload = signatories
      .filter((signer) => !signatoryParticipantsEmails?.includes(signer?.Email))
      .map((signatory) => ({
        SignatureSequence: !isSequential ? 1 : signatory.SignatureSequence ?? signatory?.sequence,
        UserId: signatory?.UserId,
        Email: signatory?.Email ?? signatory?.email,
        roleName: signatory.Name ? signatory.Name.replace(' (Me)', '') : signatory?.customName,
        isReplaced: signatory.isReplaced,
        OldSignerId: signatory.isReplaced ? signatory?.signatoryId : null,
      }));

    if (deletedSignatories?.length) {
      payload = uniqBy(
        [
          ...deletedSignatories.map((signatory) => ({
            SignatureSequence: !isSequential
              ? 1
              : signatory.SignatureSequence ?? signatory?.sequence,
            UserId: signatory?.UserId,
            Email: signatory?.Email ?? signatory?.email,
            roleName: signatory.Name ?? signatory?.customName,
            isDeleted: true,
            isReplaced: false,
            OldSignerId: null,
          })),
          ...payload,
        ],
        'Email',
      );
    }

    setLoading(true);

    dispatch(
      updateSignatoriesEffect(
        {
          formProcessPublicId: formProcessId,
          Signatories: payload,
        },
        (err, response) => {
          if (!err) {
            showSuccessMessage('Signatories updated successfully');
            refetchSignatories();
            reloadPage();
          }
        },
      ),
    );
  }, [signatories, data]);

  const reloadPage = () => {
    let formProcessId: string;
    let activeDocumentId: string;
    const isBundle = dynamicManager.isBundleForm();

    try {
      formProcessId = dynamicManager?.getBundleData()?.formProcessPublicId;
      activeDocumentId = dynamicManager.getActiveDocumentPublicId();
    } catch (err) {
      formProcessId = dynamicManager?.getAuthData()?.formProcessPublicId;
      activeDocumentId = dynamicManager?.getAuthData()?.formDocumentPublicId;
    }

    dispatch(
      getDynamicFormDocumentAuthEffect(
        {
          formProcessPublicId: formProcessId,
          formDocumentPublicId: activeDocumentId,
        },
        (error, response) => {
          if (!error) {
            if (isBundle) {
              setLoading(false);
              onClose();
            } else {
              const newEditorConfig: any =
                prepareEditorConfig(response.data.value, dynamicForm) || {};

              newEditorConfig.selectedFieldIndex = -1;

              newEditorConfig.questions = getQuestionsByRole(questions, newEditorConfig.formRoles);

              setTimeout(() => {
                dispatch(
                  updateDynamicFormAction({
                    editorConfig: newEditorConfig,
                  }),
                );
                setLoading(false);
                onClose();
              }, 300);
            }
          } else {
            setLoading(false);
          }
        },
      ),
    );
  };

  const resetData = () => {
    setSignatories([]);
    setDeletedSignatories([]);
  };

  return (
    <Modal
      open={open}
      width={675}
      footer={
        <Navigation
          buttonText={'Save'}
          onContinue={updateSignatories}
          className={styles.saveSignatoryButton}
          disabled={disabledSave || loading}
          loading={loading}
        />
      }
      onCancel={() => !loading && onClose()}
      maskClosable={false}
      destroyOnClose
      afterClose={resetData}
      className={styles.editSignatoryModal}
    >
      <div testid="header_title" className={styles.heading}>
        Edit Signatories
      </div>

      {signatories.length && !processing ? (
        <div className={styles.pageContent}>
          <DraggableCombineMenuList
            initialItems={items}
            onItemUpdate={onSequenceUpdate}
            renderFunction={renderFormSignatory}
            isDisabled={!data?.isSequentialSigning}
            isCombinedEnabled={false}
          />

          {signatories.length ? (
            <div className={styles.add} onClick={addNewSignatory}>
              <Plus color="#515151" className={styles.addIcon} />
              <div className={styles.addText}>Add Another</div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
