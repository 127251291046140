import MozziePNG from './Mozzie.png';

import styles from './styles.module.scss';

export const Message = ({ userMsg, responseFromAI, s3FilePath }) => {
  return (
    <div className={styles.messageContainer}>
      <div className={styles.userMessageContainer}>
        <div className={styles.userMessage}>
          <span>{userMsg}</span>
        </div>
      </div>
      <div className={styles.aiResponseContainer}>
        <img src={MozziePNG} alt="mozzieRobot" />
        <div className={styles.aiMessage}>
          <span>{responseFromAI}</span>
          {s3FilePath && (
            <div className={styles.reportLinks}>
              <a href={s3FilePath}>Download CSV</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
