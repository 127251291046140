import PropTypes from 'prop-types';

import { Favorite as CommonFavorite } from 'components';

const Favorite = (props) => {
  const { className, propertyInfo, isFavProperty = false } = props;

  return (
    <CommonFavorite propertyInfo={propertyInfo} className={className} isClient={isFavProperty} />
  );
};

Favorite.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    IsFavorite: PropTypes.bool,
  }).isRequired,
  isFavProperty: PropTypes.bool,
};

Favorite.defaultProps = {
  className: '',
};

export default Favorite;
