import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Minus, Plus } from 'components/Icons';
import { Col, Row, Checkbox } from 'components-antd';
import { InputLabel, DatePicker, Input } from 'components';
import { Select } from 'components';
import {
  REPEAT_EVERY_OPTIONS,
  WEEKLY_OPTIONS,
  REPEAT_DROPDOWN_OPTIONS,
  REPEAT_OPTIONS,
  WEEKLY_DROPDOWN_OPTIONS,
  REPEAT_EVERY_DROPDOWN_OPTIONS,
} from './constants';
import { formatDate } from 'helpers';
import {
  listedStatusesArray,
  listedStatusesForHideClose,
} from 'settings/constants/transactionStatus';

import { getTransactionSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';
import { transactionRoles } from 'settings/constants/roles';

enum CHECKBOXES_OPTIONS {
  CLOSING_DATE = 'CLOSING_DATE',
  LISTING_EXPIRATION = 'LISTING_EXPIRATION',
  CUSTOM_DATE = 'CUSTOM_DATE',
  OCCURANCES = 'OCCURANCES',
}

export const Repeat = ({
  task,
  isTemplate,
  isProjectTask,
  onChangeManyFields,
  className = '',
  isViewMode,
  isBusinessDays,
}) => {
  const [repeat, setRepeat] = useState(REPEAT_OPTIONS.DAILY);
  const [repeatEveryCounter, setRepeatEveryCounter] = useState(1);
  const [customRepeat, setCustomRepeat] = useState(REPEAT_EVERY_OPTIONS.WEEK);
  const [repeatOn, setRepeatOn] = useState(WEEKLY_OPTIONS.MONDAY);
  const [repeatUntilDate, setRepeatUntilDate] = useState<any>();
  const [endsCheckboxOption, setEndsCheckboxOption] = useState(CHECKBOXES_OPTIONS.CLOSING_DATE);
  const [occuranceCount, setOccuranceCount] = useState<number | null>(null);
  const { transaction } = useSelector(getTransactionSelector);

  const skipListed = !listedStatusesArray.includes(transaction?.Status);
  const listedStatuses = listedStatusesForHideClose.includes(transaction?.Status);

  const isBuyerTransaction = useMemo(() => {
    return (
      transaction?.RepresentingRoles?.length < 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.BUYER)
    );
  }, [transaction?.RepresentingRoles]);

  const showListingExpiryCheckbox = useMemo(
    () => (isTemplate && !isProjectTask) || listedStatuses,
    [],
  );

  const showClosingCheckbox = useMemo(
    () => isTemplate || skipListed || isBuyerTransaction,
    [isBuyerTransaction],
  );

  useEffect(() => {
    setupDefaultValues();
  }, []);

  const setupDefaultValues = () => {
    if (!task?.RepeatOption)
      onChangeManyFields({
        RepeatOption: REPEAT_OPTIONS.DAILY,
        EndsOnClosing: showClosingCheckbox,
        EndsOnListingExpiration: showListingExpiryCheckbox,
      });

    setRepeat(task?.RepeatOption || REPEAT_OPTIONS.DAILY);
    setRepeatEveryCounter(task?.RepeatCount || 1);
    setCustomRepeat(task?.CustomRepeatOption || REPEAT_EVERY_OPTIONS.WEEK);
    setRepeatOn(task?.RepeatOn || WEEKLY_OPTIONS.MONDAY);
    setRepeatUntilDate(task?.RepeatUntilDate);
    setOccuranceCount(task?.RepeatOccurrence);
    setEndsCheckboxOption(
      task?.EndsOnClosing
        ? CHECKBOXES_OPTIONS.CLOSING_DATE
        : task?.EndsOnListingExpiration
        ? CHECKBOXES_OPTIONS.LISTING_EXPIRATION
        : task?.RepeatUntilDate
        ? CHECKBOXES_OPTIONS.CUSTOM_DATE
        : task?.RepeatOccurrence
        ? CHECKBOXES_OPTIONS?.OCCURANCES
        : showClosingCheckbox
        ? CHECKBOXES_OPTIONS.CLOSING_DATE
        : CHECKBOXES_OPTIONS.LISTING_EXPIRATION,
    );
  };

  const onMinus = () => {
    if (isViewMode) return;

    if (repeatEveryCounter > 1) {
      setRepeatEveryCounter((value) => {
        const updatedValue = value - 1;
        onChangeManyFields({ RepeatCount: updatedValue });
        return updatedValue;
      });
    }
  };

  const onAdd = () => {
    if (isViewMode) return;

    setRepeatEveryCounter((value) => {
      const updatedValue = value + 1;
      onChangeManyFields({ RepeatCount: updatedValue });
      return updatedValue;
    });
  };

  const getWeeklyDropdonOptions = () => {
    // Exclude weekends when business days is selected
    if (isBusinessDays)
      return WEEKLY_DROPDOWN_OPTIONS.filter(
        (item) => item.value !== WEEKLY_OPTIONS.SATURDAY && item.value !== WEEKLY_OPTIONS.SUNDAY,
      );

    return WEEKLY_DROPDOWN_OPTIONS;
  };

  const handleEndsCheckbox = (e, option) => {
    if (e.target.checked) {
      setEndsCheckboxOption(option);
      onChangeManyFields({
        EndsOnClosing: option === CHECKBOXES_OPTIONS.CLOSING_DATE,
        EndsOnListingExpiration: option === CHECKBOXES_OPTIONS.LISTING_EXPIRATION,
        ...(option === CHECKBOXES_OPTIONS.CLOSING_DATE
          ? { RepeatOccurrence: null, RepeatUntilDate: null }
          : {}),
        ...(option !== CHECKBOXES_OPTIONS.CUSTOM_DATE ? { RepeatUntilDate: null } : {}),
      });
    }
  };

  return (
    <div className={classNames(styles.repeatContainer, className)}>
      <InputLabel label="Repeat" className={styles.label} />
      <Select
        value={repeat}
        options={REPEAT_DROPDOWN_OPTIONS}
        variant={Select.LIGHT_ROUND}
        onSelect={(e) => {
          const value = e.target.value.value;
          setRepeat(value);
          onChangeManyFields({
            RepeatOption: value,
            ...(value === REPEAT_OPTIONS?.CUSTOM
              ? {
                  CustomRepeatOption: REPEAT_EVERY_OPTIONS.WEEK,
                  RepeatCount: 1,
                  RepeatOn: WEEKLY_OPTIONS.MONDAY,
                }
              : {
                  CustomRepeatOption: null,
                  RepeatCount: null,
                  RepeatOn: null,
                }),
          });
        }}
        disabled={isViewMode}
      />
      {repeat === REPEAT_OPTIONS.CUSTOM && (
        <div className={styles.customRepeat}>
          <InputLabel label="Repeat Every" className={styles.label} />
          <Row gutter={24}>
            <Col span={12}>
              <div className={styles.repeatCounter}>
                <Minus className={classNames(styles.counterIcon, styles.minus)} onClick={onMinus} />
                <span className={styles.counterText}>{repeatEveryCounter}</span>
                <Plus className={classNames(styles.counterIcon, styles.plus)} onClick={onAdd} />
              </div>
            </Col>
            <Col span={12}>
              <Select
                defaultValue={REPEAT_EVERY_OPTIONS.WEEK}
                value={customRepeat}
                options={REPEAT_EVERY_DROPDOWN_OPTIONS}
                variant={Select.LIGHT_ROUND}
                onSelect={(e) => {
                  const value = e.target.value.value;
                  setCustomRepeat(value);
                  onChangeManyFields({ CustomRepeatOption: value });
                }}
                disabled={isViewMode}
              />
            </Col>
          </Row>
          {customRepeat === REPEAT_EVERY_OPTIONS.WEEK && (
            <div className={styles.repeatOn}>
              <InputLabel label="Repeat On" className={styles.label} />
              <Select
                value={repeatOn}
                options={getWeeklyDropdonOptions()}
                variant={Select.LIGHT_ROUND}
                onSelect={(e) => {
                  const value = e.target.value.value;
                  setRepeatOn(value);
                  onChangeManyFields({ RepeatOn: value });
                }}
                disabled={isViewMode}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.endsContainer}>
        <p className={styles.title}>Ends</p>
        {showClosingCheckbox && (
          <div className={styles.checkContainer}>
            <Checkbox
              checked={endsCheckboxOption === CHECKBOXES_OPTIONS.CLOSING_DATE}
              value={endsCheckboxOption === CHECKBOXES_OPTIONS.CLOSING_DATE}
              onChange={(e) => handleEndsCheckbox(e, CHECKBOXES_OPTIONS.CLOSING_DATE)}
              disabled={isViewMode}
            >
              <span className={styles.label}>
                On {isProjectTask ? 'Project Deadline' : 'Closing Date'}
              </span>
            </Checkbox>
          </div>
        )}
        {showListingExpiryCheckbox && !isProjectTask && (
          <div className={styles.checkContainer}>
            <Checkbox
              checked={endsCheckboxOption === CHECKBOXES_OPTIONS.LISTING_EXPIRATION}
              value={endsCheckboxOption === CHECKBOXES_OPTIONS.LISTING_EXPIRATION}
              onChange={(e) => handleEndsCheckbox(e, CHECKBOXES_OPTIONS.LISTING_EXPIRATION)}
              disabled={isViewMode}
            >
              <span className={styles.label}>On Listing Expiration</span>
            </Checkbox>
          </div>
        )}

        {!isTemplate && (
          <div className={styles.checkContainer}>
            <Checkbox
              checked={endsCheckboxOption === CHECKBOXES_OPTIONS.CUSTOM_DATE}
              value={endsCheckboxOption === CHECKBOXES_OPTIONS.CUSTOM_DATE}
              onChange={(e) => handleEndsCheckbox(e, CHECKBOXES_OPTIONS.CUSTOM_DATE)}
              disabled={isViewMode}
            >
              <span className={styles.label}>On</span>
            </Checkbox>
            <div className={classNames(styles.datePicker)}>
              <DatePicker
                className={classNames(styles.templateControlPicker, {
                  [styles.disabledPicker]: endsCheckboxOption === CHECKBOXES_OPTIONS.CLOSING_DATE,
                })}
                variant="light"
                options={{
                  enableTime: false,
                  minDate: !isTemplate ? task?.DueDate || new Date() : new Date(),
                }}
                value={repeatUntilDate}
                onChange={(e, val) => {
                  const date = formatDate(val?.[0], 'M/DD/YYYY');
                  setRepeatUntilDate(date);
                  onChangeManyFields({ RepeatUntilDate: formatDate(val?.[0], 'YYYY-MM-DD') });
                }}
                testid="due_date"
                disabled={endsCheckboxOption !== CHECKBOXES_OPTIONS.CUSTOM_DATE || isViewMode}
                popoverCalender
                placement="top"
                getPopupContainer={(node) => node}
                modalDatePicker
                dateInputId="task-date-input"
              />
              <div
                className={classNames(styles.placeholderContainer, {
                  [styles.disabled]: endsCheckboxOption !== CHECKBOXES_OPTIONS.CUSTOM_DATE,
                })}
              >
                {repeatUntilDate ? formatDate(repeatUntilDate, 'M/DD/YYYY') : 'MM/DD/YYYY'}
              </div>
            </div>
          </div>
        )}
        <div className={classNames(styles.checkContainer, styles.occurances)}>
          <Checkbox
            checked={endsCheckboxOption === CHECKBOXES_OPTIONS.OCCURANCES}
            value={endsCheckboxOption === CHECKBOXES_OPTIONS.OCCURANCES}
            onChange={(e) => handleEndsCheckbox(e, CHECKBOXES_OPTIONS.OCCURANCES)}
            disabled={isViewMode}
          >
            <span className={styles.label}>After</span>
          </Checkbox>
          <Input
            variant={Input.LIGHT_ROUND}
            disabled={endsCheckboxOption !== CHECKBOXES_OPTIONS.OCCURANCES || isViewMode}
            placeholder="#"
            className={styles.inputField}
            type="number"
            value={occuranceCount}
            isNumberFormat
            minNumber={1}
            onChange={(e) => {
              setOccuranceCount(e.target.value);
              onChangeManyFields({ RepeatOccurrence: parseInt(e.target.value) });
            }}
          />
          <span
            className={classNames(styles.text, {
              [styles.disabled]: endsCheckboxOption !== CHECKBOXES_OPTIONS.OCCURANCES,
            })}
          >
            Occurrences
          </span>
        </div>
      </div>
    </div>
  );
};
