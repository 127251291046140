import { Icons } from './../../Task/ViewRevamped/Icons';
import styles from './styles.module.scss';

interface itemProps {
  onClick: () => void;
  title: string;
  iconVariant: string;
}

export const MoreOptionItem = ({ onClick, title, iconVariant }: itemProps) => {
  return (
    <div className={styles.addOptionDiv} onClick={onClick}>
      <Icons variant={iconVariant} />
      <span>{title}</span>
    </div>
  );
};
