import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getActiveSearchAndGroupedClientsList,
  getRetainedSearchAndGroupedClientsList,
  getInactiveSearchAndGroupedClientsList,
  getDeclinedSearchAndGroupedClientsList,
  getPendingSearchAndGroupedClientsList,
  getConnectionTypeSelector,
  getActiveSearchAndGroupedTeamList,
  getPendingSearchAndGroupedTeamList,
  getInactiveSearchAndGroupedTeamList,
  getActiveSearchAndGroupedCollaboratorsList,
  getInactiveSearchAndGroupedCollaboratorsList,
  getPendingSearchAndGroupedCollaboratorsList,
  getDeclinedSearchAndGroupedCollaboratorsList,
  getConnectionsSearchValue,
  getAllSearchAndGroupedClientsList,
  getAllSearchAndGroupedCollaboratorsList,
  getAllSearchAndGroupedTeamList,
} from 'store/selectors/app';
import { getStateAndDataFlag } from 'store/selectors/clientsList';
import { READY } from 'settings/constants/apiState';
import SectionNameItem from '../SectionNameItem';
import ListItem from '../ListItem';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';

const ClientsList = ({ tabLabel }) => {
  const { state, isData } = useSelector(getStateAndDataFlag);
  const connectionType = useSelector(getConnectionTypeSelector);
  const searchValue = useSelector(getConnectionsSearchValue);
  const connectionsList = useSelector(
    searchValue
      ? ClientsList.CONNECTION_SELECTORS[connectionType].Aggregated
      : ClientsList.CONNECTION_SELECTORS[connectionType][tabLabel],
  );
  const sortedSectionNames = useMemo(() => Object.keys(connectionsList).sort(), [connectionsList]);

  const getConnectionRole = (role) =>
    connectionType === AGENT_CONNECTIONS.Collaborators ? TransactionUserRoleMap[role] : role;

  return (
    <>
      {sortedSectionNames.length > 0 ? (
        sortedSectionNames.map((letter, idx) => (
          <div key={idx}>
            <SectionNameItem letter={letter} />
            {connectionsList[letter].map(
              ({
                name,
                role,
                teamRole,
                sendDate,
                sendStatus,
                id,
                searchInstances,
                transactionInstances,
                listType,
              }) => (
                <ListItem
                  connectionName={name}
                  connectionRole={getConnectionRole(role)}
                  teamRole={teamRole}
                  activeTabName={tabLabel}
                  sendDate={sendDate}
                  sendStatus={sendStatus}
                  key={id}
                  connectionId={id}
                  searchInstances={searchInstances}
                  transactionInstances={transactionInstances}
                  listType={listType}
                  testid="connection_item"
                />
              ),
            )}
          </div>
        ))
      ) : state === READY && isData ? (
        <div className={styles.noClient}>
          <p className={styles.noClientsText}>
            {`You have no ${tabLabel.toLowerCase()} ${
              connectionType === AGENT_CONNECTIONS.Team
                ? 'team members'
                : connectionType.toLowerCase()
            }.`}
          </p>
        </div>
      ) : null}
    </>
  );
};

ClientsList.CONNECTION_SELECTORS = {
  [AGENT_CONNECTIONS.Clients]: {
    Active: getActiveSearchAndGroupedClientsList,
    Retention: getRetainedSearchAndGroupedClientsList,
    Pending: getPendingSearchAndGroupedClientsList,
    Declined: getDeclinedSearchAndGroupedClientsList,
    Inactive: getInactiveSearchAndGroupedClientsList,
    Aggregated: getAllSearchAndGroupedClientsList,
  },
  [AGENT_CONNECTIONS.Collaborators]: {
    Active: getActiveSearchAndGroupedCollaboratorsList,
    Pending: getPendingSearchAndGroupedCollaboratorsList,
    Declined: getPendingSearchAndGroupedCollaboratorsList,
    Inactive: getDeclinedSearchAndGroupedCollaboratorsList,
    Aggregated: getAllSearchAndGroupedCollaboratorsList,
  },
  [AGENT_CONNECTIONS.Team]: {
    Active: getActiveSearchAndGroupedTeamList,
    Pending: getPendingSearchAndGroupedTeamList,
    Inactive: getInactiveSearchAndGroupedTeamList,
    Aggregated: getAllSearchAndGroupedTeamList,
  },
};

ClientsList.propTypes = {
  tabLabel: PropTypes.string,
};

ClientsList.defaultProps = {
  tabLabel: '',
};

export default ClientsList;
