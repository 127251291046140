import classNames from 'classnames';
import styles from '../styles.module.scss';
import { hasEmoji } from '../helper';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { useEffect, useRef, useState } from 'react';
import { InsertClauseModal } from '../../../InsertClauseModal';
import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { getClauseTemplatesDataSelector } from 'store/selectors/templates';

export const TextBox = ({
  inputProps,
  fieldProps,
  questionUUID,
  onClick,
  height,
  isPDFEditorView,
}) => {
  const [open, setOpen] = useState(false);
  const [isVisibleClauseMenu, setIsVisibleClauseMenu] = useState(false);
  const clauses = useSelector(getClauseTemplatesDataSelector);

  const [fieldValue, setFieldValue] = useState(fieldProps.defaultValue);
  const { isAgent } = useSelector(getUserRolesMapSelector);

  const { onBlur, ...restFieldProps } = fieldProps;

  useEffect(() => {
    setFieldValue(fieldProps.defaultValue);
  }, [fieldProps.defaultValue]);

  const textAreaRef = useRef<HTMLInputElement>();

  const toggleModal = () => setOpen((prev) => !prev);

  const renderActionButtons = () => {
    if (!isVisibleClauseMenu || !clauses?.length) return;

    const actionButtons = [
      {
        variant: ICON_VARIANT_TYPE.InsertClause,
        onClick: toggleModal,
        title: 'Insert a Clause',
      },
    ];

    return (
      <div className={classNames(styles.actionButtons)}>
        {actionButtons.map((button) => (
          <div key={button.variant} className={styles.actionButton} onClick={button.onClick}>
            <Icons variant={button.variant} />
            <span>{button.title}</span>
          </div>
        ))}
      </div>
    );
  };

  const onSelectClause = (clause) => {
    toggleModal();
    setFieldValue(
      (prev) => `${prev || ''}${prev ? '\n' + clause.Description : clause.Description}`,
    );
    textAreaRef.current?.focus();
  };

  const onFieldBlur = (e) => {
    const value = e.target.value;

    if (isPDFEditorView) {
      onBlur(e);
    } else {
      const answers = dynamicManager.getSignatureAnswer();

      if (!answers?.[questionUUID] && !value.trim().length) {
        return;
      } else if (answers?.[questionUUID]?.Answer != null) {
        if (answers[questionUUID].Answer === value) return;
      }

      onBlur(e);
    }
  };

  return (
    <div
      {...inputProps}
      onBlur={() => {
        setTimeout(() => setIsVisibleClauseMenu(false), 200);
      }}
      itemID={`question-${questionUUID}`}
      onClick={onClick}
    >
      <textarea
        {...restFieldProps}
        className={classNames(styles.inputTextField, {
          [styles.smallInputTextField]: height < 20,
        })}
        onChange={(e) => {
          const { value } = e.target;
          if (!hasEmoji(value)) {
            e.target.value = value;

            if (textAreaRef.current) {
              textAreaRef.current.value = value;
            }

            setFieldValue(value);
          }
        }}
        value={fieldValue}
        ref={textAreaRef}
        onBlur={onFieldBlur}
        onContextMenu={(e) => {
          e.preventDefault();
          if (!isPDFEditorView) {
            isAgent && !isVisibleClauseMenu && setIsVisibleClauseMenu(true);
          }
        }}
      />

      {renderActionButtons()}

      {isAgent && (
        <InsertClauseModal isOpen={open} onClose={toggleModal} onSelectClause={onSelectClause} />
      )}
    </div>
  );
};
