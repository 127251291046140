import classNames from 'classnames';

import { useHistory } from 'react-router-dom';

import Icons from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import NotesModal from 'pages/Workshop/Transactions/components/Notes/NotesModal/NotesModal';
import styles from './styles.module.scss';

import { useCallback, useEffect, useState } from 'react';
import { getTransactionClientStats, getTransactionStats } from 'api/transactions';
import { Spinner } from 'components';
import { useDispatch } from 'react-redux';
import { updateTransactionStatsAction } from 'store/actions/transactions';

const Tile = ({ title, icon, count, route, isOverdueVariant = false }) => {
  if (!count || count === 0) return null;
  return (
    <div className={styles.tile} onClick={route}>
      <div className={styles.textContainer}>
        {icon}
        <span className={styles.text}>{title}:</span>
      </div>
      <div className={classNames(styles.count, { [styles.overdue]: isOverdueVariant })}>
        {count || 0}
      </div>
    </div>
  );
};

export const NeedsAttention = ({ transactionId, showFullAccessContent, showClientContent }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [tasksCount, setTasksCount] = useState({
    Overdue: 0,
    dueToday: 0,
    taggedComments: 0,
    taggedTaskIds: [],
  });
  const [offerCount, setOfferCount] = useState({
    New: 0,
    Pending: 0,
    Expiring: 0,
    taggedComments: 0,
    taggedOfferIds: [],
  });
  const [showingCount, setShowingCount] = useState({
    Pending: 0,
    Upcoming: 0,
    NewFeedback: 0,
  });
  const [notesCount, setNotesCount] = useState({
    New: 0,
    taggedComments: 0,
  });
  const dispatch = useDispatch();

  const routeToTasks = () => history.push(`/workshop/transactions/${transactionId}/tasks`);
  const routeToOffers = () => history.push(`/workshop/transactions/${transactionId}/offers`);
  const routeToShowings = () => history.push(`/workshop/transactions/${transactionId}/showings`);
  const openNotesModal = () => setShowNotes(true);
  const fetchTransactionStats = useCallback(async () => {
    setLoading(true);
    let data;
    if (showClientContent) {
      const resp = await getTransactionClientStats(transactionId);
      data = resp.data;
    } else {
      const resp = await getTransactionStats(transactionId);
      data = resp.data;
    }

    if (data?.result) {
      if (data?.result?.tasks) setTasksCount(data?.result?.tasks);
      if (data?.result?.offers) setOfferCount(data?.result?.offers);
      if (data?.result?.showings) setShowingCount(data?.result?.showings);
      if (data?.result?.notes) setNotesCount(data?.result?.notes);
      dispatch(updateTransactionStatsAction(data?.result));
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (transactionId) fetchTransactionStats();
  }, [transactionId]);

  const taskTiles = [
    {
      title: 'Overdue Tasks',
      icon: <Icons variant={Icons.OVERDUE_TASKS} />,
      count: tasksCount?.Overdue,
      route: routeToTasks,
      isOverdueVariant: true,
    },
    {
      title: 'Tasks Due Today',
      icon: <Icons variant={Icons.TASKS_DUE_TODAY} />,
      count: tasksCount?.dueToday,
      route: routeToTasks,
    },
    {
      title: 'Tagged Comments (Tasks)',
      icon: <Icons variant={Icons.TAGGED_COMMENTS} />,
      count: tasksCount?.taggedComments,
      route:
        tasksCount?.taggedComments === 1 && tasksCount?.taggedTaskIds?.length === 1
          ? () =>
              history.push(
                `/workshop/transactions/${transactionId}/tasks?taskId=${tasksCount?.taggedTaskIds[0]}&active=comment`,
              )
          : routeToTasks,
    },
  ];

  const offerTiles = [
    {
      title: 'New Offers',
      icon: <Icons variant={Icons.NEW_OFFERS} />,
      count: offerCount?.New,
      route: routeToOffers,
    },
    {
      title: 'Pending Offers',
      icon: <Icons variant={Icons.PENDING_OFFERS} />,
      count: offerCount?.Pending,
      route: routeToOffers,
    },
    {
      title: 'Expiring Offers',
      icon: <Icons variant={Icons.EXPIRING_OFFERS} />,
      count: offerCount?.Expiring,
      route: routeToOffers,
      isOverdueVariant: true,
    },
    {
      title: 'Tagged Comments (Offers)',
      icon: <Icons variant={Icons.TAGGED_COMMENTS} />,
      count: offerCount?.taggedComments,
      route:
        offerCount?.taggedComments === 1 && offerCount?.taggedOfferIds?.length === 1
          ? () =>
              history.push(
                `/workshop/transactions/${transactionId}/offers?offerId=${offerCount?.taggedOfferIds[0]}&active=comment`,
              )
          : routeToOffers,
    },
  ];

  const showingTiles = [
    {
      title: 'Pending Showings',
      icon: <Icons variant={Icons.PENDING_SHOWINGS} />,
      count: showingCount?.Pending,
      route: routeToShowings,
    },
    {
      title: 'Upcoming Showings',
      icon: <Icons variant={Icons.UPCOMING_SHOWINGS} />,
      count: showingCount?.Upcoming,
      route: routeToShowings,
    },
    {
      title: 'New Showing Feedback',
      icon: <Icons variant={Icons.NEW_SHOWINGS} />,
      count: showingCount?.NewFeedback,
      route: routeToShowings,
    },
  ];

  const noteTiles = [
    {
      title: 'New Notes',
      icon: <Icons variant={Icons.NEW_NOTES} />,
      count: notesCount?.New,
      route: openNotesModal,
    },
    {
      title: 'Tagged Notes',
      icon: <Icons variant={Icons.TAGGED_COMMENTS} />,
      count: notesCount?.taggedComments,
      route: openNotesModal,
    },
  ];

  const showEmptyState = () => {
    const array = [
      ...(showFullAccessContent
        ? [...taskTiles, ...offerTiles, ...showingTiles, ...noteTiles, ...taskTiles]
        : []),
      ...(showClientContent ? [...showingTiles, ...taskTiles, ...noteTiles] : []),
      ...(!showFullAccessContent && !showClientContent ? [...taskTiles, ...noteTiles] : []),
    ];
    const countsSum = array.reduce(
      (accumulator, currentValue) => accumulator + (currentValue?.count || 0),
      0,
    );

    return countsSum === 0;
  };

  return (
    <>
      <div className={styles.needsAttentionContainer}>
        <p className={styles.heading}>Needs Attention</p>
        {!showEmptyState() ? (
          <>
            {taskTiles.map((item, idx) => (
              <Tile key={idx} {...item} />
            ))}
            {showFullAccessContent && offerTiles.map((item, idx) => <Tile key={idx} {...item} />)}
            {(showFullAccessContent || showClientContent) &&
              showingTiles.map((item, idx) => <Tile key={idx} {...item} />)}
            {noteTiles.map((item, idx) => (
              <Tile key={idx} {...item} />
            ))}
          </>
        ) : (
          <div className={styles.emptyContainer}>
            {loading ? <Spinner /> : "You're all set for now."}
          </div>
        )}
      </div>
      {showNotes ? <NotesModal handleCloseNotesModal={() => setShowNotes(false)} /> : null}
    </>
  );
};
