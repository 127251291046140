import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { deleteTransactionTemplateEffect } from 'store/effects/template';
import { TemplateHeader } from '../components/TemplateHeader';
import { TemplateCard } from '../Transactions/components/TemplateCard';
import { NoTemplatesFound } from '../components/NoTemplates';
import { ConfirmationDialog, Wrapper } from 'components';
import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { RulesModal } from '../Transactions/Rules';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { Role } from 'app-constants';
import { getMasterTransactionTemplates } from 'api/templates';
import { getFormMetaSelect } from 'store/selectors/formBuilder';
import { getAllTeamsEffect } from 'store/effects';

import styles from './styles.module.scss';

export const MasterTransactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isProject = location.pathname.includes('project');

  const userRole = useSelector(getUserRoleSelector);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const [templateId, setTemplateId] = useState<number | undefined>(undefined);
  const [templates, setTemplates] = useState<any[]>();
  const [rulesModalVisible, setRulesModalVisible] = useState(false);

  const [templateToDelete, setTemplateToDelete] = useState<any>();
  const [query, setQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAgentTemplates, setShowAgentTemplates] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(true);
  const { teams: allTeams = [] } = useSelector(getFormMetaSelect);

  const fetchMasterTemplates = async () => {
    const { data, status } = await getMasterTransactionTemplates();
    if (status === 200) {
      setTemplates(data.result);
    }
    setIsPending(false);
  };

  useEffect(() => {
    if (isSuperUser && !allTeams?.length) dispatch(getAllTeamsEffect());
    fetchMasterTemplates();
  }, []);

  const getUpdatedTemplate = (templateId?: number) =>
    templates?.find((template) => template.Id === templateId);

  const onSetTemplateRules = (templateId) => {
    setTemplateId(templateId);
    setRulesModalVisible(true);
  };

  const onViewTemplate = (templateId: number) => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? link.toDashboardProjectTemplateView(templateId)
          : link.toDashboardTransactionTemplateView(templateId),
      );
    } else if (userRole === Role.Agent) {
      history.push(
        isProject
          ? link.toProjectTemplateView(templateId)
          : link.toTransactionTemplateView(templateId),
      );
    }
  };

  const onEditTemplate = (templateId: number) => {
    if (userRole === Role.SuperUser) {
      history.push(
        isProject
          ? link.toDashboardProjectTemplateEdit(templateId)
          : link.toDashboardTransactionTemplateEdit(templateId),
      );
    } else if (userRole === Role.Agent) {
      history.push(
        isProject
          ? link.toProjectTemplateEdit(templateId)
          : link.toTransactionTemplateEdit(templateId),
      );
    }
  };

  const onTemplateDelete = (templateId: number) => {
    setTemplateToDelete(getUpdatedTemplate(templateId));
  };

  const onConfirmDeleteItem = () => {
    if (!templateToDelete) return;
    setIsDeleting(true);
    dispatch(
      deleteTransactionTemplateEffect({ id: templateToDelete?.Id, isProject }, {}, (err) => {
        if (!err) {
          setTemplateToDelete(null);
        }
        setIsDeleting(false);
      }),
    );
  };

  const deleteTemplateContent = useMemo(
    () =>
      templateToDelete ? (
        <div testid="modal_title">
          Are you sure that you want to <b>delete </b> the <b>{templateToDelete.Name}</b>{' '}
          transaction template?
        </div>
      ) : null,
    [templateToDelete],
  );

  const onCloseDeleteItemModal = () => setTemplateToDelete(null);

  const filteredTemplates = useMemo(() => {
    return templates?.filter((template) => template.IsMasterTemplate === true);
  }, [templates, showAgentTemplates]);

  return (
    <Wrapper isPending={isPending} className={styles.transactions}>
      {isSuperUser && (
        <RulesModal
          rulesModalVisible={rulesModalVisible}
          transactionTemplate={getUpdatedTemplate(templateId)}
          onCancel={() => {
            setRulesModalVisible(false);
            setTemplateId(undefined);
          }}
        />
      )}
      <ConfirmationDialog
        onReject={onCloseDeleteItemModal}
        onConfirm={onConfirmDeleteItem}
        isOpen={!!templateToDelete}
        isPending={isDeleting}
      >
        {deleteTemplateContent}
      </ConfirmationDialog>

      <TemplateHeader
        label={`${isProject ? 'Master Project' : 'Master Transaction'} Templates`}
        value={query}
        onSearch={(val) => setQuery(val)}
        onClear={() => setQuery('')}
      />
      {filteredTemplates?.length ? (
        filteredTemplates
          .filter((temp) => temp.Name.toLowerCase().includes(query) || temp.Name.includes(query))
          .map((template) => (
            <TemplateCard
              key={template?.Id}
              template={template}
              onSetRules={onSetTemplateRules}
              onView={onViewTemplate}
              onEdit={onEditTemplate}
              onDelete={onTemplateDelete}
            />
          ))
      ) : (
        <NoTemplatesFound
          className={styles.noTemplates}
          templatesName={isProject ? 'project' : 'transaction'}
        />
      )}
    </Wrapper>
  );
};
