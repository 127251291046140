import {
  getAvmAdjustments,
  getPropertyReferenceData,
  getPropertyReferenceDetails,
  getPropertyReferenceNeighborhoodActivities,
  getPropertyReferenceStats,
  updateClientProperty,
} from 'api/radarPortfolio';
import {
  getAvmAdjustmentAction,
  getPropertyReferenceDataAction,
  getPropertyReferenceDetailsAction,
  getPropertyReferenceNeighborhoodActivitiesAction,
  getPropertyReferenceStatsAction,
  updateClientPropertyAction,
} from 'store/actions/radarPortfolio';
import Api from 'store/effects/core/api';

export const requestPropertyReferenceDataEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceDataAction,
    method: getPropertyReferenceData,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPropertyReferenceStatsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceStatsAction,
    method: getPropertyReferenceStats,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPropertyDetailsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceDetailsAction,
    method: getPropertyReferenceDetails,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPropertyNeighborhoodActivitiesEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceNeighborhoodActivitiesAction,
    method: getPropertyReferenceNeighborhoodActivities,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestAvmAdjustmentEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getAvmAdjustmentAction,
    method: getAvmAdjustments,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const updateClientPropertyEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: updateClientPropertyAction,
    method: updateClientProperty,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
