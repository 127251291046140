import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, reduce, trim } from 'lodash-es';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'antd';
import { Tooltip } from 'components-antd';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { Avatar, Button, Checkbox } from 'components';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { GreenCheckMark } from 'components/Icons';
import { MessageIcon } from '../AgentPartnersList/icons';
import styles from './styles.module.scss';

export const getFormattedLocationList = (area, suffix = true) => {
  if (!area?.PlaceName) return '';

  return `${area?.PlaceName?.replace(', USA', '')?.replace(', Canada', '') || ''}${
    suffix ? ';' : ''
  } `;
};

export const renderPartnerLocationsList = (partner) => {
  const cutOffValue = 5;
  if (partner.AreasOfOperation.length > cutOffValue) {
    const locationList = partner.AreasOfOperation.slice(0, cutOffValue - 1).map((area) =>
      getFormattedLocationList(area),
    );
    locationList.push(`+${partner.AreasOfOperation.length - cutOffValue} more`);
    return locationList;
  } else if (partner.AreasOfOperation.length === 1) {
    return partner.AreasOfOperation.map((area) => getFormattedLocationList(area, false));
  } else {
    return partner.AreasOfOperation.map((area) => getFormattedLocationList(area));
  }
};

const PartnerListItem = ({ partner, agentId, isClient, onSelectPartnerFromClient }) => {
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const dispatch = useDispatch();
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [viewPartnerDetails, setViewPartnerDetails] = useState(false);

  const { value: recommendedBy } = useMemo(
    () =>
      reduce(
        partner?.RecommendingAgents || [],
        (acc, { Id, FirstName, LastName, AvatarUrl }) => {
          if (Id === agentId) {
            acc.value = {
              name: trim(`${FirstName} ${LastName}`),
              avatar: AvatarUrl,
              placeholder: convertNameToAvatarPlaceholder(trim(`${FirstName} ${LastName}`)),
            };
          }
          return acc;
        },
        {},
      ),
    [partner?.RecommendingAgents, agentId],
  );

  const onSelectPartner = (event, val, checked) => {
    const newPartners = cloneDeep(multiple?.partners) || [];
    if (checked) {
      newPartners.push(partner);
    } else {
      const removedPartnerIndex = newPartners.findIndex((part) => part?.Id === partner?.Id);

      if (removedPartnerIndex !== -1) {
        newPartners.splice(removedPartnerIndex, 1);
      }
    }
    dispatch(setMultipleRequestQuotesEffect({ partners: newPartners }));
  };

  const getIsPartnerChecked = () =>
    (multiple?.partners || []).some((part) => part?.Id === partner?.Id);

  const isActiveVendor = () => {
    if (
      partner.IsStrictlyProfile ||
      !partner?.ThirdParty?.User?.IsEmailVerified ||
      !partner?.RecommendingAgents?.length
    )
      return false;

    const targetAgent = partner.RecommendingAgents.find((agent) => agent.Id === agentId);

    if (!targetAgent?.IsInvited) return false;

    return true;
  };

  if (multiple.modeEnable && !isActiveVendor()) return null;

  return (
    <>
      <PartnerProfile
        partnerId={selectedPartnerId}
        open={viewPartnerDetails}
        setModalVisible={setViewPartnerDetails}
        setSelectedPartnerId={setSelectedPartnerId}
        IsStrictlyProfile={!!partner.IsStrictlyProfile}
        clientAgentId={agentId}
        clientAgentName={partner.RecommendingAgents.find((agent) => agent.Id === agentId).FirstName}
        isActiveForClient={isActiveVendor()}
        onCancel={() => {
          setViewPartnerDetails(false);
          setSelectedPartnerId(null);
        }}
      />
      <Col span={24} testid="partner_item" className={styles.card}>
        <div
          className={styles.cardContent}
          onClick={() => {
            setSelectedPartnerId(partner?.Id);
            setViewPartnerDetails(true);
          }}
        >
          {recommendedBy ? (
            <div className={styles.recommendStar}>
              {!multiple?.modeEnable ? null : !isClient ? (
                <Checkbox
                  testid="check_partner"
                  onChange={onSelectPartner}
                  checked={getIsPartnerChecked()}
                />
              ) : null}
            </div>
          ) : (
            <div className={styles.multipleCheckbox}>
              {multiple?.modeEnable && !isClient && (
                <Checkbox
                  testid="check_partner"
                  onChange={onSelectPartner}
                  checked={getIsPartnerChecked()}
                />
              )}
            </div>
          )}
          <div className={styles.infoWrapper}>
            <div testid="avatar" className={styles.cardImage}>
              <Avatar
                src={partner?.LogoUrl}
                placeholder={convertNameToAvatarPlaceholder(trim(`${partner?.BusinessName}`))}
              />
            </div>
            <div className={styles.details}>
              <div testid="business_name" className={styles.cardTitle}>
                {partner?.BusinessName || 'Unknown name'}
              </div>
              <div className={styles.cardDescription}>
                {partner?.AreasOfOperation?.length > 0 ? (
                  <div className={styles.partnerInfoDesc}>
                    <div className={styles.descTitle}>
                      <GreenCheckMark width={16} height={16} /> Serves:
                    </div>
                    <div className={styles.itemsWrapper}>
                      <span>{renderPartnerLocationsList(partner)}</span>
                    </div>
                  </div>
                ) : null}
                <div testid="description" className={styles.partnerInfoDesc}>
                  <div className={styles.descTitle}>
                    <GreenCheckMark width={16} height={16} /> Offers:
                  </div>
                  <div className={classNames(styles.itemsWrapper, styles.servicesOffered)}>
                    {partner?.PartnerTags?.map(({ Name }) => Name).join(', ') || 'General Services'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.cardButtons}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!multiple.modeEnable && isActiveVendor() && (
              <Tooltip
                placement="top"
                overlayClassName={classNames(styles.clientMessageTooltip, 'mosaikTooltip')}
                title={
                  'This vendor accepts messages, inquiries, and quote requests directly through Mosaik.'
                }
                align={{
                  offset: [-48, 0],
                }}
              >
                <Button
                  className={styles.messages}
                  iconLeft={<MessageIcon />}
                  testid="message_button"
                />
              </Tooltip>
            )}
            {isClient && multiple.modeEnable && (
              <Checkbox
                testid="check_partner"
                onChange={() => onSelectPartnerFromClient(partner)}
                checked={getIsPartnerChecked()}
              />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

PartnerListItem.propTypes = {
  partner: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    BusinessName: PropTypes.string,
    LogoUrl: PropTypes.string,
    RecommendingAgents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  agentId: PropTypes.number,
  isClient: PropTypes.bool,
};

PartnerListItem.defaultProps = {
  agentId: null,
  isClient: false,
};

export default PartnerListItem;
