import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PartnersList from '../PartnersList';
import { toggleRequestQuoteBtnAction } from 'store/actions/partners';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import {
  getRecommendedPartnersByAgentList,
  showRequestQuoteBtnSelector,
} from 'store/selectors/partners';

const ArchivesTab = ({ categoryTitle }) => {
  const dispatch = useDispatch();
  const list = useSelector(getRecommendedPartnersByAgentList);
  const showRequestQuoteBtn = useSelector(showRequestQuoteBtnSelector);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);

  useEffect(() => {
    if (list?.length > 0) {
      !showRequestQuoteBtn && dispatch(toggleRequestQuoteBtnAction(true));
    } else {
      showRequestQuoteBtn && dispatch(toggleRequestQuoteBtnAction(false));
    }
  }, [list, dispatch, showRequestQuoteBtn]);

  const activePartnersList = useMemo(() => {
    if (!list) return;
    if (multiple?.modeEnable) {
      return list.filter(
        (partner) =>
          partner?.ThirdParty?.User?.CompletedProfile &&
          partner?.IsActive &&
          !partner?.IsArchived &&
          !partner?.isDraft,
      );
    }
    return list;
  }, [list, multiple.modeEnable]);

  return (
    <PartnersList
      list={activePartnersList?.filter((partner) => partner?.IsArchived)}
      tabName={'archive'}
    />
  );
};

export default ArchivesTab;
