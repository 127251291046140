import { routes } from 'settings/navigation/routes';
import { AGENT, CLIENT } from 'settings/constants/roles';
import feed from './feed';
import tours from './tours';
import activity from './activity';
import listingDetail from './listingDetail';
import searchResults from './searchResults';

import { Properties } from 'pages';

export default [
  {
    path: routes.properties,
    component: Properties,
    exact: true,
    roles: [CLIENT, AGENT],
  },
  ...activity,
  ...feed,
  ...tours,
  ...searchResults,
  ...listingDetail,
];
