import { useDispatch, useSelector } from 'react-redux';

import { UploadDocument } from '../../../View/components';
import { TaskSectionContainer } from '../../../ViewRevamped/components';

import { getTransactionTaskEffect } from 'store/effects';
import { getTransactionTaskAccess } from 'store/selectors/transactionTasks';
import {
  getFormTaskPermissions,
  getTransactionTaskParamsSelector,
} from 'store/selectors/transactionTask';
import {
  getAdminOwnerAccessSelector,
  getTransactionAccessSelector,
} from 'store/selectors/transaction';
import { Button } from 'components-antd';
import Icons from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Icons as TaskIcons } from './../../../ViewRevamped/Icons';
import { getUserRolesSelector } from 'store/selectors/user';
import { CLIENT } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const FormTaskActions = ({
  task,
  setOpenLinkFormsModal,
  beginSigning,
  gotToForm,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { hasAccess } = useSelector(getTransactionTaskAccess);
  const { taskId, transactionId } = useSelector(getTransactionTaskParamsSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);
  const { canBeginSigning, canResumeSigning, canSignForm, canViewForm } =
    useSelector(getFormTaskPermissions);
  const roles = useSelector(getUserRolesSelector);

  const getSignButton = () => {
    if (canBeginSigning) {
      return (
        <Button
          className={styles.signFormBtn}
          variant="hollow-bordered"
          icon={<TaskIcons variant={TaskIcons.SIGNIN} />}
          onClick={beginSigning}
          disabled={disabled}
        >
          {'Begin Signing'}
        </Button>
      );
    } else if (canResumeSigning) {
      return (
        <Button
          className={styles.signFormBtn}
          variant="hollow-bordered"
          icon={<TaskIcons variant={TaskIcons.SIGNIN} />}
          onClick={gotToForm}
          disabled={disabled}
        >
          {'Resume'}
        </Button>
      );
    } else if (canSignForm) {
      return (
        <Button
          className={styles.signFormBtn}
          variant="hollow-bordered"
          icon={<TaskIcons variant={TaskIcons.SIGNIN} />}
          onClick={gotToForm}
          disabled={disabled}
        >
          {'Sign Form'}
        </Button>
      );
    } else if (canViewForm) {
      return (
        <Button
          className={styles.signFormBtn}
          variant="hollow-bordered"
          icon={<TaskIcons variant={TaskIcons.SIGNIN} />}
          onClick={gotToForm}
          disabled={disabled}
        >
          {'View Form'}
        </Button>
      );
    }
  };

  return (
    <TaskSectionContainer disabled={disabled}>
      <div className={styles.formTaskActions}>
        <p className={styles.title}>Actions</p>
        <div className={styles.actionsContainer}>
          {(fullAccess || hasAccess(task?.Id)) && (
            <UploadDocument
              afterUploadCallback={() =>
                dispatch(getTransactionTaskEffect({ transactionId, taskId }))
              }
              uploadButton={
                <Button
                  variant="hollow-bordered"
                  className={styles.uploadBtn}
                  icon={<Icons variant={Icons.CLOUD_UPLOAD} className={styles.cloudIcon} />}
                  disabled={disabled}
                >
                  Upload File(s)
                </Button>
              }
            />
          )}
          {(isAdminOrOwner || fullAccess || hasAccess(task?.Id)) && !roles?.includes(CLIENT) && (
            <Button
              variant="hollow-bordered"
              className={styles.linkFormBtn}
              icon={<TaskIcons variant={TaskIcons.FORM} />}
              onClick={() => setOpenLinkFormsModal(true)}
              disabled={disabled}
            >
              Link an Existing Form
            </Button>
          )}
          {getSignButton()}
        </div>
      </div>
    </TaskSectionContainer>
  );
};
