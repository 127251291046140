import { KitSlug } from 'pages/Radar/types';

export const formatFrequency = (item: any, kitSlug?: string) => {
  let frequencyString = '';
  if (item?.ClosingDateRelated) {
    // frequencyString += ' (CD';
    // if (item.OffsetOperator) frequencyString += item.OffsetOperator;
    // if (item.OffsetValue) frequencyString += item.OffsetValue;
    // if (item.OffsetUnit) frequencyString += item.OffsetUnit;
    // frequencyString += ' )';
    const { OffsetOperator, OffsetValue, OffsetUnit } = item;
    if (OffsetValue) {
      frequencyString += `${OffsetValue} `;
    }
    if (OffsetUnit) {
      if (OffsetUnit === 'd') {
        frequencyString += 'Days';
      }
      if (OffsetUnit === 'm') {
        frequencyString += 'Months';
      }
      if (OffsetUnit === 'y') {
        frequencyString += 'Years';
      }
    }
    if (OffsetOperator === '+') {
      let closingStatement =
        kitSlug === KitSlug.AnnualFinancialReview
          ? ' after closing anniversary'
          : ' after closing date';
      frequencyString += closingStatement;
    }
    if (OffsetOperator === '-') {
      frequencyString += ' before closing date';
    }
  }
  if (item?.HasSpecificDates && item?.SpecificDates?.length) {
    frequencyString += item.SpecificDates.join(' ');
  }
  return frequencyString;
};
