import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Table } from 'antd';
import isEqual from 'lodash/isEqual';

import { getTransactionEffect, updateTransactionTaskDueDateEffect } from 'store/effects';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { getTaskFilters } from 'store/selectors/transactionTasks';
import { getDateOnly } from 'helpers';

import styles from './styles.module.scss';

const RowContext = createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext<any>(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      className={styles.dragBtn}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

export const DraggableRowsTable = ({ columns, data, onRow, className }) => {
  const dispatch = useDispatch();
  const [sorted, setIsSorted] = useState(false);
  const [showOrderColumn, setShowOrderColumn] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const filters = useSelector(getTaskFilters);

  useEffect(() => {
    if (data) {
      const extractRelevantFields = (item) => ({
        DueDate: item.DueDate,
        AssigneeList: item.AssigneeList,
        Title: item.Title,
        Status: item.Status,
      });

      // Extract and sort to make order irrelevant
      const sanitizedData = data
        .map(extractRelevantFields)
        .sort((a, b) => a.Title?.localeCompare(b.Title));
      const sanitizedDataSource = dataSource
        .map(extractRelevantFields)
        .sort((a, b) => a.Title?.localeCompare(b.Title));

      if (!isEqual(sanitizedData, sanitizedDataSource)) {
        setDataSource(data.map((item, idx) => ({ key: idx, ...item })));
      }
    }
    setShowOrderColumn(true);
  }, [data]);

  const handleUpdateOrder = (task, updatedOrder) => {
    dispatch(
      updateTransactionTaskDueDateEffect(
        {
          Id: task.Id,
          Order: updatedOrder,
          Hours: task?.Hours,
          Minutes: task?.Minutes,
        },
        {},
        (err, response) => {
          if (!err) {
            dispatch(getTransactionEffect({ id: task?.TransactionId }, { silent: true }));
            dispatch(
              getTaskAggregateEffect(
                {
                  filters: { ...filters, transactionRoomId: task?.TransactionId },
                },
                { silent: true },
              ),
            );

            // To avoid re-renders in the table,
            // picking order from the response and insert in the local data source
            const { Tasks } = response?.data?.result || {};
            setDataSource((prevData) =>
              prevData?.map((item) => {
                const task = Tasks?.find((i) => i.Id === item?.Id);
                return { ...item, Order: task?.Order };
              }),
            );
          }
        },
      ),
    );
  };

  const onDragEnd = ({ active, over }) => {
    const activeRow = dataSource?.find((item) => item?.key === active.id);
    const overRow = dataSource?.find((item) => item?.key === over.id);

    // We can change the order of tasks with similar due date only.
    const isDraggingAllowed =
      getDateOnly(activeRow?.DueDate, 'M/D/YYYY') === getDateOnly(overRow?.DueDate, 'M/D/YYYY');

    if (active.id !== over?.id && isDraggingAllowed) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id);
        const overIndex = prevState.findIndex((record) => record.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      handleUpdateOrder(activeRow, overRow?.Order);
    }
  };

  const doesDueDateExistElsewhere = (obj) => {
    const normalizeDate = (dateStr) => new Date(dateStr).toISOString().split('T')[0];

    const dueDateMap = new Map();

    // Count occurrences of each normalized DueDate
    for (const task of dataSource) {
      const normalizedDate = normalizeDate(task.DueDate);
      dueDateMap.set(normalizedDate, (dueDateMap.get(normalizedDate) || 0) + 1);
    }

    // Check if the DueDate appears somewhere other than the passed object
    return dueDateMap.get(normalizeDate(obj.DueDate)) > 1;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setIsSorted(!!sorter.order);
  };

  return (
    <div
      onMouseEnter={() => setShowOrderColumn(true)}
      onMouseLeave={() => setShowOrderColumn(false)}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="key"
            components={{
              body: {
                row: Row,
              },
            }}
            columns={[
              {
                key: 'sort',
                width: 25,
                className: styles.dragHandle,
                render: (row) =>
                  showOrderColumn && !sorted && doesDueDateExistElsewhere(row) ? (
                    <DragHandle />
                  ) : (
                    <div className={styles.emptyPlaceholder} />
                  ),
              },
              ...columns,
            ]}
            dataSource={dataSource}
            className={className}
            onRow={onRow}
            onChange={handleTableChange}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};
