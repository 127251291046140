import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Form } from 'components-antd';
import { TaskTemplate } from 'types';
import { TransactionTemplateTaskModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm';
import TaskTemplates from 'pages/Templates/Tasks/components/TaskTemplates';
import { Modal as TemplateModal } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { getTemplateButtons } from '../TemplateButtons';
import { TaskCard } from '../TaskCard';
import { AddNew, Drag, Timeline } from 'components/Icons';
import UploadTasks from 'components/Transactions/UploadTasks';
import { GoBack } from 'components/Icons';

import styles from './styles.module.scss';

export const TasksPane = ({ isViewMode, data }) => {
  return (
    <div className={styles.taskPaneContainer}>
      <Form.Item name="TaskTemplates">
        <TaskList isViewMode={isViewMode} data={data} />
      </Form.Item>
    </div>
  );
};

interface TaskListProps {
  onChange?: (e) => void;
  value?: TaskTemplate[];
  isViewMode: boolean;
  data: [];
}

const TaskList = ({ onChange, value, isViewMode, data }: TaskListProps) => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const [editTask, setEditTask] = useState<number | undefined>(undefined);
  const [showTaskModal, setShowTaskModal] = useState(false);

  const [isExpandGroup, setIsExpandGroup] = useState(false);
  const [selectedGroupName, setSelectedGroupName] = useState(null);

  const onRemoveTask = (index) => {
    if (isViewMode) return;
    const result = [...(value || [])];
    result.splice(index, 1);

    if (onChange) onChange(result);
    handleTaskModalCancel();
  };

  const handleTemplate = (tasks) => {
    const result = [...(value || []), ...tasks];

    if (onChange) onChange(result);

    setShowTemplateModal(false);
  };

  const sortTasks = (tasks) => {
    // Group tasks by their 'Initial' (e.g., "CD")
    const taskGroups: any[] = tasks.reduce((acc, task) => {
      const { Initial, Operator } = task;

      if (!acc[Initial]) acc[Initial] = { plus: [], minus: [] };

      if (Operator === '-') {
        acc[Initial].minus.push(task);
      } else if (Operator === '+') {
        acc[Initial].plus.push(task);
      }

      return acc;
    }, {});

    // Sort negatives in descending order, positives in ascending order
    Object.keys(taskGroups).forEach((initial) => {
      taskGroups[initial].minus.sort((a, b) => b.DueDate - a.DueDate);
      taskGroups[initial].plus.sort((a, b) => a.DueDate - b.DueDate);
    });

    // Flatten sorted tasks into a single array
    return Object.values(taskGroups)?.flatMap(({ minus, plus }) => [...minus, ...plus]);
  };

  const onSaveTask = (obj) => {
    const result = [...(value || [])];

    if (editTask !== undefined && result[editTask]) {
      result[editTask] = obj;
    } else {
      result.push(obj);
    }

    if (onChange) onChange(sortTasks(result));

    handleTaskModalCancel();
  };

  const handleTaskModalCancel = () => {
    setEditTask(undefined);
    setShowTaskModal(false);
  };

  const handleEditTask = (index) => {
    setEditTask(index);
    setShowTaskModal(true);
  };

  const handleNewTask = () => {
    if (isViewMode) return;
    setShowTaskModal(true);
    setEditTask(undefined);
  };

  const handleUpload = (tasks) => {
    const result = tasks;
    if (onChange) onChange(result);
  };

  const getDuplicateOffsets = (tasks) => {
    const countMap = tasks.reduce((acc, task) => {
      acc[task.ControlOperatorOffset] = (acc[task.ControlOperatorOffset] || 0) + 1;
      return acc;
    }, {});
    return new Set(Object.keys(countMap).filter((key) => countMap[key] > 1));
  };

  const duplicateOffsets: any = getDuplicateOffsets(value || []);

  const updateList = (result) => {
    const picked = value?.find((item) => item?.Name === result?.draggableId);
    const dropped = value?.[result?.destination?.index];

    if (!result.destination || picked?.ControlOperatorOffset !== dropped?.ControlOperatorOffset)
      return; // Exit if dropped outside a valid area

    const reorderedTasks = Array.from(value || []); // Clone the tasks array
    const [movedTask] = reorderedTasks.splice(result.source.index, 1); // Remove dragged task
    reorderedTasks.splice(result.destination.index, 0, movedTask); // Insert at new position

    if (onChange) onChange(reorderedTasks); // Update state with new order
  };

  const taskTemplateModalTitle = (
    <div className={styles.assignToHeader}>
      {isExpandGroup ? (
        <GoBack
          className={styles.headerIcon}
          onClick={(e) => {
            setIsExpandGroup(false);
            setSelectedGroupName(null);
          }}
        />
      ) : (
        <span />
      )}
      <div className={styles.headerContainer}>
        <p className={styles.headerTitle}>Task Templates</p>
        {isExpandGroup && <div className={styles.subtitle}>{selectedGroupName}</div>}
      </div>
    </div>
  );

  if (value) {
    return (
      <>
        {showTaskModal && (
          <TransactionTemplateTaskModal
            modelProps={{
              onCancel: handleTaskModalCancel,
              open: true,
              title: editTask !== undefined && value[editTask] ? 'Edit Task' : 'Add Task',
            }}
            onSave={onSaveTask}
            task={editTask !== undefined && value[editTask] ? value[editTask] : undefined}
            isViewMode={isViewMode}
            data={data}
          />
        )}

        <TemplateModal
          title={taskTemplateModalTitle}
          isOpen={showTemplateModal}
          onClose={() => setShowTemplateModal(false)}
          contentClassName={styles.taskTemplateModalContent}
          modalHeaderClassName={styles.taskTemplateModalHeader}
          innerHolderClassName={styles.taskTemplatesInnerHolder}
          testid="task_templates"
        >
          <TaskTemplates
            isTransactionTask={true}
            isPreForm={true}
            onSubmit={handleTemplate}
            className={styles.taskTemplate}
            isExpandGroup={isExpandGroup}
            setIsExpandGroup={setIsExpandGroup}
            selectedGroupName={selectedGroupName}
            setSelectedGroupName={setSelectedGroupName}
          />
        </TemplateModal>

        <DragDropContext onDragEnd={updateList}>
          {value?.length ? (
            <Droppable droppableId="task-list">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.paneContainer}
                >
                  {value.map((task, index) =>
                    duplicateOffsets.has(task.ControlOperatorOffset) ? (
                      // Draggable tasks (if ControlOperatorOffset is duplicated)
                      <Draggable
                        key={task.Name}
                        draggableId={task.Name}
                        index={index}
                        isDragDisabled={isViewMode}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.taskCardWrapper}
                          >
                            <Drag {...provided.dragHandleProps} className={styles.dragIcon} />
                            <TaskCard
                              task={task}
                              setEditTask={handleEditTask}
                              handleRemove={onRemoveTask}
                              taskNumber={index}
                              isViewMode={isViewMode}
                            />
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      // Non-draggable tasks (if ControlOperatorOffset is unique)
                      <TaskCard
                        key={task.Name}
                        task={task}
                        setEditTask={handleEditTask}
                        handleRemove={onRemoveTask}
                        taskNumber={index}
                        isViewMode={isViewMode}
                      />
                    ),
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : null}
        </DragDropContext>

        {!isViewMode && (
          <>
            {getTemplateButtons([
              {
                name: 'Create New',
                icon: <AddNew />,
                onClick: handleNewTask,
              },
              {
                name: 'Templates',
                icon: <Timeline />,
                onClick: () => !isViewMode && setShowTemplateModal(true),
              },
            ])}
            <UploadTasks handleUpload={handleUpload} isViewMode={isViewMode} />
          </>
        )}
      </>
    );
  }

  return <></>;
};
