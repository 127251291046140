import { GenerateHtml, InputLabel, isEmptyHTML, RichTextEditor } from 'components';
import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';
import { TaskSectionContainer } from '../../Task/ViewRevamped/components';
import { Icons } from './../../Task/ViewRevamped/Icons';
import { EditCircle } from 'components/Icons';

import styles from './styles.module.scss';

interface DescriptionProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  onEdit: () => void;
  isViewMode?: boolean;
  showOptional?: boolean | null;
  addDescription?: boolean | null;
  inputWrapperClassName?: string;
  viewContentClassname?: string;
  taskSectionClassName?: string;
  labelClasssName?: string;
}

export const Description = ({
  values,
  onChangeField,
  isViewMode,
  onEdit,
  showOptional,
  addDescription,
  inputWrapperClassName,
  viewContentClassname,
  taskSectionClassName,
  labelClasssName,
}: DescriptionProps) => {
  return (
    <div className={styles.description}>
      {addDescription || !isEmptyHTML(values?.Description) ? (
        addDescription ? (
          <TaskSectionContainer className={(styles.spacing, taskSectionClassName)}>
            <InputLabel
              label="Description"
              className={(styles.label, labelClasssName)}
              showOptional={showOptional}
            />
            <RichTextEditor
              value={values?.Description}
              placeholder="Add Description"
              onChange={(value) => onChangeField(value, 'Description')}
              disabled={isViewMode}
            />
          </TaskSectionContainer>
        ) : (
          <TaskSectionContainer
            icon={<Icons variant={Icons.DESCRIPTION} />}
            title="Description"
            titleClassName={styles.title}
            className={styles.spacing}
            contentClassName={styles.contentWrapper}
          >
            <div className={viewContentClassname}>
              <GenerateHtml
                content={`<span>${values?.Description}</span>`}
                className={styles.threeLines}
              />
              <EditCircle onClick={onEdit} className={styles.editIcon} />
            </div>
          </TaskSectionContainer>
        )
      ) : null}
    </div>
  );
};
