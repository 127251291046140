import { useState, useEffect, useMemo } from 'react';

import { Row, Col, Button } from 'components-antd';
import { Input, InputLabel } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { TaskEditableFormValuesKeys, TaskEditableFormValuesType } from 'types/transactionTasks';
import { TaskSectionContainer } from '../../Task/ViewRevamped/components';
import { EditCircle, MinusRounded } from 'components/Icons';
import { Icons } from './../../Task/ViewRevamped/Icons';

import styles from './styles.module.scss';

interface LinksProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  className?: string | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  editData?: any;
  addLink?: boolean;
  viewContentClassname?: string;
  onEdit: () => void;
}

interface Link {
  label: string;
  link: string;
}

export const Links = ({
  values,
  editData,
  onChangeField,
  isViewMode,
  showOptional,
  addLink,
  viewContentClassname,
  onEdit,
}: LinksProps) => {
  const initialLinkState = { label: '', link: '' };
  const [listValue, setListValue] = useState<Link[]>(values?.Links ?? []);

  const onAdd = () => {
    setListValue((prev) => [...prev, initialLinkState]);
    onChangeField(listValue, 'Links');
  };

  const onChangeLabel = (value: string, index: number) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, { link: listValue[index].link, label: value });
    setListValue([...copyList]);
    onChangeField([...copyList], 'Links');
  };

  const onChangeLink = (value: string, index: number) => {
    const copyList = [...listValue];
    copyList.splice(index, 1, { link: value, label: listValue[index].label });
    setListValue([...copyList]);
    onChangeField([...copyList], 'Links');
  };

  const onRemove = (index) => {
    const copyList = [...listValue];
    copyList.splice(index, 1);
    setListValue(copyList);
    onChangeField(copyList, 'Links');
  };

  useEffect(() => {
    if (addLink) {
      onAdd();
    }
  }, [addLink]);

  const getItem = ({ index, item }) => {
    return (
      <div className={styles.linkWrapper}>
        <Row gutter={16} align="middle">
          <Col lg={11}>
            <Input
              label="Name"
              name={`${index}-label`}
              value={item.label}
              placeholder="Add label"
              onChange={(e, value) => onChangeLabel(value, index)}
              disabled={isViewMode}
              variant={Input.LIGHT_ROUND}
            />
          </Col>
          <Col lg={11}>
            <Input
              label="URL"
              name={`${index}-link`}
              value={item.link}
              placeholder="Add link address"
              onChange={(e, value) => onChangeLink(value, index)}
              disabled={isViewMode}
              variant={Input.LIGHT_ROUND}
            />
          </Col>
          <Col lg={2}>
            <MinusRounded
              className={styles.removeIcon}
              onClick={() => !isViewMode && onRemove(index)}
            />
          </Col>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    const links = values?.Links || editData?.Links;
    if (links?.length) {
      setListValue(links);
    }
  }, []);

  const areLinksAdded = useMemo(
    () => listValue?.some((item) => item?.link.trim() !== '' || item?.label.trim() !== ''),
    [listValue],
  );

  return (
    <div className={styles.links}>
      {addLink || areLinksAdded ? (
        addLink ? (
          <TaskSectionContainer className={styles.spacing}>
            <Row align="middle">
              <Col xs={20} sm={20} md={22} lg={22} xl={22}>
                <InputLabel label="Links" className={styles.label} showOptional={showOptional} />
              </Col>
              <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                <Icon
                  variant={Icon.ADDLINK}
                  className={styles.addLinkIcon}
                  onClick={() => !isViewMode && onAdd()}
                />
              </Col>
            </Row>

            {listValue.map((item, index) => (
              <div key={index}>{getItem({ index, item })}</div>
            ))}
          </TaskSectionContainer>
        ) : (
          <TaskSectionContainer
            title="Links"
            titleClassName={styles.title}
            icon={<Icons variant={Icons.LINKS} />}
            className={styles.spacing}
          >
            <div className={viewContentClassname}>
              <div className={styles.linksContainer}>
                {listValue?.map((item, idx) => (
                  <div key={idx}>{item?.label}</div>
                ))}
              </div>
              <EditCircle onClick={onEdit} className={styles.editIcon} />
            </div>
          </TaskSectionContainer>
        )
      ) : null}
    </div>
  );
};
