import { handleActions } from 'redux-actions';
import { IDLE, READY } from 'settings/constants/apiState';
import { get, map } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetPartnersListAction,
  partnersSetSearchAction,
  partnersClearSearchAction,
  partnersResetAction,
  partnersChangeRecommendPartnerAction,
  requestGetRecommendedPartnersByAgentListAction,
  toggleRequestQuoteBtnAction,
  setVendorEditModeId,
  vendorCreateAction,
  vendorArchiveAction,
  setPartnerDraftsAsRecommendations,
  vendorInviteAction,
  setVendorDraftEditModeId,
  vendorDraftToInviteAction,
  vendorDraftUpdateAction,
  setRecommendedPartnersByAgentListAction,
  deletePartnerDraftsAsRecommendations,
  vendorProfileUpdateAction,
  setVendorEditInviteMode,
  removeRecommendationById,
  cancelRecommendationInviteById,
  setVendorEditStrictlyProfile,
  replaceInRecommendationListAction,
} from 'store/actions/partners';

const initialData = {
  search: '',
  recommendState: IDLE,
  state: IDLE,
  data: null,
  meta: {},
  recommendations: {
    state: IDLE,
    data: null,
  },
  showRequestQuoteBtn: false,
  vendorEditModeId: undefined,
  vendorEditInviteMode: false,
  draftEditModeId: undefined,
  vendorEditStrictlyProfile: false,
};

export default handleActions(
  {
    [requestGetPartnersListAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: payload?.meta || state.meta,
    }),
    [partnersChangeRecommendPartnerAction]: (state, { payload }) => {
      if (payload?.state === READY) {
        const newElement = get(payload, ['data', 'result']);

        return {
          ...state,
          data: map(state?.data || [], (partner) =>
            partner?.Id === newElement?.Id ? newElement : partner,
          ),
          recommendState: payload.state,
        };
      }

      return {
        ...state,
        recommendState: payload.state,
      };
    },
    [partnersClearSearchAction]: (state) => ({
      ...state,
      search: '',
    }),
    [partnersSetSearchAction]: (state, { payload }) => ({
      ...state,
      search: get(payload, 'search'),
    }),
    [partnersResetAction]: () => initialData,
    [appLogoutAction]: () => initialData,
    [requestGetRecommendedPartnersByAgentListAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        state: payload.state,
        data: get(payload, ['data', 'result']),
      },
    }),
    [toggleRequestQuoteBtnAction]: (state, { payload }) => ({
      ...state,
      showRequestQuoteBtn: payload,
    }),
    [setVendorEditModeId]: (state, { payload }) => ({
      ...state,
      vendorEditModeId: payload,
    }),
    [setVendorEditInviteMode]: (state, { payload }) => ({
      ...state,
      vendorEditInviteMode: payload,
    }),
    [setVendorEditStrictlyProfile]: (state, { payload }) => ({
      ...state,
      vendorEditStrictlyProfile: payload,
    }),
    [setVendorDraftEditModeId]: (state, { payload }) => ({
      ...state,
      draftEditModeId: payload,
    }),
    [vendorCreateAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? [...state.recommendations.data, payload]
          : [{ ...payload, isMyRecommended: true }],
      },
    }),
    [setPartnerDraftsAsRecommendations]: (state, { payload }) => ({
      ...state,
      recommendations: {
        state: IDLE,
        data: payload.isNew
          ? [
              ...(state?.recommendations?.data ?? []),
              ...payload.drafts.map((item) => ({ ...item, isDraft: true })),
            ]
          : payload.drafts.map((item) => ({ ...item, isDraft: true })),
      },
    }),
    [deletePartnerDraftsAsRecommendations]: (state, { payload }) => {
      if (payload.IsSpecial) {
        const index = state.recommendations.data.findIndex(
          (item) => item.isDraft && item.IsSpecial && item.Id === payload.id,
        );
        if (index !== -1 && state.recommendations.data?.length)
          return {
            ...state,
            recommendations: {
              state: IDLE,
              data: state.recommendations.data.splice(index, 1),
            },
          };
      }
      return {
        ...state,
        recommendations: {
          state: IDLE,
          data: state?.recommendations?.data?.length
            ? state.recommendations.data.filter((item) => item.isDraft && item.Id !== payload.id)
            : [],
        },
      };
    },
    [vendorArchiveAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? state.recommendations.data.map((recommendation) => {
              if (recommendation.Id !== payload.Id) return recommendation;
              else return { ...recommendation, IsArchived: payload.archived };
            })
          : [],
      },
    }),
    [vendorInviteAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? state.recommendations.data.map((recommendation) => {
              if (recommendation.Id !== payload) return recommendation;
              else return { ...recommendation, IsPublished: false };
            })
          : [],
      },
    }),
    [vendorDraftToInviteAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? state.recommendations.data.filter(
              (recommendation) => recommendation.isDraft && recommendation.Id !== payload,
            )
          : [],
      },
    }),
    [vendorDraftUpdateAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? state.recommendations.data.map((recommendation) => {
              if (recommendation.Id !== payload.Id || !recommendation.isDraft)
                return recommendation;
              else return payload;
            })
          : [],
      },
    }),
    [vendorProfileUpdateAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? state.recommendations.data.map((recommendation) => {
              if (recommendation.Id !== payload.Id) return recommendation;
              return {
                ...recommendation,
                BusinessName: payload.BusinessName,
                AreasOfOperation: payload?.AreasOfOperation?.length ? payload.AreasOfOperation : [],
                PartnerTags: payload?.PartnerTags?.length ? payload.PartnerTags : [],
              };
            })
          : [],
      },
    }),
    [setRecommendedPartnersByAgentListAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        state: payload.state,
        data: payload.data,
      },
    }),
    [removeRecommendationById]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.length
          ? state.recommendations.data.filter((recommendation) => recommendation.Id !== payload)
          : [],
      },
    }),
    [cancelRecommendationInviteById]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.map((item) => {
          if (item.Id !== payload) return item;
          return { ...item, IsInvited: false };
        }),
      },
    }),
    [replaceInRecommendationListAction]: (state, { payload }) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        data: state.recommendations?.data?.map((item) => {
          if (item.Id !== payload.targetId) return item;
          return payload.updatedPartner;
        }),
      },
    }),
  },
  initialData,
);
