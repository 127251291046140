import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';

import styles from './styles.module.scss';
import { filter, some } from 'lodash-es';

const Fields = ({ className, onChange, list, activeFields, order: orderMain }) => {
  const [actives, setActives] = useState(activeFields);

  useEffect(() => setActives(activeFields), [activeFields]);

  const pushOrRemove = ({ arr, value, order, multiple = false }) => {
    const exists = some(arr, (item) => item.value === value && item.order === order);

    return exists
      ? filter(arr, (activeId) => activeId !== value)
      : [...(multiple ? arr : []), value];
  };

  const onClickHandler = (val, order) => {
    const newVal = pushOrRemove({
      arr: actives,
      value: val,
      order: order,
      multiple: false,
    });
    if (newVal?.length) {
      setActives(newVal);
      onChange(newVal, order);
    }
  };

  if (!list) return null;

  return (
    <ul className={classNames(styles.sorts, className)}>
      {list.map(({ label, value, order }) => {
        const isActive = actives.includes(value) && order === orderMain;
        return (
          <li
            testid="sorting_field"
            key={value}
            className={styles.item}
            onClick={() => onClickHandler(value, order)}
          >
            <span className={classNames(styles.label, { [styles.active]: isActive })}>{label}</span>
            {isActive && <Icon variant={Icon.GREEN_LIGHT_CHECKMARK} />}
          </li>
        );
      })}
    </ul>
  );
};

Fields.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  activeFields: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

Fields.defaultProps = {
  className: '',
  list: [],
  activeFields: [],
  onChange: () => {},
};

export default Fields;
