import { SVGProps } from 'react';

export const OpenBook = ({ stroke = '', className = '', onClick = () => {} }) => {
  return (
    <div className={className} style={{ display: 'flex' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M2.5 15.8329C3.64014 15.1747 4.93347 14.8281 6.25 14.8281C7.56652 14.8281 8.85986 15.1747 10 15.8329C11.1401 15.1747 12.4335 14.8281 13.75 14.8281C15.0665 14.8281 16.3599 15.1747 17.5 15.8329"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 4.99895C3.64014 4.34069 4.93347 3.99414 6.25 3.99414C7.56652 3.99414 8.85986 4.34069 10 4.99895C11.1401 4.34069 12.4335 3.99414 13.75 3.99414C15.0665 3.99414 16.3599 4.34069 17.5 4.99895"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 5V15.8333"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 5V15.8333"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 5V15.8333"
          stroke={stroke || '#747475'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
