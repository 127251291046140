import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { KitReplyInnerIcon } from 'pages/Radar/RadarKits/components/icons/KitReplyInnerIcon';
import styles from '../styles.module.scss';

export const ReplyAvatar = ({ clientName }) => {
  return (
    <div className={styles.kitReplyAvatarWrapper}>
      <Avatar
        avatarClassName={styles.kitReplyAvatar}
        src={null}
        placeholder={convertNameToAvatarPlaceholder(clientName)}
      />
      <KitReplyInnerIcon className={styles.kitReplyAvatarInner} />
    </div>
  );
};
