import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../styles.module.scss';
import PartnersList from '../PartnersList';
import { toggleRequestQuoteBtnAction } from 'store/actions/partners';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { openLocationModalEffect } from 'store/effects/concierge';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { Cross } from 'components/Icons';
import {
  getAgentPreparedPartnersList,
  showRequestQuoteBtnSelector,
} from 'store/selectors/partners';

const AllTab = ({ categoryTitle }) => {
  const dispatch = useDispatch();
  const list = useSelector(getAgentPreparedPartnersList);
  const { areasOfOperation } = useSelector(getConciergeSearchSelector);
  const showRequestQuoteBtn = useSelector(showRequestQuoteBtnSelector);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);

  useEffect(() => {
    if (areasOfOperation?.length === 0) dispatch(openLocationModalEffect(true));
  }, []);

  useEffect(() => {
    if (list?.length > 0) {
      !showRequestQuoteBtn && dispatch(toggleRequestQuoteBtnAction(true));
    } else {
      showRequestQuoteBtn && dispatch(toggleRequestQuoteBtnAction(false));
    }
  }, [list, dispatch, showRequestQuoteBtn]);

  const activePartnersList = useMemo(() => {
    if (!list) return;
    if (multiple.modeEnable) {
      return list.filter(
        (partner) =>
          partner?.ThirdParty?.User?.CompletedProfile &&
          partner?.IsActive &&
          !partner?.IsArchived &&
          !partner?.isDraft,
      );
    }
    return list;
  }, [list, multiple.modeEnable]);

  return (
    <>
      {areasOfOperation?.length ? (
        <PartnersList
          list={activePartnersList?.filter((partner) => !partner?.isDraft)}
          tabName={'all'}
        />
      ) : null}
    </>
  );
};

export default AllTab;
