export const VALIDATION_TYPES = {
  AtLeast: 'AtLeast',
  AtMost: 'AtMost',
  Exactly: 'Exactly',
  InRange: 'InRange',
};

export const fieldValidationTypes = [
  {
    label: 'At least',
    value: VALIDATION_TYPES.AtLeast,
  },
  {
    label: 'At most',
    value: VALIDATION_TYPES.AtMost,
  },
  {
    label: 'Exactly',
    value: VALIDATION_TYPES.Exactly,
  },
  {
    label: 'Range',
    value: VALIDATION_TYPES.InRange,
  },
];

export const validationTypeOptions = {
  Atleast: { min: 1, max: -1 },
  Atmost: { min: -1, max: 1 },
  Exactly: { min: 1, max: 1 },
  InRange: { min: 1, max: 1 },
};
