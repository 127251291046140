import { get, cloneDeep, isNumber } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  setTransactionTaskParamsAction,
  getTransactionTaskAction,
  resetTransactionTaskAction,
  updateTransactionTaskAction,
  uploadTransactionTaskDocumentsAction,
  deleteTransactionTaskDocumentAction,
  updateTransactionTaskChecklistAction,
  updateTransactionTaskDueDateAction,
  updateTransactionTaskStatusAction,
} from 'store/actions/transactions';
import {
  getCampaignTaskAction,
  updateCampaignTaskAction,
  deleteCampaignTaskDocumentAction,
  uploadCampaignTaskDocumentsAction,
} from 'store/actions/radarKits';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  isTaskForm: false,
  isTaskDetail: false,
  transactionId: null,
  campaignId: null,
  taskId: null,
  formValues: null,
  IsBundle: null,
  formTask: null,
  formDocuments: null,
  linkedForms: null,
  canBeginSigning: false,
  canResumeSigning: false,
  canSignForm: false,
  canViewForm: false,
};

export default handleActions(
  {
    [setTransactionTaskParamsAction]: (state, { payload }) => ({
      ...state,
      isTaskForm: get(payload, 'isTaskForm', state.isTaskForm),
      isTaskDetail: get(payload, 'isTaskDetail', state.isTaskDetail),
      transactionId: get(payload, 'transactionId', state.transactionId),
      campaignId: get(payload, 'campaignId', state.campaignId),
      taskId: get(payload, 'taskId', state.taskId),
      formValues: get(payload, 'formValues', initialData.formValues),
    }),
    [getTransactionTaskAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [getCampaignTaskAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        state: get(payload, 'state'),
        data: get(payload, 'data', null),
        meta: get(payload, 'meta'),
      };
    },
    [updateCampaignTaskAction.toString()]: (state, { payload }) => {
      const currentTaskId = get(state, 'data.Id');
      const updatedData = get(payload, 'data', state?.data);
      const shouldUpdateData = updatedData?.Id === currentTaskId;
      return {
        ...state,
        data: shouldUpdateData ? updatedData : state?.data,
      };
    },
    [deleteCampaignTaskDocumentAction.toString()]: (state, { payload }) => {
      const currentTaskId = get(state, 'data.Id');
      const updatedData = get(payload, 'data.result', state?.data);
      const shouldUpdateData = updatedData?.Id === currentTaskId;
      return {
        ...state,
        data: shouldUpdateData ? updatedData : state?.data,
      };
    },
    [uploadCampaignTaskDocumentsAction.toString()]: (state, { payload }) => {
      const currentTaskId = get(state, 'data.Id');
      const updatedData = get(payload, 'data.result', state?.data);
      const shouldUpdateData = updatedData?.Id === currentTaskId;
      return {
        ...state,
        data: shouldUpdateData ? updatedData : state?.data,
      };
    },
    [updateTransactionTaskAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = get(state, 'data.Id');
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: newTaskData || state.data,
      };
    },
    [uploadTransactionTaskDocumentsAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = get(state, 'data.Id');
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: {
          ...state.data,
          Documents: newTaskData.Documents,
        },
      };
    },
    [deleteTransactionTaskDocumentAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = get(state, 'data.Id');
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: {
          ...state.data,
          Documents: newTaskData.Documents,
        },
      };
    },
    [updateTransactionTaskChecklistAction]: (state, { payload }) => {
      const task = get(payload, 'data.result', []);

      return {
        ...state,
        data: {
          ...state.data,
          ...(task?.Status ? { Status: task?.Status } : {}),
          ...(task?.StatusId ? { StatusId: task?.StatusId } : {}),
          ...(task?.Checklists ? { Checklists: task?.Checklists } : {}),
        },
      };
    },
    [updateTransactionTaskDueDateAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = payload.meta.taskId;
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: {
          ...state.data,
          ...(newTaskData?.DueDate ? { DueDate: newTaskData?.DueDate } : {}),
          ...(isNumber(newTaskData?.Hours) ? { Hours: newTaskData?.Hours } : { Hours: null }),
          ...(isNumber(newTaskData?.Minutes)
            ? { Minutes: newTaskData?.Minutes }
            : { Minutes: null }),
          ...(newTaskData?.IsBusinessDays ? { IsBusinessDays: newTaskData?.IsBusinessDays } : {}),
          ...(newTaskData?.Timezone ? { Timezone: newTaskData?.Timezone } : {}),
          ...(newTaskData?.Status ? { Status: newTaskData?.Status } : {}),
          ...(newTaskData?.StatusId ? { StatusId: newTaskData?.StatusId } : {}),
          ...(newTaskData?.AssigneeList ? { AssigneeList: newTaskData?.AssigneeList } : {}),
          ...(newTaskData?.CcList ? { CcList: newTaskData?.CcList } : {}),
          ...(newTaskData?.Order ? { Order: newTaskData?.Order } : {}),
        },
      };
    },
    [updateTransactionTaskStatusAction]: (state, { payload }) => {
      const tasks = get(payload, 'data.result.Tasks', []);
      const currentTaskId = payload.meta.taskId;
      const newTaskData = tasks.find((task) => task?.Id === currentTaskId);

      return {
        ...state,
        data: {
          ...state.data,
          ...(newTaskData?.Status ? { Status: newTaskData?.Status } : {}),
          ...(newTaskData?.StatusId ? { StatusId: newTaskData?.StatusId } : {}),
        },
      };
    },
    [resetTransactionTaskAction]: () => cloneDeep(initialData),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
