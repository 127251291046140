import React from 'react';
import PropTypes from 'prop-types';

const Archive = ({ className, onClick, color = '#FF576D' }) => (
  <div testid="archive_icon" onClick={onClick} className={className}>
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7L2.19004 8.4293C2.10484 8.57965 2.06006 8.74952 2.06006 8.92233V11.2787C2.06006 12.3833 2.95549 13.2787 4.06006 13.2787H11.9401C13.0446 13.2787 13.9401 12.3833 13.9401 11.2787V8.92235C13.9401 8.74953 13.8953 8.57965 13.81 8.42929L13 7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.37988 6.67969H5.68988V7.00969C5.68988 8.28546 6.72411 9.31969 7.99988 9.31969V9.31969C9.27566 9.31969 10.3099 8.28546 10.3099 7.00969V6.67969H12.6199"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1V5.5M8 1L5.5 3.5M8 1L10.5 3.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

Archive.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

Archive.defaultProps = {
  className: '',
  onClick: () => {},
  color: '#FF576D',
};

export default Archive;
