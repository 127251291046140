import { memo, useMemo } from 'react';

const Cross = memo(({ className, color, size, onClick, variant }: any) =>
  useMemo(() => {
    if (variant === 'small')
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.6">
            <path
              d="M4.5 4.5L11.5 11.5"
              stroke="#252D44"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 4.5L4.5 11.5"
              stroke="#252D44"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );

    return (
      <div className={className} onClick={onClick}>
        <svg
          width={size ?? '20'}
          height={size ?? '20'}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.5 14.5L14.5 5.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
          <path d="M14.5 14.5L5.5 5.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
      </div>
    );
  }, [className, color, variant]),
);

export default Cross;
