import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'components';
import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, onSave, onCancel, isPending, newFormValues } = props;

  const isSaveDisabled = () => {
    if (!newFormValues) return true;

    const { Title, Category, DueDate, Assignee, customTag } = newFormValues;

    return !Title || (!Category && !customTag) || !DueDate || !Assignee;
  };

  return (
    <div className={classNames(styles.actions, className)}>
      <Button
        title="Save"
        className={classNames(styles.btn)}
        onClick={onSave}
        loaderClassName={styles.loader}
        isPending={isPending}
        disabled={isSaveDisabled()}
        testid="save_button"
      />
      <Button
        title="Cancel"
        className={classNames(styles.btn, styles.cancel)}
        onClick={onCancel}
        testid="cancel_button"
      />
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isPending: PropTypes.bool,
  newFormValues: PropTypes.shape({
    Title: PropTypes.string,
    Category: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    customTag: PropTypes.string,
    DueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    Assignee: PropTypes.shape({}),
  }),
};

Actions.defaultProps = {
  className: '',
  onSave: () => {},
  onCancel: () => {},
  isPending: false,
  newFormValues: null,
};

export default Actions;
