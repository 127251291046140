import { Input } from 'components';
import { Button, Modal } from 'components-antd';
import parentStyles from 'components/ClientsDrawer/ProfileClient/ExpandedSection/Clients/components/AssignClientsModal/styles.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetClientsListWithAgentsEffect } from 'store/effects/clientsList';
import { getRetainedClientsList } from 'store/selectors/clientsList';
import { AssignClientItem } from '../AssignClientItem/AssignClientItem';
import {
  CampaignMemberAgentClient,
  CampaignMemberClient,
} from 'pages/Radar/RadarKits/AssignClientItem/types';
import { AssignAgentItem } from 'pages/Radar/RadarKits/AssignAgentItem/AssignAgentItem';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  subHeading: string;
  selected: {
    ClientId: number;
    AddressId: number;
  }[];
  setSelected: React.Dispatch<
    React.SetStateAction<
      {
        ClientId: number;
        AddressId: number;
      }[]
    >
  >;
  handleClose: () => void;
};

export const AssignClientsModal: React.FC<Props> = ({
  isOpen,
  closeDialog,
  subHeading,
  selected,
  setSelected,
  handleClose,
}) => {
  const [searchString, setSearchString] = useState<string>('');
  const dispatch = useDispatch();

  const teamRole = useSelector(getAgentTeamRoleSelector);
  const isAdmin = [TEAM_OWNER, TEAM_ADMIN].includes(teamRole);

  const clientsListData = useSelector(getRetainedClientsList);

  useEffect(() => {
    dispatch(requestGetClientsListWithAgentsEffect({}, { silent: clientsListData?.length !== 0 }));
  }, []);

  const onChangeSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const onChangeHandler = (e, ClientId, AddressId) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, { ClientId, AddressId }]);
    } else {
      setSelected((prev) =>
        prev.filter((item) => !(item.ClientId === ClientId && item.AddressId === AddressId)),
      );
    }
  };

  const flattenedList = useMemo(() => {
    const addressList = clientsListData.flatMap((client) =>
      client.addresses.map((address) => ({
        isArchive: address.isArchive,
        clientId: client.id,
        clientName: client.name,
        addressId: address.Id,
        address: address.address,
        avatarUrl: client.AvatarUrl,
      })),
    );

    const groupedByClient = addressList.reduce((acc, item) => {
      if (!acc[item.clientId]) {
        acc[item.clientId] = {
          clientId: item.clientId,
          clientName: item.clientName,
          avatarUrl: item.avatarUrl,
          addresses: [],
        };
      }
      if (!item.isArchive) {
        acc[item.clientId].addresses.push({
          address: item.address,
          clientId: item.clientId,
          addressId: item.addressId,
          delivery_line_1: item.address.delivery_line_1,
          last_line: item.address.last_line,
        });
      }
      return acc;
    }, {});

    return Object.values(groupedByClient) as CampaignMemberClient[];
  }, [clientsListData]);

  const clientsList = useMemo(() => flattenedList, [flattenedList]);

  const allAddressesCount = useMemo(
    () => clientsList.reduce((count, client) => count + client.addresses.length, 0),
    [clientsList],
  );

  const groupedByAgents = useMemo(() => {
    const grouped = clientsListData.reduce((acc, client) => {
      client?.agents?.forEach((agent) => {
        if (!acc[agent.Id]) {
          acc[agent.Id] = { ...agent, clients: [] };
        }
        acc[agent.Id].clients.push({
          clientId: client.id,
          clientName: client.name,
          avatarUrl: client.AvatarUrl,
          addresses: client.addresses
            .filter((addr) => !addr.isArchive)
            .map((addr) => ({
              address: addr.address,
              addressId: addr.Id,
              clientId: client.id,
              delivery_line_1: addr.address.delivery_line_1,
              last_line: addr.address.last_line,
            })),
        });
      });
      return acc;
    }, {});

    return Object.values(grouped) as CampaignMemberAgentClient[];
  }, [clientsListData]);

  const filteredClients = useMemo(() => {
    const data = isAdmin ? groupedByAgents : clientsList;
    if (searchString) {
      if (isAdmin) {
        return data.filter((agent: any) =>
          agent?.clients?.some((client) =>
            client.clientName.toLowerCase().includes(searchString.toLowerCase()),
          ),
        );
      } else {
        return data?.filter((client: any) =>
          `${client.clientName}`.toLowerCase().includes(searchString.toLowerCase()),
        );
      }
    }
    return data;
  }, [searchString, groupedByAgents, clientsList, isAdmin]);

  const allAddressesSelected = useMemo(() => {
    if (isAdmin) {
      const totalAdminAddresses = groupedByAgents?.flatMap((agent: any) =>
        agent.clients.flatMap((client) => client.addresses),
      ).length;
      return selected.length === totalAdminAddresses && selected.length > 0;
    } else {
      return selected.length === allAddressesCount && selected.length > 0;
    }
  }, [selected, groupedByAgents, allAddressesCount, isAdmin]);

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      if (isAdmin) {
        const allAddressPairs = groupedByAgents?.flatMap((agent: any) =>
          agent.clients.flatMap((client) =>
            client.addresses.map((address) => ({
              ClientId: client.clientId,
              AddressId: address.addressId,
            })),
          ),
        );
        setSelected(allAddressPairs);
      } else {
        const allAddressPairs = clientsList?.flatMap((client) =>
          client.addresses.map((address) => ({
            ClientId: client.clientId,
            AddressId: address.addressId,
          })),
        );
        setSelected(allAddressPairs);
      }
    } else {
      setSelected([]);
    }
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={closeDialog}
      maskClosable={false}
      destroyOnClose
      className={parentStyles.assignClientModal}
    >
      <div className={parentStyles.header}>
        <h2 className={parentStyles.title}>Enroll Clients</h2>
        <p className={parentStyles.subHeading}>{subHeading}</p>
      </div>
      <div className={parentStyles.secondaryHeader}>
        <Input
          className={parentStyles.searchInput}
          placeholder="Search clients..."
          variant={Input.LIGHT_ROUND}
          type="search"
          value={searchString}
          onChange={onChangeSearchString}
        />
      </div>
      <div className={parentStyles.content}>
        <div className={parentStyles.clientsContainer}>
          <AssignClientItem
            key="ALL"
            onChange={handleSelectAllChange}
            all
            allSelected={allAddressesSelected}
          />
          {isAdmin
            ? filteredClients.map((agent: any) => (
                <AssignAgentItem
                  key={agent.Id}
                  agent={agent}
                  selected={selected}
                  onChange={onChangeHandler}
                />
              ))
            : filteredClients.map((client: any, idx) => (
                <AssignClientItem
                  key={idx}
                  client={client}
                  selected={selected}
                  onChange={onChangeHandler}
                />
              ))}
        </div>
      </div>
      <div className={parentStyles.footer}>
        <Button variant="secondary" className={parentStyles.doneBtn} onClick={handleClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};
