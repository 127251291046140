import { useState } from 'react';
import { Checkbox } from 'antd';
import { Navigation } from '../../components/Footer';
import { Input, Phone } from '../../../../../../components';
import classNames from 'classnames';
import { ErrorMessage } from '../../components/ErrorMessages';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { CardBox } from 'pages/ExternalOffer/OfferSubmission/components/CardBox';
import { ScrollToTop } from 'pages/ExternalOffer/OfferSubmission/components/ScrollToTop';
import { RequiredAsterik, UploadOfferFile } from '../components';
import { prepareOfferValues } from '../components/UploadOfferFile/helper';
import { featureFlags } from 'utils/featureFlags';
import { validateEmail } from 'utils';

const initialFormState = {
  BuyingAgentName: '',
  BuyingAgentBrokerage: '',
  BuyingAgentPhone: '',
  BuyingAgentEmail: '',
  ContactViaEmail: false,
  ContactViaPhone: false,
};

const initialErrorState = {
  BuyingAgentNameError: '',
  BuyingAgentBrokerageError: '',
  BuyingAgentPhoneError: '',
  BuyingAgentEmailError: '',
  ContactViaError: '',
};

export const ContactInformation = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  data,
  loading,
  editModal,
  setCurrentStageIndex,
}) => {
  const {
    BuyingAgentName,
    BuyingAgentBrokerage,
    BuyingAgentPhone,
    BuyingAgentEmail,
    ContactViaEmail,
    ContactViaPhone,
  } = data;

  const [state, setState] = useState({
    BuyingAgentName: BuyingAgentName || '',
    BuyingAgentBrokerage: BuyingAgentBrokerage || '',
    BuyingAgentPhone: BuyingAgentPhone || '',
    BuyingAgentEmail: BuyingAgentEmail || '',
    ContactViaEmail: ContactViaEmail || false,
    ContactViaPhone: ContactViaPhone || false,
  });

  const [validationErrorMessages, setValidationErrors] = useState(initialErrorState);

  // AI-feature testing
  const { contracts } = featureFlags;
  const allowOfferUpload = !editModal && contracts;

  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const validateForm = () => {
    let valid = true;
    let errorMessages = { ...validationErrorMessages };

    if (!state.BuyingAgentName || !state.BuyingAgentName.trim()) {
      errorMessages.BuyingAgentNameError = '*Name is required!';
      valid = false;
    }
    if (!state.BuyingAgentBrokerage || !state.BuyingAgentBrokerage.trim()) {
      errorMessages.BuyingAgentBrokerageError = '*Brokerage is required!';
      valid = false;
    }
    if (!state.BuyingAgentEmail || !state.BuyingAgentEmail.trim()) {
      errorMessages.BuyingAgentEmailError = '*Email is required!';
      valid = false;
    }
    if (state.BuyingAgentEmail && !validateEmail(state.BuyingAgentEmail)) {
      errorMessages.BuyingAgentEmailError = '*Email is not valid!';
      valid = false;
    }
    if (!state.BuyingAgentPhone || !state.BuyingAgentPhone.trim()) {
      errorMessages.BuyingAgentPhoneError = '*Phone is required!';
      valid = false;
    }
    if (!state.ContactViaEmail && !state.ContactViaPhone) {
      errorMessages.ContactViaError = '*Please select how would you like to be contacted!';
      valid = false;
    }

    !valid && setValidationErrors(errorMessages);
    return valid;
  };

  const onSubmit = () => {
    const body = {
      ...state,
    };

    let isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const onReset = () => {
    setState(initialFormState);
    setValidationErrors(initialErrorState);
  };

  const onFillOfferDetails = (offerFileResult) => {
    const details = prepareOfferValues(offerFileResult, { ...data, ...state });

    const {
      BuyingAgentName,
      BuyingAgentBrokerage,
      BuyingAgentEmail,
      BuyingAgentPhone,
      ...restDetails
    } = details;

    setState((prev) => ({
      ...prev,
      BuyingAgentName,
      BuyingAgentBrokerage,
      BuyingAgentEmail,
      BuyingAgentPhone,
    }));

    onUpdate(restDetails);
  };

  return (
    <ScrollToTop>
      <CardBox className={styles.contentWrapper}>
        <div className={styles.contactFileHeader}>
          <IconText variant={'contactInformation'} text={'Contact Information'} />
          {allowOfferUpload && <UploadOfferFile onFillOfferDetails={onFillOfferDetails} />}
        </div>

        <div className={styles.inputsWrapper}>
          <div className={styles.inputBlock}>
            <div className={styles.label}>
              Name <RequiredAsterik />
            </div>
            <Input
              className={styles.input}
              inputHolderClassName={styles.inputHolder}
              variant={Input.LIGHT_ROUND}
              placeholder="Enter name"
              value={state.BuyingAgentName}
              maxLength={50}
              onChange={(e) => {
                setValidationErrors({
                  ...validationErrorMessages,
                  BuyingAgentNameError: '',
                });
                onChange({ BuyingAgentName: e.target.value });
              }}
              name="BuyingAgentName"
              id="BuyingAgentName"
              testid="buyingAgent_name"
            />
            <ErrorMessage
              id="BuyingAgentName"
              message={validationErrorMessages.BuyingAgentNameError}
            />
          </div>

          <div className={styles.inputBlock}>
            <div className={styles.label}>
              Brokerage <RequiredAsterik />
            </div>
            <Input
              className={styles.input}
              inputHolderClassName={styles.inputHolder}
              variant={Input.LIGHT_ROUND}
              placeholder="Enter brokerage"
              value={state.BuyingAgentBrokerage}
              maxLength={50}
              onChange={(e) => {
                setValidationErrors({
                  ...validationErrorMessages,
                  BuyingAgentBrokerageError: '',
                });
                onChange({ BuyingAgentBrokerage: e.target.value });
              }}
              name="BuyingAgentBrokerage"
              id="BuyingAgentBrokerage"
              testid="buyingAgent_Brokerage"
            />
            <ErrorMessage
              id="BuyingAgentBrokerage"
              message={validationErrorMessages.BuyingAgentBrokerageError}
            />
          </div>

          <div className={styles.inputBlock}>
            <div className={styles.label}>
              Email Address <RequiredAsterik />
            </div>
            <Input
              className={styles.input}
              inputHolderClassName={styles.inputHolder}
              variant={Input.LIGHT_ROUND}
              placeholder="Enter email address..."
              value={state.BuyingAgentEmail}
              maxLength={200}
              onChange={(e) => {
                setValidationErrors({
                  ...validationErrorMessages,
                  BuyingAgentEmailError: '',
                });
                onChange({ BuyingAgentEmail: e.target.value });
              }}
              name="BuyingAgentEmail"
              id="BuyingAgentEmail"
              testid="buyingAgent_Email"
            />
            <ErrorMessage
              id="BuyingAgentEmail"
              message={validationErrorMessages.BuyingAgentEmailError}
            />
          </div>

          <div className={styles.inputBlock}>
            <div className={styles.label}>
              Phone Number <RequiredAsterik />
            </div>
            <Phone
              id="BuyingAgentPhone"
              name="BuyingAgentPhone"
              placeholder="(000) 000-0000"
              variant={Input.LIGHT_ROUND}
              onChange={(e) => {
                setValidationErrors({
                  ...validationErrorMessages,
                  BuyingAgentPhoneError: '',
                });
                onChange({ BuyingAgentPhone: e.target.value });
              }}
              className={styles.inputPhoneWrapper}
              inputClassName={styles.input}
              errorClassName={styles.phonesError}
              options={{
                containerClass: styles.rightBlock,
              }}
              value={state.BuyingAgentPhone}
              testid="buyingAgent_Phone"
            />
            <ErrorMessage
              id="BuyingAgentPhone"
              message={validationErrorMessages.BuyingAgentPhoneError}
            />
          </div>

          <div className={styles.lineSeparator} />

          <div className={styles.checkboxBlock}>
            <div className={styles.label}>
              How would you like to be contacted? <RequiredAsterik />
            </div>
            <div className={styles.checkboxWrap}>
              <Checkbox
                type="checkbox"
                name="ContactViaEmail"
                className={classNames(styles.checkbox, 'mosaikCheckbox')}
                value={state.ContactViaEmail}
                onChange={(e) => {
                  setValidationErrors({
                    ...validationErrorMessages,
                    ContactViaError: '',
                  });
                  onChange({ ContactViaEmail: e.target.checked });
                }}
                checked={state.ContactViaEmail}
              >
                Email
              </Checkbox>
              <Checkbox
                type="checkbox"
                className={classNames(styles.checkbox, 'mosaikCheckbox')}
                name="ContactViaPhone"
                value={state.ContactViaPhone}
                onChange={(e) => {
                  setValidationErrors({
                    ...validationErrorMessages,
                    ContactViaError: '',
                  });
                  onChange({ ContactViaPhone: e.target.checked });
                }}
                checked={state.ContactViaPhone}
              >
                Text
              </Checkbox>
            </div>
            <ErrorMessage id="ContactVia" message={validationErrorMessages.ContactViaError} />
          </div>
        </div>

        <Navigation
          data={data}
          onNext={onSubmit}
          onFinish={onSubmit}
          disabled={false}
          edit={editModal}
          isPending={loading}
          onReset={onReset}
          isLast={stageIndex === lastStageIndex}
          className={styles.contactInformationFooter}
        />
      </CardBox>
    </ScrollToTop>
  );
};
