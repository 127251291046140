import classNames from 'classnames';
import styles from './styles.module.scss';
import { Input, InputLabel } from 'components';
import CheckboxComponent from 'components/Form/Checkbox';
import { Info } from 'components/Icons';
import { Tooltip } from 'components-antd';
import VideoIcon from 'components/Icons/video';
import { AddVendorPayloadType, VendorValidationErrors } from '../../constants';
import { useSelector } from 'react-redux';
import { selectVendorEditModeId } from 'store/selectors/partners';

type InviteOrPublishPropsType = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
  errors: VendorValidationErrors;
  setErrors: Function;
};

const InviteOrPublish = (props: InviteOrPublishPropsType) => {
  const { newVendorPayload, setNewVendorPayload, errors, setErrors } = props;
  const vendorEditModeId = useSelector(selectVendorEditModeId);
  const video = false;

  const getInviteTooltip = () => (
    <div className={styles.publishProfileTooltip}>
      An invite will be sent to the vendor to activate their Mosaik account. There is no cost to
      them. This will allow your clients to submit inquiries or quote requests directly through
      Mosaik (as opposed to contacting vendors individually) and give you visibility into vendor
      responsiveness and activity.<br></br>
      <br></br>Vendors are not required to login to Mosaik proactively and will be notified if a
      client (or member of your team) reaches out via Mosaik. Vendors will also be able to manage
      their profiles to ensure accuracy of contact information, services offered, etc.
    </div>
  );

  const getPublishProfileTooltip = () => (
    <div className={styles.publishProfileTooltip}>
      The vendor’s profile will be published and visible to your clients, but clients will not be
      able to contact the vendor directly through Mosaik and response rates will not be tracked. The
      profile will display any information you add for the vendor.
    </div>
  );

  return (
    <div className={styles.content}>
      <div className={styles.section}>
        <InputLabel label="Name" showAsterisk />
        <Input
          placeholder={'Enter name'}
          onBlur={(e) => {
            const value = e.target.value;
            if (!value)
              setErrors((prev) => {
                return { ...prev, businessNameError: 'Enter business name' };
              });
            else if (value.length > 255)
              setErrors((prev) => {
                return { ...prev, businessNameError: 'Max allowed characters: 255' };
              });
          }}
          onChange={(e, val) => {
            setNewVendorPayload((prev) => {
              return { ...prev, BusinessName: val };
            });
            if (val)
              setErrors((prev) => {
                return { ...prev, businessNameError: '' };
              });
          }}
          value={newVendorPayload.BusinessName}
          error=""
          errorClassName={styles.inputError}
          variant={Input.LIGHT_ROUNDED}
        />
        {errors.businessNameError ? (
          <div className={styles.validation}>{errors.businessNameError}</div>
        ) : null}
      </div>
      <div className={styles.selection}>
        <div className={classNames(styles.option, styles.optionInvite)}>
          <div className={styles.left}>
            <CheckboxComponent
              id="invite-vendor"
              label="Invite Vendor"
              checked={!newVendorPayload.Published}
              onChange={(e, v, checked) => {
                if (newVendorPayload.Published)
                  setNewVendorPayload((prev) => {
                    return { ...prev, Published: !prev.Published };
                  });
              }}
              direction={CheckboxComponent.DIRECTION_RIGHT}
              labelTextClassName={styles.inviteCheckboxLabel}
              checkboxClassName={'roundedCheckbox'}
              disabled={vendorEditModeId ? true : false}
              className={vendorEditModeId ? styles.checkboxDisabled : ''}
            />
            <Tooltip
              placement="top"
              overlayClassName={classNames(styles.infoTooltip, 'mosaikTooltip')}
              title={getInviteTooltip()}
              align={{
                offset: [-48, 0],
              }}
            >
              <span>
                <Info className={styles.tooltipIcon} size={'14'} color={'#AAABAB'} />
              </span>
            </Tooltip>
          </div>
          <div className={styles.right}>{'(Recommended)'}</div>
        </div>
        <div className={classNames(styles.option, styles.optionPublish)}>
          <div className={styles.left}>
            <CheckboxComponent
              id="publish-profile"
              label="Publish Profile"
              checked={newVendorPayload.Published}
              onChange={(e, v, checked) => {
                if (!newVendorPayload.Published)
                  setNewVendorPayload((prev) => {
                    return { ...prev, Published: !prev.Published };
                  });
              }}
              direction={CheckboxComponent.DIRECTION_RIGHT}
              labelTextClassName={styles.inviteCheckboxLabel}
              checkboxClassName={'roundedCheckbox'}
              disabled={vendorEditModeId ? true : false}
              className={vendorEditModeId ? styles.checkboxDisabled : ''}
            />
            <Tooltip
              placement="top"
              overlayClassName={classNames(styles.infoTooltip, 'mosaikTooltip')}
              title={getPublishProfileTooltip()}
              align={{
                offset: [-48, 0],
              }}
            >
              <span>
                <Info className={styles.tooltipIcon} size={'14'} color={'#AAABAB'} />
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      {video && (
        <div className={classNames(styles.section, styles.watchVideo)}>
          <VideoIcon />
          <div className={styles.text}>{"What's the difference?"}</div>
          <div className={styles.watch}>Watch video</div>
        </div>
      )}
    </div>
  );
};

export default InviteOrPublish;
