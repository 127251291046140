import { useMemo } from 'react';

import { Checkmark, Info } from 'components/Icons';
import { formatDate } from 'helpers';
import { Tooltip } from 'components-antd';

import styles from './styles.module.scss';

const DateTime = ({ dateTime }) => (
  <>
    {formatDate(dateTime, 'DD/MMM/YYYY')}
    <br />
    {formatDate(dateTime, 'hh:mm A')}
  </>
);

const ValidationStats = ({ validationStats }) => {
  if (!validationStats) return null;

  const {
    ActiveValidationComplete,
    OrphanValidationComplete,
    LastValidationTimestamp,
    LastValidationActiveTotal,
    LastValidationActiveMatched,
    LastValidationActiveMismatched,
    LastValidationActiveMissing,
    LastValidationOrphanTotal,
    LastValidationOrphanReloaded,
    LastValidationOrphanWithdrawn,
  } = validationStats;

  let textColor;

  if (!ActiveValidationComplete || !OrphanValidationComplete) {
    textColor = 'green';
  }

  if (
    LastValidationOrphanWithdrawn > 99 ||
    LastValidationActiveMismatched > 99 ||
    LastValidationOrphanTotal >= 500
  ) {
    textColor = 'red';
  }

  const pStyles = { color: textColor, marginBottom: 8 };

  return (
    <div className={styles.jobDetail}>
      <p style={pStyles}>
        <strong>Last run:</strong> <br />
        <DateTime dateTime={LastValidationTimestamp} />
      </p>
      {LastValidationActiveTotal > 0 && (
        <p style={pStyles}>
          <strong>Total Active (MLS):</strong> &nbsp;
          {LastValidationActiveTotal}
        </p>
      )}
      {LastValidationActiveMatched > 0 && (
        <p style={pStyles}>
          <strong>Active Matched:</strong> &nbsp;
          {LastValidationActiveMatched}
        </p>
      )}
      {LastValidationActiveMismatched > 0 && (
        <p style={pStyles}>
          <strong>Active Mismatched:</strong> &nbsp;
          {LastValidationActiveMismatched}
        </p>
      )}
      {LastValidationActiveMissing > 0 && (
        <p style={pStyles}>
          <strong>Active Missing:</strong> &nbsp;
          {LastValidationActiveMissing}
        </p>
      )}
      {LastValidationOrphanTotal > 0 && (
        <p style={pStyles}>
          <strong>Total Orphans:</strong> &nbsp;
          {LastValidationOrphanTotal}
        </p>
      )}
      {LastValidationOrphanReloaded > 0 && (
        <p style={pStyles}>
          <strong>Orphans Updated:</strong> &nbsp;
          {LastValidationOrphanReloaded}
        </p>
      )}
      {LastValidationOrphanWithdrawn > 0 && (
        <p style={pStyles}>
          <strong>Orphans Withdrawn:</strong> &nbsp;
          {LastValidationOrphanWithdrawn}
        </p>
      )}
      {ActiveValidationComplete && (
        <p style={{ ...pStyles, display: 'flex', gap: 5 }}>
          <strong>Active Validation:</strong>
          <Checkmark color={textColor} />
        </p>
      )}
      {OrphanValidationComplete && (
        <p style={{ ...pStyles, display: 'flex', gap: 5 }}>
          <strong>Orphan Validation:</strong>
          <Checkmark color={textColor} />
        </p>
      )}
    </div>
  );
};

const JobDetails = ({ job }) => {
  if (!job) return null;
  const { attempts, runAt, lockedAt, createdAt, lastError } = job;

  let textColor;

  if (lastError || attempts >= 25) {
    textColor = 'red';
  } else if (!lastError && attempts === 1 && lockedAt) {
    textColor = 'green';
  } else if (attempts > 1) {
    textColor = '#ffb700';
  } else {
    textColor = 'black';
  }

  const pStyles = { color: textColor, marginBottom: 8 };

  const isShowErrorTooltip = useMemo(() => {
    return lastError?.length > 22;
  }, [lastError]);

  return (
    <div className={styles.jobDetail}>
      <p style={pStyles}>
        <strong>Run at:</strong> <br />
        <DateTime dateTime={runAt} />
      </p>
      <p style={pStyles}>
        <strong>Created at:</strong> <br />
        <DateTime dateTime={createdAt} />
      </p>
      {lockedAt && (
        <p style={pStyles}>
          <strong>Locked at:</strong> <br />
          <DateTime dateTime={lockedAt} />
        </p>
      )}
      {lastError && (
        <p style={pStyles}>
          <strong>Last Error:</strong>
          <br />
          <div className={styles.errorContainer}>
            <span className={styles.errorText}>{lastError}</span>
            {isShowErrorTooltip && (
              <Tooltip
                title={<span>{lastError}</span>}
                trigger={'hover'}
                className={styles.errorTooltip}
                getPopupContainer={(trigger) => trigger}
                placement="left"
              >
                <div>
                  <Info className={styles.infoIcon} />
                </div>
              </Tooltip>
            )}
          </div>
        </p>
      )}
      {attempts > 0 && (
        <p style={pStyles}>
          <strong>Attempts:</strong> &nbsp;
          {attempts}
        </p>
      )}
    </div>
  );
};

const Jobs = ({ jobs }) => {
  const job = jobs?.[0];
  return <JobDetails job={job} />;
};

const OpenHouses = ({ openHouses }) => {
  const openHouse = openHouses?.[0]?.openHouseJobs?.[0];

  if (openHouses?.length === 1) {
    return <JobDetails job={openHouse} />;
  }

  return (
    <p style={{ marginBottom: 5 }}>
      <strong>Count:</strong> {openHouses?.length}
    </p>
  );
};

export const gridColumns = (isOldPipeline) => [
  {
    title: 'MLS Code',
    propKey: 'MLSCode',
    render: (MLSCode) => <span>{MLSCode}</span>,
  },
  {
    title: 'Data Provider',
    propKey: 'DataProvider',
    render: (DataProvider) => <span>{DataProvider}</span>,
  },
  ...(isOldPipeline
    ? [
        {
          title: 'Delta Job',
          propKey: 'deltaJob',
          render: (deltaJob) => deltaJob && <Jobs jobs={deltaJob} />,
        },
      ]
    : [
        {
          title: 'Delta Queue Job',
          propKey: 'deltaQueueJobs',
          render: (deltaQueueJobs) => deltaQueueJobs && <Jobs jobs={deltaQueueJobs} />,
        },
        {
          title: 'Delta Process Job',
          propKey: 'deltaProcessJobs',
          render: (deltaProcessJobs) => deltaProcessJobs && <Jobs jobs={deltaProcessJobs} />,
        },
      ]),
  {
    title: 'Open Houses',
    propKey: 'openHouses',
    render: (openHouses) => openHouses && <OpenHouses openHouses={openHouses} />,
  },
  {
    title: 'Validation Jobs',
    propKey: 'validationJobs',
    render: (validationJobs) => validationJobs && <Jobs jobs={validationJobs} />,
  },
  {
    title: 'Validation Stats',
    propKey: 'validationStats',
    render: (validationStats) =>
      validationStats && <ValidationStats validationStats={validationStats} />,
  },
];
