import { useSelector } from 'react-redux';

import {
  getDynamicFormSelector,
  getDynamicFormSignatureConfigSelector,
} from 'store/selectors/requestFormProcess';
import { FieldRenderer } from '../../FieldRenderer';
import { sortDetails } from 'pages/FormProcess/helper';
import { useMemo } from 'react';
import { FormDocumentQuestionType } from 'types';

interface ActiveQuestionsProps {
  pageIndex: number;
  documentIndex?: number;
}

export const ActiveQuestions = ({ pageIndex, documentIndex = -1 }: ActiveQuestionsProps) => {
  const { activeQuestions = [], otherSignatoriesQuestions = [] } = useSelector(
    getDynamicFormSignatureConfigSelector,
  );

  const { bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);

  const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;

  const questions = isCohesiveFlow
    ? bundleDocumentsDetails?.[documentIndex]?.activeQuestions || []
    : activeQuestions;

  let otherQuestions = isCohesiveFlow
    ? bundleDocumentsDetails?.[documentIndex]?.otherSignatoriesQuestions || []
    : otherSignatoriesQuestions;

  otherQuestions = useMemo(
    () => otherQuestions.map((item) => ({ ...item, otherQuestion: true })),
    [otherQuestions],
  );

  const pageActiveQuestions = questions.filter(
    (question) => question.Fields?.[0]?.widgets?.[0]?.pageNumber === pageIndex,
  );

  const pageOtherQuestions = otherQuestions.filter(
    (question) => question.Fields?.[0]?.widgets?.[0]?.pageNumber === pageIndex,
  );

  const pageQuestions = sortDetails([...pageActiveQuestions, ...pageOtherQuestions], 'Id');

  return (
    <>
      {pageQuestions.map(({ otherQuestion, ...question }) => (
        <FieldRenderer
          question={question as FormDocumentQuestionType}
          key={`active-questions-${question.UUID}`}
          documentIndex={documentIndex}
          isCohesiveFlow={isCohesiveFlow}
          otherSignatoryField={otherQuestion}
          pageQuestions={pageQuestions}
        />
      ))}
    </>
  );
};
