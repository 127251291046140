import { PageWrapper } from 'components-antd';
import styles from './styles.module.scss';
import { map } from 'lodash-es';
import { Icons } from './Icons';
import { routes } from 'settings/navigation/routes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import BG_Image from 'images/pinpoint_bg.png';
import {
  selectedSearchAction,
  setCriteriaModeAction,
  setIsClientMatchesAction,
  setSavedSearchTabAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import SearchesModal from '../AgentFeed/components/SearchesModal';
import { appOpenModalEffect, getSavedSearchesEffect, sortFeedEffect } from 'store/effects';
import { CREATE } from 'settings/constants/mode';
import { subFilterTypes } from 'settings/constants/properties';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { useAgentSearchInstance } from '../AgentFeed/hooks/useAgentSearchInstance';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';
import Search from 'pages/Properties/Search';
import { SORT_ASC } from 'settings/constants/sort';

export const AgentDashboard = () => {
  const dispatch = useDispatch();
  const getAgentSearchInstance = useAgentSearchInstance();
  const [showSearch, setShowSearch] = useState(false);
  const isProd = window.location.hostname === 'app.mosaik.io';

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const handleClientSearchesClick = useCallback(() => {
    dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
    dispatch(setSavedSearchTabAction({ savedSearchTab: 2 }));
    openModal(SearchesModal.id);
  }, [dispatch]);

  const handleActivityClick = useCallback(
    () => dispatch(push(routes.propertiesActivity)),
    [dispatch],
  );

  const handleMatchesClick = useCallback(() => {
    dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
    dispatch(setCriteriaModeAction({ mode: CREATE }));
    dispatch(setIsClientMatchesAction({ isClientMatches: true }));
    dispatch(setSubFilterTypeAction(subFilterTypes.NEW));
    dispatch(
      selectedSearchAction({
        selectedSearch: {
          id: undefined,
          name: 'Matches',
        },
      }),
    );
    getAgentSearchInstance(
      { criterias: {} },
      undefined,
      undefined,
      true,
      undefined,
      subFilterTypes.NEW,
    );
    dispatch(push(routes.feed));
  }, [dispatch]);

  const handleToursClick = useCallback(() => dispatch(push(routes.clientTours)), [dispatch]);

  const handleMySearchesClick = useCallback(() => {
    dispatch(getSavedSearchesEffect());
    dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
    dispatch(setSavedSearchTabAction({ savedSearchTab: 1 }));
    openModal(SearchesModal.id);
  }, [dispatch]);

  const handleNewSearchClick = useCallback(() => {
    dispatch(setIsClientMatchesAction({ isClientMatches: false }));
    dispatch(sortFeedEffect({ order: SORT_ASC, fields: ['DaysOnMarket'] }));
    setShowSearch(true);
  }, []);

  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    if ('option' in query) {
      switch (query.option) {
        case 'newSearches':
          handleClientSearchesClick();
          break;
        case 'updatedSearches':
          handleClientSearchesClick();
          break;
        case 'newFavorites':
          handleClientSearchesClick();
          break;
      }
    }
  }, []);

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.mainPageContent} showFooter={false}>
      {showSearch && <Search handleClose={() => setShowSearch(false)} />}
      <SearchesModal setShowSearch={setShowSearch} />
      <p className={styles.heading}>Pinpoint</p>
      <img src={BG_Image} width={'100%'} />
      <div className={styles.content}>
        <div className={styles.cardsContainer}>
          {map(
            [
              { icon: Icons.NEW_SEARCH, title: 'New Search', onClick: handleNewSearchClick },
              {
                icon: Icons.MY_SEARCH,
                title: 'My Searches',
                onClick: handleMySearchesClick,
              },
              { icon: Icons.TOURS, title: 'Tours', onClick: handleToursClick },
              { icon: Icons.MATCHES, title: 'Matches', onClick: handleMatchesClick },
              {
                icon: Icons.CLIENT_SEARCHES,
                title: 'Client Searches',
                onClick: handleClientSearchesClick,
              },
              { icon: Icons.ACTIVITY, title: 'Activity', onClick: handleActivityClick },
            ].filter(({ icon }) => !(isProd && icon === Icons.ACTIVITY)),
            ({ icon, title, onClick }) => (
              <div
                className={styles.card}
                onClick={() => {
                  onClick();
                }}
              >
                <Icons variant={icon} />
                {title}
              </div>
            ),
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
