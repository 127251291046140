import { Modal, Select, Button } from 'components-antd';
import styles from './styles.module.scss';
import { Icons } from 'pages/Clarity/Icons';
import { CheckBox } from 'components/Icons';
import { useEffect, useState } from 'react';
import { Input, Locations } from 'components';
import { createNewKitByAdmin } from 'api/kits';
import { Switch } from 'components-antd';
import { getKitFrequencyString } from 'pages/Dashboard/Kits/utils';

type CreationModalPropsType = {
  showModal: Function;
  open: boolean;
  categories;
  frequencies;
  propertyTypes;
  newKitHandler: Function;
};

function CreationModal(props: CreationModalPropsType) {
  const errors = {
    name: false,
    category: false,
    frequency: false,
    propertyType: false,
  };

  const [frequencyOptions, setFrequencyOptions] = useState<any[]>([]);
  const [validationErrors, SetValidationErrors] = useState(errors);
  const [name, setName] = useState<string>('');
  const [inAppReminder, setInAppReminder] = useState<boolean>(false);
  const [eligibleLocations, setEligibleLocations] = useState<any>([]);
  const [restrictedLocations, setRestrictedLocations] = useState<any>([]);

  const [eligibleCategory, setEligibleCategory] = useState<string>('');
  const [eligibleFrequencies, setEligibleFrequencies] = useState<number[]>([]);
  const [eligiblePropertyTypes, setEligiblePropertyTypes] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [publishing, setPublishing] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (props.frequencies?.length) {
      let options = props.frequencies.map((item) => {
        let frequencyString = getKitFrequencyString(item);
        return {
          Id: item.Id,
          FrequencyName: frequencyString,
        };
      });
      setFrequencyOptions(options);
    }
  }, []);

  const createNewKit = async () => {
    if (!validate()) return;
    setPublishing(true);
    try {
      const response = await createNewKitByAdmin({
        Name: name,
        Category: eligibleCategory,
        Frequencies: eligibleFrequencies,
        PropertyTypes: eligiblePropertyTypes,
        Notes: notes,
        InAppReminder: inAppReminder,
        EligibleLocations: eligibleLocations,
        RestrictedLocations: restrictedLocations,
      });
      if (response.status === 200 && response.data.result)
        props.newKitHandler(response.data.result);
    } catch (exc) {
      setError(true);
    }
    setPublishing(false);
  };

  const validate = () => {
    let errorsCopy = { ...errors };
    if (!name) errorsCopy.name = true;
    if (!eligibleCategory) errorsCopy.category = true;
    if (!eligibleFrequencies?.length) errorsCopy.frequency = true;
    if (!eligiblePropertyTypes?.length) errorsCopy.propertyType = true;
    SetValidationErrors({ ...errorsCopy });
    if (
      !errorsCopy.name &&
      !errorsCopy.category &&
      !errorsCopy.frequency &&
      !errorsCopy.propertyType
    )
      return true;
    return false;
  };

  const errorElement = () => {
    return <div className={styles.error}>Please enter or select value</div>;
  };

  const updateLocation = (result, prepareData, isEligible) => {
    if (isEligible) {
      setEligibleLocations(prepareData);
    } else {
      setRestrictedLocations(prepareData);
    }
  };

  return (
    <Modal
      footer={null}
      width={675}
      open={props.open}
      onCancel={() => {
        props.showModal(false);
      }}
      title={
        <span className={styles.headerTitle}>
          <Icons variant="kits" />
          <span className={styles.headerText}>{`New KIT`}</span>
        </span>
      }
      className={styles.newKitModal}
    >
      <div className={styles.content}>
        <div className={styles.name}>
          <p className={styles.sectionLabel}>Name:</p>
          <Input
            className={styles.field}
            variant="light"
            placeholder="Enter name"
            value={''}
            onChange={(e, val) => setName(val)}
            testid="name_input"
            maxLength={10000}
          />
          {validationErrors.name ? errorElement() : <></>}
        </div>
        <div className={styles.category}>
          <p className={styles.sectionLabel}>Category:</p>
          <Select
            large
            disabled={false}
            showArrow={true}
            placeholder="Select Category"
            getPopupContainer={(triggerNode) => triggerNode}
            menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
            className={styles.selector}
            options={props.categories.map((el) => ({
              label: el.Name,
              value: el.Id,
              key: `category-${el.Id}`,
            }))}
            filterOption={(input, option) => {
              return String((option?.label as unknown as string) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            onChange={(id) => {
              const found = props.categories.find((cat) => cat.Id === id);
              if (found) setEligibleCategory(found.Name);
            }}
          />
          {validationErrors.category ? errorElement() : <></>}
        </div>
        <div className={styles.frequency}>
          <p className={styles.sectionLabel}>Frequencies:</p>
          <Select
            large
            disabled={false}
            showArrow={true}
            placeholder="Select Frequencies"
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode}
            menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
            className={styles.selector}
            options={frequencyOptions.map((el) => ({
              label: el.FrequencyName,
              value: el.Id,
              key: `freq-${el.Id}`,
            }))}
            filterOption={(input, option) => {
              return String((option?.label as unknown as string) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            onChange={(val) => setEligibleFrequencies(val)}
          />
          {validationErrors.frequency ? errorElement() : <></>}
        </div>
        <div className={styles.propertyTypes}>
          <p className={styles.sectionLabel}>Property Types:</p>
          <Select
            large
            disabled={false}
            showArrow={true}
            placeholder="Select Property Types"
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode}
            menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
            className={styles.selector}
            options={props.propertyTypes.map((el) => ({
              label: el,
              value: el,
              key: `type-${el}`,
            }))}
            filterOption={(input, option) => {
              return String((option?.label as unknown as string) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            onChange={(val) => {
              if (val?.length) setEligiblePropertyTypes(val);
            }}
          />
          {validationErrors.propertyType ? errorElement() : <></>}
        </div>
        <div className={styles.inAppReminder}>
          <p className={styles.sectionLabel}>In-App Reminder:</p>
          <Switch checked={inAppReminder} onChange={(checked) => setInAppReminder(checked)} />
        </div>
        {restrictedLocations?.length === 0 && (
          <div className={styles.eligibleLocations}>
            <p className={styles.sectionLabel}>Eligible Locations</p>
            <Locations
              multiple
              allowStates={true}
              getStates={true}
              types={['(cities)', 'administrative_area_level_1', 'administrative_area_level_2']}
              onResult={(result, prepareData) => updateLocation(result, prepareData, true)}
              value={eligibleLocations?.length ? eligibleLocations : undefined}
              placeholder={'Enter City, State, or County'}
              activeInputClassName={styles.activeInputClassName}
              valuesWrapperClassName={styles.valuesWrapperClassName}
              placeholderClassName={styles.placeholderClass}
              valuesContainerClassName={styles.valuesContainer}
              searchIcon={!!eligibleLocations?.length}
              variant={Locations.SQUARE}
              customPlaceName={({ address }) => address?.split?.(',')?.slice(0, 2)?.join(',')}
            />
          </div>
        )}
        {eligibleLocations?.length === 0 && (
          <div className={styles.restrictedLocations}>
            <p className={styles.sectionLabel}>Restricted Locations</p>
            <Locations
              multiple
              allowStates={true}
              getStates={true}
              types={['(cities)', 'administrative_area_level_1', 'administrative_area_level_2']}
              onResult={(result, prepareData) => updateLocation(result, prepareData, false)}
              value={restrictedLocations?.length ? restrictedLocations : undefined}
              placeholder={'Enter City, State, or County'}
              activeInputClassName={styles.activeInputClassName}
              valuesWrapperClassName={styles.valuesWrapperClassName}
              placeholderClassName={styles.placeholderClass}
              valuesContainerClassName={styles.valuesContainer}
              searchIcon={!!restrictedLocations?.length}
              variant={Locations.SQUARE}
              customPlaceName={({ address }) => address?.split?.(',')?.slice(0, 2)?.join(',')}
            />
          </div>
        )}
        <div className={styles.notes}>
          <p className={styles.sectionLabel}>Notes:</p>
          <Input
            className={styles.field}
            variant="light"
            placeholder="Enter notes"
            value={''}
            onChange={(e, val) => setNotes(val)}
            testid="name_input"
            maxLength={10000}
          />
        </div>
        <div className={styles.createButton}>
          <Button
            htmlType="submit"
            variant="secondary"
            className={styles.submitButton}
            loading={publishing}
            disabled={publishing || error}
            onClick={createNewKit}
          >
            {error ? 'Oops, something went wrong.' : 'Create'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CreationModal;
