/* eslint-disable max-len */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GraphemeSplitter from 'grapheme-splitter';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useCallback, useMemo } from 'react';

import { getSimpleDate } from 'helpers';
import { emojiRegex } from 'helpers/regex';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { Dropdown, Menu, Tooltip, MenuItem } from 'components-antd';
import Avatar from './Avatar';
import { getUserRoleSelector } from 'store/selectors/user';
import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { setDrawerClientProfileId, setProfileExpandedSection } from 'store/effects/app';
import { getUserId } from 'store/selectors/user';
import { openThreadContactsDrawerAction } from 'store/actions/drawers/threadContacts';
import { DRAWER_THREAD_CONTACTS_TYPES } from 'settings/constants/drawers';
import { setTransactionTaskParamsEffect } from 'store/effects/transactions';
import { createTagNamesRegExp } from 'utils';
import { MessageAccessType } from 'app-constants/enums/message';
import Icons from 'components/Drawers/components/SendMessageForm/SendMessageFormView/Icons';
import { Dots2 } from 'components/Icons';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { isToday, isTomorrow, isYesterday } from 'helpers/date';

import styles from './styles.module.scss';

const splitter = new GraphemeSplitter();

const Message = (props) => {
  const {
    className,
    messageClassName,
    Id,
    AvatarUrl,
    Text,
    IsOwnMessage,
    MessageMeta,
    Attachments,
    onEdit,
    currentEditMessage,
    FirstName,
    LastName,
    CreatedDate,
    onDelete,
    IsDeleted,
    messageHolderClassName = '',
    messageSenderClassName = '',
    showSenderAsTitle = false,
    CommentMeta,
  } = props;

  const dispatch = useDispatch();
  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);

  const isMessageSavedOnServer = Id !== undefined;
  const isEditMessage = isMessageSavedOnServer && currentEditMessage?.Id === Id;

  const isClientRole = (role) => role === 'Client' || role === 'Buyer' || role === 'Seller';

  const isAgentRole = (role) => role === 'Agent' || role === 'BuyerAgent' || role === 'SellerAgent';

  const menuItems = useMemo(
    () => (
      <Menu className={styles.messageActionMenu}>
        <MenuItem
          className={styles.menuItem}
          onClick={() => onEdit(Id)}
          icon={<Icon variant={Icon.OPTIONS_EDIT} />}
        >
          <span className={styles.menuItemSpan}>Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => onDelete(Id)}
          className={styles.menuItem}
          icon={<Icon variant={Icon.OPTIONS_DELETE} />}
        >
          <span className={styles.menuItemSpan}>Delete</span>
        </MenuItem>
      </Menu>
    ),
    [Id, onEdit, onDelete],
  );

  const onTaggedUserClick = (tag) => {
    const isClient = isClientRole(tag.Role);
    const isViewerAgent = isAgentRole(userRole);
    const isViewerClient = isClientRole(userRole);

    if (isClient && isViewerAgent) {
      dispatch(requestGetClientsListEffect());
      dispatch(getClientDetailsEffect({ id: tag.ContactUserId }));
      dispatch(setDrawerClientProfileId({ id: tag.ContactUserId }));
      dispatch(setProfileExpandedSection(true));
    } else if (userId !== tag.ContactUserId) {
      dispatch(
        openThreadContactsDrawerAction({
          params: {
            contactId: isViewerClient && isClient ? undefined : tag.ContactUserId,
            isTransactionMessages: false,
            isSingleContact: true,
          },
          open: true,
          type: DRAWER_THREAD_CONTACTS_TYPES.CONTACT,
        }),
      );
      dispatch(setTransactionTaskParamsEffect({ isTaskDetail: false }));
    }
  };

  const getTagMarkup = (tag, isClickable = true) => {
    let tagText = '';
    let tagNode = <></>;
    if (tag.Type === 'Contact') {
      tagText = `@${tag.FirstName} ${tag.LastName}`;

      tagNode = (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          title={`${tag.FirstName} ${tag.LastName} (${tag.Role})`}
          placement="top"
        >
          <span
            onClick={() => isClickable && onTaggedUserClick(tag)}
            className={styles.tagHyperLink}
          >{`@${tag.FirstName} ${tag.LastName} `}</span>
        </Tooltip>
      );
    }

    return [tagText, tagNode];
  };

  const getText = () => {
    const graphemes = splitter.splitGraphemes(Text);

    let isEmoji = false;
    const newText = graphemes.map((grapheme, index) => {
      const matches = emojiRegex.exec(grapheme);
      if (matches) {
        isEmoji = !isEmoji ? true : isEmoji;
        // eslint-disable-next-line react/no-array-index-key
        return (
          <span className={styles.emoji} key={index}>
            {grapheme}
          </span>
        );
      }
      return grapheme;
    });

    const text = Text;
    const tagNamesArray = (MessageMeta?.Tags || CommentMeta?.Tags || [])?.map((tag, index) => {
      const { FirstName, LastName } = tag;
      const tagTextName = `@${FirstName} ${LastName}`;
      return tagTextName;
    });
    const pattern = createTagNamesRegExp(tagNamesArray);
    // Use split with the created pattern to split the text
    let splittedTextBasedOnTags = text.split(pattern);
    (MessageMeta?.Tags || CommentMeta?.Tags)?.forEach((tag, index) => {
      const { FirstName, LastName } = tag;
      let [tagText, tagNode] = getTagMarkup(tag, false);
      const tagTextName = `@${FirstName} ${LastName}`;
      splittedTextBasedOnTags = splittedTextBasedOnTags.map((val) => {
        if (typeof val === 'string' && val?.includes(tagTextName)) {
          return tagNode;
        }
        return val;
      });
    });

    return splittedTextBasedOnTags.length ? splittedTextBasedOnTags : text;
  };

  const getDate = () => {
    const date = renderDate(CreatedDate);
    const time = getSimpleDate(CreatedDate, 'h:mm A');

    return `${date} at ${time}`;
  };

  const renderDate = (value) => {
    if (isYesterday(value)) {
      return 'Yesterday';
    }

    if (isToday(value)) {
      return 'Today';
    }

    if (isTomorrow(value)) {
      return 'Tomorrow';
    }

    return getSimpleDate(CreatedDate, 'MMM DD, YYYY');
  };

  const isEditable = () => {
    const messageTime = moment(CreatedDate);
    return messageTime.add(10, 'minutes').isAfter(moment()) && !IsDeleted;
  };

  const getAccessType = useCallback(() => {
    return <Icons variant={props.AccessType.toLowerCase()} />;
  }, [props]);

  const senderTitleProps = {
    ...(showSenderAsTitle && { title: `${FirstName} ${LastName}` }),
  };

  return (
    <div
      testid="message_item"
      id={Id ? `message_${Id}` : null}
      className={classNames(
        styles.messageWrapper,
        {
          [styles.isEditMessage]:
            isEditMessage || (!isMessageSavedOnServer && !!Attachments?.length),
        },
        { [styles.isOwnMessage]: IsOwnMessage },
        className,
      )}
    >
      {!!Text && (
        <div className={classNames(styles.messageHolder, messageHolderClassName || '')}>
          <Avatar
            placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName}`)}
            className={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            src={AvatarUrl}
          />
          <div
            className={classNames(styles.messageOuter, {
              [styles.withActions]: isMessageSavedOnServer,
            })}
          >
            <div className={styles.info}>
              <div className={styles.left}>
                <div
                  testid="sender_name"
                  className={classNames(styles.senderName, messageSenderClassName)}
                  {...senderTitleProps}
                >{`${FirstName} ${LastName}`}</div>
              </div>
              <div className={styles.right}>
                {IsOwnMessage && isEditable() && (
                  <Dropdown placement="bottomRight" overlay={menuItems}>
                    <a
                      className={classNames(styles.messageAction, styles.edit, {
                        [styles.notEditable]: !isMessageSavedOnServer,
                      })}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Dots2 />
                    </a>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className={styles.messageOuterHolder}>
              <div className={classNames(styles.messageInner)}>
                <div className={classNames(styles.message, messageClassName)}>
                  <div className={styles.messageText} testid="message_text">
                    {getText()}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.messageFooter}>
              <div testid="date_sent" className={styles.date}>
                {getDate()}
              </div>
              <div className={styles.dateIcon}>{getAccessType()}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  messageClassName: PropTypes.string,
  AvatarUrl: PropTypes.string,
  Text: PropTypes.string.isRequired,
  IsOwnMessage: PropTypes.bool,
  Attachments: PropTypes.arrayOf(PropTypes.shape({})),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  currentEditMessage: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  CreatedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  AccessType: PropTypes.string,
  messageSenderClassName: PropTypes.string,
};

Message.defaultProps = {
  Id: undefined,
  className: '',
  messageClassName: '',
  AvatarUrl: null,
  IsOwnMessage: false,
  Attachments: [],
  onEdit: () => {},
  onDelete: () => {},
  currentEditMessage: null,
  FirstName: '',
  LastName: '',
  CreatedDate: null,
  AccessType: MessageAccessType.Internal,
  messageSenderClassName: '',
};

export default Message;
