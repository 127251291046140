import { Button, Input, Modal } from 'components-antd';
import styles from './styles.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { getFormattedDateTime } from '../../helper';
import {
  requestBatchCancelScheduleTourEffect,
  requestScheduleToursEffect,
} from 'store/effects/tour';
import { Tour } from 'types/tours';
import { getTeamTransactionsSelector } from 'store/selectors/transactions';
import { TourStatus } from 'app-constants/enums/tours';
import { useState } from 'react';
import { Wrapper as PendingWrapper } from 'components';
import { showSuccessMessage } from 'helpers';

export const CancelModal = (props) => {
  const {
    showModal,
    onCancel,
    onSubmit,
    tours,
  }: { showModal: boolean; onCancel: any; onSubmit: any; tours: Tour[] } = props;
  const dispatch = useDispatch();
  const teamTransactions = useSelector(getTeamTransactionsSelector);
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSubmitCancelTours = () => {
    setIsLoading(true);
    dispatch(
      requestBatchCancelScheduleTourEffect(
        {
          tourIds: tours.map((t) => t.UUID),
          notes: notes || undefined,
        },
        null,
        (err) => {
          if (!err) {
            dispatch(
              requestScheduleToursEffect({ EntireTeam: teamTransactions }, null, (err) => {
                if (!err) {
                  showSuccessMessage('Tour declined successfully');
                  onSubmit();
                }
                setIsLoading(false);
              }),
            );
          } else {
            setIsLoading(false);
          }
        },
      ),
    );
  };
  const renderSingleTour = () => {
    const tour: Tour = tours[0];
    return (
      <>
        <h3 className={styles.title}>{`Are you sure you want to ${
          tour.Status === TourStatus.Requested ? 'discard' : 'cancel'
        } this tour?`}</h3>
        <p className={styles.tourDetail}>
          {tour.PropertyAddress.Line2
            ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
            : `${tour.PropertyAddress.Line1},`}
          <span>
            {' '}
            {getFormattedDateTime(
              tour?.ScheduleDateTime || tour.CreatedDate,
              'MM/DD/YYYY, hh:mm A',
            )}
          </span>
          <span>Client: {`${tour.Creator.FirstName} ${tour.Creator.LastName}`}</span>
        </p>
      </>
    );
  };
  const renderMultipleTours = () => {
    return (
      <>
        <h3 className={styles.title}>
          {`Are you sure you want to ${
            tours[0].Status === TourStatus.Requested ? 'discard' : 'cancel'
          } ${
            tours.length
          } tours for ${` ${tours[0].Creator.FirstName} ${tours[0].Creator.LastName}`}?`}
        </h3>
        {tours.map((tour, i) => (
          <p className={styles.tourDetail} key={i}>
            {tour.PropertyAddress.Line2
              ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
              : `${tour.PropertyAddress.Line1},`}{' '}
            <span> {getFormattedDateTime(tour.CreatedDate, 'MM/DD/YYYY, hh:mm A')}</span>
          </p>
        ))}
      </>
    );
  };
  return (
    <Modal
      open={showModal}
      width={360}
      footer={null}
      className={styles.cancelToursModal}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.modalBody}>
        {tours.length > 1 ? renderMultipleTours() : renderSingleTour()}
        <div className={styles.fieldWrap}>
          <label>Note</label>
          <Input
            className={styles.input}
            type="text"
            placeholder="Write a reason for declining..."
            value={notes}
            onChange={(e) => setNotes(e.target.value.slice(0, 250))}
          />
        </div>

        <div className={styles.modalActions}>
          <PendingWrapper
            isPending={isLoading}
            className={styles.pendingWrapper}
            spinnerClassname={styles.spinner}
            loaderClassName={styles.loader}
          >
            <Button
              variant="primary"
              className={classNames(styles.button, styles.submit)}
              onClick={onSubmitCancelTours}
            >
              Yes
            </Button>
          </PendingWrapper>
          <Button
            variant="default"
            className={classNames(styles.button, styles.reject)}
            onClick={onCancel}
            disabled={isLoading}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};
