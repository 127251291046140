import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.CLOSE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_42719_6422)">
              <path
                d="M24 8L8 24"
                stroke="#515151"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8L24 24"
                stroke="#515151"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42719_6422">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.PREVIOUS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 21L6 12L15 3"
              stroke="#515151"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TRASH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_42503_47775)">
              <path
                d="M4.00195 7H20.002"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.002 11V17"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.998 11V17"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.99805 7L5.99805 19C5.99805 19.5304 6.20876 20.0391 6.58383 20.4142C6.95891 20.7893 7.46761 21 7.99805 21H15.998C16.5285 21 17.0372 20.7893 17.4123 20.4142C17.7873 20.0391 17.998 19.5304 17.998 19L18.998 7"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42503_47775">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.FILE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_42503_47771)">
              <path
                d="M18.668 4V9.33333C18.668 9.68695 18.8084 10.0261 19.0585 10.2761C19.3085 10.5262 19.6477 10.6667 20.0013 10.6667H25.3346"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.668 28H9.33464C8.62739 28 7.94911 27.719 7.44902 27.219C6.94892 26.7189 6.66797 26.0406 6.66797 25.3333V6.66667C6.66797 5.95942 6.94892 5.28115 7.44902 4.78105C7.94911 4.28095 8.62739 4 9.33464 4H18.668L25.3346 10.6667V25.3333C25.3346 26.0406 25.0537 26.7189 24.5536 27.219C24.0535 27.719 23.3752 28 22.668 28Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42503_47771">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.FILE_2: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_55383_44038)">
              <path
                d="M18.666 4V9.33333C18.666 9.68695 18.8065 10.0261 19.0565 10.2761C19.3066 10.5262 19.6457 10.6667 19.9993 10.6667H25.3327"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.666 28H9.33268C8.62544 28 7.94716 27.719 7.44706 27.219C6.94697 26.7189 6.66602 26.0406 6.66602 25.3333V6.66667C6.66602 5.95942 6.94697 5.28115 7.44706 4.78105C7.94716 4.28095 8.62544 4 9.33268 4H18.666L25.3327 10.6667V25.3333C25.3327 26.0406 25.0517 26.7189 24.5516 27.219C24.0515 27.719 23.3733 28 22.666 28Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12H13.3333"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 17.334H20"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22.666H20"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_55383_44038">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.UPLOAD_FILE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <g clipPath="url(#clip0_42503_47753)">
              <path
                d="M12.8359 32.9992C10.5484 32.9992 8.35464 32.13 6.73714 30.5828C5.11964 29.0357 4.21094 26.9372 4.21094 24.7492C4.21094 22.5612 5.11964 20.4628 6.73714 18.9156C8.35464 17.3684 10.5484 16.4992 12.8359 16.4992V16.4992C13.3762 14.0924 14.9567 11.9773 17.2297 10.6192C18.3552 9.94674 19.6168 9.48039 20.9425 9.24676C22.2683 9.01313 23.6321 9.0168 24.9563 9.25755C26.2805 9.49831 27.539 9.97144 28.66 10.6499C29.781 11.3284 30.7425 12.199 31.4897 13.2119C32.2368 14.2249 32.755 15.3603 33.0146 16.5535C33.2742 17.7466 33.2701 18.9741 33.0026 20.1659H34.8359C36.5377 20.1659 38.1699 20.8419 39.3732 22.0453C40.5766 23.2486 41.2526 24.8807 41.2526 26.5825C41.2526 28.2844 40.5766 29.9165 39.3732 31.1198C38.1699 32.3232 36.5377 32.9992 34.8359 32.9992H33.0026"
                stroke="#AAABAB"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 27.5L22 22L27.5 27.5"
                stroke="#AAABAB"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22V38.5"
                stroke="#AAABAB"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42503_47753">
                <rect width="44" height="44" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.UPLOAD: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <g clipPath="url(#clip0_42470_46355)">
              <path
                d="M12.8359 32.9992C10.5484 32.9992 8.35464 32.13 6.73714 30.5828C5.11964 29.0357 4.21094 26.9372 4.21094 24.7492C4.21094 22.5612 5.11964 20.4628 6.73714 18.9156C8.35464 17.3684 10.5484 16.4992 12.8359 16.4992V16.4992C13.3762 14.0924 14.9567 11.9773 17.2297 10.6192C18.3552 9.94674 19.6168 9.48039 20.9425 9.24676C22.2683 9.01313 23.6321 9.0168 24.9563 9.25755C26.2805 9.49831 27.539 9.97144 28.66 10.6499C29.781 11.3284 30.7425 12.199 31.4897 13.2119C32.2368 14.2249 32.755 15.3603 33.0146 16.5535C33.2742 17.7466 33.2701 18.9741 33.0026 20.1659H34.8359C36.5377 20.1659 38.1699 20.8419 39.3732 22.0453C40.5766 23.2486 41.2526 24.8807 41.2526 26.5825C41.2526 28.2844 40.5766 29.9165 39.3732 31.1198C38.1699 32.3232 36.5377 32.9992 34.8359 32.9992H33.0026"
                stroke="#51BFE1"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 27.5L22 22L27.5 27.5"
                stroke="#51BFE1"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22V38.5"
                stroke="#51BFE1"
                strokeWidth="2.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42470_46355">
                <rect width="44" height="44" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.LIBRARY: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M34.834 18.3327V15.5827C34.834 14.0639 33.6028 12.8327 32.084 12.8327H22.459C22.1705 12.8327 21.8988 12.6968 21.7257 12.466L20.0757 10.266C19.5563 9.57355 18.7412 9.16602 17.8756 9.16602H10.084C8.5652 9.16602 7.33398 10.3972 7.33398 11.916V30.2493C7.33398 31.7681 8.5652 32.9993 10.084 32.9993H31.2021C32.2947 32.9993 33.2836 32.3526 33.7215 31.3516L38.2937 20.9009C38.8237 19.6896 37.9362 18.3327 36.6141 18.3327H14.5935C13.5209 18.3327 12.5461 18.9564 12.0966 19.9303L7.33398 30.2493"
              stroke="#928CDA"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        );
      }
      case Icons.RECENT_UPLOADS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M22 14.666V21.9993L25.6667 25.666"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.59375 20.1639C6.00458 16.1306 7.8859 12.3898 10.8788 9.65513C13.8716 6.92045 17.7665 5.38332 21.8204 5.33701C25.8742 5.29071 29.8032 6.73848 32.8577 9.40409C35.9123 12.0697 37.8785 15.7666 38.3814 19.7894C38.8842 23.8122 37.8885 27.8793 35.5841 31.2148C33.2797 34.5503 29.8279 36.9206 25.8874 37.8736C21.9469 38.8266 17.7935 38.2955 14.2196 36.3817C10.6457 34.4678 7.9014 31.3052 6.51042 27.4972M5.59375 36.6639V27.4972H14.7604"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TEMPLATE: {
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_42470_46394)">
              <path
                d="M25.668 5.5V12.8333C25.668 13.3196 25.8611 13.7859 26.2049 14.1297C26.5488 14.4735 27.0151 14.6667 27.5013 14.6667H34.8346"
                stroke="#FB913A"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M31.168 38.5H12.8346C11.8622 38.5 10.9295 38.1137 10.2419 37.4261C9.55428 36.7384 9.16797 35.8058 9.16797 34.8333V9.16667C9.16797 8.19421 9.55428 7.26158 10.2419 6.57394C10.9295 5.88631 11.8622 5.5 12.8346 5.5H25.668L34.8346 14.6667V34.8333C34.8346 35.8058 34.4483 36.7384 33.7607 37.4261C33.0731 38.1137 32.1404 38.5 31.168 38.5Z"
                stroke="#FB913A"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <path d="M22 19V29" stroke="#FB913A" strokeWidth="3" strokeLinecap="round" />
            <path d="M17 24L27 24" stroke="#FB913A" strokeWidth="3" strokeLinecap="round" />
            <defs>
              <clipPath id="clip0_42470_46394">
                <rect width="44" height="44" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.FILTER: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.2256 4.996C2.94272 4.67271 3.1723 4.16675 3.60188 4.16675H16.3981C16.8277 4.16675 17.0573 4.67271 16.7744 4.996L11.6667 10.8334V15.5244C11.6667 15.7138 11.5597 15.8869 11.3903 15.9716L9.05694 17.1383C8.72449 17.3045 8.33333 17.0628 8.33333 16.6911V10.8334L3.2256 4.996Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILE_DOCUMENT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#747475" />
            <path
              d="M17.668 8.5V11.8333C17.668 12.0543 17.7558 12.2663 17.912 12.4226C18.0683 12.5789 18.2803 12.6667 18.5013 12.6667H21.8346"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.168 23.5H11.8346C11.3926 23.5 10.9687 23.3244 10.6561 23.0118C10.3436 22.6993 10.168 22.2754 10.168 21.8333V10.1667C10.168 9.72464 10.3436 9.30072 10.6561 8.98816C10.9687 8.67559 11.3926 8.5 11.8346 8.5H17.668L21.8346 12.6667V21.8333C21.8346 22.2754 21.659 22.6993 21.3465 23.0118C21.0339 23.3244 20.61 23.5 20.168 23.5Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 13.5H14.3333"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 16.834H18.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 20.166H18.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.BUNDLE_DOCUMENT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#747475" />
            <g clipPath="url(#clip0_43039_206116)">
              <path
                d="M14.6367 20L21.364 20C21.8944 20 22.4031 19.7893 22.7782 19.4142C23.1533 19.0391 23.364 18.5304 23.364 18V11L19.5 7H14.6367C14.1063 7 13.5976 7.21071 13.2225 7.58578C12.8474 7.96086 12.6367 8.46957 12.6367 9V18C12.6367 18.5304 12.8474 19.0391 13.2225 19.4142C13.5976 19.7893 14.1063 20 14.6367 20Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 7V10.4C19 10.6254 19.0896 10.8416 19.249 11.001C19.4084 11.1604 19.6246 11.25 19.85 11.25H23.25"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M16 13.5H20" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
              <path d="M16 16.5H18" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
              <path
                d="M19.364 20V22C19.364 22.5304 19.1533 23.0391 18.7782 23.4142C18.4031 23.7893 17.8944 24 17.364 24H10.6367C10.1063 24 9.59758 23.7893 9.22251 23.4142C8.84743 23.0391 8.63672 22.5304 8.63672 22V13C8.63672 12.4696 8.84743 11.9609 9.22251 11.5858C9.59758 11.2107 10.1063 11 10.6367 11H12.6367"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_43039_206116">
                <rect width="20" height="20" fill="white" transform="matrix(1 0 0 -1 6 26)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.MULTI_SELECT: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 10L9.5 12.5L17.5 4.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V10"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FORM_BUNDLE: {
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="28" cy="28" r="28" fill="#252D44" />
            <g clipPath="url(#clip0_42383_72340)">
              <path
                d="M26.1823 33.334L35.152 33.334C35.8592 33.334 36.5375 33.053 37.0376 32.5529C37.5377 32.0528 37.8187 31.3746 37.8187 30.6673V21.334L32.6667 16.0006H26.1823C25.475 16.0006 24.7968 16.2816 24.2967 16.7817C23.7966 17.2818 23.5156 17.9601 23.5156 18.6673V30.6673C23.5156 31.3746 23.7966 32.0528 24.2967 32.5529C24.7968 33.053 25.475 33.334 26.1823 33.334Z"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M32 16V20.5333C32 20.8339 32.1194 21.1222 32.3319 21.3347C32.5445 21.5473 32.8328 21.6667 33.1333 21.6667H37.6667"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 24.666H33.3333"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M28 28.666H30.6667"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M32.4866 33.334V36.0007C32.4866 36.7079 32.2057 37.3862 31.7056 37.8863C31.2055 38.3864 30.5272 38.6673 29.82 38.6673H20.8503C20.143 38.6673 19.4647 38.3864 18.9646 37.8863C18.4645 37.3862 18.1836 36.7079 18.1836 36.0007V24.0007C18.1836 23.2934 18.4645 22.6151 18.9646 22.115C19.4647 21.6149 20.143 21.334 20.8503 21.334H23.5169"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42383_72340">
                <rect width="32" height="32" fill="white" transform="matrix(1 0 0 -1 12 44)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.SMART_FORM: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#00B152" />
            <path
              d="M23.9068 14.7821C23.6811 13.317 23.0528 11.9434 22.0919 10.8145C21.131 9.68571 19.8754 8.84606 18.4651 8.38927C17.0548 7.93248 15.5454 7.87652 14.1051 8.22765C12.6649 8.57877 11.3506 9.32316 10.3087 10.3778C9.26694 11.4324 8.53864 12.7557 8.20511 14.2001C7.87158 15.6445 7.94595 17.1532 8.41991 18.5578C8.89386 19.9624 9.74877 21.2077 10.8892 22.1547C12.0297 23.1017 13.4109 23.7132 14.8787 23.921"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.0654 13.4304C18.7057 13.0012 18.2599 12.6524 17.7569 12.4065C17.2538 12.1605 16.7047 12.023 16.1451 12.0026C15.5855 11.9823 15.0279 12.0797 14.5084 12.2885C13.9888 12.4973 13.5189 12.8129 13.129 13.2148C12.7391 13.6167 12.4379 14.096 12.245 14.6217C12.052 15.1474 11.9716 15.7077 12.0089 16.2664C12.0462 16.8251 12.2004 17.3698 12.4615 17.8651C12.7225 18.3605 13.0847 18.7955 13.5246 19.142"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.5 25.5L16.5 16.5L25.5 19.5L21.8333 21.8333L19.5 25.5Z"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MENU_DOTS: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#fff" />
            <circle cx="9" cy="16" r="2" fill="#747475" />
            <circle cx="16" cy="16" r="2" fill="#747475" />
            <circle cx="23" cy="16" r="2" fill="#747475" />
          </svg>
        );
      }
      case Icons.MENU_DOTS_BG: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect width="32" height="32" rx="16" fill="#E8E9EA" />
            <circle cx="9" cy="16" r="2" fill="#262626" />
            <circle cx="16" cy="16" r="2" fill="#262626" />
            <circle cx="23" cy="16" r="2" fill="#262626" />
          </svg>
        );
      }
      case Icons.RED_PEN: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_43039_206104)">
              <path
                d="M2.66797 13.3322H5.33464L12.3346 6.33223C12.6883 5.97861 12.8869 5.499 12.8869 4.9989C12.8869 4.4988 12.6883 4.01919 12.3346 3.66557C11.981 3.31194 11.5014 3.11328 11.0013 3.11328C10.5012 3.11328 10.0216 3.31194 9.66797 3.66557L2.66797 10.6656V13.3322Z"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.33398L11.6667 7.00065"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_43039_206104">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.RED_TRASH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2.66797 4.66602H13.3346"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66797 7.33398V11.334"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33203 7.33398V11.334"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 4.66602L3.9987 12.666C3.9987 13.0196 4.13917 13.3588 4.38922 13.6088C4.63927 13.8589 4.97841 13.9993 5.33203 13.9993H10.6654C11.019 13.9993 11.3581 13.8589 11.6082 13.6088C11.8582 13.3588 11.9987 13.0196 11.9987 12.666L12.6654 4.66602"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILTER_RED: {
        return (
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M1.96746 3.10567C1.59029 2.67462 1.89641 2 2.46918 2H23.5308C24.1036 2 24.4097 2.67461 24.0325 3.10567L15.6667 12.6667V20.2546C15.6667 20.5072 15.524 20.738 15.2981 20.8509L11.2981 22.8509C10.8549 23.0726 10.3333 22.7502 10.3333 22.2546V12.6667L1.96746 3.10567Z"
              stroke="#FF576D"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DATE_PICKER: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V14.5C4 15.3284 4.67157 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67157 15.3284 4 14.5 4Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 3V5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 3V5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 8H16"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILE_PREVIEW: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#747475" />
            <path
              d="M8.5 21.8329C9.64014 21.1747 10.9335 20.8281 12.25 20.8281C13.5665 20.8281 14.8599 21.1747 16 21.8329C17.1401 21.1747 18.4335 20.8281 19.75 20.8281C21.0665 20.8281 22.3599 21.1747 23.5 21.8329"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 10.9989C9.64014 10.3407 10.9335 9.99414 12.25 9.99414C13.5665 9.99414 14.8599 10.3407 16 10.9989C17.1401 10.3407 18.4335 9.99414 19.75 9.99414C21.0665 9.99414 22.3599 10.3407 23.5 10.9989"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 11V21.8333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 11V21.8333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.5 11V21.8333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PREVIEW: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2 12.6671C2.91212 12.1405 3.94678 11.8633 5 11.8633C6.05322 11.8633 7.08788 12.1405 8 12.6671C8.91212 12.1405 9.94678 11.8633 11 11.8633C12.0532 11.8633 13.0879 12.1405 14 12.6671"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 3.99916C2.91212 3.47255 3.94678 3.19531 5 3.19531C6.05322 3.19531 7.08788 3.47255 8 3.99916C8.91212 3.47255 9.94678 3.19531 11 3.19531C12.0532 3.19531 13.0879 3.47255 14 3.99916"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EDIT_PENCIL: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.40234 15.5994H7.32502L14.997 7.92739C15.3846 7.53982 15.6023 7.01416 15.6023 6.46606C15.6023 5.91795 15.3846 5.39229 14.997 5.00472C14.6095 4.61715 14.0838 4.39941 13.5357 4.39941C12.9876 4.39941 12.4619 4.61715 12.0744 5.00472L4.40234 12.6767V15.5994Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.3438 5.73633L14.2664 8.659"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.SINGLE_FORM: {
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="28" cy="28" r="28" fill="#252D44" />
            <g clipPath="url(#clip0_58258_402012)">
              <path
                d="M22.3845 39L33.7687 39C34.6663 39 35.5272 38.6434 36.1619 38.0087C36.7966 37.374 37.1532 36.5131 37.1532 35.6155V23.7698L30.6143 17.0008H22.3845C21.4869 17.0008 20.626 17.3574 19.9913 17.9921C19.3566 18.6268 19 19.4877 19 20.3853V35.6155C19 36.5131 19.3566 37.374 19.9913 38.0087C20.626 38.6434 21.4869 39 22.3845 39Z"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.7695 17V22.7536C29.7695 23.1351 29.9211 23.501 30.1908 23.7707C30.4606 24.0405 30.8264 24.192 31.2079 24.192H36.9616"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.6914 27.999H31.4604"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M24.6914 33.0752H28.0759"
                stroke="#252D44"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_58258_402012">
                <rect width="32" height="32" fill="white" transform="matrix(1 0 0 -1 12 44)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.INFO: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_66883_172076)">
              <path
                d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8C8 9.0414 8 9.4586 8 10.5"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="8" cy="5" r="1" fill="#AAABAB" />
            </g>
            <defs>
              <clipPath id="clip0_66883_172076">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.WARNING: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_65892_33876)">
              <path
                d="M8 8V6"
                stroke="#EC455E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 7 12)" fill="#EC455E" />
              <path
                d="M6.70956 2.67761L1.34205 11.7353C0.749512 12.7352 1.4702 14 2.63248 14H13.3675C14.5298 14 15.2505 12.7352 14.658 11.7353L9.29044 2.67761C8.70946 1.69722 7.29054 1.69722 6.70956 2.67761Z"
                stroke="#EC455E"
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_65892_33876">
                <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0 16)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.CLOSE = 'close';
Icons.TRASH = 'trash';
Icons.FILE = 'file';
Icons.FILE_2 = 'file_2';
Icons.UPLOAD_FILE = 'uploadFile';
Icons.PREVIOUS = 'previous';
Icons.UPLOAD = 'upload';
Icons.LIBRARY = 'library';
Icons.RECENT_UPLOADS = 'recentUploads';
Icons.TEMPLATE = 'template';
Icons.FILTER = 'filter';
Icons.FILE_DOCUMENT = 'fileDocument';
Icons.MULTI_SELECT = 'multiSelect';
Icons.BUNDLE_DOCUMENT = 'bundleDocument';
Icons.FORM_BUNDLE = 'formBundle';
Icons.SMART_FORM = 'smartForm';
Icons.MENU_DOTS = 'menuDots';
Icons.MENU_DOTS_BG = 'menuDotsBg';
Icons.RED_PEN = 'redPen';
Icons.RED_TRASH = 'redTrash';
Icons.FILTER_RED = 'filterRed';
Icons.DATE_PICKER = 'datePicker';
Icons.FILE_PREVIEW = 'filePreview';
Icons.PREVIEW = 'preview';
Icons.EDIT_PENCIL = 'editPencil';
Icons.SINGLE_FORM = 'singleForm';
Icons.WARNING = 'warning';
Icons.INFO = 'info';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
