import { createSelector } from 'reselect';

import { PENDING, IDLE } from 'settings/constants/apiState';

const localState = ({ clarityAI }) => clarityAI;

export const getAISessionsList = createSelector(localState, ({ sessions }) => {
  return {
    isIdle: sessions?.state === IDLE,
    isPending: sessions?.state === PENDING,
    data: sessions?.data,
  };
});

export const getSessionMessages = createSelector(localState, ({ messages }) => {
  return {
    isIdle: messages?.state === IDLE,
    isPending: messages?.state === PENDING,
    messages: messages?.data?.messages,
  };
});
