import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';
import classNames from 'classnames';
import Icons from '../NeighborhoodTab/ExploreSection/Icons';
import { useRequest } from 'hooks';
import { SchoolListingDropdown } from './SchoolListingDropdown';
import { SchoolListingMap } from './SchoolListingMap';
import { getSchoolsAggregateByGeolocation } from 'api/listingDetail';
import { getListingDetailNeighborhoodTab } from 'store/selectors/listingDetail';

import { Wrapper, ApiRequestError } from 'components';

import ExploreSection from './ExploreSection';
import NeighborhoodSchool from './NeighborhoodSchool';
import NeighborhoodTransportation from './NeighborhoodTransportation';
import styles from './styles.module.scss';

const NeighborhoodTab = () => {
  const {
    schools: storeSchools,
    walkScore,
    bikeScore,
    transitScore,
    geoLat,
    geoLong,
    address,
    id,
    priceToDisplay,
  } = useSelector(getListingDetailNeighborhoodTab);
  const [{ loading, error, data: requestSchools }, , sendRequest] = useRequest({
    request: getSchoolsAggregateByGeolocation,
    autoSend: false,
    params: {
      geolocation: `${geoLat},${geoLong}`,
    },
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [gradeRanges, setGradeRanges] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [isMapViewVisible, setMapViewVisible] = useState(false);

  const toggleExploreMode = useCallback(() => setMapViewVisible((val) => !val), []);

  const schools = useMemo(() => storeSchools || requestSchools, [storeSchools, requestSchools]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!storeSchools) {
      sendRequest();
    }
  }, []);

  useEffect(() => {
    if (schools?.length > 0) {
      const uniqueGradeRanges = getUniqueGradeRanges(schools);
      setGradeRanges(uniqueGradeRanges);
      setFilteredSchools(schools);
    }
  }, [schools]);

  const schoolColumns = [
    {
      label: 'Details',
      width: '59%',
      justifyContent: 'unset',
    },
    {
      label: 'Distance',
      width: '8%',
      justifyContent: 'center',
    },
    {
      label: 'Niche',
      width: '17%',
      justifyContent: 'center',
    },
    {
      label: 'Great Schools',
      width: '17%',
      justifyContent: 'space-around',
    },
  ];

  const handleValuesChange = (values) => {
    if (values && values.length !== 0) {
      const gradeValues = values.filter((val) => val.includes('-') || val === 'All Grades');
      const typeValues = values.filter((val) => !val.includes('-') || val === 'All Schools');

      if (gradeValues.includes('All Grades') || typeValues.includes('All Schools')) {
        setFilteredSchools(schools);
      } else {
        const filtered = schools.filter((school) => {
          const matchesGrade =
            gradeValues?.length === 0 ||
            gradeValues?.some((grade) => {
              if (grade === 'allGrades') return true;
              const [low, high] = grade && grade.split('-');
              return school?.GradeLow === low && school?.GradeHigh === high;
            });

          const matchesType =
            typeValues?.length === 0 ||
            typeValues?.some((type) => {
              if (type === 'allSchools') return true;
              if (type === 'Public' || type === 'Private') {
                return school?.Admission === type;
              } else if (type === 'Charter') {
                return school?.Charter;
              } else if (type === 'Magnet') {
                return school?.Magnet;
              }
              return false;
            });

          return matchesGrade && matchesType;
        });

        setFilteredSchools(filtered);
      }
    } else {
      setFilteredSchools(schools);
    }
  };

  const items = [
    { title: 'All Schools', value: 'allSchools', group: 'School Type' },
    { title: 'Public', value: 'Public', group: 'School Type' },
    { title: 'Private', value: 'Private', group: 'School Type' },
    { title: 'Charter', value: 'Charter', group: 'School Type' },
    { title: 'Magnet', value: 'Magnet', group: 'School Type' },
    { title: 'All Grades', value: 'allGrades', group: 'Grade Level' },
    ...gradeRanges.map((range) => ({ ...range, group: 'Grade Level' })),
  ];

  const getUniqueGradeRanges = (data) => {
    const gradeRanges = data?.map((item) => ({
      title: `${item.GradeLow} - ${item.GradeHigh}`,
      value: `${item.GradeLow}-${item.GradeHigh}`,
    }));

    const uniqueGradeRanges = [...new Map(gradeRanges.map((item) => [item.value, item])).values()];

    return uniqueGradeRanges;
  };

  return (
    <div className={styles.neighborhoodTabContainer}>
      <ExploreSection geoLat={geoLat} geoLong={geoLong} id={id} price={priceToDisplay} />
      <div className={styles.block}>
        <div className={styles.blockTitle}>Transportation</div>
        <NeighborhoodTransportation
          bikeScore={bikeScore}
          transitScore={transitScore}
          walkScore={walkScore}
          geoLat={geoLat}
          geoLong={geoLong}
          location={address}
        />
      </div>
      {/* <div testid="schools_block" className={styles.block}>
        <div className={styles.schoolHeading}>Schools</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles.container}>
            <SchoolListingDropdown
              items={items}
              multiple={true}
              onChangeValues={handleValuesChange}
            />
          </div>
          <div className={styles.mapActionButtonWrap}>
            <button onClick={toggleExploreMode} className={styles.changeModeBtn}>
              <Icons
                className={styles.changeModeBtnIcon}
                variant={isMapViewVisible ? Icons.LIST : Icons.MAP}
              />
            </button>
          </div>
        </div>
        <Wrapper isPending={loading}>
          {error ? (
            <ApiRequestError title={error?.message} />
          ) : (
            <>
              {isMapViewVisible && filteredSchools && (
                <div className={styles.listingMapWrap}>
                  <SchoolListingMap data={filteredSchools} />
                </div>
              )}
              {!isMapViewVisible && !isMobile && (
                <div className={styles.listingColumnsWrap}>
                  {schoolColumns &&
                    schoolColumns.map((data, ind) => (
                      <div
                        className={styles.listingColums}
                        style={{ width: data?.width, justifyContent: data?.justifyContent }}
                        key={ind}
                      >
                        {data?.label}
                      </div>
                    ))}
                </div>
              )}
              {!isMapViewVisible &&
                filteredSchools &&
                filteredSchools?.length !== 0 &&
                filteredSchools?.sort((a, b) => a.DistanceFromProperty - b.DistanceFromProperty)
                  ?.length &&
                map(filteredSchools, (info, index) => (
                  <React.Fragment key={index}>
                    <NeighborhoodSchool key={index} data={info} isMobile={isMobile} />
                    {index !== filteredSchools?.length - 1 && (
                      <div className={styles.dividerListing}></div>
                    )}
                  </React.Fragment>
                ))}
              <div
                className={classNames(
                  styles.InfoWrap,
                  isMobile ? styles.infoWrapMarginMobile : styles.infoWrapMargin,
                )}
              >
                This data may not be complete and school attendance zone boundaries are subject to
                change. They are not guaranteed to be accurate, up to date, or complete.Please check
                with the applicable school district to confirm school assignments and prior to
                making a decision based on this information. School ratings provided by Great
                Schools and Niche.
              </div>
              {!isMobile && <div className={styles.dividerListing}></div>}
              {!isMobile && (
                <div className={styles.reportWrap}>
                  <Icons variant={Icons?.REPORT} style={{ display: 'flex' }} />
                  <div className={styles.reportIncorrectTypography}>
                    Report incorrect information
                  </div>
                </div>
              )}
              {!isMobile && (
                <div className={styles.blockInfo}>
                  Copyright 2021 Midwest Real Estate Data LLC. All rights reserved. The data
                  relating to real estate for sale on this web site comes in part from the Broker
                  Reciprocity Program of the Midwest Real Estate Data LLC. Listing information is
                  deemed reliable but not guaranteed. NOTICE: Many homes contain recording devices,
                  and buyers should be aware they may be recorded during a showing.
                </div>
              )}
            </>
          )}
        </Wrapper>
      </div> */}
    </div>
  );
};

export default NeighborhoodTab;
