import { isNumber } from 'lodash-es';
import moment from 'moment';
import { getParticipantsData } from 'utils/workshopHelper';

export const prepareReviewersIds = (document) => {
  if (!document?.reviewers) return [];
  return document?.reviewers
    .filter((reviewer) => reviewer?.id !== 'everyone')
    .map((reviewer) => reviewer.id);
};

const getUTCDueDate = (date) => {
  if (!date) return undefined;
  return moment(date).utcOffset(0, true).toISOString().split('T')[0];
};

const getUsersEmailWithAccess = (document) => {
  return document?.UsersEmailWithAccess?.length
    ? document.UsersEmailWithAccess
    : getParticipantsData(document).UsersEmailWithAccess;
};

const getAccessToEveryone = (document) => {
  return document?.AccessToEveryone
    ? document.AccessToEveryone
    : getParticipantsData(document).AccessToEveryone;
};

const getUsersWithAccess = (document) => {
  return document?.UsersWithAccess?.length
    ? document?.UsersWithAccess.map((user) => user.Id)
    : getParticipantsData(document).UsersWithAccess;
};

export const handleData = (transactionId, cfg, currentUserId) => {
  const getAssigneeIds = (cfg, currentUserId) => {
    if (cfg?.AssigneeList?.length) {
      return cfg?.AssigneeList?.map((item) => item.Id || item.id);
    } else if (currentUserId) {
      return [currentUserId];
    }
  };

  const emailTemplate = cfg?.EmailTemplate;
  const body = {
    templateTaskId: cfg?.templateTaskId,
    formTemplateId: cfg?.formTemplateId,
    transactionId: transactionId,
    taskId: cfg?.Id,
    Title: cfg?.Title,
    Categories: cfg?.Categories?.map((item) => item?.Name || item),
    DueDate: getUTCDueDate(cfg?.DueDate),
    AssigneeIds: getAssigneeIds(cfg, currentUserId),
    Description: cfg?.Description,
    Links: cfg?.Links ? (cfg?.Links || []).filter((linkObject) => !!linkObject.link) : undefined,
    Checklists: cfg?.Checklists
      ? (cfg?.Checklists || []).filter((checklist) => !!checklist.Name)
      : undefined,
    Status: cfg?.Status,
    Documents: cfg?.Documents?.length
      ? (cfg?.Documents || []).map((document) => ({
          Title: document?.Title || document?.documentName,
          Category:
            cfg?.customTag ||
            cfg?.Category?.name ||
            cfg?.Category?.Name ||
            cfg?.Category ||
            document?.category ||
            document?.Category?.Name ||
            document?.Category ||
            undefined,
          File: {
            ContentType: document?.ContentType || document?.contentType || document?.Meta?.MimeType,
            Filename: document?.Filename || document?.filename || document?.Title,
            Size: document?.Size || document?.size,
            Url: document?.Url || document?.url,
            DocumentVaultUUID: document?.DocumentVaultUUID,
            Data:
              document?.Data || document?.data ? btoa(document?.Data || document?.data) : undefined,
          },
          UsersWithAccess: getUsersWithAccess(document),
          UsersEmailWithAccess: getUsersEmailWithAccess(document),
          AccessToEveryone: getAccessToEveryone(document),
        }))
      : undefined,
    EntityId: cfg?.FormId?.toString(),
    IsRequired: cfg?.IsRequired,
    TaskTemplateInitial: cfg?.TaskTemplateInitial,
    TaskTemplateOperator: cfg?.TaskTemplateOperator,
    TaskTemplateDateOffset: cfg?.TaskTemplateDateOffset,
    TaskTemplateInitialOperatorOffset: cfg?.TaskTemplateInitialOperatorOffset,
    MilestoneId: cfg?.MilestoneId || undefined,
    CampaignId: cfg?.CampaignId || undefined,
    Order: cfg?.Order || undefined,
    EmailTemplateId: emailTemplate?.EmailTemplateId,
    EmailTemplateVersionId: emailTemplate?.EmailTemplateVersionId,
    Hours: isNumber(cfg?.Hours) ? cfg?.Hours : null,
    Minutes: isNumber(cfg?.Minutes) ? cfg?.Minutes : null,
    IsBusinessDays: cfg?.IsBusinessDays,
    Timezone: cfg?.Timezone,
    ...(cfg?.RepeatOption ? { RepeatOption: cfg?.RepeatOption } : {}),
    ...(cfg?.CustomRepeatOption ? { CustomRepeatOption: cfg?.CustomRepeatOption } : {}),
    ...(cfg?.RepeatCount ? { RepeatCount: cfg?.RepeatCount } : {}),
    ...(cfg?.RepeatOn ? { RepeatOn: cfg?.RepeatOn } : {}),
    ...(cfg?.RepeatOccurrence ? { RepeatOccurrence: cfg?.RepeatOccurrence } : {}),
    ...(cfg?.RepeatUntilDate ? { RepeatUntilDate: cfg?.RepeatUntilDate } : {}),
    ...(cfg?.RepeatOption ? { EndsOnClosing: cfg?.EndsOnClosing } : {}),
    ...(cfg?.RepeatOption ? { EndsOnListingExpiration: cfg?.EndsOnListingExpiration } : {}),
  };

  if (cfg?.CcList) body.CcIds = cfg.CcList.map((item) => item.Id || item.id);

  return body;
};

export const handlePostData = (transactionId, cfg, currentUserId) => ({
  ...handleData(transactionId, cfg, currentUserId),
  SaveAsTemplate: !!cfg?.SaveAsTemplate,
});
