import { apiServer } from 'settings/web-services/api';

export function getAllPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/all', body, { params: queryParams });
}

export function getRecommendedPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/recommended', body, { params: queryParams });
}

export function getPriceReductionsPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/price-reductions', body, { params: queryParams });
}

export function getNewPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/new', body, { params: queryParams });
}

export function getSavedSearchesPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/savedSearches', body, { params: queryParams });
}

export function getStatusChangesPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/newStatus', body, { params: queryParams });
}

export function getWatchlistPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/watchlist', body, { params: queryParams });
}

export function getBackOnMarketPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/backOnMarket', body, { params: queryParams });
}

export function getSuggestionsPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/suggestions', body, { params: queryParams });
}

export function getOpenHousesPropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.get('/listings/feed/openHouse', { params: queryParams });
}

export function getSearchInstancePropsV2({ queryParams, body }: FeedApiParams) {
  return apiServer.post('/listings/v2/feed/search-instance', body, { params: queryParams });
}

export function getSavedSearchProperties({ queryParams, body }: FeedApiParams, options = {}) {
  const { signal, ...restOptions } = options as { signal?: AbortSignal };
  return apiServer.post('/listings/v3/search', body, {
    params: queryParams,
    signal,
    ...restOptions,
  });
}

export function getAgentProperties({ queryParams, body }: FeedApiParams, options = {}) {
  const { signal, ...restOptions } = options as { signal?: AbortSignal };

  return apiServer.post('/listings/v3/agent/search', body, {
    params: queryParams,
    signal,
    ...restOptions,
  });
}

export function getFavoritesProperties({ queryParams, body }: FeedApiParams) {
  return apiServer.get('/listings/v3/favorites', { params: queryParams });
}

export function getHighlightsProperties({ queryParams }: FeedApiParams) {
  return apiServer.get('/listings/v3/highlights', { params: queryParams });
}

export function getTopMatchesProperties({ queryParams }: FeedApiParams) {
  return apiServer.get('/listings/v3/top-matches', { params: queryParams });
}

export enum SortField {
  MatchScore = 'MatchScore',
  ClientsMatched = 'ClientsMatched',
  SellingPrice = 'SellingPrice',
  DaysOnMarket = 'DaysOnMarket',
  NumBeds = 'NumBeds',
  NumBathsTotal = 'NumBathsTotal',
  SquareFeet = 'SquareFeet',
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

export interface FeedV2Query {
  sortField: SortField;
  sortOrder: SortOrder;
  endCursor: string | null;
  first: number;
  contextId: string | null;
  loadTotalCount: boolean;
  currentTimeUTC: Date | string;
  searchInstanceId: Number | null;
  subFilterType?: string | null;
}

export interface FeedApiParams {
  queryParams: FeedV2Query;
  body: object;
}
