import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { SignatureResponseType } from 'pages/FormBuilder/components';
import { VALIDATION_TYPES } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/ValidationSettingsPopover/helper';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import {
  filterRadioQuestions,
  flatQuestions,
} from 'pages/FormProcess/DynamicForm/DynamicManager/helper';
import { FormDocumentQuestionsType } from 'types';
const EMOJI_REGEX =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|\ud83d[\ude00-\ude4f]|\ud83d[\ude80-\udeff]|\ud83e[\udd00-\uddff]|\ud83c[\udf00-\udfff]|\ud83d[\udc00-\udcff])/g;

// eslint-disable-next-line no-useless-escape
export const VALID_KEYS_REGEX = /^[a-zA-Z0-9!@#$%^&*()_+|}{":?><~`\\\[\]'./,;= \t\n-]*$/;

export const getSignatureConfig = ({
  bundleDocumentsDetails,
  activeQuestions = [] as FormDocumentQuestionsType,
  responses,
  showWizard = false,
}) => {
  const isSignatureCohesiveFlow =
    bundleDocumentsDetails.length > 1 && bundleDocumentsDetails[0].activeQuestions;

  let questions: any[] = activeQuestions;
  let answers = responses;
  let wizard = showWizard;

  if (isSignatureCohesiveFlow) {
    questions = [];
    answers = {};
    wizard = false;

    bundleDocumentsDetails.forEach((item) => {
      questions = [...questions, ...item.activeQuestions];
      answers = { ...answers, ...item.answers };
      wizard = wizard || item.showWizard;
    });
  }

  return { questions, answers, wizard };
};

export const getCohesiveFieldAnswers = (bundleDocumentsDetails) => {
  let answers = {};

  bundleDocumentsDetails.forEach((item) => {
    answers = { ...answers, ...(item.answers || {}) };
  });

  return answers;
};

export const getCohesiveFieldQuestions = (bundleDocumentsDetails) => {
  let questions: any[] = [];

  bundleDocumentsDetails.forEach((item) => {
    questions = [...questions, ...(item.activeQuestions || [])];
  });

  return questions;
};

const getFieldType = (questions, uuid) => {
  const fieldQuestion = questions.find(({ UUID }) => uuid === UUID);
  return fieldQuestion.FieldType || fieldQuestion.type;
};

export const getSignatureFieldsUUID = (questions) => {
  const signatureTypeUUID = questions
    .filter(({ FieldType, type }) =>
      [DYNAMIC_QUESTION_TYPE.Initials, DYNAMIC_QUESTION_TYPE.Signature].includes(type || FieldType),
    )
    .map(({ UUID }) => UUID);

  return signatureTypeUUID;
};

const getSignatureAnswer = (savedAnswer, updatedSignature, fieldType) => {
  const { type: answerType, signature: answerSignature } = savedAnswer;
  const { type, signModalTab = '', ...rest } = JSON.parse(updatedSignature);

  const isInitial = fieldType === DYNAMIC_QUESTION_TYPE.Initials;
  const isSignature = fieldType === DYNAMIC_QUESTION_TYPE.Signature;

  const signature = {
    ...rest,
    ...(isInitial && type === SignatureResponseType.Image
      ? { type: SignatureResponseType.Text }
      : isSignature && signModalTab === 'draw'
      ? { type: answerType, signature: answerSignature }
      : { type }),
  };

  return signature;
};

const prepareAnswers = (
  answers,
  updatedSignature,
  questions,
  questionUUID,
  pdfEditorView = false,
) => {
  let signatureUUIDs = getSignatureFieldsUUID(questions);
  signatureUUIDs = !pdfEditorView
    ? signatureUUIDs.filter((uuid) => uuid !== questionUUID)
    : signatureUUIDs;

  const allAnswers = { ...answers };

  signatureUUIDs.forEach((UUID) => {
    if (allAnswers[UUID]) {
      const fieldType = getFieldType(questions, UUID);
      const answer = allAnswers[UUID];
      const signature = getSignatureAnswer(answer, updatedSignature, fieldType);
      allAnswers[UUID] = { ...allAnswers[UUID], Answer: JSON.stringify(signature) };
    }
  });

  return allAnswers;
};

const prepareEditorViewAnswers = (answers, data, questionUUID, isCohesiveFlow?) => {
  const dynamicDocuments = dynamicManager.getDynamicDocuments();
  if (!isCohesiveFlow) {
    const { questions } = dynamicManager.getEditorConfig();

    const preparedQuestions = flatQuestions(questions).questions;

    const documentAnswers = prepareAnswers(answers, data, preparedQuestions, questionUUID, true);
    dynamicDocuments[0].Answers = documentAnswers;
  } else {
    const bundleDocuments = dynamicManager.getBundleDocumentsDetails();
    bundleDocuments.forEach((item, index) => {
      const preparedQuestions = flatQuestions(item.questions).questions;
      const fieldAnswers = dynamicDocuments[index].Answers;

      const documentAnswers = prepareAnswers(
        fieldAnswers,
        data,
        preparedQuestions,
        questionUUID,
        true,
      );

      dynamicDocuments[index].Answers = documentAnswers;
    });
  }

  return dynamicDocuments;
};

export const getSignatureAnswers = (
  answers,
  data,
  questionUUID,
  isCohesiveFlow?,
  pdfEditorView?,
) => {
  let answerDetails;

  if (pdfEditorView) {
    answerDetails = prepareEditorViewAnswers(answers, data, questionUUID, isCohesiveFlow);
  } else if (isCohesiveFlow) {
    const documentDetails = dynamicManager.getBundleDocumentsDetails();

    documentDetails.forEach((item) => {
      item.answers = prepareAnswers(item.answers, data, item.activeQuestions, questionUUID);
    });

    answerDetails = [...documentDetails];
  } else {
    let questions: any[] = [];

    questions = dynamicManager.getQuestions(!!pdfEditorView);
    answerDetails = prepareAnswers(answers, data, questions, questionUUID);
  }

  return { answerDetails };
};

const prepareEditorSignatureQuestions = (questions, updatedSignature) => {
  const documentQuestions = { ...questions };

  Object.keys(documentQuestions).forEach((page) => {
    Object.keys(documentQuestions?.[page] || {}).forEach((role) => {
      documentQuestions[page][role].forEach((item, index) => {
        const { fieldAnswer, type: fieldType } = item;
        if (
          fieldAnswer &&
          (fieldType === DYNAMIC_QUESTION_TYPE.Signature ||
            fieldType === DYNAMIC_QUESTION_TYPE.Initials)
        ) {
          const signature = getSignatureAnswer(fieldAnswer.answer, updatedSignature, fieldType);
          documentQuestions[page][role][index].fieldAnswer = {
            ...fieldAnswer,
            answer: JSON.stringify(signature),
          };
        }
      });
    });
  });

  return documentQuestions;
};

export const modifySignatureEditorQuestions = (updatedSignature, isCohesiveFlow?) => {
  if (isCohesiveFlow) {
    const bundleDocuments = dynamicManager.getBundleDocumentsDetails();
    bundleDocuments.forEach((item: any) => {
      item.questions = prepareEditorSignatureQuestions(item.questions, updatedSignature);
    });

    return bundleDocuments;
  } else {
    const { questions } = dynamicManager.getEditorConfig();
    const documentQuestions = prepareEditorSignatureQuestions(questions, updatedSignature);
    return documentQuestions;
  }
};

export const modifySignatureUUID = (UUID) => {
  if (!UUID) return '';

  const plainText = UUID.replaceAll('-', '').toUpperCase();
  return `${plainText.substring(0, 14)}...`;
};

export const getDateFontSize = (width, defaultFontSize) => {
  if (width <= 70) return 3;
  else if (width <= 100) return 10;
  else if (width <= 130) return 12;

  return defaultFontSize;
};

export const hasEmoji = (value) => {
  return !VALID_KEYS_REGEX.test(value);
};

export const removeEmoji = (value) => {
  const replaced = value.replace(EMOJI_REGEX, '');
  return replaced;
};

export const isSafari = () => {
  const ua = navigator.userAgent;
  return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('Chromium');
};

/** Radio group related helpers */
const getGroupQuestions = (questions, validationId) => {
  const radioQuestions = filterRadioQuestions(questions) || [];

  const groupQuestions = radioQuestions.filter(
    ({ QuestionValidation }) => QuestionValidation?.Id === validationId,
  );

  return groupQuestions;
};

export const getGroupItemAnswer = (questions, item, answers) => {
  const groupQuestions = getGroupQuestions(questions, item.QuestionValidationId);

  return groupQuestions?.find(({ UUID }) => UUID !== item.UUID && answers[UUID]?.Answer)?.UUID;
};

export const isValidationFulfilled = (questions, validationId, answers) => {
  const groupQuestions = getGroupQuestions(questions, validationId);

  const groupAnswers = groupQuestions.filter(({ UUID }) => answers[UUID]?.Answer);

  const validation = groupQuestions[0]?.QuestionValidation;

  if (
    !groupQuestions.length ||
    (!groupAnswers?.length && validation.Type === VALIDATION_TYPES.AtMost && validation.Value === 1)
  )
    return true;

  if (!groupAnswers?.length) return false;

  return (
    isAtLeastValidated(validation, groupAnswers) ||
    isAtMostValidated(validation, groupAnswers) ||
    isExactlyValidated(validation, groupAnswers) ||
    isInRangeValidated(validation, groupAnswers)
  );
};

export const canFillNextQuestion = (questions, validationId, answers) => {
  const groupQuestions = getGroupQuestions(questions, validationId);
  const groupAnswers = groupQuestions.filter(({ UUID }) => answers[UUID]?.Answer);

  let canFill = true;

  if (!groupAnswers?.length || shouldAllowOnlyOne(groupQuestions[0])) return canFill;

  const validation = groupQuestions[0].QuestionValidation;
  const { Value, Max, Type } = validation;

  if (Type === VALIDATION_TYPES.AtLeast) return true;

  canFill = Max ? groupAnswers.length !== Max : groupAnswers.length !== Value;

  return canFill;
};

export const shouldAllowOnlyOne = (field) => {
  if (!field.QuestionValidation) return false;

  const { Type, Value } = field.QuestionValidation;

  return (Type === VALIDATION_TYPES.AtMost || Type === VALIDATION_TYPES.Exactly) && Value === 1;
};

export const isAtLeastValidated = (validation, answers) => {
  const { Type, Value } = validation;
  return Type === VALIDATION_TYPES.AtLeast && answers.length >= Value;
};

export const isAtMostValidated = (validation, answers) => {
  const { Type, Value } = validation;
  return Type === VALIDATION_TYPES.AtMost && answers.length <= Value;
};

export const isExactlyValidated = (validation, answers) => {
  const { Type, Value } = validation;
  return Type === VALIDATION_TYPES.Exactly && answers.length === Value;
};

export const isInRangeValidated = (validation, answers) => {
  const { Type, Min, Max } = validation;
  return Type === VALIDATION_TYPES.InRange && answers.length >= Min && answers.length <= Max;
};

export const getValidationMessage = (validation) => {
  if (!validation) return '';

  const { Type, Min, Max, Value } = validation;

  const { InRange, AtMost, Exactly } = VALIDATION_TYPES;

  if (Type == AtMost || Type === Exactly) {
    const label = AtMost ? 'At most' : 'Exactly';
    return `${label} ${Value} answers are required!`;
  } else if (Type === InRange) {
    return `Number of answers must be between ${Min} and ${Max}`;
  }
};
