/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import ReactHintFactory from 'react-hint';

import ApplicantsHint from 'components/Applicants/Hint';
import FavoritesHint from 'components/Favorite/Hint';
import MatchHint from 'components/Match/Hint';
import MatchUserHint from 'pages/Properties/ListingDetail/components/Match/MatchUserListItem/Hint';
import RecommendHint from 'pages/ServicesCategory/components/OthersRolesPartnersList/Hint';
import 'react-hint/css/index.css';

import styles from './styles.module.scss';
import './styles.scss';
import MatchWithBuyerHint from 'pages/Properties/components/MatchFav/Hint';

const ReactHint = ReactHintFactory(React);

const Hint = () => {
  const [customPosition, setCustomPosition] = useState(null);

  const onRenderContent = (target, content) => {
    if (target.dataset.buyers) {
      setCustomPosition('left');
      const buyers = JSON.parse(target.dataset.buyers);
      return <ApplicantsHint className={styles.hint} buyers={buyers} />;
    }

    if (target.dataset.addedtofavoritesby) {
      setCustomPosition(null);
      const addedToFavoritesBy = JSON.parse(target.dataset.addedtofavoritesby);
      return <FavoritesHint className={styles.hint} addedToFavoritesBy={addedToFavoritesBy} />;
    }

    if (target.dataset.match) {
      const { matchedCriterias, matchScore } = JSON.parse(target.dataset.match);
      setCustomPosition('left');
      return (
        <MatchHint
          className={styles.hint}
          matchedCriterias={matchedCriterias}
          matchScore={matchScore}
        />
      );
    }

    if (target.dataset.matchuser) {
      setCustomPosition('right');
      const { avatar, name, score, mustHave, wants, searchInstance } = JSON.parse(
        target.dataset.matchuser,
      );
      return (
        <MatchUserHint
          className={styles.hint}
          avatar={avatar}
          name={name}
          score={score}
          mustHave={mustHave}
          wants={wants}
          searchInstance={searchInstance}
        />
      );
    }

    if (target?.dataset?.matchwithbuyers) {
      setCustomPosition('left');
      const { buyers = [], addedToFavoritesBy = [] } = JSON.parse(
        target?.dataset?.matchwithbuyers || '{}',
      );
      return (
        <MatchWithBuyerHint
          className={styles.hint}
          buyers={buyers}
          addedToFavoritesBy={addedToFavoritesBy}
        />
      );
    }

    if (target.dataset.servicerecommend) {
      setCustomPosition(null);
      const data = JSON.parse(target.dataset.servicerecommend);
      return <RecommendHint className={styles.hint} {...data} />;
    }
    setCustomPosition('top');
    return <div className={styles.hint}>{content}</div>;
  };

  return (
    <ReactHint
      autoPosition={!customPosition}
      position={customPosition ?? undefined}
      events={{ hover: true, focus: true, click: true }}
      onRenderContent={onRenderContent}
      delay={{ show: 0, hide: 100 }}
      persist
    />
  );
};

export default Hint;
