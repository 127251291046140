import { useMemo } from 'react';
import { SingleMultiAvatar } from 'components/SingleMultiAvatar';
import { EditCircle } from 'components/Icons';
import { formatDate } from 'helpers';
import { Icons } from '../../Task/ViewRevamped/Icons';

import styles from './styles.module.scss';

export const CollapsedTaskDetails = ({ isTemplate, task, onEdit }) => {
  const assigneeList = useMemo(() => {
    return task?.AssigneeList?.map((item) => ({
      ...item,
      FirstName: item?.firstName || item?.FirstName,
      LastName: item?.lastName || item?.LastName,
    }));
  }, [task?.AssigneeList]);

  return (
    <div className={styles.collapsedTaskDetails}>
      <div className={styles.left}>
        {isTemplate ? (
          <Icons variant={Icons.TASK} />
        ) : (
          <SingleMultiAvatar avatar={assigneeList} showOverlayCounter={false} />
        )}
        <div className={styles.taskSummary}>
          <p className={styles.title}>{task?.Title || task?.Name || '-'} </p>
          <p className={styles.dueDate}>
            Due:{' '}
            {isTemplate
              ? task?.ControlOperatorOffset
              : formatDate(task?.DueDate, 'MMM DD, YYYY') || '-'}
          </p>
        </div>
      </div>
      <div className={styles.right}>
        <EditCircle className={styles.editIcon} onClick={onEdit} />
      </div>
    </div>
  );
};
