import moment from 'moment';
import { isNumber } from 'lodash-es';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { Input, InputLabel, DatePicker } from 'components';

import {
  validateControlOperatorOffset,
  convertToInitialOffset,
  convertToDateTime,
} from 'utils/templatesHelper';
import { getTransactionDateControlsSelector } from 'store/selectors/transaction';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { getDateOnly } from 'helpers';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { DueDateOptions } from '../DueDateOptions';
import { DueDateTime, Repeat } from '../DueDateOptions/components';
import { Tooltip } from 'components-antd';
import { InfoIcon } from 'pages/ServicesCategory/components/AgentPartnersList/components/PartnersList/components/PartnerCard/icons';
import { getUserTimeZone } from 'store/selectors/user';

import styles from './styles.module.scss';

interface DueDateProps {
  values: TaskEditableFormValuesType;
  onChangeManyFields: (data: TaskEditableFormValuesType) => void;
  isTemplate: boolean;
  isCampaignTask?: boolean;
  isViewMode?: boolean | null;
  hideAsterisk?: boolean | null;
  hideLabel?: boolean;
  wrapperClassName?: string;
  multiTasks?: boolean;
  scrollToBottom?: boolean;
  customDueDate?: Date | null;
  inputWrapperClassName?: string;
  isProjectTask?: boolean;
  isTooltipInfoText?: boolean;
  hideRepeat?: boolean;
  showMdDropdown?: boolean;
  mdDropdownLayout?: ReactNode;
}

export const DueDate = ({
  isTemplate,
  isCampaignTask,
  values,
  onChangeManyFields,
  isViewMode,
  hideAsterisk,
  hideLabel = false,
  wrapperClassName,
  multiTasks = false,
  scrollToBottom = false,
  customDueDate = null,
  inputWrapperClassName,
  isProjectTask = false,
  isTooltipInfoText = false,
  hideRepeat = false,
  showMdDropdown = false,
  mdDropdownLayout,
}: DueDateProps) => {
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [isAddTime, setIsAddTime] = useState<boolean>(false);
  const [isRepeat, setIsRepeat] = useState<boolean>(false);
  const [isBusinessDays, setIsBusinessDays] = useState<boolean>(false);
  const [time, setTime] = useState<Date>();
  const [timezone, setTimezone] = useState();
  const userTimezone = useSelector(getUserTimeZone);

  const dateControls = useSelector(getTransactionDateControlsSelector);
  const inputRef = useRef();

  const userTimezoneMap = {
    'US/Eastern': 'America/New_York',
    'US/Central': 'America/Chicago',
    'US/Mountain': 'America/Denver',
    'US/Pacific': 'America/Los_Angeles',
    'US/Alaska': 'America/Anchorage',
    'US/Hawaii': 'Pacific/Honolulu',
  };

  useEffect(() => {
    if (customDueDate) {
      dateControls.MD = customDueDate;
      handleDateChange(
        {
          target: {
            value: getControlOffsetValue(),
          },
        },
        isBusinessDays,
      );
    } else {
      delete dateControls?.MD;
    }
  }, [customDueDate]);

  useEffect(() => {
    if (isNumber(values?.Hours) && isNumber(values?.Minutes)) {
      setIsAddTime(true);
      setTime(moment({ hour: values?.Hours, minute: values?.Minutes }).toDate());
    }
    setIsBusinessDays(values?.IsBusinessDays);
    setTimezone(values?.Timezone || userTimezoneMap?.[userTimezone]);
    setIsRepeat(!!values?.RepeatOption);
  }, [values]);

  const isProject = useIsProjectRoute() || isProjectTask;
  const onChangeControlOperatorOffset = (e, val) => {
    const dueDate = values?.DueDate;
    !isTemplate && dueDate
      ? onChangeManyFields({
          DueDate: null,
          ControlOperatorOffset: '',
        })
      : validateControlOperatorOffset(
          e,
          val,
          onChangeManyFields,
          null,
          isTemplate,
          true,
          multiTasks,
          isCampaignTask,
        );
  };

  const getControlOffsetValue = () =>
    !isTemplate && values?.DueDate
      ? getDateOnly(values.DueDate, 'MMMM D, Y')
      : values?.ControlOperatorOffset;

  const handleDateChange = (e, isBusinessDaysEnabled) =>
    isTemplate
      ? convertToInitialOffset(e.target.value, onChangeManyFields)
      : convertToDateTime(
          e.target.value,
          dateControls,
          (data) => {
            onChangeManyFields({ ...data, IsBusinessDays: isBusinessDaysEnabled });
            setShowErrMsg(!data.DueDate);
          },
          null,
          true,
          isBusinessDaysEnabled,
        );

  const getPlaceholderText = () => {
    if (multiTasks) return 'M/D/YY';
    if (isTemplate && isCampaignTask) return 'A+2, CL+4';
    return `${isTemplate ? 'A' : 'T'}+7, ${isProject ? 'KO+2, DL-4' : 'CD+2, CL-4, MD+2'}`;
  };

  const getInfoText = () => {
    const content = (
      <span className={styles.infoText}>
        {!isTooltipInfoText ? '(' : ''}
        {values?.ControlOperatorOffset ? `${values?.ControlOperatorOffset} ` : ''}
        {isBusinessDays ? 'business' : 'calendar'} days{!isTooltipInfoText ? ')' : ''}
      </span>
    );

    if (isTooltipInfoText)
      return (
        <Tooltip title={content} className={styles.tooltipContainer}>
          <InfoIcon />
        </Tooltip>
      );

    return content;
  };

  const toggleBusinessDaysSelection = (value) => {
    let isResetDueDate = false;
    const dueDate = moment(values?.DueDate);

    setIsBusinessDays(value);

    // If the date is on weekend & isBusiness is selected, reset it
    if (value && (dueDate.isoWeekday() === 6 || dueDate.isoWeekday() === 7)) isResetDueDate = true;

    if (values?.ControlOperatorOffset) {
      handleDateChange(
        {
          target: {
            value: values?.ControlOperatorOffset,
          },
        },
        value,
      );
    } else
      onChangeManyFields({
        IsBusinessDays: value,
        ...(isResetDueDate ? { DueDate: null } : {}),
      });

    if (isTemplate)
      onChangeManyFields({
        IsBusinessDays: value,
        ...(isResetDueDate ? { DueDate: null } : {}),
      });
  };

  const toggleRepeatOption = (value) => {
    setIsRepeat(value);
    if (!value) {
      onChangeManyFields({
        RepeatOption: null,
        CustomRepeatOption: null,
        RepeatCount: null,
        RepeatOn: null,
        RepeatOccurrence: null,
        RepeatUntilDate: null,
        EndsOnClosing: false,
        EndsOnListingExpiration: false,
      });
    }
  };

  return (
    <div className={classnames(styles.dueDate, { [styles.marginBottom]: isAddTime })}>
      <div className={classnames(styles.fieldWrapper, wrapperClassName)}>
        {hideLabel ? (
          <></>
        ) : (
          <InputLabel label="Due Date" className={styles.label} showAsterisk={!hideAsterisk} />
        )}
        <div className={classnames(styles.dueDateWrapper, { [styles.gap]: isAddTime })}>
          <div className={classnames(styles.datePicker, inputWrapperClassName)}>
            <DatePicker
              className={classnames(styles.templateControlPicker, {
                [styles.disabledPicker]: isTemplate || isViewMode,
              })}
              variant="light"
              options={{ enableTime: false }}
              value={getControlOffsetValue()}
              onChange={(e, val) => {
                onChangeManyFields({
                  DueDate: val?.[0],
                  ControlOperatorOffset: '',
                });
                setShowErrMsg(!val?.[0]);
              }}
              daysFromToday={values?.Offset}
              testid="due_date"
              disabled={isTemplate || isViewMode}
              popoverCalender={true}
              placement="top"
              getPopupContainer={(node) => node}
              modalDatePicker={true}
              dateInputId="task-date-input"
              isBusinessDaysOnly={isBusinessDays}
            />
            <Input
              variant={Input.LIGHT_ROUND}
              placeholder={getPlaceholderText()}
              value={getControlOffsetValue()}
              onChange={(e, val) => onChangeControlOperatorOffset(e, val)}
              maxLength={10}
              onBlur={(e) => handleDateChange(e, isBusinessDays)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleDateChange(e, isBusinessDays);
                }
              }}
              saveOnChange={false}
              disabled={isViewMode}
              className={styles.datePickerInput}
              id="task-date-input"
            />
            {showErrMsg && <div className={styles.errorMsg}>Invalid Date</div>}
            {getInfoText()}
          </div>
          {isAddTime && (
            <DueDateTime
              time={time}
              setTime={(value) => {
                setTime(value);
                const hours = moment(value).hours();
                const mins = moment(value).minutes();
                onChangeManyFields({
                  Hours: hours,
                  Minutes: mins,
                  Timezone: timezone,
                });
              }}
              timezone={timezone}
              setTimezone={(value) => {
                setTimezone(value);
                onChangeManyFields({
                  Timezone: value,
                  Hours: values?.Hours,
                  Minutes: values?.Minutes,
                });
              }}
              isViewMode={isViewMode}
            />
          )}
          {!isViewMode && !isCampaignTask && (
            <DueDateOptions
              isAddTime={isAddTime}
              setIsAddTime={(value) => {
                setIsAddTime(value);
                if (!value)
                  onChangeManyFields({
                    Hours: null,
                    Minutes: null,
                    Timezone: null,
                  });
              }}
              isRepeat={isRepeat}
              setIsRepeat={toggleRepeatOption}
              isBusinessDays={isBusinessDays}
              setIsBusinessDays={toggleBusinessDaysSelection}
              hideRepeat={hideRepeat}
            />
          )}
        </div>
      </div>
      {showMdDropdown && mdDropdownLayout}
      {isRepeat && (
        <Repeat
          isTemplate={isTemplate}
          isProjectTask={isProjectTask}
          onChangeManyFields={onChangeManyFields}
          task={values}
          className={isAddTime ? styles.marginTopRepeat : ''}
          isBusinessDays={isBusinessDays}
          isViewMode={isViewMode}
        />
      )}
    </div>
  );
};
