import { Typography } from 'antd';
import Close from 'components/Close';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { LocationSearch } from '../ClientSearch/LocationSearch';

const Search = ({ handleClose }) => {
  const { agentClientMode } = useSelector(getFeedMetaSelectorV3);

  return (
    <div className={styles.fullContainer}>
      <div className={styles.closeContainer}>
        <Close onClick={handleClose} />
      </div>
      <div className={styles.searchContainer}>
        <Typography.Title level={2} className={styles.title}>
          Start a new search...
        </Typography.Title>
        <LocationSearch
          formClassname={styles.formWrapper}
          valuesWrapperClassName={styles.valuesWrapper}
          searchWrapperClassName={styles.searchWrapper}
          isSearchPage={false}
          userId={agentClientMode?.user?.Id}
        />
      </div>
    </div>
  );
};

export default Search;
