import { FC } from 'react';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { useReplyKitModal } from 'pages/Radar/RadarKits/ReplyKitsModal/useReplyKitModal';
import { ReplyAvatar } from 'pages/Radar/RadarKits/ReplyKitsModal/components/ReplyKitAvatar';
import { MinimizeIcon } from 'pages/Radar/RadarKits/components/icons/MinimizeIcon';

type ReplyKitContentProps = {
  reply: Record<string, any>;
  onMinimize?: () => void;
  totalCount: number;
};

export const ReplyKitContent: FC<ReplyKitContentProps> = ({ reply, onMinimize, totalCount }) => {
  const { cleanBodyText, extractNameFromEmail, extractEmailViaFrom, parsedDate } =
    useReplyKitModal();
  const hasTextBody = reply.TextBody;
  const date = parsedDate(reply.TextBody);

  return (
    <div className={styles.kitReplyContentWrapper}>
      <div className={styles.kitReplyContent}>
        <span>
          <ReplyAvatar clientName={extractNameFromEmail(reply.From)} />
        </span>

        <div className={styles.senderInfo}>
          {onMinimize ? (
            <MinimizeIcon className={styles.replyMinimize} onClick={onMinimize} />
          ) : (
            <></>
          )}
          <div className={styles.senderInfoFromName}>
            <strong className={styles.senderInfoFrom}>{extractNameFromEmail(reply.From)}</strong>{' '}
            &nbsp; &nbsp;{' '}
            <span className={styles.senderInfoFromEmail}>{extractEmailViaFrom(reply.From)}</span>
          </div>
          <>
            {hasTextBody ? (
              <>
                <div className={styles.emailMetadata}>
                  <div>
                    <span className={styles.fw500}>Date:&nbsp;</span>
                    {date || new Date(reply.CreatedDate).toLocaleString()}
                  </div>
                  <div>
                    <span className={styles.fw500}>Subject:</span> {reply.Subject}
                  </div>
                  <div>
                    <span className={styles.fw500}>To:</span> {reply.To}
                  </div>
                </div>
                <div
                  className={classNames(styles.textEmail, {
                    [styles.contentShort]: totalCount === 4,
                  })}
                >
                  {cleanBodyText(reply.TextBody)}
                </div>
              </>
            ) : (
              <div className={styles.contentWrapper}>
                <div className={classNames(styles.tableContainer)}>
                  <div dangerouslySetInnerHTML={{ __html: reply?.HTMLBody || '' }} />
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
