import { Menu, Dropdown, Collapse } from 'antd';
import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { TextArea } from 'components-antd';
import { v4 as uuid } from 'uuid';
import { Add, Lock, Info } from 'components/Icons';
import GeneralInfo from './components/GeneralInfo';
import PointOfContact from './components/PointOfContact';
import ServiceAreas from './components/ServiceAreas';
import ServicesOffered from './components/ServicesOffered';
import { useSelector } from 'react-redux';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import classNames from 'classnames';
import {
  AddVendorPayloadType,
  detailsMenuItems,
  VendorDetailType,
  VendorValidationErrors,
} from '../../constants';
import { MenuInfo } from '../../icons';

type AddVendorDetailsPropsType = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
  vendorDetailTypes: VendorDetailType[];
  setVendorDetailTypes: Function;
  activeSection: VendorDetailType | undefined;
  setActiveSection: Function;
  partnerTags: any[] | undefined;
  errors: VendorValidationErrors;
  setErrors: Function;
  aopError: boolean;
};

const { Panel } = Collapse;

const AddVendorDetails = (props: AddVendorDetailsPropsType) => {
  const {
    newVendorPayload,
    setNewVendorPayload,
    vendorDetailTypes,
    setVendorDetailTypes,
    activeSection,
    setActiveSection,
    partnerTags,
    errors,
    setErrors,
    aopError,
  } = props;
  const maxLocationsInHeader = 3;
  const [showDetailsMenu, setShowDetailsMenu] = useState<boolean>(false);
  const allCategories = useSelector(getAllServicesCategoriesList);
  const selectedConciergeCategory = useSelector(getConciergeSearchSelector);
  const [phoneAdded, setPhoneAdded] = useState<boolean>(false);
  const [websiteAdded, setWebsiteAdded] = useState<boolean>(false);
  const [addressAdded, setAddressAdded] = useState<boolean>(false);
  const groupedSections: VendorDetailType[] = [
    VendorDetailType.General,
    VendorDetailType.PhoneNumber,
    VendorDetailType.Website,
    VendorDetailType.Address,
  ];

  const allCategoriesOptions = useMemo<{ name: string; value: number }[]>(() => {
    return allCategories.map((category) => ({ name: category.Title, value: Number(category.Id) }));
  }, [allCategories]);

  useEffect(() => {
    if (
      !newVendorPayload.Category &&
      selectedConciergeCategory.category &&
      !isNaN(Number(selectedConciergeCategory.category)) &&
      allCategoriesOptions.length
    ) {
      const found = allCategoriesOptions.find(
        (cat) => cat.value === Number(selectedConciergeCategory.category),
      );
      if (found)
        setNewVendorPayload((prev) => {
          return { ...prev, Category: { ...found } };
        });
    }
  }, [selectedConciergeCategory.category, allCategoriesOptions]);

  const handleMenuClick = async (e) => {
    const key = e.key;

    if (vendorDetailTypes.includes(key)) return;

    if (key && key != activeSection) {
      if (
        activeSection &&
        groupedSections.includes(key) &&
        !groupedSections.includes(activeSection)
      ) {
        setActiveSection(undefined);
        await delay();
      }
      if (
        key === VendorDetailType.General ||
        key === VendorDetailType.PhoneNumber ||
        key === VendorDetailType.Website ||
        key === VendorDetailType.Address
      ) {
        setActiveSection(VendorDetailType.General);
        if (key === VendorDetailType.PhoneNumber) setPhoneAdded(true);
        if (key === VendorDetailType.Website) setWebsiteAdded(true);
        if (key === VendorDetailType.Address) setAddressAdded(true);
      } else setActiveSection(key);
      if (key === VendorDetailType.ServiceAreas && !newVendorPayload.ServiceAreas?.length)
        setNewVendorPayload((prev) => {
          return {
            ...prev,
            ServiceAreas: [{ uuid: uuid(), hasRadius: true, radius: 0, location: undefined }],
          };
        });
      if (!vendorDetailTypes.includes(key)) setVendorDetailTypes((prev) => [...prev, key]);
      setShowDetailsMenu(false);
    }
  };

  const detailsMenu = (
    <div
      className={styles.menuContainer}
      onClick={() => {
        setShowDetailsMenu(false);
      }}
    >
      <Menu onClick={handleMenuClick} className={styles.menu}>
        {detailsMenuItems.map((item) => (
          <Menu.Item
            key={item.key}
            className={vendorDetailTypes.includes(item.key) ? styles.menuItemSelected : ''}
          >
            <div className={styles.menuItem}>
              <div className={styles.icon} style={{ backgroundColor: item.iconBG }}>
                {item.icon}
              </div>
              <div className={styles.labelBox}>
                <div className={styles.label}>{item.label}</div>
                {item.subText && <div className={styles.subText}>{item.subText}</div>}
              </div>
            </div>
          </Menu.Item>
        ))}
        <div className={styles.footer}>
          <div>
            <MenuInfo />
          </div>
          <div className={styles.text}>
            The vendor will be able to add/edit these details during account activation. You can add
            them ahead of time if you choose.
          </div>
        </div>
      </Menu>
    </div>
  );

  const delay = () => {
    return new Promise((resolve) => setTimeout(resolve, 25));
  };

  const onChange = async (key: string | string[]) => {
    if (key && key != activeSection) {
      setActiveSection(undefined);
      await delay();
      setActiveSection(key);
    }
  };

  const getServiceAreasSubHeader = (): string => {
    if (!newVendorPayload.ServiceAreas?.length) return '';

    let locations = newVendorPayload.ServiceAreas.filter((area) => area?.location?.ProviderPlaceId);

    if (!locations.length) return '';

    let remainingCount = 0;

    if (locations.length > 3) {
      remainingCount = locations.length - maxLocationsInHeader;
      locations = locations.slice(0, 3);
    }

    const header = locations
      .map((area) => {
        if (area.location.Type === 'State' || area.location.Type === 'County')
          return `${area.location.PlaceName.split(',')[0]}, ${area.location.State}`;
        else if (area.location.Type === 'Zipcode')
          return `${area.location.PlaceName.split(',')[0]}`;
        else return `${area.location.City}, ${area.location.State}`;
      })
      .join('; ');

    return remainingCount ? `${header}; +${remainingCount} more` : header;
  };

  const containsAllVendorDetailTypes = (): boolean => {
    const allValues = Object.values(VendorDetailType).filter(
      (val) => val !== VendorDetailType.General,
    );
    return allValues.every((type) => vendorDetailTypes.includes(type));
  };

  return (
    <div className={styles.content}>
      <Collapse
        defaultActiveKey={VendorDetailType.General}
        onChange={onChange}
        accordion
        activeKey={activeSection}
        className={styles.antCollapse}
        collapsible="header"
        bordered={false}
      >
        <Panel
          className={classNames(
            styles.antCollapsePanel,
            activeSection === VendorDetailType.General
              ? styles.antCollapsePanelOpen
              : styles.antCollapsePanelClose,
          )}
          key={VendorDetailType.General}
          showArrow={false}
          header={
            <div className={styles.antCollapsePanelHeader}>
              {newVendorPayload.LogoFile?.Data ||
              allCategories?.find((cat) => cat.Id === newVendorPayload.Category?.value)?.Icon ? (
                <img
                  src={
                    newVendorPayload.LogoFile?.Data ||
                    allCategories?.find((cat) => cat.Id === newVendorPayload.Category?.value)?.Icon
                  }
                  alt="category"
                  className={styles.image}
                />
              ) : (
                <div className={styles.iconPlaceholder}>
                  <Info />
                </div>
              )}
              <div className={styles.info}>
                <div className={styles.name}>{newVendorPayload.BusinessName || 'Vendor Info'}</div>
                {newVendorPayload.Category ? (
                  <div className={styles.subHeading}>{newVendorPayload.Category.name}</div>
                ) : null}
              </div>
            </div>
          }
        >
          <div
            className={classNames(
              styles.sectionHeader,
              newVendorPayload.Published ? styles.sectionHeaderLabel : '',
            )}
          >
            Details
          </div>
          <GeneralInfo
            newVendorPayload={newVendorPayload}
            setNewVendorPayload={setNewVendorPayload}
            vendorDetailTypes={vendorDetailTypes}
            errors={errors}
            setErrors={setErrors}
            phoneAdded={phoneAdded}
            websiteAdded={websiteAdded}
            addressAdded={addressAdded}
            allCategoriesOptions={allCategoriesOptions.length ? allCategoriesOptions : []}
          />
        </Panel>
        {vendorDetailTypes.map((type) => {
          if (type === VendorDetailType.ServiceAreas)
            return (
              <Panel
                className={classNames(
                  styles.antCollapsePanel,
                  styles.antCollapsePanelAreas,
                  activeSection === VendorDetailType.ServiceAreas
                    ? styles.antCollapsePanelOpen
                    : styles.antCollapsePanelClose,
                )}
                key={VendorDetailType.ServiceAreas}
                showArrow={false}
                header={
                  <div className={styles.antCollapsePanelHeader}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundColor: detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.ServiceAreas,
                        )?.iconBG,
                      }}
                    >
                      {
                        detailsMenuItems.find((item) => item.key === VendorDetailType.ServiceAreas)
                          ?.icon
                      }
                    </div>
                    <div className={styles.info}>
                      <div className={styles.name}>Service Areas</div>
                      {newVendorPayload.ServiceAreas?.length ? (
                        <div className={styles.subHeading}>{getServiceAreasSubHeader()}</div>
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div className={classNames(styles.sectionHeader, styles.sectionHeaderLabel)}>
                  Service Areas
                </div>
                <ServiceAreas
                  newVendorPayload={newVendorPayload}
                  setNewVendorPayload={setNewVendorPayload}
                  aopError={aopError}
                />
              </Panel>
            );
          if (type === VendorDetailType.ServicesOffered)
            return (
              <Panel
                className={classNames(
                  styles.antCollapsePanel,
                  activeSection === VendorDetailType.ServicesOffered
                    ? styles.antCollapsePanelOpen
                    : styles.antCollapsePanelClose,
                )}
                key={VendorDetailType.ServicesOffered}
                showArrow={false}
                header={
                  <div className={styles.antCollapsePanelHeader}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundColor: detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.ServicesOffered,
                        )?.iconBG,
                      }}
                    >
                      {
                        detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.ServicesOffered,
                        )?.icon
                      }
                    </div>
                    <div className={styles.info}>
                      <div className={styles.name}>Services Offered</div>
                      {newVendorPayload.ServicesOffered?.length ? (
                        <div className={styles.subHeading}>
                          {newVendorPayload.ServicesOffered.map((ser) => ser.name).join(', ')}
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div className={classNames(styles.sectionHeader, styles.sectionHeaderLabel)}>
                  Services Offered
                </div>
                <ServicesOffered
                  newVendorPayload={newVendorPayload}
                  setNewVendorPayload={setNewVendorPayload}
                  partnerTags={partnerTags}
                />
              </Panel>
            );
          if (type === VendorDetailType.PointOfContact)
            return (
              <Panel
                className={classNames(
                  styles.antCollapsePanel,
                  activeSection === VendorDetailType.PointOfContact
                    ? styles.antCollapsePanelOpen
                    : styles.antCollapsePanelClose,
                )}
                key={VendorDetailType.PointOfContact}
                showArrow={false}
                header={
                  <div className={styles.antCollapsePanelHeader}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundColor: detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.PointOfContact,
                        )?.iconBG,
                      }}
                    >
                      {
                        detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.PointOfContact,
                        )?.icon
                      }
                    </div>
                    <div className={styles.info}>
                      <div className={styles.name}>Point of Contact</div>
                      {newVendorPayload.PointOfContactName ? (
                        <div className={styles.subHeading}>
                          {newVendorPayload.PointOfContactName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div className={styles.sectionHeader}>Point of Contact</div>
                <PointOfContact
                  newVendorPayload={newVendorPayload}
                  setNewVendorPayload={setNewVendorPayload}
                  errors={errors}
                  setErrors={setErrors}
                />
              </Panel>
            );
          if (type === VendorDetailType.AgentNote)
            return (
              <Panel
                className={classNames(
                  styles.antCollapsePanel,
                  styles.antCollapsePanelNote,
                  activeSection === VendorDetailType.AgentNote
                    ? styles.antCollapsePanelOpen
                    : styles.antCollapsePanelClose,
                )}
                key={VendorDetailType.AgentNote}
                showArrow={false}
                header={
                  <div className={styles.antCollapsePanelHeader}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundColor: detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.AgentNote,
                        )?.iconBG,
                      }}
                    >
                      {
                        detailsMenuItems.find((item) => item.key === VendorDetailType.AgentNote)
                          ?.icon
                      }
                    </div>
                    <div className={styles.info}>
                      <div className={styles.name}>Agent Note</div>
                      {newVendorPayload.AgentNote ? (
                        <div className={styles.subHeading}>{newVendorPayload.AgentNote}</div>
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div className={styles.sectionHeader}>Agent Note</div>
                <TextArea
                  className={styles.textArea}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder={'Start typing...'}
                  onChange={(e) => {
                    setNewVendorPayload((prev) => {
                      return { ...prev, AgentNote: e.target.value };
                    });
                  }}
                  value={newVendorPayload.AgentNote}
                />
                <div className={styles.footNote}>
                  <Lock lockType="small" />
                  Visible only to clients, not to vendors
                </div>
              </Panel>
            );
          if (type === VendorDetailType.Description)
            return (
              <Panel
                className={classNames(
                  styles.antCollapsePanel,
                  activeSection === VendorDetailType.Description
                    ? styles.antCollapsePanelOpen
                    : styles.antCollapsePanelClose,
                )}
                key={VendorDetailType.Description}
                showArrow={false}
                header={
                  <div className={styles.antCollapsePanelHeader}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundColor: detailsMenuItems.find(
                          (item) => item.key === VendorDetailType.Description,
                        )?.iconBG,
                      }}
                    >
                      {
                        detailsMenuItems.find((item) => item.key === VendorDetailType.Description)
                          ?.icon
                      }
                    </div>
                    <div className={styles.info}>
                      <div className={styles.name}>Description</div>
                      {newVendorPayload.Description ? (
                        <div className={styles.subHeading}>{newVendorPayload.Description}</div>
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div className={styles.sectionHeader}>Description</div>
                <TextArea
                  className={styles.textArea}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder={'Enter description...'}
                  onChange={(e) => {
                    setNewVendorPayload((prev) => {
                      return { ...prev, Description: e.target.value };
                    });
                  }}
                  value={newVendorPayload.Description}
                />
              </Panel>
            );
          return null;
        })}
      </Collapse>
      {containsAllVendorDetailTypes() ? null : (
        <div className={styles.addMoreDetails}>
          <Dropdown
            overlay={detailsMenu}
            trigger={['click']}
            open={showDetailsMenu}
            onOpenChange={(flag: boolean) => {
              setShowDetailsMenu(flag);
            }}
            className={styles.addMoreDetailsDD}
          >
            <div
              className={styles.box}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Add color={'orange_circle_small'} container={false} />
              <div className={styles.text}>Add More Details</div>
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default AddVendorDetails;
