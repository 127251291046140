import styles from './styles.module.scss';
import { FilterSection } from './FilterSection';
import React, { useMemo, useState } from 'react';
import { DatePicker } from 'components';
import { Locations, Checkbox } from 'components';
import { Input, Option } from 'components-antd';
import { ServiceIcons, ServiceIconTypes } from '../../../../Services/icons';
import { Location } from 'types';
import { uniqBy } from 'lodash-es';
import { sortLocations } from 'helpers';
import { SearchIcon } from '../Icons/SearchIcon';
import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import { Autocomplete } from '../Autocomplete';

export type FormValues = {
  keyword: string;
  locations: {
    location: Location;
    active: boolean;
  }[];
  dateRange: {
    from?: Date;
    to?: Date;
  };
  tags: { name: string; active: boolean }[];
};

export const Filters = () => {
  const [location, setLocation] = useState<Location[]>();
  const [formErrors, setFormErrors] = useState<string>();

  const [isSearch, setIsSearch] = useState(false);

  const [tagValue, setTagValue] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      keyword: '',
      locations: [],
      dateRange: {
        to: moment().subtract(3, 'days').toDate(),
      },
      tags: [
        { name: 'Tag 1', active: false },
        { name: 'Deal of the Week', active: true },
        { name: 'Tag 2', active: false },
        { name: 'Highlight of the Day', active: true },
        { name: 'Tag 3', active: false },
        { name: 'Lorem Ipsum', active: false },
        { name: 'Lorem Ipsum 2', active: false },
        { name: 'Custom Tag', active: false },
      ],
    } as FormValues,

    onSubmit: (values) => {
      // Do nothing
    },
  });

  const activeLocationsCount = useMemo(
    () => formik.values.locations.filter((location) => location.active).length,
    [formik.values.locations],
  );

  const activeTagsCount = useMemo(
    () => formik.values.tags.filter((tag) => tag.active).length,
    [formik.values.tags],
  );

  const generateLocationName = (location: Location) => {
    if (location.PlaceName && location.PlaceName.endsWith(', USA')) {
      return location.PlaceName.slice(0, location.PlaceName.lastIndexOf(', USA'));
    }

    return `${location.City}, ${location.State}`;
  };

  return (
    <div className={styles.filterContainer}>
      <FilterSection title="Keywords">
        <Input
          className={styles.input}
          hideOutline
          placeholder="Search by keyword ..."
          prefix={<SearchIcon />}
          value={formik.values.keyword}
          onChange={(event) => {
            formik.setFieldValue('keyword', event.target.value);
          }}
        />
      </FilterSection>
      <div className={styles.divider} />

      <FilterSection title="Areas" count={activeLocationsCount}>
        <div className={styles.locationWrapper}>
          <Locations
            multiple
            shouldFilterPlaces
            showOnlyCityAndState
            allowStates
            getStates
            variant={Locations.SQUARE}
            searchIcon
            searchIconClassName={styles.searchIcon}
            name="locations"
            onResult={(result, preparedData: Location[]) => {
              formik.setFieldValue('locations', [
                ...formik.values.locations.map((l) => {
                  return l.active ||
                    preparedData.findIndex(
                      (location) => location.ProviderPlaceId === l.location.ProviderPlaceId,
                    ) === -1
                    ? l
                    : { ...l, active: true };
                }),
                ...preparedData
                  .filter(
                    (location) =>
                      !formik.values.locations.some(
                        (l) => l.location.ProviderPlaceId === location.ProviderPlaceId,
                      ),
                  )
                  .map((location) => ({
                    location,
                    active: true,
                  })),
              ]);
            }}
            value={[]}
            placeholder="Search by area ..."
            activeInputClassName={styles.activeInputClassName}
            placeholderClassName={styles.placeholderClass}
          />
        </div>

        {formik.values.locations.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              width: '100%',
            }}
          >
            {formik.values.locations.map(({ location, active }, index) => (
              <Checkbox
                key={location.ProviderPlaceId}
                className={styles.checkbox}
                labelClassName={styles.checkboxLabel}
                labelTextClassName={styles.checkboxLabelText}
                label={generateLocationName(location)}
                checked={active}
                onChange={() => {
                  formik.setFieldValue(
                    'locations',
                    [...formik.values.locations].map((l, i) =>
                      i === index ? { ...l, active: !l.active } : l,
                    ),
                  );
                }}
                closeIcon
                onClickClose={() => {
                  formik.setFieldValue(
                    'locations',
                    [...formik.values.locations].filter((l, i) => i !== index),
                  );
                }}
                closeIconClassName={styles.closeIcon}
                direction={Checkbox.DIRECTION_RIGHT}
              />
            ))}
          </div>
        )}
      </FilterSection>
      <div className={styles.divider} />

      <FilterSection title="Date">
        <div className={styles.dateRange}>
          <DatePicker
            format="m/d/Y"
            placeholder="From"
            inputInnerClass={styles.datePicker}
            icon
            variant={DatePicker.ROUNDED}
            allowPlugin={false}
            name="dateRange.from"
            value={formik.values.dateRange.from}
            onChange={(event) => {
              formik.setFieldValue(event.target.name, event.target.value);
            }}
            options={{
              enableTime: false,
              maxDate: formik.values.dateRange.to ?? moment().toDate(),
            }}
          />
          <div className={styles.to}>to</div>
          <DatePicker
            format="m/d/Y"
            placeholder="To"
            inputInnerClass={styles.datePicker}
            icon
            variant={DatePicker.ROUNDED}
            allowPlugin={false}
            name="dateRange.to"
            value={formik.values.dateRange.to}
            onChange={(event) => {
              formik.setFieldValue(event.target.name, event.target.value);
            }}
            options={{
              enableTime: false,
              maxDate: moment().toDate(),
            }}
          />
        </div>
      </FilterSection>
      <div className={styles.divider} />

      <FilterSection title="Tags" count={activeTagsCount}>
        <Autocomplete name={tagValue} setName={setTagValue} placeholder="Search by tags ..." />
        <div className={styles.tagWrapper}>
          {formik.values.tags.map((tag, index) => (
            <div
              className={classNames(styles.tag, {
                [styles.active]: tag.active,
              })}
              key={`${tag.name}-${index}`}
              onClick={() => {
                const nextTags = formik.values.tags.map((t) =>
                  t.name === tag.name ? { ...t, active: !t.active } : t,
                );

                return formik.setFieldValue('tags', nextTags);
              }}
            >
              <div>{tag.name}</div>
            </div>
          ))}
        </div>
      </FilterSection>
    </div>
  );
};
