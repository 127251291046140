import classNames from 'classnames';
import styles from './styles.module.scss';

export const isEmptyHTML = (element) => {
  if (!element) return true;
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = element;
  if (!htmlObject || !htmlObject.textContent) return true;
  return htmlObject.textContent.trim() === '';
};

export function isContentEmpty(html) {
  // Remove <br> tags, whitespace, and empty <div> & <p> tags
  const cleanedHTML = html
    .replace(/<br\s*\/?>/g, '') // Remove <br> tags
    .replace(/<p>\s*<\/p>/g, '') // Remove empty <p> tags
    .replace(/<div>\s*<\/div>/g, '') // Remove empty <div> tags
    .trim(); // Remove extra whitespace

  return cleanedHTML === '';
}

export const GenerateHtml = ({ content, className = '' }) => {
  return (
    <div
      className={classNames(styles.generateHtml, className)}
      dangerouslySetInnerHTML={{ __html: content !== null ? content : '' }}
    />
  );
};
