/* eslint-disable no-console */
import React, { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useResize } from 'hooks';
import { Document, Page, pdfjs } from 'react-pdf';

import { ArrowLeft, ArrowRight } from 'components/Icons';
import { previewDocumentEffect } from 'store/effects/transactions';
import Close from 'components/Messages/MessagesView/components/Close';
import Download from 'components/Messages/MessagesView/components/Download';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewModal = ({
  isOpen,
  className,
  file,
  onClose,
  showDownload = true,
  hideFileNameIfEmpty = false,
  showInCohesiveFlow = false,
}) => {
  const { screen } = useResize();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [previewFile, setPreviewFile] = useState(null);
  const dispatch = useDispatch();

  const contentRef = useRef();

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [pageNumber]);

  function onDocumentLoadSuccess({ numPages: value }) {
    setNumPages(value);
  }

  const getPreviewDocument = () => {
    if (file?.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: file.DocumentVaultUUID }, {}, (err, resp) => {
          if (err) return showErrorMessage(err);
          setPreviewFile({
            DocumentBuffer: resp,
            Filename: resp.headers['file-name'],
          });
        }),
      );
    }
  };

  useEffect(() => {
    getPreviewDocument();
  }, []);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const isFileImageType = useMemo(
    () =>
      previewFile?.DocumentBuffer?.headers?.['content-type']?.includes('image') ||
      file?.DocumentBuffer?.headers?.['content-type']?.includes('image'),
    [file, previewFile],
  );

  const isDocFileType = useMemo(
    () =>
      previewFile?.DocumentBuffer?.headers?.['content-type']?.includes('officedocument') ||
      file?.DocumentBuffer?.headers?.['content-type']?.includes('officedocument'),
    [file, previewFile],
  );

  return createPortal(
    <div
      testid="photos_view_modal"
      className={classNames(styles.modal, { [styles.isOpen]: isOpen }, className)}
    >
      <div className={styles.opacityLayer} />
      {isOpen && (
        <div className={styles.content} ref={contentRef}>
          <div className={styles.head}>
            <div className={styles.counter} />
            <div
              testid="file_name"
              className={classNames({
                [styles.fileName]: !showDownload,
                [styles.fileNameWithDownload]: showDownload,
              })}
            >
              {file?.Filename || (hideFileNameIfEmpty ? '' : 'No file name')}
            </div>
            <div className={styles.actions}>
              {showDownload && numPages && <Download document={file} />}
              <Close onClose={onClose} />
            </div>
          </div>
          <div className={styles.innerHolder}>
            <div
              className={classNames(styles.pdfWrapper, {
                [styles.cohesiveFlowPDFWrapper]: showInCohesiveFlow,
              })}
            >
              {isFileImageType ? (
                <img
                  src={`data:${
                    file?.DocumentBuffer?.headers?.['content-type'] ||
                    previewFile?.DocumentBuffer?.headers?.['content-type']
                  };base64,${arrayBufferToBase64(
                    file?.DocumentBuffer?.data || previewFile?.DocumentBuffer?.data,
                  )}`}
                  alt={file.Filename || 'Preview'}
                  className={styles.imagePreview}
                />
              ) : (
                <Document
                  loading={
                    <div className={showInCohesiveFlow ? styles.cohesiveFlowLoadingWrapper : ''}>
                      <div className={styles.blackWrapper}>
                        <div>Loading file...</div>
                      </div>
                    </div>
                  }
                  file={
                    file?.Url
                      ? file?.Url
                      : { data: file?.DocumentBuffer?.data || previewFile?.DocumentBuffer?.data }
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) => {
                    showErrorMessage('Unable to load PDF file.');
                  }}
                >
                  {!showInCohesiveFlow && numPages && (
                    <Page pageNumber={pageNumber} height={screen.width * 0.8} />
                  )}

                  {showInCohesiveFlow &&
                    numPages &&
                    [...Array(numPages).keys()].map((pageNo) => (
                      <div className={styles.documentPage} key={pageNo}>
                        <Page key={pageNo} pageNumber={pageNo + 1} height={screen.width * 0.8} />
                        {numPages > 1 && (
                          <div className={styles.blackWrapper}>
                            <div>{`Page ${pageNo + 1}/${numPages}`}</div>
                          </div>
                        )}
                      </div>
                    ))}
                </Document>
              )}

              {!showInCohesiveFlow && numPages > 1 && (
                <div className={styles.blackWrapper}>
                  <div>{`Page ${pageNumber}/${numPages}`}</div>
                </div>
              )}
            </div>
          </div>

          {!showInCohesiveFlow && numPages > 1 && (
            <>
              {pageNumber > 1 && (
                <button
                  className={styles.leftNav}
                  disabled={pageNumber === 1}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  <ArrowLeft className={styles.navIconLeft} />
                </button>
              )}

              {pageNumber < numPages && (
                <button
                  className={styles.rightNav}
                  disabled={numPages === pageNumber}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  <ArrowRight className={styles.navIcon} />
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>,
    document.body,
  );
};

PdfViewModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

PdfViewModal.defaultProps = {
  className: null,
};

export default PdfViewModal;
