import React from 'react';
import { useSelector } from 'react-redux';
import { Bookmark } from 'pages/Properties/LotiIcons';
import { TabButton } from '../../AgentHeader';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { Icons } from '../../../../../common';

import styles from './styles.module.scss';

export const SearchBtn: React.FC = () => {
  const { selectedSearch, selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);

  const TabButtonText = () => (
    <div className={styles.tabBtnText}>
      <span className={styles.text}>
        {selectedClientSavedSearch?.Id
          ? 'Client Search'
          : selectedSearch?.name || 'My Primary Search'}
      </span>
    </div>
  );

  return (
    <TabButton
      text={<TabButtonText />}
      icon={selectedClientSavedSearch?.Id ? Icons.CLIENT_SEARCH : Bookmark}
      className={styles.savedSearchBtn}
      whiteLayerClassname={styles.whiteLayerClassname}
    />
  );
};
