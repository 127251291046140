import { routes } from 'settings/navigation/routes';
import { ClarityAI, ClarityRevamped } from 'pages';
import { AGENT, CLIENT } from 'settings/constants/roles';

export default [
  {
    path: routes.clarity,
    component: ClarityRevamped,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.clarityAI,
    component: ClarityAI,
    exact: true,
    roles: [AGENT],
  },
];
