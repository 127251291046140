import { useCallback, useEffect, useState, Fragment, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNumber } from 'lodash-es';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, cloneDeep, orderBy } from 'lodash-es';
import classNames from 'classnames';
import { Checkbox } from 'antd';

import { Wrapper, Button } from 'components';
import { SearchInput } from 'components-antd';

import { ArrowRight } from 'components/Icons';
import {
  getFilteredGroupedTaskTemplatesSelector,
  getTaskTemplatesSelector,
  getGroupedTaskTemplatesByNameSelector,
  getTransactionTemplatesSelector,
} from 'store/selectors/templates';

import {
  getAllTaskTemplatesEffect,
  getAllTransactionTemplatesEffect,
} from 'store/effects/templates';

import { setTransactionTaskParamsEffect } from 'store/effects/transactions';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { getUserDataSelector } from 'store/selectors/user';
import { getTransactionSelector } from 'store/selectors/transaction';
import NoTemplatesFound from './NoTemplates';
import { TaskFilter } from './TaskFilter';
import { getAssignees } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { taskTemplateDateInitials } from 'app-constants/enums/taskTemplateDateInitials';
import { getDateOnly } from 'helpers';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

const TaskTemplates = (props) => {
  const {
    className,
    isPreForm,
    onSubmit,
    isTransactionTask,
    allowMultiSelect,
    selectedTemplates,
    setSelectedTemplates,
    selectedGroupName,
    isExpandGroup,
    setIsExpandGroup,
    setSelectedGroupName,
  } = props;
  const taskTemplates = useSelector(getFilteredGroupedTaskTemplatesSelector);
  const transactionTemplates = useSelector(getGroupedTaskTemplatesByNameSelector);
  const templates = { ...taskTemplates, ...(!isTransactionTask ? transactionTemplates : {}) };

  const { isPending, isIdle } = useSelector(getTaskTemplatesSelector);
  const { isIdle: isTransactionTemplatesIdle, isPending: isTransactionTemplatesPending } =
    useSelector(getTransactionTemplatesSelector);
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const isProject = useIsProjectRoute();

  const {
    transaction: {
      Participants,
      EffectiveDate: transactionContractDate,
      ClosingDate: transactionClosingDate,
      ListingExpireDate: transactionExpiryDate,
    },
  } = useSelector(getTransactionSelector);
  const participants = isPreForm ? preForm?.participants || [] : Participants || [];
  const user = useSelector(getUserDataSelector);
  const allParticipants = isPreForm ? [...participants, user] : participants;
  const participantOptions = allParticipants.filter((p) => p?.Id || p?.id);
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const [multiSelect, setMultiSelect] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const scrollContainerRef = useRef();

  const selectedLength = Object.keys(selected)?.length;

  useEffect(() => {
    dispatch(getAllTaskTemplatesEffect({}, isIdle ? { silent: true } : null));
    if (!isTransactionTask)
      dispatch(
        getAllTransactionTemplatesEffect(
          {
            isProject,
          },
          isTransactionTemplatesIdle ? { silent: true } : null,
        ),
      );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isExpandGroup) setMultiSelect(false);
    if (isExpandGroup && scrollContainerRef.current)
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isExpandGroup]);

  useEffect(() => {
    if (
      allowMultiSelect &&
      !multiSelect &&
      selectedTemplates &&
      Object.keys(selectedTemplates)?.length &&
      !selectedLength
    ) {
      setSelected(cloneDeep(selectedTemplates));
      setMultiSelect(true);
    }
  }, [selectedTemplates]);

  useEffect(() => {
    if (!multiSelect && selectedLength) {
      updatedSelected({});
    }
  }, [multiSelect]);

  const isSelected = (templateId) => !!selected[templateId];

  const toggleTemplate = (template, data) => {
    const items = cloneDeep(data);
    if (items[template.Id]) delete items[template.Id];
    else items[template.Id] = template;

    updatedSelected({ ...items });
  };

  const updatedSelected = (items) => {
    setSelected({ ...items });

    if (setSelectedTemplates) {
      setSelectedTemplates({ ...items });
    }
  };

  const multiSelectMode = !!(allowMultiSelect && multiSelect);

  const getTemplateDueDate = (dueDate, operator, initial, isBusinessDays = false) => {
    if (dueDate !== undefined && operator && initial) {
      if (initial === taskTemplateDateInitials.ClosingDate && transactionClosingDate) {
        if (operator === '+') {
          return moment(transactionClosingDate)
            .add(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        } else {
          return moment(transactionClosingDate)
            .subtract(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        }
      } else if (initial === taskTemplateDateInitials.ContractDate && transactionContractDate) {
        if (operator === '+') {
          return moment(transactionContractDate)
            .add(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        } else {
          return moment(transactionContractDate)
            .subtract(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        }
      } else if (
        initial === taskTemplateDateInitials.ListingAgreementExpiryDate &&
        transactionExpiryDate
      ) {
        if (operator === '+') {
          return moment(transactionExpiryDate)
            .add(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        } else {
          return moment(transactionExpiryDate)
            .subtract(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        }
      } else if (
        initial === taskTemplateDateInitials.AssigningDate ||
        initial === taskTemplateDateInitials.Today
      ) {
        if (operator === '+') {
          return moment()
            .add(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        } else {
          return moment()
            .subtract(dueDate + (isBusinessDays ? 2 : 0), 'days')
            .toDate();
        }
      } else {
        return moment().toDate();
      }
    } else if (multiSelectMode) {
      return moment().add(2, 'days').toDate();
    }

    return null;
  };

  const formattedTask = (template) => {
    const dueDate = get(template, 'DueDate');
    const checklists = (get(template, 'Checklists') || []).map(({ Name, Required, Id }) => ({
      Name,
      Required,
      Id,
    }));

    if (isTransactionTask) {
      return {
        Name: get(template, 'Name') || '',
        DueDate: dueDate,
        Initial: get(template, 'Initial'),
        Operator: get(template, 'Operator'),
        ControlOperatorOffset: get(template, 'ControlOperatorOffset'),
        Categories: get(template, 'Categories') || [],
        Description: get(template, 'Description') || '',
        Checklists: orderBy(checklists, ['Id'], ['asc']),
        Links: get(template, 'Links') || [],
        Documents: get(template, 'Documents') || [],
        AssigneeRoles: get(template, 'AssigneeRoles'),
        CcRoles: get(template, 'CcRoles'),
        People: get(template, 'People'),
        Teams: get(template, 'Teams'),
        Locations: get(template, 'Locations'),
        EmailTemplate: get(template, 'EmailTemplate'),
      };
    } else {
      return {
        Title: get(template, 'Name') || '',
        DueDate: getDateOnly(
          getTemplateDueDate(
            dueDate,
            get(template, 'Operator'),
            get(template, 'Initial'),
            get(template, 'IsBusinessDays'),
          ),
        ),
        Categories: get(template, 'Categories') ? get(template, 'Categories') : [],
        Description: get(template, 'Description') || '',
        Checklists: orderBy(checklists, ['Id'], ['asc']),
        Links: get(template, 'Links') || [],
        Documents: get(template, 'Documents') || [],
        AssigneeList: getAssignees(template, 'AssigneeRoles', participantOptions),
        CcList: getAssignees(template, 'CcRoles', participantOptions),
        TaskTemplateInitial: get(template, 'Initial'),
        TaskTemplateOperator: get(template, 'Operator'),
        TaskTemplateDateOffset: dueDate,
        TaskTemplateInitialOperatorOffset:
          get(template, 'Initial') + get(template, 'Operator') + dueDate,
        EmailTemplate: get(template, 'EmailTemplate'),
        Hours: isNumber(template?.Hours) ? template?.Hours : null,
        Minutes: isNumber(template?.Minutes) ? template?.Minutes : null,
        Timezone: get(template, 'Timezone'),
        IsBusinessDays: get(template, 'IsBusinessDays'),
        RepeatOption: get(template, 'RepeatOption'),
        CustomRepeatOption: get(template, 'CustomRepeatOption'),
        RepeatCount: get(template, 'RepeatCount'),
        RepeatOn: get(template, 'RepeatOn'),
        RepeatOccurrence: get(template, 'RepeatOccurrence'),
        RepeatUntilDate: get(template, 'RepeatUntilDate'),
        EndsOnClosing: get(template, 'EndsOnClosing'),
        EndsOnListingExpiration: get(template, 'EndsOnListingExpiration'),
      };
    }
  };

  const onTemplateSelect = useCallback(
    (template, data) => {
      if (isPreForm || multiSelectMode) toggleTemplate(template, data);
      else {
        dispatch(
          setTransactionTaskParamsEffect({
            formValues: formattedTask(template),
            isTaskForm: true,
          }),
        );
        onSubmit();
      }
    },
    [dispatch, multiSelectMode],
  );

  const handleMultiTaskSubmit = () => {
    const formValues = Object.values(selected).map((item) => formattedTask(item));
    if (Object.values(selected)?.length && onSubmit) {
      onSubmit({
        formValues,
        assignTo: true,
      });
    }
  };

  const onClickGroup = (groupName) => {
    setIsExpandGroup(true);
    setSelectedGroupName(groupName);
  };

  const filteredTemplates = useMemo(() => {
    const search = searchQuery.toLowerCase();
    const result = {};

    Object.entries(templates).forEach(([category, items]) => {
      if (category?.toLowerCase()?.includes(search)) {
        result[category] = items;
      }
    });

    return result;
  }, [searchQuery]);

  return (
    <Wrapper
      isPending={isPending || isTransactionTemplatesPending}
      className={classNames(styles.wrapper, className)}
    >
      {isExpandGroup && (isTransactionTask || allowMultiSelect) && (
        <TaskFilter
          {...(allowMultiSelect
            ? {
                allowMultiSelect,
                updateMultiSelect: () => {
                  setMultiSelect(!multiSelect);
                },
                multiSelectActive: multiSelect,
              }
            : {})}
        />
      )}
      {!isExpandGroup && (
        <div className={styles.searchWrapper}>
          <SearchInput
            value={searchQuery}
            className={classNames(styles.searchInput)}
            onChange={(e) => setSearchQuery(e.target.value)}
            maxLength={100}
          />
        </div>
      )}
      {Object.keys(filteredTemplates).length ? (
        <>
          <div
            className={classNames(styles.templatesContainer, {
              [styles.templatesHeight]: isExpandGroup,
            })}
            ref={scrollContainerRef}
          >
            {!isExpandGroup ? (
              Object.keys(filteredTemplates).map((groupName, idx) =>
                filteredTemplates?.[groupName]?.length ? (
                  <Fragment key={groupName}>
                    <div
                      testid="template_group"
                      className={styles.group}
                      onClick={() => onClickGroup(groupName)}
                    >
                      <div className={styles.groupContainer}>
                        <h3 testid="group_name" className={styles.category}>
                          {groupName}
                          <span className={styles.counter}>
                            ({filteredTemplates?.[groupName]?.length})
                          </span>
                        </h3>
                        <ArrowRight className={styles.arrowIcon} />
                      </div>
                    </div>
                    {idx < Object.keys(filteredTemplates)?.length - 1 && (
                      <div className={styles.divider} />
                    )}
                  </Fragment>
                ) : null,
              )
            ) : (
              <div className={styles.groupTemplates}>
                {templates?.[selectedGroupName]?.map((template, idx) => (
                  <Fragment>
                    <div
                      key={template?.Id}
                      className={styles.template}
                      onClick={() => onTemplateSelect(template, selected)}
                      testid="template_item"
                    >
                      <div>
                        <div>
                          <p testid="template_name" className={styles.templateName}>
                            {template?.Name}
                          </p>
                          <div className={styles.information}>
                            <span className={styles.label}>Due:&nbsp;</span>
                            <span className={styles.value}>{template?.ControlOperatorOffset}</span>
                            {template?.AssigneeRoles?.length && (
                              <>
                                <div className={styles.seperator} />
                                <span className={styles.label}>Assign to:&nbsp;</span>
                                <span className={styles.value}>
                                  {template?.AssigneeRoles?.map(
                                    (item) => TransactionUserRoleMap[item] || item,
                                  )?.join(', ')}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {!isPreForm && !multiSelectMode && (
                        <ArrowRight className={styles.arrowIcon} />
                      )}
                      {multiSelectMode && (
                        <Checkbox
                          className={classNames(
                            styles.checkbox,
                            styles.multiSelectCheckbox,
                            'mosaikCheckbox',
                          )}
                          checked={isSelected(template.Id)}
                        />
                      )}
                      {isPreForm && (
                        <Checkbox
                          className={classNames(styles.checkbox, 'mosaikCheckbox')}
                          checked={isSelected(template.Id)}
                          onClick={() => onTemplateSelect(template, selected)}
                        />
                      )}
                    </div>
                    {idx < templates?.[selectedGroupName]?.length - 1 && (
                      <div className={styles.divider} />
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
          {isPreForm ? (
            <Button
              title={`Add Tasks`}
              onClick={() => onSubmit(Object.values(selected).map((item) => formattedTask(item)))}
              className={styles.submitBtn}
              titleClassName={styles.buttonTitle}
              disabled={!Object.keys(selected).length}
            />
          ) : null}
          {multiSelectMode ? (
            <Button
              title={`Assign ${selectedLength ? ' ' + selectedLength + ' ' : ' '}${
                selectedLength > 1 ? 'Tasks' : 'Task'
              }`}
              onClick={handleMultiTaskSubmit}
              className={classNames(styles.submitBtn, styles.multiBtn)}
              titleClassName={styles.buttonTitle}
              disabled={!Object.keys(selected).length}
            />
          ) : null}
        </>
      ) : (
        <NoTemplatesFound className={styles.noTemplates} templatesName="task" />
      )}
    </Wrapper>
  );
};

TaskTemplates.propTypes = {
  className: PropTypes.string,
  isPreForm: PropTypes.bool,
  isTransactionTask: PropTypes.bool,
  onSubmit: PropTypes.func,
  allowMultiSelect: PropTypes.bool,
  selectedTemplates: PropTypes.object,
  setSelectedTemplates: PropTypes.func,
  isExpandGroup: PropTypes.bool,
  setIsExpandGroup: PropTypes.func,
  selectedGroupName: PropTypes.string | null,
  setSelectedGroupName: PropTypes.func,
};

TaskTemplates.defaultProps = {
  className: '',
  isPreForm: false,
  isTransactionTask: false,
  onSubmit: () => {},
  allowMultiSelect: false,
  selectedTemplates: {},
  setSelectedTemplates: undefined,
};

export default TaskTemplates;
