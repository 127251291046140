import { useEffect, useState } from 'react';
import { Tooltip } from 'components-antd';

import styles from './styles.module.scss';
import classNames from 'classnames';

export const InfoTooltip = (props) => {
  const {
    text,
    getPopupContainer,
    tooltipOpen,
    setOpenChange,
    autoClose = true,
    trigger = 'hover',
    className,
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (tooltipOpen) {
      !open && setOpen(tooltipOpen);

      if (autoClose) {
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
    }
  }, [tooltipOpen]);

  if (!text) return <>{props.children}</>;

  return (
    <div
      className={classNames(styles.InfoTooltipInfo, className)}
      onMouseEnter={() => trigger === 'hover' && setOpen(true)}
      onMouseLeave={() => {
        setOpen(false);
        setOpenChange?.();
      }}
    >
      <Tooltip
        title={text}
        placement={'top'}
        open={open}
        overlayClassName={styles.InfoTooltip}
        getPopupContainer={getPopupContainer}
        arrowPointAtCenter
        color="#262626"
      >
        {props.children}
      </Tooltip>
    </div>
  );
};
