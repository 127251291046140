import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper as PendingWrapper, SearchFieldWithDebounce } from 'components';
import { Tabs, Button } from 'components-antd';
import { Repeat } from 'components/Icons';

import Header from 'pages/Dashboard/components/Header';
import Grid from 'pages/Dashboard/components/Grid';
import { getMlsDashboardStatsEffect } from 'store/effects/adminPanel/mlsDashboard';
import { getMlsStatsDashboardSelector } from 'store/selectors/adminPanel';
import { gridColumns } from './config';
import { MLSStatsModal } from './MLSStatsModal';

import styles from './styles.module.scss';
import { cloneDeep } from 'lodash-es';

const MlsDashboard = (props) => {
  const dispatch = useDispatch();
  const [isErrorsOnly, setIsErrorsOnly] = useState(false);
  const [localData, setLocalData] = useState({
    oldPipeline: [],
    newPipeline: [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [mlsSelected, setMlsSelected] = useState(null);

  const { data, isIdle, isPending } = useSelector(getMlsStatsDashboardSelector);
  const { oldPipeline, newPipeline } = data || {};
  const { className } = props;

  useEffect(() => {
    if (isIdle) {
      dispatch(getMlsDashboardStatsEffect({}));
    } else {
      dispatch(getMlsDashboardStatsEffect({}, { silent: true }));
    }
  }, []);

  useEffect(() => {
    if (data) {
      setLocalData({
        oldPipeline: oldPipeline || [],
        newPipeline: newPipeline || [],
      });
    }
  }, [data]);

  const searchPipelines = (pipelines, searchString) => {
    if (!searchString) return pipelines;

    return pipelines?.filter((item) => {
      return (
        item?.DataProvider?.toLowerCase().includes(searchString?.toLowerCase()) ||
        item?.MLSCode?.toLowerCase().includes(searchString?.toLowerCase())
      );
    });
  };

  const handleSearch = (searchString) => {
    const newResults = searchPipelines(cloneDeep(data?.newPipeline), searchString);
    const oldResults = searchPipelines(cloneDeep(data?.oldPipeline), searchString);

    setLocalData({
      newPipeline: searchString !== '' ? newResults : data?.newPipeline,
      oldPipeline: searchString !== '' ? oldResults : data?.oldPipeline,
    });
  };

  const handleErrorFilter = () => {
    if (isErrorsOnly) {
      setLocalData({
        newPipeline,
        oldPipeline,
      });
    } else {
      // Filter oldPipeline
      const filteredOldPipeline = oldPipeline.filter((item) => {
        const jobs = [item?.deltaJob?.[0] || [], item?.validationJobs?.[0] || []];
        const hasRedJob = jobs.some((job) => job?.lastError || job?.attempts > 1);
        let hasRedOpenHouse = false;
        let hasRedValidationStat =
          item?.validationStats?.LastValidationOrphanWithdrawn > 99 ||
          item?.validationStats?.LastValidationActiveMismatched > 99 ||
          item?.validationStats?.LastValidationOrphanTotal >= 500;

        if (item?.openHouses?.length === 1) {
          hasRedOpenHouse = item?.openHouses?.some((openHouse) => {
            const job = openHouse?.openHouseJobs?.[0];
            return job?.lastError || job?.attempts > 1;
          });
        }

        return hasRedJob || hasRedOpenHouse || hasRedValidationStat;
      });

      // Filter newPipeline
      const filteredNewPipeline = newPipeline.filter((item) => {
        const deltaJobs = [
          item.deltaQueueJobs?.[0] || [],
          item.deltaProcessJobs?.[0] || [],
          item?.validationJobs?.[0] || [],
        ];

        const hasRedJob = deltaJobs.some((job) => {
          return job?.lastError || job?.attempts > 1;
        });

        const hasRedOpenHouse = item.openHouses?.some((openHouse) => {
          const job = openHouse?.openHouseJobs?.[0];
          return job?.lastError || job?.attempts > 1;
        });

        let hasRedValidationStat =
          item?.validationStats?.LastValidationOrphanWithdrawn > 99 ||
          item?.validationStats?.LastValidationActiveMismatched > 99 ||
          item?.validationStats?.LastValidationOrphanTotal >= 500;

        return hasRedJob || hasRedOpenHouse || hasRedValidationStat;
      });

      setLocalData({
        newPipeline: filteredNewPipeline,
        oldPipeline: filteredOldPipeline,
      });
    }
    setIsErrorsOnly((value) => !value);
  };

  const tabItems = [
    {
      key: '1',
      label: 'New Pipeline',
      children: (
        <div className={styles.gridContainer}>
          <Grid
            items={localData?.newPipeline || []}
            columns={gridColumns(false)}
            keyProp="Id"
            hidePagination
            onRowClick={(x, item) => {
              setMlsSelected(item);
              setModalOpen(true);
            }}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: 'Old Pipeline',
      children: (
        <div className={styles.gridContainer}>
          <Grid
            items={localData?.oldPipeline || []}
            columns={gridColumns(true)}
            keyProp="Id"
            hidePagination
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Header title="MLS Dashboard" />
      <div className={styles.subHeader}>
        <div className={styles.searchWrapper}>
          <SearchFieldWithDebounce
            className={styles.inputWrap}
            classNameInput={styles.searchInput}
            iconClassName={styles.icon}
            resetAction={() => handleSearch('')}
            sendAction={handleSearch}
            isLoading={false}
            placeholder="Search"
            testid="search"
            isTransactionAggregate={true}
            isExpandedVersion
            doNotDispatch
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            variant="red-outlined"
            className={classNames(styles.errorsFilter, { [styles.active]: isErrorsOnly })}
            onClick={handleErrorFilter}
            disabled={isPending}
          >
            Error(s) Only
          </Button>
          <Button
            variant="secondary"
            className={styles.refetchBtn}
            onClick={() => dispatch(getMlsDashboardStatsEffect({}))}
            disabled={isPending}
          >
            <Repeat /> Refetch
          </Button>
        </div>
      </div>
      <PendingWrapper className={classNames(styles.wrapper, className)} isPending={isPending}>
        <Tabs defaultActiveKey="1" items={tabItems} className={styles.mlsDashboardTabs} />
      </PendingWrapper>
      <MLSStatsModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setMlsSelected(null);
        }}
        mls={mlsSelected}
      />
    </>
  );
};

MlsDashboard.propTypes = {
  className: PropTypes.string,
};

MlsDashboard.defaultProps = {
  className: '',
};

export default MlsDashboard;
