import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Users, Edit, Dots, Delete, OpenBook } from 'components/Icons';
import { ConfirmModal, GenerateHtml, Options } from 'components';

import { deleteEmailTemplate } from 'api/templates/email';
import { showErrorMessage } from 'helpers';
import { useSelector } from 'react-redux';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';
import { EmailTemplateModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModalForm/EmailTemplateModal';

import styles from './styles.module.scss';

export const TemplateItem = ({
  emailTemplateId,
  subject,
  body,
  createdBy,
  isPublishedToTeam = false,
  refetchEmailTemplates,
}) => {
  const history = useHistory();
  const userRole = useSelector(getAgentTeamRoleSelector);
  const loggedInUserId = useSelector(getUserId);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [viewEmailTemplate, setViewEmailTemplate] = useState(false);

  // Only Team Admin/Owner or Uploader can modify the Email Template.
  const canModify = useMemo(
    () => userRole === TEAM_ADMIN || userRole === TEAM_OWNER || createdBy === loggedInUserId,
    [],
  );

  const TEMPLATE_OPTIONS = [
    {
      label: 'Preview',
      icon: <OpenBook stroke="#FF576D" className={styles.optionIcon} />,
      onClick: () => setViewEmailTemplate(true),
    },
    ...(canModify
      ? [
          {
            label: 'Edit',
            icon: <Edit stroke="#FF576D" className={styles.optionIcon} />,
            onClick: () =>
              history.push(`/templates/email/${emailTemplateId}/edit`, {
                body,
                subject,
                isPublishedToTeam,
              }),
          },
        ]
      : []),
    ...(canModify
      ? [
          {
            label: 'Remove',
            icon: <Delete color="#FF576D" className={styles.optionIcon} />,
            onClick: () => setShowDeleteDialog(true),
          },
        ]
      : []),
  ];

  const onCloseModal = () => setShowDeleteDialog(false);

  const onSubmitConfirmDelete = async () => {
    setRequestPending(true);

    try {
      await deleteEmailTemplate(emailTemplateId);
    } catch (e) {
      showErrorMessage(e);
    } finally {
      setRequestPending(false);
      refetchEmailTemplates();
    }
  };

  return (
    <div className={styles.templateItem}>
      <div className={styles.subjectHeader}>
        <p className={styles.subjectText}>{subject}</p>
        <Options
          title={<Dots className={styles.dotsIcon} />}
          placement="leftBottom"
          options={TEMPLATE_OPTIONS}
          popoverClassName={styles.templateOptions}
          titleClassName={styles.dotsTitle}
        />
      </div>
      <p className={styles.bodyText}>
        <GenerateHtml content={body} />
      </p>
      {isPublishedToTeam && (
        <div className={styles.publishedTeam}>
          <Users className={styles.icon} color="#747475" />
          <span>Published to Team</span>
        </div>
      )}
      <ConfirmModal
        title="Are you sure you want to delete the email template?"
        isOpen={showDeleteDialog}
        onClose={onCloseModal}
        onSubmit={onSubmitConfirmDelete}
        isPending={requestPending}
        submitButtonTitle="Delete"
        cancelButtonTitle="Cancel"
        icon
      />
      <EmailTemplateModal
        open={viewEmailTemplate}
        onClose={() => setViewEmailTemplate(false)}
        subjectText={subject}
        bodyText={body}
      />
    </div>
  );
};
