import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';
import { Ratings } from 'components';
import { useSelector } from 'react-redux';
import { getListingDetailMapData } from 'store/selectors/listingDetail';
import LocationCategoryIcons from './LocationCategoryIcons';
import haversineDistance from 'helpers/haversineDistance';

import styles from './styles.module.scss';

const ListModeView = ({ data }) => {
  const { center } = useSelector(getListingDetailMapData);

  const getDistance = (distance, lat, lng) => {
    if ((!distance || distance == '-') && center && center.lat && center.lng && lat && lng) {
      return haversineDistance(center.lat, center.lng, lat, lng) + ' mi';
    }
    return '-';
  };

  const sortedData = (data) =>
    data.sort((a, b) => {
      const distanceA = getDistance(
        a.distance,
        a.geometry.coordinates.lat,
        a.geometry.coordinates.lng,
      );
      const distanceB = getDistance(
        b.distance,
        b.geometry.coordinates.lat,
        b.geometry.coordinates.lng,
      );

      if (distanceA === '-') return 1;
      if (distanceB === '-') return -1;

      return parseFloat(distanceA) - parseFloat(distanceB);
    });

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  };

  return (
    <div className={styles.listMode}>
      {data?.length ? (
        <>
          <div className={styles.titles}>
            <p>Locations</p>
            <p>Distance</p>
          </div>
          <div className={styles.list}>
            {map(
              sortedData(data),
              ({
                address,
                info,
                rating,
                title,
                distance,
                image,
                id,
                category,
                voteCount,
                geometry,
              }) => (
                <div key={id} className={styles.listModeItem}>
                  <div className={styles.listModeItemIcon}>
                    <LocationCategoryIcons category={category} />
                  </div>
                  <div className={styles.listModeItemContent}>
                    <div className={styles.listModeItemContentTop}>
                      <p className={styles.category}>{capitalizeWords(title)}</p>
                      <p className={styles.distance}>
                        {getDistance(
                          distance,
                          geometry?.coordinates?.lat,
                          geometry?.coordinates?.lng,
                        )}
                      </p>
                    </div>
                    <div className={styles.listModeItemContentMiddle}>
                      <p className={styles.rating}>{rating}</p>
                      <Ratings
                        rating={rating}
                        stylesClassName={styles.stars}
                        colorWithHash={'#FB913A'}
                      />
                      <p className={styles.count}>{'(' + voteCount?.toLocaleString() + ')'}</p>
                    </div>
                    <div className={styles.listModeItemContentLast}>
                      {category !== 'Parks & Nature' && (
                        <>
                          <p className={styles.price}>$</p>
                          <p className={styles.dot}>&#x2022;</p>
                        </>
                      )}
                      <p className={styles.address}>{address}</p>
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        </>
      ) : (
        <div className={styles.noData}>No results</div>
      )}
    </div>
  );
};

ListModeView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      id: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      distance: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      rating: PropTypes.number,
    }),
  ),
};

ListModeView.defaultProps = {
  data: null,
};

export default ListModeView;
