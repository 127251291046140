import { useState } from 'react';
import { Switch } from 'components-antd';
import styles from './styles.module.scss';
import { Edit } from 'components/Icons';
import Permission from 'components/Icons/Permission';
import { updateKitById } from 'api/kits';
import classNames from 'classnames';
import getGeoLocationsAsString from 'helpers/getGeoLocationsAsString';
import { Tooltip } from 'antd';
import { KitIcon } from 'components/Icons/KitIcon';
import { getKitFrequencyString } from 'pages/Dashboard/Kits/utils';

type KitDataRowPropsType = {
  data;
  reminderTemplateClickHandler: Function;
  editKitClickHandler: Function;
  permitClickHandler: Function;
};

function KitDataRow(props: KitDataRowPropsType) {
  const [enabled, setEnabled] = useState<boolean>(props.data.Published);

  const getEligibleFrequencies = () => {
    if (props.data?.EligibleFrequencies?.length) {
      let frequencies = props.data?.EligibleFrequencies.map((item) => {
        let frequencyString = getKitFrequencyString(item);
        return frequencyString;
      });
      return frequencies.join(',');
    } else return '-';
  };

  const switchHandler = async (v: boolean) => {
    await updateKitById(props.data.Id, { Published: v });
  };

  return (
    <tr className={styles.kitsRowData}>
      <td>
        <div className={styles.kitName}>
          <div>{props.data.Name}</div>
          <div className={styles.kitOptions}>
            <KitIcon
              onClick={() => {
                props.reminderTemplateClickHandler(props.data.Id);
              }}
              color="#000"
              className={styles.kitEdit}
              withCircle={false}
            />
            <Edit
              onClick={() => {
                props.editKitClickHandler(props.data.Id);
              }}
              stroke="#000"
              className={styles.kitEdit}
            />
            <Permission
              onClick={() => {
                props.permitClickHandler(props.data.Id);
              }}
              className={styles.kitPermission}
            />
          </div>
        </div>
      </td>
      <td>
        <div className={styles.text}>{props.data.Category}</div>
      </td>
      <td>
        <div className={styles.options}>
          <p>{getEligibleFrequencies()}</p>
        </div>
      </td>
      <td>
        <div className={classNames(styles.text, styles.notes)}>
          {props?.data?.InternalName ? props?.data?.InternalName : '-'}
        </div>
      </td>
      <td>
        <div className={styles.text}>
          {props.data.EligiblePropertyTypes.length
            ? props.data.EligiblePropertyTypes.replaceAll('{', '')
                .replaceAll('}', '')
                .replaceAll('"', '')
            : '-'}
        </div>
      </td>
      <td>
        <div className={classNames(styles.text, styles.locations)}>
          {getGeoLocationsAsString(props.data?.KitsLocation, true)}
        </div>
      </td>
      <td>
        <div className={styles.switchCell}>
          <Switch
            className={styles.switch}
            checked={enabled}
            defaultChecked={props.data.Enable}
            onChange={(v) => {
              setEnabled(v);
              switchHandler(v);
            }}
          />
        </div>
      </td>
    </tr>
  );
}

export default KitDataRow;
