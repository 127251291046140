import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Wrapper } from 'components';
import { PageWrapper } from 'components-antd';
import { Icons } from 'pages/Vault/Icons';
import { routes } from 'settings/navigation/routes';
import {
  AvatarColumn,
  DateTimeColumn,
  DocumentNameColumn,
  DocumentOptions,
  DocumentsContent,
  EmptyState,
  LocalHeader,
} from '../components';
import { DocsContentView } from '../constants';

import { PENDING, READY } from 'settings/constants/apiState';
import { getMiscDocumentsEffect, getVaultDocumentsMetaEffect } from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { getMetaVaultDocuments, getMiscVaultDocumentsList } from 'store/selectors/vault';

import styles from './styles.module.scss';

export const Miscellaneous = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchContext = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;
  const { data, isPending } = useSelector(getMiscVaultDocumentsList);
  const { data: metaData } = useSelector(getMetaVaultDocuments);
  const { miscDocuments } = data || {};
  const [viewType, setViewType] = useState(DocsContentView.GRID_VIEW);

  const fetchMiscDocuments = () => {
    dispatch(
      getMiscDocumentsEffect({
        id: selectedAgentId,
      }),
    );
  };

  useEffect(() => {
    if (contextsState.state === READY && selectedAgentId) {
      fetchMiscDocuments();
      if (!metaData) {
        dispatch(
          getVaultDocumentsMetaEffect({
            id: selectedAgentId,
          }),
        );
      }
    }
  }, [contextsState.state, selectedAgentId]);

  const handleBackBtn = () => history.push(routes.vault);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_, { Title, Filename, FormName }) => {
        const name = Title || Filename || FormName;
        return <DocumentNameColumn name={name} docIconType={Icons.DOCUMENT_1} />;
      },
      sorter: (a, b) => {
        const aName = a?.Title || a?.Filename || a?.FormName;
        const bName = b?.Title || b?.Filename || b?.FormName;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      render: (_, { UploaderFirstName, UploaderLastName, AvatarUrl }) => {
        return (
          <AvatarColumn name={`${UploaderFirstName} ${UploaderLastName}`} imgSrc={AvatarUrl} />
        );
      },
      sorter: (a, b) => {
        const aName = `${a?.UploaderFirstName} ${a?.UploaderLastName}`;
        const bName = `${b?.UploaderFirstName} ${b?.UploaderLastName}`;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Uploaded',
      key: 'uploaded',
      dataIndex: 'uploaded',
      render: (_, { CreatedDate }) => {
        return <DateTimeColumn date={CreatedDate} />;
      },
      sorter: (a, b) => a?.CreatedDate?.localeCompare(b?.CreatedDate),
    },
    {
      title: '',
      key: '',
      render: (_, file) => {
        return (
          <div className={styles.optionsColumn}>
            <DocumentOptions
              className={styles.bottomOffset}
              file={file}
              Property={null}
              refetch={fetchMiscDocuments}
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageWrapper bgGray mainPageContentStyle={styles.pagecontentStyles} version={2}>
      <LocalHeader
        title="Miscellaneous"
        handleBackBtn={handleBackBtn}
        viewType={viewType}
        setViewType={setViewType}
        uploadCallback={fetchMiscDocuments}
        isMisc
      />
      <div className={styles.preApprovalsPage}>
        <Wrapper isPending={isPending || contextsState?.state === PENDING}>
          {miscDocuments?.length ? (
            <DocumentsContent
              title="Total"
              totalCount={miscDocuments?.length}
              columns={columns}
              data={miscDocuments}
              viewType={viewType}
              refetch={fetchMiscDocuments}
              isMisc
            />
          ) : (
            <EmptyState />
          )}
        </Wrapper>
      </div>
    </PageWrapper>
  );
};
