import PropTypes from 'prop-types';

const Pin = ({ className, color = '#515151' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <ellipse cx="10" cy="9.16699" rx="2.5" ry="2.5" stroke={color} strokeWidth="2" />
    <path
      d="M16.6663 9.16667C16.6663 12.8846 12.9802 16.3721 11.0899 17.9162C10.4484 18.4401 9.55094 18.4401 8.90949 17.9162C7.01913 16.3721 3.33301 12.8846 3.33301 9.16667C3.33301 5.48477 6.31778 2.5 9.99967 2.5C13.6816 2.5 16.6663 5.48477 16.6663 9.16667Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

Pin.propTypes = {
  className: PropTypes.string,
};

Pin.defaultProps = {
  className: '',
};

export default Pin;
