import { useDispatch, useSelector } from 'react-redux';

import { Icons } from '../../../AgentDashboard/Icons';
import { Avatar, Modal, Wrapper } from 'components';
import { LocationService } from 'services';
import {
  appOpenModalEffect,
  getClientCountsEffect,
  getClientFavoritesPropsEffect,
  getClientHighlightsEffect,
  sortFeedEffect,
} from 'store/effects';
import { getModalSelector } from 'store/selectors/app';
import { getClientCountsSelectorV3, getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import {
  setAgentClientModeAction,
  setIsSimpleHeaderTitleVisibleAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { ClientSearches } from './ClientSearches';

import styles from './styles.module.scss';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { SavedSearches } from './SavedSearches';
import { getInitials } from 'utils';
import { ArrowLeftFull } from 'components/Icons';
import { getClientsSearchInstancesSelector } from 'store/selectors/clientSearches';
import classNames from 'classnames';
import { useEffect } from 'react';
import { routes } from 'settings/navigation/routes';
import { useHistory } from 'react-router-dom';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';

const ClientView = ({ clientSearchQuery, setShowSearch }) => {
  const history = useHistory();
  const { selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);
  const id = selectedClientSavedSearch?.Id;
  const dispatch = useDispatch();
  const { FirstName, LastName, AvatarUrl } = selectedClientSavedSearch;
  const { counts } = useSelector(getClientCountsSelectorV3);
  const getAgentSearchInstance = useAgentSearchInstance();

  const handleCommentsClick = () => {
    dispatch(
      setAgentClientModeAction({
        agentClientMode: { value: true, user: selectedClientSavedSearch },
      }),
    );
    dispatch(
      setIsSimpleHeaderTitleVisibleAction({
        isSimpleHeaderTitleVisible: { value: true, text: 'Comments' },
      }),
    );
    getAgentSearchInstance({}, undefined, selectedClientSavedSearch, undefined, true, 'All');
    dispatch(appOpenModalEffect({ id: null, open: false }));
    history.push(routes.feed);
  };

  const handleFavoritesClick = () => {
    dispatch(sortFeedEffect({ fields: [] }));
    dispatch(
      setAgentClientModeAction({
        agentClientMode: { value: true, user: selectedClientSavedSearch },
      }),
    );
    dispatch(
      setIsSimpleHeaderTitleVisibleAction({
        isSimpleHeaderTitleVisible: { value: true, text: 'Favorites' },
      }),
    );
    dispatch(getClientFavoritesPropsEffect({ userId: id }));
    dispatch(appOpenModalEffect({ id: null, open: false }));
    history.push(routes.feed);
  };

  const handleHighlightsClick = () => {
    dispatch(
      setAgentClientModeAction({
        agentClientMode: { value: true, user: selectedClientSavedSearch },
      }),
    );
    dispatch(
      setIsSimpleHeaderTitleVisibleAction({
        isSimpleHeaderTitleVisible: { value: true, text: 'Highlights' },
      }),
    );
    dispatch(getClientHighlightsEffect({ userId: id }));
    dispatch(appOpenModalEffect({ id: null, open: false }));
    history.push(routes.feed);
  };

  return (
    <>
      <ArrowLeftFull
        color="#262626"
        className={styles.back}
        width={32}
        height={32}
        onClick={() => {
          dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
        }}
      />
      <div className={styles.container}>
        <Avatar
          avatarClassName={styles.avatar}
          placeholder={getInitials(`${FirstName} ${LastName}`)}
          src={AvatarUrl}
        />
        <span className={styles.heading}>{`${FirstName} ${LastName}`}</span>
        <div className={styles.actionButtons}>
          {[
            {
              icon: Icons.COMMENTS,
              color: 'blue',
              title: 'Comments',
              count: counts?.totalUnreadComments || 0,
              onClick: handleCommentsClick,
            },
            {
              icon: Icons.FAVORITES,
              color: 'red',
              title: 'Favorites',
              count: counts?.totalFavorites || 0,
              onClick: handleFavoritesClick,
            },
            {
              icon: Icons.HIGHLIGHTS,
              color: 'yellow',
              title: 'Highlights',
              count: counts?.totalHighlights || 0,
              onClick: handleHighlightsClick,
            },
          ].map(({ title, count, icon, color, onClick }) => {
            const disabled = count < 1;
            return (
              <div
                className={classNames(styles.button, { [styles.disabled]: disabled })}
                onClick={() => {
                  !disabled ? onClick() : undefined;
                }}
              >
                <div className={classNames(styles.icon, styles[color])}>
                  <Icons variant={icon} />
                </div>
                <p className={styles.buttonText}>
                  {title} <span>({count})</span>
                </p>
              </div>
            );
          })}
        </div>
        <div style={{ width: '100%', padding: '0 40px' }}>
          <div
            style={{ width: '100%', height: '1px', backgroundColor: '#E8E9EA', margin: '16px 0' }}
          />
        </div>
      </div>
      <SavedSearches
        data={clientSearchQuery}
        client={selectedClientSavedSearch}
        className={styles.savedSearches}
        setShowSearch={setShowSearch}
      />
    </>
  );
};

const SearchesModal = ({ setShowSearch }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const dispatch = useDispatch();
  const { selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);
  const modal = useSelector(getModalSelector);
  const { savedSearchTab } = useSelector(getFeedMetaSelectorV3);
  const { data } = useSelector(getClientsSearchInstancesSelector);
  const { isPending: isPendingCounts } = useSelector(getClientCountsSelectorV3);
  const clientSearchQuery = data.find(
    (obj) => obj?.Id === selectedClientSavedSearch?.Id,
  )?.SearchQueries;

  useEffect(() => {
    if (selectedClientSavedSearch?.Id) {
      dispatch(getClientCountsEffect(selectedClientSavedSearch?.Id));
    }
  }, [selectedClientSavedSearch?.Id]);

  const { agentSavedSearches, isLoading: isSavedSearchLoading } = useSelector(
    getSavedSearchesFormattedSelector,
  );

  const onClose = () => {
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const getTab = () => {
    switch (savedSearchTab) {
      case 1:
        return (
          <SavedSearches
            data={agentSavedSearches}
            isPending={isSavedSearchLoading}
            setShowSearch={setShowSearch}
          />
        );
      case 2:
        return <ClientSearches />;
      default:
        return null;
    }
  };

  if (modal.id !== SearchesModal.id) return null;

  const labels = {
    1: 'My Searches',
    2: 'Client Searches',
  };

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      headerClassName={styles.header}
      label={selectedClientSavedSearch ? '' : labels?.[savedSearchTab]}
      className={styles.searchModalWrapper}
    >
      <Wrapper className={styles.drawerInner} isPending={isPendingCounts}>
        {selectedClientSavedSearch ? (
          <ClientView clientSearchQuery={clientSearchQuery} setShowSearch={setShowSearch} />
        ) : (
          getTab()
        )}
      </Wrapper>
    </Modal>
  );
};

export default SearchesModal;

SearchesModal.id = 'searchesModal';
