import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Checkbox, isEmptyHTML } from 'components';
import {
  createTransactionTaskEffect,
  getTransactionEffect,
  setTransactionTaskParamsEffect,
  setTransactionTasksLastStateEffect,
  updateTransactionTaskEffect,
} from 'store/effects/transactions';
import { postTaskTemplateEffect, putTaskTemplateEffect } from 'store/effects/template';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT } from 'settings/constants/roles';
import { TaskTemplate } from 'types/transactionTemplate';
import { TaskEditableFormValuesType } from 'types/transactionTasks';

import styles from './styles.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import {
  updateCampaignTaskEffect,
  createCampaignTaskEffect,
  requestGetCampaignByIdEffect,
  putCampaignTaskTemplateEffect,
  postCampaignTaskTemplateEffect,
} from 'store/effects/radarKits';
import { pathToRegex } from 'settings/navigation/helpers';

interface FooterProps {
  values: TaskTemplate & { CampaignId?: number };
  changedValues: TaskEditableFormValuesType;
  isTemplate: boolean;
  isAssignTask: boolean;
  isViewMode?: boolean;
  isTransactionTask: boolean;
  isNew: boolean;
  templateId: number;
  setNewFormValues: (...args: any[]) => any;
  onCancelModal: (...args: any[]) => any;
  assignTask: (...args: any[]) => any;
  isAggregatedTasks: boolean;
  isCampaignTask?: boolean;
  onSaveTaskAsTemplate: () => void;
  isTranactionRoom?: boolean;
}

export const Footer = ({
  isTransactionTask = false,
  isNew = false,
  isTemplate = false,
  isAssignTask = false,
  templateId,
  values,
  changedValues,
  setNewFormValues = () => {},
  onCancelModal = () => {},
  assignTask = () => {},
  isAggregatedTasks = false,
  isViewMode,
  isCampaignTask,
  onSaveTaskAsTemplate = () => {},
  isTranactionRoom = false,
}: FooterProps) => {
  const userRole = useSelector(getUserRoleSelector);
  const { id } = useParams<{ id: string }>();
  const [isPending, setIsPending] = useState(false);
  const isNewTask = isNew && !isTemplate;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isDisabled = () =>
    !(
      (values?.Title?.length || values?.Name?.length) &&
      (isCampaignTask
        ? true
        : isTemplate
        ? values?.AssigneeRoles?.length
        : values?.AssigneeList?.length) &&
      (values?.DueDate || values?.DueDate === 0)
    ) ||
    isPending ||
    isViewMode;

  const parseControlOperatorOffset = (cpo: string) => {
    if (cpo) {
      //Split array on + or -
      let strArray = cpo.split(/[+-]/);
      if (strArray.length !== 2 || isNaN(Number(strArray[1]))) return undefined;
      let operator = cpo.includes('+') ? '+' : '-';
      return {
        initial: strArray[0],
        operator,
        dateOffset: Number(strArray[1]),
      };
    }
  };

  const openTaskModalAfterEditting = (taskId = null, campaignId = null, transactionId = null) => {
    // Don't open Task Modal details when multiple tasks are
    // created using repetition fields.
    if (values?.RepeatOption) return;

    setTimeout(() => {
      dispatch(
        setTransactionTaskParamsEffect({
          taskId: taskId || values.Id,
          transactionId: transactionId || values?.TransactionId,
          campaignId: campaignId || values?.CampaignId,
          isTaskDetail: true,
        }),
      );
      dispatch(setTransactionTasksLastStateEffect({ taskId: taskId || values.Id }));
    }, 100);
  };

  const handleFormRequest = () => {
    setIsPending(true);
    const isUpdate: boolean = values?.Id ? true : false;
    let effect = isUpdate ? updateTransactionTaskEffect : createTransactionTaskEffect;
    let cfg: any = isUpdate
      ? {
          ...changedValues,
          Id: values.Id,
          Description: isEmptyHTML(values?.Description) ? '' : values?.Description,
        }
      : {
          ...values,
        };
    if (isCampaignTask) {
      effect = isUpdate ? updateCampaignTaskEffect : createCampaignTaskEffect;
    }

    if (!values?.Id && values?.ControlOperatorOffset) {
      const parsedObj = parseControlOperatorOffset(values?.ControlOperatorOffset);
      if (parsedObj) {
        (cfg as any).TaskTemplateInitial = parsedObj.initial;
        (cfg as any).TaskTemplateOperator = parsedObj.operator;
        (cfg as any).TaskTemplateDateOffset = parsedObj.dateOffset;
        (cfg as any).TaskTemplateInitialOperatorOffset = values?.ControlOperatorOffset;
      }
    }

    dispatch(
      effect(cfg, {}, (err, response) => {
        const taskResponse = response?.data?.result;

        if (!err) {
          if (isNewTask && isTranactionRoom && changedValues.SaveAsTemplate) {
            onSaveTaskAsTemplate?.();
          }

          const newTaskIdx = taskResponse?.Tasks?.length - 1;
          const taskIdForNewTask = taskResponse?.Tasks?.[newTaskIdx]?.Id;
          const campaignIdForNewTask = taskResponse?.Tasks?.[newTaskIdx]?.CampaignId;

          if (pathname === routes.tasks) {
            dispatch(
              getTaskAggregateEffect({}, { silent: true }, () => {
                openTaskModalAfterEditting(
                  isNew ? taskIdForNewTask : null,
                  isNew ? campaignIdForNewTask : null,
                  isNew ? taskResponse?.Id : null,
                );
              }),
            );
          } else if (pathname !== routes.tasks && values?.TransactionId) {
            dispatch(
              getTransactionEffect({ id: values?.TransactionId }, { silent: true }, () => {
                openTaskModalAfterEditting();
              }),
            );
          } else if (pathname.match(pathToRegex(routes.radarViewSequence)) && id) {
            dispatch(requestGetCampaignByIdEffect(id, { silent: true }));
          } else
            openTaskModalAfterEditting(taskIdForNewTask, campaignIdForNewTask, taskResponse?.Id);

          onCloseModal();
          setIsPending(false);
        }
      }),
    );
  };

  const onCloseModal = () => {
    setNewFormValues({});
    onCancelModal();
  };

  const onSaveTemplate = () => {
    setIsPending(true);
    const isUpdate = values?.Id;
    let effect = isUpdate ? putTaskTemplateEffect : postTaskTemplateEffect;
    if (isCampaignTask) {
      effect = isUpdate ? putCampaignTaskTemplateEffect : postCampaignTaskTemplateEffect;
    }
    const body = {
      ...values,
      ...(values?.Locations && {
        Locations: values.Locations?.filter((el) => el.areasOfOperation?.length)
          .map((areas) => {
            const { miles, areasOfOperation, UUID } = areas;
            return areasOfOperation.map((area) => ({ ...area, Radius: miles }));
          })
          .flat(),
      }),
      EmailTemplateId: values?.EmailTemplate?.EmailTemplateId,
    };

    dispatch(
      effect(body, { id: templateId }, (err) => {
        if (pathname === routes.tasks) {
          dispatch(getTaskAggregateEffect({}, { silent: true }));
        } else if (pathname !== routes.tasks && values?.TransactionId) {
          dispatch(getTransactionEffect({ id: values?.TransactionId }, { silent: true }));
        } else if (pathname.match(pathToRegex(routes.radarViewSequence)) && id) {
          dispatch(requestGetCampaignByIdEffect(id, { silent: true }));
        }
        if (!err) {
          onCloseModal();
        }
        setIsPending(false);
      }),
    );
  };

  const onSubmit = () => {
    if (isAssignTask) {
      assignTask(values);
      onCloseModal();
    } else {
      isTemplate ? onSaveTemplate() : handleFormRequest();
    }
  };

  const toggleSaveAsTemplate = (val) => {
    setNewFormValues({ ...values, SaveAsTemplate: val });
    if (values) values.SaveAsTemplate = val;
  };

  const saveAsTemplate = () => (
    <div className={styles.saveAsTemplate}>
      <Checkbox
        className={styles.checkbox}
        checkmarkClassName={styles.checkmark}
        checked={values?.SaveAsTemplate || changedValues?.SaveAsTemplate}
        onChange={(e) => toggleSaveAsTemplate(e?.target?.checked)}
      />
      <p onClick={() => toggleSaveAsTemplate(!values?.SaveAsTemplate)}>Save as template</p>
    </div>
  );

  return (
    <div className={styles.footer}>
      {!isTransactionTask ? (
        <>
          <div
            className={classNames(styles.saveBtnDiv, {
              [styles.createBtnDiv]: isNewTask && userRole === AGENT,
            })}
          >
            {isNewTask && userRole === AGENT && !isCampaignTask ? saveAsTemplate() : null}
            <button
              className={classNames(
                styles.saveBtn,
                { [styles.createBtn]: isNewTask && userRole === AGENT },
                { [styles.saveBtnDisabled]: isDisabled() },
              )}
              onClick={onSubmit}
              disabled={isDisabled()}
            >
              <span>{isNew ? 'Create' : 'Save'}</span>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};
