import styles from './styles.module.scss';
import EmptyKit from '../EmptyKit';
import KitsDataRow from '../KitDataRow';

type KitsTablePropsType = {
  kitsData;
  categoriesData;
  frequenciesData;
  propertyTypesData;
  permitClickHandler: Function;
  editKitClickHandler: Function;
  reminderTemplateClickHandler: Function;
};

function KitsTable(props: KitsTablePropsType) {
  return (
    <div className={styles.KitsTable}>
      <table className={styles.table}>
        <tr className={styles.kitsRowHeading}>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>KIT</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Category</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Eligible Frequencies</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Internal Name</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Eligible Property Type(s)</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Eligible Location(s)</div>
          </td>
          <td className={styles.kitsCellHeading}>
            <div className={styles.heading}>Published</div>
          </td>
        </tr>
        {props.kitsData.length ? (
          props.kitsData.map((item) => (
            <KitsDataRow
              data={item}
              permitClickHandler={props.permitClickHandler}
              editKitClickHandler={props.editKitClickHandler}
              reminderTemplateClickHandler={props.reminderTemplateClickHandler}
            />
          ))
        ) : (
          <tr>
            <td colSpan={6}>
              <EmptyKit />
            </td>
          </tr>
        )}
      </table>
    </div>
  );
}

export default KitsTable;
