import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduce, trim } from 'lodash-es';
import AntSkeleton from 'antd/lib/skeleton';
import classNames from 'classnames';
import { Alert, Modal, ModalProps, Paragraph, Title, Skeleton, Tooltip } from 'components-antd';
import Button from 'components/Button';
import { Avatar } from 'components';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { getAgentTypeSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { RecommendationModal } from 'pages/ServicesCategory/components/AgentPartnersList/components/PartnersList/components/RecommendationModal';
import styles from './styles.module.scss';
import DetailsSection from './DetailsSection';
import Promotion from './components/Promotion';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { THIRD_PARTY, AGENT, CLIENT } from 'settings/constants/roles';
import { requestGetPartnerByIdEffect } from 'store/effects/singlePartner';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { getClientAgentId, getUserRoleSelector } from 'store/selectors/user';
import { getUserId } from 'store/selectors/user';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { InviteVendorIcon } from 'pages/ServicesCategory/components/icons';
import UploadIcon from 'pages/Settings/SettingsDisplay/UploadIcon';
import { vendorActionsById } from 'api/partners';
import { link } from 'settings/navigation/link';
import { requestGetPartnerByIdAction, singlePartnerArchived } from 'store/actions/singlePartner';
import { LocationType } from 'types';
import { ResendIcon } from 'pages/ServicesCategory/components/AgentPartnersList/components/PartnersList/components/PartnerCard/icons/ResendIcon';
import {
  getLoadingPartnerData,
  getPartnerObject,
  getPartnerBusinessNameAndId,
} from 'store/selectors/singlePartner';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import {
  convertNameToAvatarPlaceholder,
  formatPhoneNumber,
  getHrefLink,
  getIfExistThread,
  getPropertyTwoLinesAddress,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import {
  FavoriteIcon,
  Areas,
  Description,
  Notes,
  Organization,
  Message,
  Plus,
  Edit,
  Cross,
  Archive,
  ArrowUp,
  ArrowDown,
} from 'components/Icons';
import {
  cancelRecommendationInviteById,
  deletePartnerDraftsAsRecommendations,
  removeRecommendationById,
  replaceInRecommendationListAction,
  setVendorEditInviteMode,
  setVendorEditModeId,
  setVendorEditStrictlyProfile,
  vendorArchiveAction,
  vendorInviteAction,
} from 'store/actions/partners';
import { CloseModalIcon } from 'pages/ServicesCategory/components/AddVendorModal/icons';
import { getPartnerById } from 'api/singlePartner';
import { getActiveTeamList } from 'store/selectors/teamList';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import { getUserByEmail } from 'api/user';

interface PartnerProfileProps extends ModalProps {
  requestQuoteId: number;
  partnerId: number;
  setModalVisible: (open: boolean) => void;
  setSelectedPartnerId: Function;
  viewingFoundProfile?: boolean;
  IsStrictlyProfile: boolean;
  clientAgentName?: string;
  isActiveForClient?: boolean;
  setIsStrictlyProfile?: Function;
}

type MenuItem = Required<MenuProps>['items'][number];

enum VendorAction {
  RequestQuote = 'Request Quote',
  Message = 'Message',
  Edit = 'Edit',
  Invite = 'Invite to Mosaik',
  Resend = 'Resend',
  CancelInvite = 'Cancel Invite',
  PublishProfile = 'Publish Profile',
  Unarchive = 'Unarchive',
  Archive = 'Archive',
}

export const PartnerProfile = ({
  partnerId,
  setModalVisible,
  requestQuoteId,
  open,
  setSelectedPartnerId,
  viewingFoundProfile,
  IsStrictlyProfile,
  clientAgentName,
  isActiveForClient,
  setIsStrictlyProfile,
  ...props
}: PartnerProfileProps) => {
  const websiteCharacterLimit = 35;
  const history = useHistory();
  const dispatch = useDispatch();
  const [requestMode, setRequestMode] = useState(false);
  const [confirmChangeRecommendation, setConfirmChangeRecommendation] = useState(false);
  const [updateChangeRecommendation, setUpdateChangeRecommendation] = useState(false);
  const { isPending, isIdle, metaId } = useSelector(getLoadingPartnerData);
  const { id } = useSelector(getPartnerBusinessNameAndId);
  const partnerData = useSelector(getPartnerObject);
  const userId = useSelector(getUserId);
  const threads = useSelector(getThreadsListSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const { category: categoryId } = useSelector(getConciergeSearchSelector);
  const [generalInfoComponents, setGeneralInfoComponents] = useState<any[]>([]);
  const isLoading = metaId !== partnerId || isPending || isIdle;
  const agentId = useSelector(getClientAgentId);
  const userRole = useSelector(getUserRoleSelector);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [performing, setPerforming] = useState<boolean>(false);
  const [recommend, setRecommend] = useState<boolean | undefined>(undefined);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const teamMembers = useSelector(getActiveTeamList);
  const clientContext = useSelector(getClientContextsStateSelector);
  const statusRef = useRef<any>(null);

  useEffect(() => {
    if (requestQuoteId) {
      setRequestMode(true);
    }
  }, []);

  useEffect(() => {
    if (partnerData) {
      if (!generalInfoComponents.length) {
        let components: any[] = [];
        components.push(StatusComponent(components.length % 2 === 0));
        partnerData.PhoneNumber && components.push(PhoneComponent(components.length % 2 === 0));
        partnerData.Email && components.push(EmailComponent(components.length % 2 === 0));
        partnerData.Address && components.push(AddressComponent(components.length % 2 === 0));
        partnerData.Website && components.push(WebsiteComponent(components.length % 2 === 0));
        (partnerData?.ContactPersonFirstName || partnerData?.ContactPersonEmail) &&
          components.push(POCComponent(components.length % 2 === 0));
        setGeneralInfoComponents([...components]);
      }
    } else {
      setGeneralInfoComponents([]);
    }
  }, [partnerData]);

  useEffect(() => {
    if (statusRef?.current) {
      if (!partnerData?.IsPublished && !partnerData?.IsInvited)
        statusRef.current.textContent = 'Draft';
      else if (partnerData?.IsPublished && !partnerData?.IsInvited)
        statusRef.current.textContent = 'Info Only';
      else if (partnerData?.IsInvited) statusRef.current.textContent = 'Pending';
    }
  }, [partnerData?.IsPublished, partnerData?.IsInvited]);

  useEffect(() => {
    if (partnerId) {
      dispatch(requestGetPartnerByIdEffect({ partnerId, IsStrictlyProfile }));
    }
  }, [partnerId, dispatch]);

  useEffect(() => {
    if (updateChangeRecommendation) {
      dispatch(requestGetPartnerByIdEffect({ partnerId }));
      setUpdateChangeRecommendation(false);
    }
  }, [updateChangeRecommendation]);

  const { address, categories } = useMemo(() => {
    return {
      address: getPropertyTwoLinesAddress(partnerData?.Address),
      categories: reduce(
        partnerData?.PartnerCategories,
        (acc, { Title }) => {
          if (acc) {
            return `${acc}, ${Title}`;
          }
          return Title;
        },
        '',
      ),
    };
  }, [partnerData?.Address, partnerData?.PartnerCategories, partnerData?.RecommendingAgents]);

  const initiateSendingMessageToPartner = useCallback(() => {
    setModalVisible(false);

    const thread = getIfExistThread(
      threads,
      [id, userId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      const firstName = partnerData?.ThirdParty?.User?.FirstName;
      const lastName = partnerData?.ThirdParty?.User?.LastName;

      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id: partnerData?.Id,
                name: `${firstName || ''} ${lastName || ''} (${partnerData?.BusinessName || ''})`,
                value: partnerData?.Id,
                role: THIRD_PARTY,
                avatarUrl: partnerData?.LogoUrl,
                firstName: partnerData?.FirstName,
                lastName: partnerData?.LastName,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  }, [dispatch, threads, id, userId, partnerData]);

  const recommendedAgent =
    userRole === AGENT &&
    partnerData?.RecommendingAgents.find(
      ({ Id, TeamId }) => Id === agentId || (TeamId && TeamId === teamId),
    );

  const validateAgentType = () =>
    agentType === AGENT_TYPE.Individual ||
    (agentType === AGENT_TYPE.Team && agentRole !== TEAM_BASIC);

  const showRecommended = () =>
    (recommendedAgent && validateAgentType() && !partnerData?.IsArchived) ||
    (partnerData?.IsStrictlyProfile && userRole === AGENT && !partnerData?.IsArchived);

  const showRecommend = () => userRole === AGENT && validateAgentType();

  const getBusinessHandlerName = (): string => {
    let businessHandlerName = '';
    if (partnerData?.ContactPersonFirstName)
      businessHandlerName = partnerData?.ContactPersonFirstName;
    if (businessHandlerName && partnerData?.ContactPersonLastName)
      businessHandlerName += ` ${partnerData?.ContactPersonLastName}`;
    return businessHandlerName;
  };

  const POCComponent = (odd: boolean) => {
    return (
      <Paragraph className={classNames(styles.contactParagraph, odd ? styles.greyRow : '')}>
        <div className={styles.contactInner}>
          <span>Point of Contact</span>
        </div>
        <div className={classNames(styles.cpDetail, styles.cpDetailPOC)}>
          {getBusinessHandlerName()}
          {partnerData?.ContactPersonEmail && <div>{partnerData.ContactPersonEmail}</div>}
        </div>
      </Paragraph>
    );
  };

  const modalHeader = () => (
    <div className={styles.modalHeader}>
      {isLoading ? (
        <AntSkeleton.Avatar shape="circle" active={isLoading} size="large" />
      ) : (
        <Avatar
          className={styles.avatarWrap}
          avatarClassName={styles.avatar}
          src={partnerData?.LogoUrl}
          placeholder={convertNameToAvatarPlaceholder(trim(partnerData?.BusinessName))}
        />
      )}

      <Skeleton showSkeleton={isLoading} rowCount={2}>
        <Title level={3} className={styles.businessName}>
          {partnerData?.BusinessName}
        </Title>
        {categories ? (
          <div className={styles.categoriesContainer}>
            {categories?.split(',').map((cat, index, array) => (
              <div
                className={classNames(
                  styles.categoriesParagraph,
                  array.length > 1 ? styles.categoriesParagraphBig : '',
                )}
              >
                {cat.trim()}
              </div>
            ))}
          </div>
        ) : null}
        {userRole === CLIENT ? (
          <Alert
            className={styles.recommendedBarClient}
            message={
              <>
                <FavoriteIcon className={styles.recommendedIcon} color={'#fb913a'} />
                {'Recommended by '}
                <span>{clientAgentName ? `${clientAgentName}'s Team` : '-'}</span>
              </>
            }
            type="error"
          />
        ) : null}
      </Skeleton>
    </div>
  );

  const getVendorStatus = (): string => {
    let status: string = '-';
    if (
      !(
        partnerData.RecommendingAgents.map((agent) => agent.Id).includes(userId) ||
        partnerData.ProfileCreatedBy === userId
      )
    )
      status = 'Active';
    else if (partnerData?.IsStrictlyProfile) status = 'Info Only';
    else if (isLikeDraft()) status = 'Draft';
    else if (partnerData?.IsArchived) status = 'Archived';
    else if (
      !partnerData?.IsArchived &&
      partnerData?.IsActive &&
      partnerData?.IsPublished &&
      !partnerData?.IsInvited
    )
      status = 'Info Only';
    else if (
      !partnerData?.IsArchived &&
      partnerData?.IsInvited &&
      (!partnerData?.IsActive || !partnerData?.ThirdParty?.User?.CompletedProfile)
    )
      status = 'Pending';
    else if (
      partnerData?.IsActive &&
      !partnerData?.IsArchived &&
      partnerData?.ThirdParty?.User?.CompletedProfile
    )
      status = 'Active';

    return status;
  };

  const StatusComponent = (odd: boolean) => {
    if (userRole === CLIENT) return null;

    return (
      <Paragraph className={classNames(styles.contactParagraph, odd ? styles.greyRow : '')}>
        <div className={styles.contactInner}>
          <span>Status</span>
        </div>
        <div
          testid="phone"
          className={classNames(styles.contactPhoneText, styles.cpDetail)}
          ref={statusRef}
        >
          {getVendorStatus()}
        </div>
      </Paragraph>
    );
  };

  const PhoneComponent = (odd: boolean) => {
    return (
      <Paragraph className={classNames(styles.contactParagraph, odd ? styles.greyRow : '')}>
        <div className={styles.contactInner}>
          <span>Phone</span>
        </div>
        <div testid="phone" className={classNames(styles.contactPhoneText, styles.cpDetail)}>
          {partnerData?.PhoneNumber ? formatPhoneNumber(partnerData?.PhoneNumber) : '-'}
        </div>
      </Paragraph>
    );
  };

  const EmailComponent = (odd: boolean) => {
    return (
      <Paragraph className={classNames(styles.contactParagraph, odd ? styles.greyRow : '')}>
        <div className={styles.contactInner}>
          <span>Email</span>
        </div>
        <div className={styles.cpDetail}>{partnerData?.Email}</div>
      </Paragraph>
    );
  };

  const AddressComponent = (odd: boolean) => {
    return (
      <Paragraph
        className={classNames(
          styles.contactParagraph,
          address.length > 1 || partnerData?.SuiteUnit ? styles.contactParagraphAddress : '',
          odd ? styles.greyRow : '',
        )}
      >
        <div className={styles.contactInner}>
          <span>Address</span>
        </div>
        <div className={classNames(styles.contactAddressText, styles.cpDetail)}>
          {address.length && (
            <>
              <div className={styles.contactAddressTextLine}>
                {partnerData?.Address?.Line1 || address[0] || address[1] || '-'}
              </div>
              {address[0] && address[1] && <div>{address[1]}</div>}
              {partnerData?.SuiteUnit && <div>Unit/ Suite: {partnerData.SuiteUnit}</div>}
            </>
          )}
        </div>
      </Paragraph>
    );
  };

  const WebsiteComponent = (odd: boolean) => {
    return (
      <Paragraph className={classNames(styles.contactParagraph, odd ? styles.greyRow : '')}>
        <div className={styles.contactInner}>
          <span>Website</span>
        </div>
        {partnerData?.Website?.length > websiteCharacterLimit ? (
          <Tooltip
            placement="top"
            overlayClassName={classNames(styles.infoTooltip, 'mosaikTooltip')}
            title={partnerData?.Website}
            align={{
              offset: [-48, 0],
            }}
          >
            <div className={classNames(styles.cpDetail, styles.website)}>
              <a
                href={getHrefLink(partnerData?.Website)}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {partnerData?.Website}
              </a>
            </div>
          </Tooltip>
        ) : (
          <div className={classNames(styles.cpDetail, styles.website)}>
            <a
              href={getHrefLink(partnerData?.Website)}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {partnerData?.Website}
            </a>
          </div>
        )}
      </Paragraph>
    );
  };

  const renderRightPane = (): boolean => {
    return (
      partnerData?.AgentNote ||
      partnerData?.Description ||
      partnerData?.PartnerTags?.length > 0 ||
      partnerData?.AreasOfOperation?.length > 0
    );
  };

  const handleMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToPartner,
    {
      categoryId,
    },
    userRole === CLIENT,
  );

  const handleRequestQuoteClick = useEmailVerificationWall(
    ActionToInterrupt.REQUEST_QUOTE,
    () => {
      history.push(
        link.toServicesCategoryPartnerQuote({
          partnerId,
          categoryId:
            Number(categoryId) > 0
              ? categoryId
              : partnerData?.PartnerCategories?.length
              ? partnerData?.PartnerCategories[0].Id
              : '',
        }),
      );
    },
    {
      categoryId:
        Number(categoryId) > 0
          ? categoryId
          : partnerData?.PartnerCategories?.length
          ? partnerData?.PartnerCategories[0].Id
          : '',
    },
    userRole === CLIENT,
  );

  const handleArchive = async () => {
    setPerforming(true);
    try {
      const response = await vendorActionsById(partnerData?.Id, {
        IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
        IsArchived: true,
      });
      if (response.status === 200) {
        setConfirmChangeRecommendation(false);
        setRecommend(undefined);
        dispatch(singlePartnerArchived(true));
        dispatch(
          vendorArchiveAction({ Id: partnerData?.Id, archived: true, UnrecommendDate: new Date() }),
        );
        await silentRefresh();
        showSuccessMessage('Partner archived successfully.');
      } else showErrorMessage('Failed to archive partner.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Failed to archive partner.');
    }
    setPerforming(false);
  };

  const handleRecommend = async () => {
    setPerforming(true);
    try {
      const response = await vendorActionsById(partnerData?.Id, {
        IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
        Recommend: true,
      });
      if (response.status === 200) {
        setConfirmChangeRecommendation(false);
        setRecommend(undefined);
        await silentRefresh();
        showSuccessMessage('Partner recommended successfully.');
      } else showErrorMessage('Failed to recommended partner.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Failed to recommended partner.');
    }
    setPerforming(false);
  };

  const handleCancelInviteClick = async () => {
    setPerforming(true);
    try {
      const response = await vendorActionsById(partnerData?.Id, {
        IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
        CancelInvite: true,
      });
      if (response.status === 200) {
        await silentRefresh();
        showSuccessMessage('Invite cancelled successfully.');
      } else showErrorMessage('Failed to cancel invite.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Failed to cancel invite.');
    }
    setPerforming(false);
  };

  const handleEditClick = (inviteMode: boolean = false) => {
    if (setSelectedPartnerId) setSelectedPartnerId(0);
    if (inviteMode) dispatch(setVendorEditInviteMode(true));
    if (partnerData?.IsStrictlyProfile) dispatch(setVendorEditStrictlyProfile(true));
    dispatch(setVendorEditModeId(partnerData?.Id));
    setModalVisible(false);
  };

  const verifyEnteredEmail = async (): Promise<boolean | number> => {
    if (!partnerData?.IsStrictlyProfile) return true;

    try {
      const response = await getUserByEmail(partnerData.Email);
      if (response.status === 200 && response?.data?.result) {
        const user = response?.data?.result;

        if (
          user.ThirdParty &&
          !user.IsEmailVerified &&
          !user.CompletedProfile &&
          !user.ThirdParty.Partner?.RecommendedByAgentsId?.includes(userId)
        )
          return user.Id;
        else if (
          user.ThirdParty.Partner?.RecommendedByAgentsId?.includes(userId) ||
          (user.ThirdParty && user.IsEmailVerified && user.CompletedProfile)
        ) {
          if (setIsStrictlyProfile) setIsStrictlyProfile(false);
          setSelectedPartnerId(user.Id);
          showErrorMessage(
            'The Business with Email already exists. You can add it to your recommendations',
          );
        } else
          showErrorMessage(
            'The user with Email already exists. Try editing the Email before inviting.',
          );

        return false;
      }
    } catch (e) {
      showErrorMessage('Unable to verify email.');
      return false;
    }

    return true;
  };

  const handleInviteClick = async () => {
    if (!partnerData.Email) {
      handleEditClick(true);
    } else {
      setPerforming(true);
      const emailVerified = await verifyEnteredEmail();
      if (emailVerified === false) {
        setPerforming(false);
        return;
      } else {
        try {
          const response = await vendorActionsById(partnerData?.Id, {
            IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
            ...(typeof emailVerified === 'number' ? {} : { Invite: true }),
            InvitingExistingPartnerId: emailVerified === true ? undefined : emailVerified,
            DeleteProfileId: partnerData?.Id,
          });
          if (response.status === 200) {
            dispatch(vendorInviteAction(partnerData?.Id));
            await silentRefresh(response?.data?.result, partnerData?.Id);
            if (Number(categoryId) === ConciergeDefaultCategories.Draft)
              dispatch(
                deletePartnerDraftsAsRecommendations({ id: partnerData?.Id, IsSpecial: true }),
              );
            showSuccessMessage('Partner invited successfully.');
          } else showErrorMessage('Failed to invite partner.');
        } catch (e: any) {
          showErrorMessage(e.response?.data?.message || 'Failed to invite partner.');
        }
      }

      setPerforming(false);
    }
  };

  const handlePublishClick = async () => {
    setPerforming(true);
    try {
      const response = await vendorActionsById(partnerData?.Id, {
        IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
        IsPublished: true,
      });
      if (response.status === 200) {
        await silentRefresh();
        if (Number(categoryId) === ConciergeDefaultCategories.Draft)
          dispatch(deletePartnerDraftsAsRecommendations({ id: partnerData?.Id, IsSpecial: true }));
        showSuccessMessage('Partner published successfully.');
      } else showErrorMessage('Failed to publish partner.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Failed to publish partner.');
    }
    setPerforming(false);
  };

  const handleResendClick = async () => {
    setPerforming(true);
    try {
      const response = await vendorActionsById(partnerData?.Id, {
        IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
        ResendInvite: true,
      });
      if (response.status === 200) {
        await silentRefresh();
        showSuccessMessage('The invitation was successfully resent.');
      } else showErrorMessage('Unable to resend invite.');
    } catch (e: any) {
      showErrorMessage(e.response?.data?.message || 'Unable to resend invite.');
    }
    setPerforming(false);
  };

  const handleUnarchiveOrRecommendClick = async () => {
    setPerforming(true);
    try {
      const response = await vendorActionsById(partnerData?.Id, {
        IsStrictlyProfile: !!partnerData?.IsStrictlyProfile,
        IsArchived: false,
      });
      if (response.status === 200) {
        setConfirmChangeRecommendation(false);
        setRecommend(undefined);
        dispatch(singlePartnerArchived(false));
        dispatch(vendorArchiveAction({ Id: partnerData?.Id, archived: false }));
        await silentRefresh();
        showSuccessMessage(
          `Partner ${viewingFoundProfile ? 'recommended' : 'unarchived'} successfully.`,
        );
      } else
        showErrorMessage(`Failed to ${viewingFoundProfile ? 'recommend' : 'unarchive'} partner.`);
    } catch (e: any) {
      showErrorMessage(
        e.response?.data?.message ||
          `Failed to ${viewingFoundProfile ? 'recommend' : 'unarchive'} partner.`,
      );
    }
    setPerforming(false);
  };

  const performVendorAction = {
    [VendorAction.Archive]: handleArchive,
    [VendorAction.CancelInvite]: handleCancelInviteClick,
    [VendorAction.Edit]: handleEditClick,
    [VendorAction.Invite]: handleInviteClick,
    [VendorAction.Message]: handleMessageButtonClick,
    [VendorAction.PublishProfile]: handlePublishClick,
    [VendorAction.RequestQuote]: handleRequestQuoteClick,
    [VendorAction.Resend]: handleResendClick,
    [VendorAction.Unarchive]: handleUnarchiveOrRecommendClick,
  };

  const isLikeDraft = (): boolean => {
    return !partnerData?.IsPublished && !partnerData?.IsInvited;
  };

  const getActionItems = (): MenuItem[] => {
    const menuItem = [
      {
        label: (
          <Button
            className={styles.button}
            title="Actions"
            testid="vendor_actions"
            icon={menuOpen ? <ArrowUp color="#AAABAB" /> : <ArrowDown color="#AAABAB" />}
            disabled={performing || refreshing}
          />
        ),
        key: 'vendorActionItem',
        children: [],
      },
    ];

    const isStrictlyProfile = partnerData?.IsStrictlyProfile;
    const isActive = isStrictlyProfile ? true : partnerData?.IsActive;
    const isEmailVerified = !isStrictlyProfile && partnerData?.ThirdParty?.User?.IsEmailVerified;
    const isCompletedProfile =
      !isStrictlyProfile && partnerData?.ThirdParty?.User?.CompletedProfile;
    const isPublished = isStrictlyProfile ? true : partnerData?.IsPublished;
    const isInvited = isStrictlyProfile ? false : partnerData?.IsInvited;
    const isArchived = partnerData?.IsArchived;
    const isAgent = userRole === AGENT;
    const isBasic = agentRole === TEAM_BASIC;
    const isDraftLike = isLikeDraft();
    const isRecommendingAgent = isStrictlyProfile
      ? true
      : partnerData?.RecommendingAgents?.length &&
        partnerData.RecommendingAgents.map((agent) => agent.Id).includes(userId);

    const shouldShowRequestQuote =
      isAgent &&
      !isStrictlyProfile &&
      isActive &&
      !isArchived &&
      isEmailVerified &&
      isCompletedProfile &&
      !isDraftLike;

    const shouldShowMessage =
      isAgent &&
      !isStrictlyProfile &&
      isActive &&
      !isArchived &&
      isEmailVerified &&
      isCompletedProfile &&
      !isDraftLike;

    const shouldShowInvite =
      isAgent &&
      !isBasic &&
      isRecommendingAgent &&
      !isEmailVerified &&
      !isCompletedProfile &&
      (isStrictlyProfile || !isInvited || isDraftLike);

    const shouldShowResend =
      isAgent &&
      !isBasic &&
      isRecommendingAgent &&
      !isEmailVerified &&
      !isCompletedProfile &&
      !isStrictlyProfile &&
      isInvited &&
      !isDraftLike;

    const shouldShowCancelInvite =
      isAgent &&
      !isBasic &&
      isRecommendingAgent &&
      !isEmailVerified &&
      !isCompletedProfile &&
      !isStrictlyProfile &&
      isInvited &&
      !isDraftLike;

    const shouldShowPublishProfile =
      isAgent && !isBasic && !isStrictlyProfile && isRecommendingAgent && !isPublished;

    const shouldShowArchive = isAgent && !isBasic && isRecommendingAgent && !isArchived;

    const shouldShowEdit =
      isAgent && !isBasic && isRecommendingAgent && !isEmailVerified && !isCompletedProfile;

    if (menuItem[0]) {
      const menuItemChildren = menuItem[0].children as any[];
      // Request Quote
      if (shouldShowRequestQuote)
        menuItemChildren.push({
          label: VendorAction.RequestQuote,
          key: VendorAction.RequestQuote,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(70, 115, 209, 0.1)' }}
            >
              <Plus color="#4673D1" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      // Message
      if (shouldShowMessage)
        menuItemChildren.push({
          label: VendorAction.Message,
          key: VendorAction.Message,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(4, 164, 81, 0.1)' }}
            >
              <Message fill="#04A451" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      // Invite
      if (shouldShowInvite)
        menuItemChildren.push({
          label: VendorAction.Invite,
          key: VendorAction.Invite,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(70, 115, 209, 0.1)' }}
            >
              <InviteVendorIcon color="#4673D1" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      //Resend
      if (shouldShowResend)
        menuItemChildren.push({
          label: VendorAction.Resend,
          key: VendorAction.Resend,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(70, 115, 209, 0.1)' }}
            >
              <ResendIcon color="#4673D1" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      //CancelInvite
      if (shouldShowCancelInvite)
        menuItemChildren.push({
          label: VendorAction.CancelInvite,
          key: VendorAction.CancelInvite,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(255, 87, 109, 0.1)' }}
            >
              <Cross color="#EC455E" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      //PublishProfile
      if (shouldShowPublishProfile)
        menuItemChildren.push({
          label: VendorAction.PublishProfile,
          key: VendorAction.PublishProfile,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(81, 191, 225, 0.1)' }}
            >
              <UploadIcon color="#51BFE1" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      //Edit
      if (shouldShowEdit)
        menuItemChildren.push({
          label: VendorAction.Edit,
          key: VendorAction.Edit,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(251, 145, 58, 0.1)' }}
            >
              <Edit stroke={'#FB913A'} />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      //Archive
      if (shouldShowArchive)
        menuItemChildren.push({
          label: VendorAction.Archive,
          key: VendorAction.Archive,
          icon: (
            <div
              className={styles.vendorActionMenuItemIcon}
              style={{ backgroundColor: 'rgba(116, 116, 117, 0.1)' }}
            >
              <Archive color="#747475" />
            </div>
          ),
          className: styles.vendorActionMenuItem,
        });
      //Unarchive
    }

    return menuItem;
  };

  const silentRefresh = async (id = 0, replaceInListById = 0) => {
    if (!partnerData?.Id) return;

    setRefreshing(true);
    try {
      const response = await getPartnerById({ partnerId: id ? id : partnerData?.Id });
      if (response.status === 200 && response?.data?.result) {
        const updatedPartner = response?.data?.result;
        const obj = {
          state: 'ready',
          meta: { partnerId: partnerData?.Id },
          data: {
            success: true,
            result: updatedPartner,
          },
        };
        if (replaceInListById)
          dispatch(
            replaceInRecommendationListAction({
              targetId: replaceInListById,
              updatedPartner,
            }),
          );
        dispatch(requestGetPartnerByIdAction(obj));
        if (!updatedPartner?.IsPublished && !updatedPartner?.IsInvited)
          dispatch(removeRecommendationById(partnerData.Id));
        else if (updatedPartner?.IsPublished && !updatedPartner?.IsInvited)
          dispatch(cancelRecommendationInviteById(partnerData.Id));
      }
    } catch (e) {
      console.error(e);
    }
    setRefreshing(false);
  };

  const getAreaName = (area): string => {
    let formattedName: string = '-';
    if (area.Type === LocationType.Zipcode) {
      if (area.PlaceName) {
        const splitted = area.PlaceName.split(',');
        if (splitted.length > 1)
          formattedName = `${splitted[0].trim()}, ${splitted[1].trim().replace(/\d+/g, '')}`;
        formattedName = splitted[0];
      } else formattedName = area.Zipcode || '-';
    } else if (area.Type === 'State' || area.Type === 'County')
      formattedName = `${area.PlaceName.split(',')[0]}, ${area.State}`;
    else if (area.City && area.State) formattedName = `${area.City}, ${area.State}`;
    else if (area.City) formattedName = area.City;
    else if (area.State) formattedName = area.State;
    return formattedName;
  };

  const showAgentNote = (): boolean => {
    try {
      if (!partnerData?.AgentNote) return false;

      if (userRole === AGENT) {
        if (
          agentType === AGENT_TYPE.Team &&
          !partnerData?.IsStrictlyProfile &&
          (!teamMembers?.length ||
            !teamMembers.find((member) => member.id === partnerData?.ThirdParty.User?.InvitedBy))
        )
          return false;
      }

      if (userRole === CLIENT) {
        if (!clientContext?.data?.length) return false;

        const clientAgentIds: any[] = clientContext.data
          .map((d) => (d.Agents?.length ? d.Agents.map((a) => a.Id) : undefined))
          .flat();

        if (!clientAgentIds.length) return false;

        if (
          partnerData?.IsStrictlyProfile &&
          (!partnerData?.ProfileCreatedBy ||
            !clientAgentIds.includes(partnerData?.ProfileCreatedBy))
        )
          return false;

        if (
          !partnerData?.IsStrictlyProfile &&
          !clientAgentIds.includes(partnerData?.ThirdParty.User?.InvitedBy)
        )
          return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  const menuItems = getActionItems();
  const isMenuDisabled = menuItems.length > 0 && (menuItems[0] as any)?.children.length === 0;

  return (
    <Modal
      footer={null}
      className={classNames(
        styles.basePartnerProfile,
        renderRightPane() ? styles.twoPane : styles.onePane,
      )}
      open={open}
      {...props}
    >
      <div className={styles.closeBox}>
        <div
          className={styles.closeBoxIcon}
          onClick={() => {
            setModalVisible(false);
            if (setSelectedPartnerId) setSelectedPartnerId(0);
          }}
        >
          <CloseModalIcon />
        </div>
      </div>
      <div className={styles.partnerBody}>
        {!requestMode && (
          <div className={classNames(styles.pane, styles.paneLeft)}>
            <div className={styles.section}>
              {modalHeader()}
              <Skeleton showSkeleton={isLoading} rowCount={4}>
                {generalInfoComponents.map((comp) => comp)}
              </Skeleton>
            </div>
            <Skeleton showSkeleton={isLoading}>
              {showRecommended() ? (
                <Alert
                  className={classNames(
                    styles.recommendedBar,
                    styles.clickable,
                    performing || refreshing || agentRole === TEAM_BASIC
                      ? styles.alertDisabled
                      : '',
                  )}
                  message={
                    <>
                      <FavoriteIcon className={styles.recommendedIcon} color={'#fb913a'} />
                      Recommended
                    </>
                  }
                  onClick={() => {
                    if (performing || refreshing || agentRole === TEAM_BASIC) return;

                    setRecommend(false);
                    setConfirmChangeRecommendation(true);
                  }}
                  type="error"
                />
              ) : showRecommend() ? (
                <Alert
                  className={classNames(
                    styles.unRecommendedBar,
                    performing || refreshing || agentRole === TEAM_BASIC
                      ? styles.alertDisabled
                      : '',
                  )}
                  message={
                    <>
                      <FavoriteIcon className={styles.unRecommendedIcon} active={false} />
                      Recommend
                    </>
                  }
                  onClick={() => {
                    if (performing || refreshing || agentRole === TEAM_BASIC) return;

                    setRecommend(true);
                    setConfirmChangeRecommendation(true);
                  }}
                />
              ) : null}
              {userRole === CLIENT ? (
                isActiveForClient ? (
                  <>
                    <Button
                      className={styles.clientMessageButton}
                      title="Message"
                      testid="vendor_message"
                      iconLeft={<Message />}
                      onClick={handleMessageButtonClick}
                    />
                    <Button
                      className={styles.clientQuoteButton}
                      title="Request Quote"
                      testid="vendor_quote"
                      onClick={handleRequestQuoteClick}
                    />
                  </>
                ) : null
              ) : (
                <Menu
                  onClick={() => {}}
                  selectedKeys={['vendorActionItem']}
                  mode="horizontal"
                  items={menuItems.length ? menuItems : []}
                  onOpenChange={(openKeys) => {
                    if (openKeys.length === 1 && openKeys[0] === 'vendorActionItem')
                      setMenuOpen(true);
                    else setMenuOpen(false);
                  }}
                  onSelect={(item) => {
                    performVendorAction[item.key]();
                  }}
                  style={
                    !showRecommended() && !showRecommend()
                      ? {
                          marginTop: 16,
                        }
                      : {}
                  }
                  className={classNames(
                    styles.actionsMenu,
                    isMenuDisabled ? styles.actionsMenuDisabled : '',
                  )}
                  triggerSubMenuAction={'click'}
                  overflowedIndicator={null}
                  subMenuCloseDelay={0}
                  disabled={isMenuDisabled}
                />
              )}
            </Skeleton>
          </div>
        )}

        {!requestMode && renderRightPane() && (
          <div className={classNames(styles.pane, styles.paneRight)}>
            <Skeleton showSkeleton={isLoading} rowCount={1} className={styles.skeleton}>
              <Promotion
                promotionInfo={{
                  isPromotionActive: false,
                  noOfCustomers: 10,
                  offPercentage: 10,
                  expiryDate: '8/20/2025',
                }}
              />
            </Skeleton>
            <Skeleton showSkeleton={isLoading} rowCount={1} className={styles.skeleton}>
              {showAgentNote() ? (
                <DetailsSection
                  icon={<Notes color="#4673D1" />}
                  title="Agent Note"
                  text={partnerData.AgentNote}
                  iconBG="rgba(70 ,115, 209,0.1)"
                  bold={true}
                />
              ) : null}
            </Skeleton>
            <Skeleton showSkeleton={isLoading} rowCount={1} className={styles.skeleton}>
              {partnerData?.Description ? (
                <DetailsSection
                  icon={<Description color="#04A451" />}
                  title="Description"
                  text={partnerData.Description}
                  iconBG="rgba(4, 164, 81,0.1)"
                  bold={false}
                />
              ) : null}
            </Skeleton>
            <Skeleton showSkeleton={isLoading} rowCount={1} className={styles.skeleton}>
              {partnerData?.PartnerTags?.length ? (
                <DetailsSection
                  icon={<Organization color="#928CDA" />}
                  title="Services Offered"
                  infoToDisplay={partnerData.PartnerTags.map((tag) => ({ text: tag.Name }))}
                  iconBG="rgba(146, 140, 218,0.1)"
                  bold={true}
                />
              ) : null}
            </Skeleton>
            <Skeleton showSkeleton={isLoading} rowCount={1} className={styles.skeleton}>
              {partnerData?.AreasOfOperation?.length ? (
                <DetailsSection
                  icon={<Areas color="#51BFE1" />}
                  title="Service Areas"
                  infoToDisplay={partnerData.AreasOfOperation.map((area) => ({
                    text: getAreaName(area),
                    subText:
                      area.Radius && !isNaN(Number(area.Radius)) && Number(area.Radius) > 0
                        ? `   (+${area.Radius} miles)`
                        : '',
                  }))}
                  iconBG="rgba(81, 191, 225,0.1)"
                  bold={false}
                />
              ) : null}
            </Skeleton>
          </div>
        )}
      </div>
      {confirmChangeRecommendation && (
        <RecommendationModal
          isOpen={recommend !== undefined && confirmChangeRecommendation}
          selectedPartner={partnerData}
          onCloseModal={() => setConfirmChangeRecommendation(false)}
          isLoadingRecommendation={isPending}
          onChangeRecommendation={
            recommend
              ? (!partnerData.IsStrictlyProfile &&
                  partnerData.RecommendingAgents.map((agent) => agent.Id).includes(userId)) ||
                (partnerData.IsStrictlyProfile && partnerData.ProfileCreatedBy === userId)
                ? handleUnarchiveOrRecommendClick
                : handleRecommend
              : handleArchive
          }
        />
      )}
    </Modal>
  );
};
