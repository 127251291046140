import { PageWrapper } from 'components/Templates';
import PropTypes from 'prop-types';
import { PageNotFound } from 'components';
import { routes } from 'settings/navigation/routes';
import { Tasks } from './Tasks';
import { Timelines } from './Timelines';
import { Transactions } from './Transactions';
import { Details } from './Details';
import { TransactionTemplateCreate } from './Transactions/TransactionTemplateCreate';
import { TransactionTemplateEdit } from './Transactions/TransactionTemplateEdit';
import { TransactionTemplateView } from './Transactions/TransactionTemplateView';
import { Clauses } from './Clauses';
import { CreateClause, EditClause } from './Clauses/components';
import { EmailTemplates, NewEmailTemplate, EditEmailTemplate } from './EmailTemplates';

export const Templates = ({ match: { path } }) => {
  const getPageByRoute = (path) => {
    switch (path) {
      case routes.templatesTasks:
        return <Tasks />;
      case routes.templatesTimelines:
        return <Timelines />;
      case routes.templatesTransactions:
        return <Transactions />;
      case routes.templatesTransactionCreate:
        return <TransactionTemplateCreate />;
      case routes.templatesTransactionView:
        return <TransactionTemplateView />;
      case routes.templatesTransactionEdit:
        return <TransactionTemplateEdit />;
      case routes.templatesDetails:
        return <Details />;
      case routes.templatesProjects:
        return <Transactions />;
      case routes.templatesProjectCreate:
        return <TransactionTemplateCreate />;
      case routes.templatesProjectView:
        return <TransactionTemplateView />;
      case routes.templatesProjectEdit:
        return <TransactionTemplateEdit />;
      case routes.templatesClause:
        return <Clauses />;
      case routes.templatesClauseCreate:
        return <CreateClause />;
      case routes.templatesClauseEdit:
        return <EditClause />;
      case routes.templatesEmail:
        return <EmailTemplates />;
      case routes.templatesEmailNew:
        return <NewEmailTemplate />;
      case routes.templatesEmailEdit:
        return <EditEmailTemplate />;
      default:
        return <PageNotFound backUrl={routes.templatesTransactions} />;
    }
  };
  return <PageWrapper>{getPageByRoute(path)}</PageWrapper>;
};

Templates.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};
