import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { SearchInput } from 'components-antd';
import { MultiSelect } from 'components/Icons';
import { setTaskTemplatesQueryAction } from 'store/actions/templates';

import styles from './styles.module.scss';

interface TaskFilterProps {
  allowMultiSelect?: boolean;
  updateMultiSelect?: (...params) => void;
  multiSelectActive?: boolean;
}

export const TaskFilter = ({
  allowMultiSelect,
  multiSelectActive,
  updateMultiSelect,
}: TaskFilterProps) => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  const onQueryChange = (value) => {
    setQuery(value);
    dispatch(setTaskTemplatesQueryAction(value));
  };

  useEffect(() => {
    return () => {
      dispatch(setTaskTemplatesQueryAction(''));
    };
  }, []);

  return (
    <div className={styles.filterWrap}>
      <SearchInput
        value={query}
        className={classNames(
          {
            [styles.filterInputShort]: allowMultiSelect,
          },
          styles.searchInput,
        )}
        onChange={(e) => onQueryChange(e.target.value)}
        maxLength={100}
      />

      {allowMultiSelect && (
        <div
          className={classNames(styles.multiSelect, {
            [styles.multiSelectActive]: multiSelectActive,
          })}
          onClick={updateMultiSelect}
        >
          <MultiSelect className={classNames({ [styles.activeIcon]: multiSelectActive })} />
        </div>
      )}
    </div>
  );
};
