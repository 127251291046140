import React from 'react';

export const DetailsIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_67732_27948)">
        <path
          d="M7.00013 16.9999C5.34323 16.9999 4.00007 15.6567 4.00016 13.9998L4.00058 5.99986C4.00067 4.34306 5.34379 3.00001 7.00059 3.00001L12.9999 3.00004C14.6568 3.00005 16 4.34325 15.9999 6.00013C15.9999 8.66674 15.9998 11.3334 15.9998 14C15.9998 15.6568 14.6567 17 12.9998 17L7.00013 16.9999Z"
          stroke="#4673D1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.5 7H7.5" stroke="#4673D1" strokeWidth="2" strokeLinecap="round" />
        <path d="M12.5 10H7.5" stroke="#4673D1" strokeWidth="2" strokeLinecap="round" />
        <path d="M10 13H7.5" stroke="#4673D1" strokeWidth="2" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_67732_27948">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
