import { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Input, InputLabel, RichTextEditor, Wrapper, isContentEmpty } from 'components';
import { Button } from 'components-antd';
import { Checkbox } from 'antd';
import { routes } from 'settings/navigation/routes';
import {
  createEmailTemplate,
  editEmailTemplate,
  getEmailTemplateDetails,
} from 'api/templates/email';
import { showErrorMessage, showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';

export const TemplateContent = ({ isEditMode = false }) => {
  const history: any = useHistory();
  const params: any = useParams();

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isPublishedToTeam, setIsPublishedToTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailTemplateLoading, setEmailTemplateLoading] = useState(false);

  useEffect(() => {
    if (params?.id) {
      const state = history?.location?.state;
      const { body, subject, isPublishedToTeam } = state || {};
      setBody(body);
      setSubject(subject);
      setIsPublishedToTeam(isPublishedToTeam);
      fetchEmailTemplateDetails();
    }
  }, []);

  const fetchEmailTemplateDetails = async () => {
    setEmailTemplateLoading(true);
    try {
      const resp = await getEmailTemplateDetails(params?.id);
      const emailTemplate = resp?.data?.value;
    } catch (e) {
      showErrorMessage(e);
      setEmailTemplateLoading(false);
    } finally {
      setEmailTemplateLoading(false);
    }
  };

  const saveBtnClick = async () => {
    setLoading(true);

    const payload = {
      Subject: subject,
      Body: body,
      PublishToTeam: isPublishedToTeam,
      Variables: [],
    };

    try {
      isEditMode
        ? await editEmailTemplate({ id: params?.id, ...payload })
        : await createEmailTemplate(payload);
    } catch (e) {
      showErrorMessage(e);
      setLoading(false);
    } finally {
      showSuccessMessage(`Successfully ${isEditMode ? 'updated' : 'created'} Email Template`);
      routeToEmails();
      setLoading(false);
    }
  };

  const routeToEmails = () => history.push(routes.templatesEmail);

  const isDisabledSaveBtn = useMemo(
    () => isContentEmpty(body) || !subject?.trim()?.length,
    [body, subject],
  );

  return (
    <Wrapper isPending={emailTemplateLoading}>
      <div className={classNames(styles.emailTemplateHeader)}>
        <h2 className={styles.headerText}>{isEditMode ? 'Edit' : 'New'} Email Template</h2>
      </div>
      <div className={styles.templateContent}>
        <InputLabel label="Subject" showAsterisk />
        <Input
          variant={Input.LIGHT_ROUND}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          maxLength={255}
        />
        <InputLabel label="Body" showAsterisk className={styles.marginTop} />
        <RichTextEditor
          value={body}
          placeholder="Write something..."
          onChange={(value) => setBody(value)}
          className={styles.bodyContainer}
        />
        <Checkbox
          onChange={(e) => setIsPublishedToTeam(e.target.checked)}
          className={classNames(styles.checkbox, 'mosaikCheckbox')}
          value={isPublishedToTeam}
          checked={isPublishedToTeam}
        >
          Publish To Team
        </Checkbox>
      </div>
      <div className={styles.templateFooter}>
        <Button variant="hollow-bordered" className={styles.footerBtn} onClick={routeToEmails}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          className={classNames(styles.footerBtn, { [styles.disabled]: isDisabledSaveBtn })}
          loading={loading}
          onClick={saveBtnClick}
          disabled={isDisabledSaveBtn}
        >
          Save
        </Button>
      </div>
    </Wrapper>
  );
};
