export const getAddressFormattedCassAddress = (
  cassAddress: any,
): { address1: string; address2: string } => {
  if (!cassAddress) return { address1: '', address2: '' };
  // Extract values from the components of the Cass address
  const streetNumber = cassAddress?.components?.primary_number;
  const streetName = cassAddress?.components?.street_name;
  const streetPostDirection = cassAddress?.components?.street_postdirection
    ? ` ${cassAddress?.components?.street_postdirection}`
    : '';
  const streetSuffix = cassAddress?.components?.street_suffix
    ? ` ${cassAddress?.components?.street_suffix}`
    : '';
  const city = cassAddress?.components?.city_name;
  const state = cassAddress?.components?.state_abbreviation;
  const { zipcode } = cassAddress.components;

  // Construct address1 (StreetNumber + StreetName + optional StreetSuffix + optional StreetPostDirection)
  const address1 = `${streetNumber} ${streetName}${streetSuffix}${streetPostDirection}`.trim();

  // Construct address2 (City, State Zipcode)
  const address2 = `${city}, ${state} ${zipcode}`;

  // Return the formatted addresses
  return { address1, address2 };
};
