import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { keys } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AuditLogCard } from 'components-antd/AuditLogCard';
import { activityLogsPageSizeForInMemorySearch } from 'app-constants/activityLogs';
import { Spinner } from 'components';
import { getActivityLogs } from 'api/activityLog';
import { getTaskCategoriesEffect } from 'store/effects/transactions';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';
import { LocalHeader } from '../../LocalHeader';
import { Badge, Popover } from 'components-antd';
import { Icons } from '../../Icons';
import { FilterPopover } from '../FilterPopover';
import { ActivityLogsType, ActivityLogsTypesValue } from 'types/activityLogs';
import { getRelatedEntitiesCompleteListState } from 'store/selectors/relatedEntities';
import { relatedEntitiesCompleteListGetDataEffect } from 'store/effects/relatedEntities';
import { formatLogsData } from 'utils/activityLogsHelper';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FilterSections } from '../FilterSections/FilterSections';

export const TransactionActivityLogSection: React.FC<any> = () => {
  const now = moment().utcOffset() / 60;
  const params = useParams();
  const initializeFilter = {
    ActivitiesType: ActivityLogsType.Transaction,
    PropertiesTransactionIds: [Number(params['id'])],
    timeZoneOffset: now,
    Order: 'desc',
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [fieldsPayload, setFieldsPayload] = useState<any>(initializeFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [logItems, setLogItems]: any[] = useState([]);
  const [searchables, setSearchables]: any[] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formatted, setFormatted] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});

  const dispatch = useDispatch();
  const pageSize = activityLogsPageSizeForInMemorySearch;

  const [prevY, setPrevY] = useState(0);
  let logsRef: any = useRef([]);

  let loadingRef: any = useRef(null);
  let prevYRef = useRef({});
  let pageRef: any = useRef({});
  let totalItemsRef: any = useRef({});
  let fieldsRef = useRef({});
  logsRef.current = [];
  pageRef.current = pageNumber;
  prevYRef.current = prevY;
  totalItemsRef.current = totalItems;
  fieldsRef.current = fieldsPayload;

  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const relatedEntities = useSelector(getRelatedEntitiesCompleteListState);

  const handleObserver = (entities) => {
    const y = entities[0].boundingClientRect.y;
    if (prevYRef.current > y) {
      if (pageRef.current <= Math.ceil(totalItemsRef.current / pageSize)) {
        getLogs();
      }
    }
    setPrevY(y);
  };

  useEffect(() => {
    getLogs();
    dispatch(getTaskCategoriesEffect());
    dispatch(relatedEntitiesCompleteListGetDataEffect());

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    observer.observe(loadingRef?.current);
  }, []);

  useEffect(() => {
    setFormatted(formatLogsData(logItems, { taskGroup: true, categories: categories }));
  }, [logItems]);

  const getLogs = async () => {
    setIsLoading(true);
    try {
      const { data } = await getActivityLogs(
        fieldsRef.current,
        `?Page=${pageRef.current}&PerPage=${pageSize}`,
      );
      if (data?.result) {
        let items = data?.result?.items || [];
        if (pageRef.current === 1) {
          setLogItems((prevList) => {
            return [...items];
          });
          setSearchables(items);
        } else {
          setLogItems((prevList) => {
            return [...prevList, ...items];
          });
          setSearchables((prevList) => {
            return [...prevList, ...items];
          });
        }
        setPageNumber(pageRef.current + 1);
        setTotalItems(data?.result?.total);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const resetToPageOneWithOrWithoutFilter = (payload?: any) => {
    setFieldsPayload(payload || initializeFilter);
    fieldsRef.current = payload || initializeFilter;
    pageRef.current = 1;
    setPageNumber(1);
    getLogs();
  };

  const doneHandler = ({ userIds, tags, activityTypes, from, to, showOverdueTask }) => {
    const payload = {
      ...fieldsPayload,
      UserIds: userIds,
      CategoryIds: tags,
      ...(activityTypes && activityTypes.length > 0 && { ActionTypes: activityTypes }),
      FromTimestamp: from,
      ToTimestamp: to,
      ...(showOverdueTask && { ActionTypes: [ActivityLogsTypesValue.TaskOverdue] }),
    };
    setAppliedFilters({
      userIds,
      tags,
      activityTypes,
      from,
      to,
      showOverdueTask,
    });
    resetToPageOneWithOrWithoutFilter(payload);
  };

  const getRegExForInsensitiveGlobalMatch = (str: string) => new RegExp(str, 'gi');

  const inMemorySearch = (value: string) => {
    let items = [];
    if (value && value.length > 2) {
      items = searchables.filter((log: any) => {
        let result = false;
        const actionType = log?.ActionType;
        const title =
          log?.TagLine?.TaskName || log?.TagLine?.MilestoneName || log?.TagLine?.DocumentName;
        const userName = `${log?.UserFirstName} ${log?.UserLastName}`;
        const transactionPrevStatus = log?.TagLine?.PreviousStatus;
        const transactionNewStatus = log?.TagLine?.NewStatus;
        const address = log?.TagLine?.Transaction
          ? log?.TagLine?.Transaction?.Address?.PlaceName
          : `${log?.TagLine?.Address?.Line1}, ${log?.TagLine?.Address?.City}, ${log?.TagLine?.Address?.State}`;
        if (
          actionType?.match(getRegExForInsensitiveGlobalMatch(value)) ||
          title?.match(getRegExForInsensitiveGlobalMatch(value)) ||
          userName?.match(getRegExForInsensitiveGlobalMatch(value)) ||
          transactionPrevStatus?.match(getRegExForInsensitiveGlobalMatch(value)) ||
          transactionNewStatus?.match(getRegExForInsensitiveGlobalMatch(value)) ||
          address?.match(getRegExForInsensitiveGlobalMatch(value))
        ) {
          result = true;
        }
        return result;
      });
      setLogItems(items);
    } else {
      setLogItems(searchables);
    }
  };

  const filterPopoverContent = () => 'Transaction Activity Filters';

  const renderFilterIcon = (
    <Popover content={filterPopoverContent} placement="topLeft">
      <div className={styles.btnFilterPopover}>
        <Badge>
          <Icons variant={'filterSecondary'} className={styles.filterIcon} />
        </Badge>
      </div>
    </Popover>
  );

  const renderFilter = () => (
    <FilterSections
      onDone={doneHandler}
      onReset={() => {
        setAppliedFilters({});
        resetToPageOneWithOrWithoutFilter();
      }}
      taskCategories={categories}
      relatedEntities={relatedEntities}
      appliedFilters={appliedFilters}
    />
  );

  return (
    <div className={styles.transactionActivityLogSection}>
      <LocalHeader Filter={renderFilter} onChangeSearch={inMemorySearch} />
      <div>
        {formatted.length > 0 &&
          formatted.map((item, idx) => {
            return (
              <AuditLogCard
                key={idx}
                activityLogs={item[`${keys(item)}`]}
                DayAndDate={keys(item)[0]}
                activityLogCardClassNameSecondary={styles.transactionActivityLogCard}
                avatarWrapperClassName={styles.transactionActivityLogCardAvatorWrapper}
                timelineItemWrapperClassName={styles.transactionActivityLogCardTimelineItem}
                showActivityTag={true}
                taskCategories={categories}
              />
            );
          })}
      </div>

      <div ref={loadingRef} className={styles.loader}>
        <span style={{ display: isLoading ? 'block' : 'none' }}>
          <Spinner />
        </span>
      </div>
    </div>
  );
};
