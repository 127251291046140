import Api from 'store/effects/core/api';
import {
  requestClientDashboardNewMatchesAction,
  requestClientDashboardAgentSuggestionsAction,
  requestClientDashboardAgentCommentsAction,
  requestClientDashboardOpenHousesAction,
  requestClientDashboardPriceReductionsAction,
  requestClientDashboardBackOnMarketsAction,
  requestClientDashboardMyTransactionsAction,
  requestClientDashboardUpcomingShowingsAction,
  requestClientDashboardNeedsAttentionAction,
  requestClientDashboardAddressesValuationAction,
} from 'store/actions/clientDashboard';
import {
  getClientDashboardNewMatchesApi,
  getClientDashboardAgentSuggestionsApi,
  getClientDashboardAgentCommentsApi,
  getClientDashboardBackOnMarketApi,
  getClientDashboardOpenHousesApi,
  getClientDashboardPriceReductionsApi,
  getClientDashboardMyTransactionsApi,
  getClientDashboardUpcomingShowingsApi,
  getClientDashboardNeedsAttentionApi,
} from 'api/dashboard/ClientDashboardStats';
import { getClientAddressesValuation } from 'api/dashboard';

export const getClientDashboardNewMatchesEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardNewMatchesAction,
    method: getClientDashboardNewMatchesApi,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardAgentSuggestionsEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardAgentSuggestionsAction,
    method: getClientDashboardAgentSuggestionsApi,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardAgentCommentsEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardAgentCommentsAction,
    method: getClientDashboardAgentCommentsApi,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardOpenHousesEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardOpenHousesAction,
    method: getClientDashboardOpenHousesApi,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardPriceReductionsEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardPriceReductionsAction,
    method: getClientDashboardPriceReductionsApi,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardBackOnMarketEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardBackOnMarketsAction,
    method: getClientDashboardBackOnMarketApi,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardMyTransactionsEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardMyTransactionsAction,
    method: getClientDashboardMyTransactionsApi,
  };
  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardUpcomingShowingsEffect = (cfg, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardUpcomingShowingsAction,
    method: getClientDashboardUpcomingShowingsApi,
  };
  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardNeedsAttionEffect = (cfg = {}, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardNeedsAttentionAction,
    method: getClientDashboardNeedsAttentionApi,
  };
  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getClientDashboardAddressesValuationEffect = (cfg = {}, options: any = {}, cb?) => {
  const requestParams = {
    action: requestClientDashboardAddressesValuationAction,
    method: getClientAddressesValuation,
  };
  const sendRequest = options.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);
  return sendRequest(cfg, options, cb);
};
