import { useHistory } from 'react-router-dom';

const useBackPage = ({ defaultBackUrl, state = {} }) => {
  const history = useHistory();

  const onBack = (event) => {
    event?.preventDefault?.();

    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(defaultBackUrl, { ...state });
    }
  };

  return [onBack];
};

export default useBackPage;
