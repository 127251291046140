import styles from './styles.module.scss';
import { validateEmail } from 'utils';
import { Input, InputLabel } from 'components';
import { Cross } from 'components/Icons';
import {
  AddVendorPayloadType,
  VendorValidationErrors,
} from 'pages/ServicesCategory/components/AddVendorModal/constants';
import classNames from 'classnames';

type PointOfContactPropsTYpe = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
  errors: VendorValidationErrors;
  setErrors: Function;
};

const PointOfContact = (props: PointOfContactPropsTYpe) => {
  const { newVendorPayload, setNewVendorPayload, errors, setErrors } = props;

  return (
    <>
      <div className={styles.detailField}>
        <InputLabel label="Name" />
        <Input
          className={classNames(
            styles.input,
            newVendorPayload.PointOfContactName ? styles.inputShow : styles.inputHide,
          )}
          placeholder={'Enter name'}
          onChange={(e, val) => {
            setNewVendorPayload((prev) => {
              return { ...prev, PointOfContactName: val };
            });
          }}
          value={newVendorPayload.PointOfContactName}
          error=""
          errorClassName={styles.inputError}
          variant={Input.FULL}
          removeHandler={() => {
            setNewVendorPayload((prev) => {
              return { ...prev, PointOfContactName: '' };
            });
          }}
          removeIcon={<Cross color="#747475" />}
        />
      </div>
      <div className={styles.detailField}>
        <InputLabel label="Email" />
        <Input
          className={styles.input}
          placeholder={'Enter email'}
          onBlur={(e) => {
            const value = e.target.value;
            if (value && !validateEmail(value.trim()))
              setErrors((prev) => {
                return { ...prev, POCEmailError: 'Enter valid email' };
              });
            else if (value?.length > 255) {
              setErrors((prev) => {
                return { ...prev, POCEmailError: 'Max allowed characters: 255' };
              });
            } else
              setErrors((prev) => {
                return { ...prev, POCEmailError: '' };
              });
          }}
          onChange={(e, val) => {
            setNewVendorPayload((prev) => {
              return { ...prev, PointOfContactEmail: val.trim() };
            });
          }}
          value={newVendorPayload.PointOfContactEmail}
          error=""
          errorClassName={styles.inputError}
        />
        {errors.POCEmailError ? (
          <div className={styles.validation}>{errors.POCEmailError}</div>
        ) : null}
      </div>
    </>
  );
};

export default PointOfContact;
