import classNames from 'classnames';
import { Check, Copy } from 'components/Icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'components-antd';
import { Button, RichTextEditor } from 'components';
import Trash from 'components/Icons/Trash';

import styles from './styles.module.scss';

interface EmailTemplateModalType {
  subjectText: string | undefined;
  bodyText: string | undefined;
  open: boolean;
  onClose: () => void;
  onRemove?: () => void;
}

export const EmailTemplateModal = ({
  subjectText = '',
  bodyText = '',
  open,
  onClose,
  onRemove,
}: EmailTemplateModalType) => {
  const [subjectCopied, setSubjectCopied] = useState<boolean>(false);
  const [bodyCopied, setBodyCopied] = useState<boolean>(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const timeoutHandler = useCallback(() => {
    setSubjectCopied(false);
    setBodyCopied(false);
    if (timeoutId.current) clearTimeout(timeoutId.current);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  return (
    <Modal
      width={675}
      footer={null}
      maskClosable={false}
      onCancel={onClose}
      destroyOnClose
      open={open}
      className={styles.emailTemplateModal}
    >
      <header className={styles.modalHeading}>Email Template</header>
      <div className={styles.emailTemplate}>
        <div className={styles.section}>
          <div className={styles.header}>
            <div className={styles.heading}>Subject</div>
            <div
              className={subjectCopied ? styles.copied : styles.copy}
              onClick={() => {
                navigator.clipboard.writeText(subjectText);
                timeoutId.current = setTimeout(timeoutHandler, 3000);
                setSubjectCopied(true);
              }}
            >
              {subjectCopied ? <Check color="#04a451" /> : <Copy color="#747475" />}
              <span>{subjectCopied ? 'Copied' : 'Copy'}</span>
            </div>
          </div>
          <div className={styles.note}>{subjectText}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.header}>
            <div className={styles.heading}>Body</div>
            <div
              className={bodyCopied ? styles.copied : styles.copy}
              onClick={() => {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = bodyText; // Convert HTML string to DOM
                const plainText: any = tempDiv.innerText || tempDiv.textContent; // Extract text

                navigator.clipboard.writeText(plainText);
                timeoutId.current = setTimeout(timeoutHandler, 3000);
                setBodyCopied(true);
              }}
            >
              {bodyCopied ? <Check color="#04a451" /> : <Copy color="#747475" />}
              <span>{bodyCopied ? 'Copied' : 'Copy'}</span>
            </div>
          </div>
          <RichTextEditor value={bodyText} disabled onChange={undefined} />
        </div>
      </div>
      <footer
        className={classNames(styles.emailTemplateFooter, { [styles.flexCenter]: !onRemove })}
      >
        {onRemove && (
          <div className={styles.removeEmailTemplate} onClick={onRemove}>
            <Trash width={16} height={16} stroke={'#EC455E'} strokeWidth={'3'} />
            <span className={styles.removeBtnText}>Remove</span>
          </div>
        )}
        <Button title={'Done'} className={styles.okBtn} onClick={onClose} />
      </footer>
    </Modal>
  );
};
