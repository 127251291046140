import { useDispatch, useSelector } from 'react-redux';

import {
  getDynamicFormSelector,
  getDynamicFormSignatureConfigSelector,
} from 'store/selectors/requestFormProcess';
import { FormDocumentQuestionType, FormDocumentQuestionsType } from 'types';
import { DYNAMIC_QUESTION_TYPE, getDynamicFormColor } from 'app-constants';
import { updateDynamicFormActiveQuestionEffect } from 'store/effects/formProcess';
import { SignatureField, TextField } from './Fields';
import { getCohesiveFieldAnswers } from './Fields/helper';
import { textFieldsType, signatureFieldType } from './Fields/types';

interface FieldRendererProps {
  question: FormDocumentQuestionType;
  disabled?: boolean;
  isCohesiveFlow?: boolean;
  otherSignatoryField?: boolean;
  documentIndex?: number;
  pageQuestions?: FormDocumentQuestionsType;
}

export const FieldRenderer = ({
  question,
  disabled,
  documentIndex = -1,
  isCohesiveFlow,
  otherSignatoryField,
  pageQuestions,
}: FieldRendererProps) => {
  const dispatch = useDispatch();

  const fieldDisabled = disabled || question.disabled;

  const {
    selectedFieldUUID: fieldUUID,
    responses: answers,
    allFormRoles,
    hideQuestionRoles,
    activeQuestionUUID,
  } = useSelector(getDynamicFormSignatureConfigSelector);

  const { bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);

  let responses = isCohesiveFlow ? getCohesiveFieldAnswers(bundleDocumentsDetails) : answers;

  let selectedFieldUUID = activeQuestionUUID || fieldUUID;

  const isHighlighted = question.UUID === selectedFieldUUID;

  const answer = responses[question.UUID]?.Answer || '';

  const field = question?.Fields?.[0];
  const widget = field?.widgets?.[0];

  const color = getDynamicFormColor(allFormRoles, question);

  if (!widget) return <></>;

  const handleFieldClick = (e) => {
    if (!isHighlighted) {
      dispatch(
        updateDynamicFormActiveQuestionEffect({
          uuid: question.UUID,
          documentIndex,
          isCohesiveFlow,
        }),
      );
    }
  };

  const renderField = () => {
    const questionType = question.Type as DYNAMIC_QUESTION_TYPE;
    if (textFieldsType.includes(questionType)) {
      return (
        <TextField
          question={question}
          answer={answer}
          color={color}
          disabled={fieldDisabled}
          isHighlighted={isHighlighted}
          handleFieldClick={handleFieldClick}
          documentIndex={documentIndex}
          isCohesiveFlow={isCohesiveFlow}
          otherSignatoryField={otherSignatoryField}
          pageQuestions={pageQuestions}
          responses={responses}
        />
      );
    } else if (signatureFieldType.includes(questionType)) {
      return (
        <SignatureField
          question={question}
          answer={answer}
          color={color}
          disabled={fieldDisabled || otherSignatoryField}
          isOtherSignatory={otherSignatoryField}
          isHighlighted={isHighlighted}
          documentIndex={documentIndex}
          isCohesiveFlow={isCohesiveFlow}
        />
      );
    }

    return <></>;
  };

  if (
    question?.CustomPermissions?.[0] &&
    hideQuestionRoles?.includes(question?.CustomPermissions?.[0])
  ) {
    return <></>;
  }

  return <>{renderField()}</>;
};
