import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getDataFor } from 'store/helpers';
import { cloneDeep, get } from 'lodash-es';

import {
  requestGetListingDetailByIdAction,
  requestToggleFavoriteListingAction,
  listingDetailToggleFavoriteListingAction,
} from 'store/actions/listingDetail';
import { appLogoutAction } from 'store/actions/app';
import { requestSendSharePropsToRecipientsAction } from 'store/actions/feed';

const initialData = {
  favoriteState: IDLE,
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetListingDetailByIdAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: getDataFor('meta', payload, initialData),
    }),
    [requestToggleFavoriteListingAction]: (state, { payload }) => ({
      ...state,
      favoriteState: payload.state,
    }),
    [listingDetailToggleFavoriteListingAction]: (state, { payload }) => ({
      ...state,
      data: {
        ...state?.data,
        IsFavorite: !!payload?.flag,
      },
    }),
    [requestSendSharePropsToRecipientsAction]: (state, { payload }) => {
      const updatedData = cloneDeep(state?.data);
      if (payload.state === 'ready' && payload?.meta && updatedData) {
        const { ClientIds } = payload.meta;
        const clients = payload?.options?.clients?.data
          .filter((client) => ClientIds.includes(client.Id))
          ?.map((data) => ({
            FirstName: data?.FirstName,
            LastName: data?.LastName,
            Id: data?.Id,
            CreatedDate: new Date(),
          }));
        updatedData.RecommendedTo = clients;
        return {
          ...state,
          data: updatedData,
        };
      } else return state;
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
