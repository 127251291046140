import classNames from 'classnames';

import { ClientInput, ClientRole } from 'components/Transactions';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';
import { Role } from 'app-constants';

import styles from './styles.module.scss';
import { Cross } from 'components/Icons';
import { MemberInput } from '../../TransactionMembers/ExternalMember/components';

interface ParticipantProps {
  value: ValueObject;
  participants?: ValueObject[];
  isParInfo: boolean;
  isAssignRoleInfo: boolean;
  isAllowDuplicates?: boolean;
  allowUser?: boolean;
  onDelete: (...args) => void;
  onChangeClient: (...args) => void;
  onChangeRole: (...args) => void;
  onBlurClientInput: (...args) => void;
  onRoleKeyPress: (...args) => void;
  onRemoveData: (...args) => void;
}

const Participant = ({
  value,
  participants,
  isParInfo,
  isAssignRoleInfo,
  isAllowDuplicates = false,
  allowUser = false,
  onDelete,
  onChangeClient,
  onChangeRole,
  onBlurClientInput,
  onRoleKeyPress,
  onRemoveData,
}: ParticipantProps) => {
  const deleteIcon = (
    <Icon
      onClick={onDelete}
      className={styles.iconDelete}
      variant={Icon.MINUS}
      testid="close_icon"
    />
  );

  return (
    <div className={styles.participantWrap}>
      <div className={styles.participant}>
        <div className={styles.clientInputWrap}>
          <p className={styles.inputLabel}>{value?.customName ? 'First Name' : 'Name'}</p>
          <ClientInput
            onBlur={onBlurClientInput}
            onChange={onChangeClient}
            value={value}
            dropdownContainer={classNames(styles.dropdownWrap, {
              [styles.customNameDropdown]: value?.customName,
            })}
            excludedParticipantIds={
              isAllowDuplicates
                ? []
                : participants
                    ?.filter((p) => p.id !== value.id)
                    .filter((p) => p.id !== undefined)
                    .map((p) => p.id as number)
            }
            className={classNames(styles.client)}
            placeholder=" "
            allowUser={allowUser}
            excludeLastName
          />
          {value?.firstName ? (
            <Cross color="#262626" className={styles.removeButton} onClick={onRemoveData} />
          ) : null}
        </div>

        {value?.customName && (
          <div className={styles.clientInputWrap}>
            <p className={styles.inputLabel}>{'Last Name'}</p>
            <MemberInput
              value={value.lastName}
              placeholder="Last Name"
              onUpdate={() => {}}
              onBlurInput={(inputValue) =>
                onBlurClientInput(`${value.firstName} ${inputValue.trim()}`)
              }
              inputHolderClassName={styles.customName}
              className={classNames(styles.memberInput)}
            />
          </div>
        )}
      </div>

      <div className={styles.roleInputView}>
        <p className={styles.inputLabel}>Role</p>
        <ClientRole
          className={styles.clientRole}
          wrapperClassName={styles.inputWrapper}
          arrowIconClassName={styles.arrowdownicon}
          onChange={onChangeRole}
          onKeyPress={onRoleKeyPress}
          value={value}
          isParInfo={isParInfo}
          isAssignRoleInfo={isAssignRoleInfo}
          isSearchIcon={false}
        />
      </div>
      {deleteIcon}
    </div>
  );
};

export default Participant;
