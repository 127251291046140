import { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getCurrentContextSelector } from 'store/selectors/context';

import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { openShareDrawerEffect, setRecipientsEffect, setSharePropertyEffect } from 'store/effects';
import { requestScheduleTourEffect } from 'store/effects/listingDetail';
import { Button } from 'components';
import { DocImage, Messages, Calendar } from 'components/Icons';
import { Icons } from 'pages/Properties/common/Icons';
import { socketsGetMessagesByThreadIdEffect } from 'store/effects/sockets/messages';
import { getIsCommentsDrawerOpenSelector } from 'store/selectors/drawers/comments';
import { getHomeInfoSelector } from './selectors';
import HomeActionsAddToFavorites from './AddToFavorites';
import { getCountThreadsByPropertyId } from 'store/selectors/sockets/threads';
import { showSuccessMessage } from 'helpers/success';

import styles from './styles.module.scss';
import { MyLibraryModal } from 'pages/Workshop/Forms/components/MyLibraryModal';
import { getFormattedListingDetailData } from 'store/selectors/listingDetail';
import { isArray, uniqBy } from 'lodash-es';

const HomeActions = ({
  className,
  price,
  addressLine1,
  addressLine2,
  showFormLibraryModal,
  setShowFormLibraryModal,
  onTourSchedule,
  isTourScheduled,
  address,
  recommendedTo,
  isFetchingTourStatus,
}) => {
  const dispatch = useDispatch();
  const context = useSelector(getCurrentContextSelector);
  const [isLoading, setIsLoading] = useState(false);

  const [fixedStyles, setFixedStyles] = useState(false);

  const { isClient, propertyInfo } = useSelector(getHomeInfoSelector);
  const { threads } = useSelector((state) =>
    getCountThreadsByPropertyId(state, { propertyId: propertyInfo?.Id }),
  );
  const commentsDrawerOpened = useSelector(getIsCommentsDrawerOpenSelector);
  const propertyThreads = threads || [];
  const isUnreadThread = propertyThreads.some((thread) => !!thread?.IsUnread);
  const { activityStatus } = useSelector(getFormattedListingDetailData);
  const isPropertyClosed = useMemo(() => {
    return activityStatus?.trim()?.toLowerCase() === 'closed';
  }, [activityStatus]);

  const countThreads = uniqBy(propertyThreads, 'Id').reduce((count, { PropertyComments }) => {
    return (
      count +
        PropertyComments.filter(
          ({ IsDeleted, MessageMeta }) =>
            !IsDeleted &&
            (isArray(MessageMeta)
              ? MessageMeta?.some(({ EntityId }) => EntityId === propertyInfo?.Id)
              : MessageMeta.EntityId === propertyInfo?.Id),
        )?.length || 0
    );
  }, 0);

  const onComment = () => {
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openCommentsDrawerEffect({ open: true }));
  };
  useEffect(() => {
    if (isClient && countThreads === 1) {
      dispatch(setSharePropertyEffect(propertyInfo));
      const propertyThreadId = propertyThreads?.[0]?.Id;
      if (propertyThreadId) {
        dispatch(socketsGetMessagesByThreadIdEffect({ Id: propertyThreadId }));
      }
    }
  }, [countThreads, commentsDrawerOpened]); // eslint-disable-line
  const onScheduleTour = () => {
    setIsLoading(true);
    let agentId = context.Agents.map((agent) => agent.Id);
    dispatch(
      requestScheduleTourEffect({ propertyInfo, agentId }, null, (error, response) => {
        if (response?.status === 200) {
          onTourSchedule();
          showSuccessMessage('Tour Request Sent');
          setIsLoading(false);
        }
      }),
    );
  };

  useEffect(() => {
    const listenToScroll = () => {
      setFixedStyles(window.scrollY >= 700);
    };

    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const onShare = useCallback(() => {
    const recipients = recommendedTo?.map((item) => ({
      Id: item.Id,
      Name: `${item.FirstName} ${item.LastName}`,
      CreatedDate: item.CreatedDate,
    }));
    dispatch(setRecipientsEffect(recipients ?? []));
    dispatch(setSharePropertyEffect(propertyInfo));
    dispatch(openShareDrawerEffect({ open: true }));
  }, [propertyInfo, dispatch]);

  return (
    <>
      {showFormLibraryModal ? (
        <MyLibraryModal
          open={showFormLibraryModal}
          onClose={() => setShowFormLibraryModal(false)}
          propertyInfo={{
            address: {
              ...propertyInfo?.Address,
              PlaceName: address,
              Line2: addressLine2,
            },
          }}
        />
      ) : (
        <></>
      )}
      <div
        testid="home_actions"
        className={classNames(styles.homeActionsContainer, className)}
        style={
          fixedStyles
            ? {
                position: 'fixed',
                top: '100px',
                width: '290px',
              }
            : {}
        }
      >
        <p testid="price" className={styles.price}>
          {price && price !== '0' && price !== '$0' && price !== 0 ? price : 'Price Unavailable'}
        </p>
        <p testid="address_1" className={styles.address}>
          {addressLine1}
        </p>
        <p testid="address_2" className={styles.address}>
          {addressLine2}
        </p>
        <div className={styles.buttonsContainer}>
          <Button
            onClick={onComment}
            className={styles.homeActionsButton}
            title={
              <>
                {countThreads ? (
                  <>
                    Comments <span className={styles.count}>({countThreads})</span>
                  </>
                ) : (
                  'Comment'
                )}
              </>
            }
            icon={
              <Messages
                variant="hollowSmall"
                className={classNames(styles.buttonIcon, styles.commentIcon, {
                  [styles.isUnread]: isUnreadThread,
                })}
              />
            }
            titleClassName={styles.buttonTitle}
            testid="comment"
          />
          {isClient ? <HomeActionsAddToFavorites /> : null}
          {!isClient ? (
            <Button
              onClick={onShare}
              className={classNames(styles.homeActionsButton, {
                [styles.recommended]: recommendedTo?.length,
              })}
              title={
                <>
                  {recommendedTo?.length ? (
                    <>
                      Highlighted <span className={styles.count}>({recommendedTo?.length})</span>
                    </>
                  ) : (
                    'Highlight'
                  )}
                </>
              }
              titleClassName={styles.buttonTitle}
              icon={
                <Icons
                  variant={Icons.STAR_2}
                  className={classNames(styles.buttonIcon, {
                    [styles.recommended]: recommendedTo?.length,
                  })}
                />
              }
              testid="share_with_client"
            />
          ) : null}
          {
            isClient ? (
              !isPropertyClosed ? (
                <Button
                  className={classNames(styles.homeActionsButton, styles.lastButton)}
                  title={isTourScheduled ? 'Tour Requested' : 'Request Tour'}
                  icon={<Calendar className={styles.buttonIcon} color={'#fff'} />}
                  titleClassName={styles.buttonTitle}
                  testid="schedule_tour"
                  onClick={onScheduleTour}
                  disabled={isTourScheduled || isLoading}
                  isPending={isFetchingTourStatus}
                />
              ) : null
            ) : null
            // <Button
            //   className={classNames(styles.homeActionsButton, styles.lastButton)}
            //   title="Initiate Offer"
            //   icon={
            //     <DocImage
            //       color="white"
            //       className={classNames(styles.buttonIcon, styles.clientsIcon, styles.docIcon)}
            //     />
            //   }
            //   titleClassName={styles.buttonTitle}
            //   testid="initiate_offer"
            //   onClick={() => setShowFormLibraryModal(true)}
            // />
          }
        </div>
      </div>
    </>
  );
};

HomeActions.propTypes = {
  className: PropTypes.string,
  virtualTour: PropTypes.string,
  price: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  isTourScheduled: PropTypes.bool,
  onTourSchedule: PropTypes.func,
  isFetchingTourStatus: PropTypes.bool,
};

HomeActions.defaultProps = {
  className: '',
  virtualTour: '',
  price: 0,
  addressLine1: '',
  addressLine2: '',
  isTourScheduled: true,
  onTourSchedule: () => {},
  isFetchingTourStatus: false,
};

export default HomeActions;
