import { getMlsDashboardStats } from 'api/admin/mlsDashboard';
import Api from 'store/effects/core/api';
import { requestGetMlsDashboardStatsAction } from 'store/actions/adminPanel';

export const getMlsDashboardStatsEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetMlsDashboardStatsAction,
    method: getMlsDashboardStats,
  };
  let sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, {}, cb);
};
