import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PageWrapper, Row, Button } from 'components-antd';
import { routes } from 'settings/navigation/routes';
import { ConciergeCategoriesList } from './components/ConciergeCategoriesList';
import { LocationModal } from './components/LocationModal';
import { requestGetQuotesEffect } from 'store/effects/quotes';
import { getQuotesDataSelector, getQuotesSelector } from 'store/selectors/quotes';
import { Search } from './components/Search';
import { quoteRequestStatus } from 'settings/constants/quotes';
import ServicesCategory from '../ServicesCategory';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { resetRequestQuoteAction } from 'store/actions/quotes';
import { LocationService } from 'services';
import styles from './styles.module.scss';
import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { CLIENT } from 'settings/constants/roles';
import { requestGetTeamListEffect } from 'store/effects';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import {
  updateConciergeCategoryConfiguration,
  updateConciergeLocationConfiguration,
  updateConciergeSelectedTab,
  updateConciergeServicesConfiguration,
} from 'store/actions/concierge';

const locationSrv = new LocationService();

export const Services = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isPending, isIdle } = useSelector(getQuotesSelector);
  const quotesData = useSelector(getQuotesDataSelector);
  const { category } = useSelector(getConciergeSearchSelector);
  const [isSearch, setIsSearch] = useState<boolean>(!!category);
  locationSrv.setLocation(location);
  const { categoryid } = locationSrv.getQuery();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const userRole = useSelector(getUserRoleSelector);
  const divRef = useRef<any>(null);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const maxDisplayQuotesCount = 99;

  const gotoMyQuotes = () => history.push(routes.servicesQuotes);

  useEffect(() => {
    const handleClick = (e) => {
      if (e?.target?.id === 'nav-id-/services') resetAllStates();
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (isIdle) {
      dispatch(requestGetQuotesEffect());
    } else {
      dispatch(requestGetQuotesEffect({}, { silent: true }));
    }
  }, []);

  useEffect(() => {
    if (categoryid) {
      dispatch(updateConciergeCategoryConfiguration(categoryid.toString()));
      setIsSearch(true);
    }

    if (userRole !== CLIENT) dispatch(requestGetTeamListEffect());

    return () => {
      resetAllStates();
    };
  }, []);

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'auto' });
  }, [category, multiple?.modeEnable]);

  const newQuotesCount = useMemo<number>(() => {
    return (
      quotesData?.filter(
        (quote) =>
          (quote.Status === quoteRequestStatus.PendingAnswer ||
            quote.Status === quoteRequestStatus.New) &&
          !quote.IsArchived,
      )?.length || 0
    );
  }, [quotesData]);

  const resetAllStates = (goingBack: boolean = false) => {
    setIsSearch(false);
    dispatch(updateConciergeCategoryConfiguration());
    dispatch(updateConciergeSelectedTab(0));
    dispatch(updateConciergeServicesConfiguration([]));
    dispatch(resetRequestQuoteAction());

    if (!goingBack && !isClient) {
      dispatch(updateConciergeLocationConfiguration([]));
    }
  };

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.mainPageContent} showFooter={!category}>
      <LocationModal />
      <div
        className={classNames(styles.headerRow, {
          [styles.headerRowWithoutBackBtn]: !isSearch,
        })}
        ref={divRef}
      >
        {isSearch && (
          <div className={styles.headerLeft}>
            <Button
              variant={'go-back'}
              onClick={() => resetAllStates(true)}
              className={styles.goBackButton}
              id="concierge-back-button"
            />
          </div>
        )}
        <div className={styles.headerTitle}>Concierge</div>
        <div className={styles.headerRight}>
          <div className={classNames(styles.myQuotes, 'show-cursor')} onClick={gotoMyQuotes}>
            <div>My Quotes</div>
            {!isPending && newQuotesCount > 0 && (
              <div className={styles.myQuotesCountWrapper}>
                <div>
                  {newQuotesCount > maxDisplayQuotesCount
                    ? `${maxDisplayQuotesCount}+`
                    : newQuotesCount}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Row justify="center">
        <Search isSearch={isSearch} setIsSearch={setIsSearch} />
      </Row>
      {isSearch ? (
        <ServicesCategory />
      ) : (
        <Row className={styles.resultRow}>
          <ConciergeCategoriesList setIsSearch={setIsSearch} />
        </Row>
      )}
    </PageWrapper>
  );
};
