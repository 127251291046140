import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { MinusRounded } from 'components/Icons';

import styles from './styles.module.scss';
import './styles.scss';

const Phone = (props) => {
  const {
    id,
    testid,
    name,
    label,
    value,
    onChange,
    onBlur,
    className,
    inputClassName,
    errorClassName,
    countryCodeEditable,
    removeHandler,
    replacePlaceholderWithLabelWhenHasValue,
    removeIcon,
  } = props;
  const { disabled, error, options, variant, placeholder } = props;
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChangeHandler = useCallback(
    (val, country) => {
      setInputValue(val);
      onChange({ target: { value: val, name } }, val, country);
    },
    [onChange, name],
  );

  const hasLabel =
    (label && !replacePlaceholderWithLabelWhenHasValue) ||
    ((label || placeholder) && replacePlaceholderWithLabelWhenHasValue && !!value);

  return (
    <div testid={testid} className={classNames(styles.inputWrapper, styles[variant], className)}>
      {hasLabel && <label htmlFor={id}>{label || placeholder}</label>}
      <PhoneInput
        inputClass={classNames(styles.phone, inputClassName)}
        buttonClass={styles.dropdownButton}
        onlyCountries={['us']}
        country="us"
        disableCountryCode={true}
        value={inputValue}
        onChange={onChangeHandler}
        disabled={disabled}
        autocompleteSearch
        placeholder={placeholder}
        countryCodeEditable={countryCodeEditable}
        disableDropdown
        onBlur={onBlur}
        {...options}
      />
      {!!removeHandler && (
        <button
          testid="remove_phone"
          className={styles.removeBtn}
          onClick={removeHandler}
          type="button"
        >
          {removeIcon || <MinusRounded />}
        </button>
      )}
      {error && (
        <div testid="validation" className={classNames(styles.error, errorClassName)}>
          {error}
        </div>
      )}
    </div>
  );
};

Phone.LIGHT = 'light';
Phone.FULL = 'full';
Phone.LIGHT_ROUND = 'lightRound';

Phone.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  removeHandler: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  replacePlaceholderWithLabelWhenHasValue: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.shape({}),
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  testid: PropTypes.string,
  removeIcon: PropTypes.element,
};

Phone.defaultProps = {
  id: undefined,
  name: undefined,
  className: '',
  inputClassName: '',
  errorClassName: '',
  label: undefined,
  removeHandler: null,
  onChange: () => {},
  onBlur: () => {},
  value: '',
  disabled: false,
  replacePlaceholderWithLabelWhenHasValue: false,
  countryCodeEditable: true,
  error: undefined,
  options: {},
  variant: Phone.FULL,
  placeholder: '(———) ——— ————',
  testid: undefined,
  removeIcon: undefined,
};

export default Phone;
