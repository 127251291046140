import React, { FC, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

interface Props {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
  alterViewPort?: boolean;
}

const ArrowLeft: FC<Props> = memo(
  ({ className = '', color = '#303030', width = 6, height = 10, alterViewPort = true }) =>
    useMemo(
      () => (
        <div className={className}>
          <svg
            width={width}
            height={height}
            viewBox={`0 0 ${alterViewPort ? width : 6} ${alterViewPort ? height : 10}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9L1 5L5 1"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      [className, color, width, height],
    ),
);

export default ArrowLeft;
