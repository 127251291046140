import { PageWrapper } from 'components-antd';
import styles from './styles.module.scss';
import { map } from 'lodash-es';
import { Icons } from './Icons';
import { routes } from 'settings/navigation/routes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { appManageClientDrawerAction } from 'store/actions/app';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import classNames from 'classnames';

const LottieRadarLink = 'https://lottie.host/e2712987-c213-49d1-8188-2a2432431110/fUf2m5OnKh.json';

export const Radar = () => {
  const dispatch = useDispatch();
  const isNotProd = window.location.hostname !== 'app.mosaik.io';

  const handleClientsClick = useCallback(
    () => dispatch(appManageClientDrawerAction(true)),
    [dispatch],
  );

  const handleLogsClick = useCallback(() => dispatch(push(routes.radarLogs)), [dispatch]);

  const handlePortfolioClick = useCallback(() => dispatch(push(routes.radarPortfolio)), [dispatch]);

  const handleKitsClick = useCallback(() => dispatch(push(routes.radarKits)), [dispatch]);

  const handleIntelClick = useCallback(() => dispatch(push(routes.intel)), [dispatch]);

  const handleSignalsClick = useCallback(() => dispatch(push(routes.radarSignals)), [dispatch]);

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.mainPageContent}>
      <p className={styles.heading}>Radar</p>
      <DotLottieReact src={LottieRadarLink} loop autoplay speed={0.3} />
      <div
        className={classNames(styles.content, {
          [styles.twoPerRow]: !isNotProd,
        })}
      >
        <div
          className={classNames(styles.cardsContainer, {
            [styles.twoPerRow]: !isNotProd,
          })}
        >
          {map(
            [
              ...(isNotProd
                ? [
                    { icon: Icons.SIGNALS, title: 'Signals', onClick: handleSignalsClick },
                    {
                      icon: Icons.INTEL,
                      title: 'Intel',
                      onClick: handleIntelClick,
                      disabled: true,
                    },
                    { icon: Icons.KITS, title: 'KITs', onClick: handleKitsClick },
                    { icon: Icons.PORTFOLIO, title: 'Portfolio', onClick: handlePortfolioClick },
                    { icon: Icons.CLIENTS, title: 'Clients', onClick: handleClientsClick },
                    { icon: Icons.LOGS, title: 'Logs', onClick: handleLogsClick },
                  ]
                : [
                    { icon: Icons.PORTFOLIO, title: 'Portfolio', onClick: handlePortfolioClick },
                    { icon: Icons.CLIENTS, title: 'Clients', onClick: handleClientsClick },
                    { icon: Icons.SIGNALS, title: 'Signals', onClick: handleSignalsClick },
                    { icon: Icons.KITS, title: 'KITs', onClick: handleKitsClick },
                  ]),
            ],
            ({ icon, title, onClick, disabled }) => (
              <div
                className={classNames(styles.card, { [styles.disabled]: disabled })}
                onClick={() => {
                  !disabled && onClick();
                }}
              >
                <Icons variant={icon} />
                {title}
                {disabled && <p className={styles.subtitle}>Coming soon</p>}
              </div>
            ),
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
