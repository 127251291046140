import { useEffect, useState } from 'react';

import { Modal, Row, Col } from 'components-antd';
import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';
import { getMlsStats } from 'api/admin/mlsDashboard';
import { formatDate } from 'helpers';

const StatsItem = ({ label, value }) => (
  <>
    <Col lg={12}>
      <span className={styles.label}>{label}:</span>
    </Col>
    <Col lg={12}>
      <span>{value}</span>
    </Col>
  </>
);

export const MLSStatsModal = ({ open, onClose, mls }) => {
  const [mlsStats, setMlsStats] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) fetchMlsStats();
  }, [open]);

  const fetchMlsStats = async () => {
    try {
      setLoading(true);
      const mlsStatsData = await getMlsStats(
        mls?.MlsId,
        mls?.baseMlsCode === 'bright' ? { state: mls?.State } : null,
      );
      setMlsStats(mlsStatsData?.data);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null} className={styles.mlsModal} width={500}>
      <p className={styles.header}>{mls?.MLSCode}</p>
      <PendingWrapper isPending={loading}>
        <div className={styles.content}>
          <Row gutter={24}>
            <StatsItem label="Total Listings" value={mlsStats?.totallistings} />
            <StatsItem label="Pending Listings" value={mlsStats?.pendinglistings} />
            <StatsItem label="Processed Listings" value={mlsStats?.processedlistings} />
            <StatsItem label="Failed Listings" value={mlsStats?.failedlistings} />
            <StatsItem label="Queued Listings" value={mlsStats?.queuelistings} />
            <StatsItem
              label="Latest Processed Date"
              value={formatDate(mlsStats?.latestprocesseddate, 'DD/MMM/YYYY hh:mm A')}
            />
            <StatsItem
              label="Latest Load Date"
              value={formatDate(mlsStats?.latestloaddate, 'DD/MMM/YYYY hh:mm A')}
            />
          </Row>
        </div>
      </PendingWrapper>
    </Modal>
  );
};
