import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Badge } from 'components-antd';
import { ArrowLeft } from 'components/Icons';
import Slider from 'react-slick';
import { FavourtiesHeader } from '../../../common';
import Close from 'components/Close';

import { routes } from 'settings/navigation/routes';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { appOpenModalEffect, resetClientFavoritesPropsEffect } from 'store/effects';
import { getIsFeedFilterDrawerOpenSelector, getSubFilterTypeSelector } from 'store/selectors/feed';

import CriteriaModal from 'pages/Properties/CriteriaModal';
import { getFeedCriteriaSelectorV3, getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import styles from './styles.module.scss';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import {
  selectedSearchAction,
  setCriteriaModeAction,
  setIsClientMatchesAction,
  setIsSimpleHeaderTitleVisibleAction,
} from 'store/actions/feedv3';
import { SearchBtn } from './components/SearchBtn';
import { SaveSearchBtn } from './../SaveSearchBtn';
import { LocationSearch } from 'pages/Properties/ClientSearch/LocationSearch';
import { EDIT } from 'settings/constants/mode';
import { useAgentSearchInstance } from '../../hooks/useAgentSearchInstance';
import { useLottie } from 'lottie-react';
import {
  All,
  BackToMarket,
  Comment,
  New,
  OpenHouse,
  PriceReduction,
  Status,
  Settings,
} from 'pages/Properties/LotiIcons';
import { Icons } from '../../../common';
import { isString } from 'lodash-es';

const subFilterTypes = {
  ALL: 'All',
  MATCHES: 'Matches',
  NEW: 'New',
  OPEN_HOUSES: 'OpenHouses',
  PRICE_REDUCTIONS: 'PriceReductions',
  BACK_ON_THE_MARKET: 'BackOnTheMarket',
  STATUS_CHANGES: 'StatusChanges',
  SUGGESTIONS: 'Suggestions',
  COMMENTS: 'Comments',
};

const TabBtnType = {
  subFilter: 'subFilter',
  tabFilter: 'tabFilter',
};

const CenterTabSectionsNewSearch = [
  {
    id: subFilterTypes.ALL,
    label: 'All',
    icon: All,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.NEW,
    label: 'New',
    icon: New,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.OPEN_HOUSES,
    label: 'Open Houses',
    icon: OpenHouse,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.PRICE_REDUCTIONS,
    label: 'Price Reductions',
    icon: PriceReduction,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.BACK_ON_THE_MARKET,
    label: 'Back on Market',
    icon: BackToMarket,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.STATUS_CHANGES,
    label: 'Status Changes',
    icon: Status,
    type: TabBtnType.subFilter,
  },
];

const CenterTabSections = [
  {
    id: subFilterTypes.ALL,
    label: 'All',
    icon: All,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.NEW,
    label: 'New',
    icon: New,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.COMMENTS,
    label: 'Comments',
    icon: Comment,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.OPEN_HOUSES,
    label: 'Open Houses',
    icon: OpenHouse,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.PRICE_REDUCTIONS,
    label: 'Price Reductions',
    icon: PriceReduction,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.BACK_ON_THE_MARKET,
    label: 'Back on Market',
    icon: BackToMarket,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.STATUS_CHANGES,
    label: 'Status Changes',
    icon: Status,
    type: TabBtnType.subFilter,
  },
];

export const TabButton = ({
  text,
  icon,
  onClick = () => {},
  isActive = false,
  className = '',
  count = 0,
  disabled = false,
  whiteLayerClassname = '',
  lotiClassName = '',
}) => {
  const [isCompleted, setIsCompleted] = useState(false);

  const { View, play, setDirection, goToAndStop } = useLottie(
    {
      loop: false,
      autoplay: false,
      animationData: icon,
      renderer: 'canvas',
      onComplete: () => setIsCompleted(true), // Mark as completed
    },
    { height: 32, width: 32 }, // Ensure correct sizing
  );

  useEffect(() => {
    if (isCompleted) {
      goToAndStop(0, true); // Reset animation to the beginning after completion
      setIsCompleted(false); // Reset completion state
    }
  }, [isCompleted, goToAndStop]);

  const handleMouseEnter = () => {
    setDirection(1); // Forward
    play();
  };

  const handleMouseLeave = () => {
    if (!isCompleted) {
      setDirection(-1); // Reverse only if not completed
      play();
    }
  };

  return (
    <button
      className={classNames(styles.tabButton, { [styles.active]: isActive }, className)}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Badge count={count}>
        {isString(icon) ? (
          <Icons variant={icon} />
        ) : (
          <div className={classNames(styles.loti, lotiClassName)}>
            {View}
            <div className={classNames(styles.whiteLayer, whiteLayerClassname)} />
          </div>
        )}
      </Badge>
      <span className={styles.btnText}>{text}</span>
    </button>
  );
};

type Props = {
  isSavedSearchResult?: boolean;
  setIsOpenSearchModal?: Function;
  setShowSavedSearches?: Function;
  setShowBlurBg: Function;
  isPending?: boolean;
};

export const AgentHeader: React.FC<Props> = ({
  isSavedSearchResult = false,
  isPending = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCriteriaOpen = useSelector(getIsFeedFilterDrawerOpenSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { agentSavedSearches } = useSelector(getSavedSearchesFormattedSelector);
  const getAgentSearchInstance = useAgentSearchInstance();
  const { isSimpleHeaderTitleVisible } = useSelector(getFeedMetaSelectorV3);

  const setIsSimpleHeaderTitleVisible = (state) => {
    dispatch(setIsSimpleHeaderTitleVisibleAction({ isSimpleHeaderTitleVisible: state }));
  };

  const onChangeSubFilter = (value) => {
    dispatch(setSubFilterTypeAction(value));
  };

  const CarouselNext = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };
  const CarouselPrev = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };

  const settings = {
    draggable: true,
    accessibility: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSavedSearchResult ? 6 : 7,
    slidesToScroll: 2,
    nextArrow: <CarouselNext className={styles.arrows} />,
    prevArrow: <CarouselPrev className={styles.arrows} />,
    responsive: [
      {
        breakpoint: 1500,
        settings: { slidesToShow: isSavedSearchResult ? 5 : 7 },
      },
      {
        breakpoint: 1400,
        settings: { slidesToShow: isSavedSearchResult ? 5 : 6 },
      },
      {
        breakpoint: 1300,
        settings: { slidesToShow: isSavedSearchResult ? 4 : 6 },
      },
      {
        breakpoint: 1050,
        settings: { slidesToShow: isSavedSearchResult ? 3 : 5 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: isSavedSearchResult ? 2 : 3 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: isSavedSearchResult ? 1 : 2 },
      },
    ],
  };

  if (isSimpleHeaderTitleVisible?.value) {
    return (
      <FavourtiesHeader
        text={isSimpleHeaderTitleVisible?.text}
        onClose={() => {
          setIsSimpleHeaderTitleVisible({ value: false, text: '' });
          dispatch(resetClientFavoritesPropsEffect());
          history.push(routes.properties);
        }}
      />
    );
  }

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const CloseButton = ({ onClose }) => {
    return (
      <div className={styles.closeBtn}>
        <Close
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    );
  };

  return (
    <div className={classNames(styles.clientHeader)}>
      <CriteriaModal />
      {isSavedSearchResult && (
        <div className={classNames(styles.tabSection, styles.searchInputSection, styles.noBorder)}>
          <div className={styles.searchContainer}>
            <LocationSearch
              formClassname={classNames({ [styles.formWrapper]: isSavedSearchResult })}
              valuesWrapperClassName={styles.valuesWrapper}
              valueClassName={styles.locationValue}
              isSearchPage={true}
              isAgent={true}
              disabled={isPending}
            />
          </div>
        </div>
      )}
      <div className={classNames(styles.tabSection, { [styles.flexEnd]: !isSavedSearchResult })}>
        {isSavedSearchResult ? <SaveSearchBtn disabled={false} isAgent={true} /> : <SearchBtn />}
        <TabButton
          text="Criteria"
          className={styles.criteria}
          lotiClassName={styles.opacityControl}
          icon={Settings}
          onClick={() => {
            openModal(CriteriaModal.id);
          }}
          isActive={isCriteriaOpen}
          count={
            criteria
              ? Object.values(cleanSearchQueryObj(criteria))?.filter((item) => Boolean(item))
                  ?.length
              : 0
          }
          disabled={isPending}
        />
      </div>
      <div
        className={classNames(
          styles.tabSection,
          styles.scrollable,
          isSavedSearchResult ? styles.search : styles.feed,
        )}
      >
        <Slider {...settings} className={classNames(styles.subfeedSlider)}>
          {(isSavedSearchResult ? CenterTabSectionsNewSearch : CenterTabSections).map(
            (item, idx) => (
              <TabButton
                key={idx}
                text={item.label}
                icon={item.icon}
                className={subFilterType !== item?.id ? styles.opacityControl : ''}
                onClick={() => onChangeSubFilter(item.id)}
                isActive={subFilterType === item?.id}
              />
            ),
          )}
        </Slider>
      </div>
      <div className={classNames(styles.tabSection, styles.noBorder)}>
        {isSavedSearchResult ? (
          <SaveSearchBtn disabled={false} button={CloseButton} isAgent={true} />
        ) : (
          <div className={styles.closeDiv}>
            <CloseButton
              onClose={() => {
                history.push(routes.properties);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
