import { apiServer } from 'settings/web-services/api';

export function getClientDashboardNewMatchesApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-new-matches?contextId=${contextId}`);
}

export function getClientDashboardAgentSuggestionsApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-agent-suggestions?&contextId=${contextId}`);
}

export function getClientDashboardAgentCommentsApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-agent-comments?contextId=${contextId}`);
}

export function getClientDashboardOpenHousesApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-open-houses?contextId=${contextId}`);
}

export function getClientDashboardPriceReductionsApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-price-reductions?contextId=${contextId}`);
}

export function getClientDashboardBackOnMarketApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-back-markets?contextId=${contextId}`);
}

export function getClientDashboardMyTransactionsApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-transactions?contextId=${contextId}`);
}

export function getClientDashboardUpcomingShowingsApi(cfg) {
  const { contextId } = cfg;
  return apiServer.get(`/dashboard/client-upcoming-showings?contextId=${contextId}`);
}

export function getClientDashboardNeedsAttentionApi() {
  return apiServer.get(`/dashboard/needsAttention/client`);
}
