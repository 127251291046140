import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getRecipientsSelector } from 'store/selectors/feed';
import { setRecipientsEffect } from 'store/effects';
import { Checkbox } from 'components';

import styles from './styles.module.scss';

const ListItem = ({ id, name, date }) => {
  const dispatch = useDispatch();
  const recipients = useSelector(getRecipientsSelector);

  const onCheckItem = (event, clientId, isChecked) => {
    if (isChecked) {
      dispatch(
        setRecipientsEffect([...recipients, { Id: id, Name: name, CreatedDate: new Date() }]),
      );
    } else {
      const filteredShares = recipients.filter((recipient) => recipient.Id !== id);
      dispatch(setRecipientsEffect(filteredShares));
    }
  };

  const isChecked = () => recipients.some(({ Id: recipientId }) => recipientId === id);

  return (
    <div testid="list_item" className={styles.itemContainer}>
      <div className={styles.inner}>
        <div className={styles.nameHolder}>
          <p testid="item_name" className={styles.name}>
            {name}
          </p>
        </div>
      </div>
      <Checkbox
        labelClassName={styles.checkboxLabel}
        checkboxClassName={styles.checkbox}
        checkboxWrapperClassName={styles.checkboxWrapper}
        value={id}
        checked={isChecked()}
        onChange={onCheckItem}
        testid="item_checkbox"
      />
    </div>
  );
};

ListItem.propTypes = {
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ListItem.defaultProps = {
  name: '',
  id: null,
};

export default ListItem;
