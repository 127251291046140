import { DYNAMIC_QUESTION_TYPE, getSignatoryColor } from 'app-constants';
import { Avatar, UserAvatar } from 'components-antd';
import { SignatureResponseType } from 'pages/FormBuilder/components';
import { ICON_VARIANT_TYPE } from 'pages/FormProcess/Icons';
import { Icons } from 'pages/FormProcess/Icons';

import styles from './styles.module.scss';

export const extractSignatureFieldData = (response, questionType) => {
  let text: string | undefined = '';

  const responseType = typeof response;

  if (responseType === 'object') {
    if (questionType === DYNAMIC_QUESTION_TYPE.DateSigned) {
      text = response.signatureDate;
    } else if (questionType === DYNAMIC_QUESTION_TYPE.FullName) {
      text =
        response.type === SignatureResponseType.Text ? response.fullName : response.fullNameImage;
    } else if (questionType === DYNAMIC_QUESTION_TYPE.Initials) {
      text = response.type === SignatureResponseType.Text ? response.initials : response.initials; // TODO: Handle initials image.
    } else if (questionType === DYNAMIC_QUESTION_TYPE.Signature) {
      text = response.type === SignatureResponseType.Text ? response.fullName : response.signature;
    }
  } else if (responseType === 'string') {
    if (questionType === DYNAMIC_QUESTION_TYPE.TextBox) {
      text = response;
    }
  }

  return text ?? '';
};

export interface QuestionItem {
  name: string;
  type: DYNAMIC_QUESTION_TYPE;
  icon: typeof ICON_VARIANT_TYPE[keyof typeof ICON_VARIANT_TYPE];
}

export const QuestionConfig = (width?) => {
  const isLargeDesktopOrLarge = width && width > 1515;
  const isDesktopOrLarge = width && width > 1300;
  const isDesktopSmallOrLarge = width && width > 1130;
  const isTabletOrLarge = width && width > 890;

  const isTabletSmallOrLarge = width && width > 700;
  const isMobileSmallOrLarge = width && width > 650;
  const isMobileExtraSmallOrLarge = width && width > 475;

  return [
    {
      groupName: 'Signature Fields',
      items: [
        {
          name: 'Signature',
          type: DYNAMIC_QUESTION_TYPE.Signature,
          icon: ICON_VARIANT_TYPE.Signature,
        },
        ...(isMobileExtraSmallOrLarge || !width
          ? [
              {
                name: 'Initials',
                type: DYNAMIC_QUESTION_TYPE.Initials,
                icon: ICON_VARIANT_TYPE.Initials,
              },
            ]
          : []),

        ...(isMobileSmallOrLarge || !width
          ? [
              {
                name: 'Date Signed',
                type: DYNAMIC_QUESTION_TYPE.DateSigned,
                icon: ICON_VARIANT_TYPE.DateSigned,
              },
            ]
          : []),
      ],
    },
    ...(isTabletSmallOrLarge || !width
      ? [
          {
            groupName: 'Standard Fields',
            items: [
              {
                name: 'Full Name',
                type: DYNAMIC_QUESTION_TYPE.FullName,
                icon: ICON_VARIANT_TYPE.FullName,
              },

              ...(isTabletOrLarge || !width
                ? [
                    {
                      name: 'Text',
                      type: DYNAMIC_QUESTION_TYPE.TextBox,
                      icon: ICON_VARIANT_TYPE.TextBox,
                    },
                  ]
                : []),

              ...(isDesktopSmallOrLarge || !width
                ? [
                    {
                      name: 'Check Box',
                      type: DYNAMIC_QUESTION_TYPE.CheckBox,
                      icon: ICON_VARIANT_TYPE.CheckBox,
                    },
                    {
                      name: 'Radio',
                      type: DYNAMIC_QUESTION_TYPE.RadioGroup,
                      icon: ICON_VARIANT_TYPE.Radio,
                    },
                  ]
                : []),

              ...(isDesktopOrLarge || !width
                ? [
                    {
                      name: 'Date',
                      type: DYNAMIC_QUESTION_TYPE.Date,
                      icon: ICON_VARIANT_TYPE.Date,
                    },
                    {
                      name: 'Address',
                      type: DYNAMIC_QUESTION_TYPE.Address,
                      icon: ICON_VARIANT_TYPE.Address,
                    },
                    {
                      name: 'Phone #',
                      type: DYNAMIC_QUESTION_TYPE.Phone,
                      icon: ICON_VARIANT_TYPE.PhoneNumber,
                    },
                  ]
                : []),

              ...(isLargeDesktopOrLarge || !width
                ? [
                    {
                      name: 'Number',
                      type: DYNAMIC_QUESTION_TYPE.Number,
                      icon: ICON_VARIANT_TYPE.Number,
                    },
                    {
                      name: 'Currency',
                      type: DYNAMIC_QUESTION_TYPE.Currency,
                      icon: ICON_VARIANT_TYPE.Currency,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(isLargeDesktopOrLarge || !width
      ? [
          {
            groupName: 'StrikeThrough',
            items: [
              {
                name: 'Strikethrough',
                type: DYNAMIC_QUESTION_TYPE.StrikeThrough,
                icon: ICON_VARIANT_TYPE.StrikeThrough,
              },
            ],
          },
        ]
      : []),
  ];
};

export const MoreQuestionConfigs = (width) => {
  const AllQuestionConfig = QuestionConfig()
    .map((groupConfig) => groupConfig.items)
    .flat();

  const isTabletHorizontal = width <= 1300 && width > 1130;
  const isTabletSmall = width <= 1130;
  const isTablet = width <= 890;
  const isTabletExtraSmall = width <= 700;
  const isMobile = width <= 650;
  const isMobileExtraSmall = width <= 475;

  const tabletFields = [
    'Radio',
    'Date',
    'Address',
    'Phone #',
    'Number',
    'Currency',
    'StrikeThrough',
  ];
  let fields: any = [];

  if (isTabletHorizontal) {
    return AllQuestionConfig.filter(({ name }) => tabletFields.includes(name));
  } else if (isMobileExtraSmall) {
    fields = ['Initials', 'Date Signed', 'Full Name', 'Text', 'Check Box', ...tabletFields];
  } else if (isMobile) {
    fields = ['Date Signed', 'Full Name', 'Text', 'Check Box', ...tabletFields];
  } else if (isTabletExtraSmall) {
    fields = ['Full Name', 'Text', 'Check Box', ...tabletFields];
  } else if (isTablet) {
    fields = ['Text', 'Check Box', ...tabletFields];
  } else if (isTabletSmall) {
    fields = ['Check Box', ...tabletFields];
  }

  if (fields.length) {
    return AllQuestionConfig.filter(({ name }) => fields.includes(name));
  }

  return [
    {
      name: 'Number',
      type: DYNAMIC_QUESTION_TYPE.Number,
      icon: ICON_VARIANT_TYPE.Number,
    },
    {
      name: 'Currency',
      type: DYNAMIC_QUESTION_TYPE.Currency,
      icon: ICON_VARIANT_TYPE.Currency,
    },
  ];
};

export const StrikeThroughQuestionConfigs = [
  {
    name: 'Strikethrough',
    type: DYNAMIC_QUESTION_TYPE.StrikeThrough,
    icon: ICON_VARIANT_TYPE.StrikeThrough,
  },
];

export const StrikeThroughQuestionFields = {
  groupName: 'Strike Through Fields',
  items: StrikeThroughQuestionConfigs,
};

export const AllQuestionConfig: any = [];

export const formRoleDropDown = (formRoles) => {
  const selectOptions = formRoles?.map((role, index) => ({
    label: (
      <span className={styles.avatarRow}>
        <UserAvatar
          name={role.userName}
          avatar={role.avatar}
          minimizeAvatar
          shortName
          style={{
            backgroundColor: getSignatoryColor(index)?.border,
          }}
          noBackground
          size={24}
        />
        <span className={styles.avatarName}>{role.roleName}</span>
      </span>
    ),
    value: role.roleId,
  }));

  return selectOptions;
};

export const formQuestionTypeDropDown = (border) => {
  const questionOptions = AllQuestionConfig.map((questionItem) => ({
    label: (
      <span className={styles.avatarRow}>
        <Icons variant={questionItem.icon} color={border} />
        <span className={styles.avatarName}>{questionItem.name}</span>
      </span>
    ),
    value: questionItem.type,
  }));

  return questionOptions;
};

export const sortDocuments = (details, key) => {
  if (!details?.length) return [];

  return details.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};
