import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import { AddRounded2, Lock2, Stripe } from 'components/Icons';
import classNames from 'classnames';
import { useSubscription } from 'pages/OnboardingV2Agent/ProAndGrowFlow/StripeCheckout/StandardCheckoutForm/hooks/useSubscription';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useRetrievePlan } from 'pages/Paywall/hooks/useRetrievePlan';
import { showErrorMessage } from 'helpers';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#FFF',
      fontFamily: 'Inter, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(170, 171, 171, 0.5)',
      },
      ':focus': {},
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.18px',
      padding: '16px 14px',
      border: '1px solid #4e536bcc',
      borderRadius: '4px',
      backgroundColor: 'transparent',
    },
    invalid: {
      color: '#ff3333',
      iconColor: '#ff3333',
    },
  },
};

type StripeCheckoutProps = {
  requestedSeatCount: 2 | 3;
  onNext: () => void;
};

export const StandardCheckoutForm: React.FC<StripeCheckoutProps> = ({
  requestedSeatCount = 2,
  onNext,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [coupon, setCoupon] = useState('');

  const {
    getCoupon,
    availablePlansLoading,
    implementationPlanLoading,
    checkoutLoading,
    couponLoading,
    implementationPrice,
    subscriptionPrice,
    subscribeStandard,
    currentPlan,
    standardPlan,
    couponError,
  } = useSubscription({ requestedSeatCount });
  const { startRetrievingPlan } = useRetrievePlan(onNext);

  const mainLoading = availablePlansLoading || implementationPlanLoading;
  const amount = `$${implementationPrice}`;

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements || !standardPlan?.ExternalId) {
        showErrorMessage('Stripe has not yet loaded.');
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      if (!cardElement || !cardExpiryElement || !cardCvcElement) {
        console.error('some fields are empty!');
        return;
      }
      setProcessing(true);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      } as CreatePaymentMethodCardData);

      if (result.error) {
        throw new Error(result.error.message);
      } else {
        const paymentMethodId = result.paymentMethod.id;
        await subscribeStandard(
          paymentMethodId,
          standardPlan.ExternalId,
          requestedSeatCount,
          coupon,
          (err) => {
            if (!err) {
              startRetrievingPlan();
            }
          },
        );
      }
    } catch (err) {
      console.error(err);
      setProcessing(false);
    }
  };
  const loading = mainLoading || couponLoading || checkoutLoading || processing;
  const isInvalidCoupon = couponError && coupon;

  return (
    <div className={styles.formContainer}>
      <h1 className={styles.heading}>Please enter your payment information.</h1>
      <p className={styles.subHeading}>
        Your card will be charged{' '}
        {couponLoading ? (
          <span className={styles.priceBlur}></span>
        ) : (
          <span className={styles.price}>{amount}</span>
        )}{' '}
        for the one-time, nonrefundable activation fee. This card will also be used for your monthly
        fees going forward. You can change your payment information anytime via the settings in your
        account.
      </p>
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <div className={styles.inputWrapper}>
          <label className={styles.formLabel}>Name on Card</label>
          <input type="text" placeholder="Jane Smith" required className={styles.cardElement} />
        </div>
        <div className={styles.inputWrapper}>
          <label className={styles.formLabel}>Card Number</label>

          <CardNumberElement
            className={styles.cardElement}
            options={{
              ...CARD_ELEMENT_OPTIONS,
              showIcon: true,
              iconStyle: 'solid',
              preferredNetwork: ['visa', 'mastercard', 'amex', 'jcb'],
            }}
          />
        </div>
        <div className={styles.flexRow}>
          <div className={styles.inputWrapper}>
            <label className={styles.formLabel}>Expiry Date</label>
            <CardExpiryElement className={styles.cardElement} options={CARD_ELEMENT_OPTIONS} />
          </div>

          <div className={styles.inputWrapper}>
            <label className={styles.formLabel}>Security Code</label>
            <CardCvcElement className={styles.cardElement} options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        <div className={styles.divider}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="589"
            height="2"
            viewBox="0 0 589 2"
            fill="none"
          >
            <path d="M0 1H589" stroke="white" strokeOpacity="0.1" />
          </svg>
        </div>
        {showPromoInput ? (
          <>
            <div className={classNames(styles.promoInput)}>
              <label className={styles.formLabel}>Promo Code</label>
              <input
                type="text"
                placeholder="Enter Promo Code"
                className={styles.cardElement}
                value={coupon}
                onChange={(e) => {
                  setCoupon(e.target.value);
                  getCoupon(e.target.value);
                }}
              />
              {isInvalidCoupon && <div className={styles.couponError}>{couponError}</div>}
            </div>
          </>
        ) : (
          <div className={styles.promoWrapper}>
            <div className={styles.addPromo} onClick={() => setShowPromoInput(true)}>
              <AddRounded2 /> Add Promo Code
            </div>
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.stripeSecure}>
            <div>
              <Lock2 />
            </div>
            <div className={styles.stripeText}>Secured by</div>
            <div>
              <Stripe className={styles.stripeIcon} />
            </div>
          </div>

          <button type="submit" className={styles.payBtn} disabled={loading || isInvalidCoupon}>
            {loading ? (
              <>
                <Spin
                  className={styles.loader}
                  indicator={
                    <LoadingOutlined
                      color="antiquewhite"
                      width={24}
                      height={24}
                      className={styles.spinner}
                      spin
                    />
                  }
                />
              </>
            ) : (
              <>Pay {amount}</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
