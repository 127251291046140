import styles from './styles.module.scss';
import PropertyAvatar from './components/PropertyAvatar';
import { getClientFavoritesPropsEffect } from 'store/effects';
import { getCurrentContextSelector, getSelectedContextIdSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { setCurrentContextAction } from 'store/actions/context';
import { getClientContextsEffect } from 'store/effects/context';
import { READY, IDLE } from 'settings/constants/apiState';
import { getClientFavoritePropsSelector } from 'store/selectors/feed';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { toggleFavoriteListingDetailEffect } from 'store/effects/listingDetail';
import { listingDetailToggleFavoriteListingAction } from 'store/actions/listingDetail';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';

type AvatarPropsType = {
  data: any[];
  removePropertyHandler: Function;
  commentOnProperty: Function;
  shareProperty: Function;
};

function Avatar(props: AvatarPropsType) {
  const dispatch = useDispatch();
  const context = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const userId = useSelector(getUserId);
  const agentId = useSelector(getSelectedContextIdSelector);
  const clientFavoritesProps = useSelector(getClientFavoritePropsSelector);
  const [favoriteProperties, setFavoriteProperties] = useState<any>([]);
  const { isClient } = useSelector(getUserRolesMapSelector);

  let countClass = '';
  switch (props.data.length) {
    case 2:
      countClass = 'Two';
      break;
    case 3:
      countClass = 'Three';
      break;
    case 4:
      countClass = 'Four';
      break;
    case 5:
      countClass = 'Five';
      break;
    default:
      countClass = '';
  }

  const fetchContexts = useCallback(() => dispatch(getClientContextsEffect()), [dispatch]);

  useEffect(() => {
    if (isClient && contextsState.state === IDLE) fetchContexts();
  }, []);

  useEffect(() => {
    if (!context && contextsState.state === READY) {
      const contexts = contextsState.data || [];
      const selectedContext = contexts[0];

      // TODO: we won't depend on specific agent
      const firstAgent = selectedContext?.Agents[0];
      if (!agentId && firstAgent) {
        dispatch(setCurrentContextAction(selectedContext));
      }
    }
  }, [contextsState.state]);

  // useEffect(() => {
  //   if (clientFavoritesProps.state === IDLE && context && context.ContextKey) {
  //     dispatch(getClientFavoritesPropsEffect({ userId }));
  //   }
  // }, [context]);

  useEffect(() => {
    if (clientFavoritesProps.state == 'ready') {
      setFavoriteProperties(clientFavoritesProps.data);
    }
  }, [clientFavoritesProps.state]);

  const getFavoriteStatus = (id: string) => {
    if (favoriteProperties.length) {
      const propertyInfo = favoriteProperties.find((obj) => obj.Id === id);
      if (propertyInfo) return true;
      else return false;
    } else return false;
  };

  const setFavoriteStatus = (id: string, status: boolean) => {
    const tempFavoriteProperties = [...favoriteProperties];
    if (status) {
      const property = props.data.find((obj) => obj.Id === id);
      tempFavoriteProperties.push(property);
      setFavoriteProperties(tempFavoriteProperties);
    } else {
      const newFavoriteProperties = tempFavoriteProperties.filter((obj) => obj.Id !== id);
      setFavoriteProperties(newFavoriteProperties);
    }
    dispatch(
      toggleFavoriteListingDetailEffect(
        { id, isFavorite: status, contextKey: context.ContextKey },
        {},
        (err) => {
          if (!err) {
            dispatch(listingDetailToggleFavoriteListingAction({ flag: status }));
          }
        },
      ),
    );
  };

  return (
    <div className={styles.avatarSection} id="compareAvatars">
      <PropertyAvatar empty={true} />
      {props.data.map((item, index) => (
        <PropertyAvatar
          index={index}
          propertyId={item.Id}
          photo={item.photo}
          countClass={countClass}
          address={item.address}
          photoCount={item.photoCount}
          city={item.city}
          state={item.state}
          zip={item.zip}
          removePropertyHandler={props.removePropertyHandler}
          currentPropertyCount={props.data.length}
          commentOnProperty={props.commentOnProperty}
          shareProperty={props.shareProperty}
          setFavoriteStatus={setFavoriteStatus}
          favoriteStatus={getFavoriteStatus(item.Id)}
          favoriteFetchStatus={clientFavoritesProps.state}
          empty={false}
        />
      ))}
    </div>
  );
}

export default Avatar;
