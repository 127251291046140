import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Suggestion = (props) => {
  const { className, getSuggestionItemProps, suggestion, formatStateSuggestion } = props;

  return (
    <div
      {...getSuggestionItemProps(suggestion, {
        className: classNames(styles.suggestion, { [styles.active]: suggestion.active }, className),
      })}
    >
      <div className={styles.icon}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="15" r="3" stroke="#AAABAB" strokeWidth="2" />
          <path
            d="M24 15C24 19.6072 19.283 23.9196 17.0931 25.6734C16.4466 26.1912 15.5533 26.1912 14.9069 25.6734C12.717 23.9196 8 19.6072 8 15C8 10.5817 11.5817 7 16 7C20.4183 7 24 10.5817 24 15Z"
            stroke="#AAABAB"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div>
        <div className={styles.description}>
          {suggestion.street_line
            ? `${suggestion.street_line}${
                suggestion.secondary ? ' ' + suggestion.secondary : ''
              }, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`
            : formatStateSuggestion
            ? suggestion?.formattedSuggestion?.mainText
            : suggestion.description}
        </div>
      </div>
    </div>
  );
};

Suggestion.propTypes = {
  className: PropTypes.string,
  getSuggestionItemProps: PropTypes.func,
  suggestion: PropTypes.shape({
    active: PropTypes.bool,
    description: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  formatStateSuggestion: PropTypes.bool,
};

Suggestion.defaultProps = {
  className: '',
  getSuggestionItemProps: () => {},
  onClick: () => {},
  formatStateSuggestion: false,
};

export default Suggestion;
