import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from 'components/Icons';
import Slider from 'react-slick';
import { FavourtiesHeader } from '../../../common/FavourtiesHeader';
import { SavedSearches } from './components/SavedSearches';

import { propertyStatus } from 'settings/constants/properties';
import { routes } from 'settings/navigation/routes';
import { setFeedPrefsAction, setSubFilterTypeAction } from 'store/actions/feed';
import { appOpenModalEffect, setActiveTabEffect, sortFeedEffect } from 'store/effects';
import { getIsFeedFilterDrawerOpenSelector, getSubFilterTypeSelector } from 'store/selectors/feed';

import Close from 'components/Close';
import { LocationSearch } from 'pages/Properties/ClientSearch/LocationSearch';
import CriteriaModal from 'pages/Properties/CriteriaModal';
import { getFeedCriteriaSelectorV3, getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { SaveSearchBtn } from './components/SaveSearchBtn';
import styles from '../../../AgentFeed/components/AgentHeader/styles.module.scss';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { useSearchInstanceV3Effect } from '../../hooks/useSearchInstanceV3Effect';
import {
  All,
  BackToMarket,
  Star,
  New,
  OpenHouse,
  PriceReduction,
  Status,
  Settings,
} from 'pages/Properties/LotiIcons';
import { TabButton } from 'pages/Properties/AgentFeed/components';

const subFilterTypes = {
  ALL: 'All',
  NEW: 'New',
  RECOMMENDED: 'Recommended',
  OPEN_HOUSES: 'OpenHouses',
  PRICE_REDUCTIONS: 'PriceReductions',
  BACK_ON_THE_MARKET: 'BackOnTheMarket',
  STATUS_CHANGES: 'StatusChanges',
  SUGGESTIONS: 'Suggestions',
};

const TabBtnType = {
  subFilter: 'subFilter',
  tabFilter: 'tabFilter',
};

const CenterTabSections = [
  {
    id: subFilterTypes.ALL,
    label: 'All',
    icon: All,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.RECOMMENDED,
    label: 'Recommended',
    icon: Star,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.NEW,
    label: 'New',
    icon: New,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.OPEN_HOUSES,
    label: 'Open Houses',
    icon: OpenHouse,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.PRICE_REDUCTIONS,
    label: 'Price Reductions',
    icon: PriceReduction,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.BACK_ON_THE_MARKET,
    label: 'Back on Market',
    icon: BackToMarket,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.STATUS_CHANGES,
    label: 'Status Changes',
    icon: Status,
    type: TabBtnType.subFilter,
  },
];

type Props = {
  isSavedSearchResult?: boolean;
  isSimpleHeaderTitleVisible: any;
  setIsSimpleHeaderTitleVisible: Function;
  setIsOpenSearchModal?: Function;
  setShowSavedSearches?: Function;
  setShowBlurBg: Function;
  isPending?: boolean;
};

export const ClientHeader: React.FC<Props> = ({
  isSavedSearchResult = false,
  isSimpleHeaderTitleVisible,
  setIsSimpleHeaderTitleVisible,
  setIsOpenSearchModal = (flag: boolean) => {},
  setShowSavedSearches = (flag: boolean) => {},
  setShowBlurBg,
  isPending = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCriteriaOpen = useSelector(getIsFeedFilterDrawerOpenSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const getSearchInstanceV3 = useSearchInstanceV3Effect();

  const onChangeSubFilter = (value) => {
    dispatch(setSubFilterTypeAction(value));
  };

  const onChangeTab = (tabId) => {
    dispatch(setFeedPrefsAction({ Status: [propertyStatus.active] }));
    dispatch(setActiveTabEffect(tabId));
  };

  const CarouselNext = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };
  const CarouselPrev = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };

  const settings = {
    draggable: true,
    accessibility: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSavedSearchResult ? 6 : 7,
    slidesToScroll: 2,
    nextArrow: <CarouselNext className={styles.arrows} />,
    prevArrow: <CarouselPrev className={styles.arrows} />,
    responsive: [
      {
        breakpoint: 1500,
        settings: { slidesToShow: isSavedSearchResult ? 5 : 7 },
      },
      {
        breakpoint: 1400,
        settings: { slidesToShow: isSavedSearchResult ? 5 : 6 },
      },
      {
        breakpoint: 1300,
        settings: { slidesToShow: isSavedSearchResult ? 4 : 6 },
      },
      {
        breakpoint: 1050,
        settings: { slidesToShow: isSavedSearchResult ? 3 : 5 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: isSavedSearchResult ? 2 : 3 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: isSavedSearchResult ? 1 : 2 },
      },
    ],
  };

  if (isSimpleHeaderTitleVisible?.value) {
    return (
      <FavourtiesHeader
        text={isSimpleHeaderTitleVisible?.text}
        onClose={() => {
          setIsSimpleHeaderTitleVisible({ value: false, text: '' });
          history.push(routes.properties);
        }}
      />
    );
  }

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const onOpenSearch = (showSavedSearches: boolean = true) => {
    setIsOpenSearchModal(true);
    showSavedSearches && setShowSavedSearches(true);
  };

  const CloseButton = ({ onClose }) => {
    return (
      <div className={styles.closeBtn}>
        <Close
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    );
  };

  return (
    <div className={classNames(styles.clientHeader)}>
      <CriteriaModal />
      {isSavedSearchResult && (
        <div className={classNames(styles.tabSection, styles.searchInputSection, styles.noBorder)}>
          <div className={styles.searchContainer}>
            <LocationSearch
              formClassname={classNames({ [styles.formWrapper]: isSavedSearchResult })}
              valuesWrapperClassName={styles.valuesWrapper}
              valueClassName={styles.locationValue}
              isSearchPage={true}
              disabled={isPending}
            />
          </div>
        </div>
      )}
      <div className={classNames(styles.tabSection, styles.flexEnd)}>
        {isSavedSearchResult ? (
          <SaveSearchBtn disabled={false} />
        ) : (
          <SavedSearches handleOpenSearch={onOpenSearch} setShowBlurBg={setShowBlurBg} />
        )}
        <TabButton
          text="Criteria"
          icon={Settings}
          onClick={() => {
            openModal(CriteriaModal.id);
          }}
          isActive={isCriteriaOpen}
          count={
            criteria
              ? Object.values(cleanSearchQueryObj(criteria))?.filter((item) => Boolean(item))
                  ?.length
              : 0
          }
          disabled={isPending}
          className={styles.criteria}
          lotiClassName={styles.opacityControl}
        />
      </div>
      <div
        className={classNames(
          styles.tabSection,
          styles.scrollable,
          isSavedSearchResult ? styles.search : styles.feed,
        )}
      >
        <Slider {...settings} className={classNames(styles.subfeedSlider)}>
          {CenterTabSections.map((item, idx) => (
            <TabButton
              key={idx}
              text={item.label}
              icon={item.icon}
              onClick={() =>
                item.type === TabBtnType.subFilter
                  ? onChangeSubFilter(item.id)
                  : onChangeTab(item.id)
              }
              className={subFilterType !== item?.id ? styles.opacityControl : ''}
              isActive={subFilterType === item?.id}
            />
          ))}
        </Slider>
      </div>
      <div className={classNames(styles.tabSectionSearch, styles.noBorder)}>
        {isSavedSearchResult ? (
          <SaveSearchBtn
            disabled={false}
            button={CloseButton}
            onClose={() => {
              history.push(routes.properties);
            }}
          />
        ) : (
          <CloseButton
            onClose={() => {
              history.push(routes.properties);
            }}
          />
        )}
      </div>
    </div>
  );
};
