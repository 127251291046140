import { showSuccessMessage } from 'helpers';
import { useSetSearch } from 'hooks';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CREATE, EDIT } from 'settings/constants/mode';
import { propertyStatus } from 'settings/constants/properties';
import { routes } from 'settings/navigation/routes';
import {
  setClientsRenameSearchInstanceAction,
  setClientsSearchInstanceStatusAction,
} from 'store/actions/clientSearches';
import {
  selectedSearchAction,
  setCriteriaModeAction,
  setIsClientMatchesAction,
} from 'store/actions/feedv3';
import {
  setRenameSavedSearchAction,
  setRenameSearchInstanceAction,
  setSavedSearchStatusAction,
  setSearchInstanceStatusAction,
} from 'store/actions/mySearches';
import {
  activateSearchInstanceEffect,
  appOpenModalEffect,
  deactivateSearchInstanceEffect,
  openSearchResultsFilterDrawerEffect,
  renameSearchInstanceEffect,
  resetSearchCriteriaEffect,
  setRawPrefDataEffect,
  sortFeedEffect,
} from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useSearchInstanceV3Effect } from '../ClientFeed/hooks/useSearchInstanceV3Effect';
import { subFilterTypes } from 'settings/constants/properties';
import { getSubFilterTypeSelector } from 'store/selectors/feed';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { useAgentSearchInstance } from '../AgentFeed/hooks/useAgentSearchInstance';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import CriteriaModal from '../CriteriaModal';
import { SORT_ASC } from 'settings/constants/sort';

const filterByStatus = (data, status) => {
  return data?.filter((item) => item.status === status);
};

type HookProps = {
  isClientSearch?: boolean;
  clientId?: string | null;
  onClose?: () => void;
  showSavedSearchesProp?: boolean;
  setShowSavedSearchesProp?: Dispatch<SetStateAction<boolean>>;
};

export const useSavedSearches = ({
  isClientSearch = false,
  clientId = null,
  onClose,
  showSavedSearchesProp = false,
  setShowSavedSearchesProp,
}: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { updateSearchValue } = useSetSearch('search');
  const [isActiveSelected, setIsActiveSelected] = useState<boolean>(true);
  const { isClient, isAgent, isThirdParty } = useSelector(getUserRolesMapSelector);
  const { data, isLoading } = useSelector(getSearchInstancesFormattedSelector);
  const searchContext = useSelector(getCurrentContextSelector);
  const { selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);

  const cfg = { contextKey: searchContext?.ContextKey };
  const getSearchInstanceV3 = useSearchInstanceV3Effect();
  const getAgentSearchInstance = useAgentSearchInstance();

  const subFilterType = useSelector(getSubFilterTypeSelector);

  const activeSearches = useMemo(() => (data ? filterByStatus(data, 'Active') : null), [data]);
  const inactiveSearches = useMemo(() => (data ? filterByStatus(data, 'Inactive') : null), [data]);

  const savedSearchesCount = useMemo(() => data?.length || 0, [data]);
  const searchesToShow = useMemo(
    () => (isActiveSelected ? activeSearches : inactiveSearches),
    [isActiveSelected, activeSearches, inactiveSearches],
  );

  const isSavedSearchesOpen = useMemo(() => showSavedSearchesProp, [showSavedSearchesProp]);

  const handleSavedSearchClick = () => {
    if (setShowSavedSearchesProp) setShowSavedSearchesProp((prev) => !prev);
  };

  const getEditHandler = useCallback(
    (search) => () => {
      dispatch(selectedSearchAction({ selectedSearch: search }));
      getSearchInstanceV3(search, () => history.push(routes.searchResults));
    },
    [dispatch],
  );

  const getRenameHandler = (id) => (name, onCloseRenameModal) => {
    dispatch(
      renameSearchInstanceEffect({ id, name }, {}, (err) => {
        if (!err) {
          isClient
            ? dispatch(setRenameSearchInstanceAction({ id, name }))
            : isClientSearch
            ? dispatch(setClientsRenameSearchInstanceAction({ id, name, clientId }))
            : dispatch(setRenameSavedSearchAction({ id, name }));
          showSuccessMessage('Search Instance Renamed Successfully');
        }
        onCloseRenameModal();
      }),
    );
  };

  const getSearchHandler = useCallback(
    ({ openCriteriaModal, ...criterias }) =>
      () => {
        dispatch(setIsClientMatchesAction({ isClientMatches: false }));
        dispatch(
          selectedSearchAction({
            selectedSearch: criterias,
          }),
        );
        dispatch(sortFeedEffect({ order: SORT_ASC, fields: ['DaysOnMarket'] }));
        getAgentSearchInstance(
          { criterias: criterias?.criterias, id: criterias?.Id },
          undefined,
          selectedClientSavedSearch,
        );
        if (openCriteriaModal) {
          dispatch(appOpenModalEffect({ id: CriteriaModal.id, open: true }));
        } else {
          dispatch(appOpenModalEffect({ id: null, open: false }));
        }
        dispatch(setCriteriaModeAction({ mode: EDIT }));
        history.push(routes.feed);
      },
    [dispatch],
  );

  const getInactiveHandler = useCallback(
    (id) => () => {
      dispatch(
        deactivateSearchInstanceEffect({ id }, {}, (err) => {
          if (!err) {
            if (isClient) {
              dispatch(
                setSearchInstanceStatusAction({
                  id,
                  status: 'Inactive',
                }),
              );
            } else if (isClientSearch) {
              dispatch(
                setClientsSearchInstanceStatusAction({
                  id,
                  status: 'Inactive',
                  clientId: clientId,
                }),
              );
            } else {
              dispatch(setSavedSearchStatusAction({ id, status: 'Inactive' }));
            }
            showSuccessMessage('Search Instance Deactivated');
          }
        }),
      );
    },
    [dispatch],
  );

  const getActivateHandler = useCallback(
    (id) => () => {
      dispatch(
        activateSearchInstanceEffect({ id }, {}, (err) => {
          if (!err) {
            if (isClient) {
              dispatch(
                setSearchInstanceStatusAction({
                  id,
                  status: 'Active',
                }),
              );
            } else if (isClientSearch) {
              dispatch(
                setClientsSearchInstanceStatusAction({
                  id,
                  status: 'Active',
                  clientId: clientId,
                }),
              );
            } else {
              dispatch(setSavedSearchStatusAction({ id, status: 'Active' }));
            }
            showSuccessMessage('Search Instance Activated');
          }
        }),
      );
    },
    [dispatch],
  );

  return {
    showSavedSearches: isSavedSearchesOpen,
    isActiveSelected,
    setIsActiveSelected,
    searchesToShow,
    handleSavedSearchClick,
    getEditHandler,
    getRenameHandler,
    getSearchHandler,
    getInactiveHandler,
    savedSearchesCount,
    getActivateHandler,
  };
};
