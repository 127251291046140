import classNames from 'classnames';
import { InfoTooltip } from '../../InfoTooltip';
import styles from './styles.module.scss';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { removeSpaces } from 'utils';
import { shouldHideType } from '../helper';
import { GroupField } from './GroupField';
import { FieldItem } from '../FieldItem';

export const FormEditorField = (props) => {
  const { isDragging, field, role, color, fieldItemProps, isTemplate } = props;

  const { type, width } = field;

  const isCheckbox = type === DYNAMIC_QUESTION_TYPE.CheckBox;
  const isGroupField = type === DYNAMIC_QUESTION_TYPE.RadioGroup;

  const getFieldWrapper = () => {
    const _type = removeSpaces(type);
    const hideType = shouldHideType(type, width);
    if (type === DYNAMIC_QUESTION_TYPE.DateSigned || type === DYNAMIC_QUESTION_TYPE.Date) {
      return (
        <div
          className={classNames(styles.fieldWrapper, {
            [styles.hiddenTypeWrap]: hideType,
            [styles.editorFieldWrapper]: !isTemplate,
          })}
          id="fieldWrap"
        >
          <div className={styles.lineHeight}>
            <Icons
              className={styles.icon}
              style={{ '--border-color': color?.border } as any}
              variant={_type}
            />
          </div>
          {hideType ? <></> : 'MM/DD/YYYY'}
        </div>
      );
    }

    if (isCheckbox) {
      return (
        <Icons
          variant={ICON_VARIANT_TYPE.CheckboxField}
          className={styles.checkbox}
          style={{ '--border-color': color?.border } as any}
        />
      );
    }

    return (
      <div
        className={classNames(styles.fieldWrapper, {
          [styles.hiddenTypeWrap]: hideType,
          [styles.editorFieldWrapper]: !isTemplate,
        })}
        id="fieldElement"
      >
        <div className={styles.lineHeight}>
          <Icons
            className={_type === 'TextBox' ? styles.iconText : styles.icon}
            style={{ '--border-color': color?.border } as any}
            variant={_type}
          />
        </div>
        {hideType ? <></> : type}
      </div>
    );
  };

  if (!isGroupField) {
    return <FieldItem {...fieldItemProps} fieldPlaceholder={getFieldWrapper()} />;
  }

  return (
    <InfoTooltip
      text={isDragging ? '' : role?.roleName}
      id="fieldInfo"
      className={isGroupField ? styles.radioGroupTooltip : ''}
    >
      {isGroupField ? (
        <GroupField {...props} />
      ) : (
        <span
          className={classNames(styles.formEditorField, {
            [styles.checkboxField]: isCheckbox,
            [styles.required]: field.required && !isCheckbox,
            [styles.checkboxRequiredField]: isCheckbox && field.required,
          })}
          id="fieldEditor"
        >
          {getFieldWrapper()}
        </span>
      )}
    </InfoTooltip>
  );
};
