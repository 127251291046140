import { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { link } from 'settings/navigation/link';
import {
  getSearchCriteriaSelector,
  getSearchDrawnPolygonSelector,
  getSearchIsMapDrawingModeSelector,
} from 'store/selectors/search';
import { Map as CommonMap } from 'components';
import { MapWithDrawingManager } from 'components/MapWithDrawingManager/MapWithDrawingManager';
import { featureFlags } from 'utils/featureFlags';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import { routes } from 'settings/navigation/routes';

const Map = ({
  id,
  markers,
  properties,
  totalCount,
  setDrawnPolygonDispatchFn,
  polygonLocations,
  disableDraw,
}) => {
  const currentSearch = useSelector(getSearchCriteriaSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);

  function isValidLatLng(lat, lng) {
    const isLatValid = lat >= -90 && lat <= 90;
    const isLngValid = lng >= -180 && lng <= 180;
    return isLatValid && isLngValid;
  }

  function filterInvalidMarkers(markers) {
    const updatedMarkers = {};

    for (const key in markers) {
      const [lng, lat] = key.split(' ').map(Number);

      if (isValidLatLng(lat, lng)) {
        updatedMarkers[key] = markers[key];
      }
    }

    return updatedMarkers;
  }

  const markers_ = filterInvalidMarkers(markers);

  const getPropertyLink = useCallback(
    (id) => link.toSearchListingDetails(routes.feedListingDetail, id),
    [],
  );

  const getListingCountTextForMap = () => {
    if (properties?.length && totalCount && properties?.length !== totalCount?.value) {
      const countText = totalCount?.type === 'Exact' ? totalCount?.value : `${totalCount?.value}+`;
      return `${properties.length} of ${totalCount?.value > 1000 ? '1000+' : countText} listings`;
    }
    return '';
  };

  const getSelectedLocations = () => {
    let selectedLocations = [];
    const placeIds = currentSearch?.Locations?.filter((location) => location.ProviderPlaceId)?.map(
      (location) => location.ProviderPlaceId,
    );

    const editPlaceIds = criteria?.Locations?.filter((location) => location.ProviderPlaceId)?.map(
      (location) => location.ProviderPlaceId,
    );

    if (placeIds && placeIds.length) {
      selectedLocations = [...placeIds];
    }

    if (editPlaceIds && editPlaceIds.length) {
      selectedLocations = [...selectedLocations, ...editPlaceIds];
    }

    return selectedLocations;
  };

  if (featureFlags.customSearchPolygonDrawing) {
    return (
      <MapWithDrawingManager
        markersMap={markers_}
        id={id}
        getPropertyLink={getPropertyLink}
        getDrawnPolygonSelectorFn={getSearchDrawnPolygonSelector}
        getIsMapDrawingModeSelectorFn={getSearchIsMapDrawingModeSelector}
        listingCountTextForMap={getListingCountTextForMap()}
        isMapEnhancementEnabled={true}
        selectedLocations={getSelectedLocations()}
        setDrawnPolygonDispatchFn={setDrawnPolygonDispatchFn}
        polygonLocations={polygonLocations}
        disableDraw={disableDraw}
      />
    );
  }

  return (
    <CommonMap
      markersMap={markers_}
      id={id}
      getPropertyLink={getPropertyLink}
      listingCountTextForMap={getListingCountTextForMap()}
      isMapEnhancementEnabled={true}
      selectedLocations={getSelectedLocations()}
    />
  );
};

Map.propTypes = {
  id: PropTypes.string,
  disableDraw: PropTypes.bool,
};

Map.defaultProps = {
  id: '',
  disableDraw: false,
};

export default Map;
