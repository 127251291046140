import { handleActions } from 'redux-actions';
import { ERROR, IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import { requestGetMlsDashboardStatsAction } from 'store/actions/adminPanel';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetMlsDashboardStatsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data', initialData.data),
    }),
  },
  initialData,
);
