import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetAgentGraphsAction,
  requestGetTeamGraphsAction,
  requestUpdateClientStateFlag,
  setAgentDashboardTimeStamp,
} from 'store/actions/agentDashboard';

const initialData = {
  state: IDLE,
  agentData: null,
  teamData: null,
  updatedClientStateFlag: false,
  meta: {},
  timeStamp: null,
};

export default handleActions(
  {
    [setAgentDashboardTimeStamp]: (state, { payload }) => ({
      ...state,
      timeStamp: payload.timeStamp,
    }),
    [requestGetAgentGraphsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      agentData: get(payload, 'data', initialData.agentData) || state.agentData,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestGetTeamGraphsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      teamData: get(payload, 'data', initialData.teamData) || state.teamData,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestUpdateClientStateFlag]: (state, value) => {
      return {
        ...state,
        updatedClientStateFlag: value || state.updatedClientStateFlag,
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
