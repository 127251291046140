import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Popover, Radio } from 'components-antd';
import { useMemo, useState } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDynamicFormEditorConfigSelector } from 'store/selectors/requestFormProcess';
import { Check } from 'components/Icons';
import { updateDynamicFormQuestionEffect } from 'store/effects/formProcess';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { DefaultSystemFont, SignatureResponseType } from 'pages/FormBuilder/components';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

export const RolesPopover = ({
  field,
  color,
  selectedFieldIndex,
  fieldActionProps,
  canChangeRole,
  isTemplate,
  updateAnswer,
  responses,
  documentIndex,
}) => {
  const name = field.customRole?.roleName;
  const isRadioGroup = field.type === DYNAMIC_QUESTION_TYPE.RadioGroup;
  const isFullName = field.type === DYNAMIC_QUESTION_TYPE.FullName;

  const dispatch = useDispatch();

  const { formRoles } = useSelector(getDynamicFormEditorConfigSelector);

  const roleId = field.customRole?.roleId;

  const manageFullName = (roleItem, newField) => {
    const value = roleItem.roleName;
    const data = {
      fullName: value,
      type: SignatureResponseType.Text,
      font: DefaultSystemFont,
    };

    const questionUUID = field.UUID;
    const fontSize = 12;
    if (field.id) {
      const documentAnswers = { ...responses };

      documentAnswers[questionUUID] = documentAnswers[questionUUID] ?? {
        UUID: questionUUID,
        Answer: '',
        DefultAnswer: '',
      };

      documentAnswers[questionUUID].Answer = JSON.stringify(data);

      dynamicManager.handleQuestionResponse(
        questionUUID,
        JSON.stringify(data),
        documentIndex,
        12,
        false,
        false,
      );

      updateAnswer?.(documentAnswers);
    } else {
      newField.fieldAnswer = {
        answer: JSON.stringify(data),
        UUID: questionUUID,
        fontSize,
        documentIndex,
      };
    }

    return newField;
  };

  const onSelectRole = (roleItem) => {
    let newField = { ...field };

    if (roleItem.roleId === field.customRole.roleId) return;

    if (isFullName && !isTemplate) {
      newField = manageFullName(roleItem, newField);
    }

    dispatch(
      updateDynamicFormQuestionEffect({
        ...newField,
        index: selectedFieldIndex,
        newRole: roleItem.roleId,
        ...fieldActionProps,
        isGroupField: isRadioGroup,
      }),
    );
  };

  const menu = (
    <Radio.Group className={styles.optionsItems}>
      <Menu>
        {formRoles.map((item, idx) => (
          <MenuItem key={idx} onClick={() => onSelectRole(item)}>
            <Radio value={item.roleId} key={idx}>
              <div className={styles.option}>
                <span
                  style={{
                    backgroundColor: item.color?.border,
                  }}
                  className={styles.userColorBox}
                />
                <span className={styles.optionValue}>{item.roleName}</span>
              </div>
              {roleId === item.roleId && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.rolesDropdownWrapper)}>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger={['click']}
        overlayClassName={styles.rolesPopover}
        disabled={!canChangeRole}
      >
        <div
          className={classNames(styles.roleInformation, {
            [styles.disabledRoleInformation]: !canChangeRole,
          })}
        >
          <span
            style={{
              backgroundColor: color?.border,
            }}
            className={styles.userColorBox}
          />
          <span className={styles.userTitle}>{name}</span>
        </div>
      </Dropdown>
    </div>
  );
};
