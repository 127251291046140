import { useEffect, useCallback, useState, useMemo } from 'react';
import classnames from 'classnames';

import { Modal, TabPane, Tabs } from 'components-antd';
import { Spinner } from 'components';
import { Header } from './components/Header';
import { Details } from './components/Details';
import { Documents } from './components/Documents';
import { HistoryLogs } from '../../../../../components/HistoryLogs';
import { Highlights } from './components/Highlights';
import { Actions } from './components/Actions';
import { Comment } from 'components/Icons';
import { getOfferIterationDetailsById, offerDetails } from 'api/offers';
import Comments from './components/Comments';
import styles from './styles.module.scss';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferMessagesSelector } from 'store/selectors/offerMessages';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

import {
  resetOfferMessagesEffect,
  socketsGetOfferMessagesByThreadIdEffect,
} from 'store/effects/sockets/offerMessages';
import { getUserId } from 'store/selectors/user';
import { Icons } from 'pages/Workshop/Icons';

interface OfferDetailsModalProps {
  isOpen: boolean;
  onClose: any;
  offerData: any;
  transactionId: any;
  setOfferCompareModalIsOpen: any;
  columnName?: any;
  setReloadOffer?: Function;
  isActionDisabled?: boolean;
  isActivityDisabled?: boolean;
  defaultTabOpen?: string;
}

export const OfferDetailsModal = ({
  isOpen,
  onClose,
  offerData,
  transactionId,
  setOfferCompareModalIsOpen,
  setReloadOffer,
  defaultTabOpen,
  isActionDisabled,
  isActivityDisabled,
}: OfferDetailsModalProps) => {
  const [offerIteration, setOfferIteration]: any = useState(undefined);
  const [offerIterations, setOfferIterations]: any = useState([]);
  const [offerDetailsData, setOfferDetailsData]: any = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tabKey, setTabKey] = useState(defaultTabOpen ?? '1');
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const userId = useSelector(getUserId);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const { threadsList, messages } = useSelector(getOfferMessagesSelector);

  const fetchOfferDetails = useCallback(async (UUID) => {
    setIsLoading(true);
    const { data } = await offerDetails({ UUID });
    setOfferIterations(data?.value?.OfferIteration);
    setReadOnly(!data?.value?.isInvited || !fullAccess);
    setOfferDetailsData(data.value);
    setIsLoading(false);
  }, []);

  const fetchOfferIterationDetails = useCallback(async (UUID) => {
    setIsLoading(true);
    const { data } = await getOfferIterationDetailsById({ Id: UUID });
    if (data?.value) {
      setOfferIteration(data.value);
      const offerUUID = data?.value?.OfferDetails?.UUID;
      fetchOfferDetails(offerUUID);
    }
    setIsLoading(false);
  }, []);

  const verify = (iteration) => {
    return iteration?.UUID === offerIteration?.UUID;
  };
  const checkAndGetThread = () =>
    threadsList.find(
      (thread) =>
        thread?.EntityId === `${offerData?.OfferId}` && thread?.Type === SOCKET_THREAD_TYPES.OFFER,
    );

  useEffect(() => {
    if (isOpen) {
      fetchOfferIterationDetails(offerData?.UUID);
    } else resetAll();
  }, [isOpen, offerData?.UUID]);

  function resetAll() {
    // Resets all states so old data doesn't populate.
    setIsLoading(true);
    setOfferIteration(undefined);
    setOfferIterations([]);
    setOfferDetailsData({});
    setIsLoading(false);
  }

  useEffect(() => {
    if (isOpen) {
      const existThread = checkAndGetThread();
      if (existThread) {
        dispatch(socketsGetOfferMessagesByThreadIdEffect({ Id: existThread?.Id }));
      } else {
        dispatch(resetOfferMessagesEffect());
      }

      offerDetailsData?.Comments?.forEach((comment) => {
        if (comment.SenderUserId !== userId) {
          comment.MessageReceipts.some((receipt) => {
            if (receipt.UserId === userId && !receipt.IsRead) {
              setUnreadMessagesCount((unreadMessagesCount) => unreadMessagesCount + 1);
              return true;
            }
          });
        }
      });
    } else {
      setUnreadMessagesCount(0);
    }
  }, [offerData?.Id, isOpen, offerDetailsData]);
  useEffect(() => {
    setTabKey(defaultTabOpen ?? '1');
  }, [defaultTabOpen, offerData?.Id]);
  const getIterationType = (iteration) => {
    if (iteration.IterationCount > 0) {
      return iteration.PerformedBy.Email
        ? iteration?.PerformedBy?.Email === offerData?.BuyingAgentEmail
          ? 'Inbound'
          : 'Outbound'
        : 'Inbound';
    } else {
      return 'Initial Offer';
    }
  };

  const renderIteration = (iteration, index, array) => {
    let counterType = getIterationType(iteration);
    if (counterType !== 'Initial Offer') {
      counterType = `Counter #${iteration.IterationCount} (${counterType})`;
    }
    if (index < array.length - 1) {
      counterType += ' > ';
    }

    return (
      <span
        key={index}
        onClick={() => {
          setOfferIteration(iteration);
        }}
        className={classnames(styles.iterations, { [styles.activeIteration]: verify(iteration) })}
      >
        {counterType}
      </span>
    );
  };

  const TABS_ITEMS = useMemo(
    () => [
      {
        key: '1',
        label: 'Files',
        icon: (
          <Icons
            className={styles.tabIcon}
            color={tabKey === '1' ? '#262626' : '#AAABAB'}
            variant={Icons.FILES}
          />
        ),
        children: <Documents offerIteration={offerIteration} offerData={offerData} />,
      },

      {
        key: '2',
        label: 'Comments',
        icon: (
          <Icons
            color={tabKey === '2' ? '#262626' : '#AAABAB'}
            className={styles.tabIcon}
            variant={Icons.TASK_COMMENT}
          />
        ),
        children: <Comments offerData={offerData} commentAutoFocus={true} readOnly={readOnly} />,
      },
      ...(!isActivityDisabled
        ? [
            {
              key: '3',
              label: 'History',
              icon: (
                <Icons
                  color={tabKey === '3' ? '#262626' : '#AAABAB'}
                  className={styles.tabIcon}
                  variant={Icons.HISTORY}
                />
              ),
              children: (
                <HistoryLogs
                  query={{ OfferId: offerData?.OfferId }}
                  offerSubmissionDate={offerIteration?.CreatedDate}
                  BuyingAgentName={offerData?.BuyingAgentName}
                  showHeader={false}
                  showAddress={false}
                  className={styles.tabContentWrapper}
                />
              ),
            },
          ]
        : []),
    ],
    [offerData, tabKey, offerIteration, defaultTabOpen],
  );

  return (
    <Modal
      open={isOpen}
      width={1000}
      footer={null}
      onCancel={onClose}
      closeIcon={false}
      destroyOnClose
      closable={false}
      className={styles.appointmentDetailsModal}
      title={
        isLoading ? null : (
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <Header
                iteration={offerIteration}
                offerData={offerDetailsData}
                onModalClose={onClose}
                offerIterations={offerIterations}
                renderiteration={renderIteration}
                readOnly={readOnly}
              />
            </div>
          </div>
        )
      }
    >
      {!isLoading ? (
        <div className={styles.content}>
          <div className={styles.detailsContainer}>
            {offerIteration && (
              <>
                <Details data={offerIteration} offerData={offerDetailsData} />
                {!isActionDisabled && (
                  <Actions
                    data={offerIteration}
                    offerData={offerData}
                    onClose={onClose}
                    setOfferCompareModalIsOpen={setOfferCompareModalIsOpen}
                    columnName={offerIteration?.IterationStatus}
                    transactionId={transactionId}
                    fullAccess={fullAccess}
                    setReloadOffer={setReloadOffer}
                    readOnly={readOnly}
                  />
                )}
              </>
            )}
            <Tabs
              activeKey={tabKey}
              onChange={setTabKey}
              defaultActiveKey={defaultTabOpen ?? '2'}
              className={classnames(styles.tabWrapper, {
                [styles.tabWrapper2]: isActivityDisabled,
              })}
            >
              {TABS_ITEMS.map(({ key, icon, children, label }) => (
                <TabPane
                  tab={
                    <div className={styles.tabRow}>
                      <span>{icon}</span>
                      <span>
                        {label}{' '}
                        {key === '2' && (
                          <span
                            className={
                              unreadMessagesCount ? styles.unreadCount : styles.regularCount
                            }
                          >
                            (
                            {unreadMessagesCount
                              ? `${unreadMessagesCount} New`
                              : messages?.length || 0}
                            )
                          </span>
                        )}
                      </span>
                    </div>
                  }
                  key={key}
                >
                  {children}
                </TabPane>
              ))}
            </Tabs>
          </div>

          {offerIteration && (
            <Highlights data={{ ...offerIteration, OfferCount: offerData?.count }} />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

export default OfferDetailsModal;
