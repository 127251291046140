import { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FlatPickr from 'react-flatpickr';
import classNames from 'classnames';
import moment from 'moment';
import { Popover } from 'components-antd';
import { TimelineCalendar } from 'components/Icons';
import { DaysFromToday } from './plugins';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import styles from './styles.module.scss';
import 'flatpickr/dist/themes/material_blue.css';
import './styles.scss';
import { createPortal } from 'react-dom';

const DatePicker = (props) => {
  const {
    id,
    name,
    value,
    onChange,
    onDaysFromTodayChange,
    className,
    options,
    label,
    daysFromToday,
    enableDateControls,
    showAsterisk,
    datePickerInputClass,
    datePickerErrorClass,
    autofocus,
    placeholder,
    disabled,
    error,
    variant,
    testid,
    icon,
    format,
    openCalendar,
    popoverCalender,
    scrollToCalendar,
    placement,
    allowTypedInput,
    allowPlugin,
    getPopupContainer,
    debounce = false,
    inputInnerClass = '',
    scrollToBottom = false,
    monthOnly = false,
    modalDatePicker = false,
    dateInputId = '',
    closeOnOutsideClick = false,
    allowedMonthsByYear = null,
    isBusinessDaysOnly = false,
  } = props;
  const fp = useRef(null);
  const buttonRef = useRef();

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showCalendarPopover, setShowCalendarPopover] = useState(false);

  useEffect(() => {
    if (fp.current && fp.current.flatpickr) {
      const calendarContainer = fp.current.flatpickr.calendarContainer;
      if (calendarContainer) {
        const daysFromTodayWrapper = calendarContainer.querySelector('.days-from-today-wrapper');
        const inputElement = daysFromTodayWrapper?.querySelector('input[type="text"]');
        if (inputElement) {
          inputElement.value = '';
        }
      }
    }
  }, [fp]);

  const onChangeHandler = useCallback(
    (newDate) => {
      if (debounce) {
        // Clear the previous timeout to avoid multiple setTimeouts
        if (onChangeHandler.timeoutId) {
          clearTimeout(onChangeHandler.timeoutId);
        }

        // Set a new timeout to trigger the debounced onChangeHandler function after 300 milliseconds
        onChangeHandler.timeoutId = setTimeout(() => {
          onChange({ target: { value: newDate, name } }, newDate);
        }, 300);
      } else {
        onChange({ target: { value: newDate, name } }, newDate);
      }
    },
    [name, onChange],
  );

  const fpScrollToView = () => {
    if ((scrollToCalendar && popoverCalender) || scrollToBottom) {
      const calendarContainer = fp.current?.flatpickr?.calendarContainer;
      if (calendarContainer) {
        calendarContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  useEffect(() => {
    if (openCalendar) {
      fp?.current?.flatpickr?.open();
    }
  }, [openCalendar]);

  useEffect(() => {
    const handlePosition = () => {
      const inputElement = document.getElementById(dateInputId);
      if (inputElement) {
        const rect = inputElement.getBoundingClientRect();

        const isMobile = window.innerWidth <= 575;
        const isLandscape = window.innerWidth >= window.innerHeight && window.innerHeight <= 900;
        const calendarHeight = isMobile ? 390 : isLandscape ? 340 : 463;

        const offsetBottom = isMobile ? 80 : isLandscape ? 120 : 0;
        const offsetTop = isMobile ? 140 : isLandscape ? 180 : 100;

        // Calculate available space above and below the input
        const spaceBelow = window.innerHeight - rect.bottom;

        // Determine where to position the calendar
        const placement = spaceBelow > calendarHeight ? 'bottom' : 'top';

        const topPosition =
          placement === 'bottom'
            ? rect.bottom + window.scrollY - offsetBottom
            : rect.top - calendarHeight + window.scrollY + offsetTop;

        setDropdownPosition({
          top: topPosition,
          left: isMobile ? 0 : rect.left + window.scrollX,
          placement,
        });
      }
    };

    if (showCalendarPopover && dateInputId) {
      handlePosition();
      window.addEventListener('resize', handlePosition);
    }

    return () => {
      window.removeEventListener('resize', handlePosition);
    };
  }, [showCalendarPopover]);

  const onDaysFromTodayChangeHandler = useCallback(
    (newOffset) => {
      onDaysFromTodayChange({ target: { value: newOffset, name } }, newOffset);
    },
    [onDaysFromTodayChange, name],
  );

  const onDatePickerClick = () => {
    if (popoverCalender) {
      popoverCalender && fp?.current?.flatpickr?.open();
      modalDatePicker && setShowCalendarPopover(true);
    }
  };

  const disableInvalidDates = (_, __, instance) => {
    const year = instance.currentYear;
    const monthEls = document.querySelectorAll('.flatpickr-monthSelect-month');
    setTimeout(() => {
      monthEls.forEach((monthEl, index) => {
        if (!allowedMonthsByYear?.[year] || !allowedMonthsByYear[year].includes(index + 1)) {
          monthEl.classList.add('disabled');
        } else {
          monthEl.classList.remove('disabled');
        }
      });
    }, 10);
  };

  const flatPickrCalender = () => (
    <FlatPickr
      id={id}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      className={classNames(styles.inputField, datePickerInputClass, {
        [styles.modalFlatPicker]: modalDatePicker,
      })}
      value={value}
      onChange={onChangeHandler}
      autoFocus={autofocus}
      ref={fp}
      onOpen={fpScrollToView}
      options={{
        disable: [
          ...(allowedMonthsByYear
            ? [
                function (date) {
                  const year = date.getFullYear();
                  const month = date.getMonth() + 1;
                  return !(
                    allowedMonthsByYear?.[year] && allowedMonthsByYear?.[year].includes(month)
                  );
                },
              ]
            : []),
          ...(isBusinessDaysOnly
            ? [
                function (date) {
                  // Disable weekends (Saturday = 6, Sunday = 0)
                  return date.getDay() === 6 || date.getDay() === 0;
                },
              ]
            : []),
        ],
        onYearChange: allowedMonthsByYear ? disableInvalidDates : [],
        onReady: allowedMonthsByYear ? disableInvalidDates : [],
        clickOpens: !enableDateControls,
        enableTime: true,
        dateFormat: format,
        mode: 'single',
        disableMobile: true,
        monthSelectorType: 'static',
        allowInput: allowTypedInput,
        parseDate: (dateStr) => {
          return moment(dateStr).toDate();
        },
        plugins: monthOnly
          ? [
              monthSelectPlugin({
                shorthand: true,
                dateFormat: 'm-Y',
                altFormat: 'F Y',
              }),
            ]
          : allowPlugin
          ? [
              new DaysFromToday({
                value: daysFromToday,
                onChange: onDaysFromTodayChangeHandler,
                placeholder: '# Days',
              }),
            ]
          : [],
        closeOnSelect: true,
        static: true,
        ...options,
      }}
      onClose={() => {
        showCalendarPopover && setShowCalendarPopover(false);
        closeOnOutsideClick && fp?.current?.flatpickr?.close();
      }}
    />
  );

  const renderPopoverCalendar = () => {
    if (modalDatePicker) {
      return createPortal(
        <div>
          <div
            style={{
              position: 'absolute',
              top: dropdownPosition.top,
              left: dropdownPosition.left,
            }}
          >
            <Popover
              placement={dropdownPosition.placement}
              open={true}
              overlayClassName={styles.datePickerPopoverOverlay}
              content={<div className={styles.datePickerPopover}>{flatPickrCalender()}</div>}
              getPopupContainer={getPopupContainer}
            ></Popover>
          </div>
        </div>,
        document.body,
      );
    }

    return (
      <Popover
        placement={placement}
        open={true}
        overlayClassName={styles.datePickerPopoverOverlay}
        content={<div className={styles.datePickerPopover}>{flatPickrCalender()}</div>}
        getPopupContainer={getPopupContainer}
      ></Popover>
    );
  };

  return (
    <div
      testid={testid}
      className={classNames(
        styles.datePickerInput,
        styles[variant],
        { [styles.datePickerUnderline]: popoverCalender },
        { [styles.monthOnly]: monthOnly },
        className,
      )}
      onClick={onDatePickerClick}
      ref={buttonRef}
    >
      {label && (
        <label htmlFor={id}>
          <span>
            {label}
            {showAsterisk ? <span className={styles.required}> *</span> : ''}
          </span>
        </label>
      )}

      <div className={classNames(styles.inputInner, inputInnerClass)}>
        {icon && <TimelineCalendar className={styles.icon} />}
        {popoverCalender ? renderPopoverCalendar() : flatPickrCalender()}
      </div>
      {error && <div className={classNames(styles.error, datePickerErrorClass)}>{error}</div>}
    </div>
  );
};

DatePicker.LIGHT = 'light';
DatePicker.ROUNDED = 'rounded';

DatePicker.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  daysFromToday: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  ]),
  onChange: PropTypes.func,
  onDaysFromTodayChange: PropTypes.func,
  options: PropTypes.shape({}),
  error: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  icon: PropTypes.bool,
  enableDateControls: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  datePickerInputClass: PropTypes.string,
  datePickerErrorClass: PropTypes.string,
  autofocus: PropTypes.bool,
  format: PropTypes.string,
  openCalendar: PropTypes.bool,
  popoverCalender: PropTypes.bool,
  scrollToCalendar: PropTypes.bool,
  placement: PropTypes.string,
  allowTypedInput: PropTypes.bool,
  allowPlugin: PropTypes.bool,
  getPopupContainer: PropTypes.func,
  inputInnerClass: PropTypes.string,
  scrollToBottom: PropTypes.bool,
  monthOnly: PropTypes.bool,
  modalDatePicker: PropTypes.bool,
  dateInputId: PropTypes.string,
  closeOnOutsideClick: PropTypes.bool,
  isBusinessDaysOnly: PropTypes.bool,
};

DatePicker.defaultProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  className: '',
  value: null,
  onChange: () => {},
  onDaysFromTodayChange: () => {},
  options: {},
  placeholder: 'MM/DD/YY',
  disabled: false,
  error: undefined,
  variant: DatePicker.LIGHT,
  daysFromToday: '',
  testid: undefined,
  icon: true,
  enableDateControls: false,
  showAsterisk: false,
  format: 'F j, Y',
  openCalendar: false,
  popoverCalender: false,
  scrollToCalendar: false,
  placement: '',
  allowTypedInput: true,
  allowPlugin: true,
  debounce: false,
  scrollToBottom: false,
  inputInnerClass: '',
  monthOnly: false,
  modalDatePicker: false,
  dateInputId: '',
  closeOnOutsideClick: false,
  isBusinessDaysOnly: false,
};

export default DatePicker;
