import { useSelector, useDispatch } from 'react-redux';

import {
  getFormNamesSelector,
  getFormMetaSelector,
  getFormClientNamesSelector,
  getFormOwnerNamesSelector,
} from 'store/selectors/requestFormProcess';

import { getProjectsSelector } from 'store/selectors/projects';
import { getAggregatePageTypeSelector } from 'store/selectors/workshop';
import { FilterSections } from './FilterSections/FilterSections';
import { updateFormsFiltersAction } from 'store/actions/formProcess';
import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';

export const Filter = (props) => {
  const { isArchive } = props;

  const { transactions } = useSelector(getFormMetaSelector);
  const formNames = useSelector(getFormNamesSelector);
  const clientNames = useSelector(getFormClientNamesSelector);
  const projects = useSelector(getProjectsSelector);
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const teamRole = useSelector(getAgentTeamRoleSelector);

  const isOwnerOrAdmin = teamRole === TEAM_OWNER || teamRole === TEAM_ADMIN;

  const formOwners = useSelector(getFormOwnerNamesSelector);

  const dispatch = useDispatch();

  const doneHandler = (p) => {
    const filters = addProOrTransFilterParam(aggregatedPageType, p);

    dispatch(updateFormsFiltersAction({ search: undefined, ...filters }));
  };

  const resetHandler = () => {
    dispatch(updateFormsFiltersAction({ search: undefined }));
  };

  return (
    <FilterSections
      onDone={doneHandler}
      onReset={resetHandler}
      isArchive={isArchive}
      formNames={formNames}
      taskTransactions={transactions}
      projects={projects}
      clientNames={clientNames}
      formOwners={formOwners}
      isOwnerOrAdmin={isOwnerOrAdmin}
    />
  );
};
