import { Marker, SearchIcon } from 'components/Icons';
import React, { useRef } from 'react';
import { RefSelectProps } from 'antd/lib/select';
import { AsyncSelect } from 'components';

import { getSchoolsDistrictsAutocompleteResults } from 'api/listingDetail';
import { SelectOption } from 'types';

import styles from './styles.module.scss';
interface SchoolsDistrictsSearchProps {
  value: SpecificSchoolOrDistrict[];
  onChange: (newValue: SpecificSchoolOrDistrict | SpecificSchoolOrDistrict[]) => void;
}

export const SchoolsDistrictsSearch: React.FC<SchoolsDistrictsSearchProps> = (props) => {
  const selectRef = useRef<RefSelectProps | null>(null);

  return (
    <div className={styles.container}>
      <AsyncSelect
        value={props.value}
        ref={selectRef}
        onChange={(e) => {
          const select = selectRef.current as any;
          if (select) {
            select.blur();
          }
          props.onChange(e as SpecificSchoolOrDistrict[]);
        }}
        mode="multiple"
        fetchOptions={fetchAutocompleteData}
        placeholder={<p className={styles.placeholder}>Enter school(s) district(s)</p>}
        style={{ width: '100%' }}
        className={styles.schoolsDistrictSelect}
      />
      <div className={styles.icon}>
        <Marker size={24} color="#747475" />
      </div>
    </div>
  );
};

export interface SpecificSchoolOrDistrict extends SelectOption {
  value: string;
}

async function fetchAutocompleteData(searchString: string): Promise<SpecificSchoolOrDistrict[]> {
  if (!searchString) {
    return [];
  }

  const response = await getSchoolsDistrictsAutocompleteResults({
    text: searchString,
  });

  const items = response.data.result.Items;
  return items.map((item) => ({
    label: item.State && item.County ? `${item.Name} (${item.County}, ${item.State})` : item.Name,
    value: JSON.stringify({
      Id: item.Id,
      Name: item.Name,
      Type: item.Type,
      State: item.State,
      County: item.County,
    }),
  }));
}
