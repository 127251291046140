import {
  deleteTaskTemplate,
  getTaskTemplateById,
  postTaskTemplate,
  putTaskTemplate,
} from 'api/templates';
import {
  requestDeleteTaskTemplateAction,
  requestGetTaskTemplateAction,
  requestPostTaskTemplateAction,
  requestPutTaskTemplateAction,
  setTaskTemplateDrawerOpenedAction,
} from 'store/actions/template';
import Api from 'store/effects/core/api';
import { getAllTaskTemplatesEffect } from 'store/effects/templates';
import { get, isNumber } from 'lodash-es';

export const getTaskTemplateEffect = ({ id }, options, cb) => {
  const requestParams = { action: requestGetTaskTemplateAction, method: getTaskTemplateById };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest({ id }, options, cb);
};

export const setTaskTemplateDrawerOpenedEffect = (isOpened, params) => (dispatch) => {
  dispatch(
    setTaskTemplateDrawerOpenedAction({
      isDrawerOpened: isOpened,
      drawerParams: params || {},
    }),
  );
};
export const getTaskTemplateSendData = (values) => ({
  Name: get(values, 'Name') || undefined,
  Description: get(values, 'Description') || undefined,
  DueDate: values?.DueDate || values?.DueDate == 0 ? +values.DueDate : undefined,
  Initial: values?.Initial,
  Operator: values?.Operator,
  ControlOperatorOffset: values?.ControlOperatorOffset || undefined,
  Categories: values?.Categories?.map((item) => item?.Name || item),
  Checklists: values?.Checklists
    ? (values?.Checklists || []).filter((checklist) => !!checklist.Name)
    : undefined,
  Links: get(values, 'Links') || undefined,
  Documents: values?.Documents?.length
    ? values.Documents.map((document) => ({
        Title: document?.Title || document?.documentName,
        Category:
          document?.customTag ||
          document?.category?.name ||
          document?.category?.Name ||
          document?.category,
        File: {
          ContentType: document?.ContentType || document?.contentType || document?.Meta?.MimeType,
          Filename: document?.Filename || document?.filename || document?.Title,
          Size: document?.Size || document?.size,
          Url: document?.Url || document?.url,
          DocumentVaultUUID: document?.DocumentVaultUUID,
          Data:
            document?.Data || document?.data ? btoa(document?.Data || document?.data) : undefined,
        },
      }))
    : undefined,
  AssigneeRoles: get(values, 'AssigneeRoles'),
  CcRoles: get(values, 'CcRoles'),
  People: values?.People || undefined,
  Teams: values?.Teams || undefined,
  Locations: values?.Locations,
  CampaignId: values?.CampaignId,
  EmailTemplateId: values?.EmailTemplate?.EmailTemplateId,
  Hours: isNumber(values?.Hours) ? values?.Hours : null,
  Minutes: isNumber(values?.Minutes) ? values?.Minutes : null,
  IsBusinessDays: values?.IsBusinessDays,
  Timezone: values?.Timezone,
  ...(values?.RepeatOption ? { RepeatOption: values?.RepeatOption } : {}),
  ...(values?.CustomRepeatOption ? { CustomRepeatOption: values?.CustomRepeatOption } : {}),
  ...(values?.RepeatCount ? { RepeatCount: values?.RepeatCount } : {}),
  ...(values?.RepeatOn ? { RepeatOn: values?.RepeatOn } : {}),
  ...(values?.RepeatOccurrence ? { RepeatOccurrence: values?.RepeatOccurrence } : {}),
  ...(values?.RepeatUntilDate ? { RepeatUntilDate: values?.RepeatUntilDate } : {}),
  ...(values?.RepeatOption ? { EndsOnClosing: values?.EndsOnClosing } : {}),
  ...(values?.RepeatOption ? { EndsOnListingExpiration: values?.EndsOnListingExpiration } : {}),
});

export const postTaskTemplateEffect = (data, options, cb) => {
  const requestParams = { action: requestPostTaskTemplateAction, method: postTaskTemplate };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTaskTemplateSendData(data);
  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTaskTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const putTaskTemplateEffect = (data, options, cb) => {
  const requestParams = { action: requestPutTaskTemplateAction, method: putTaskTemplate };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTaskTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTaskTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const deleteTaskTemplateEffect = ({ id }, options, cb) => {
  const requestParams = { action: requestDeleteTaskTemplateAction, method: deleteTaskTemplate };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest({ id }, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllTaskTemplatesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};
