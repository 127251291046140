import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getIfExistThread } from 'helpers/threads';
import {
  openCommentsDrawerEffect,
  setCurrentCommentsThreadIdEffect,
  setCommentsDrawerParamsEffect,
} from 'store/effects/drawers/comments';
import { setSharePropertyEffect } from 'store/effects';
import {
  Drawer,
  SendMessageForm,
  DrawerComparePhotos,
  Participants,
  Messages,
  Wrapper as PendingWrapper,
} from 'components';
import {
  resetMessagesEffect,
  socketsGetMessagesByThreadIdEffect,
  sendNewMessageEffect,
  editMessageEffect,
  deleteMessageEffect,
} from 'store/effects/sockets/messages';
import {
  createNewThreadEffect,
  sendThreadOpenedEffect,
  sendThreadsClosedEffect,
} from 'store/effects/sockets/threads';
import { SOCKET_THREAD_TYPES, SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';
import { getPropertyAddress } from 'helpers';

import { useLocation, useHistory } from 'react-router-dom';
import { LocationService } from 'services';
import PaddingWrapper from './PaddingWrapper';
import MessagesHeader from './MessagesHeader';
import MessagesSubheader from './MessagesSubheader';
import { commentsDrawerInfoSelector } from './selectors';
import Header from './Header';
import NewCommentHeader from './NewCommentHeader';
import Threads from './Threads';
import SocketConnectionWrapper from '../components/SocketConnectionWrapper';

import styles from './styles.module.scss';
import { relatedEntitiesGetDataEffect } from 'store/effects/relatedEntities';
import { requestGetQuotesAuditLogsEffect } from 'store/effects/quotes';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';
import { isArray, uniqBy } from 'lodash-es';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';
import { buildMessageTopicName, getMessageMetaData } from 'utils';

const drawerAnimationDuration = 300;

const locationSrv = new LocationService();

const CommentsDrawer = ({ className, initialParticipants }) => {
  const dispatch = useDispatch();
  const {
    drawerOpened,
    currentThreadId,
    shareProperty,
    threads,
    messages,
    agents,
    params,
    userId,
  } = useSelector(commentsDrawerInfoSelector);
  const [isNewComment, setIsNewComment] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [isThreadCreating, setIsThreadCreating] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  useEffect(() => {
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, []);

  useEffect(() => {
    if (initialParticipants?.length) {
      setParticipants((prevState) =>
        uniqBy([...prevState, ...initialParticipants.filter((p) => !!p && p?.id)], 'id'),
      );
    }
  }, [initialParticipants]);

  useEffect(() => {
    dispatch(requestGetUserParticipantsListEffect({}, { silent: true }));
  }, []);

  locationSrv.setLocation(location);

  useEffect(() => {
    if ((shareProperty && !threads.length) || isArray(shareProperty)) {
      setIsNewComment(true);
      setParticipants([]);
    }
  }, [threads]);
  useEffect(() => {
    if (drawerOpened) {
      const query = locationSrv.getQuery();
      history.replace(locationSrv.setQuery({ ...query, threadId: undefined }));

      dispatch(relatedEntitiesGetDataEffect());
      dispatch(requestGetQuotesAuditLogsEffect());
    }
  }, [drawerOpened]); // eslint-disable-line

  useEffect(() => {
    if (!drawerOpened && !params?.scheduleTour) {
      setTimeout(() => {
        dispatch(setSharePropertyEffect(null));
        setIsNewComment(false);
      }, drawerAnimationDuration);
    }
  }, [drawerOpened]); // eslint-disable-line

  // When user is opened some thread - notify backend about this
  useEffect(() => {
    if (drawerOpened && !params?.scheduleTour) {
      if (currentThreadId) {
        dispatch(sendThreadOpenedEffect({ threadId: currentThreadId }));
      }
      return () => dispatch(sendThreadsClosedEffect());
    }
  }, [currentThreadId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNewComment = () => {
    setIsNewComment(true);
    setParticipants([]);
  };

  const onCloseDrawer = () => {
    if (params?.scheduleTour) {
      dispatch(
        openCommentsDrawerEffect({
          open: false,
          params: { scheduleTour: params?.scheduleTour },
        }),
      );
      setTimeout(() => {
        dispatch(openCommentsDrawerEffect({ params: {} }));
      }, drawerAnimationDuration);
    } else {
      dispatch(openCommentsDrawerEffect({ open: false }));
      setTimeout(() => {
        dispatch(openCommentsDrawerEffect({ params: {} }));
      }, drawerAnimationDuration);
    }
    setTimeout(() => dispatch(resetMessagesEffect()), drawerAnimationDuration);
  };

  const getHeader = () => {
    if (currentThreadId) {
      return (
        <MessagesHeader
          className={styles.threadHeadActions}
          threadId={currentThreadId}
          shareProperty={shareProperty}
          onBackToAllComments={() => setIsNewComment(false)}
        />
      );
    }

    if (isNewComment || params?.scheduleTour) {
      return (
        <NewCommentHeader
          onClose={() => {
            setTimeout(() => setIsNewComment(false), drawerAnimationDuration);
            onCloseDrawer();
          }}
        />
      );
    }

    return (
      <Header
        onClose={() => dispatch(openCommentsDrawerEffect({ open: false }))}
        onNewComment={onNewComment}
      />
    );
  };

  const getSubheader = () => {
    if (currentThreadId) {
      return null;
    }

    if (!isNewComment && !params?.scheduleTour) {
      return (
        <PaddingWrapper className={styles.photosWrapper}>
          <DrawerComparePhotos compares={shareProperty ? [shareProperty] : []} />
        </PaddingWrapper>
      );
    }
    return null;
  };

  const onSendMessage = (message, attachments, tags, messageId, threadId, shareProperty) => {
    if (messageId) {
      return dispatch(
        editMessageEffect({
          MessageId: messageId,
          Text: message,
          Attachments: attachments,
          MessageType: SOCKET_MESSAGE_TYPES.PROPERTY_COMMENT,
          MessageMeta: Array.isArray(shareProperty)
            ? shareProperty.map((property) => ({
                EntityId: property?.Id,
                EntityData: { PropertyAddress: property?.Address },
                Tags: tags,
              }))
            : {
                EntityId: shareProperty?.Id,
                EntityData: { PropertyAddress: shareProperty.Address },
                Tags: tags,
              },
        }),
      );
    }

    return dispatch(
      sendNewMessageEffect(
        {
          ThreadId: threadId || currentThreadId,
          Text: message,
          Attachments: attachments,
          RequestTour: params?.scheduleTour ? true : undefined,
          MessageType: SOCKET_MESSAGE_TYPES.PROPERTY_COMMENT,
          MessageMeta: getMessageMetaData(shareProperty, tags),
        },
        {},
        (err) => {
          if (!err && params?.scheduleTour) {
            dispatch(setCommentsDrawerParamsEffect({ params: {} }));
          }
          if (!err) {
            dispatch(socketsGetMessagesByThreadIdEffect({ Id: threadId || currentThreadId }));
          }
        },
      ),
    );
  };

  const onDeleteMessage = useCallback(
    (messageId) => {
      return dispatch(
        deleteMessageEffect({
          MessageId: messageId,
        }),
      );
    },
    [dispatch],
  );

  const onCreateThreadAndSendNewMessage = (message, attachments, tags) => {
    if (!participants?.length) return null;

    const existThread = getIfExistThread(
      threads,
      [...participants, userId],
      (thread) => thread?.Type === SOCKET_THREAD_TYPES.CHAT,
    );

    if (existThread) {
      onSendMessage(message, attachments, tags, null, existThread?.Id, shareProperty);
      return dispatch(socketsGetMessagesByThreadIdEffect({ Id: existThread?.Id }));
    } else {
      setIsThreadCreating(true);

      dispatch(
        createNewThreadEffect(
          {
            Type: SOCKET_THREAD_TYPES.CHAT,
            TopicName: buildMessageTopicName(shareProperty),
            ParticipantIds: participants.map((partic) => partic?.id),
            FirstMessage: {
              Text: message,
              Attachments: attachments,
              MessageType: SOCKET_MESSAGE_TYPES.PROPERTY_COMMENT,
              MessageMeta: getMessageMetaData(shareProperty, tags),
            },
            RequestTour: params?.scheduleTour ? true : undefined,
          },
          {},
          (err, data) => {
            if (!err) {
              dispatch(setCurrentCommentsThreadIdEffect({ threadId: data?.Id }));
              dispatch(socketsGetMessagesByThreadIdEffect({ Id: data?.Id }));
            }
            setIsThreadCreating(false);

            if (!err && params?.scheduleTour) {
              dispatch(setCommentsDrawerParamsEffect({ params: {} }));
            }
          },
        ),
      );
    }
  };

  const onChangeParticipants = (partic) => {
    setParticipants(partic);
  };

  const getMessageFormValue = () =>
    params?.scheduleTour
      ? "Hi, I'm interested in seeing this property and would like to schedule a tour. Here are some dates and times that I'm available: "
      : '';

  const getContent = () => {
    if (currentThreadId) {
      return (
        <SendMessageForm
          id="commentsDrawer"
          onSend={onSendMessage}
          value={getMessageFormValue()}
          subheader={<MessagesSubheader shareProperty={shareProperty} />}
          shareProperty={
            shareProperty ? (Array.isArray(shareProperty) ? shareProperty : [shareProperty]) : []
          }
          content={
            <Messages
              threadId={currentThreadId}
              list={messages}
              onDelete={onDeleteMessage}
              className={styles.messages}
            />
          }
        />
      );
    }

    if (isNewComment || params?.scheduleTour) {
      return (
        <SendMessageForm
          isNewMessage
          participantsCount={participants?.length}
          id="commentsDrawer"
          onSend={onCreateThreadAndSendNewMessage}
          value={getMessageFormValue()}
          shareProperty={
            shareProperty ? (Array.isArray(shareProperty) ? shareProperty : [shareProperty]) : []
          }
          subheader={
            <PaddingWrapper className={styles.subheaderWrapper}>
              <Participants
                onChange={onChangeParticipants}
                className={styles.participants}
                prefixClassName={styles.prefixParticipants}
                valuesClassName={styles.valuesClassName}
                value={participants}
              />
            </PaddingWrapper>
          }
          content={
            <PendingWrapper className={styles.threadCreationSpinner} isPending={isThreadCreating} />
          }
        />
      );
    }

    return <Threads threads={threads} />;
  };

  return (
    <Drawer
      onClose={onCloseDrawer}
      className={classNames(styles.commentsDrawer, className)}
      isOpen={drawerOpened}
      header={getHeader()}
      testid="comments_drawer"
    >
      <SocketConnectionWrapper>
        <div className={styles.drawerInner}>
          {getSubheader()}
          {getContent()}
        </div>
      </SocketConnectionWrapper>
    </Drawer>
  );
};

CommentsDrawer.propTypes = {
  className: PropTypes.string,
  initialParticipants: PropTypes.oneOfType([undefined, []]),
};

CommentsDrawer.defaultProps = {
  className: '',
  initialParticipants: undefined,
};

export default CommentsDrawer;
