import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Tabs } from 'components-antd';
import { Comments } from '../../../View/components';
import { Documents } from '../../../..';
import { LinkedForms } from './../../../../LinkedForms';

import { getAdminOwnerAccessSelector } from 'store/selectors/transaction';
import { CommentsEntityType } from 'api/comments';

import styles from './styles.module.scss';

enum TabKeys {
  COMMENTS = 'Comments',
  FILES = 'Files',
  LINKED_FORMS = 'Linked Forms',
}

export const RightSectionTabs = ({ task, transactionId, linkedFormsList, disabled }) => {
  const [activeKey, setActiveKey] = useState<string>(TabKeys.COMMENTS);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);

  const getCommentsContent = () => {
    if (task?.canComment) {
      return (
        <Comments
          commentAutoFocus={true}
          isModal={false}
          entityId={task?.Id}
          entityType={CommentsEntityType.taskComment}
          threadId={task.CommentsThreadId}
          messageListWrapperClassName={styles.taskCommentsList}
        />
      );
    }

    if (isAdminOrOwner) {
      return (
        <Comments
          commentAutoFocus={true}
          isModal={false}
          entityId={task?.Id}
          entityType={CommentsEntityType.taskComment}
          messageListWrapperClassName={styles.taskCommentsList}
        />
      );
    }
  };

  const LinkedFormsTabItem: {
    key: string;
    label: string;
    children: ReactNode;
  } = {
    key: TabKeys.LINKED_FORMS,
    label: TabKeys.LINKED_FORMS,
    children: (
      <div className={styles.linkedFormsContainer}>
        <LinkedForms forms={linkedFormsList} taskId={task?.Id} transactionId={transactionId} />
      </div>
    ),
  };

  const tabItems: {
    key: string;
    label: string;
    children: ReactNode;
  }[] = [
    {
      key: TabKeys.COMMENTS,
      label: TabKeys.COMMENTS,
      children: getCommentsContent(),
    },
    ...(task?.Documents?.length
      ? [
          {
            key: TabKeys.FILES,
            label: TabKeys.FILES,
            children: (
              <div className={styles.documentsContainer}>
                <Documents value={task?.Documents} taskId={task?.Id} />
              </div>
            ),
          },
        ]
      : []),
    ...(linkedFormsList?.length ? [LinkedFormsTabItem] : []),
  ];

  return (
    <div className={classNames(styles.rightSectionTabs, { [styles.disabled]: disabled })}>
      <Tabs
        activeKey={activeKey}
        size="middle"
        items={tabItems}
        className={styles.viewTaskTabs}
        onChange={(tab) => setActiveKey(tab)}
      />
      {disabled && <div className={styles.overlaySheet} />}
    </div>
  );
};
