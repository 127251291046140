export const MinusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 8H13" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export const PlusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 8H13" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
    <path d="M8 13L8 3" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export const DeleteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66797 4.66602H13.3346"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 7.33398V11.334"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33203 7.33398V11.334"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 4.66602L3.9987 12.666C3.9987 13.0196 4.13917 13.3588 4.38922 13.6088C4.63927 13.8589 4.97841 13.9993 5.33203 13.9993H10.6654C11.019 13.9993 11.3581 13.8589 11.6082 13.6088C11.8582 13.3588 11.9987 13.0196 11.9987 12.666L12.6654 4.66602"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
      stroke="#FF576D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LocationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="12" cy="11.166" rx="2.5" ry="2.5" stroke="#747475" strokeWidth="2" />
    <path
      d="M18.6663 11.1667C18.6663 14.8846 14.9802 18.3721 13.0899 19.9162C12.4484 20.4401 11.5509 20.4401 10.9095 19.9162C9.01913 18.3721 5.33301 14.8846 5.33301 11.1667C5.33301 7.48477 8.31778 4.5 11.9997 4.5C15.6816 4.5 18.6663 7.48477 18.6663 11.1667Z"
      stroke="#747475"
      strokeWidth="2"
    />
  </svg>
);

export const AvatarMini = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3334 6.66536C13.3334 8.50631 11.841 9.9987 10.0001 9.9987C8.15913 9.9987 6.66675 8.50631 6.66675 6.66536C6.66675 4.82442 8.15913 3.33203 10.0001 3.33203C11.841 3.33203 13.3334 4.82442 13.3334 6.66536Z"
      stroke="#FB913A"
      strokeWidth="2"
    />
    <path
      d="M5 16.2487C5.31162 14.6906 6.74429 13.332 8.33323 13.332H11.6667C13.2556 13.332 14.6884 14.6906 15 16.2487"
      stroke="#FB913A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MenuInfo = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5" clipPath="url(#clip0_67264_186162)">
      <path
        d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
        stroke="#252D44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8C8 9.0414 8 9.4586 8 10.5"
        stroke="#252D44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="5" r="1" fill="#252D44" />
    </g>
    <defs>
      <clipPath id="clip0_67264_186162">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const UploadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00069 17.9992C5.75297 17.9992 4.55635 17.5251 3.67407 16.6812C2.7918 15.8373 2.29614 14.6927 2.29614 13.4992C2.29614 12.3057 2.7918 11.1612 3.67407 10.3172C4.55635 9.47332 5.75297 8.99922 7.00069 8.99922C7.29537 7.6864 8.15745 6.5327 9.39728 5.79193C10.0112 5.42514 10.6993 5.17077 11.4225 5.04333C12.1456 4.9159 12.8895 4.9179 13.6118 5.04922C14.3341 5.18054 15.0205 5.43861 15.632 5.8087C16.2434 6.17878 16.7679 6.65364 17.1755 7.20615C17.583 7.75865 17.8656 8.378 18.0072 9.02881C18.1488 9.67963 18.1466 10.3492 18.0007 10.9992H19.0007C19.9289 10.9992 20.8192 11.368 21.4756 12.0243C22.1319 12.6807 22.5007 13.571 22.5007 14.4992C22.5007 15.4275 22.1319 16.3177 21.4756 16.9741C20.8192 17.6305 19.9289 17.9992 19.0007 17.9992H18.0007"
      stroke="#747475"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15L12 12L15 15"
      stroke="#747475"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12V21"
      stroke="#747475"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseModalIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_431_687)">
      <path
        d="M24 8L8 24"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8L24 24"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_431_687">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseModalIconSmall = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_71312_31740)">
      <path
        d="M18 6L6 18"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#515151"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_71312_31740">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const SeparatorDot = () => (
  <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.24" cx="2" cy="2" r="2" fill="#4673D1" />
  </svg>
);

export const DraftIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.7778 15L22.7778 24.7778C22.7778 25.3671 22.5437 25.9324 22.1269 26.3491C21.7102 26.7659 21.1449 27 20.5556 27H9.44445C8.85508 27 8.28985 26.7659 7.8731 26.3491C7.45635 25.9324 7.22223 25.3671 7.22223 24.7778V9.22222C7.22223 8.63285 7.45635 8.06762 7.8731 7.65087C8.28985 7.23413 8.85508 7 9.44445 7H17.2222L19.4444 9.22222"
      stroke="#262626"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3333 21H16.5086L27.4686 10.04C28.0223 9.4863 28.3333 8.73536 28.3333 7.95235C28.3333 7.16934 28.0223 6.4184 27.4686 5.86472C26.9149 5.31105 26.164 5 25.381 5C24.598 5 23.847 5.31105 23.2934 5.86472L12.3333 16.8248V21Z"
      stroke="#262626"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2491 6.91016L26.4244 11.0854"
      stroke="#262626"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
