import { Upload } from 'components-antd';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Avatar } from 'components';
import { showErrorMessage } from 'helpers';
import {
  AddVendorPayloadType,
  maxLogoSizeInBytes,
} from 'pages/ServicesCategory/components/AddVendorModal/constants';
import { UploadIcon } from 'pages/ServicesCategory/components/AddVendorModal/icons';

type UploadLogoPropsType = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
};

const UploadLogo = (props: UploadLogoPropsType) => {
  const { newVendorPayload, setNewVendorPayload } = props;
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    if (newVendorPayload?.LogoUrl) setUploaded(true);
  }, [newVendorPayload?.LogoUrl]);

  const validate = (file): boolean => {
    if (!file.type.startsWith('image/')) {
      showErrorMessage('Invalid file type, only JPEG and PNG is allowed!');
      return false;
    }
    if (file.size > maxLogoSizeInBytes) {
      showErrorMessage('Max image size is 5mb');
      return false;
    }
    return true;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleBeforeUpload = async (file) => {
    if (!validate(file)) return false;

    setUploading(true);
    try {
      const base64 = await getBase64(file);
      setNewVendorPayload((prev) => {
        return {
          ...prev,
          LogoFile: { ContentType: file.type, Data: base64, Filename: file.name, Size: file.size },
        };
      });
      setUploaded(true);
    } catch (error) {
      showErrorMessage('Unable to process image.');
    }

    setUploading(false);
    return false;
  };

  return (
    <div className={styles.uploadLogo}>
      {(newVendorPayload?.LogoUrl || newVendorPayload?.LogoFile) && uploaded ? (
        <div className={styles.uploaded}>
          <Avatar
            src={newVendorPayload?.LogoUrl || newVendorPayload?.LogoFile?.Data}
            placeholder={'Logo'}
            className={styles.avatar}
          />
          <div
            className={styles.remove}
            onClick={() => {
              setNewVendorPayload((prev) => {
                return { ...prev, LogoFile: undefined, LogoUrl: undefined };
              });
              setUploaded(false);
            }}
          >
            Remove
          </div>
        </div>
      ) : (
        <Upload
          className={classNames(styles.upload, uploading ? styles.uploading : '')}
          accept="image/png, image/jpeg, image/jpg"
          showUploadList={false}
          disabled={uploading}
          beforeUpload={handleBeforeUpload}
        >
          <div className={styles.icon}>
            <UploadIcon />
          </div>
          <div>Upload Logo</div>
        </Upload>
      )}
    </div>
  );
};

export default UploadLogo;
