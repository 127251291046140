import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  getPropertyReferenceDetailsAction,
  updatePropertyAddressMetaAction,
} from 'store/actions/radarPortfolio';
import { IPropertyReferenceDetails, IPropertyReferenceDetailsState } from './types';

const initialState: IPropertyReferenceDetailsState = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [getPropertyReferenceDetailsAction as any]: (state, { payload }) => ({
      state: get(payload, 'state'),
      data: get(payload, 'data', null),
      meta: get(payload, 'meta'),
    }),
    [updatePropertyAddressMetaAction as any]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        address: {
          ...state.data?.address,
          ...((payload as any)?.neighbourhoodRadius
            ? {
                neighbourhoodRadius: (payload as any)?.neighbourhoodRadius,
              }
            : {}),
          ...((payload as any)?.closingDate
            ? {
                closingDate: (payload as any)?.closingDate,
              }
            : {}),
          ...((payload as any)?.notes
            ? {
                notes: (payload as any)?.notes,
              }
            : {}),
          ...((payload as any)?.beds
            ? {
                beds: (payload as any)?.beds,
              }
            : {}),
          ...((payload as any)?.baths
            ? {
                baths: (payload as any)?.baths,
              }
            : {}),
          ...((payload as any)?.lotSize
            ? {
                lotSize: (payload as any)?.lotSize,
              }
            : {}),
          ...((payload as any)?.size
            ? {
                size: (payload as any)?.size,
              }
            : {}),
          ...((payload as any)?.propertyType
            ? {
                propertyType: (payload as any)?.propertyType,
              }
            : {}),
        },
      } as IPropertyReferenceDetails,
    }),
  },
  initialState,
);
