import { PageWrapper } from 'components-antd';
import styles from './styles.module.scss';
import { map } from 'lodash-es';
import { Icons } from './Icons';
import { routes } from 'settings/navigation/routes';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import BG_Image from 'images/pinpoint_bg.png';
import { setIsSimpleHeaderTitleVisibleAction, setSavedSearchTabAction } from 'store/actions/feedv3';
import SearchesModal from '../ClientFeed/components/SearchesModal';
import { appOpenModalEffect, getSearchInstancesEffect, sortFeedEffect } from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import {
  getFavoritesPropertiesV3Effect,
  getHighlightPropertiesV3Effect,
  getTopMatchesPropertiesV3Effect,
} from 'store/effects/feedv3';
import { getFeedSortSelector } from 'store/selectors/feed';
import Search from 'pages/Properties/Search';
import { SORT_DESC } from 'settings/constants/sort';

export const ClientDashboard = () => {
  const dispatch = useDispatch();
  const searchContext = useSelector(getCurrentContextSelector);
  const sort = useSelector(getFeedSortSelector);
  const [showSearch, setShowSearch] = useState(false);

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const handleMatchesClick = useCallback(() => {
    dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['MatchScore'] }));
    dispatch(
      getTopMatchesPropertiesV3Effect({
        queryParams: {
          sortField: 'MatchScore',
          sortOrder: SORT_DESC,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
    dispatch(
      setIsSimpleHeaderTitleVisibleAction({
        isSimpleHeaderTitleVisible: { value: true, text: 'Top Matches' },
      }),
    );
    dispatch(push(routes.feed));
  }, [dispatch, searchContext, sort]);

  const handleToursClick = useCallback(() => dispatch(push(routes.clientTours)), [dispatch]);

  const handleMySearchesClick = useCallback(() => {
    dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['MatchScore'] }));
    dispatch(getSearchInstancesEffect({ contextKey: searchContext?.ContextKey }));
    dispatch(setSavedSearchTabAction({ savedSearchTab: 1 }));
    openModal(SearchesModal.id);
  }, [dispatch, searchContext]);

  const handleFavoriteClick = useCallback(() => {
    dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['MatchScore'] }));
    dispatch(
      getFavoritesPropertiesV3Effect({
        queryParams: {
          sortField: 'MatchScore',
          sortOrder: SORT_DESC,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
    dispatch(
      setIsSimpleHeaderTitleVisibleAction({
        isSimpleHeaderTitleVisible: { value: true, text: 'Favorites' },
      }),
    );
    dispatch(push(routes.feed));
  }, [dispatch, searchContext, sort]);

  useEffect(() => {
    setIsSimpleHeaderTitleVisibleAction({
      isSimpleHeaderTitleVisible: { value: false, text: 'Favorites' },
    });
  }, []);

  const handleHighlightsClick = useCallback(() => {
    dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['MatchScore'] }));
    dispatch(
      getHighlightPropertiesV3Effect({
        queryParams: {
          sortField: 'MatchScore',
          sortOrder: SORT_DESC,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
    dispatch(
      setIsSimpleHeaderTitleVisibleAction({
        isSimpleHeaderTitleVisible: { value: true, text: 'Highlights' },
      }),
    );
    dispatch(push(routes.feed));
  }, [dispatch, searchContext, sort]);

  const handleNewSearchClick = useCallback(() => {
    dispatch(sortFeedEffect({ order: SORT_DESC, fields: ['MatchScore'] }));
    setShowSearch(true);
  }, []);

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.mainPageContent} showFooter={false}>
      {showSearch && <Search handleClose={() => setShowSearch(false)} />}
      <SearchesModal setShowSearch={setShowSearch} />
      <p className={styles.heading}>Pinpoint</p>
      <img src={BG_Image} width={'100%'} />
      <div className={styles.content}>
        <div className={styles.cardsContainer}>
          {map(
            [
              { icon: Icons.NEW_SEARCH, title: 'New Search', onClick: handleNewSearchClick },
              {
                icon: Icons.MY_SEARCH,
                title: 'My Searches',
                onClick: handleMySearchesClick,
              },
              { icon: Icons.TOP_MATCHES, title: 'Top Matches', onClick: handleMatchesClick },
              {
                icon: Icons.AGENT_HIGHLIGHTS,
                title: 'Agent Highlights',
                onClick: handleHighlightsClick,
              },
              {
                icon: Icons.FAVORITES,
                title: 'Favorites',
                onClick: handleFavoriteClick,
              },
              { icon: Icons.TOURS, title: 'Tours', onClick: handleToursClick },
            ],
            ({ icon, title, onClick }) => (
              <div
                className={styles.card}
                onClick={() => {
                  onClick();
                }}
              >
                <Icons variant={icon} />
                {title}
              </div>
            ),
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
