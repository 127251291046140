import { useEffect, useState } from 'react';
import { Row, Col, Select, InputNumber, Popover, Switch, Tag } from 'antd';
import classNames from 'classnames';

import { TimeInput } from 'components-antd';
import { Icons } from '../../../Icons';
import { ApprovalMembers } from './ApprovalMembers';
import { Checkbox } from 'components-antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Tooltip } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { showErrorMessage } from 'helpers/errors';
import { isEmail } from 'utils';

import styles from './styles.module.scss';
import moment from 'moment';

const { Option } = Select;
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const AvailabilityForm = ({
  data,
  transaction,
  delegates,
  index,
  onDelete,
  onChange,
  participantList,
  onUpdate,
  showEditForm,
  setShowEditForm,
}) => {
  const creator = transaction?.Creator;
  const seller = transaction?.Participants?.find((p) => p?.Role?.Title === 'Seller');
  const [approvalType, setApprovalType] = useState<'auto' | 'manual'>(
    data?.AutomaticApproval ? 'auto' : 'manual',
  );
  const [delegate, setDelegate] = useState([]);
  const [open, setOpen] = useState(false);
  const [isDelegateApprovalRequired, setIsDelegateApprovalRequired] = useState(false);
  const [state, setState] = useState({
    DelegateList: data?.DelegateList || [],
  });

  const renderDays = () => {
    return days.map((d, index) => (
      <Option key={index + 1} value={d}>
        {d}
      </Option>
    ));
  };
  useEffect(() => {
    if (state?.DelegateList?.length) {
      const body = {
        DelegateList: state?.DelegateList ?? [],
      };
      onUpdate(body);
    }
  }, [state.DelegateList]);

  const getInfo = () => {
    return (
      <p>
        <b>Sequential Approval</b>
        <br />
        Requests will be sent in the sequence outlined below.
        <br />
        <br />
        <b>Non-Sequential Approval</b>
        <br />
        Requests will be sent at the same time to all selected parties. All selected parties must
        approve for the showing to be confirmed.
      </p>
    );
  };

  const getApprovalInputText = () => {
    if (data.AutomaticApproval) {
      return 'Automatic Approval';
    }
    let text = '';
    if (data.SequentialApproval) {
      const approvers = data.ApprovalUsers?.sort((a, b) => a - b);
      approvers.forEach((a) => {
        text += `${a.Sequence}. ${a.Name ? `${a.Name}` : `${a.Email}`}, `;
      });
      text = text.slice(0, -2);
    } else {
      data.ApprovalUsers.forEach((a) => {
        text +=
          text.includes('Delegate') && a.Role === 'Delegate'
            ? ''
            : `${a.Role === 'SellerAgent' ? 'Agent' : a.Role}, `;
      });
      text = text.slice(0, -2);
    }
    return text;
  };

  const onApprovalChange = (obj, addApprover = true) => {
    let ApprovalUsers = data.ApprovalUsers;
    if (addApprover) ApprovalUsers.push(obj);
    else {
      const index = ApprovalUsers.findIndex(
        (u) =>
          ((obj.UserId && u.UserId === obj.UserId) || (obj.Email && u.Email === obj.Email)) &&
          u.Role === obj.Role,
      );
      const sequence = ApprovalUsers[index].Sequence;
      if (index !== -1) ApprovalUsers.splice(index, 1);

      ApprovalUsers =
        ApprovalUsers?.map((a) => {
          if (a.Sequence > sequence) {
            return {
              ...a,
              Sequence: --a.Sequence,
            };
          }
          return a;
        }) || [];
    }
    onChange(index, { ApprovalUsers });
  };

  const getMemberState = (userId, email = '', type) => {
    const member: any = data?.ApprovalUsers?.find(
      (u) => ((userId && u.UserId === userId) || (email && u.Email === email)) && u.Role === type,
    );
    return {
      selected: !!member,
      sequence: member?.Sequence || '',
    };
  };

  useEffect(() => {
    if (approvalType === 'auto') {
      onChange(index, { AutomaticApproval: true, SequentialApproval: false });
    } else {
      onChange(index, { AutomaticApproval: false, SequentialApproval: data.SequentialApproval });
    }
  }, [approvalType]);

  const renderList = (obj, filtered = false) => {
    const list: any = [];
    var filteredParticipants = participantList.filter((participant) => {
      return !delegates.some((delegate) => {
        return delegate.UserId === participant.Id;
      });
    });
    filteredParticipants?.forEach((p) => {
      if (filtered && (p.Id === creator?.Id || p.Id === seller?.Id)) return;

      const index = obj?.findIndex((f) => f?.UserId === p.Id);
      if (index === -1) {
        list.push(
          <Option key={p.Id} value={p.Email}>
            {p.FirstName} {p.LastName}
          </Option>,
        );
      }
    });

    return list;
  };
  const handleChange = (email, key) => {
    const emails = [email, ...state[key].map((val) => val.Email)];
    const participant = participantList.find(
      (p) => p.Email === email || `${p.FirstName} ${p.LastName}` === email,
    );
    if (!participant && !isEmail(email)) {
      showErrorMessage('Invalid Email');
      return;
    }
    setState({
      ...state,
      [key]: emails.map((e) => {
        const participant = participantList.find(
          (p) => p.Email === e || `${p.FirstName} ${p.LastName}` === e,
        );
        return participant
          ? {
              UserId: participant.Id,
              Email: participant.Email,
              FirstName: participant.FirstName,
              LastName: participant.LastName,
            }
          : { Email: e };
      }),
    });
    let obj;
    if (isDelegateApprovalRequired) {
      if (participant) {
        obj = {
          Email: email,
          Name: `${participant.FirstName} ${participant.LastName}`,
          RequireApproval: isDelegateApprovalRequired,
          Role: 'Delegate',
          Sequence: data.ApprovalUsers.length + 1,
          UserId: participant.Id,
        };
      } else {
        obj = {
          Email: email,
          Name: email,
          RequireApproval: isDelegateApprovalRequired,
          Role: 'Delegate',
          Sequence: data.ApprovalUsers.length + 1,
        };
      }
      setIsDelegateApprovalRequired(false);
      onApprovalChange(obj);
    }
  };
  const content = (
    <div className={styles.approvalDropdown}>
      <div className={styles.radioGroupContainer}>
        <div className={styles.toggleRadioContainer}>
          <Checkbox
            className={classNames(styles.radio, 'mosaikCheckbox')}
            onChange={() => {
              setApprovalType('auto');
            }}
            checked={approvalType === 'auto'}
          />
          <h4>Automatic </h4>
        </div>
        <div className={styles.toggleRadioContainer}>
          <Checkbox
            className={classNames(styles.radio, 'mosaikCheckbox')}
            onChange={(e: CheckboxChangeEvent) => {
              setApprovalType('manual');
            }}
            checked={approvalType === 'manual'}
          />
          <h4 className={classNames({ [styles.disabled]: data.AutomaticApproval })}>Manual</h4>
        </div>
      </div>
      {approvalType === 'manual' && (
        <div>
          <p className={classNames(styles.subTitle, { [styles.disabled]: data.AutomaticApproval })}>
            Whose approval is needed?
          </p>
          <div className={styles.approvalParticipant}>
            {creator && (
              <ApprovalMembers
                data={data}
                userId={creator.Id}
                email={creator.Email}
                memberType="Agent"
                name={`${creator.FirstName} ${creator.LastName}`}
                onChange={onApprovalChange}
                memberState={getMemberState(creator.Id, undefined, 'SellerAgent')}
              />
            )}
            {seller && (
              <ApprovalMembers
                data={data}
                userId={seller.Id}
                email={seller.Email}
                memberType="Seller"
                name={`${seller.FirstName} ${seller.LastName}`}
                onChange={onApprovalChange}
                memberState={getMemberState(seller.Id, undefined, 'Seller')}
              />
            )}
            {delegates?.length ? (
              <>
                {delegates.map((d, index) => (
                  <ApprovalMembers
                    key={index}
                    data={data}
                    userId={d.UserId}
                    email={d.Email}
                    name={d.UserId ? `${d.FirstName} ${d.LastName}` : d.Email}
                    onChange={onApprovalChange}
                    memberState={getMemberState(d.UserId, d.Email, 'Delegate')}
                  />
                ))}
              </>
            ) : null}
            <div className={styles.toggleContainer}>
              <Checkbox
                className={classNames(styles.radio, 'mosaikCheckbox')}
                onChange={() => {
                  setIsDelegateApprovalRequired((val) => !val);
                }}
                checked={isDelegateApprovalRequired}
              />
              <div className={styles.selectorContainer}>
                <Select
                  mode="tags"
                  className={styles.selector}
                  onSelect={(delegate) => {
                    setDelegate([]);
                    handleChange(delegate, 'DelegateList');
                  }}
                  onChange={(v) => {
                    setDelegate(v);
                  }}
                  placeholder={'Enter name or email'}
                  value={delegate}
                >
                  {renderList(state.DelegateList, true)}
                </Select>
              </div>
            </div>
          </div>
          <div className={styles.sequentialApprovalContainer}>
            <Switch
              className={styles.switch}
              checked={data.SequentialApproval}
              onChange={(v) => onChange(index, { SequentialApproval: v, AutomaticApproval: false })}
              disabled={data.AutomaticApproval}
            />
            <h4 className={classNames({ [styles.disabled]: data.AutomaticApproval })}>
              Sequential Approval
            </h4>
            <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
              <Tooltip
                trigger="hover"
                title={getInfo()}
                placement="top"
                open={open}
                overlayClassName={styles.fullAccessTooltip}
              >
                <Icon variant={Icon.INFO} />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {showEditForm === index ? (
        <div className={styles.availabilityForm}>
          <div className={styles.dayTimeWrap}>
            <div className={styles.days}>
              <div className={styles.daysWrap}>
                <h4 className={styles.title}>
                  <span className={styles.required}>* </span>Days of Week
                </h4>
                <Select
                  mode="multiple"
                  className={classNames(styles.selector, 'mosaikSelect')}
                  maxTagCount="responsive"
                  placeholder="Select Days"
                  value={data.DaysOfWeek}
                  tagRender={(props) => {
                    const { label, value, closable, onClose } = props;
                    return value !== 'All' ? (
                      <Tag className={styles.customTag} closable={closable} onClose={onClose}>
                        {label}
                      </Tag>
                    ) : (
                      <></>
                    );
                  }}
                  onChange={(v) => {
                    let value = v;

                    if (!v.includes('All') && !data.DaysOfWeek.includes('All') && v.length === 7) {
                      value = ['All', ...days];
                    } else if (!v.includes('All') && data.DaysOfWeek.includes('All')) {
                      value = [];
                    } else if (v.includes('All') && !data.DaysOfWeek.includes('All')) {
                      value = ['All', ...days];
                    } else if (v.includes('All')) {
                      value =
                        v.length === 7 ? v.filter((item) => item !== 'All') : ['All', ...days];
                    }

                    onChange(index, { DaysOfWeek: value });
                  }}
                  getPopupContainer={(triggerNode) => triggerNode}
                  popupClassName={`mosaikSelectDropdown ${styles.availablityDaysDropdown}`}
                >
                  <>
                    <Option key={0} value={'All'}>
                      All
                    </Option>
                    {renderDays()}
                  </>
                </Select>
              </div>
              <div className={styles.approvalWrap}>
                <h4 className={styles.title}>
                  <span className={styles.required}>* </span>Approval
                </h4>
                <Popover content={content} trigger="click">
                  <div className={styles.approvalInput}>
                    <p>{getApprovalInputText()}</p>
                  </div>
                </Popover>
              </div>
            </div>

            <div className={styles.time}>
              <h4 className={styles.title}>
                <span className={styles.required}>* </span>Time
              </h4>
              <div className="time-area">
                <TimeInput
                  value={data.StartTime as any}
                  onChange={(e: any) => onChange(index, { StartTime: e })}
                  wrapperClassName={styles.timeInput}
                  selectClassName={styles.timeSelect}
                  popupClassName={styles.timeDropdown}
                  getPopupContainer={(node) => node}
                />
                <span className={styles.separator}>-</span>
                <TimeInput
                  value={data.EndTime as any}
                  onChange={(e: any) => onChange(index, { EndTime: e })}
                  wrapperClassName={styles.timeInput}
                  selectClassName={styles.timeSelect}
                  popupClassName={styles.timeDropdown}
                  getPopupContainer={(node) => node}
                />
              </div>

              <div className={styles.noticeWrap}>
                <h4 className={styles.title}>Minimum Notice (hours)</h4>
                <InputNumber
                  min={0}
                  placeholder="Ex: 2"
                  value={data.MinimumNotice !== null && data.MinimumNotice}
                  onChange={(v) => onChange(index, { MinimumNotice: Math.floor(v) })}
                  className={styles.notice}
                />
              </div>
            </div>
          </div>
          <div className={styles.iconWrapper}>
            <Icons
              className={styles.deleteIcon}
              variant="delete"
              onClick={() => {
                onDelete(index);
                setShowEditForm(-1);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.collapseWrapper}>
          <div>
            <h4>{data?.DaysOfWeek.map((day) => day.substring(0, 3)).join(', ')}</h4>
            <p>
              {data?.StartTime && moment(data?.StartTime).format('h:mm A')} -{' '}
              {data?.EndTime && moment(data?.EndTime).format('h:mm A')}
            </p>
          </div>
          <Icons
            className={styles.editIcon}
            variant="edit"
            onClick={() => setShowEditForm(index)}
          />
        </div>
      )}
    </>
  );
};
