import React, { useCallback } from 'react';

export const ServiceIconTypes = {
  location: 'location',
  search: 'search',
  check: 'check',
  filledLocation: 'filledLocation',
  drafts: 'drafts',
  pending: 'pending',
  all: 'all',
} as const;

interface ServiceIconProps extends React.SVGProps<SVGSVGElement> {
  icon: typeof ServiceIconTypes[keyof typeof ServiceIconTypes];
}

export const ServiceIcons = ({ icon, ...props }: ServiceIconProps) => {
  const getIcon = useCallback(() => {
    switch (icon) {
      case ServiceIconTypes.location:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="12" cy="11" r="3" stroke="#515151" strokeWidth="2" />
            <path
              d="M13.0931 21.6734L13.7182 22.454L13.0931 21.6734ZM10.9069 21.6734L10.2818 22.454L10.9069 21.6734ZM19 11C19 12.9761 17.9778 14.9886 16.5776 16.7938C15.1921 18.5801 13.5321 20.0407 12.468 20.8929L13.7182 22.454C14.844 21.5524 16.6375 19.9798 18.1579 18.0196C19.6637 16.0784 21 13.6311 21 11H19ZM11.532 20.8929C10.4678 20.0407 8.8079 18.5801 7.42238 16.7938C6.02217 14.9886 5 12.9761 5 11H3C3 13.6311 4.3363 16.0784 5.84205 18.0196C7.36249 19.9798 9.15598 21.5524 10.2818 22.454L11.532 20.8929ZM5 11C5 7.13401 8.13401 4 12 4V2C7.02944 2 3 6.02944 3 11H5ZM12 4C15.866 4 19 7.13401 19 11H21C21 6.02944 16.9706 2 12 2V4ZM12.468 20.8929C12.1869 21.118 11.8131 21.118 11.532 20.8929L10.2818 22.454C11.2936 23.2643 12.7064 23.2643 13.7182 22.454L12.468 20.8929Z"
              fill="#515151"
            />
          </svg>
        );
      case ServiceIconTypes.search:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9997 18.9987L14.333 14.332"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ServiceIconTypes.check:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M4 10L8 14L16 6"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case ServiceIconTypes.filledLocation:
        return (
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="28" cy="28" r="28" fill="#FFF2F3" />
            <ellipse cx="28" cy="26.668" rx="4" ry="4" stroke="#FF576D" strokeWidth="2" />
            <path
              d="M29.098 41.1823L29.7134 41.9706L29.098 41.1823ZM37.6668 26.6667C37.6668 29.5315 36.1241 32.4069 34.123 34.9057C32.1394 37.3825 29.8142 39.3547 28.4827 40.394L29.7134 41.9706C31.1049 40.8843 33.564 38.8032 35.6841 36.1558C37.7866 33.5305 39.6668 30.2108 39.6668 26.6667H37.6668ZM27.5176 40.394C26.1861 39.3547 23.8609 37.3825 21.8773 34.9057C19.8762 32.4069 18.3335 29.5315 18.3335 26.6667H16.3335C16.3335 30.2108 18.2137 33.5305 20.3162 36.1558C22.4363 38.8032 24.8954 40.8843 26.287 41.9705L27.5176 40.394ZM18.3335 26.6667C18.3335 21.3279 22.6614 17 28.0002 17V15C21.5568 15 16.3335 20.2233 16.3335 26.6667H18.3335ZM28.0002 17C33.3389 17 37.6668 21.3279 37.6668 26.6667H39.6668C39.6668 20.2233 34.4435 15 28.0002 15V17ZM28.4827 40.394C28.1915 40.6213 27.8088 40.6213 27.5176 40.394L26.287 41.9705C27.3015 42.7625 28.6988 42.7625 29.7134 41.9706L28.4827 40.394Z"
              fill="#FF576D"
            />
          </svg>
        );
      case ServiceIconTypes.drafts:
        return (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 18C19 16.3431 20.3431 15 22 15H43.4012C44.2156 15 44.9951 15.3311 45.5605 15.9173L56.1593 26.9061C56.6986 27.4653 57 28.2119 57 28.9888V58C57 59.6569 55.6569 61 54 61H22C20.3431 61 19 59.6569 19 58V18Z"
              fill="#928CDA"
            />
            <g style={{ mixBlendMode: 'multiply' }}>
              <path
                d="M70.669 26.3136C71.4501 25.5326 71.4501 24.2662 70.669 23.4852L63.598 16.4141C62.8169 15.6331 61.5506 15.6331 60.7695 16.4141L38.5139 38.6698C38.1857 38.998 37.9819 39.4301 37.9373 39.892L37.0662 48.9256C37.0059 49.5516 37.5315 50.0773 38.1576 50.017L46.8339 49.1803C47.5268 49.1135 48.1749 48.8077 48.6672 48.3155L70.669 26.3136Z"
                fill="#FB913A"
              />
            </g>
          </svg>
        );
      case ServiceIconTypes.pending:
        return (
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 57C18 44.8497 27.8497 35 40 35C52.1503 35 62 44.8497 62 57V59.9582C62 61.6381 60.6381 63 58.9582 63H21.0418C19.3619 63 18 61.6381 18 59.9582V57Z"
              fill="#FF576D"
            />
            <path
              d="M18 23C18 35.1503 27.8497 45 40 45C52.1503 45 62 35.1503 62 23V20.0418C62 18.3619 60.6381 17 58.9582 17H21.0418C19.3619 17 18 18.3619 18 20.0418V23Z"
              fill="#FB913A"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.9653 40C50.1666 36.8758 45.3024 35 40 35C34.6977 35 29.8335 36.8758 26.0348 40C29.8335 43.1242 34.6977 45 40 45C45.3024 45 50.1666 43.1242 53.9653 40Z"
              fill="#E4364D"
            />
          </svg>
        );
      case ServiceIconTypes.all:
        return (
          <svg
            width="76"
            height="76"
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M68.4 63.6501C69.4493 63.6501 70.3058 62.7987 70.2441 61.7512C70.0325 58.1588 69.2218 54.6223 67.8413 51.2894C66.218 47.3706 63.8388 43.8099 60.8395 40.8105C57.8402 37.8112 54.2794 35.432 50.3606 33.8088C46.4418 32.1856 42.2417 31.3501 38 31.3501C33.7583 31.3501 29.5581 32.1856 25.6393 33.8088C21.7205 35.432 18.1597 37.8112 15.1604 40.8105C12.1611 43.8099 9.78187 47.3706 8.15865 51.2894C6.77811 54.6223 5.96738 58.1588 5.75582 61.7512C5.69413 62.7987 6.55062 63.6501 7.59996 63.6501L68.4 63.6501Z"
              fill="#928CDA"
            />
            <path
              d="M20.8055 27.4832C20.0635 26.7412 20.0635 25.5382 20.8055 24.7962L37.1175 8.48423C37.8595 7.74224 39.0625 7.74223 39.8045 8.48423L56.1165 24.7962C56.8585 25.5382 56.8585 26.7412 56.1165 27.4832L39.8045 43.7952C39.0625 44.5372 37.8595 44.5372 37.1175 43.7952L20.8055 27.4832Z"
              fill="#FF576D"
            />
            <path
              d="M49.9558 33.6443L39.8046 43.7954C39.0626 44.5374 37.8596 44.5374 37.1176 43.7954L26.7097 33.3876C30.3185 32.0412 34.1421 31.3501 38 31.3501C42.096 31.3501 46.1532 32.1291 49.9558 33.6443Z"
              fill="#92305D"
            />
          </svg>
        );
      default:
        return <></>;
    }
  }, [icon]);

  return getIcon();
};
