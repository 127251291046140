import moment from 'moment';
import { RadarHeader, Table } from 'pages/Radar/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusIcon } from '../components';
import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPastMemberCampaignInfoEffect,
  getUpcomingMemberCampaignInfoEffect,
  getFailedMemberCampaignInfoEffect,
  requestGetCampaignByIdEffect,
  requestGetPublishedKitsEffect,
  cancelCampaignExecEventEffect,
  retryCampaignExecEventEffect,
  getCanceledMemberCampaignInfoEffect,
  getOverDueMemberCampaignInfoEffect,
} from 'store/effects/radarKits';
import { useParams } from 'react-router-dom';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { featureFlags } from 'utils/featureFlags';
import { CampaignKitTestClock } from 'pages/Radar/RadarKits/CampaignKitTestClock/CampaignKitTestClock';
import { AddKitsModal } from '../AddKitsModal';
import { SelectedKit } from '../ViewSequence';
import { getPublishedKitsSelector, selectedSequenceSelector } from 'store/selectors/radarKits';
import {
  CancelledDataType,
  FailedDataType,
  OverdueTaskType,
  PastDataType,
  UpcomingDataType,
  useCampaignClientExecInfo,
} from './useCampaignClientExecInfo.hook';
import Spinner from 'components/Spinner';
import { ReplyKitsModal } from 'pages/Radar/RadarKits/ReplyKitsModal';
import { Wrapper } from 'components';
import classNames from 'classnames';

export const AssignedClientsView: React.FC = () => {
  const { id, clientId } = useParams<{ id: string; clientId: string }>();
  const dispatch = useDispatch();
  const [upcomingData, setUpcomingData] = useState<any[]>([]);
  const [pastData, setPastData] = useState<any[]>([]);
  const [failedData, setFailedData] = useState<any[]>([]);
  const [canceledData, setCanceledData] = useState<any[]>([]);
  const [overDueDate, setOverDueDate] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<any>(null);
  const [client, setClient] = useState<any>(null);
  const [isAddKitsModalOpen, setIsAddKitsModalOpen] = useState<boolean>(false);
  const [isReplyModelOpen, setIsReplyModelOpen] = useState<boolean>(false);
  const [replyRecord, setReplyRecord] = useState<any>(null);
  const handleOpenAddKitsModal = () => setIsAddKitsModalOpen(true);
  const handleCloseAddKitsModal = () => {
    fetchData();
    setIsAddKitsModalOpen(false);
  };

  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [retryLoading, setRetryLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState({
    upcoming: true,
    past: true,
    failed: true,
    canceled: true,
    overdue: true,
    campaign: true,
    client: true,
  });

  const fetchData = () => {
    setLoading({
      upcoming: true,
      past: true,
      failed: true,
      canceled: true,
      overdue: true,
      campaign: true,
      client: true,
    });
    dispatch(
      getUpcomingMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!upcomingData },
        (err, res) => {
          if (!err) setUpcomingData(res.data);
          setLoading((prev) => ({ ...prev, upcoming: false }));
        },
      ),
    );
    dispatch(
      getPastMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!pastData },
        (err, res) => {
          if (!err) setPastData(res.data);
          setLoading((prev) => ({ ...prev, past: false })); // Update loading state
        },
      ),
    );
    dispatch(
      getFailedMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!failedData },
        (err, res) => {
          if (!err) setFailedData(res.data);
          setLoading((prev) => ({ ...prev, failed: false })); // Update loading state
        },
      ),
    );
    dispatch(
      getCanceledMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: true },
        (err, res) => {
          if (!err) setCanceledData(res.data);
          setLoading((prev) => ({ ...prev, canceled: false })); // Update loading state
        },
      ),
    );
    dispatch(
      getOverDueMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: true },
        (err, res) => {
          if (!err) setOverDueDate(res.data);
          setLoading((prev) => ({ ...prev, overdue: false })); // Update loading state
        },
      ),
    );
    dispatch(
      requestGetCampaignByIdEffect(id, { silent: true }, (err, res) => {
        if (!err) setCampaign(res.data.result);
        setLoading((prev) => ({ ...prev, campaign: false })); // Update loading state
      }),
    );
    dispatch(
      getClientDetailsEffect({ id: clientId }, { silent: true }, (err, res) => {
        if (!err) setClient(res.data.result);
        setLoading((prev) => ({ ...prev, client: false })); // Update loading state
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cancelExecEvent = useCallback(
    async (execId) => {
      setCancelLoading(true);
      dispatch(
        cancelCampaignExecEventEffect(
          {
            CampaignId: id,
            MemberId: clientId,
            Id: execId,
          },
          { silent: true },
          (err, res) => {
            if (!err) {
              dispatch(
                getFailedMemberCampaignInfoEffect(
                  {
                    campaignId: id,
                    memberId: clientId,
                  },
                  { silent: !!failedData },
                  (err, res) => {
                    if (!err) setFailedData(res.data);
                  },
                ),
              );
              dispatch(
                getCanceledMemberCampaignInfoEffect(
                  {
                    campaignId: id,
                    memberId: clientId,
                  },
                  { silent: true },
                  (err, res) => {
                    if (!err) setCanceledData(res.data);
                  },
                ),
              );
            }
            setCancelLoading(false);
          },
        ),
      );
    },
    [id, clientId],
  );

  const retryExecEvent = useCallback(
    async (execId) => {
      setRetryLoading(true);
      dispatch(
        retryCampaignExecEventEffect(
          {
            CampaignId: id,
            MemberId: clientId,
            Id: execId,
          },
          { silent: true },
          (err, res) => {
            if (!err) {
              dispatch(
                getFailedMemberCampaignInfoEffect(
                  {
                    campaignId: id,
                    memberId: clientId,
                  },
                  { silent: !!failedData },
                  (err, res) => {
                    if (!err) setFailedData(res.data);
                  },
                ),
              );
              dispatch(
                getPastMemberCampaignInfoEffect(
                  {
                    campaignId: id,
                    memberId: clientId,
                  },
                  { silent: !!pastData },
                  (err, res) => {
                    if (!err) setPastData(res.data);
                  },
                ),
              );
            }
            setRetryLoading(false);
          },
        ),
      );
    },
    [id, clientId],
  );

  const openReplyModal = useCallback((record) => {
    setReplyRecord(record);
    setIsReplyModelOpen(true);
  }, []);

  const closeReplyModal = useCallback(() => {
    setReplyRecord(null);
    setIsReplyModelOpen(false);
  }, []);

  const { upcomingColumns, pastColumns, failedColumns, overdueColumns, canceledColumns } =
    useCampaignClientExecInfo({
      onCancel: cancelExecEvent,
      onRetry: retryExecEvent,
      onReplyClick: openReplyModal,
    });

  const [activeTab, setActiveTab] = useState<number>(0);

  const upcomingDataSource = useMemo<UpcomingDataType[]>(() => {
    return upcomingData.map((item) => {
      if (item.campaignKit) {
        return {
          key: item.campaignKit.Id,
          name: {
            title: item.campaignKit.Kit.Name,
            info: 'KIT',
          },
          assignee: item.assignee,
          scheduled: moment(item.execDate).format('MM/DD/YYYY'),
        };
      } else {
        return {
          key: item.campaignTask.Id,
          name: {
            title: item.campaignTask.Title,
            info: 'Task',
          },
          assignee: item?.campaignTask?.AssigneeList?.[0]?.FirstName,
          scheduled: moment(item.execDate).format('MM/DD/YYYY'),
        };
      }
    });
  }, [upcomingData]);

  const pastDataSource = useMemo<PastDataType[]>(() => {
    return pastData.map((item) => {
      if (item.campaignKit) {
        return {
          key: item.campaignKit.Id,
          name: {
            title: item.campaignKit.Kit.Name,
            info: 'KIT',
          },
          completed: {
            on: moment(item.execDate).format('MM/DD/YYYY'),
            by: item.assignee,
          },
          isOpened:
            item.opened && item.openedDate
              ? {
                  date: moment(item.openedDate).format('MM/DD/YYYY'),
                  time: moment(item.openedDate).format('h:mm A'),
                }
              : null,
          isClicked:
            item.clicked && item.clickedDate
              ? {
                  date: moment(item.clickedDate).format('MM/DD/YYYY'),
                  time: moment(item.clickedDate).format('h:mm A'),
                }
              : null,
          isReplied: item?.repliedDate
            ? {
                date: moment(item.repliedDate).format('MM/DD/YYYY'),
                time: moment(item.repliedDate).format('h:mm A'),
              }
            : null,
          replies: item.replies || [],
        };
      } else {
        return {
          key: item.campaignTask.Id,
          name: {
            title: item.campaignTask.Title,
            info: 'Task',
          },
          completed: {
            on: moment(item.execDate).format('MM/DD/YYYY'),
            by: item?.campaignTask?.CompletedBy,
          },
          isOpened:
            item.opened && item.openedDate
              ? {
                  date: moment(item.openedDate).format('MM/DD/YYYY'),
                  time: moment(item.openedDate).format('h:mm A'),
                }
              : null,
          isClicked:
            item.clicked && item.clickedDate
              ? {
                  date: moment(item.clickedDate).format('MM/DD/YYYY'),
                  time: moment(item.clickedDate).format('h:mm A'),
                }
              : null,
          isReplied: item?.repliedDate
            ? {
                date: moment(item.repliedDate).format('MM/DD/YYYY'),
                time: moment(item.repliedDate).format('h:mm A'),
              }
            : null,
          replies: item.replies || [],
        };
      }
    });
  }, [pastData]);

  const failedDataSource = useMemo<FailedDataType[]>(() => {
    return failedData.map((item) => {
      return {
        key: item.campaignKit.Id,
        name: {
          title: item.campaignKit.Kit.Name,
          info: 'KIT',
        },
        failed: moment(item.execDate).format('MM/DD/YYYY'),
        error: item.error,
        isRetryAble: item.isRetryAble,
        id: item.execId,
      };
    });
  }, [failedData]);

  const overdueDataSource = useMemo<OverdueTaskType[]>(() => {
    return overDueDate.map((item) => {
      return {
        name: item.campaignTask.Title,
        assignee: `${item?.campaignTask?.AssigneeList?.[0]?.FirstName || ''} ${
          item?.campaignTask?.AssigneeList?.[0]?.LastName || ''
        }`,
        scheduled: moment(item?.campaignTask?.DueDate).format('MM/DD/YYYY'),
      };
    });
  }, [overDueDate]);

  const canceledDataSource = useMemo<CancelledDataType[]>(() => {
    return canceledData.map((item) => {
      return {
        name: item.campaignKit.Kit.Name,
        assignee: client ? `${client?.FirstName} ${client?.LastName}` : '',
        canceled: item.updatedDate ? moment.utc(item.updatedDate).format('MM/DD/YYYY') : '',
      };
    });
  }, [canceledData, client]);

  const showSimulator = useMemo(
    () => featureFlags.testClock && client?.Email?.endsWith('@mosaik.io'),
    [client],
  );

  const { data: publishedKits } = useSelector(getPublishedKitsSelector);
  // Fetch Published Kits
  useEffect(() => {
    dispatch(requestGetPublishedKitsEffect({}, { silent: !!publishedKits }));
  }, []);

  const sequenceData = useSelector(selectedSequenceSelector);

  const selectedKits = useMemo<SelectedKit[]>(() => {
    if (!publishedKits || !sequenceData?.CampaignKits) return [];
    return sequenceData.CampaignKits.map((campaignKit) => {
      const kit = publishedKits.find((item) => item.KitId === campaignKit.KitId);
      return {
        ...campaignKit,
        Kits: kit?.Kits,
      };
    });
  }, [publishedKits, sequenceData]);

  const tabs = [
    ...(failedDataSource?.length
      ? [
          {
            label: 'Failed',
            count: failedDataSource?.length,
            id: 1,
          },
        ]
      : []),
    ...(overdueDataSource?.length
      ? [
          {
            label: 'Overdue',
            count: overdueDataSource?.length,
            id: 2,
          },
        ]
      : []),
    {
      label: 'Upcoming',
      count: upcomingDataSource?.length,
      id: 0,
    },
    {
      label: 'Past',
      count: pastDataSource?.length,
      id: 3,
    },
    ...(canceledDataSource?.length
      ? [
          {
            label: 'Cancelled',
            count: canceledDataSource?.length,
            id: 4,
          },
        ]
      : []),
  ];

  const Placeholder = ({ type }) => (
    <div className={styles.placeholder}>There isn’t any {type} activity for this sequence. </div>
  );

  const renderTab = () => {
    switch (activeTab) {
      case 2:
        return (
          <div>
            <Table
              title="Overdue"
              count={overdueDataSource.length}
              data={overdueDataSource}
              columns={overdueColumns}
              rowKey={(_, i) => i}
              className={styles.table}
            />
          </div>
        );
      case 1:
        return (
          <>
            {cancelLoading || retryLoading ? (
              <Spinner loaderClassName={styles.loadingSpinner} />
            ) : (
              <div>
                <Table
                  title="Failed"
                  count={failedDataSource.length}
                  data={failedDataSource}
                  columns={failedColumns}
                  rowKey={(_, i) => i}
                  className={styles.table}
                />
              </div>
            )}
          </>
        );
      case 0:
        return (
          <div>
            {upcomingDataSource.length ? (
              <Table
                title="Upcoming"
                count={upcomingDataSource.length}
                data={upcomingDataSource}
                columns={upcomingColumns}
                rowKey={(_, i) => i}
                className={styles.table}
              />
            ) : (
              <Placeholder type={'upcoming'} />
            )}
          </div>
        );

      case 3:
        return (
          <div>
            {pastDataSource.length ? (
              <Table
                title="Past"
                count={pastDataSource.length}
                data={pastDataSource}
                columns={pastColumns}
                rowKey={(_, i) => i}
                className={styles.table}
              />
            ) : (
              <Placeholder type={'past'} />
            )}
          </div>
        );

      case 4:
        return (
          <div>
            <Table
              title="Canceled"
              count={canceledDataSource.length}
              data={canceledDataSource}
              columns={canceledColumns}
              rowKey={(_, i) => i}
              className={styles.table}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className={parentStyles.pageWrapper}>
        <RadarHeader
        // rightChildren={
        //   <div className={styles.btnWrapper}>
        //     <button
        //       className={styles.btn}
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         handleOpenAddKitsModal();
        //       }}
        //     >
        //       <div>
        //         <PlusIcon />
        //       </div>
        //       New
        //     </button>
        //   </div>
        // }
        >
          <div className={parentStyles.titleInputContainer}>
            {client && (
              <div className={parentStyles.title}>{`${client.FirstName} ${client.LastName}`}</div>
            )}
            {campaign?.Name && <div className={parentStyles.description}>{campaign?.Name}</div>}
          </div>
        </RadarHeader>
        <div className={styles.tabWrapper}>
          {tabs.map(({ label, count, id }) => (
            <div
              className={classNames(styles.tabItem, { [styles.active]: activeTab === id })}
              onClick={() => setActiveTab(id)}
            >
              {label} <span>({count})</span>
            </div>
          ))}
        </div>
        {showSimulator && <CampaignKitTestClock clientId={clientId} campaignId={id} />}
        <Wrapper
          isPending={Object.values(loading)?.some((elm) => elm)}
          className={parentStyles.pageContent}
        >
          <div className={parentStyles.tableContainer}>{renderTab()}</div>
        </Wrapper>
      </div>
      {isAddKitsModalOpen && (
        <AddKitsModal
          isOpen={isAddKitsModalOpen}
          closeModal={handleCloseAddKitsModal}
          title="Add KITs"
          selectedKits={selectedKits}
          sequenceId={id}
        />
      )}
      {isReplyModelOpen && (
        <ReplyKitsModal
          isOpen={isReplyModelOpen}
          closeModal={closeReplyModal}
          replyRecord={replyRecord}
          client={client}
        />
      )}
    </>
  );
};
