import { ChildrenOption } from 'types';

export const FORM_TYPE = {
  Quote: 'Quote',
  Smart: 'Smart',
  Agent: 'Agent',
} as const;

export enum FORM_FONT_FAMILY {
  Arial = 'Arial',
  Inter = 'Inter',
  TimesNewRoman = 'Times New Roman',
}

export enum FormEditorRole {
  Agent = 'Agent',
  Client = 'Client',
  Defer = 'Defer',
}

export const SIGNATURE_MAPPING = {
  FullName: 'FullName',
  Signature: 'Signature',
  Initials: 'Initials',
  SignatureDate: 'SignatureDate',
} as const;

export const FORM_USER_ROLE = {
  Client: 'Client',
  Agent: 'Agent',
} as const;

export const FORM_USER_ROLE_COLORS = {
  Client: '#4673D1',
  Agent: '#FF576D',
  ClientAgent: '#46d182',
} as const;

export const Signature_Default_Role = {
  Buyers_Agent: `Buyer's Agent`,
  Buyer: 'Buyer',
} as const;

export const FORM_QUESTION_TYPE = {
  FreeText: 'Free Text',
  YesNo: 'Yes/No',
  Currency: 'Currency',
  Date: 'Date',
  Email: 'Email',
  Number: 'Number',
  PhoneNumber: 'Phone Number',
  FileUpload: 'File Upload',
  ListBox: 'List Box',
  Signature: 'Signature',
} as const;

export const SMART_FORM_QUESTION_TYPE = {
  FreeText: 'Free Text',
  YesNo: 'Yes/No',
  Currency: 'Currency',
  Date: 'Date',
  Email: 'Email',
  Number: 'Number',
  PhoneNumber: 'Phone Number',
  ListBox: 'List Box',
  Signature: 'Signature',
} as const;

export enum DYNAMIC_QUESTION_TYPE {
  Signature = 'Signature',
  Initials = 'Initials',
  TextBox = 'Text Box',
  CheckBox = 'Check Box',
  FullName = 'Full Name',
  Number = 'Number',
  Currency = 'Currency',
  Phone = 'Phone Number',
  DateSigned = 'Date Signed',
  Date = 'Date',
  Address = 'Address',
  StrikeThrough = 'StrikeThrough',
  RadioGroup = 'Radio Group',
}

export const FORM_STATUS_TYPE = {
  Active: 'Active',
  Draft: 'Draft',
  InActive: 'InActive',
} as const;

export const FORM_MODE = {
  Edit: 'Edit',
  View: 'View',
};

export const DEFAULT_QUESTION_META = {
  [FORM_QUESTION_TYPE.Date]: {
    DateRange: false,
  },
  [FORM_QUESTION_TYPE.ListBox]: {
    MultiSelect: false,
    ListBoxOptions: [],
  },
};

export const FORM_UPDATE_STATUS = {
  Publish: 'Publish',
  Delete: 'Delete',
  Unpublish: 'Unpublish',
  Version: 'Version',
  Copy: 'Copy',
} as const;

export const PARTNER_CHILDREN = [
  {
    value: 'BusinessName',
    label: 'Business Name',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'AdditionalBusinessName',
    label: 'Additional BusinessName',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'Email',
    label: 'Email',
    type: FORM_QUESTION_TYPE.Email,
  },
  {
    value: 'PhoneNumber',
    label: 'Phone Number',
    type: FORM_QUESTION_TYPE.PhoneNumber,
  },
  {
    value: 'Address.Line1||Address.City||Address.State||Address.Zip',
    label: 'Address',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'SuiteUnit',
    label: 'Suite Unit',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'Description',
    label: 'Description',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'Website',
    label: 'Website',
    type: FORM_QUESTION_TYPE.FreeText,
  },
];
export const CLIENT_AGENT_CHILDREN = [
  {
    value: 'FirstName||LastName',
    label: 'Name',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'FirstName',
    label: 'First name',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'LastName',
    label: 'Last name',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'Email',
    label: 'Email',
    type: FORM_QUESTION_TYPE.Email,
  },
  {
    value: 'Phones.0.PhoneNumber',
    label: 'Phone',
    type: FORM_QUESTION_TYPE.PhoneNumber,
  },
];

export const SYSTEM_OPTION_CHILDREN: ChildrenOption[] = [
  {
    label: 'Current Date (MM/DD/YYYY)',
    value: 'CurrentDate',
    type: FORM_QUESTION_TYPE.Date,
  },
];

export const PROPERTY_LISTING_CHILDREN: ChildrenOption[] = [
  {
    label: 'Id',
    value: 'Id',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Activity Status',
    value: 'ActivityStatus',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    value: 'Address.Line1,,Address.City,,Address.State,,Address.Zip',
    label: 'Full Address',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Address',
    value: 'Address',
    children: [
      {
        label: 'Line1',
        value: 'Line1',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Line2',
        value: 'Line2',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'City',
        value: 'City',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'State',
        value: 'State',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Zip',
        value: 'Zip',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Type',
        value: 'Type',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Provider Place Id',
        value: 'ProviderPlaceId',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Place Name',
        value: 'PlaceName',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Geolocation',
        value: 'Geolocation',
        children: [
          {
            label: 'Long',
            value: 'Long',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Lat',
            value: 'Lat',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
    ],
  },
  {
    label: 'Is Address Withheld',
    value: 'IsAddressWithheld',
    type: FORM_QUESTION_TYPE.YesNo,
  },
  {
    label: 'Neighborhood',
    value: 'Neighborhood',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Geolocation',
    value: 'Geolocation',
    children: [
      {
        label: 'Long',
        value: 'Long',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Lat',
        value: 'Lat',
        type: FORM_QUESTION_TYPE.Number,
      },
    ],
  },
  {
    label: 'Num Baths Total',
    value: 'NumBathsTotal',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Num Beds',
    value: 'NumBeds',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Building Info',
    value: 'BuildingInfo',
    children: [
      {
        label: 'Building Name',
        value: 'BuildingName',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Num Of Units',
        value: 'NumOfUnits',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Sqft Source',
        value: 'SqftSource',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Is Recent Rehab',
        value: 'IsRecentRehab',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Rehab Year',
        value: 'RehabYear',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Is Rebuilt',
        value: 'IsRebuilt',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Rebuilt Year',
        value: 'RebuiltYear',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Green Features',
        value: 'GreenFeatures',
        children: [
          {
            label: 'Building Verification Type',
            value: 'BuildingVerificationType',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Energy Efficient',
            value: 'EnergyEfficient',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Energy Generation',
            value: 'EnergyGeneration',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'In Door Air Quality',
            value: 'InDoorAirQuality',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Sustainability',
            value: 'Sustainability',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Water Conversion',
            value: 'WaterConversion',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
      {
        label: 'Allowed Pets',
        value: 'AllowedPets',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Max Pet Weight',
        value: 'MaxPetWeight',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Ownership',
        value: 'Ownership',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Owner Occupied Percent',
        value: 'OwnerOccupiedPercent',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Association Features',
        value: 'AssociationFeatures',
        type: FORM_QUESTION_TYPE.FreeText,
      },
    ],
  },
  {
    label: 'Photo Urls',
    value: 'PhotoUrls',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Selling Price',
    value: 'SellingPrice',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Is Auction',
    value: 'IsAuction',
    type: FORM_QUESTION_TYPE.YesNo,
  },
  {
    label: 'Square Feet',
    value: 'SquareFeet',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'New Construction',
    value: 'NewConstruction',
    type: FORM_QUESTION_TYPE.YesNo,
  },
  {
    label: 'View',
    value: 'View',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Num Of Stories',
    value: 'NumOfStories',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Parking',
    value: 'Parking',
    children: [
      {
        label: 'Num Of Parking Spaces',
        value: 'NumOfParkingSpaces',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Num Of Garage Spaces',
        value: 'NumOfGarageSpaces',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Num Of Open Parking Spaces',
        value: 'NumOfOpenParkingSpaces',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Parking Type',
        value: 'ParkingType',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Garage Features',
        value: 'GarageFeatures',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Garage On Site',
        value: 'GarageOnSite',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Garage Ownership',
        value: 'GarageOwnership',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Garage Type',
        value: 'GarageType',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Deed Parking Cost',
        value: 'DeedParkingCost',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Deed Garage Cost',
        value: 'DeedGarageCost',
        type: FORM_QUESTION_TYPE.Number,
      },
    ],
  },
  {
    label: 'Interior',
    value: 'Interior',
    children: [
      {
        label: 'Rooms',
        value: 'Rooms',
        children: [
          {
            label: 'Master Bedroom',
            value: 'MasterBedroom',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Bedroom2',
            value: 'Bedroom2',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Bedroom3',
            value: 'Bedroom3',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Bedroom4',
            value: 'Bedroom4',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Bedroom5',
            value: 'Bedroom5',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Bathroom',
            value: 'Bathroom',
            children: [
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Living Room',
            value: 'LivingRoom',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Dining Room',
            value: 'DiningRoom',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Family Room',
            value: 'FamilyRoom',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Kitchen',
            value: 'Kitchen',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Laundry',
            value: 'Laundry',
            children: [
              {
                label: 'Size',
                value: 'Size',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Level',
                value: 'Level',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Flooring',
                value: 'Flooring',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Features',
                value: 'Features',
                type: FORM_QUESTION_TYPE.FreeText,
              },
              {
                label: 'Accessibility',
                value: 'Accessibility',
                type: FORM_QUESTION_TYPE.FreeText,
              },
            ],
          },
          {
            label: 'Basement',
            value: 'Basement',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
      {
        label: 'Fireplaces',
        value: 'Fireplaces',
        children: [
          {
            label: 'Num Of Fireplaces',
            value: 'NumOfFireplaces',
            type: FORM_QUESTION_TYPE.Number,
          },
          {
            label: 'Location',
            value: 'Location',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Features',
            value: 'Features',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
      {
        label: 'Additional Rooms',
        value: 'AdditionalRooms',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Equipment',
        value: 'Equipment',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Other Features',
        value: 'OtherFeatures',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Heating',
        value: 'Heating',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Cooling',
        value: 'Cooling',
        type: FORM_QUESTION_TYPE.FreeText,
      },
    ],
  },
  {
    label: 'Exterior And Lot Details',
    value: 'ExteriorAndLotDetails',
    children: [
      {
        label: 'Exterior',
        value: 'Exterior',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Exterior Features',
        value: 'ExteriorFeatures',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Sewage',
        value: 'Sewage',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Electricity',
        value: 'Electricity',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Water',
        value: 'Water',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Details',
        value: 'Details',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Lot Size',
        value: 'LotSize',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Lot Size Dimensions',
        value: 'LotSizeDimensions',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Lot Size Source',
        value: 'LotSizeSource',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Can Owner Rent',
        value: 'CanOwnerRent',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Direction Faces',
        value: 'DirectionFaces',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Other Features',
        value: 'OtherFeatures',
        type: FORM_QUESTION_TYPE.FreeText,
      },
    ],
  },
  {
    label: 'Description',
    value: 'Description',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Market',
    value: 'Market',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Last Updated In M L S',
    value: 'LastUpdatedInMLS',
    type: FORM_QUESTION_TYPE.Date,
  },
  {
    label: 'Listing Agent Name',
    value: 'ListingAgentName',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Listing Office Name',
    value: 'ListingOfficeName',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Modified Date',
    value: 'ModifiedDate',
    type: FORM_QUESTION_TYPE.Date,
  },
  {
    label: 'Closed Date',
    value: 'ClosedDate',
    type: FORM_QUESTION_TYPE.Date,
  },
  {
    label: 'Provider',
    value: 'Provider',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Tax Amount',
    value: 'TaxAmount',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Tax Year',
    value: 'TaxYear',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Tax Exemptions',
    value: 'TaxExemptions',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Home Type',
    value: 'HomeType',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Distressed',
    value: 'Distressed',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Num Rooms',
    value: 'NumRooms',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Num Baths Full',
    value: 'NumBathsFull',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Num Baths Half',
    value: 'NumBathsHalf',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Community',
    value: 'Community',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Community Information',
    value: 'CommunityInformation',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Year Built',
    value: 'YearBuilt',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Is Senior Community',
    value: 'IsSeniorCommunity',
    type: FORM_QUESTION_TYPE.YesNo,
  },
  {
    label: 'Previous Selling Price',
    value: 'PreviousSellingPrice',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Insurance Cost',
    value: 'InsuranceCost',
    type: FORM_QUESTION_TYPE.Number,
  },
  {
    label: 'Virtual Tour Url',
    value: 'VirtualTourUrl',
    type: FORM_QUESTION_TYPE.FreeText,
  },
  {
    label: 'Open Houses',
    value: 'OpenHouses',
    children: [
      {
        label: 'Date',
        value: 'Date',
        type: FORM_QUESTION_TYPE.Date,
      },
      {
        label: 'Start Time',
        value: 'StartTime',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'End Time',
        value: 'EndTime',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Description',
        value: 'Description',
        type: FORM_QUESTION_TYPE.FreeText,
      },
    ],
  },
  {
    label: 'Association Info',
    value: 'AssociationInfo',
    children: [
      {
        label: 'Is Association',
        value: 'IsAssociation',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Fee',
        value: 'Fee',
        type: FORM_QUESTION_TYPE.Number,
      },
      {
        label: 'Fee Frequency',
        value: 'FeeFrequency',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Features Included In Fee',
        value: 'FeaturesIncludedInFee',
        type: FORM_QUESTION_TYPE.FreeText,
      },
    ],
  },
  {
    label: 'Listing Info',
    value: 'ListingInfo',
    children: [
      {
        label: 'Days On Market',
        value: 'DaysOnMarket',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Current Use',
        value: 'CurrentUse',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Directions',
        value: 'Directions',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Special Service Area',
        value: 'SpecialServiceArea',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Special Service Fee',
        value: 'SpecialServiceFee',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Township',
        value: 'Township',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Zoning',
        value: 'Zoning',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Parcel Number',
        value: 'ParcelNumber',
        type: FORM_QUESTION_TYPE.FreeText,
      },
    ],
  },
  {
    label: 'Agent Info',
    value: 'AgentInfo',
    children: [
      {
        label: 'Buyer Agency Compensation',
        value: 'BuyerAgencyCompensation',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Compensation Paid On',
        value: 'CompensationPaidOn',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Disclosures',
        value: 'Disclosures',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Sale Price Includes Parking',
        value: 'SalePriceIncludesParking',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Special Conditions',
        value: 'SpecialConditions',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Holds Earnest Money',
        value: 'HoldsEarnestMoney',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Has Virtual Photos',
        value: 'HasVirtualPhotos',
        type: FORM_QUESTION_TYPE.YesNo,
      },
      {
        label: 'Documents Available',
        value: 'DocumentsAvailable',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Exclusions',
        value: 'Exclusions',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Inclusions',
        value: 'Inclusions',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Keys',
        value: 'Keys',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Listing Agent',
        value: 'ListingAgent',
        children: [
          {
            label: 'Full Name',
            value: 'FullName',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Email',
            value: 'Email',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Phone Mobile',
            value: 'PhoneMobile',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Phone Office',
            value: 'PhoneOffice',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Fax',
            value: 'Fax',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
      {
        label: 'Occupant',
        value: 'Occupant',
        children: [
          {
            label: 'Name',
            value: 'Name',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Type',
            value: 'Type',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
      {
        label: 'Possesion',
        value: 'Possesion',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Private Remarks',
        value: 'PrivateRemarks',
        type: FORM_QUESTION_TYPE.FreeText,
      },
      {
        label: 'Showing',
        value: 'Showing',
        children: [
          {
            label: 'Phone',
            value: 'Phone',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Instructions',
            value: 'Instructions',
            type: FORM_QUESTION_TYPE.FreeText,
          },
          {
            label: 'Requirements',
            value: 'Requirements',
            type: FORM_QUESTION_TYPE.FreeText,
          },
        ],
      },
    ],
  },
];

export enum FormView {
  Wizard = 'Wizard',
  PDFEditor = 'PDFEditor',
}

export const MosaikTag = {
  Form: 'Form',
  Partner: 'Partner',
  SmartForm: 'SmartForm',
};
