import { push } from 'connected-react-router';
import { map } from 'lodash-es';

import { openThreadContactsDrawerAction } from 'store/actions/drawers/threadContacts';
import {
  openMessagesDrawerAction,
  changeMessagesDrawerTypeAction,
} from 'store/actions/drawers/messages';
import { sendThreadArchiveStatusEffect } from 'store/effects/sockets/threads';
import { openAddParticipantsDrawerEffect } from 'store/effects/drawers/addParticipants';
import { openMessageAttachmentsDrawerAction } from 'store/actions/drawers/messageAttachments';

import { link } from 'settings/navigation/link';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { DRAWER_THREAD_CONTACTS_TYPES, DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import {
  AttachmentOption,
  AddParticipantOption,
  ArchiveOption,
  ProfileOption,
  ViewActivityOption,
} from '../components/ThreadActionOptions';
import { THIRD_PARTY } from 'settings/constants/roles';
import { openQuoteActivityDrawerAction } from 'store/actions/drawers/viewQuoteActivity';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { Icons } from '../components/Icons/Icons';

export const getViewContactsOption = (dispatch, thread, cb = () => {}) => [
  {
    title: <ProfileOption optionText={'View Contacts'} />,
    onClick: () => {
      dispatch(
        openThreadContactsDrawerAction({
          params: { threadId: thread?.Id },
          type: DRAWER_THREAD_CONTACTS_TYPES.CONTACTS_LIST,
          open: true,
        }),
      );
      cb();
    },
    testid: 'view_contacts',
  },
];

export const getViewAttachmentsOption = (dispatch, params, cb = () => {}) => [
  {
    title: <AttachmentOption optionText={'View Attachments'} variant={Icons.ATTACHMENT} />,
    onClick: () => {
      dispatch(
        openMessageAttachmentsDrawerAction({
          params: { ...params, variant: 'attachment' },
          open: true,
        }),
      );
      cb();
    },
    testid: 'view_attachments',
  },
];

export const getPropertyCommentsOption = (dispatch, params, cb = () => {}) => [
  {
    title: <AttachmentOption optionText={'Property Comments'} variant={Icons.COMMENT} />,
    onClick: () => {
      dispatch(
        openMessageAttachmentsDrawerAction({
          params: { ...params, variant: 'comments' },
          open: true,
        }),
      );
      cb();
    },
    testid: 'view_attachments',
  },
];

export const getViewProfileOption = (dispatch, thread, userId, cb = () => {}) => {
  const history = useHistory();
  const participant = thread.Participants.filter((p) => p.Id !== userId)[0];
  const isClient = participant?.Roles[0] === 'Client';

  const viewer = thread.Participants.filter((p) => p.Id === userId)[0];
  const isViewerAgent = viewer?.Roles[0] === 'Agent';

  return [
    {
      title: <ProfileOption optionText={'View Profile'} />,
      onClick: () => {
        if (isClient && isViewerAgent) {
          dispatch(openMessagesDrawerAction(false));
          history.push(`${routes.clientProfile}/${participant.Id}`);
        } else {
          dispatch(
            openThreadContactsDrawerAction({
              params: {
                contactId: participant.Id,
                threadId: thread.Id,
                isTransactionMessages: false,
                isSingleContact: true,
              },
              open: true,
              type: DRAWER_THREAD_CONTACTS_TYPES.CONTACT,
            }),
          );
        }
        cb();
      },
      testid: 'view_profile',
    },
  ];
};

export const getGoToTypeOption = (
  dispatch,
  thread,
  cb = () => {},
  linkTo = link.toFeedListingDetails,
) =>
  thread.Type !== SOCKET_THREAD_TYPES.CHAT
    ? [
        {
          title: `Go to ${thread.Type}`,
          onClick: () => {
            dispatch(push(linkTo(thread.EntityId)));
            dispatch(openMessagesDrawerAction(false));
            cb();
          },
          testid: 'go_to_details',
        },
      ]
    : []; // TODO: add offers and transactions support

export const getQuoteActivityDrawerOption = (dispatch, thread, userId, cb = () => {}) => {
  const recipient = thread.Participants.filter((p) => p.Id !== userId)[0];
  const viewer = thread.Participants.filter((p) => p.Id === userId)[0];
  const recipientRole = recipient?.Roles[0];
  const viewerRole = viewer?.Roles[0];

  if (
    (recipientRole === THIRD_PARTY || viewerRole === THIRD_PARTY) &&
    thread.Participants.length <= 2
  ) {
    return [
      {
        title: <ViewActivityOption optionText={'View Activity'} />,
        onClick: () => {
          dispatch(
            openQuoteActivityDrawerAction({
              params: {
                contactId: recipient.Id,
                userId,
                threadId: thread.Id,
                isSingleContact: true,
              },
              open: true,
            }),
          );
          cb();
        },
        testid: 'go_to_view_activity',
      },
    ];
  } else {
    return [];
  }
};

export const getArchiveThreadOption = (dispatch, thread, cb = () => {}) => [
  {
    title: <ArchiveOption optionText={thread.IsArchived ? 'Remove from Archive' : 'Archive'} />,
    onClick: () => {
      dispatch(
        sendThreadArchiveStatusEffect({ threadIds: [thread?.Id], archived: !thread.IsArchived }),
      );
      cb();
    },
    testid: 'archive',
  },
];

export const getArchiveThreadsListOption = (dispatch, threads, cb = () => {}) => [
  {
    title: (
      <ArchiveOption optionText={threads?.[0]?.IsArchived ? 'Remove from Archive' : 'Archive'} />
    ),
    onClick: () => {
      dispatch(
        sendThreadArchiveStatusEffect({
          threadIds: map(threads, ({ Id }) => Id),
          archived: !threads?.[0]?.IsArchived,
        }),
      );
      cb();
    },
    testid: 'archive',
  },
];

export const getAddParticipantThreadOption = (dispatch, params) => [
  {
    title: <AddParticipantOption optionText={'Add Participant'} />,
    onClick: () =>
      dispatch(
        openAddParticipantsDrawerEffect({
          open: true,
          ...params,
        }),
      ),
    testid: 'add_participants',
  },
];

export const openChatThreadHandler = (dispatch, { threadId, ...rest } = {}) =>
  dispatch(
    changeMessagesDrawerTypeAction({
      type: threadId ? DRAWER_MESSAGES_TYPES.CHAT : DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
      params: {
        threadId,
        ...rest,
      },
    }),
  );

export const getOpenChatThreadOption = (dispatch, thread, { ...params } = {}) => [
  {
    title: 'Open message',
    onClick: () => openChatThreadHandler(dispatch, { threadId: thread.Id, ...params }),
    testid: 'open_message',
  },
];
