import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  requestClientDashboardAddressesValuationAction,
  requestClientDashboardMyTransactionsAction,
  requestClientDashboardNeedsAttentionAction,
  requestClientDashboardNewMatchesAction,
  requestClientDashboardAgentSuggestionsAction,
  requestClientDashboardAgentCommentsAction,
  requestClientDashboardBackOnMarketsAction,
  requestClientDashboardOpenHousesAction,
  requestClientDashboardPriceReductionsAction,
  requestClientDashboardUpcomingShowingsAction,
} from 'store/actions/clientDashboard';

const initialData = {
  newMatches: {
    state: IDLE,
    data: {},
    meta: {},
  },
  agentSuggestions: {
    state: IDLE,
    data: {},
    meta: {},
  },
  agentComments: {
    state: IDLE,
    data: {},
    meta: {},
  },
  openHouses: {
    state: IDLE,
    data: {},
    meta: {},
  },
  priceReductions: {
    state: IDLE,
    data: {},
    meta: {},
  },
  backOnMarkets: {
    state: IDLE,
    data: {},
    meta: {},
  },
  myTransactions: {
    state: IDLE,
    data: {},
    meta: {},
  },
  upcomingShowings: {
    state: IDLE,
    data: {},
    meta: {},
  },
  needsAttention: {
    state: IDLE,
    data: {},
    meta: {},
  },
  addressesValuation: {
    state: IDLE,
    data: [],
    meta: {},
  },
};

export default handleActions(
  {
    [requestClientDashboardNewMatchesAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        newMatches: {
          ...state.newMatches,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [requestClientDashboardAgentSuggestionsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        agentSuggestions: {
          ...state.agentSuggestions,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [requestClientDashboardAgentCommentsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        agentComments: {
          ...state.agentComments,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },

    [requestClientDashboardBackOnMarketsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        backOnMarkets: {
          ...state.backOnMarkets,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },

    [requestClientDashboardOpenHousesAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        openHouses: {
          ...state.openHouses,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },

    [requestClientDashboardPriceReductionsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        priceReductions: {
          ...state.priceReductions,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },

    [requestClientDashboardUpcomingShowingsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        upcomingShowings: {
          ...state.upcomingShowings,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },

    [requestClientDashboardMyTransactionsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        myTransactions: {
          ...state.myTransactions,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [requestClientDashboardNeedsAttentionAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        needsAttention: {
          ...state.needsAttention,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [requestClientDashboardAddressesValuationAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        addressesValuation: {
          ...state.addressesValuation,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
    [requestClientDashboardUpcomingShowingsAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        upcomingShowings: {
          ...state.upcomingShowings,
          state: get(payload, 'state'),
          data: get(payload, 'data'),
          meta: get(payload, 'meta'),
        },
      };
    },
  },
  initialData,
);
