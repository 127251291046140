import { useRef, useState, useEffect, ReactNode } from 'react';
import { Button } from 'components-antd';

import styles from './styles.module.scss';
import classNames from 'classnames';

interface TruncatedContentType {
  content: string | ReactNode;
  maxLines?: number;
  showMoreText?: string;
  showMoreOnClick?: Function;
  className?: string;
}

export const TruncatedContent = ({
  content,
  maxLines = 3,
  showMoreText = '+ Show More',
  showMoreOnClick,
  className = '',
}: TruncatedContentType) => {
  const paragraphRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    if (paragraphRef.current) {
      // Check if content overflows
      const { scrollHeight, clientHeight } = paragraphRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, [content]);

  return (
    <div className={classNames(styles.truncatedContentContainer, className)}>
      <p
        ref={paragraphRef}
        className={styles.content}
        style={{
          WebkitLineClamp: showFullContent ? 'none' : maxLines,
        }}
      >
        {content}
      </p>
      {isTruncated && (
        <Button
          variant="link"
          className={styles.expandBtn}
          onClick={() =>
            showMoreOnClick ? showMoreOnClick() : setShowFullContent(!showFullContent)
          }
        >
          {showFullContent ? '- Hide' : showMoreText}
        </Button>
      )}
    </div>
  );
};
