import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { isNumber } from 'lodash-es';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import {
  isBeforeToday,
  subtractTimeZone,
  getDateOnly,
  formatDate,
  showSuccessMessage,
} from 'helpers';
import { OverdueLabel } from '../OverdueLabel';
import { DueDate } from '../../../../TaskModalForm/DueDate';
import { TaskSectionContainer } from '../TaskSectionContainer';
import { Icons } from '../../Icons';
import { Button } from 'components-antd';

import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { getUserId } from 'store/selectors/user';
import { routes } from 'settings/navigation/routes';
import { getTransactionEffect, updateTransactionTaskDueDateEffect } from 'store/effects';
import { updateCampaignTaskEffect } from 'store/effects/radarKits';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { getTaskFilters } from 'store/selectors/transactionTasks';

import styles from './styles.module.scss';

const timezoneLabels = {
  'Pacific/Honolulu': '(HST)',
  'America/Anchorage': '(AKST)',
  'America/Los_Angeles': '(PST)',
  'America/Denver': '(MST)',
  'America/Chicago': '(CST)',
  'America/New_York': '(EST)',
};

export const TaskDueDate = ({ task, setEditId, disabled }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const { pathname } = useLocation();
  const [isPending, setIsPending] = useState(false);
  const isProject = useIsProjectRoute();

  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const userId = useSelector(getUserId);
  const { formValues } = useSelector(getTransactionTaskParamsSelector);
  const [updatedFormValues, setUpdatedFormValues] = useState(formValues);
  const filters = useSelector(getTaskFilters);

  useEffect(() => {
    setUpdatedFormValues(task);
  }, [isEdit]);

  const onFormValueChange = (values) => {
    setUpdatedFormValues({ ...(updatedFormValues || {}), ...values });
  };

  const onChangeManyFields = (data) => {
    onFormValueChange({ ...(updatedFormValues || {}), ...data });
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditId(null);
  };

  const showOverDueLabel = () =>
    ![tasksStatusesIds.done, tasksStatusesIds.na].includes(task?.Status);

  const hasEditAccess = useMemo(
    () => !(task?.Assignor?.Id !== userId && !fullAccess && !disabled),
    [disabled],
  );

  const onSave = () => {
    setIsPending(true);
    let effect = updateTransactionTaskDueDateEffect;
    let cfg: any = {
      Id: task.Id,
      DueDate: getDateOnly(updatedFormValues?.DueDate),
      Hours: updatedFormValues?.Hours,
      Minutes: updatedFormValues?.Minutes,
      Timezone: updatedFormValues?.Timezone,
      IsBusinessDays: updatedFormValues?.IsBusinessDays,
      ...(task?.CampaignId ? { CampaignId: task?.CampaignId } : {}),
    };

    if (task?.CampaignId) {
      effect = updateCampaignTaskEffect;
    }

    dispatch(
      effect(cfg, {}, (err) => {
        if (!err) {
          if (pathname === routes.tasks) {
            dispatch(getTaskAggregateEffect({}, { silent: true }));
          } else if (pathname !== routes.tasks && task?.TransactionId) {
            dispatch(getTransactionEffect({ id: task?.TransactionId }, { silent: true }));
            dispatch(
              getTaskAggregateEffect(
                {
                  filters: { ...filters, transactionRoomId: task?.TransactionId },
                },
                { silent: true },
              ),
            );
          }
          showSuccessMessage('Changes are saved succesfully');
          onCancel();
        }
        setIsPending(false);
      }),
    );
  };

  const renderTime = () => {
    if (isNumber(task?.Hours) && isNumber(task?.Minutes)) {
      const time = moment({ hour: task?.Hours, minute: task?.Minutes }).toDate();
      return (
        <span>
          &nbsp;at <strong>{formatDate(time, 'hh:mm A')}</strong>
          &nbsp;{timezoneLabels?.[task?.Timezone]}
        </span>
      );
    }

    return null;
  };

  return (
    <TaskSectionContainer
      icon={!isEdit && <Icons variant={Icons.DUE_DATE} />}
      contentClassName={styles.dueDateContent}
      disabled={disabled}
    >
      <div className={styles.dueDateContainer}>
        {!isEdit ? (
          <>
            <div
              className={classNames(styles.dueDate, {
                [styles.marginTop]: !isBeforeToday(subtractTimeZone(task?.DueDate, 'M/DD/YYYY')),
              })}
            >
              Due:&nbsp;
              <strong>{getDateOnly(task?.DueDate, 'M/D/YY')}</strong>
              {renderTime()}
            </div>
            {showOverDueLabel() && isBeforeToday(subtractTimeZone(task?.DueDate, 'M/DD/YYYY')) && (
              <OverdueLabel className={styles.overdueLabel} />
            )}
            {hasEditAccess && (
              <Icon
                testid="edit_icon"
                className={styles.editIcon}
                variant={Icon.EDIT_DARK}
                onClick={() => {
                  setIsEdit(true);
                  setEditId('task-due-date');
                }}
              />
            )}
          </>
        ) : (
          <>
            <DueDate
              isTemplate={false}
              isCampaignTask={!!task?.campaignId}
              values={updatedFormValues}
              onChangeManyFields={onChangeManyFields}
              isViewMode={false}
              scrollToBottom={true}
              customDueDate={null}
              isProjectTask={isProject}
              isTooltipInfoText
              hideRepeat
            />
            <div className={styles.footerBtns}>
              <Button
                variant="secondary"
                size="large"
                disabled={isPending || !updatedFormValues?.DueDate}
                onClick={onSave}
                loading={isPending}
              >
                Update
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </TaskSectionContainer>
  );
};
