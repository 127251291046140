import { FormBundleDocument } from 'types';
import { dynamicManager } from '../../DynamicManager';
import { sortDocuments } from '../../helper';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { v4 as uuidv4 } from 'uuid';

import {
  INITIALS_MIN_HEIGHT,
  INITIALS_MIN_WIDTH,
  SIGNATURE_MIN_HEIGHT,
  SIGNATURE_MIN_WIDTH,
} from './components/FieldRenderer/helper';
import { BORDER_WIDTH, GROUP_PADDING } from './components';
import { DEFAULT_PDF_SCALE } from 'pages/FormProcess/components';

export const MOBILE_WIDTH = 768;

export const getAllDocumentsWithPages = (documents) => {
  const formMeta = dynamicManager.getFormMeta();
  let allDocumentsWithPages: FormBundleDocument[] = [];

  const bundleDocuments = dynamicManager.getBundleDocumentsDetails();
  const dynamicDocuments = dynamicManager.getDynamicDocuments();

  if (bundleDocuments.length) {
    allDocumentsWithPages = dynamicDocuments.map((docItem, index) => ({
      ...docItem,
      totalPages: bundleDocuments[index]?.meta.totalPages,
    }));
  } else {
    const allDocuments = sortDocuments(documents, 'Order');

    allDocumentsWithPages = [
      {
        ...allDocuments[0],
        ...(formMeta && { totalPages: formMeta.totalPages }),
      },
    ];
  }

  return allDocumentsWithPages;
};

export const calculateTotalPages = (documents) => {
  return documents?.reduce((count, doc) => count + doc.totalPages, 0) || 0;
};

export const getDefaultFieldSize = (type, scale) => {
  let width, height;

  if (type === DYNAMIC_QUESTION_TYPE.Signature) {
    width = SIGNATURE_MIN_WIDTH;
    height = SIGNATURE_MIN_HEIGHT;
  } else if (type === DYNAMIC_QUESTION_TYPE.Initials) {
    width = INITIALS_MIN_WIDTH;
    height = INITIALS_MIN_HEIGHT;
  } else if (type === DYNAMIC_QUESTION_TYPE.CheckBox) {
    width = 21;
    height = 21;
  } else if (type === DYNAMIC_QUESTION_TYPE.RadioGroup) {
    width = 31;
    height = 50;
  } else if (type === DYNAMIC_QUESTION_TYPE.FullName) {
    width = SIGNATURE_MIN_WIDTH;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.DateSigned) {
    width = 106.67;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.Date) {
    width = 106.67;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.Address) {
    width = 160;
    height = 20;
  } else if (type === DYNAMIC_QUESTION_TYPE.StrikeThrough) {
    width = 160;
    height = 0;
  } else {
    width = 106.67;
    height = 20;
  }

  return {
    width: width * scale,
    height: height * scale,
  };
};

export const prepareRadioQuestionsForSave = (fields, scale) => {
  const radioQuestions: any[] = [];
  const deletedGroupQuestions: any[] = [];
  const updatedValidationItems: any[] = [];
  const radioGroupFields = fields.filter(({ type }) => type === DYNAMIC_QUESTION_TYPE.RadioGroup);

  radioGroupFields.forEach((item) => {
    const {
      UUID: groupUUID,
      options,
      validation,
      x: groupX,
      y: groupY,
      width: groupWidth,
      height: groupHeight,
      required,
      pageNumber,
      roleName,
      isUpdated: isGroupUpdated,
      isDeleted: isGroupDeleted,
      isGroupResized,
      validationUpdated,
      groupDragged,
      id,
      questionValidationId: fiedValidationId,
      validationId,
      isNewGroup,
    } = item;

    const questionValidationId = fiedValidationId || validationId;

    if (isGroupDeleted) {
      deletedGroupQuestions.push({ validationId: questionValidationId });
      return;
    }

    if (validationUpdated || groupDragged || isGroupResized) {
      const validationItem = {
        ...validation,
        height: groupHeight,
        width: groupWidth,
        x: groupX,
        y: groupY,
        required,
        questionValidationId,
      };

      updatedValidationItems.push(validationItem);
    }

    options.forEach((radioOption) => {
      const {
        UUID,
        width,
        height,
        x,
        y,
        isUpdated,
        isDeleted,
        id: optionId,
        isRoleModified,
        isNewOption,
        fieldAnswer,
      } = radioOption;

      const shouldStopSave =
        optionId &&
        !isDeleted &&
        !isUpdated &&
        !validationUpdated &&
        !groupDragged &&
        !isRoleModified;

      if (shouldStopSave) return;

      const canAddQuestion =
        isNewGroup ||
        isNewOption ||
        isRoleModified ||
        isUpdated ||
        isDeleted ||
        groupDragged ||
        fieldAnswer;

      if (canAddQuestion) {
        const questionItem = {
          type: 'Radio Group',
          UUID,
          width,
          height,
          x: x / scale + GROUP_PADDING + groupX + BORDER_WIDTH,
          y: y / scale + GROUP_PADDING + groupY + BORDER_WIDTH,
          required,
          pageNumber,
          roleName,
          isUpdated: isUpdated || isRoleModified || groupDragged,
          isDeleted,
          ...(fieldAnswer && { fieldAnswer }),
          ...(optionId && { id: optionId }),

          ...(questionValidationId
            ? { validationId: questionValidationId }
            : {
                validation: {
                  ...validation,
                  height: groupHeight,
                  width: groupWidth,
                  x: groupX,
                  y: groupY,
                  required,
                  UUID: groupUUID,
                  isUpdated: isGroupUpdated,
                  isDeleted: isGroupDeleted,
                  ...(id && { id }),
                },
              }),
        };

        radioQuestions.push(questionItem);
      }
    });
  });

  return { radioQuestions, deletedGroupQuestions, updatedValidationItems };
};

export const getGroupedFields = (radioQuestions) => {
  const groupedFields: any = {};

  radioQuestions.forEach((item) => {
    const { UUID: parentUUID } = item.validation;

    if (!groupedFields[parentUUID]) {
      groupedFields[parentUUID] = [];
    }

    groupedFields[parentUUID].push(item);
  });

  return groupedFields;
};

export const convertRadioQuestionsIntoOptions = (questions) => {
  const typeKey = 'type';

  const savedRadioOptions = questions.filter(
    (item) => item[typeKey] === DYNAMIC_QUESTION_TYPE.RadioGroup && !item.options,
  );

  if (!savedRadioOptions.length) return [];

  const radioGroupFieldQuestions: any = [];
  const groupedFields: any = {};
  const scale = DEFAULT_PDF_SCALE;

  savedRadioOptions.forEach((item) => {
    const questionValidationId = item.questionValidationId || item.validationId;

    if (!groupedFields[questionValidationId]) {
      groupedFields[questionValidationId] = [];
    }

    groupedFields[questionValidationId].push(item);
  });

  Object.keys(groupedFields).forEach((groupKey) => {
    const groupOptions = groupedFields[groupKey];

    const {
      UUID: optionUUID,
      id: optionId,
      height: optionHeight,
      width: optionWidth,
      x: optionX,
      y: optionY,
      ...otherItems
    } = groupOptions[0];

    const { validations, ...restOtherItems } = otherItems;
    const {
      GroupField: { widgets },
      Type,
      Min,
      Max,
      Value,
      Id: id,
      Required: required,
    } = validations;

    const mainField = {
      UUID: uuidv4(),
      id,
      validation: {
        type: Type,
        ...(Value && { value: Value }),
        ...((Min || Max) && { min: Min, max: Max }),
      },
      ...widgets[0],
      ...restOtherItems,
      required,
    };

    const options = groupOptions.map(({ validation, ...optionItem }) => ({
      id: optionItem.id,
      UUID: optionItem.UUID,
      x: (optionItem.x - mainField.x) * scale - GROUP_PADDING - BORDER_WIDTH,
      y: (optionItem.y - mainField.y) * scale - GROUP_PADDING - BORDER_WIDTH,
      width: optionItem.width,
      height: optionItem.height,
    }));

    mainField.options = options;

    radioGroupFieldQuestions.push(mainField);
  });

  return radioGroupFieldQuestions;
};
