import {
  GET_FORM_PROCESS_META,
  GET_SMART_FORM_DATA,
  GET_PUBLISHED_FORMS,
  FORM_PROCESS_DOCUMENT,
  GET_ANON_FORM_PROCESS,
  SEND_FORM_PROCESS,
  GET_POST_FORM_DATA,
  CREATE_UPDATE_FORM,
  GET_PUBLISHED_FORM,
  CREATE_BUNDLE_FORM,
  PREVIEW_DRAFT_FORM,
  GET_FORM_TEMPLATE,
  CREATE_UPDATE_DYNAMIC_FORM,
  DYNAMIC_FORM_DOCUMENT_AUTH,
  DYNAMIC_FORM_DOCUMENT_QUESTION,
  PROGRESS_DYNAMIC_FORM_DOCUMENT_AUTH,
  GET_DYNAMIC_POST_FORM_DATA,
  REGRESS_DYNAMIC_FORM_DOCUMENT_AUTH,
  GET_ANON_DYNAMIC_FORM_PROCESS,
  CREATE_FORM_TEMPLATE,
  UPDATE_FORM_TEMPLATE,
  GET_TEMPLATE_DYNAMIC_FORM_PROCESS,
  TEMPLATE_FORM_QUESTION,
  GET_FORM_RECENT_UPLOADS,
  GET_AGENT_FORM_DATA,
  PROGRESS_SAVE_AS_TEMPLATE_FORM,
  GET_AGENT_FORM_ROLES,
  CREATE_UPDATE_DYNAMIC_BUNDLE_FORM,
  CREATE_BUNDLE_FORM_TEMPLATE,
  SAVE_BUNDLE_TEMPLATE,
  GET_BUNDLE_FORM_ROLES,
  CREATE_FORM_COPY,
  DELETE_FORM_DOCUMENT,
  DELETE_TEMPLATE_FROM_BUNDLE,
  PREVIEW_BUNDLE_FORM,
  GET_SIGNATORIES,
  UPDATE_SIGNATORIES,
  GET_BUNDLE_TEMPLATE,
  REORDER_BUNDLE_DOCUMENT,
  DELETE_TEMPLATE_FORM,
  ADD_FORM_DYNAMIC_VIEW,
  UPDATE_TEMPLATE_SIGNATORIES,
  ADD_TEMPLATE_DYNAMIC_VIEW,
  DELETE_VALIDATION_QUESTION,
  VALIDATE_FILL_QUESTION,
  UPDATE_VALIDATION_QUESTION,
  VALIDATE_EXTERNAL_SIGNATORY_QUESTION,
} from 'app-constants';
import { FormIdsType } from 'types';
import { apiServer } from 'settings/web-services/api';

export const getPublishedForms = ({ search, order, orderBy, ...cfg }) =>
  apiServer.post(GET_PUBLISHED_FORMS({ search, orderBy, order }), cfg);

export const getFormTemplates = (cfg) => apiServer.get(GET_FORM_TEMPLATE(cfg));

export const getFormRecentUploads = (cfg) =>
  apiServer.get(GET_FORM_RECENT_UPLOADS, { params: cfg?.filters ?? cfg });

export const getFormMeta = (cfg) => apiServer.get(GET_FORM_PROCESS_META, { params: cfg });

export const getSmartFormData = (cfg: FormIdsType) => apiServer.get(GET_SMART_FORM_DATA(cfg));

export const getAgentFormData = (cfg: FormIdsType) => apiServer.get(GET_AGENT_FORM_DATA(cfg));

export const getAgentFormRoles = () => apiServer.get(GET_AGENT_FORM_ROLES);

export const getBundleFormRoles = (cfg) => apiServer.get(GET_BUNDLE_FORM_ROLES(cfg));

export const getBundleTemplate = (cfg) => apiServer.get(GET_BUNDLE_TEMPLATE(cfg));

export const createNewForm = (cfg) => apiServer.post(CREATE_UPDATE_FORM, cfg);

export const createNewTemplate = (cfg) => apiServer.post(CREATE_FORM_TEMPLATE, cfg);

export const updateFormTemplate = (cfg) => apiServer.put(UPDATE_FORM_TEMPLATE(cfg));

export const createDynamicForm = (cfg) => apiServer.post(CREATE_UPDATE_DYNAMIC_FORM, cfg);

export const addNewFormInDynamicView = ({ formData, formDetails }) =>
  apiServer.post(ADD_FORM_DYNAMIC_VIEW, formData || formDetails, {
    ...(formData && {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  });

export const addNewTemplateInDynamicView = ({ formData, formDetails, templateId }) =>
  apiServer.post(ADD_TEMPLATE_DYNAMIC_VIEW(templateId), formData || formDetails, {
    ...(formData && {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  });

export const createDynamicBundleForm = (cfg) =>
  apiServer.post(CREATE_UPDATE_DYNAMIC_BUNDLE_FORM, cfg);

export const reorderDynamicBundleDocument = ({ bundleId, ...cfg }) =>
  apiServer.patch(REORDER_BUNDLE_DOCUMENT(bundleId), cfg);

export const createFormCopy = ({ formProcessId, ...cfg }) =>
  apiServer.post(CREATE_FORM_COPY(formProcessId), cfg);

export const updateForm = (cfg) => apiServer.put(CREATE_UPDATE_FORM, cfg);

export const getFormProcessDocument = (cfg) => apiServer.get(FORM_PROCESS_DOCUMENT(cfg));

export const getAnonFormProcessDocument = (cfg) => apiServer.get(GET_ANON_FORM_PROCESS(cfg));

export const getAnonDynamicFormProcessDocument = (cfg) =>
  apiServer.get(GET_ANON_DYNAMIC_FORM_PROCESS(cfg));

export const getTemplateDynamicFormProcessDocument = (cfg) =>
  apiServer.get(GET_TEMPLATE_DYNAMIC_FORM_PROCESS(cfg));

export const sendFormProcessDocument = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(SEND_FORM_PROCESS({ formProcessPublicId, formDocumentPublicId }), cfg);

export const getPostFormData = (cfg) => apiServer.get(GET_POST_FORM_DATA(cfg));

export const getPublishedForm = (cfg) => apiServer.get(GET_PUBLISHED_FORM(cfg));

export const createBundleForm = (cfg) => apiServer.post(CREATE_BUNDLE_FORM, cfg);

export const createBundleFormTemplate = (cfg) => apiServer.post(CREATE_BUNDLE_FORM_TEMPLATE, cfg);

export const previewDraftFormDocument = ({ formId, versionId, ...cfg }) =>
  apiServer.get(PREVIEW_DRAFT_FORM(formId, versionId), cfg);

export const previewBundleFormDocument = ({ bundleId, isTemplate, ...cfg }) =>
  apiServer.get(PREVIEW_BUNDLE_FORM(bundleId), {
    ...(isTemplate && { params: { isTemplate: true } }),
    ...cfg,
  });

export const getDynamicFormDocumentAuth = (cfg) => apiServer.get(DYNAMIC_FORM_DOCUMENT_AUTH(cfg));

export const updateDynamicFormDocumentQuestion = ({
  formProcessPublicId,
  formDocumentPublicId,
  ...formData
}) =>
  apiServer.post(
    DYNAMIC_FORM_DOCUMENT_QUESTION({ formProcessPublicId, formDocumentPublicId }),
    formData,
  );

export const updateTemplateFormDocumentQuestion = ({ templateId, ...formData }) =>
  apiServer.post(TEMPLATE_FORM_QUESTION({ templateId }), formData);

export const deleteQuestionValidation = (formData) =>
  apiServer.post(DELETE_VALIDATION_QUESTION, formData);

export const updateQuestionValidation = ({ questionValidationId, ...formData }) =>
  apiServer.patch(UPDATE_VALIDATION_QUESTION(questionValidationId), formData);

export const validateGroupFieldAnswer = (formData) =>
  apiServer.get(VALIDATE_FILL_QUESTION(formData));

export const validateExternalGroupField = (formData) =>
  apiServer.get(VALIDATE_EXTERNAL_SIGNATORY_QUESTION(formData));

export const updateDynamicFormProgress = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(
    PROGRESS_DYNAMIC_FORM_DOCUMENT_AUTH({ formProcessPublicId, formDocumentPublicId }),
    cfg,
  );

export const updatSaveAsTemplateFormProgress = ({
  formProcessPublicId,
  formDocumentPublicId,
  ...cfg
}) =>
  apiServer.post(
    PROGRESS_SAVE_AS_TEMPLATE_FORM({ formProcessPublicId, formDocumentPublicId }),
    cfg,
  );

export const updateDynamicFormRegress = ({ formProcessPublicId, formDocumentPublicId, ...cfg }) =>
  apiServer.post(
    REGRESS_DYNAMIC_FORM_DOCUMENT_AUTH({ formProcessPublicId, formDocumentPublicId }),
    cfg,
  );

export const getDynamicPostFormData = (cfg) => apiServer.get(GET_DYNAMIC_POST_FORM_DATA(cfg));

export const getSignatories = (cfg) => apiServer.get(GET_SIGNATORIES(cfg));

export const updateSignatories = (cfg) => apiServer.post(UPDATE_SIGNATORIES(cfg), cfg);

export const updateTemplateSignatories = ({ templateId, ...cfg }) =>
  apiServer.put(UPDATE_TEMPLATE_SIGNATORIES(templateId), cfg);

export const saveBundleAsTemplate = ({ formProcessPublicId, ...cfg }) =>
  apiServer.post(SAVE_BUNDLE_TEMPLATE({ formProcessPublicId }), cfg);

export const deleteFormDocument = (cfg) => apiServer.delete(DELETE_FORM_DOCUMENT(cfg));

export const deleteTemplateFromBundle = (cfg) => apiServer.delete(DELETE_TEMPLATE_FROM_BUNDLE(cfg));

export const deleteFormTemplate = (cfg) => apiServer.post(DELETE_TEMPLATE_FORM, cfg);
