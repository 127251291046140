import { useCallback } from 'react';
import PropTypes from 'prop-types';

const Heart = ({ className, variant }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Heart.HOLLOW:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <g clipPath="url(#clip0_70781_159068)">
              <path
                d="M10.5001 17.2208L3.75939 10.4801C3.38001 10.0691 3.0853 9.58739 2.89209 9.0625C2.69887 8.53762 2.61093 7.97981 2.63328 7.42094C2.67843 6.29225 3.1701 5.22772 4.00013 4.46154C4.83016 3.69536 5.93056 3.29029 7.05925 3.33544C8.18794 3.38059 9.25247 3.87226 10.0186 4.70228C10.1612 4.79751 10.3287 4.84834 10.5001 4.84834C10.6715 4.84834 10.8391 4.79751 10.9816 4.70228C11.7478 3.87226 12.8123 3.38059 13.941 3.33544C15.0697 3.29029 16.1701 3.69536 17.0001 4.46154C17.8302 5.22772 18.3218 6.29225 18.367 7.42094C18.4121 8.54963 18.0071 9.65003 17.2409 10.4801L10.5001 17.2208Z"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_70781_159068">
                <rect width="20" height="20" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
        );
      default: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_70781_160308)">
              <path
                d="M10.0001 17.2221L3.25939 10.4813C2.88001 10.0704 2.5853 9.58867 2.39209 9.06378C2.19887 8.5389 2.11093 7.9811 2.13328 7.42222C2.17843 6.29353 2.6701 5.22901 3.50013 4.46283C4.33016 3.69664 5.43056 3.29157 6.55925 3.33672C7.68794 3.38187 8.75247 3.87354 9.51865 4.70357C9.66117 4.79879 9.82872 4.84962 10.0001 4.84962C10.1715 4.84962 10.3391 4.79879 10.4816 4.70357C11.2478 3.87354 12.3123 3.38187 13.441 3.33672C14.5697 3.29157 15.6701 3.69664 16.5001 4.46283C17.3302 5.22901 17.8218 6.29353 17.867 7.42222C17.9121 8.55092 17.5071 9.65131 16.7409 10.4813L10.0001 17.2221Z"
                fill="#FF576D"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_70781_160308">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
    }
  }, [variant]);
  return <div className={className}>{getIcon(variant)}</div>;
};

Heart.HOLLOW = 'hollow';
Heart.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

Heart.defaultProps = {
  className: '',
  color: 'white',
  variant: undefined,
};

export default Heart;
