import { createAction } from 'redux-actions';

export const requestGetRecommendedPropsAction = createAction('REQUEST/GET_RECOMMENDED_PROPERTIES');
export const requestGetNewPropsAction = createAction('REQUEST/GET_NEW_PROPERTIES');
export const requestGetClientFavoritePropsAction = createAction(
  'REQUEST/GET_BUYER_FAVORITE_PROPERTIES',
);
export const requestGetClientHighlightsPropsAction = createAction(
  'REQUEST/GET_BUYER_HIGHLIGHTS_PROPERTIES',
);
export const requestGetAllPropsAction = createAction('REQUEST/GET_ALL_PROPERTIES');
export const requestGetSavedSearchesPropsAction = createAction(
  'REQUEST/GET_SAVED_SEARCHES_PROPERTIES',
);
export const requestGetStatusChangesPropsAction = createAction(
  'REQUEST/GET_STATUS_CHANGES_PROPERTIES',
);
export const requestGetOpenHousesPropsAction = createAction('REQUEST/GET_OPEN_HOUSES_PROPERTIES');
export const requestGetPriceReductionsPropsAction = createAction(
  'REQUEST/GET_PRICE_REDUCTIONS_PROPERTIES',
);
export const requestClientCountAction = createAction('REQUEST/GET_CLIENT_COUNT');
export const requestAgentClientsAction = createAction('REQUEST/GET_AGENT_CLIENTS');
export const requestGetClientFavoritesPropertiesAction = createAction(
  'REQUEST/GET_CLIENT_FAVORITES_PROPERTIES',
);
export const requestGetWatchlistPropsAction = createAction('REQUEST/GET_WATCHLIST_PROPERTIES');
export const requestGetBackOnMarketPropsAction = createAction(
  'REQUEST/GET_BACK_ON_MARKET_PROPERTIES',
);
export const requestGetSuggestionsPropsAction = createAction('REQUEST/GET_SUGGESTIONS_PROPERTIES');
export const requestGetSearchInstancePropsAction = createAction(
  'REQUEST/GET_SEARCH_INSTANCE_PROPERTIES',
);

export const requestGetClientsAction = createAction('REQUEST/GET_CLIENTS');
export const requestUpdateSearchCriteriaAction = createAction('REQUEST/UPDATE_SEARCH_CRITERIA');
export const requestSendSharePropsToRecipientsAction = createAction(
  'REQUEST/SEND_SHARE_PROPS_TO_RECIPIENTS',
);
export const requestGetComparePropertiesAction = createAction('REQUEST/GET_COMPARE_PROPERTIES');

export const openFeedFilterDrawerAction = createAction('FEED/OPEN_FILTER_DRAWER');
export const openShareDrawerAction = createAction('FEED/OPEN_SHARE_DRAWER');
export const sortFeedAction = createAction('FEED/SORT');
export const sortFeedResetAction = createAction('FEED/SORT_RESET');
export const setFeedPageInfoAction = createAction('FEED/SET_PAGE_INFO');
export const setMultipleModAction = createAction('FEED/MULTIPLE_MODE');
export const setComparesAction = createAction('FEED/SET_COMPARES');
export const setFeedDrawerCommuteIdAction = createAction('FEED/SET_DRAWER_COMMUTE_ID');
export const openCompareModalAction = createAction('FEED/OPEN_COMPARE_MODAL');
export const deleteCompareItemAction = createAction('FEED/DELETE_COMPARE_ITEM');
export const searchClientsAction = createAction('FEED/SEARCH_CLIENTS');
export const setRecipientsAction = createAction('FEED/SET_RECIPIENTS');
export const setActiveTabAction = createAction('FEED/SET_ACTIVE_TAB');
export const setSharePropertyAction = createAction('FEED/SET_SHARE_PROPERTY');
// export const resetPropertyCommentsAction = createAction('RESET_PROPERTY_COMMENTS');
export const resetComparePropertiesStateAction = createAction(
  'REQUEST/RESET_COMPARE_PROPERTIES_STATE',
);
export const resetClientFavoritePropsAction = createAction('REQUEST/RESET_BUYER_FAVORITE_PROPS');
export const resetPropertiesWholeTabsAction = createAction('RESET_PROPERTIES_WHOLE_TABS');
export const resetFullDataComparePropertiesAction = createAction(
  'RESET_FULL_DATA_COMPARE_PROPERTIES',
);
export const setFeedPrefsAction = createAction('FEED/SET_FEED_PREFS');
export const setFeedMapViewAction = createAction('FEED/SET_MAP_VIEW');
export const resetFeedMapViewAction = createAction('FEED/RESET_MAP_VIEW');
export const initialImageAnalysisAction = createAction('PROPERTY/RUN_IMAGE_ANALYSIS');
export const propertySeenOnMap = createAction('PROPERTY/SEEN_ON_MAP');
export const setPropertyToScrollInList = createAction('PROPERTY/SET_PROPERTY_TO_SCROLL_IN_LIST');
export const resetPropertyToScrollInList = createAction(
  'PROPERTY/RESET_PROPERTY_TO_SCROLL_IN_LIST',
);

export const setSubFilterTypeAction = createAction('FEED/SET_SUB_FILTER_TYPE');

export const toggleFeedMapDrawingModeAction = createAction('FEED/TOGGLE_MAP_DRAWING_MODE');
export const cleanFeedDrawnPolygonAction = createAction('FEED/CLEAN_DRAWN_POLYGON');
export const setFeedDrawnPolygonAction = createAction('FEED/SET_DRAWN_POLYGON');

export const cleanFeedViewportCoordinatesAction = createAction('FEED/CLEAN_VIEWPORT_COORDINATES');
export const setFeedViewportCoordinatesAction = createAction('FEED/SET_VIEWPORT_COORDINATES');

export const setFeedCurrentPageInfoAction = createAction('FEED/SET_CURRENT_PAGE_INFO');
