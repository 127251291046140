export const otherSignatoryField = (createdById, signatories, fieldRole) => {
  const signatoryItem = signatories.signatoryParticipants?.find(({ Id }) => Id === createdById);

  const fieldRoleId = fieldRole.formRole || fieldRole.roleId;

  const isOtherSignatoryField = !signatoryItem || signatoryItem.RoleId !== fieldRoleId;

  let isSequentialDisabled =
    !isOtherSignatoryField &&
    signatories.isSequentialSigning &&
    signatoryItem.SignatureSequence !== 1;

  return { isOtherSignatoryField, isSequentialDisabled };
};
