import { useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { Input, InputLabel, DatePicker } from 'components';
import { LoadingOutlined } from '@ant-design/icons';
import { Popover } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { validateControlOperatorOffset, convertToDateTime } from 'utils/templatesHelper';
import {
  getTransactionAccessSelector,
  getTransactionDateControlsSelector,
  getTransactionSelector,
} from 'store/selectors/transaction';
import {
  getTransactionEffect,
  updateTransactionTaskDueDateEffect,
} from 'store/effects/transactions';
import {
  getTransactionTaskAccessSelector,
  getTransactionTaskSelector,
} from 'store/selectors/transactionTask';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { subtractTimeZone, showSuccessMessage, getDateOnly } from 'helpers';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { routes } from 'settings/navigation/routes';
import { useLocation } from 'react-router-dom';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { Spin } from 'antd';
import { getTaskFilters } from 'store/selectors/transactionTasks';
import { requestGetCampaignByIdEffect, updateCampaignTaskEffect } from 'store/effects/radarKits';
import { pathToRegex } from 'settings/navigation/helpers';
import { getUserId } from 'store/selectors/user';

interface DueDateProps {
  isViewMode?: boolean;
  hideAsterisk?: boolean;
}

const DueDate = ({ isViewMode, hideAsterisk }: DueDateProps) => {
  const dispatch = useDispatch();
  const dueDate = useRef<any>('');
  const isProject = useIsProjectRoute();
  const dateControls = useSelector(getTransactionDateControlsSelector);
  const { fullAccess: transactionFullAccess } = useSelector(getTransactionAccessSelector);
  const { fullAccess: taskFullAccess } = useSelector(getTransactionTaskAccessSelector);
  const { task } = useSelector(getTransactionTaskSelector);
  const { pathname } = useLocation();
  const { transaction } = useSelector(getTransactionSelector);
  const taskFilters = useSelector(getTaskFilters);
  const CampaignId = task?.CampaignId;

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(true);
  const [init, setInit] = useState(true);
  const [fieldValues, setFieldValues] = useState({
    ControlOperatorOffset: task?.ControlOperatorOffset,
  } as TaskEditableFormValuesType);

  const fullAccess = useMemo(
    () => transactionFullAccess || (CampaignId && taskFullAccess),
    [transactionFullAccess, taskFullAccess, CampaignId],
  );

  const userId = useSelector(getUserId);

  const canEditDueDate = fullAccess || task?.Assignor?.Id === userId;

  const onChangeManyFields = (fields) => {
    setFieldValues((values) => ({ ...values, ...fields }));
  };

  const updateDueDate = () => {
    if (
      !dueDate?.current ||
      (task?.DueDate &&
        dueDate.current &&
        new Date(subtractTimeZone(task.DueDate)).getTime() === new Date(dueDate.current).getTime())
    )
      return;

    let cfg = {
      Id: task.Id,
      DueDate: dueDate?.current,
      ...(task?.CampaignId && { CampaignId: task?.CampaignId }),
    };
    setLoading(true);
    const effect = task?.CampaignId ? updateCampaignTaskEffect : updateTransactionTaskDueDateEffect;
    dispatch(
      effect(cfg, {}, (err) => {
        showSuccessMessage(
          !err ? 'Changes are saved successfully' : 'An error ocurred while saving changes',
        );
        setEdit(false);
        setLoading(false);

        if (pathname !== routes.tasks && transaction?.Id) {
          dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }));
          dispatch(
            getTaskAggregateEffect(
              {
                filters: { ...taskFilters, transactionRoomId: transaction?.Id },
              },
              { silent: true },
            ),
          );
        } else if (pathname === routes.tasks) {
          dispatch(getTaskAggregateEffect({}, { silent: true }));
        } else if (pathname.match(pathToRegex(routes.radarViewSequence)) && task?.CampaignId) {
          dispatch(requestGetCampaignByIdEffect(task?.CampaignId, { silent: true }));
        }
      }),
    );
  };

  const onClose = () => {
    setOpenCalendar(false);
    dueDate ? updateDueDate() : setEdit(false);
  };

  const onEdit = () => {
    setEdit(true);
    setOpenCalendar(true);
  };

  const onChangeControlOperatorOffset = (e, val) => {
    setInit(false);
    fieldValues?.DueDate
      ? onChangeManyFields({
          DueDate: null,
          ControlOperatorOffset: '',
        })
      : validateControlOperatorOffset(e, val, onChangeManyFields, null, undefined, true);
  };

  const getControlOffsetValue = () =>
    fieldValues?.DueDate
      ? moment(fieldValues.DueDate).format('M/D/YY')
      : init
      ? getDateOnly(task?.DueDate)
      : fieldValues?.ControlOperatorOffset;

  const datePickerRef = useRef(null);

  const handleDateChange = (e) => {
    setInit(false);
    convertToDateTime(
      e.target.value,
      dateControls,
      (val) => {
        if (val.DueDate) {
          dueDate.current = val.DueDate;
        }
        onChangeManyFields(val);
        setShowErrMsg(false);
        if (!val.DueDate) {
          setShowErrMsg(true);
          return;
        } else onClose();
      },
      null,
      true,
    );
  };

  const getTime = () => {
    const { Hours, Minutes } = task || {};
    if (Hours && Minutes) {
      const time = moment({ hour: Hours, minute: Minutes }).toDate();
      return (
        <>
          <span style={{ fontWeight: 400 }}>at</span>
          <span>{moment(time).format('hh:mm A')}</span>
        </>
      );
    }

    return null;
  };

  return (
    <div className={styles.dueDate}>
      <div className={classNames({ [styles.editable]: !edit }, styles.fieldWrapper)}>
        <InputLabel label="Due:" className={styles.label} />
        <Popover
          open={edit}
          overlayClassName={styles.viewTaskDueDatePickerPopover}
          content={
            <div className={styles.taskDueDatePicker} ref={datePickerRef}>
              <DatePicker
                className={styles.templateControlPicker}
                variant="light"
                options={{ enableTime: false }}
                value={getDateOnly(task?.DueDate)}
                onChange={(e, val) => {
                  setInit(false);
                  setShowErrMsg(false);
                  if (val?.length) {
                    dueDate.current = val[0]?.toString();
                    onChangeManyFields({ DueDate: val[0], ControlOperatorOffset: '' });
                    updateDueDate();
                  }
                }}
                daysFromToday={fieldValues?.Offset}
                autofocus={false}
                testid="due_date"
                disabled={isViewMode || loading}
                icon={true}
                openCalendar={edit && openCalendar}
                datePickerInputClass={styles.datePickerInput}
                debounce={true}
              />
              <Input
                variant={Input.LIGHT}
                placeholder={`T+7, ${isProject ? 'KO+2, DL-4' : 'CD+2, CL-4'}`}
                value={getControlOffsetValue()}
                onChange={(e, val) => onChangeControlOperatorOffset(e, val)}
                maxLength={10}
                onBlur={(e) => handleDateChange(e)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleDateChange(e);
                  }
                }}
                saveOnChange={false}
                disabled={isViewMode || loading}
                inputClassName={styles.input}
              />
              {showErrMsg && <div className={styles.errorMsg}>Invalid Date</div>}
              {loading && (
                <Spin
                  className={styles.loader}
                  indicator={<LoadingOutlined className={styles.spinner} spin />}
                />
              )}
            </div>
          }
        ></Popover>
        <div
          className={styles.dateHolder}
          onClick={() => (canEditDueDate && !edit ? onEdit() : null)}
        >
          {!edit ? (
            <>
              <span>{getDateOnly(task?.DueDate, 'M/D/YY')}</span>
              {getTime()}
            </>
          ) : null}
          {canEditDueDate && (
            <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT_DARK} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DueDate;
