import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Tabs, Row, Col } from 'components-antd';
import { Checklists, UploadDocument, Comments } from '../../../View/components';
import { Documents } from '../../../..';
import { Plus } from 'components/Icons';

import { getTransactionAccessByTaskSelector } from 'store/selectors/transaction';
import { getTransactionTaskAccess } from 'store/selectors/transactionTasks';
import { CommentsEntityType } from 'api/comments';

import styles from './styles.module.scss';

enum TabKeys {
  CHECKLISTS = 'Checklist',
  COMMENTS = 'Comments',
  FILES = 'Files',
}

export const RightSectionTabs = ({ task, campaignTaskFullAccess, disabled }) => {
  const [activeKey, setActiveKey] = useState<string>(
    task?.Checklists?.length
      ? TabKeys.CHECKLISTS
      : task?.CampaignId
      ? TabKeys.FILES
      : TabKeys.COMMENTS,
  );
  const { hasFullAccess } = useSelector(getTransactionAccessByTaskSelector);
  const { hasAccess } = useSelector(getTransactionTaskAccess);
  const [commentsCount, setCommentsCount] = useState(0);

  const renderCommentSection = () => {
    return (
      <>
        {task?.canComment && (
          <>
            <div className={styles.customLine} />
            <div className={styles.taskComments}>
              <Comments
                commentAutoFocus={true}
                isModal={false}
                entityId={task.Id}
                entityType={CommentsEntityType.taskComment}
                threadId={task?.CommentsThreadId}
                messageListWrapperClassName={styles.taskCommentsList}
                setCommentsCount={setCommentsCount}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const ChecklistTabItem: {
    key: string;
    label: string | ReactNode;
    children: ReactNode;
  } = {
    key: TabKeys.CHECKLISTS,
    label: TabKeys.CHECKLISTS,
    children: (
      <div className={styles.checklistContainer}>
        <Checklists
          className={styles.taskViewChecklist}
          taskId={task?.Id}
          transactionId={task?.TransactionId}
          checklists={task?.Checklists}
          fullAccess={hasFullAccess(task?.TransactionId)}
        />
      </div>
    ),
  };

  const tabItems = (commentsCount) => [
    ...(task?.Checklists?.length ? [ChecklistTabItem] : []),
    ...(!task?.CampaignId
      ? [
          {
            key: TabKeys.COMMENTS,
            label: (
              <span>
                {TabKeys.COMMENTS}{' '}
                {commentsCount > 0 ? (
                  <span className={styles.grayText}>({commentsCount})</span>
                ) : null}
              </span>
            ),
            children: renderCommentSection(),
          },
        ]
      : []),
    {
      key: TabKeys.FILES,
      label: (
        <span>
          {TabKeys.FILES}{' '}
          {task?.Documents?.length > 0 ? (
            <span className={styles.grayText}>({task?.Documents?.length})</span>
          ) : null}
        </span>
      ),
      children: (
        <div className={styles.documentsContainer}>
          <Documents value={task?.Documents} taskId={task?.Id} />
          {task?.Documents.length === 0 && (
            <div className={styles.noFiles}>There are no files associated with this task.</div>
          )}
          {(hasFullAccess(task?.TransactionId) ||
            hasAccess(task?.Id) ||
            campaignTaskFullAccess) && (
            <UploadDocument
              uploadButton={
                <Button
                  variant="hollow-bordered"
                  className={styles.addFilesBtn}
                  icon={<Plus className={styles.btnIcon} />}
                >
                  Add Files
                </Button>
              }
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={classNames(styles.rightSectionTabs, { [styles.disabled]: disabled })}>
      <Tabs
        activeKey={activeKey}
        size="middle"
        items={tabItems(commentsCount)}
        className={styles.viewTaskTabs}
        onChange={(tab) => !disabled && setActiveKey(tab)}
      />
      {disabled && <div className={styles.overlaySheet} />}
    </div>
  );
};
