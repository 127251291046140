import styles from './styles.module.scss';
import { isValidURL, validateEmail } from 'utils';
import UploadLogo from '../UploadLogo';
import { Cross } from 'components/Icons';
import * as Yup from 'yup';
import { Input, Select, Autocomplete, Phone, PropertyAddress, InputLabel } from 'components';
import {
  AddVendorPayloadType,
  VendorDetailType,
  VendorValidationErrors,
} from 'pages/ServicesCategory/components/AddVendorModal/constants';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

type GeneralInfoPropsTYpe = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
  vendorDetailTypes: VendorDetailType[];
  errors: VendorValidationErrors;
  setErrors: Function;
  phoneAdded: boolean;
  websiteAdded: boolean;
  addressAdded: boolean;
  allCategoriesOptions: {
    name: string;
    value: number;
  }[];
};

const GeneralInfo = (props: GeneralInfoPropsTYpe) => {
  const {
    newVendorPayload,
    setNewVendorPayload,
    vendorDetailTypes,
    errors,
    setErrors,
    allCategoriesOptions,
    phoneAdded,
    websiteAdded,
    addressAdded,
  } = props;
  const phoneSchema = Yup.string().phone('US', false, '');
  const phoneRef = useRef<any>(null);
  const websiteRef = useRef<any>(null);
  const addressRef = useRef<any>(null);
  const [phoneScrolled, setPhoneScrolled] = useState<boolean>(false);
  const [websiteScrolled, setWebsiteScrolled] = useState<boolean>(false);
  const [addressScrolled, setAddressScrolled] = useState<boolean>(false);

  useEffect(() => {
    if (
      vendorDetailTypes.includes(VendorDetailType.PhoneNumber) &&
      phoneRef?.current &&
      !phoneScrolled &&
      phoneAdded
    ) {
      phoneRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setPhoneScrolled(true);
    }
    if (
      vendorDetailTypes.includes(VendorDetailType.Website) &&
      websiteRef?.current &&
      !websiteScrolled &&
      websiteAdded
    ) {
      websiteRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setWebsiteScrolled(true);
    }
    if (
      vendorDetailTypes.includes(VendorDetailType.Address) &&
      addressRef?.current &&
      !addressScrolled &&
      addressAdded
    ) {
      addressRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setAddressScrolled(true);
    }
  }, [vendorDetailTypes, phoneRef, websiteRef, addressRef, phoneAdded, websiteAdded, addressAdded]);

  const validatePhone = (phone) => {
    try {
      phoneSchema.validateSync(phone);
      return true;
    } catch (error) {
      return false;
    }
  };

  const showEmailError = (): boolean => {
    if (!errors.businessEmailError) return false;

    if (newVendorPayload.Published && errors.businessEmailError === 'Enter email') return false;

    return true;
  };

  return (
    <>
      {newVendorPayload.Published ? (
        <UploadLogo newVendorPayload={newVendorPayload} setNewVendorPayload={setNewVendorPayload} />
      ) : null}
      <div className={styles.detailField}>
        <InputLabel label="Name" showAsterisk className={styles.detailLabel} />
        <Input
          className={styles.input}
          placeholder={'Enter name'}
          onBlur={(e) => {
            const value = e.target.value;
            if (!value)
              setErrors((prev) => {
                return { ...prev, businessNameError: 'Enter business name' };
              });
            else if (value.length > 255)
              setErrors((prev) => {
                return { ...prev, businessNameError: 'Max allowed characters: 255' };
              });
          }}
          onChange={(e, val) => {
            setNewVendorPayload((prev) => {
              return { ...prev, BusinessName: val };
            });
            if (val)
              setErrors((prev) => {
                return { ...prev, businessNameError: '' };
              });
          }}
          value={newVendorPayload.BusinessName}
          error=""
          errorClassName={styles.inputError}
          variant={Input.LIGHT_ROUNDED}
        />
        {errors.businessNameError ? (
          <div className={styles.validation}>{errors.businessNameError}</div>
        ) : null}
      </div>
      <div className={styles.detailField}>
        <InputLabel label="Category" showAsterisk />
        <Select
          id={'new-vendor-category-dd'}
          options={allCategoriesOptions}
          variant={Autocomplete.LIGHT}
          placeholder={'Select category'}
          onSelect={(values) => {
            setNewVendorPayload((prev) => {
              return {
                ...prev,
                Category: values?.target?.value
                  ? { name: values.target.value.name, value: values.target.value.value }
                  : undefined,
              };
            });
            setErrors((prev) => {
              return { ...prev, categoryError: values?.target?.value ? '' : 'Select category' };
            });
          }}
          value={newVendorPayload.Category}
          emptyMessage={'No categories available!'}
          className={styles.input}
        />
        {errors.categoryError ? (
          <div className={styles.validation}>{errors.categoryError}</div>
        ) : null}
      </div>
      <div className={styles.detailField}>
        <InputLabel label="Email" showAsterisk={!newVendorPayload.Published} />
        <Input
          className={styles.input}
          placeholder={'Enter email'}
          onBlur={(e) => {
            const value = e.target.value;
            if (!value)
              setErrors((prev) => {
                return { ...prev, businessEmailError: 'Enter email' };
              });
            else if (value?.trim() && !validateEmail(value.trim()))
              setErrors((prev) => {
                return { ...prev, businessEmailError: 'Enter valid email' };
              });
            else if (value?.trim() && value.length > 255) {
              setErrors((prev) => {
                return { ...prev, businessEmailError: 'Max allowed characters: 255' };
              });
            } else
              setErrors((prev) => {
                return { ...prev, businessEmailError: '' };
              });
          }}
          onChange={(e, val) => {
            setNewVendorPayload((prev) => {
              return { ...prev, Email: val.trim() };
            });
          }}
          value={newVendorPayload.Email}
          variant={Input.LIGHT_ROUNDED}
        />
        {showEmailError() ? (
          <div className={styles.validation}>{errors.businessEmailError}</div>
        ) : null}
      </div>
      {vendorDetailTypes.map((type) => {
        if (type === VendorDetailType.PhoneNumber)
          return (
            <div className={styles.detailField} ref={phoneRef}>
              <InputLabel label="Phone" />
              <Phone
                onBlur={async (e) => {
                  const value = e.target.value;
                  if (value && !validatePhone(value))
                    setErrors((prev) => {
                      return { ...prev, phoneError: 'Must be a valid phone number for region US' };
                    });
                  else
                    setErrors((prev) => {
                      return { ...prev, phoneError: '' };
                    });
                }}
                className={classNames(
                  styles.input,
                  styles.clientPhoneInput,
                  newVendorPayload.Phone
                    ? styles.clientPhoneInputShow
                    : styles.clientPhoneInputHide,
                )}
                placeholder="(---) --- -----"
                value={newVendorPayload.Phone || ''}
                error=""
                errorClassName={styles.inputError}
                onChange={async (e, value) => {
                  setNewVendorPayload((prev) => {
                    return { ...prev, Phone: value };
                  });
                  setErrors((prev) => {
                    return { ...prev, phoneError: '' };
                  });
                }}
                removeHandler={() => {
                  setNewVendorPayload((prev) => {
                    return { ...prev, Phone: '' };
                  });
                  setErrors((prev) => {
                    return { ...prev, phoneError: '' };
                  });
                }}
                removeIcon={<Cross color="#747475" />}
              />
              {errors.phoneError ? (
                <div className={styles.validation}>{errors.phoneError}</div>
              ) : null}
            </div>
          );
        else if (type === VendorDetailType.Website)
          return (
            <div className={styles.detailField} ref={websiteRef}>
              <InputLabel label="Website" />
              <Input
                className={styles.input}
                placeholder={'Enter website'}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value) {
                    if (!isValidURL(value.trim()))
                      setErrors((prev) => {
                        return { ...prev, websiteError: 'Enter valid URL' };
                      });
                    else if (value.length > 255)
                      setErrors((prev) => {
                        return { ...prev, websiteError: 'Max allowed characters: 255' };
                      });
                    else
                      setErrors((prev) => {
                        return { ...prev, websiteError: '' };
                      });
                  } else
                    setErrors((prev) => {
                      return { ...prev, websiteError: '' };
                    });
                }}
                onChange={(e, val) => {
                  setNewVendorPayload((prev) => {
                    return { ...prev, Website: val.trim() };
                  });
                  setErrors((prev) => {
                    return { ...prev, websiteError: '' };
                  });
                }}
                value={newVendorPayload.Website}
                error=""
                errorClassName={styles.inputError}
                variant={Input.LIGHT_ROUNDED}
              />
              {errors.websiteError ? (
                <div className={styles.validation}>{errors.websiteError}</div>
              ) : null}
            </div>
          );
        else if (type === VendorDetailType.Address)
          return (
            <div className={styles.detailField} ref={addressRef}>
              <PropertyAddress
                searchWrapperClassName={classNames(styles.addressInput, styles.input)}
                overrideLocationsSearchStyles={true}
                updateLocation={(address) => {
                  if (address?.ProviderPlaceId) {
                    setNewVendorPayload((prev) => {
                      return {
                        ...prev,
                        Address: { ...address },
                      };
                    });
                  } else if (address?.Line2)
                    setNewVendorPayload((prev) => {
                      return { ...prev, Address: { ...prev.Address, Line2: address?.Line2 } };
                    });
                  else
                    setNewVendorPayload((prev) => {
                      return { ...prev, Address: {} };
                    });
                }}
                address={
                  newVendorPayload?.Address?.Line2
                    ? { Line2: newVendorPayload.Address.Line2 }
                    : undefined
                }
                location={newVendorPayload?.Address || {}}
                variant={Input.LIGHT_ROUND}
                unitVariant={Input.FULL}
                unitClassName={classNames(
                  styles.unitApt,
                  newVendorPayload.Address?.Line2 ? styles.unitAptShow : styles.unitAptHide,
                )}
                unitRemoveHandler={() => {
                  setNewVendorPayload((prev) => {
                    return { ...prev, Address: { ...prev.Address, Line2: undefined } };
                  });
                }}
              />
            </div>
          );
        return null;
      })}
    </>
  );
};

export default GeneralInfo;
