import { combineReducers } from 'redux';

import savedSearchesProperties from './savedSearchesProperties';
import savedSearchesPropertiesDraw from './savedSearchesPropertiesDraw';
import agentProperties from './agentProperties';
import agentPropertiesDraw from './agentPropertiesDraw';
import favorites from './favorites';
import highlights from './highlights';
import sort from './sort';
import meta from './meta';
import pageInfo from './pageInfo';
import criteria from './criteria';
import clientsCounts from './clientsCounts';
import topMatch from './topMatch';

export default combineReducers({
  savedSearchesProperties,
  savedSearchesPropertiesDraw,
  agentPropertiesDraw,
  agentProperties,
  favorites,
  highlights,
  sort,
  meta,
  pageInfo,
  criteria,
  clientsCounts,
  topMatch,
});
