/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk } from 'lodash-es';

import styles from './styles.module.scss';

export const getHintColor = (val) => {
  const MatchScore = Number(val);
  if (MatchScore >= 75) {
    return 'green';
  } else if (MatchScore >= 25 && MatchScore < 75) {
    return 'orange';
  } else return 'gray';
};

const Match = ({ propertyInfo, className, isPriceReduction }) => {
  const isHint = propertyInfo?.MatchedCriterias?.length ? { 'data-rh': true } : {};

  const getPreferences = () => chunk(propertyInfo?.MatchedCriterias, 2);
  const { MatchScore } = propertyInfo || {};

  if (MatchScore === null || MatchScore === undefined) {
    return null;
  }

  return (
    <div
      {...isHint}
      data-match={JSON.stringify({ matchedCriterias: getPreferences(), matchScore: MatchScore })}
      className={classNames(styles.matchScoreWrapper, styles[getHintColor(MatchScore)], className, {
        [styles.priceReductionsMatch]: isPriceReduction,
      })}
      testid="data_match"
      onClick={(e) => e.stopPropagation()}
      position="top"
    >
      <span testid="match_score">{`${MatchScore || 0}%`}</span>
      <span className={styles.text}>Match</span>
    </div>
  );
};

Match.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    MatchScore: PropTypes.number,
    MatchedCriterias: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }).isRequired,
};

Match.defaultProps = {
  className: '',
};

export default Match;
