import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import moment from 'moment';
import { getDate } from 'helpers';
import { getUserId } from '../user';
import { getTransactionSelector, getAllTransactionParticipantsSelector } from '../transaction';
import { getTransactionStatusCount } from 'utils';
import { union } from 'lodash-es';

const localState = ({ transactionTasks }) => transactionTasks;
const taskAggregateState = ({ taskAggregate }) => taskAggregate;

export const getTransactionTasksSelector = createSelector(
  localState,
  getTransactionSelector,
  getUserId,
  taskAggregateState,
  (transactionTasks, { transaction }, userId, taskAggregate) => ({
    isIdle: transactionTasks.state === IDLE,
    isPending: transactionTasks.state === PENDING,
    isError: transactionTasks.state === ERROR,
    isData: !!transactionTasks?.data,
    status: transactionTasks?.meta?.status,
    isCreator: userId === transaction?.Creator?.Id,
    tasks: union(transactionTasks?.data || [], taskAggregate?.data || []),
  }),
);

export const getIsTaskFiltersApplied = createSelector(
  localState,
  ({ isTaskFiltersApplied }) => isTaskFiltersApplied,
);

export const getTaskFilters = createSelector(localState, ({ filters }) => filters);

export const getTransactionsTasksFilterSelector = createSelector(
  localState,
  (transactionsTasksFilter) => transactionsTasksFilter,
);

export const getTasksLastStatePerTransactionSelector = createSelector(
  localState,
  (transactionTasks) => transactionTasks?.lastStatePerTransaction,
);

export const getTransactionTasksStatisticsSelector = createSelector(
  getTransactionTasksSelector,
  ({ tasks }) => {
    return getTransactionStatusCount(tasks);
  },
);

// TODO: Remove if not required
export const getFilteredTasksSelector = createSelector(
  getTransactionTasksSelector,
  getTransactionSelector,
  ({ tasks }, { isPending }) => {
    return {
      tasks,
      isPending,
    };
  },
);

export const getTransactionTaskAccess = createSelector(
  getTransactionTasksSelector,
  getUserId,
  getAllTransactionParticipantsSelector,
  ({ tasks }, userId, transactionParticipants) => {
    return {
      hasAccess: (taskId) => {
        const data = tasks?.find((item) => item?.Id === taskId);

        // Check if user is in CC List and is full access participant of transaction

        let hasCCFullAccess = false;
        const transactionParticipant = transactionParticipants?.find((item) => item?.Id === userId);
        if (
          data?.CcList?.map((item) => item?.Id)?.includes(userId) &&
          transactionParticipant?.TransactionAccess
        )
          hasCCFullAccess = true;

        const hasPermission =
          data?.AssigneeList?.filter((item) => item?.Id === userId).length ||
          userId === data?.AssignorId ||
          hasCCFullAccess;

        return hasPermission;
      },
    };
  },
);
