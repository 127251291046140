import { useEffect, useState } from 'react';

import { Row, Col, Checkbox } from 'antd';
import { ButtonCheckbox } from '../ButtonCheckbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import styles from './styles.module.scss';
import { InputCheckbox } from '../InputCheckbox';
import { DropdownCheckbox } from '../DropdownCheckbox';
import { cloneDeep, isEqual } from 'lodash-es';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const OptionGroup = (props) => {
  const {
    fieldTitle,
    options,
    isFirstStepDropDownGroup,
    isFirstStepInputGroup,
    allowMultipleSelection,
    hasFirstStep,
    firstStepOptions,
    hasSecondStep,
    secondStepOptions,
    firstStepBoxTitle,
    secondStepBoxTitle,
    isSecondStepNumberGroup,
    onMainCheckboxChange,
    onStepOneCheckboxChange,
    onStepTwoCheckboxChange,
    onUserInputChange,
    disableAllCheck,
    trigger,
  } = props;

  const [showStepOne, setShowStepOne] = useState(false);
  const [showStepTwo, setShowStepTwo] = useState(false);

  useEffect(() => {
    if (options?.filter((opt) => opt.checked).length > 0) {
      setShowStepOne(true);
    }
    if (firstStepOptions?.filter((opt) => opt.checked).length > 0) {
      setShowStepTwo(true);
    }
  }, []);

  useEffect(() => {
    setShowStepTwo(true);
  }, [trigger]);

  const checkboxChecked = (checkedValues: CheckboxValueType[]) => {
    onMainCheckboxChange(checkedValues); // INFO: this will be called when user check email/sms/in-app
    if (checkedValues.length > 0) {
      setShowStepOne(true);
    } else {
      setShowStepOne(false);
      setShowStepTwo(false);
    }
  };

  const checkStepTwo = (checkedValues: CheckboxValueType[]) => {
    let values = cloneDeep(checkedValues);
    if (checkedValues.length > 1 && isEqual(checkedValues, ['Realtime', 'OnceDay'])) {
      const old = firstStepOptions?.filter((opt) => opt.checked).map((opt) => opt.value);
      values = values.filter((chk) => !old.includes(chk));
    }

    onStepOneCheckboxChange(values); // INFO: this will be called when user check second one options after checking email/sms/in-app

    const previousSelectedValues = firstStepOptions
      .filter((opt) => opt.checked)
      .map(({ value }) => value);

    if (
      !disableAllCheck &&
      ((previousSelectedValues.includes('All') && !checkedValues.includes('All')) ||
        (previousSelectedValues.includes('AllTasks') && !checkedValues.includes('AllTasks')))
    ) {
      setShowStepTwo(false);
    } else {
      checkedValues.length > 0 ? setShowStepTwo(true) : setShowStepTwo(false);
    }
  };

  return (
    <div className={styles.optionGroup}>
      <p className={styles.fieldTitle}>{fieldTitle}</p>
      <div className={styles.optionsWrap}>
        <ButtonCheckbox
          options={options}
          onChange={checkboxChecked}
          value={options?.filter((opt) => opt.checked).map((opt) => opt.value)}
        />
      </div>

      {hasFirstStep && showStepOne && (
        <div className={styles.boxWrap}>
          {isFirstStepInputGroup ? (
            firstStepOptions.map((item, index) => {
              return (
                <Col sm={24} md={24}>
                  {item.isInput ? (
                    <InputCheckbox
                      inputText={item.inputText}
                      label={item.label}
                      value={item.value}
                      checked={item.checked}
                      inputValue={item.inputValue}
                      inputPlaceholder={item.inputPlaceholder}
                      onChangeCheckbox={(e) =>
                        checkStepTwo(e.target.checked ? [e.target.value] : [])
                      }
                      onChangeInput={(e) => {
                        if (item.value === 'HasMatchScore') {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '');
                          let numValue = Math.min(Math.max(Number(value), 0), 100);
                          onStepOneCheckboxChange([item.value], numValue.toString());
                        } else onStepOneCheckboxChange([item.value], e.target.value);
                      }}
                      className={styles.stepOneInputCheckbox}
                      wrapClassName={styles.checkboxInputWrap}
                    />
                  ) : (
                    <Checkbox
                      className={styles.customCheckbox}
                      value={item.value}
                      checked={item.checked}
                      onChange={(e) => {
                        checkStepTwo(e.target.checked ? [e.target.value] : []);
                      }}
                    >
                      {item.label}
                    </Checkbox>
                  )}
                </Col>
              );
            })
          ) : isFirstStepDropDownGroup ? (
            <div className={styles.stepOne}>
              {firstStepBoxTitle && <h4 className={styles.boxTitle}>{firstStepBoxTitle}</h4>}
              <DropdownCheckbox
                options={firstStepOptions[0].dropdownOptions}
                value={firstStepOptions[0].value}
                inputText={firstStepOptions[0].inputText}
                checked={firstStepOptions[0].checked}
                inputValue={firstStepOptions[0].inputValue}
                selectedValue={firstStepOptions[0].selectedValue}
                onChangeCheckbox={(value) => checkStepTwo([value])}
                onChangeInput={(e, value) => onStepOneCheckboxChange([value], e.target.value)}
                onChangeSelect={(selectedValue, value) =>
                  onStepOneCheckboxChange([value], null, selectedValue)
                }
              />
            </div>
          ) : allowMultipleSelection ? (
            <div className={styles.stepOne}>
              {firstStepBoxTitle && <h4 className={styles.boxTitle}>{firstStepBoxTitle}</h4>}

              <Checkbox.Group
                onChange={checkStepTwo}
                value={firstStepOptions?.filter((opt) => opt.checked).map((opt) => opt.value)}
                className={styles.checkboxGroup}
              >
                {firstStepOptions.map((item, index) => {
                  return (
                    <Col sm={24} md={24}>
                      {item.isInput ? (
                        <InputCheckbox
                          inputText={item.inputText}
                          label={item.label}
                          value={item.value}
                          checked={item.checked}
                          inputValue={item.inputValue}
                          inputPlaceholder={item.inputPlaceholder}
                          onChangeCheckbox={(e) => {}}
                          onChangeInput={(e) => onUserInputChange(item.value, e.target.value)}
                          className={styles.stepOneInputCheckbox}
                          wrapClassName={styles.checkboxInputWrap}
                        />
                      ) : (
                        <Checkbox
                          className={styles.customCheckbox}
                          value={item.value}
                          checked={item.checked}
                        >
                          {item.label}
                        </Checkbox>
                      )}
                    </Col>
                  );
                })}
              </Checkbox.Group>
            </div>
          ) : (
            <div className={styles.stepOne}>
              {firstStepBoxTitle && <h4 className={styles.boxTitle}>{firstStepBoxTitle}</h4>}
              <Checkbox.Group
                className={styles.checkboxGroup}
                options={firstStepOptions}
                onChange={checkStepTwo}
                value={firstStepOptions?.filter((opt) => opt.checked).map((opt) => opt.value)}
              />
            </div>
          )}

          {hasSecondStep && showStepTwo && !isSecondStepNumberGroup ? (
            <div className={styles.stepTwo}>
              <span className={styles.separator}></span>
              {secondStepBoxTitle && <h4 className={styles.boxTitle}>{secondStepBoxTitle}</h4>}
              <Row>
                {secondStepOptions.map((item, index) => {
                  return (
                    <Col sm={24} md={12} key={index}>
                      {item.isInput ? (
                        <InputCheckbox
                          inputText={item.inputText}
                          value={item.value}
                          checked={item.checked}
                          inputValue={item.inputValue}
                          onChangeCheckbox={(e) =>
                            onStepTwoCheckboxChange([e.target.checked ? item.value : ''])
                          }
                          onChangeInput={(e) =>
                            onStepTwoCheckboxChange([item.value], e.target.value)
                          }
                        />
                      ) : (
                        <Checkbox
                          className={styles.customCheckbox}
                          value={item.value}
                          checked={item.checked}
                          onChange={(e) =>
                            onStepTwoCheckboxChange([e.target.checked ? item.value : ''])
                          }
                        >
                          {item.label}
                        </Checkbox>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : (
            hasSecondStep &&
            showStepTwo &&
            isSecondStepNumberGroup && (
              <div className={styles.stepTwo}>
                <span className={styles.separator}></span>
                {secondStepBoxTitle && <h4 className={styles.boxTitle}>{secondStepBoxTitle}</h4>}
                <Checkbox.Group
                  className={styles.checkboxGroup}
                  options={secondStepOptions}
                  value={secondStepOptions?.filter((opt) => opt.checked).map((opt) => opt.value)}
                  onChange={onStepTwoCheckboxChange}
                />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
