import { combineReducers } from 'redux';

import allClientDocuments from './allClientDocuments';
import clientTransactionDocuments from './clientTransactionDocuments';
import preapprovalDocuments from './preapprovalDocuments';
import archives from './archives';
import meta from './meta';
import miscDocuments from './miscDocuments';
export default combineReducers({
  allClientDocuments,
  clientTransactionDocuments,
  preapprovalDocuments,
  archives,
  meta,
  miscDocuments,
});
