import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Marker } from 'components/Icons';

import styles from './styles.module.scss';
import { getAgentName, getTime } from 'pages/Workshop/Tours/helper';
import { TourStatus } from 'app-constants/enums/tours';
import { getCleanedText } from 'utils/pinpointHelpers';

export const TimelineItem = (props) => {
  const { children, className, infoWrapClassName, tour, hideTime } = props;
  const [showNotes, setShowNotes] = useState(true);
  const notesCount = tour.ClientNotes ? 1 : 0;

  const getStreetTwo = () => {
    return getCleanedText(
      tour?.PropertyAddress?.City,
      tour?.PropertyAddress?.State,
      tour?.PropertyAddress?.Zip,
    );
  };

  return (
    <div className={classNames(styles.item, className)}>
      {tour.Status !== TourStatus.Requested && !hideTime && (
        <p className={styles.date}>{getTime(tour.ScheduleDateTime)}</p>
      )}

      <span
        className={
          tour.Status !== TourStatus.Requested && !hideTime
            ? styles.separator
            : styles.separatorUnlined
        }
      >
        <Marker className={styles.icon} />
      </span>
      <div className={classNames(styles.info, infoWrapClassName)}>
        <Link
          className={styles.streetInfo}
          to={{
            pathname: `/properties/search/listing-detail/${tour.PropertyId}`,
            state: { backUrl: location.pathname },
          }}
        >
          <span className={styles.streetOne}>
            {tour.PropertyAddress.Line2
              ? `${tour.PropertyAddress.Line1} ${tour.PropertyAddress.Line2},`
              : `${tour.PropertyAddress.Line1},`}
          </span>
          <span className={styles.streetTwo}>{getStreetTwo()}</span>
        </Link>
        <p className={styles.agentInfo}>
          <span>{tour.TourAssignee.length > 1 ? 'Agents:' : 'Agent:'}</span> {getAgentName(tour)}
          {tour.Status === TourStatus.Requested && <span className={styles.pending}>Pending</span>}
        </p>
        {notesCount > 0 && (
          <>
            <div className={classNames(styles.notes, { [styles.show]: !showNotes })}>
              {tour.ClientNotes && (
                <p className={styles.note}>
                  <span>Notes: </span>
                  {tour.ClientNotes}
                </p>
              )}
            </div>
            <span className={styles.notesToggler} onClick={() => setShowNotes(!showNotes)}>
              {showNotes ? 'View' : 'Hide'} Notes {showNotes && `${notesCount}`}
            </span>
          </>
        )}
      </div>
      <div className={styles.action}>{children}</div>
    </div>
  );
};
