import { Tours } from 'pages/Workshop/Tours';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { getUserRolesMapSelector } from 'store/selectors/user';
import ClientFeed from './ClientFeed';
import ClientResults from './ClientResults/ClientResults';
import AgentFeed from './AgentFeed';

import Search from './Search';
import { ClientTours } from './Tours';
import WithSelectedProperties from './WithSelectedProperties';
import AgentResults from './AgentResults/AgentResults';
import { AgentDashboard } from './AgentDashboard';
import { ClientDashboard } from './ClientDashboard';

export const Properties = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isClient } = useSelector(getUserRolesMapSelector);

  const showComponent = () => {
    switch (pathname) {
      case routes.searchResults:
        return (
          <>
            {isClient ? (
              <WithSelectedProperties>
                <ClientResults />
              </WithSelectedProperties>
            ) : (
              <WithSelectedProperties>
                <AgentResults />
              </WithSelectedProperties>
            )}
          </>
        );
      case routes.feed:
        return (
          <>
            {isClient ? (
              <WithSelectedProperties>
                <ClientFeed />
              </WithSelectedProperties>
            ) : (
              <WithSelectedProperties>
                <AgentFeed />
              </WithSelectedProperties>
            )}
          </>
        );
      case routes.properties:
        return <>{isClient ? <ClientDashboard /> : <AgentDashboard />}</>;
      case routes.clientTours:
        return isClient ? <ClientTours /> : <Tours />;
      default:
        history.replace(routes.feed);
    }
  };

  return <>{showComponent()}</>;
};
