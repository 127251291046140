import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'components';
import AllTab from './components/AllTab';
import MyRecommendationsTab from './components/MyRecommendationsTab';
import ArchivesTab from './components/ArchivesTab';
import { OpenFolderIcon, StarIcon, ArchiveIcon } from './icons';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { updateConciergeSelectedTab } from 'store/actions/concierge';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ConciergeDefaultCategories } from 'pages/Services/constants';

const AgentPartnersList = ({ title }) => {
  const dispatch = useDispatch();
  const { selectedTab, category } = useSelector(getConciergeSearchSelector);

  useEffect(() => {
    if (Number(category) === ConciergeDefaultCategories.Draft)
      dispatch(updateConciergeSelectedTab(0));
  }, [category]);

  const setTab = (tabIndex) => {
    dispatch(updateConciergeSelectedTab(tabIndex));
  };

  const tabs = useMemo(
    () => [
      {
        id: 'recommendations',
        label: 'My Recommendations',
        Icon: <StarIcon />,
        Component: MyRecommendationsTab,
        testid: 'recommendations',
      },
      { id: 'all', label: `Directory`, Icon: <OpenFolderIcon />, Component: AllTab, testid: 'all' },
      {
        id: 'archive',
        label: 'Archive',
        Icon: <ArchiveIcon />,
        Component: ArchivesTab,
        testid: 'archive',
      },
    ],
    [title, selectedTab],
  );

  return (
    <div className={styles.wrapper}>
      <Tabs
        tabsClassName={classNames(
          styles.messagesItemTabs,
          Number(category) === ConciergeDefaultCategories.Draft ||
            Number(category) === ConciergeDefaultCategories.Pending
            ? styles.hideTabs
            : '',
        )}
        tabClassName={styles.messagesItemTab}
        activeTabClassName={styles.messagesActiveItemTab}
        tabs={tabs}
        contentClassName={styles.agentPartnerTab}
        categoryTitle={title}
        activeTabIndex={selectedTab}
        onChange={setTab}
      />
    </div>
  );
};

AgentPartnersList.propTypes = {
  title: PropTypes.string,
};

AgentPartnersList.defaultProps = {
  title: null,
};

export default AgentPartnersList;
