/* eslint-disable max-len */
import { createSelector } from 'reselect';
import { get, groupBy } from 'lodash-es';
import moment from 'moment';

import {
  groupNotificationsTypes,
  notificationTypes,
  NotificationTypesForGrouping,
  SeverityTagsMapping,
} from 'settings/constants/notifications';
import {
  filterNotificationsByType,
  orderByDate,
} from 'components/Drawers/NotificationsDrawer/helpers';
import { getPropertyAddress } from 'helpers';

export const localState = ({ sockets }) => get(sockets, 'notifications');

export const getNotificationsListSelector = createSelector(
  localState,
  (notifications) => notifications,
);

export const getIsUnreadNotificationsSelector = createSelector(
  getNotificationsListSelector,
  (notifications) => notifications?.some((notification) => !notification?.IsRead),
);

export const getGroupedNotificationsSelector = ({ filter, isClient }) =>
  createSelector(getNotificationsListSelector, (notifications) => {
    if (filter && filter.severityLevel && !Array.isArray(filter.severityLevel)) {
      filter.severityLevel = [filter.severityLevel];
    }
    const filteredNotifications = notifications.filter((notific) =>
      filter?.severityLevel &&
      filter?.severityLevel.length &&
      !filter?.severityLevel.includes(SeverityTagsMapping.All)
        ? filter.severityLevel.includes(notific.SeverityLevel)
        : true,
    );
    const getAvatarUrl = (notifics) => {
      let avatarUrl = null;
      notifics.some((notification) => {
        const { Payload } = notification || {};
        if (Payload.AvatarUrl || Payload.ClientAvatarUrl || Payload.SenderAvatarUrl) {
          avatarUrl = Payload.AvatarUrl || Payload.ClientAvatarUrl || Payload.SenderAvatarUrl;
          return true;
        }
        return false;
      });
      return avatarUrl;
    };

    const groupByDate = groupBy(filteredNotifications, (item) => {
      const messageDate = moment(item?.CreatedDate);
      const today = moment().startOf('day');

      const isToday = () => messageDate.isSame(today, 'd');

      if (!item?.IsRead) return groupNotificationsTypes.Unread;
      if (isToday()) {
        return groupNotificationsTypes.Today;
      }
      return groupNotificationsTypes.Earlier;
    });

    return Object.entries(groupByDate).reduce((acc, [date, notificationsValues]) => {
      // #region For agent new listing notification grouping by there clients which matched
      const otherNotifications = []; //INFO: other than new listing agent notifications
      const groupByClient = {};

      const newListingAgentNotifications = notificationsValues.filter((notific) => {
        if (
          [
            notificationTypes.NewListingAgent,
            notificationTypes.NewListingWithHighMatchscoreAgent,
          ].includes(notific.Type) &&
          !filter?.severityLevel
        ) {
          return true;
        } else {
          otherNotifications.push(notific);
          return false;
        }
      });

      if (newListingAgentNotifications.length && !filter?.severityLevel) {
        const clientsNames = [
          ...new Set(
            (newListingAgentNotifications || [])
              ?.map((notific) =>
                notific?.Payload?.Properties?.map((item) =>
                  item.ClientListWithMatchPercentage?.map(
                    (list) => `${list.ClientFirstName} ${list.ClientLastName}`,
                  ),
                ),
              )
              ?.flat(2),
          ),
        ];

        const groupByClientList = clientsNames.map((clientName) =>
          groupBy(newListingAgentNotifications, (item) => {
            return item?.Payload?.Properties?.map((prop) =>
              prop.ClientListWithMatchPercentage.map((list) =>
                clientName === `${list.ClientFirstName} ${list.ClientLastName}`
                  ? clientName
                  : false,
              ),
            )
              .flat(1)
              .filter((name) => name)?.[0];
          }),
        );

        for (let i = 0; i < groupByClientList.length; i++) {
          Object.assign(groupByClient, groupByClientList[i]);
        }
        delete groupByClient['undefined'];
      }
      // #endregion

      let notificationsByName = groupBy(otherNotifications, (item) => {
        const itemPayload = item?.Payload || {};

        if (filter?.severityLevel || isClient) {
          //INFO: Type and TransactionId based grouping for client based
          if (isClient && NotificationTypesForGrouping.Tasks.includes(item?.Type)) {
            if (
              //INFO: Type, TransactionId, and TaskId based grouping for client if comments on any tasks
              [notificationTypes.NewTaskComment, notificationTypes.NewTaskCommentTagged].includes(
                item?.Type,
              )
            ) {
              return `${item.Type}-${itemPayload?.TransactionId}-${itemPayload?.TaskId}`;
            }
            return `${item.Type}-${itemPayload?.TransactionId}`;
          }
          //INFO: Type and ThreadId based grouping for client based
          if (isClient && NotificationTypesForGrouping.NewMessage.includes(item?.Type)) {
            return `${item.Type}-${itemPayload?.ThreadId}`;
          }

          if (NotificationTypesForGrouping.NewListing.includes(item?.Type)) {
            return item?.Type;
          }
          //INFO: Group by Id is just ignore all form of grouping if filter applied
          return item.Id;
        } else {
          //INFO: Name based grouping for agent
          const FirstName =
            itemPayload?.FirstName ||
            itemPayload?.CreatorFirstName ||
            itemPayload?.Assignor?.FirstName ||
            itemPayload?.ClientFirstName ||
            itemPayload?.SenderFirstName ||
            itemPayload?.AgentFirstName ||
            itemPayload?.RequestorFirstName ||
            itemPayload?.BuyerAgentFirstName ||
            itemPayload?.CreatorFirstName ||
            itemPayload?.FirstName ||
            itemPayload?.DeclinerFirstName ||
            itemPayload?.CancelingPersonFirstName ||
            itemPayload?.RequesterName ||
            itemPayload?.ListingAgentName ||
            itemPayload?.BuyerAgentName ||
            itemPayload?.SubmitterName ||
            itemPayload?.SellerAgentName ||
            itemPayload?.BuyingAgentName ||
            itemPayload?.PartnerName ||
            itemPayload?.TaskChangedByName ||
            itemPayload?.SenderName ||
            itemPayload?.Username ||
            itemPayload?.EditorName ||
            itemPayload?.SignatoryName ||
            itemPayload?.TaskAssignedToName;

          const LastName =
            itemPayload?.LastName ||
            itemPayload?.CreatorLastName ||
            itemPayload?.Assignor?.LastName ||
            itemPayload?.ClientLastName ||
            itemPayload?.SenderLastName ||
            itemPayload?.AgentLastName ||
            itemPayload?.RequestorLastName ||
            itemPayload?.BuyerAgentLastName ||
            itemPayload?.CreatorLastName ||
            itemPayload?.LastName ||
            itemPayload?.DeclinerLastName ||
            itemPayload?.CancelingPersonLastName;

          if (FirstName) {
            return `${FirstName}${LastName ? ` ${LastName}` : ''}`;
          }

          if (itemPayload?.Address?.Line1) {
            return itemPayload?.Address?.Line1;
          }

          if (itemPayload?.MilestoneName) {
            return itemPayload.MilestoneName;
          }

          if (itemPayload?.TransactionAddressLine1) {
            return itemPayload.TransactionAddressLine1;
          }

          if (itemPayload?.PropertyAddressLine1) {
            return itemPayload.PropertyAddressLine1;
          }

          if (itemPayload?.FormName) {
            return itemPayload.FormName;
          }

          if (itemPayload?.StreetAddress) {
            return itemPayload.StreetAddress;
          }

          if (itemPayload?.PropertyAddress) {
            return getPropertyAddress(itemPayload?.PropertyAddress);
          }
          if (itemPayload?.summary) {
            // we dont want to group task summary notifications
            return itemPayload?.Link;
          }
          return 'Unknown';
        }
      });

      Object.keys(groupByClient).forEach((key) => {
        //INFO: If group key is same then merge there values
        if (notificationsByName[key]) {
          groupByClient[key] = [...groupByClient[key], ...notificationsByName[key]];
        }
      });

      notificationsByName = { ...notificationsByName, ...groupByClient };

      const notificationsData = Object.entries(notificationsByName).reduce(
        (acc2, [name, notifics]) => {
          const filteredNotificationsByDate = filterNotificationsByType(notifics, date);
          const lastCreatedDate = orderByDate(filteredNotificationsByDate, 'CreatedDate')?.[0]
            ?.CreatedDate;
          const avatarUrl = getAvatarUrl(filteredNotificationsByDate);
          const adjastedNotifications = filteredNotificationsByDate.map((notific) => ({
            ...notific,
            AvatarUrl: avatarUrl,
            UserName: name,
          }));

          return [
            ...acc2,
            {
              name,
              count: filteredNotificationsByDate?.length,
              notifications: {
                name,
                avatarUrl,
                list: orderByDate(adjastedNotifications, 'CreatedDate'),
              },
              isUnread: notificationsValues.some((item) => !item?.IsRead),
              lastCreatedDate,
              avatarUrl,
            },
          ];
        },
        [],
      );
      return {
        ...acc,
        [date]: orderByDate(notificationsData, 'lastCreatedDate'),
      };
    }, {});
  });

export const getGroupedSeverityNotificationSelector = createSelector(
  getNotificationsListSelector,
  (notifications) => {
    const array = [];
    notifications.forEach((notification) => {
      if (!notification.IsRead) {
        array.push(notification);
      }
    });
    return groupBy(array, 'SeverityLevel');
  },
);

//INFO: group by notitification type for client
const groupByTypeForClient = (notifications) => {};
