import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { Button } from 'components';
import { useOutsideClick } from 'hooks';
import { setFormLibraryModalVisibleAction } from 'store/actions/formProcess';
import { isFormLibraryModalVisibleSelector } from 'store/selectors/requestFormProcess';

import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';
import { InputSelectModal } from 'components-antd';
import { createTransactionFolderEffect } from 'store/effects/transactions/folders/create';
import {
  getTransactionFolderEffect,
  resetTransactionFolderEffect,
} from 'store/effects/transactions/folders/read';
import { showSuccessMessage } from 'helpers';
import { MyLibraryModal } from 'pages/Workshop/Forms/components/MyLibraryModal';
import { LinkFormModal } from 'pages/Workshop/Forms/components/LinkFormModal';
import Upload from '../Upload';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getTransactionDocumentsSelector } from 'store/selectors/transactionDocuments';
import { getTransactionFolderSelector } from 'store/selectors/transactionFolder';

const New = (props) => {
  const { className, uploadBtnProps, onClickOptions, open, setOpen, hasLimitedAccess, loading } =
    props;
  const [openLinkFormsModal, setOpenLinkFormsModal] = useState(false);
  const [modalUpload, setModalUpload] = useState({ open: false });
  const { folders } = useSelector(getTransactionFolderSelector) || [];
  const buttonRef = useRef();
  const optionsRef = useRef();
  const isFormLibraryModalVisible = useSelector(isFormLibraryModalVisibleSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const dispatch = useDispatch();
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { documents } = useSelector(getTransactionDocumentsSelector);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onLinkForm = (e) => {
    e.stopPropagation();
    setOpenLinkFormsModal(true);
    setOpen(false);
  };

  const onUpload = (e) => {
    e.stopPropagation();
    setModalUpload({ open: true });
    setOpen(false);
  };

  const onNewForm = (e) => {
    e.stopPropagation();
    openFormLibraryModalOrRequireEmailVerification(true);
    setOpen(false);
  };

  const onNewFolder = (e) => {
    e.stopPropagation();
    setOpenNewFolderModal(true);
    setOpen(false);
  };

  useEffect(() => {
    if (transaction?.Id && !loading) {
      dispatch(getTransactionFolderEffect(transaction, {}));
      if (folders?.length) {
        dispatch(resetTransactionFolderEffect());
      }
    }
  }, [transaction, loading]);

  const handleCreateFolder = async (setLoading, values) => {
    let { entityName } = values;
    if (entityName) {
      entityName = entityName.replace(/\s+/g, ' ');
      entityName = entityName.trim();
      setLoading(true);
      dispatch(
        createTransactionFolderEffect(entityName, {}, (err, response) => {
          if (!err) {
            dispatch(getTransactionFolderEffect(transaction, {}));
            setLoading(false);
            showSuccessMessage(`Folder '${entityName}' Created`);
            setOpenNewFolderModal(false);
          }
        }),
      );
      setLoading(false);
    }
  };

  const openFormLibraryModalOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.CREATE_FORM,
    (value) => dispatch(setFormLibraryModalVisibleAction(value)),
  );

  const onCloseFormLibraryModal = () => dispatch(setFormLibraryModalVisibleAction(false));
  return (
    <div className={classNames(styles.new, className)}>
      <Button
        title="New"
        className={styles.btn}
        iconLeft={<Icon className={styles.icon} variant={Icon.PLUS} />}
        titleClassName={styles.btnTitle}
        onClick={onClickOptions}
        testid="new_task_button"
        ref={buttonRef}
      />
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            <div>
              <li testid="upload_file_action" className={styles.item} onClick={onUpload}>
                <div className={styles.icon}>
                  <Icon variant={Icon.UPLOAD_2} color="#FF576D" />
                </div>
                <span>Upload</span>
              </li>
              {!hasLimitedAccess && (
                <>
                  <li testid="link_form_action" className={styles.item} onClick={onLinkForm}>
                    <div className={styles.icon}>
                      <Icon variant={Icon.LINK_FORM} color="#FF576D" />
                    </div>
                    <span>Link Form</span>
                  </li>
                  <li testid="new_form_action" className={styles.item} onClick={onNewForm}>
                    <div className={styles.icon}>
                      <Icon variant={Icon.PLUS} color="#FF576D" />
                    </div>
                    <span>New Form</span>
                  </li>
                  {!props.isNewFolder && (
                    <li testid="upload_action" className={styles.item} onClick={onNewFolder}>
                      <div className={styles.icon}>
                        <Icon variant={Icon.FOLDER_2} color="#FF576D" />
                      </div>
                      <span>New Folder</span>
                    </li>
                  )}
                </>
              )}
            </div>
          </ul>
        </div>
      )}

      {isAgent ? (
        <MyLibraryModal
          open={isFormLibraryModalVisible}
          transaction={transaction}
          onClose={onCloseFormLibraryModal}
        />
      ) : (
        <></>
      )}

      <LinkFormModal
        open={openLinkFormsModal}
        transaction={transaction}
        onClose={() => setOpenLinkFormsModal(false)}
      />

      <Upload {...uploadBtnProps} modalUpload={modalUpload} setModalUpload={setModalUpload} />

      <div className={styles.newFolderModal}>
        <InputSelectModal
          open={openNewFolderModal}
          title="New Folder"
          okText="Save"
          submit={handleCreateFolder}
          onCancel={() => setOpenNewFolderModal(false)}
          entityNameRequired={true}
          selectedItemRequired={true}
          entityNameMaxLength={64}
          destroyOnClose={true}
          titleClassName={styles.newFolderModalTitle}
          entityName="new"
        />
      </div>
    </div>
  );
};

New.propTypes = {
  className: PropTypes.string,
};

New.defaultProps = {
  className: '',
};

export default New;
