import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import {
  getDynamicFormRequiredErrorSelector,
  getDynamicFormScaleSelector,
  getDynamicFormSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

import styles from './styles.module.scss';

import moment from 'moment';
import { TextFitField } from './TextFitField';
import {
  DefaultSystemFont,
  SignatureResponseType,
  SignatureResult,
} from 'pages/FormBuilder/components';
import { extractSignatureFieldData } from 'pages/FormProcess/DynamicForm/helper';
import { InfoTooltip } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/InfoTooltip';
import {
  canFillNextQuestion,
  getDateFontSize,
  getGroupItemAnswer,
  shouldAllowOnlyOne,
} from './helper';
import { getFieldInitialFontSize } from './TextFitField/helper';

import { DatePicker } from 'antd';

import { TextBox } from './TextBox';
import { CheckboxField } from './CheckboxField';
import { RadioField } from './RadioField';
import { TextFieldProps } from './types';

moment.locale('en-us', {
  week: {
    dow: 7,
  },
});

export const TextField = ({
  question,
  disabled,
  answer,
  color,
  isHighlighted,
  handleFieldClick,
  documentIndex,
  otherSignatoryField,
  pageQuestions,
  responses,
  editorViewProps,
  onUpdateAnswer,
}: TextFieldProps) => {
  const scale = useSelector(getDynamicFormScaleSelector);

  const errors = useSelector(getDynamicFormRequiredErrorSelector);

  const { editorField, onFieldUpdate, isTemplate } = editorViewProps || {};
  const pdfEditorView = !!editorViewProps;
  const questionType = (question.Type || editorField?.type) as DYNAMIC_QUESTION_TYPE;

  let defaultChecked = false;
  let defaultValue = answer;

  const field = question?.Fields?.[0] || editorField;
  const widget = field?.widgets?.[0] || editorField;

  const { x, y, width, height } = widget;

  const fieldInitialFontSize = getFieldInitialFontSize(height, scale);
  const [date, setDate] = useState(defaultValue);
  const {
    meta: { address: defaultAddress },
  } = useSelector(getDynamicFormSelector);

  const { currentUserSignature: defaultSignature } = useSelector(getRequestFormProcessSelector);

  const isCheckbox = questionType === DYNAMIC_QUESTION_TYPE.CheckBox;
  const isTextBox = questionType === DYNAMIC_QUESTION_TYPE.TextBox;
  const isNumber = questionType === DYNAMIC_QUESTION_TYPE.Number;
  const isCurrency = questionType === DYNAMIC_QUESTION_TYPE.Currency;
  const isPhone = questionType === DYNAMIC_QUESTION_TYPE.Phone;
  const isDate = questionType === DYNAMIC_QUESTION_TYPE.Date;
  const isAddress = questionType === DYNAMIC_QUESTION_TYPE.Address;
  const isFullName = questionType === DYNAMIC_QUESTION_TYPE.FullName;
  const isRadioGroup = questionType === DYNAMIC_QUESTION_TYPE.RadioGroup;
  let isDisabledGroup = false;

  const allowOneAnswer = isRadioGroup && shouldAllowOnlyOne(question);

  let response: any = [];

  const canPrefillName = () => isFullName && !disabled && !pdfEditorView && !otherSignatoryField;

  useEffect(() => {
    if (canPrefillName()) {
      let response: SignatureResult | undefined;

      try {
        if (answer) response = JSON.parse(answer);
      } catch (e) {
        response = undefined;
      }

      const responseData = extractSignatureFieldData(response, questionType);

      if (!responseData && defaultSignature) {
        const data = {
          fullName: defaultSignature.FullName,
          type: SignatureResponseType.Text,
          font: DefaultSystemFont,
        };

        saveFieldAnswer(question.UUID, JSON.stringify(data), documentIndex, fieldInitialFontSize);
      }
    } else if (isAddress && !answer && defaultAddress && !editorField?.id) {
      saveFieldAnswer(question.UUID, defaultAddress, documentIndex, fieldInitialFontSize);
    }
  }, [defaultSignature]);

  try {
    if (answer && (isCheckbox || isRadioGroup)) response = JSON.parse(answer);
  } catch (e) {
    response = [];
  }

  if (isCheckbox || isRadioGroup) {
    if (response.includes(question.UUID)) {
      defaultChecked = true;
    } else {
      defaultChecked = false;
    }

    if (isRadioGroup && !defaultChecked) {
      isDisabledGroup = !canFillNextQuestion(
        pageQuestions,
        question?.QuestionValidationId,
        responses,
      );
    }
  }

  const hasValue = isFullName ? defaultValue && JSON.parse(defaultValue).fullName : defaultValue;

  const requiredClass = {
    [styles.required]: (question.Required || question.required) && !hasValue,
  };

  const isRequired =
    (isNumber ||
      isTextBox ||
      isPhone ||
      isCurrency ||
      isAddress ||
      isDate ||
      isFullName ||
      isRadioGroup) &&
    errors.some((error) => error.questionUUID === question?.UUID) &&
    !defaultValue?.length;

  const getFieldPosition = () => {
    if (isRadioGroup && question?.QuestionValidation) {
      const { x: groupX, y: groupY } = question.QuestionValidation.GroupField.widgets[0];
      return { fieldX: groupX + (x - groupX), fieldY: groupY + (y - groupY) };
    }

    return { fieldX: x, fieldY: y };
  };

  const saveFieldAnswer = (
    questionUUID,
    value,
    documentIndex,
    fontSize = 12,
    isCheckboxAnswer = false,
  ) => {
    if (pdfEditorView && !field.id) {
      const { fieldAnswer } = editorField;
      const shouldUpdate = Boolean(
        (!fieldAnswer && value.trim().length > 0) || (fieldAnswer && fieldAnswer.answer !== value),
      );

      shouldUpdate &&
        onFieldUpdate?.({
          fieldAnswer: { answer: value, UUID: questionUUID, fontSize, documentIndex },
        });
    } else {
      const shouldUpdate = Boolean(!editorField || value !== answer) || isCheckboxAnswer;

      if (shouldUpdate) {
        if (pdfEditorView) {
          const documentAnswers = { ...responses };

          documentAnswers[questionUUID] = documentAnswers[questionUUID] ?? {
            UUID: questionUUID,
            Answer: '',
            DefultAnswer: '',
          };

          documentAnswers[questionUUID].Answer = value;
          onUpdateAnswer?.(documentAnswers);
        }

        dynamicManager.handleQuestionResponse(
          questionUUID,
          value,
          documentIndex,
          fontSize,
          isCheckboxAnswer,
          pdfEditorView,
        );
      }
    }
  };

  const getStyles = () => {
    const { fieldX, fieldY } = getFieldPosition();
    return {
      style: {
        width: pdfEditorView ? '100%' : width * scale,
        height: pdfEditorView ? '100%' : height * scale,
        ...(!pdfEditorView && {
          left: fieldX * scale,
          top: fieldY * scale,
        }),

        fontFamily: field.fontFamily,
        ...(!isRadioGroup &&
          !isCheckbox &&
          !pdfEditorView && { border: `2px solid ${color.border}` }),

        ...(isTextBox && { fontSize: fieldInitialFontSize }),

        ...(isCheckbox || isRadioGroup
          ? {
              accentColor: color.border,
              '--border-color': color.border,
              // accentColor: '#fff',
              ...(!pdfEditorView && { '--border-color': color.border }),
            }
          : {}),

        ...(!pdfEditorView && {
          ...((isTextBox || isAddress || isFullName) && !isHighlighted
            ? { background: color.background }
            : {}),
          ...(isNumber && !isHighlighted ? { background: color.background } : {}),
          ...(isPhone && !isHighlighted ? { background: color.background } : {}),
          ...(isCurrency && !isHighlighted ? { background: color.background } : {}),
        }),
      },
    };
  };

  const resetPreviousQuestion = () => {
    let previousQuestionUUID;

    previousQuestionUUID = getGroupItemAnswer(pageQuestions, question, responses);
    if (previousQuestionUUID && previousQuestionUUID !== question.UUID) {
      dynamicManager.handleQuestionResponse(
        previousQuestionUUID,
        '',
        documentIndex,
        fieldInitialFontSize,
        true,
      );
    }

    return previousQuestionUUID;
  };

  const onRadioClick = async (event) => {
    let defaultAnswer = response;
    let { checked } = event.target;

    if (!isDisabledGroup) {
      checked = !defaultChecked;

      if (checked) {
        defaultAnswer = [question.UUID];
      }

      if (isRadioGroup) {
        if (isRequired) {
          const updatedConfig = errors.filter(({ questionUUID }) => questionUUID !== question.UUID);
          dynamicManager.updateRequiredConfig(updatedConfig);
        }

        if (allowOneAnswer && checked) {
          resetPreviousQuestion();
        }
      }

      dynamicManager.handleQuestionResponse(
        question.UUID,
        checked ? JSON.stringify(defaultAnswer) : '',
        documentIndex,
        fieldInitialFontSize,
        true,
      );
    }
  };

  const getFieldProps = () => {
    return {
      ...(isCheckbox || isRadioGroup
        ? {
            className: classNames({
              [styles.PDFFieldOverlay]: !pdfEditorView,
              [styles.checkboxField]: isCheckbox,
              [styles.radioField]: isRadioGroup,
            }),
            value: field.id,
            defaultChecked,
            ...(isRadioGroup
              ? {
                  type: 'radio',
                  onClick: onRadioClick,
                  ...(allowOneAnswer && { name: `radio-group-${question?.QuestionValidationId}` }),
                }
              : {
                  type: 'checkbox',
                  onChange: (e) => {
                    let defaultAnswer = response;
                    const { checked } = e.target;

                    defaultAnswer = defaultAnswer.filter((el) => el !== e.target.value);

                    if (checked) {
                      defaultAnswer = [question.UUID];
                    }

                    saveFieldAnswer(
                      question.UUID,
                      checked ? JSON.stringify(defaultAnswer) : '',
                      documentIndex,
                      fieldInitialFontSize,
                      true,
                    );
                  },
                }),
          }
        : {}),

      ...(isTextBox || isPhone || isDate || isAddress || isFullName
        ? {
            className: classNames(
              { [styles.PDFFieldOverlay]: !pdfEditorView },
              { [styles.textField]: !pdfEditorView },

              {
                [styles.activeTextField]: isHighlighted && !pdfEditorView,
                [styles.textBoxField]: isTextBox,
              },
              requiredClass,
            ),
            onBlur: (e, fontSize = fieldInitialFontSize) => {
              const value = e.target.value;

              const data = {
                fullName: value?.trim(),
                type: SignatureResponseType.Text,
                font: DefaultSystemFont,
              };

              isFullName &&
                saveFieldAnswer(question.UUID, JSON.stringify(data), documentIndex, fontSize);

              !isFullName && saveFieldAnswer(question.UUID, value.trim(), documentIndex, fontSize);
            },

            ...(isFullName && defaultValue
              ? { defaultValue: JSON.parse(defaultValue).fullName }
              : { defaultValue }),
          }
        : {}),

      ...(isNumber || isCurrency
        ? {
            className: classNames(
              { [styles.PDFFieldOverlay]: !pdfEditorView },
              { [styles.textField]: !pdfEditorView },

              {
                [styles.activeTextField]: isHighlighted,
              },
              isCurrency && requiredClass,
              {
                [styles.prefixContainer]: isCurrency,
                [styles.numberContainer]: isNumber,
                [styles.currencyEditorField]: isCurrency && pdfEditorView,
              },
            ),
            onBlur: (e, fontSize = fieldInitialFontSize) => {
              saveFieldAnswer(question.UUID, e.target.value, documentIndex, fontSize);
            },
          }
        : {}),
    };
  };

  const onUpdateDateField = (value) => {
    setDate(value);
  };

  const getProps = useCallback(
    () => ({
      ...getStyles(),
      ...getFieldProps(),
      disabled,
    }),
    [
      scale,
      isHighlighted,
      disabled,
      isRequired,
      hasValue,
      ...(isFullName ? [defaultValue] : isRadioGroup ? [defaultChecked] : []),
      ...(pdfEditorView ? [editorField] : []),
    ],
  );

  const getInputFieldProps = useCallback(
    () => ({
      ...getFieldProps(),
      disabled,
    }),
    [
      disabled,
      isRequired,
      hasValue,
      ...(pdfEditorView ? [editorField] : []),
      ...(isRadioGroup ? [defaultChecked, responses, isDisabledGroup] : []),
    ],
  );

  return (
    <Fragment>
      {isRadioGroup ? (
        <RadioField
          fieldProps={getProps()}
          inputFieldProps={getInputFieldProps()}
          itemID={`question-${question.UUID}`}
          fieldStyle={{
            width: width * scale,
            height: height * scale,
            left: x * scale,
            top: y * scale,
            border: 'none',
            borderRadius: '6px',
          }}
          inputFieldStyle={{
            accentColor: color.border,
            '--border-color': color.border,
          }}
          isDisabledGroup={isDisabledGroup}
          validation={question.QuestionValidation}
        />
      ) : (
        <InfoTooltip
          text={isRequired ? <div className={styles.requiredPopover}>Required</div> : ''}
          tooltipOpen={isRequired}
          className={styles.textFieldTooltip}
        >
          {isPhone ? (
            <TextFitField
              onClick={handleFieldClick}
              itemID={`question-${question.UUID}`}
              textFitType="phoneNumber"
              {...getProps()}
              fieldProps={{ className: styles.phoneNumberField }}
              fieldInitialFontSize={fieldInitialFontSize}
            />
          ) : isNumber || isCurrency ? (
            <TextFitField
              textFitType="numberInput"
              itemID={`question-${question.UUID}`}
              defaultValue={defaultValue}
              onClick={handleFieldClick}
              fieldProps={{
                requiredClass,
                isCurrency,
                className: styles.numberField,
                prefixClass: styles.currencyPrefix,
              }}
              {...getProps()}
              fieldInitialFontSize={fieldInitialFontSize}
            />
          ) : isDate ? (
            <div
              {...getProps()}
              className={`${getProps().className} ${styles.dateBoxContainer}`}
              itemID={`question-${question.UUID}`}
              onBlur={(event) =>
                getProps?.().onBlur &&
                getProps?.().onBlur?.(
                  event,
                  getDateFontSize(getProps().style.width, fieldInitialFontSize),
                )
              }
            >
              <DatePicker
                disabled={disabled}
                format={'MM/DD/YYYY'}
                placeholder={width > 70 ? 'Date' : ''}
                value={date ? moment(date) : null}
                onClick={handleFieldClick}
                onChange={onUpdateDateField}
                className={styles.formDatePicker}
                style={{
                  background: !isHighlighted && !pdfEditorView ? color.background : 'unset',
                  fontSize: `${getDateFontSize(getProps().style.width, fieldInitialFontSize)}px`,
                }}
                popupClassName={classNames('mosaikCalendar', styles.formDatePickerPopup)}
              />
            </div>
          ) : isAddress || isFullName ? (
            <TextFitField
              onClick={handleFieldClick}
              itemID={`question-${question.UUID}`}
              isFullName={isFullName}
              isAddress={isAddress}
              fieldInitialFontSize={fieldInitialFontSize}
              {...getProps()}
              isSmallField={height < 20}
              pdfEditorView={pdfEditorView}
            />
          ) : isCheckbox ? (
            <CheckboxField
              onClick={handleFieldClick}
              fieldProps={getProps()}
              inputFieldProps={getInputFieldProps()}
              itemID={`question-${question.UUID}`}
              fieldStyle={{
                width: width * scale,
                height: height * scale,
                left: x * scale,
                top: y * scale,
                borderColor: color.border,
              }}
            />
          ) : (
            <TextBox
              inputProps={getProps()}
              fieldProps={getInputFieldProps()}
              questionUUID={question.UUID}
              onClick={handleFieldClick}
              height={height}
              isPDFEditorView={pdfEditorView}
            />
          )}
        </InfoTooltip>
      )}
    </Fragment>
  );
};
