/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input, InputLabel } from 'components';

import styles from './styles.module.scss';

const MinMax = (props) => {
  const { className, variant, valueMin, valueMax, nameMin, nameMax, error } = props;
  const {
    onChangeMin,
    onChangeMax,
    disabled,
    prefix,
    suffix,
    inputHolderClassName,
    numberInputClassName,
    icon,
  } = props;
  const { placeholderMin, placeholderMax, label, thousandSeparator, separatorClassName } = props;
  const { testidMin, testidMax } = props;

  return (
    <div className={classNames(styles.wrapper, className)}>
      {label && <InputLabel label={label} className={styles.inputLabel} />}
      <div className={classNames(styles.horizontalOptions)}>
        <Input
          isNumericString
          isNumberFormat
          thousandSeparator={thousandSeparator}
          prefix={prefix}
          suffix={suffix}
          name={nameMin}
          type="number"
          placeholder={placeholderMin}
          variant={variant}
          value={valueMin}
          onChange={onChangeMin}
          disabled={disabled}
          className={styles.selectWrapper}
          testid={testidMin}
          inputHolderClassName={inputHolderClassName}
          numberInputClassName={numberInputClassName}
          icon={icon}
          error={error}
        />
        <span
          className={classNames(styles.separator, { [styles.error]: error }, separatorClassName)}
        >
          to
        </span>
        <Input
          isNumericString
          isNumberFormat
          thousandSeparator={thousandSeparator}
          prefix={prefix}
          suffix={suffix}
          name={nameMax}
          type="number"
          placeholder={placeholderMax}
          variant={variant}
          value={valueMax}
          onChange={onChangeMax}
          disabled={disabled}
          className={styles.selectWrapper}
          testid={testidMax}
          inputHolderClassName={inputHolderClassName}
          numberInputClassName={numberInputClassName}
          icon={icon}
          error={error}
        />
      </div>
    </div>
  );
};

MinMax.propTypes = {
  className: PropTypes.string,
  separatorClassName: PropTypes.string,
  inputHolderClassName: PropTypes.string,
  variant: PropTypes.string,
  valueMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameMin: PropTypes.string,
  nameMax: PropTypes.string,
  onChangeMin: PropTypes.func,
  onChangeMax: PropTypes.func,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  placeholderMin: PropTypes.string,
  placeholderMax: PropTypes.string,
  label: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  testidMin: PropTypes.string,
  testidMax: PropTypes.string,
  numberInputClassName: PropTypes.string,
};

MinMax.defaultProps = {
  className: '',
  separatorClassName: '',
  inputHolderClassName: '',
  variant: Input.LIGHT_FULL,
  valueMin: '',
  valueMax: '',
  nameMin: 'min',
  nameMax: 'max',
  onChangeMin: () => {},
  onChangeMax: () => {},
  disabled: false,
  prefix: '',
  suffix: '',
  placeholderMin: '',
  placeholderMax: '',
  label: '',
  thousandSeparator: true,
  testidMin: undefined,
  testidMax: undefined,
  numberInputClassName: '',
};

export default MinMax;
