import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { Link } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import styles from './styles.module.scss';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';

const SelectedPartners = (props) => {
  const { className, categoryId } = props;
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const dispatch = useDispatch();

  const getPartnerIds = () => {
    const partnersIds = (multiple?.partners || []).map((partner) => partner?.Id);
    return partnersIds.join(',');
  };

  const handleCancel = () => {
    dispatch(setMultipleRequestQuotesEffect({ partners: [] }));
    dispatch(setMultipleRequestQuotesEffect({ modeEnable: false }));
  };

  return (
    <div
      className={classNames(
        styles.selectedPartners,
        { [styles.active]: !!multiple?.modeEnable },
        className,
      )}
    >
      <div className={styles.cancel} onClick={handleCancel}>
        Cancel
      </div>
      <Button
        component={Link}
        to={link.toServicesCategoryPartnerQuote({ categoryId, partnerId: getPartnerIds() })}
        className={classNames(
          styles.continueBtn,
          multiple?.partners?.length ? '' : styles.btnHidden,
        )}
        title="Continue"
        testid="continue_button"
        titleClassName={styles.title}
      />
    </div>
  );
};

SelectedPartners.propTypes = {
  className: PropTypes.string,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  categoryName: PropTypes.string,
};

SelectedPartners.defaultProps = {
  className: '',
  categoryName: '',
};

export default SelectedPartners;
