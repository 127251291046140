import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Search = memo(({ className }) =>
  useMemo(
    () => (
      <div className={classNames(className)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
            stroke="#747475"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.9987 18.9997L14.332 14.333"
            stroke="#747475"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [className],
  ),
);

Search.propTypes = {
  className: PropTypes.any,
};

Search.defaultProps = {
  className: '',
};

export default Search;
