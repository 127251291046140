import { ArrowRight, Email } from 'components/Icons';
import styles from './styles.module.scss';
import IdeaBulb from 'components/Icons/IdeaBulb';
import { AddNewVendorStages } from '../../constants';

type PriorToInvitationPropsType = {
  setCurrentStage: Function;
};

const PriorToInvitation = (props: PriorToInvitationPropsType) => {
  const { setCurrentStage } = props;

  return (
    <div className={styles.priorToInvitation}>
      <div className={styles.iconBox}>
        <div className={styles.icon}>
          <IdeaBulb color="#51BFE1" />
        </div>
      </div>
      <div className={styles.heading}>Prior to sending this invitation...</div>
      <div className={styles.note}>
        Please notify the vendor via email or phone that you will be inviting them to{' '}
        <span className={styles.bold}>Mosaik</span>. This is a best practice that will help ensure
        vendor activation and engagement. If you have any questions in this regard, please feel free
        to contact
        <span className={styles.link}> support@mosaik.io</span>.
      </div>
      <div className={styles.footer}>
        <div
          className={styles.button}
          onClick={() => {
            setCurrentStage(AddNewVendorStages.EmailTemplate);
          }}
        >
          <div className={styles.iconLeft}>
            <Email color="#51BFE1" />
          </div>
          <div className={styles.text}>See email template</div>
          <ArrowRight className={styles.iconRight} />
        </div>
      </div>
    </div>
  );
};

export default PriorToInvitation;
