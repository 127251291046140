import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { getCtaData } from 'api/kits';
import { PageNotFound, Spinner } from 'components';
import { routes } from 'settings/navigation/routes';
import classNames from 'classnames';
import { validate as uuidValidate } from 'uuid';
import { Link, useParams } from 'react-router-dom';
import { Footer } from 'components-antd/Footer/Footer';
import { formatPhoneNumber } from 'helpers';
import { useSelector } from 'react-redux';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { useHistory } from 'react-router-dom';
import { Header } from 'antd/lib/layout/layout';
import { HeaderLogo } from 'components-antd';

type KitCtaParamType = {
  uuid: string;
};

function ClientKitCta() {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [client, setClient] = useState<any>(null);
  const [agent, setAgent] = useState<any>(null);
  const [threadId, setThreadId] = useState<string>('');
  const { uuid } = useParams<KitCtaParamType>();
  const messageRedirectUrl = `${routes.clientDashboard}?drawer=message`;
  const { isClient } = useSelector(getUserRolesMapSelector);
  const userId = useSelector(getUserId);
  const history = useHistory();

  const fetchCtaData = async () => {
    try {
      const response = await getCtaData(uuid);
      if (response.status === 200) {
        const { Agent, Client, Kits, ThreadId, MessageId } = response.data.result;
        setClient(Client);
        setAgent(Agent);
        if (ThreadId && MessageId) {
          setThreadId(ThreadId);
          // show acknowledgement message
        } else {
          history.push('/');
        }
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId && !isClient) history.push('/');
    if (!uuidValidate(uuid)) setError(true);
  }, [userId, isClient, uuid]);

  useEffect(() => {
    if (loading) fetchCtaData();
  }, [loading]);

  const getPrimaryPhoneNumber = () => {
    const primary = agent?.Phones?.find((phone) => phone.IsPrimary);
    if (primary) return formatPhoneNumber(primary.PhoneNumber);
    return '';
  };

  const agentFullName = useMemo(() => {
    if (!agent) return '';
    return `${agent.FirstName || ''} ${agent.LastName || ''}`.trim();
  }, [agent]);

  if (loading) {
    return <Spinner loaderClassName={classNames(styles.loader)} />;
  } else if (error) {
    return (
      <div>
        <PageNotFound backUrl={routes.dashboard} />
      </div>
    );
  } else
    return (
      <>
        <Header className={classNames(styles.ctaPageHeader, 'prevent-user-select')}>
          <div className={styles.headerContainer}>
            <HeaderLogo absoluteHeader={false} />
            <Link to={`${routes.login}threadId=${threadId}`}>
              <button className={classNames(styles.loginButton)} title="Sign in" testid="login">
                Login
              </button>
            </Link>
          </div>
        </Header>
        <div className={styles.cta}>
          <div className={styles.ctaContent}>
            <div className={styles.ctaContentArea}>
              <div className={styles.ctaContentAreaInner}>
                <div>
                  <p className={classNames(styles.text, styles.marginShort)}>
                    Hi {client?.User?.FirstName || ''},
                  </p>
                  <p className={classNames(styles.text)}>
                    I got your note and will follow-up with you shortly.
                  </p>
                  <p className={classNames(styles.text, styles.marginShort)}>{agentFullName}</p>
                  <p className={classNames(styles.contact, styles.marginShort)}>
                    {getPrimaryPhoneNumber()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Footer className={styles.ctaFooter} />
          </div>
        </div>
      </>
    );
}

export default ClientKitCta;
