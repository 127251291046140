export const getInitials = (name?: string): string => {
  if (!name) return '';
  const words = name.split(' ').filter((word) => word.length > 0);
  const initials = words
    .slice(0, 2)
    .map((word) => word[0])
    .join('');
  return initials.toUpperCase();
};

export const removeSpaces = (str) => {
  let trimmedStr = str.trim();

  let finalStr = trimmedStr.replace(/\s+/g, '');

  return finalStr;
};

export const getDistancebetweenTwoLocations = (
  locationLat,
  locationLong,
  locationToBeComparedLat,
  locationToBeComparedLong,
) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 3959; // miles
  const dLat = toRad(locationToBeComparedLat - locationLat);
  const dLon = toRad(locationToBeComparedLong - locationLong);
  const lat1 = toRad(locationLat);
  const lat2 = toRad(locationToBeComparedLat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};
