export const splitFirstOccurrence = (
  str: string,
  separator: string,
): { first: string; remainder: string } => {
  const [first, ...rest] = (str || '').split(separator);
  const remainder = rest.join(' ');
  return { first, remainder };
};

export const hasTimePassed = (date: Date, minutes: number = 7) => {
  if (!date) return false;
  const currentTime = new Date();
  const thresholdTime = new Date(date.getTime() + minutes * 60000);
  return currentTime > thresholdTime;
};

export const getFirstNameLastInitial = (name?: string) => {
  const { first, remainder } = splitFirstOccurrence(name || '', ' ');
  return `${first}${remainder ? ` ${remainder[0]}` : ''}`;
};

export const sortNamesAlphabetically = (names: string[]) =>
  names.slice().sort((a, b) => a.localeCompare(b));

export const getShowingIdAndTypeFromUrl = () => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const showingId = queryParams.get('showing');
  const type = queryParams.get('type');
  return { showingId, type };
};

export const formatPhoneNumber = (phone: string): string | undefined => {
  if (!phone) return undefined;

  const digits = phone.replace(/\D/g, '');

  if (digits.length !== 10) return undefined;

  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
};
