import { ReactNode, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Wrapper, Input, Button } from 'components';
import { Tabs } from 'components-antd';
import { TabContent, EmptyTemplates } from './components';
import { Plus } from 'components/Icons';
import { routes } from 'settings/navigation/routes';
import { getEmailTemplates } from 'api/templates/email';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

enum TabKeys {
  MY_TEMPLATES = 'My Templates',
  TEAM_TEMPLATES = 'Team Templates',
}

export const EmailTemplates = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState<string>(TabKeys.MY_TEMPLATES);
  const [searchString, setSearchString] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [myEmailTemplates, setMyEmailTemplates] = useState([]);
  const [teamEmailTemplates, setTeamEmailTemplates] = useState([]);

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  useEffect(() => {
    setActiveKey(myEmailTemplates?.length ? TabKeys.MY_TEMPLATES : TabKeys.TEAM_TEMPLATES);
  }, [myEmailTemplates, teamEmailTemplates]);

  const fetchEmailTemplates = async () => {
    setLoading(true);
    try {
      const resp = await getEmailTemplates();
      const emailTemplates = resp?.data?.value;
      setMyEmailTemplates(emailTemplates?.filter((item) => !item?.PublishToTeam));
      setTeamEmailTemplates(emailTemplates?.filter((item) => item?.PublishToTeam));
    } catch (e) {
      showErrorMessage(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const tabItems: {
    key: string;
    label: string;
    children: ReactNode;
  }[] = [
    ...(myEmailTemplates?.length
      ? [
          {
            key: TabKeys.MY_TEMPLATES,
            label: TabKeys.MY_TEMPLATES,
            children: (
              <TabContent
                searchString={searchString}
                emailTemplates={myEmailTemplates}
                refetchEmailTemplates={fetchEmailTemplates}
              />
            ),
          },
        ]
      : []),
    ...(teamEmailTemplates?.length
      ? [
          {
            key: TabKeys.TEAM_TEMPLATES,
            label: TabKeys.TEAM_TEMPLATES,
            children: (
              <TabContent
                searchString={searchString}
                emailTemplates={teamEmailTemplates}
                refetchEmailTemplates={fetchEmailTemplates}
              />
            ),
          },
        ]
      : []),
  ];

  const isSingleTab = useMemo(() => {
    if (teamEmailTemplates?.length && !myEmailTemplates?.length) return true;
    if (!teamEmailTemplates?.length && myEmailTemplates?.length) return true;

    return false;
  }, [teamEmailTemplates, myEmailTemplates]);

  return (
    <Wrapper isPending={loading}>
      <div className={styles.emailTemplateHeader}>
        <h2 className={styles.headerText}>Email Templates</h2>
        <div className={styles.buttonsContainer}>
          <Input
            variant={Input.LIGHT_ROUNDED}
            type="search"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            className={styles.searchInput}
          />
          <Button
            title="New"
            iconLeft={<Plus color="#fff" className={styles.plusIcon} />}
            titleClassName={styles.btnTitle}
            onClick={() => history.push(routes.templatesEmailNew)}
          />
        </div>
      </div>
      <div className={styles.emailTemplateTabs}>
        {myEmailTemplates?.length || teamEmailTemplates?.length ? (
          <Tabs
            activeKey={activeKey}
            size="middle"
            items={tabItems}
            className={classNames(styles.emailTemplateTabs, { [styles.singleTab]: isSingleTab })}
            onChange={(tab) => setActiveKey(tab)}
          />
        ) : (
          <EmptyTemplates />
        )}
      </div>
    </Wrapper>
  );
};
