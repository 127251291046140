import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Modal, Row, Col } from 'components-antd';
import { Wrapper as PendingWrapper, TruncatedContent, GenerateHtml } from 'components';
import {
  Header,
  TaskSectionContainer,
  TaskDueDate,
  RightSectionTabs,
  AssignInfo,
} from './components';
import { Actions, Links } from '../View/components';
import { Icons } from './Icons';

import { tasksStatusesIds } from 'settings/constants/transactionTasks';

import {
  getTransactionTaskAccessSelector,
  getTransactionTaskParamsSelector,
  getTransactionTaskSelector,
} from 'store/selectors/transactionTask';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getUserId } from 'store/selectors/user';
import { OpenBook } from 'components/Icons';
import { EmailTemplateModal } from '../../TaskModalForm/EmailTemplateModal';

import styles from './styles.module.scss';

export const ViewRevamped = ({ isOpen, onCloseModal, className }) => {
  const { isPending, task, isError, status } = useSelector(getTransactionTaskSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess: taskFullAccess } = useSelector(getTransactionTaskAccessSelector);
  const isNotFound = isError && status && status === 404;
  const userId = useSelector(getUserId);
  const { campaignId } = useSelector(getTransactionTaskParamsSelector);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [showDescriptionView, setShowDescriptionView] = useState(false);
  const [viewEmailTemplate, setViewEmailTemplate] = useState(false);
  const [editId, setEditId] = useState(null);

  const campaignTaskFullAccess = useMemo(
    () => campaignId && taskFullAccess,
    [taskFullAccess, campaignId],
  );

  useEffect(() => {
    if (isOpen) {
      const unreadCount = task?.Comments?.reduce((acc, comment) => {
        if (comment.SenderUserId !== userId) {
          return comment.MessageReceipts.some(
            (receipt) => receipt?.UserId === userId && !receipt.IsRead,
          )
            ? acc + 1
            : acc;
        }
        return acc;
      }, 0);
      setUnreadMessagesCount(unreadCount);
    } else {
      setUnreadMessagesCount(0);
    }
    setShowDescriptionView(false);
    setEditId(null);
  }, [task?.Id, isOpen]);

  // Sections disable boolean on editting Due Date/Assignee List
  const isDisableSection = useMemo(() => editId !== null, [editId]);

  return (
    <Modal
      open={isOpen}
      width={showDescriptionView ? 675 : 1000}
      footer={null}
      onCancel={() => {
        onCloseModal();
      }}
      maskClosable={false}
      destroyOnClose
      className={classNames(styles.taskViewModal, {
        [styles.descriptionTaskModal]: showDescriptionView,
      })}
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backdropFilter: 'blur(5px)',
      }}
    >
      <div testid="task_view" className={classNames(styles.view, className)}>
        <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
          {isNotFound ? (
            <div className={styles.notFoundMessage}>The task has already been deleted</div>
          ) : (
            <div className={styles.content}>
              <Header
                taskTransactionId={task?.TransactionId}
                title={task?.Title}
                transaction={transaction}
                categories={task?.Categories?.map((item) => item?.Name)}
                showDescriptionView={showDescriptionView}
                hideDescriptionView={() => setShowDescriptionView(false)}
              />
              {showDescriptionView ? (
                <div className={styles.descriptionView}>
                  <GenerateHtml content={task?.Description} />
                </div>
              ) : (
                <>
                  <Actions
                    transactionId={task?.TransactionId}
                    dueDate={task?.DueDate}
                    fullAccess={campaignTaskFullAccess}
                    className={styles.taskActions}
                    optionDotsClassName={styles.optionDotsClassName}
                  />
                  <Row gutter={24} className={styles.sectionsRow}>
                    <Col lg={12} className={styles.leftSection}>
                      <TaskDueDate
                        task={task}
                        disabled={isDisableSection && editId !== 'task-due-date'}
                        setEditId={setEditId}
                      />
                      <TaskSectionContainer disabled={isDisableSection && editId !== 'assign-info'}>
                        <AssignInfo
                          task={task}
                          disabled={isDisableSection && editId !== 'assign-info'}
                          setEditId={setEditId}
                        />
                      </TaskSectionContainer>
                      <TaskSectionContainer
                        title="Description"
                        icon={<Icons variant={Icons.DESCRIPTION} />}
                        hideSection={!task?.Description}
                        disabled={isDisableSection}
                      >
                        <TruncatedContent
                          content={<GenerateHtml content={task?.Description} />}
                          showMoreText="Read All"
                          showMoreOnClick={() => setShowDescriptionView(true)}
                          className={styles.maxContentWidth}
                        />
                      </TaskSectionContainer>
                      {task?.EmailTemplate ? (
                        <TaskSectionContainer
                          title="Email Template"
                          align="center"
                          icon={<Icons variant={Icons.EMAIL_TEMPLATE} />}
                          disabled={isDisableSection}
                        >
                          <div className={styles.containerEmailTemplate}>
                            <p className={styles.text}>{task?.EmailTemplate?.Subject}</p>
                            <OpenBook
                              className={styles.iconEmailTemplate}
                              onClick={() => !isDisableSection && setViewEmailTemplate(true)}
                            />
                          </div>
                        </TaskSectionContainer>
                      ) : (
                        <></>
                      )}
                      <TaskSectionContainer
                        title="Links"
                        icon={<Icons variant={Icons.LINKS} />}
                        hideSection={!task?.Links?.length}
                        disabled={isDisableSection}
                      >
                        <Links value={task?.Links} className={styles.taskLinks} />
                      </TaskSectionContainer>
                    </Col>
                    <Col lg={12}>
                      <RightSectionTabs
                        task={task}
                        campaignTaskFullAccess={campaignTaskFullAccess}
                        disabled={isDisableSection}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
        </PendingWrapper>
      </div>
      <EmailTemplateModal
        open={viewEmailTemplate}
        onClose={() => setViewEmailTemplate(false)}
        subjectText={task?.EmailTemplate?.Subject}
        bodyText={task?.EmailTemplate?.Body}
      />
    </Modal>
  );
};
