import { Email } from 'components/Icons';

import styles from './styles.module.scss';

export const EmptyTemplates = () => {
  return (
    <div className={styles.emptyTemplatesContainer}>
      <Email />
      <p>You don&apos;t have any templates</p>
    </div>
  );
};
