/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Wrapper as PendingWrapper } from 'components';

import { getCassAddressData, getLocationData, getLocationState } from 'helpers/locations';
import { useOutsideClick } from 'hooks';
import Label from './Label';
import Wrapper from './Wrapper';
import { MapDraw } from 'components/Icons/MapDraw';
import Values from './Values';
import GeoCoderComponent from './Geocoder';
import { Geotag } from 'components/Icons';
import styles from './styles.module.scss';

import { CASS_ADDRESS } from 'settings/constants/locations';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CustomLocationModal from './CustomLocationModal';
import { LocationType } from 'types';
import Spinner from 'components/Spinner';

const Locations = (props) => {
  const {
    testid,
    name,
    className,
    language,
    error,
    label,
    labelClassName,
    shouldFilterStateLevelResults,
    shouldFilterPlaces,
    shouldFilterCityLevelResults,
    shouldFilterStreetLevelResults,
    showOnlyCassAddresses,
    setFullCassAddress,
    showOnlyCityAndState,
    customLoading,
    showDrawMarkers,
  } = props;
  const {
    blockClassName,
    errorClassName,
    altLabel,
    altLabelClassName,
    dropdownClassName,
    searchIcon,
    showSuffix,
    suffixClassName,
  } = props;
  const {
    onResult,
    onChange,
    placeholder,
    placeholderIcon,
    placeholderClassName,
    variant,
    showPrefixIcon,
    value,
    trackProximity,
    disabled,
    updateInput,
    resetUpdate,
    rounded = false,
  } = props;
  const {
    countries,
    types,
    multiple,
    showMultipleText,
    valuesWrapperClassName,
    popupClassName,
    activeInputClassName,
    activeInputIconClassName,
    valueClassName,
    valuesContainerClassName,
    enableDraw,
    multipleDraw,
    customLocationFlag,
    allowedSmartyAddress,
    loaderClassName,
    showOnlyStateSuggestions,
    customPlaceName,
  } = props;
  const {
    searchWrapperClassName,
    searchIconClassName,
    menuTop,
    allowStates,
    replacePlaceholderWithLabelWhenHasValue,
    getStates,
    showAsterisk,
    fieldTouched,
    displayedValuesLimit,
  } = props;

  const polygonLocationsFiltered = value?.filter((loc) => loc?.Type === LocationType.Polygon) ?? [];
  const [polygonLocations, setPolygonLocations] = useState(polygonLocationsFiltered);
  const locationSelector = polygonLocationsFiltered?.length > 0;
  const valuesWrapperRef = useRef();
  const geoCoderRef = useRef();
  const [focus, setFocus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useOutsideClick([valuesWrapperRef, geoCoderRef], () => {
    setFocus(false);
    handleFieldTouch();
  });

  const onResultHandler = useCallback(
    (result) => {
      const preparedData =
        result.type === CASS_ADDRESS
          ? setFullCassAddress
            ? result?.result
            : getCassAddressData(result)
          : getLocationData(result, allowStates);
      if (!preparedData) return null;

      if (getStates) {
        if (result.type === CASS_ADDRESS) {
          if (!setFullCassAddress) preparedData.locationState = result?.coordinates;
        } else {
          const locationState = getLocationState(result);
          if (locationState) preparedData.locationState = locationState;
        }
      }

      const { place_id: ProviderPlaceId } = result?.result?.[0] || {};

      if (multiple) {
        const uniquePlaces = value.filter((place) => place?.ProviderPlaceId !== ProviderPlaceId);
        const newValue = [...uniquePlaces, preparedData];
        onResult(result, newValue);
      } else {
        onResult(result, [preparedData]);
      }
    },
    [value],
  ); // eslint-disable-line

  const handleFieldTouch = () => {
    if (fieldTouched) {
      fieldTouched();
    }
  };

  const onDeleteHandler = (itemId, isPolygon) => {
    const newValues = value.filter(({ ProviderPlaceId, Type }) =>
      isPolygon ? Type !== LocationType.Polygon : ProviderPlaceId !== itemId,
    );
    if (isPolygon) setPolygonLocations([]);
    onResult({ deleteItemId: itemId }, newValues);
  };

  const setModalOpen = () => {
    setIsModalOpen(true);
  };

  const hasLabel =
    (label && !replacePlaceholderWithLabelWhenHasValue) ||
    ((label || placeholder) && replacePlaceholderWithLabelWhenHasValue && !!value);

  return (
    <>
      {isModalOpen && (
        <CustomLocationModal
          showDrawMarkers={showDrawMarkers}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          onResult={(polygonLocations) => {
            onResult(polygonLocations, polygonLocations);
          }}
          multipleDraw={multipleDraw}
          polygonLocations={polygonLocations}
          setPolygonLocations={setPolygonLocations}
        />
      )}
      <div className={styles.customLocationToggleContainer}>
        <div className={styles.title}>
          {hasLabel && (
            <Label
              label={label || placeholder}
              className={classNames(labelClassName)}
              altLabel={altLabel}
              altLabelClassName={altLabelClassName}
              showAsterisk={showAsterisk}
            />
          )}
        </div>
      </div>
      <div className={styles.container}>
        <Wrapper
          disabled={disabled}
          className={classNames(styles.searchWrapper, className, styles[variant])}
        >
          <div className={classNames(styles.block, blockClassName)}>
            {focus && !locationSelector ? (
              <GeoCoderComponent
                shouldFilterPlaces={shouldFilterPlaces}
                shouldFilterStateLevelResults={shouldFilterStateLevelResults}
                shouldFilterCityLevelResults={shouldFilterCityLevelResults}
                shouldFilterStreetLevelResults={shouldFilterStreetLevelResults}
                inputClassName={activeInputClassName}
                inputIconClassName={activeInputIconClassName}
                ref={geoCoderRef}
                focus={focus}
                name={name}
                onResult={onResultHandler}
                language={language}
                placeholder={placeholder}
                trackProximity={trackProximity}
                countries={countries}
                value={value}
                types={types}
                onChange={onChange}
                variant={variant}
                showPrefixIcon={showPrefixIcon}
                values={value}
                className={popupClassName}
                dropdownClassName={dropdownClassName}
                searchWrapperClassName={classNames(styles[variant], searchWrapperClassName)}
                currentValue={value}
                onDeleteValue={onDeleteHandler}
                menuTop={menuTop}
                multiple={multiple}
                allowedSmartyAddress={allowedSmartyAddress}
                loaderClassName={loaderClassName}
                showOnlyStateSuggestions={showOnlyStateSuggestions}
                showOnlyCassAddresses={showOnlyCassAddresses}
                setFullCassAddress={setFullCassAddress}
                showOnlyCityAndState={showOnlyCityAndState}
                customLoading={customLoading}
              />
            ) : null}

            <div
              ref={valuesWrapperRef}
              onClick={() => setFocus(true)}
              className={classNames(
                styles.valuesWrapper,
                styles[variant],
                { [styles.focus]: focus },
                { [styles.hasIcon]: searchIcon },
                { [styles.rounded]: rounded && !enableDraw },
                { [styles.enableDraw]: enableDraw },
                { [styles.hasSuffix]: showSuffix },
                valuesWrapperClassName,
              )}
              testid={testid}
            >
              {searchIcon && (
                <Geotag
                  className={classNames(styles.geoTagIcon, searchIconClassName)}
                  color="#747475"
                />
              )}
              {!value?.length ? (
                <>
                  {placeholderIcon}
                  <div
                    testid="placeholder"
                    className={classNames(
                      styles.placeholder,
                      { [styles.hasIcon]: searchIcon },
                      placeholderClassName,
                    )}
                  >
                    {locationSelector ? 'Add custom locations' : placeholder}
                    <PendingWrapper
                      loaderClassName={classNames(styles.loader)}
                      isPending={customLoading}
                    />
                  </div>
                </>
              ) : null}
              {showSuffix && (
                <div
                  className={classNames(styles.suffixIcon, suffixClassName)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFocus(false);
                  }}
                >
                  <Button icon={<SearchOutlined />} type="primary" danger htmlType="submit" />
                </div>
              )}
              <Values
                className={classNames(
                  { [styles.valuesHasIcon]: searchIcon },
                  styles[variant],
                  valuesContainerClassName,
                )}
                valueClassName={valueClassName}
                showMultipleText={showMultipleText}
                multiple={multiple}
                values={value}
                onDelete={onDeleteHandler}
                locationSelector={locationSelector}
                customPlaceName={customPlaceName}
                displayedValuesLimit={displayedValuesLimit}
                customLoading={customLoading}
              />
            </div>
          </div>
          {error && (
            <div testid="validation" className={classNames(styles.error, errorClassName)}>
              {error}
            </div>
          )}
        </Wrapper>
        <div>
          {enableDraw && (
            <button
              type="button"
              className={classNames(styles.toggleButton, { [styles.active]: locationSelector })}
              onClick={() => {
                // onSearchClick();
                setModalOpen();
              }}
            >
              <MapDraw width={20} height={20} color={locationSelector ? '#FF576D' : '#515151'} />
              <p className={styles.draw}>Draw</p>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

Locations.LIGHT = 'light';
Locations.LIGHT_FULL = 'lightFull';
Locations.FULL = 'full';
Locations.ROUND = 'round';
Locations.SQUARE = 'square';

Locations.propTypes = {
  rounded: PropTypes.bool,
  name: PropTypes.string,
  searchIcon: PropTypes.bool,
  allowStates: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  blockClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  valuesWrapperClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  popupClassName: PropTypes.string,
  activeInputClassName: PropTypes.string,
  activeInputIconClassName: PropTypes.string,
  language: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  altLabel: PropTypes.string,
  altLabelClassName: PropTypes.string,
  onResult: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderIcon: PropTypes.element,
  variant: PropTypes.string,
  showPrefixIcon: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      ProviderPlaceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      PlaceName: PropTypes.string,
    }),
  ),
  trackProximity: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  countries: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  showMultipleText: PropTypes.bool,
  replacePlaceholderWithLabelWhenHasValue: PropTypes.bool,
  searchWrapperClassName: PropTypes.string,
  searchIconClassName: PropTypes.string,
  menuTop: PropTypes.bool,
  getStates: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  isViewMode: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  valuesContainerClassName: PropTypes.string,
  customLocationFlag: PropTypes.bool,
  showSuffix: PropTypes.bool,
  allowedSmartyAddress: PropTypes.bool,
  suffixClassName: PropTypes.string,
  shouldFilterStateLevelResults: PropTypes.bool,
  shouldFilterPlaces: PropTypes.bool,
  shouldFilterCityLevelResults: PropTypes.bool,
  shouldFilterStreetLevelResults: PropTypes.bool,
  showOnlyStateSuggestions: PropTypes.bool,
  enableDraw: PropTypes.bool,
  multipleDraw: PropTypes.bool,
  showOnlyCassAddresses: PropTypes.bool,
  showOnlyCityAndState: PropTypes.bool,
  setFullCassAddress: PropTypes.bool,
  customPlaceName: PropTypes.any,
  displayedValuesLimit: PropTypes.number,
  customLoading: PropTypes.bool,
  showDrawMarkers: PropTypes.bool,
};

Locations.defaultProps = {
  testid: undefined,
  searchIcon: true,
  allowStates: false,
  name: undefined,
  enableDraw: false,
  multipleDraw: false,
  setFullCassAddress: false,
  className: '',
  labelClassName: '',
  blockClassName: '',
  errorClassName: '',
  valueClassName: '',
  activeInputClassName: '',
  activeInputIconClassName: '',
  valuesWrapperClassName: '',
  dropdownClassName: '',
  popupClassName: '',
  language: undefined,
  error: undefined,
  label: '',
  altLabel: '',
  altLabelClassName: '',
  onResult: () => {},
  onChange: () => {},
  placeholder: 'Enter address or location name',
  variant: Locations.LIGHT,
  showPrefixIcon: false,
  value: [],
  trackProximity: true,
  disabled: false,
  countries: undefined,
  types: undefined,
  multiple: true,
  showMultipleText: false,
  replacePlaceholderWithLabelWhenHasValue: false,
  searchWrapperClassName: '',
  searchIconClassName: '',
  menuTop: false,
  getStates: false,
  placeholderIcon: <></>,
  placeholderClassName: undefined,
  isViewMode: false,
  showAsterisk: false,
  valuesContainerClassName: '',
  customLocationFlag: false,
  showSuffix: false,
  allowedSmartyAddress: false,
  loaderClassName: '',
  shouldFilterStateLevelResults: false,
  shouldFilterPlaces: false,
  shouldFilterCityLevelResults: false,
  shouldFilterStreetLevelResults: true,
  showOnlyStateSuggestions: false,
  showOnlyCassAddresses: false,
  showOnlyCityAndState: false,
  customPlaceName: null,
  displayedValuesLimit: 2,
  customLoading: false,
  showDrawMarkers: false,
};

export default Locations;
