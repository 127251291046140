import React from 'react';
import classNames from 'classnames';

type MinimizeIconProps = {
  className?: string;
  onClick?: () => void;
};

export const MinimizeIcon: React.FC<MinimizeIconProps> = ({ className, onClick }) => {
  return (
    <div className={classNames(className)} onClick={onClick}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#F4F6FB" />
        <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
};
