import { Checkbox, Col, Form, Input, Row, TextArea, useForm } from 'components-antd';
import styles from './style.module.scss';
import { ClauseFormFooter } from './ClausesFormFooter';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { extractClauseData, fieldRquiredRule } from './helper';
import { useEffect, useState } from 'react';
import { createClauseTemplateEffect, updateClauseTemplateEffect } from 'store/effects/templates';
import { showErrorMessage, showSuccessMessage } from 'helpers';

export const ClauseForm = (props) => {
  const { clauseData, viewMode = false, clausesTabKey = '' } = props;

  const [isSaving, setSaving] = useState(false);

  const [form] = useForm<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(agentRole);

  useEffect(() => {
    if (clauseData) {
      form.setFields(extractClauseData(clauseData));
    }
  }, []);

  const hasErrors = () => {
    return form.getFieldsError().some((item) => item.errors.length > 0);
  };

  const handleSave = () => {
    if (!hasErrors()) {
      setSaving(true);
      const fieldValues = form.getFieldsValue();
      const callback = (error) => {
        if (!error) {
          showSuccessMessage(`Clause Template ${clauseData ? 'updated' : 'created'} successfully`);
          handleExit();
        } else {
          setSaving(false);
        }
      };

      if (clauseData) {
        dispatch(updateClauseTemplateEffect(fieldValues, { id: clauseData.Id }, callback));
      } else {
        dispatch(createClauseTemplateEffect(fieldValues, {}, callback));
      }
    }
  };

  const handleExit = () => {
    history.push({ pathname: routes.templatesClause, state: { clausesTabKey } });
  };

  const onFieldChange = ({ target }, fieldName) => {
    if (target.value.trim().length) {
      form.setFields([
        {
          name: fieldName,
          errors: undefined,
        },
      ]);
    }
  };

  return (
    <div className={styles.clausesFormContainer}>
      <div className={styles.clauseFormHeading}>{!clauseData ? 'New Clause' : 'Details'}</div>

      <Form
        form={form}
        onFinish={handleSave}
        onReset={handleExit}
        onFinishFailed={showErrorMessage}
      >
        <Row className={styles.row}>
          <Col md={24} lg={5}>
            <div className={styles.label}>Name</div>
          </Col>

          <Col md={24} lg={19}>
            <Form.Item
              className={styles.formItem}
              name={'name'}
              validateTrigger={'onSubmit'}
              rules={[fieldRquiredRule]}
            >
              <Input
                size="large"
                className="mosaikFormInput"
                placeholder={'Add name...'}
                onChange={(e) => onFieldChange(e, 'name')}
                maxLength={255}
                disabled={viewMode}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col md={24} lg={5}>
            <div className={styles.label}>Text</div>
          </Col>

          <Col md={24} lg={19}>
            <Form.Item
              className={styles.formItem}
              name={'description'}
              validateTrigger={'onSubmit'}
              rules={[fieldRquiredRule]}
            >
              <TextArea
                autoSize
                size="large"
                className={`mosaikFormInput ${styles.clauseDescription}`}
                placeholder="Add text..."
                onChange={(e) => onFieldChange(e, 'description')}
                disabled={viewMode}
              />
            </Form.Item>
          </Col>
        </Row>

        {isAdminOrOwner && (
          <div className={styles.row}>
            <Form.Item className={styles.formItem} name="publishToTeam" valuePropName="checked">
              <Checkbox className={styles.checkbox} disabled={viewMode}>
                Publish To Team
              </Checkbox>
            </Form.Item>
          </div>
        )}

        <ClauseFormFooter isPending={isSaving} viewMode={viewMode} />
      </Form>
    </div>
  );
};
