import { createAction } from 'redux-actions';

export const requestGetPartnersListAction = createAction('REQUEST/GET_PARTNERS_LIST');

export const partnersSetSearchAction = createAction('PARTNERS/SET_SEARCH');
export const partnersClearSearchAction = createAction('PARTNERS/CLEAR_SEARCH');
export const partnersResetAction = createAction('PARTNERS/RESET');

export const partnersChangeRecommendPartnerAction = createAction(
  'PARTNERS/CHANGE_RECOMMEND_PARTNER_STATUS',
);

export const requestGetRecommendedPartnersByAgentListAction = createAction(
  'REQUEST/GET_RECOMMENDED_PARTNERS_BY_AGENT_LIST',
);
export const setRecommendedPartnersByAgentListAction = createAction(
  'REQUEST/SET_RECOMMENDED_PARTNERS_BY_AGENT_LIST',
);

export const toggleRequestQuoteBtnAction = createAction('TOGGLE/REQUEST_QUOTE_BUTTON');
export const setVendorEditModeId = createAction('VENDOR/EDIT_MODE_ID');
export const setVendorEditInviteMode = createAction('VENDOR/EDIT_INVITE_MODE');
export const setVendorEditStrictlyProfile = createAction('VENDOR/STRICTLY_PROFILE');
export const setVendorDraftEditModeId = createAction('VENDOR/DRAFT_EDIT_MODE_ID');
export const vendorArchiveAction = createAction('VENDOR/ARCHIVE');
export const vendorInviteAction = createAction('VENDOR/Invite');
export const vendorCreateAction = createAction('VENDOR/CREATE');
export const vendorDraftToInviteAction = createAction('VENDOR/DRAFT_TO_INVITE');
export const vendorDraftUpdateAction = createAction('VENDOR/DRAFT_UPDATED');
export const vendorProfileUpdateAction = createAction('VENDOR/PROFILE_Updated');
export const setPartnerDraftsAsRecommendations = createAction('PARTNER/DRAFTS_AS_RECOMMENDATIONS');
export const removeRecommendationById = createAction('PARTNER/REMOVE_RECOMMENDATION_BY_ID');
export const replaceInRecommendationListAction = createAction(
  'PARTNER/REPLACE_IN_RECOMMENDATION_LIST',
);
export const cancelRecommendationInviteById = createAction(
  'PARTNER/CANCEL_RECOMMENDATION_INVITE_BY_ID',
);
export const deletePartnerDraftsAsRecommendations = createAction(
  'DELETE/DRAFTS_AS_RECOMMENDATIONS',
);
