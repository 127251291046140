import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';

import styles from './styles.module.scss';

const TitleValueTable = ({
  items,
  className,
  itemsClassName,
  itemPropertyClassname,
  itemValueClassname,
  last = false,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {map(items, ({ title, value }) => (
        <div
          testid="row_item"
          className={classNames(styles.item, itemsClassName, {
            [styles.last]: last,
          })}
          key={title + value}
        >
          <div testid="row_title" className={classNames(styles.bold, itemPropertyClassname)}>
            {title}
          </div>
          <div testid="row_value" className={classNames(styles.value, itemValueClassname)}>
            {Array.isArray(value)
              ? map(value, (val, index) => <div key={index}>{val}</div>)
              : value}
          </div>
        </div>
      ))}
    </div>
  );
};

TitleValueTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    }),
  ).isRequired,
  itemsClassName: PropTypes.string,
  itemPropertyClassname: PropTypes.string,
  itemValueClassname: PropTypes.string,
};

TitleValueTable.defaultProps = {
  className: '',
  itemsClassName: '',
  itemPropertyClassname: '',
  itemValueClassname: '',
};

export default TitleValueTable;
