import styles from './styles.module.scss';
import { Locations } from 'components';
import { v4 as uuid } from 'uuid';
import ServiceAreaActions from '../ServiceAreaActions';
import { AddVendorPayloadType } from 'pages/ServicesCategory/components/AddVendorModal/constants';
import classNames from 'classnames';
import { useRef } from 'react';

type ServiceAreasPropsType = {
  newVendorPayload: AddVendorPayloadType;
  setNewVendorPayload: Function;
  aopError: boolean;
};

const ServiceAreas = (props: ServiceAreasPropsType) => {
  const { newVendorPayload, setNewVendorPayload, aopError } = props;

  return (
    <>
      {newVendorPayload?.ServiceAreas?.map((item) => {
        return (
          <div className={styles.serviceArea}>
            <Locations
              allowStates
              allowedSmartyAddress={true}
              types={['(regions)']}
              variant={Locations.LIGHT}
              className={classNames(styles.inputBlock, styles.locationsBlock)}
              labelClassName={styles.editLabel}
              blockClassName={styles.rightBlock}
              valuesWrapperClassName={styles.valuesWrapper}
              activeInputClassName={styles.activeInput}
              activeInputIconClassName={styles.searchIcon}
              errorClassName={styles.locationError}
              placeholderClassName={styles.placeholder}
              searchIconClassName={styles.searchIcon}
              dropdownClassName={styles.dropdown}
              menuTop
              testid="area_served"
              showPrefixIcon={true}
              multiple={false}
              placeholder={'Add areas...'}
              onResult={(result, prepareData) => {
                if (prepareData.length)
                  setNewVendorPayload((prev) => {
                    return {
                      ...prev,
                      ServiceAreas: prev.ServiceAreas.map((area) =>
                        area.uuid === item.uuid ? { ...item, location: prepareData[0] } : area,
                      ),
                    };
                  });
              }}
              value={item.location ? [item.location] : []}
            />
            {item.hasRadius && (
              <RadiusInput item={item} setNewVendorPayload={setNewVendorPayload} />
            )}
            <ServiceAreaActions setNewVendorPayload={setNewVendorPayload} serviceArea={item} />
          </div>
        );
      })}
      <div className={styles.addAnother}>
        <div
          onClick={() => {
            setNewVendorPayload((prev) => {
              return {
                ...prev,
                ServiceAreas: [
                  ...prev.ServiceAreas,
                  { uuid: uuid(), hasRadius: true, radius: 0, location: undefined },
                ],
              };
            });
          }}
        >
          + Add Another
        </div>
      </div>
      {aopError ? (
        <div className={styles.validation}>
          {'Areas of Operation are required when publishing vendor.'}
        </div>
      ) : null}
    </>
  );
};

const RadiusInput = ({ item, setNewVendorPayload }) => {
  const radiusRef = useRef<any>(null);

  return (
    <div className={styles.radius} onClick={() => radiusRef?.current?.focus()}>
      <div className={item.radius ? '' : styles.label}>+</div>
      <input
        type="number"
        min={0}
        max={999}
        step={1}
        placeholder={'0'}
        value={item.radius || undefined}
        inputMode="numeric"
        ref={radiusRef}
        onBlur={(e: any) => {
          const value = e.target.valueAsNumber;
          setNewVendorPayload((prev) => {
            return {
              ...prev,
              ServiceAreas: prev.ServiceAreas.map((area) =>
                area.uuid === item.uuid
                  ? { ...item, radius: Number(value) > 999 ? 999 : value }
                  : area,
              ),
            };
          });
        }}
        onInput={(e: any) => {
          const value = e.target.valueAsNumber;
          setNewVendorPayload((prev) => {
            return {
              ...prev,
              ServiceAreas: prev.ServiceAreas.map((area) =>
                area.uuid === item.uuid
                  ? { ...item, radius: Number(value) > 999 ? 999 : value }
                  : area,
              ),
            };
          });
        }}
        onKeyDown={(e) => {
          if (e.key.toLowerCase() === '.') e.preventDefault();
        }}
      />
      <div className={styles.label}>mi</div>
    </div>
  );
};

export default ServiceAreas;
