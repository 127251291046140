import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLoadingParticipantsList,
  getInviteTransactionParticipantsFormattedList,
} from 'store/selectors/drawers/addParticipants';
import ClientInputView, { ValueObject } from './ClientInputView';
import { getUserId, getUserSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';
import { formatPhoneNumber } from 'helpers/formatters';
import { Role } from 'app-constants';
import { get } from 'lodash-es';

interface ClientInputProps {
  excludeUser?: boolean;
  className?: string;
  dropdownContainer?: string;
  userInfoContainer?: string;
  userNameContainer?: string;
  userEmailContainer?: string;
  value: ValueObject;
  onChange?: (...args) => void;
  onBlur?: (...args) => void;
  disabled?: boolean;
  placeholder?: string;
  allowedRoles?: Role[];
  excludedParticipantIds?: number[];
  allowUser?: boolean;
  maxLength?: number;
  excludeLastName?: boolean;
}

const ClientInput = ({
  excludeUser = false,
  allowedRoles,
  allowUser = false,
  ...props
}: ClientInputProps) => {
  const dispatch = useDispatch();

  const userId = useSelector(getUserId);
  const userSelector = useSelector(getUserSelector);
  const user = userSelector?.data ?? {};
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { isIdle, isData } = useSelector(getLoadingParticipantsList);

  let participants = useSelector(getInviteTransactionParticipantsFormattedList) || [];

  useEffect(() => {
    if (isAgent && !isData) {
      dispatch(
        requestGetUserParticipantsListEffect(
          {
            includeCollaborators: true,
          },
          { silent: !isIdle && isData },
        ),
      );
    }
  }, []);

  if (excludeUser) {
    participants = (participants || []).filter((participant) => participant?.id !== userId);
  }
  if (props.excludedParticipantIds?.length) {
    participants = (participants || []).filter(
      (participant) => !props.excludedParticipantIds?.includes(participant?.id),
    );
  }
  if (allowedRoles?.length) {
    participants = participants.filter((participant) => allowedRoles.includes(participant?.role));
  }

  if (allowUser) {
    const role = get(user, 'Roles.0');
    const formattedUser = {
      id: user?.Id,
      name: `${user?.FirstName} ${user?.LastName}$/$${user?.Email}`, // don't remove $/$ separator
      firstName: user?.FirstName,
      lastName: user?.LastName,
      role,
      avatarUrl: user?.AvatarUrl,
      email: user?.Email,
      Phones:
        user?.Phones?.map((phone) => ({
          ...phone,
          PhoneNumber: formatPhoneNumber(phone.PhoneNumber),
        })) ?? [],
    };
    participants = [...participants, formattedUser];
  }

  return <ClientInputView participants={participants} {...props} />;
};

export default ClientInput;
