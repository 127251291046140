import PropTypes from 'prop-types';

const Calendar = ({ className, color = '#525252' }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <g clipPath="url(#clip0_70781_160298)">
        <path
          d="M15.5007 4.16797H5.50065C4.58018 4.16797 3.83398 4.91416 3.83398 5.83464V15.8346C3.83398 16.7551 4.58018 17.5013 5.50065 17.5013H15.5007C16.4211 17.5013 17.1673 16.7551 17.1673 15.8346V5.83464C17.1673 4.91416 16.4211 4.16797 15.5007 4.16797Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.834 2.5V5.83333"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.16602 2.5V5.83333"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.83398 9.16797H17.1673"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.83398 13.332H12.1673"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_70781_160298">
          <rect width="20" height="20" fill={color} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

Calendar.propTypes = {
  className: PropTypes.string,
};

Calendar.defaultProps = {
  className: '',
};

export default Calendar;
