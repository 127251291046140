import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import _ from 'lodash';
import classNames from 'classnames';

import { Button, Input, SimpleModal } from 'components';
import { useFormik } from 'formik';
import Icon from 'pages/Properties/SearchResults/Icons';
import styles from 'pages/Properties/SearchResults/LocalHeader/SaveSearchButton/styles.module.scss';
import { ValidationSchema } from 'pages/Properties/SearchResults/LocalHeader/SaveSearchButton/validation';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { selectedSearchAction, setCriteriaModeAction } from 'store/actions/feedv3';
import { saveSearchInstanceEffect } from 'store/effects';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import { BookmarkOutline } from 'pages/Properties/LotiIcons';
import { TabButton } from 'pages/Properties/AgentFeed/components';
import { EDIT } from 'settings/constants/mode';
import { Cross } from 'components/Icons';
import { useSearchInstanceV3Effect } from 'pages/Properties/ClientFeed/hooks/useSearchInstanceV3Effect';

type Props = {
  className?: string;
  disabled?: boolean;
  button?: any;
  onClose?: any;
};

export const SaveSearchBtn: React.FC<Props> = ({
  className,
  disabled = false,
  button: CustomButton,
  onClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const searchContext = useSelector(getCurrentContextSelector);
  const cfgSearch = { contextKey: searchContext?.ContextKey };
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const getSearchInstanceV3 = useSearchInstanceV3Effect();

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setIsPending(true);

      const DefaultPropertySearchPreferences = cleanSearchQueryObj(criteria);

      if (DefaultPropertySearchPreferences?.HomeType?.length < 1) {
        DefaultPropertySearchPreferences.HomeType = ['Single', 'Townhouse', 'Condo'];
      }

      const cfg = {
        name: values.name,
        status: SEARCH_INSTANCE_STATUS.ACTIVE,
        DefaultPropertySearchPreferences: {
          ...DefaultPropertySearchPreferences,
        },
        ...cfgSearch,
      };

      dispatch(
        saveSearchInstanceEffect(cfg, {}, (err, resp) => {
          if (!err) {
            // Get the recently added saved search and apply that on the Feed page.
            const latestIdObject: any = _.maxBy(resp?.data?.result, 'Id');
            if (latestIdObject) {
              const item = {
                id: latestIdObject?.Id,
                name: latestIdObject?.Name,
                criterias: DefaultPropertySearchPreferences,
              };
              dispatch(selectedSearchAction({ selectedSearch: item }));
              getSearchInstanceV3(item);
            }
            dispatch(setCriteriaModeAction({ mode: EDIT }));
            history.push(CustomButton ? routes.properties : routes.feed);
          } else setIsPending(false);
        }),
      );
    },
  });

  const onCloseFn = () => {
    setPopupOpen(false);
    formik.resetForm();
    if (onClose) onClose();
  };

  const closeModal = () => {
    setPopupOpen(false);
    formik.resetForm();
  };

  const onClickHandler = () => {
    if (!disabled) setPopupOpen(!popupOpen);
  };
  return (
    <div className={classNames(styles.saveSearchButton, className)}>
      {CustomButton ? (
        <span onClick={onClickHandler}>
          <CustomButton />
        </span>
      ) : (
        <TabButton
          text="Save Search"
          icon={BookmarkOutline}
          onClick={onClickHandler}
          className={styles.margin}
        />
      )}

      <SimpleModal isOpen={popupOpen} onClose={closeModal} contentClassName={styles.popup}>
        <div className={styles.popupInner}>
          <form testid="save_search_form" onSubmit={formik.handleSubmit}>
            <Cross size={20} onClick={closeModal} className={styles.cross} color="#515151" />
            <div className={classNames({ [styles.noMargin]: CustomButton }, styles.header)}>
              <Icon className={styles.icon} variant={Icon.BOOKMARK} />
              <span className={styles.title}>Save Search</span>
            </div>
            {CustomButton && (
              <p className={styles.subText}>
                You can save this search to continue browsing later from your Saved Searches
              </p>
            )}
            <Input
              label="Name"
              name="name"
              variant={Input.LIGHT_ROUND}
              placeholder="e.g. Chicago - 3 BR / 2 BA"
              maxLength={30}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name ? formik.errors.name : ''}
              testid="search_name"
              className={styles.saveSearchInput}
            />
            <div className={styles.actions}>
              <Button
                onClick={onCloseFn}
                className={classNames(styles.action, styles.cancel)}
                title={CustomButton ? `Don't Save` : 'Cancel'}
                testid="cancel"
                titleClassName={styles.buttonTitle}
              />
              <Button
                type="submit"
                title={CustomButton ? 'Save & Exit' : 'Save'}
                isPending={isPending}
                className={classNames(styles.action, styles.save)}
                loaderClassName={styles.loader}
                testid="save"
                titleClassName={styles.buttonTitle}
              />
            </div>
          </form>
        </div>
      </SimpleModal>
    </div>
  );
};
