import { getCtaData } from 'api/kits';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserRolesMapSelector } from 'store/selectors/user';

export const useKitCta = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sourceParam = queryParams.get('source');
  const uuidParam = queryParams.get('uuid');

  const { isClient } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    if (sourceParam === 'kits' && uuidParam && isClient) {
      getCtaData(uuidParam);
    }
  }, [isClient, sourceParam, uuidParam]);
};
