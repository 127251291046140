import classNames from 'classnames';
import styles from './styles.module.scss';
import { useEffect, useRef, useState } from 'react';

type DetailsSectionPropsType = {
  icon;
  title: string;
  bold: boolean;
  text?: string;
  infoToDisplay?: { text: string; subText?: string }[];
  iconBG: string;
};

const DetailsSection = (props: DetailsSectionPropsType) => {
  const infoLimit = 3;
  const { icon, title, text, infoToDisplay, iconBG, bold } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef<any>(null);
  const [showAll, setShowAll] = useState<boolean>(
    infoToDisplay && infoToDisplay.length <= infoLimit ? true : false,
  );

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsClamped(element.scrollHeight > element.offsetHeight);
    }
  }, []);

  return (
    <div className={styles.detailsSection}>
      <div className={styles.detailIcon} style={{ backgroundColor: iconBG }}>
        {icon}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.title}>{title}</div>
        {infoToDisplay?.length ? (
          showAll ? (
            infoToDisplay.map((info) => (
              <div className={classNames(styles.text, bold ? styles.textBold : '')}>
                {info.text}
                {info.subText && <span className={styles.subText}>{info.subText}</span>}
              </div>
            ))
          ) : (
            infoToDisplay.slice(0, infoLimit).map((info) => (
              <div className={classNames(styles.text, bold ? styles.textBold : '')}>
                {info.text}
                {info.subText && <span className={styles.subText}>{` ${info.subText}`}</span>}
              </div>
            ))
          )
        ) : (
          <div
            ref={textRef}
            className={classNames(
              classNames(styles.text, bold ? styles.textBold : ''),
              isExpanded ? styles.textUnclamped : styles.textClamped,
            )}
          >
            {text || '-'}
          </div>
        )}
        {(infoToDisplay && infoToDisplay.length > infoLimit) || (text && isClamped) ? (
          <div
            className={styles.showAll}
            onClick={() => {
              setShowAll((prev) => !prev);
              setIsExpanded((prev) => !prev);
            }}
          >{`Show ${showAll ? 'less' : 'all'}`}</div>
        ) : null}
      </div>
    </div>
  );
};

export default DetailsSection;
