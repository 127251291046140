export enum RadarPortfolioViewTypes {
  ViewByClient = 'ViewByClient',
  ViewByProperty = 'ViewByProperty',
  ViewByAgent = 'ViewByAgent',
}

export enum TeamViewTypes {
  TeamPortfolio = 'TeamPortfolio',
  MyPortfolio = 'MyPortfolio',
}

export enum NeighborhoodCategoryTypes {
  New = 'New',
  StatusChanges = 'Status Changes',
  PriceReductions = 'Price Reductions',
  PriceIncrease = 'Price Increase',
  BackOnMarket = 'Back on Market',
  WITHDRAWN = 'Withdrawn',
  UNDER_CONTRACT = 'Under Contract',
  EXPIRED = 'Expired',
  OpenHouses = 'Open Houses',
}

export enum NeighborhoodStatusTypes {
  Sold = 'Sold',
  ActiveUnderContract = 'ActiveUnderContract',
  ComingSoon = 'ComingSoon',
  AcceptingBackups = 'AcceptingBackups',
}

export enum NeighborhoodFilterTypes {
  All = 'All',
  NEW_LISTING = 'New Listing',
  PRICE_REDUCTION = 'Price Reduction',
  PRICE_INCREASE = 'Price Increase',
  BACK_ON_THE_MARKET = 'Back on the Market',
  WITHDRAWN = 'Withdrawn',
  UNDER_CONTRACT = 'Under Contract',
  EXPIRED = 'Expired',
  SOLD = 'Sold',
}

export enum NeighborhoodDayRangeTypes {
  Today = 'Today',
  ThisWeek = 'ThisWeek',
  ThisMonth = 'ThisMonth',
}

export enum NeighborhoodViewTypes {
  List = 'List',
  Map = 'Map',
}

export enum KitSlug {
  TaxDocuments = 'tax-documents', // Tax Documents
  AnnualFinancialReview = 'annual-financial-review', // Annual Financial Review
  HomeWarrantyExpirationReminder = 'home-warranty-expiration-reminder', // Home Warranty Expiration Reminder
  PMIRemoval = 'pmi-removal', // PMI Removal
  CheckInSixMonths = 'check-in-6-months', // Check-In (6 Months)
  WearTear = 'wear-and-tear-check-up', // Wear & Tear Check-Up
  AirFiltersReminder = 'air-filters-reminder', // Air Filters Reminder
  TaxDocumentsYearOne = 'tax-documents-year-1', // Tax Documents (Year 1)
  SupplementalTaxReminder = 'supplemental-tax-reminder', // Supplemental Tax Reminder
  PropertyTaxReminder = 'property-tax-reminder', // Property Tax Reminder
  ThingsAfterClosing = 'things-to-look-for-after-closing', // Things To Look For After Closing
  HomeInsuranceRenewalReminder = 'home-insurance-renewal-reminder', // Home Insurance Renewal Reminder
  HomeRentalValue = 'home-and-rental-value', // Home & Rental Value
  NeighborhoodActivity = 'neighborhood-activity', // Neighborhood Activity
  NewListing = 'new-listing', // New Listing
  NewPriceChange = 'new-price-change', // New Price Change
  RecentlySold = 'recently-sold', // Recently Sold
  Default = 'default',
}

export type NeighborhoodFiltersChangeHandler = (params: {
  category?: NeighborhoodCategoryTypes[];
  activityStatus?: NeighborhoodStatusTypes[];
  dayRange?: number;
}) => void;
