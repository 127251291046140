import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadingPartnersData } from 'store/selectors/partners';
import { Wrapper } from 'components';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import { requestGetPartnersListByCategoryIdEffect } from 'store/effects/partners';
import { showErrorMessage } from 'helpers';
import { getAllVendorDrafts, getRecommendedPartnerByAgentList } from 'api/partners';
import {
  setRecommendedPartnersByAgentListAction,
  setPartnerDraftsAsRecommendations,
} from 'store/actions/partners';

const RequestCategoriesWrapper = ({ children, className }) => {
  const dispatch = useDispatch();
  const { isPending, isError } = useSelector(getLoadingPartnersData);
  const searchConfig = useSelector(getConciergeSearchSelector);
  const { areasOfOperation, category: categoryId, services } = searchConfig;
  const userRole = useSelector(getUserRoleSelector);
  const [draftsLoading, setDraftsLoading] = useState(false);
  const [recommendationsLoading, setRecommendationsLoading] = useState(false);

  useEffect(() => {
    if (
      categoryId !== null &&
      categoryId !== undefined &&
      categoryId !== '' &&
      Number(categoryId) !== ConciergeDefaultCategories.Draft &&
      !isPending &&
      (areasOfOperation?.length || userRole === CLIENT)
    )
      dispatch(requestGetPartnersListByCategoryIdEffect());
  }, [categoryId, areasOfOperation, services]);

  useEffect(() => {
    if (userRole === AGENT) {
      Number(categoryId) === ConciergeDefaultCategories.Draft
        ? fetchPartnerDrafts()
        : fetchRecommendations();
    }
  }, [dispatch, categoryId, userRole, areasOfOperation, services]);

  const fetchRecommendations = async () => {
    setRecommendationsLoading(true);
    try {
      const cfg = {
        areasOfOperation,
        categories: [categoryId],
        services,
      };
      const response = await getRecommendedPartnerByAgentList(cfg);
      if (response.status === 200)
        dispatch(
          setRecommendedPartnersByAgentListAction({
            state: 'ready',
            data: response?.data?.result?.length ? response?.data?.result : [],
            meta: categoryId,
          }),
        );
    } catch (e) {
      showErrorMessage('Unable to get recommended partners');
    }
    setRecommendationsLoading(false);
  };

  const fetchPartnerDrafts = async () => {
    if (draftsLoading) return;

    setDraftsLoading(true);
    try {
      const response = await getAllVendorDrafts();
      if (response.status === 200) {
        dispatch(
          setPartnerDraftsAsRecommendations({
            drafts: response?.data?.result?.length ? response.data.result : [],
            meta: categoryId,
          }),
        );
      }
    } catch (e) {
      showErrorMessage('Unable to load drafts');
    }
    setDraftsLoading(false);
  };

  const isLoading = useMemo(() => {
    if (Number(categoryId) !== ConciergeDefaultCategories.Draft) {
      if (recommendationsLoading) return true;
      //if (metaId !== categoryId) return true;
      if (isError) return false;
      if (isPending) return true;
      //if (!isData) return true;
    }

    if (draftsLoading) return true;

    return false;
  }, [isPending, isError, categoryId, draftsLoading, recommendationsLoading]);

  return (
    <Wrapper className={className} isPending={isLoading}>
      {children}
    </Wrapper>
  );
};

RequestCategoriesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RequestCategoriesWrapper.defaultProps = {
  className: '',
};

export default RequestCategoriesWrapper;
