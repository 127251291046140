import { TransactionTemplate } from 'types';

export const extractTemplateData = (templateData?: TransactionTemplate) => {
  return [
    {
      name: 'TransactionCategoryId',
      value: templateData?.TransactionCategoryId,
    },
    {
      name: 'Name',
      value: templateData?.Name || '',
    },
    {
      name: 'Description',
      value: templateData?.Description || '',
    },
    {
      name: 'PropertyType',
      value: templateData?.PropertyType || [],
    },
    {
      name: 'Financing',
      value: templateData?.Financing || [],
    },
    {
      name: 'City',
      value: templateData?.City || [],
    },
    {
      name: 'State',
      value: templateData?.State || [],
    },
    {
      name: 'County',
      value: templateData?.County || [],
    },
    {
      name: 'People',
      value: templateData?.People || [],
    },
    {
      name: 'Teams',
      value: templateData?.Teams || [],
    },
    {
      name: 'Locations',
      value: templateData?.Locations || [],
    },
    {
      name: 'TimelineTemplate',
      value: templateData?.TimelineTemplate || {},
    },
    {
      name: 'TaskTemplates',
      value: templateData?.TaskTemplates?.sort((item1, item2) => item1?.Order - item2?.Order) || [],
    },
    {
      name: 'TransactionFormTemplate',
      value: templateData?.TransactionFormTemplate || [],
    },
    {
      name: 'DetailFields',
      value: templateData?.DetailFields || [],
    },
  ];
};
