import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;
const mlsDashboardStatsState = createSelector(localState, ({ mlsDashboard }) => mlsDashboard);

export const getMlsStatsDashboardSelector = createSelector(
  mlsDashboardStatsState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);
