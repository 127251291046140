import { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'components-antd';
import { Input, Wrapper } from 'components';
import {
  getFormattedListingDetailData,
  getListingDetailData,
  getListingDetailMapData,
  getListingMarket,
} from 'store/selectors/listingDetail';
import { getCurrentContextSelector } from 'store/selectors/context';
import { requestScheduleTourEffect, getScheduleTourEffect } from 'store/effects/listingDetail';
import { showSuccessMessage } from 'helpers/success';
import { showErrorMessage } from 'helpers/errors';
import moment from 'moment';
import PhotosViewModal from '../PhotosViewModal';
import SearchableReactJson from 'searchable-react-json-view';
import HomeActions from '../HomeActions';
import HeadInfo from '../HeadInfo';
import Match from '../Match';
import ListingDetailTabs from '../ListingDetailTabs';
import ListingDetailPhotosBlock from '../PhotosBlock';
import ListingDetailMap from '../ListingDetailMap';
import ListingOpenHouse from '../ListingOpenHouse';
import { getHomeInfoSelector } from '../HomeActions/selectors';
import { Accordion } from 'components';

import styles from './styles.module.scss';
import PriceReduction from '../PriceReduction';
import { fixURL } from 'helpers';
import { Checkbox } from 'antd';
import { getCommonProfileData, getUserSelector } from 'store/selectors/user';
import useUrlValidity from 'hooks/use-url-validity';
import { featureFlags } from 'utils/featureFlags';
import { getMlsDisclaimer, getMlsImage, getSupportEmail } from 'helpers/listingDisclaimer';

interface VirtualTour {
  item: string;
  itemValue: string;
}

const ListingDetailContent = ({
  propertyInfo,
  handleEvaluationCheckBoxClick,
  isFullEvaluation,
}) => {
  const {
    activityStatus,
    address,
    price,
    baths,
    beds,
    addressLine2,
    addressLine1,
    photos,
    virtualTour,
    square,
    propertyHistory,
    sourceSection,
    mlsLastUpdate,
    virtualTourBranded,
    virtualTourUnBranded,
    closingPrice,
    recommendedTo,
    extractedFeatures,
  } = useSelector(getFormattedListingDetailData);
  const [isTourScheduled, setIsTourScheduled] = useState(true);
  const [isLoadingTourStatus, setIsLoadingTourStatus] = useState(false);
  const { isMosaikUser } = useSelector(getCommonProfileData);
  const { Raw, ...MlsData } = useSelector(getListingDetailData);
  const [mlsSearch, setMlsSearch] = useState('');
  const [searchLoader, setSearchLoader] = useState(false);
  const timerRef = useRef<any>(null); // Ref to store the debounce timer

  const handleInputChange = (_, val) => {
    setMlsSearch(val);
    setSearchLoader(true); // Set loader to true

    // Clear the previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer
    timerRef.current = setTimeout(() => {
      setSearchLoader(false); // Stop the loader after 1 second
      // Trigger the search logic
    }, 1000);
  };

  const dispatch = useDispatch();

  const { isClient } = useSelector(getHomeInfoSelector);
  const context = useSelector(getCurrentContextSelector);
  const mapOptions = useSelector(getListingDetailMapData);
  const { oldPrice = 0, newPrice = 0 } = propertyHistory || {};
  const user = useSelector(getUserSelector);
  const market = useSelector(getListingMarket);
  const mlsDisclaimer = getMlsDisclaimer(market);
  const { mlsLastChecked } = useSelector(getFormattedListingDetailData);
  const { mlsLogoURL, mlsLogoWidth } = getMlsImage(market);

  const [showFormLibraryModal, setShowFormLibraryModal] = useState(false);

  const [modalOpenImage, setModalOpenImage] = useState(null);
  const [tabValue, setTabValue] = useState('');
  const [showRawData, setShowRawData] = useState(false);

  const showMatchScoreSimulator = !featureFlags.hideMatchScoreSimulator && isMosaikUser;

  useEffect(() => {
    if (isClient) {
      setIsLoadingTourStatus(true);
      dispatch(
        getScheduleTourEffect({ propertyId: propertyInfo.Id }, null, (err, { data }) => {
          setIsLoadingTourStatus(false);
          const tour = data.value;
          if (!tour) {
            setIsTourScheduled(false);
          }
          if (moment(tour.ScheduleDateTime) < moment()) {
            setIsTourScheduled(false);
            return;
          }
          if (tour.Status === 'Canceled') {
            setIsTourScheduled(false);
          }
        }),
      );
    }
  }, []);

  const { isValid: isVirtualTourValid } = useUrlValidity(virtualTour);
  const { isValid: isVirtualTourBrandedValid } = useUrlValidity(virtualTourBranded);
  const { isValid: isVirtualTourUnBrandedValid } = useUrlValidity(virtualTourUnBranded);

  const [virtualTourValue, setVirtualTourValue] = useState(undefined);
  const [virtualTours, setVirtualTours] = useState<VirtualTour[]>([]);

  useEffect(() => {
    const tours: VirtualTour[] = [];
    if (isVirtualTourValid && virtualTour) {
      tours.push({ item: 'Virtual Tour', itemValue: virtualTour });
    }
    if (isVirtualTourBrandedValid && virtualTourBranded) {
      tours.push({ item: 'Virtual Tour (Branded)', itemValue: virtualTourBranded });
    }
    if (
      isVirtualTourUnBrandedValid &&
      virtualTourUnBranded &&
      !virtualTourUnBranded.includes('.pdf')
    ) {
      tours.push({ item: 'Virtual Tour (UnBranded)', itemValue: virtualTourUnBranded });
    }
    setVirtualTours(tours);

    if (tours.length > 0) {
      // Set virtualTourValue to the first valid URL
      setVirtualTourValue(fixURL(tours[0].itemValue));
    } else {
      setVirtualTourValue(undefined);
    }
  }, [
    isVirtualTourValid,
    isVirtualTourBrandedValid,
    isVirtualTourUnBrandedValid,
    virtualTour,
    virtualTourBranded,
    virtualTourUnBranded,
  ]);

  const virtualTourVisibility = virtualTours.length > 0;

  const openModal = useCallback((value = 0) => {
    setModalOpenImage(value);
    document.body.style.overflow = 'hidden';
  }, []);

  const closeModal = useCallback(() => {
    setModalOpenImage(null);
    document.body.style.overflow = 'auto';
  }, []);

  const setTab = useCallback((value) => {
    setTabValue(value);
  }, []);

  const onScheduleTour = () => {
    let agentId = context.Agents.map((agent) => agent.Id);
    dispatch(
      requestScheduleTourEffect({ propertyInfo, agentId }, null, (error, response) => {
        if (response?.status === 200) {
          setIsTourScheduled(true);
          showSuccessMessage('Tour Request Sent');
        } else {
          showErrorMessage('Tour not scheduled successfully');
        }
      }),
    );
  };

  const handleOnTourSchedule = useCallback(() => setIsTourScheduled(true), []);

  return (
    <>
      <Modal
        open={showRawData}
        width={'100%'}
        footer={null}
        onCancel={() => {
          setShowRawData(false);
        }}
        maskClosable={false}
        destroyOnClose
      >
        <div className={styles.mlsData}>
          <p className={styles.title}>Mls Data</p>
          <Input
            label="Search"
            placeholder=""
            variant="lightRound"
            value={mlsSearch}
            onChange={handleInputChange}
          />
          <Wrapper className={styles.json} isPending={searchLoader}>
            <div className={styles.column}>
              <p>Raw Data</p>
              <div className={styles.content}>
                <SearchableReactJson
                  src={Raw}
                  theme="rjv-default"
                  enableClipboard={false}
                  displayDataTypes={false}
                  indentWidth={2}
                  collapsed={true}
                  highlightSearch={mlsSearch}
                />
              </div>
            </div>
            <div className={styles.column}>
              <p>Mls Data</p>
              <div className={styles.content}>
                <SearchableReactJson
                  src={MlsData}
                  theme="rjv-default"
                  enableClipboard={false}
                  displayDataTypes={false}
                  indentWidth={2}
                  collapsed={true}
                  highlightSearch={mlsSearch}
                />
              </div>
            </div>
          </Wrapper>
        </div>
      </Modal>
      <PhotosViewModal
        isTourScheduled={isTourScheduled}
        onScheduleTour={onScheduleTour}
        closeModal={closeModal}
        recommendedTo={recommendedTo}
        setVirtualTourValue={setVirtualTourValue}
        virtualTourValue={virtualTourValue}
        virtualTours={virtualTours}
        tabValue={tabValue}
        setTab={setTab}
        photos={photos}
        modalOpenImage={modalOpenImage}
        isOpen={modalOpenImage !== null}
        onClose={closeModal}
        setShowFormLibraryModal={setShowFormLibraryModal}
        propertyInfo={propertyInfo}
        virtualTourVisibility={virtualTourVisibility}
        photosTab={photos.length > 0}
        className=""
      />
      <div testid="property_content" className={styles.container}>
        <ListingDetailPhotosBlock
          photos={photos}
          openModal={openModal}
          setTab={setTab}
          virtualTour={virtualTourVisibility}
        />
        <div className={styles.contentWrapper}>
          <div className={styles.contentBlock}>
            <HeadInfo
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              price={price}
              baths={baths as number}
              beds={beds as number}
              square={square}
              sourceSection={sourceSection}
              mlsLastUpdate={mlsLastUpdate as unknown as string}
              activityStatus={activityStatus}
              closingPrice={closingPrice}
            />
            <Match />
            <ListingDetailTabs />
            {isMosaikUser || showMatchScoreSimulator ? (
              <Accordion
                testid="description_block"
                className={styles.accordion}
                titleClassName={styles.titleClassName}
                previewClassName={styles.previewClassName}
                iconContainerClassName={styles.iconContainer}
                title="Simulator"
              >
                <div className={styles.contentS}>
                  {isMosaikUser && (
                    <div className={styles.mlsBlock}>
                      <Checkbox
                        className={styles.checkBoxIcon}
                        value={showRawData}
                        checked={showRawData}
                        onChange={(e) => setShowRawData(e.target.checked)}
                      ></Checkbox>
                      <span className={styles.checkBoxText}>{'MLS Data'}</span>
                    </div>
                  )}
                  {showMatchScoreSimulator && (
                    <>
                      <div className={styles.evaluateBlock}>
                        <Checkbox
                          className={styles.checkBoxIcon}
                          value={isFullEvaluation}
                          checked={isFullEvaluation}
                          onChange={handleEvaluationCheckBoxClick}
                        ></Checkbox>
                        <span className={styles.checkBoxText}>{'Match Score Simulator'}</span>
                      </div>
                      {extractedFeatures &&
                        Object.entries(extractedFeatures).map(([key, value]) => (
                          <div key={key}>
                            {key}: {value}
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </Accordion>
            ) : null}
            <div className={styles.propertyDisclaimer}>
              <p className={styles.content}>
                {mlsLogoURL && (
                  <div>
                    <img width={mlsLogoWidth} src={mlsLogoURL}></img>
                    <br></br>
                  </div>
                )}
                {mlsDisclaimer}
                <br></br>
                <br></br>
                {!!mlsLastChecked && (
                  <div testid="mls_last_checked" className={styles.mlsLastChecked}>
                    {mlsLastChecked}
                  </div>
                )}
                <i>
                  Please contact{' '}
                  <a href={`mailto:${getSupportEmail(getListingMarket as any)}`}>
                    {getSupportEmail(getListingMarket as any)}
                  </a>{' '}
                  to report incorrect information.
                </i>
              </p>
            </div>
          </div>
          <div className={styles.sidebarBlock}>
            <ListingOpenHouse
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              photo={photos?.length ? photos[0] : null}
            />
            <PriceReduction oldPrice={oldPrice} newPrice={newPrice} />
            <ListingDetailMap options={mapOptions} />
            <HomeActions
              recommendedTo={recommendedTo}
              virtualTour={virtualTour}
              price={price}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              showFormLibraryModal={showFormLibraryModal}
              setShowFormLibraryModal={setShowFormLibraryModal}
              isTourScheduled={isTourScheduled}
              onTourSchedule={handleOnTourSchedule}
              address={address}
              isFetchingTourStatus={isLoadingTourStatus}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingDetailContent;
