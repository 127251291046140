import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface TaskSectionContainerType {
  icon?: ReactNode;
  children: ReactNode;
  align?: 'center' | 'flex-start';
  title?: string;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  hideSection?: boolean;
  disabled?: boolean;
}

export const TaskSectionContainer = ({
  icon,
  children,
  align = 'flex-start',
  title,
  titleClassName,
  className,
  contentClassName,
  hideSection = false,
  disabled = false,
}: TaskSectionContainerType) => {
  if (hideSection) return null;

  return (
    <div
      className={classNames(
        styles.taskSectionContainer,
        {
          [styles.alignCenter]: align === 'center',
        },
        {
          [styles.disabled]: disabled,
        },
        className,
      )}
    >
      {icon && <div style={align !== 'center' ? { marginTop: 5 } : {}}>{icon}</div>}
      <div className={classNames(styles.content, contentClassName)}>
        {title && <p className={classNames(styles.title, titleClassName)}>{title}</p>}
        {children}
      </div>
    </div>
  );
};
