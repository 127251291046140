import React, { memo, useMemo } from 'react';

const MinusRounded = memo(
  ({ className, color = '#FF576D', onClick }: React.HTMLAttributes<HTMLDivElement>) =>
    useMemo(
      () => (
        <div className={className} onClick={onClick}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6 9C5.44772 9 5 9.4477 5 10C5 10.5523 5.44772 11 6 11H14C14.5523 11 15 10.5523 15 10C15 9.4477 14.5523 9 14 9H6Z"
              fill={color}
            />
          </svg>
        </div>
      ),
      [className],
    ),
);

export default MinusRounded;
