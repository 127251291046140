import React, { useEffect, useMemo, useState } from 'react';

import { TableColumnsType } from 'antd';
import classNames from 'classnames';
import { getShortName, UserAvatar } from 'components-antd';
import moment from 'moment';
import { RadarHeader, Table } from 'pages/Radar/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import {
  requestGetCampaignByIdEffect,
  requestGetCampaignMembersByCampaignIdEffect,
  requestUpdateCampaignEffect,
} from 'store/effects/radarKits';
import { selectedSequenceSelector, selectedSequenceStateSelector } from 'store/selectors/radarKits';
import tableStyles from '../../components/Table/styles.module.scss';
import { AssignClientsModal } from '../AssignClientsModal';
import { DeleteIcon, PlusIcon } from '../components';
import { DropdownMenu } from '../components/DropdownMenu';
import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { Spinner, Wrapper } from 'components';
import { PENDING } from 'settings/constants/apiState';

type DataType = {
  user: {
    name: string;
    picture?: string;
    id: string;
  };
  dateAdded: string;
  sent: number;
  opened: number;
  tasksCompleted: number;
};

export const AssignedClients: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedSequence = useSelector(selectedSequenceSelector);
  const state = useSelector(selectedSequenceStateSelector);
  const isPending = state === PENDING;
  const [selectedMembers, setSelectedMembers] = useState<{ ClientId: number; AddressId: number }[]>(
    [],
  );
  const [campaignMembers, setCampaignMembers] = useState<any[]>([]);

  useEffect(() => {
    dispatch(requestGetCampaignByIdEffect(id));
  }, []);

  const dataSource = useMemo<DataType[]>(() => {
    if (campaignMembers?.length === 0) return [];
    return campaignMembers.map((cm) => ({
      user: {
        name: cm.member.Client.User.FirstName + ' ' + cm.member.Client.User.LastName,
        picture: cm.member.Client.User.AvatarUrl,
        id: cm.member.ClientId,
      },
      dateAdded: moment(cm.member.CreatedDate).format('MM/DD/YYYY'),
      sent: cm.sentCount,
      opened: cm.openedCount,
      tasksCompleted: cm.taskCompleted,
    }));
  }, [campaignMembers]);

  useEffect(() => {
    dispatch(
      requestGetCampaignMembersByCampaignIdEffect(id, {}, (err, res) => {
        if (!err) {
          const members = res?.data?.result || [];
          setCampaignMembers(members);
          setSelectedMembers(
            members.flatMap((client) =>
              client.member.Addresses.map((address) => ({
                ClientId: client.member.ClientId,
                AddressId: address.AddressId,
              })),
            ),
          );
        }
      }),
    );
  }, [id, dispatch]);

  const navigateToAssignedClientView = (id: any, clientId: string) => {
    history.push(link.toRadarKitSequenceAssignedClientView(id, clientId));
  };
  const [isAssignClientsModalOpen, setIsAssignClientsModalOpen] = useState<boolean>(false);

  const columns = (dispatch): TableColumnsType<DataType> => [
    {
      title: 'Name',
      dataIndex: 'user',
      key: 'user',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
      render: (user: any) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={styles.iconWrapper}>
            <UserAvatar
              size={32}
              src={user?.picture}
              name={user.name}
              minimizeAvatar
              placeholder={getShortName(user.name)}
            />
          </div>
          <div className={classNames(styles.detailsWrapper, styles.name)}>{user.name}</div>
        </div>
      ),
    },
    {
      title: 'Added',
      dataIndex: 'dateAdded',
      key: 'dateAdded',
      className: tableStyles.tableCol,
      sorter: (a, b) => moment(a.dateAdded).unix() - moment(b.dateAdded).unix(),
      render: (value: string) => <div className={tableStyles.tableNormalCell}>{value}</div>,
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      key: 'sent',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.sent - b.sent,
      render: (value: string) => <div className={tableStyles.tableNormalCell}>{value}</div>,
    },
    {
      title: 'Opened',
      dataIndex: 'opened',
      key: 'opened',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.opened - b.opened,
      render: (value: string) => <div className={tableStyles.tableNormalCell}>{value}</div>,
    },
    {
      title: 'Tasks Completed',
      dataIndex: 'tasksCompleted',
      key: 'tasksCompleted',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.tasksCompleted - b.tasksCompleted,
      render: (value: string) => <div className={tableStyles.tableNormalCell}>{value}</div>,
    },
    {
      title: '',
      key: 'action',
      className: tableStyles.tableCol,
      width: 30,
      render: (value, record) => (
        <div className={tableStyles.tableActionCell}>
          <DropdownMenu
            id={record.user.id}
            options={[
              {
                label: 'Remove',
                icon: <DeleteIcon />,
                onSelect: (memberId) => {
                  dispatch(
                    requestUpdateCampaignEffect(
                      {
                        Id: Number(id),
                        CampaignMembers: selectedMembers.filter(
                          (member) => member.ClientId !== Number(memberId),
                        ),
                      },
                      {},
                      (err, res) => {
                        if (!err && res.data.result.Id) {
                          dispatch(
                            requestGetCampaignMembersByCampaignIdEffect(
                              id,
                              { silent: true },
                              (err, res) => {
                                if (!err) {
                                  const members = res?.data?.result || [];
                                  setCampaignMembers(members);
                                  setSelectedMembers(
                                    members.flatMap((client) =>
                                      client.member.Addresses.map((address) => ({
                                        ClientId: client.member.ClientId,
                                        AddressId: address.AddressId,
                                      })),
                                    ),
                                  );
                                }
                              },
                            ),
                          );
                        }
                        setIsAssignClientsModalOpen(false);
                      },
                    ),
                  );
                },
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const columnsMemo = useMemo(() => columns(dispatch), [campaignMembers, selectedMembers]);

  return (
    <>
      <div className={parentStyles.pageWrapper}>
        <RadarHeader
          rightChildren={
            <div className={styles.btnWrapper}>
              <button
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAssignClientsModalOpen(true);
                }}
              >
                <div>
                  <PlusIcon />
                </div>
                Enroll
              </button>
            </div>
          }
        >
          <div className={parentStyles.titleInputContainer}>
            <div className={parentStyles.title}>{selectedSequence?.Name}</div>
            <div className={parentStyles.description}>Enrolled Clients</div>
          </div>
        </RadarHeader>
        <Wrapper className={parentStyles.pageContent} isPending={isPending}>
          <div className={parentStyles.tableContainer}>
            <div>
              <Table
                title={`${campaignMembers.length} Client${campaignMembers.length === 1 ? '' : 's'}`}
                data={dataSource}
                columns={columnsMemo}
                handleRowClick={(record: any) => {
                  navigateToAssignedClientView(id, record.user.id);
                }}
              />
            </div>
          </div>
        </Wrapper>
      </div>
      {isAssignClientsModalOpen && (
        <AssignClientsModal
          isOpen={isAssignClientsModalOpen}
          handleClose={() => {
            dispatch(
              requestUpdateCampaignEffect(
                {
                  Id: Number(id),
                  CampaignMembers: selectedMembers,
                },
                {},
                (err, res) => {
                  if (!err && res.data.result.Id) {
                    dispatch(
                      requestGetCampaignMembersByCampaignIdEffect(
                        id,
                        { silent: true },
                        (err, res) => {
                          if (!err) {
                            const members = res?.data?.result || [];
                            setCampaignMembers(members);
                            setSelectedMembers(
                              members.flatMap((client) =>
                                client.member.Addresses.map((address) => ({
                                  ClientId: client.member.ClientId,
                                  AddressId: address.AddressId,
                                })),
                              ),
                            );
                          }
                        },
                      ),
                    );
                  }
                  setIsAssignClientsModalOpen(false);
                },
              ),
            );
          }}
          closeDialog={() => {
            setIsAssignClientsModalOpen(false);
          }}
          subHeading="Assign clients to this kit"
          selected={selectedMembers}
          setSelected={setSelectedMembers}
        />
      )}
    </>
  );
};
