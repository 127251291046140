import { Tabs } from 'components';
import { House, Geotag } from 'components/Icons';
import NeighborhoodTab from './NeighborhoodTab';
import PropertyTab from './PropertyTab';

import styles from './styles.module.scss';
import { useState } from 'react';
import classNames from 'classnames';

const ListingDetailTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const renderTab = () => {
    switch (activeTab) {
      case 0:
        return <PropertyTab />;

      case 1:
        return <NeighborhoodTab />;

      default:
        break;
    }
  };
  return (
    <div>
      <div className={styles.tabs}>
        {[
          {
            label: 'Property',
            Component: PropertyTab,
            Icon: House,
          },
          {
            label: 'Neighborhood',
            Component: NeighborhoodTab,
            Icon: Geotag,
          },
        ].map(({ label, Icon }, i) => (
          <div
            className={classNames(styles.tab, { [styles.active]: i === activeTab })}
            onClick={() => setActiveTab(i)}
          >
            <Icon className={styles.tabIcon} />
            <p>{label}</p>
          </div>
        ))}
      </div>
      {renderTab()}
    </div>
  );
};

export default ListingDetailTabs;
