export enum REPEAT_OPTIONS {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
  CUSTOM = 'Custom',
}

export enum REPEAT_EVERY_OPTIONS {
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
}

export enum WEEKLY_OPTIONS {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export const WEEKLY_DROPDOWN_OPTIONS = [
  {
    name: 'Monday',
    value: WEEKLY_OPTIONS.MONDAY,
  },
  {
    name: 'Tuesday',
    value: WEEKLY_OPTIONS.TUESDAY,
  },
  {
    name: 'Wednesday',
    value: WEEKLY_OPTIONS.WEDNESDAY,
  },
  {
    name: 'Thursday',
    value: WEEKLY_OPTIONS.THURSDAY,
  },
  {
    name: 'Friday',
    value: WEEKLY_OPTIONS.FRIDAY,
  },
  {
    name: 'Saturday',
    value: WEEKLY_OPTIONS.SATURDAY,
  },
  {
    name: 'Sunday',
    value: WEEKLY_OPTIONS.SUNDAY,
  },
];

export const REPEAT_DROPDOWN_OPTIONS = [
  {
    name: 'Daily',
    value: REPEAT_OPTIONS.DAILY,
  },
  {
    name: 'Weekly',
    value: REPEAT_OPTIONS.WEEKLY,
  },
  {
    name: 'Monthly',
    value: REPEAT_OPTIONS.MONTHLY,
  },
  {
    name: 'Annually',
    value: REPEAT_OPTIONS.ANNUALLY,
  },
  {
    name: 'Custom',
    value: REPEAT_OPTIONS.CUSTOM,
  },
];

export const REPEAT_EVERY_DROPDOWN_OPTIONS = [
  {
    name: 'Week',
    value: REPEAT_EVERY_OPTIONS.WEEK,
  },
  {
    name: 'Month',
    value: REPEAT_EVERY_OPTIONS.MONTH,
  },
  {
    name: 'Year',
    value: REPEAT_EVERY_OPTIONS.YEAR,
  },
];
