import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { deleteVendorDraftById, getAllVendorDrafts } from 'api/partners';
import { showErrorMessage, showSuccessMessage, convertNameToAvatarPlaceholder } from 'helpers';
import classNames from 'classnames';
import { Spinner, Avatar } from 'components';
import { trim } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { InviteVendorIcon } from 'pages/ServicesCategory/components/icons';
import moment from 'moment';
import { Dots, Edit } from 'components/Icons';
import { deletePartnerDraftsAsRecommendations } from 'store/actions/partners';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { ConciergeDefaultCategories } from 'pages/Services/constants';
import { DeleteIcon } from '../../icons';

type VendorDraftsPropsType = {
  setViewDrafts: Function;
  draftEditClickHandler: Function;
  clearStateOnDraftDelete: Function;
};

const VendorDrafts = (props: VendorDraftsPropsType) => {
  const dispatch = useDispatch();
  const { category } = useSelector(getConciergeSearchSelector);
  const { setViewDrafts, draftEditClickHandler, clearStateOnDraftDelete } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [drafts, setDrafts] = useState<any[]>([]);

  useEffect(() => {
    fetchAllDrafts();
  }, []);

  const fetchAllDrafts = async () => {
    try {
      const response = await getAllVendorDrafts();
      if (response.status === 200 && response?.data?.result?.length)
        setDrafts(response.data.result);
    } catch (e) {
      showErrorMessage('Unable to load drafts');
      setViewDrafts(false);
    }
    setLoading(false);
  };

  const draftDeleteHandler = async (id) => {
    try {
      const response = await deleteVendorDraftById(id);
      if (response.status === 200) {
        clearStateOnDraftDelete(id);
        setDrafts((prev) => [...prev.filter((draft) => draft.Id !== id)]);
        if (Number(category) === ConciergeDefaultCategories.Draft)
          dispatch(deletePartnerDraftsAsRecommendations({ id }));
        showSuccessMessage('Draft deleted successfully');
      }
    } catch (e) {
      showErrorMessage('Unable to delete drafts');
    }
  };

  if (loading)
    return (
      <div className={classNames(styles.vendorDrafts, styles.states)}>
        <Spinner />
      </div>
    );
  else if (!loading && !drafts.length)
    return <div className={classNames(styles.vendorDrafts, styles.states)}>No saved drafts!</div>;
  else
    return (
      <div className={styles.vendorDrafts}>
        {drafts.map((draft) => (
          <DraftCard
            draft={draft}
            draftDeleteHandler={draftDeleteHandler}
            draftEditClickHandler={draftEditClickHandler}
          />
        ))}
      </div>
    );
};

type DraftCardPropsType = {
  draft;
  draftDeleteHandler: Function;
  draftEditClickHandler: Function;
};

const DraftCard = (props: DraftCardPropsType) => {
  const { draft, draftDeleteHandler, draftEditClickHandler } = props;
  const allCategories = useSelector(getAllServicesCategoriesList);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getLogo = () => {
    if (draft.LogoUrl)
      return (
        <Avatar
          avatarClassName={styles.logo}
          src={draft.LogoUrl}
          placeholder={convertNameToAvatarPlaceholder(trim(draft.BusinessName))}
        />
      );

    if (draft?.PartnerCategoryIds?.length) {
      const category = allCategories?.find((cat) => cat.Id === draft?.PartnerCategoryIds[0]);
      if (category.Icon) return <img src={category.Icon} alt="category" className={styles.logo} />;
    }

    return (
      <div className={classNames(styles.logo, styles.logoIcon)}>
        <InviteVendorIcon color="#4673D1" />
      </div>
    );
  };

  return (
    <div className={styles.draftCard}>
      <div className={styles.left}>
        {getLogo()}
        <div className={styles.info}>
          <div className={styles.name}>{draft.BusinessName || '-'}</div>
          <div className={styles.date}>{`Last Edited: ${moment(
            draft.UpdatedDate || draft.CreatedDate,
          ).format('DD/MM/YYYY')}`}</div>
        </div>
      </div>
      <div
        className={styles.right}
        onClick={() => {
          setShowMenu((prev) => !prev);
        }}
      >
        <Dots className={styles.icon} />
        {showMenu && (
          <div className={styles.menu} ref={menuRef}>
            <div
              className={styles.item}
              onClick={() => {
                draftEditClickHandler(draft);
              }}
            >
              <span className={styles.icon}>
                <Edit stroke={'#ff576d'} />
              </span>
              <div className={styles.label}>Edit</div>
            </div>
            <div
              className={styles.item}
              onClick={() => {
                draftDeleteHandler(draft.Id);
              }}
            >
              <DeleteIcon />
              <div className={styles.label}>Delete</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorDrafts;
