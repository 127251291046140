import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Icons } from 'pages/ClarityAI/Icons';
import { Input } from 'components';
import { Dots2 } from 'components/Icons';
import { LoadingOutlined } from '@ant-design/icons';

import { getAISessionsList } from 'store/selectors/clarityAI';
import { createAISessionEffect } from 'store/effects/clarityAI';

import styles from './styles.module.scss';

const HistoryItem = ({ title = '', active = false, onClick }) => {
  return (
    <div className={classNames(styles.historyItem, { [styles.active]: active })} onClick={onClick}>
      <span>{title}</span>
      <Dots2 />
    </div>
  );
};

export const History = ({ activeSessionTabId, setActiveSessionTabId, fetchSessions }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(getAISessionsList);
  const [loading, setLoading] = useState(false);

  const createNewSession = () => {
    setLoading(true);
    dispatch(
      createAISessionEffect({}, {}, () => {
        fetchSessions(true);
        setLoading(false);
      }),
    );
  };

  return (
    <div className={styles.historySection}>
      <div className={styles.header}>
        <Icons variant={Icons.EXPAND_COLLAPSE} className={styles.icon} />
        {loading ? (
          <LoadingOutlined />
        ) : (
          <Icons variant={Icons.EDIT} onClick={() => createNewSession()} className={styles.icon} />
        )}
      </div>
      <div className={styles.searchContainer}>
        <Input variant={Input.LIGHT_ROUNDED} type="search" />
      </div>
      <div className={styles.historyContainer}>
        <p className={styles.title}>History</p>
        {data?.map(({ sessionId, title }, idx) => (
          <HistoryItem
            key={idx}
            title={title || 'New Chat'}
            active={sessionId == activeSessionTabId}
            onClick={() => setActiveSessionTabId(sessionId)}
          />
        ))}
      </div>
    </div>
  );
};
