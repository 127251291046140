import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const NoTemplatesFound = (props) => {
  const { className, templatesName, placeholderIcon, placeholderText } = props;

  return (
    <div className={classNames(styles.noTemplates, className)}>
      <div className={styles.noTemplatesIcon}>
        {placeholderIcon || (
          <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="118"
              height="118"
              rx="59"
              fill="white"
              stroke="#ADADAD"
              strokeWidth="2"
            />
            <path
              d="M36.625 42.125L40.75 46.25L47.625 39.375"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.625 58.625L40.75 62.75L47.625 55.875"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.625 75.125L40.75 79.25L47.625 72.375"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M57.25 43.5H82"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M57.25 60H82"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M57.25 76.5H82"
              stroke="#ADADAD"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <div>
        {placeholderText ||
          `You don’t have any 
        ${templatesName || ''} 
        templates`}
      </div>
    </div>
  );
};

NoTemplatesFound.propTypes = {
  className: PropTypes.string,
  templatesName: PropTypes.string,
  placeholderText: PropTypes.string,
  placeholderIcon: PropTypes.element,
};

NoTemplatesFound.defaultProps = {
  className: '',
  templatesName: '',
  placeholderText: '',
  placeholderIcon: undefined,
};
