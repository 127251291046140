const UploadIcon = ({ color = '#262626' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.00069 17.9992C5.75297 17.9992 4.55635 17.5251 3.67407 16.6812C2.7918 15.8373 2.29614 14.6927 2.29614 13.4992C2.29614 12.3057 2.7918 11.1612 3.67407 10.3172C4.55635 9.47332 5.75297 8.99922 7.00069 8.99922C7.29537 7.6864 8.15745 6.5327 9.39728 5.79193C10.0112 5.42514 10.6993 5.17077 11.4225 5.04333C12.1456 4.9159 12.8895 4.9179 13.6118 5.04922C14.3341 5.18054 15.0205 5.43861 15.632 5.8087C16.2434 6.17878 16.7679 6.65364 17.1755 7.20615C17.583 7.75865 17.8656 8.378 18.0072 9.02881C18.1488 9.67963 18.1466 10.3492 18.0007 10.9992H19.0007C19.9289 10.9992 20.8192 11.368 21.4756 12.0243C22.1319 12.6807 22.5007 13.571 22.5007 14.4992C22.5007 15.4275 22.1319 16.3177 21.4756 16.9741C20.8192 17.6305 19.9289 17.9992 19.0007 17.9992H18.0007"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15L12 12L15 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
