import classNames from 'classnames';
import { getValidationMessage, isSafari } from '../helper';
import styles from './style.module.scss';
import { Fragment } from 'react';
import { InfoTooltip } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/InfoTooltip';

export const RadioField = (props) => {
  const {
    itemID,
    onClick,
    fieldProps,
    inputFieldProps,
    fieldStyle,
    inputFieldStyle,
    isDisabledGroup,
    validation,
  } = props;

  const { defaultChecked } = fieldProps;
  const { onChange, ...restFieldProps } = inputFieldProps;

  const renderRadioField = () => {
    return (
      <div className={classNames(styles['custom-radio'])} style={fieldStyle} onClick={onClick}>
        <input
          key={defaultChecked.toString()}
          itemID={itemID}
          style={inputFieldStyle}
          {...restFieldProps}
          disabled={isDisabledGroup}
        />
      </div>
    );
  };

  if (isDisabledGroup)
    return (
      <InfoTooltip
        className={styles.radioFieldTooltip}
        trigger="hover"
        text={getValidationMessage(validation)}
      >
        {renderRadioField()}
      </InfoTooltip>
    );

  return <Fragment>{renderRadioField()}</Fragment>;
};
