import { find } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'settings/navigation/routes';
import { useHistory } from 'react-router-dom';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { getUserRolesSelector } from 'store/selectors/user';
import {
  getRecommendedPartnersByAgentList,
  selectVendorDraftEditModeId,
  selectVendorEditModeId,
  showRequestQuoteBtnSelector,
} from 'store/selectors/partners';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import RequestCategoriesWrapper from '../Services/components/RequestCategoriesWrapper';
import { Button } from 'components';
import PartnersList from './components/PartnersList';
import RequestPartnersWrapper from './components/RequestPartnersWrapper';
import SelectedPartners from './components/SelectedPartners';
import { InviteVendorIcon } from './components/icons';
import { AddVendorModal } from './components/AddVendorModal';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { Role, TEAM_BASIC } from 'settings/constants/roles';
import styles from './styles.module.scss';
import { setVendorDraftEditModeId, setVendorEditModeId } from 'store/actions/partners';
import { ConciergeDefaultCategories, conciergeAgentDefaultItems } from 'pages/Services/constants';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import classNames from 'classnames';

// TODO: add request partners list
const ServicesCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getAllServicesCategoriesList);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const userRoles = useSelector(getUserRolesSelector);
  const showRequestQuoteBtn = useSelector(showRequestQuoteBtnSelector);
  const {
    areasOfOperation,
    category: categoryId,
    selectedTab,
  } = useSelector(getConciergeSearchSelector);
  const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState<boolean>(false);
  const vendorEditModeId = useSelector(selectVendorEditModeId);
  const vendorDraftEditModeId = useSelector(selectVendorDraftEditModeId);
  const isAgent = useMemo(() => userRoles.includes(Role.Agent), [userRoles]);
  const isClient = useMemo(() => userRoles.includes(Role.Client), [userRoles]);
  const history = useHistory();
  const list = useSelector(getRecommendedPartnersByAgentList);
  const [selectedPartnerId, setSelectedPartnerId] = useState(0);
  const [viewPartnerDetails, setViewPartnerDetails] = useState(false);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const [viewingFoundProfile, setViewingFoundProfile] = useState<boolean>(false);

  useEffect(() => {
    if (vendorEditModeId || vendorDraftEditModeId) setIsAddVendorModalOpen(true);
  }, [vendorEditModeId, vendorDraftEditModeId]);

  // Redirect to services page if user is client and has no areas of operation
  useEffect(() => {
    if (isClient && areasOfOperation?.length === 0) {
      history.push(routes.services);
    }
  }, [areasOfOperation, isClient]);

  const onRequestQuote = () => {
    dispatch(setMultipleRequestQuotesEffect({ modeEnable: !multiple?.modeEnable }));
  };
  const onRequestQuoteOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.REQUEST_QUOTE,
    onRequestQuote,
    {
      categoryId: categoryId,
    },
  );

  const categoryById = useMemo(() => {
    const allCategories = [...conciergeAgentDefaultItems, ...categories];
    return find(allCategories, ({ Id }) => Id === Number(categoryId));
  }, [categories, categoryId]);

  const showRequestQuoteButton = (): boolean => {
    if (!list?.length) return false;

    const haveRequestWorthyPartners = list.some(
      (partner) =>
        !partner?.isDraft &&
        !partner?.IsPublished &&
        !partner?.IsArchived &&
        partner?.IsActive &&
        partner?.ThirdParty?.User?.CompletedProfile,
    );
    return (
      showRequestQuoteBtn &&
      Number(categoryId) > ConciergeDefaultCategories.All &&
      haveRequestWorthyPartners
    );
  };

  return (
    <>
      <div
        className={classNames(
          styles.contentWrapper,
          isAgent && multiple?.modeEnable ? styles.contentWrapperMulti : null,
        )}
      >
        <div className={styles.col1}></div>
        <div className={styles.col2}>
          <RequestCategoriesWrapper className={styles.wrapper}>
            <RequestPartnersWrapper>
              <PartnersList category={categoryById} />
            </RequestPartnersWrapper>
          </RequestCategoriesWrapper>
        </div>
        <div className={styles.col3}>
          {isAgent && !multiple?.modeEnable ? (
            <div className={styles.btnWrapper}>
              {agentRole === TEAM_BASIC ? null : (
                <Button
                  onClick={() => setIsAddVendorModalOpen(true)}
                  className={styles.addVendorBtn}
                  titleClassName={styles.addVendorBtnTitle}
                  title="Add Vendor"
                  testid="add_vendor"
                  iconLeft={<InviteVendorIcon className={styles.inviteVendorIcon} />}
                />
              )}
              {showRequestQuoteButton() && (
                <Button
                  onClick={onRequestQuoteOrRequireEmailVerification}
                  className={styles.requestQuoteBtn}
                  title="Request Quote"
                  testid="request_quote"
                  disabled={selectedTab === 2}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {categoryById?.Id !== undefined && categoryById?.Id !== null && (
        <SelectedPartners categoryId={categoryById?.Id} categoryName={categoryById?.Title} />
      )}
      <PartnerProfile
        partnerId={selectedPartnerId}
        open={viewPartnerDetails}
        setModalVisible={setViewPartnerDetails}
        setSelectedPartnerId={setSelectedPartnerId}
        requestQuoteId={0}
        viewingFoundProfile={viewingFoundProfile}
        IsStrictlyProfile={false}
        onCancel={() => {
          setViewPartnerDetails(false);
          setSelectedPartnerId(0);
          setViewingFoundProfile(false);
        }}
      />
      {isAddVendorModalOpen && (
        <AddVendorModal
          open={isAddVendorModalOpen}
          handleClose={(partnerId = 0, foundProfile = false) => {
            setIsAddVendorModalOpen(false);
            dispatch(setVendorEditModeId(undefined));
            dispatch(setVendorDraftEditModeId(undefined));

            if (foundProfile) setViewingFoundProfile(true);

            if (partnerId) {
              setSelectedPartnerId(partnerId);
              setViewPartnerDetails(true);
            }
          }}
        />
      )}
    </>
  );
};

export default ServicesCategory;
