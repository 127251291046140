import React from 'react';
import { useSelector } from 'react-redux';

import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { Bookmark } from 'pages/Properties/LotiIcons';

import styles from './styles.module.scss';
import { TabButton } from 'pages/Properties/AgentFeed/components';

type Props = {
  isSavedSearchResult?: boolean;
  setShowBlurBg: Function;
  handleOpenSearch: (showSavedSearches: boolean) => void;
};

export const SavedSearches: React.FC<Props> = () => {
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);

  const TabButtonText = () => (
    <div className={styles.tabBtnText}>
      <span className={styles.text}>{selectedSearch?.name || 'None'}</span>
    </div>
  );

  return (
    <TabButton
      text={<TabButtonText />}
      icon={Bookmark}
      className={styles.savedSearchBtn}
      whiteLayerClassname={styles.whiteLayerClassname}
    />
  );
};
