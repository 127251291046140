import UsersOverview from 'pages/Dashboard/Users';
import { routes } from 'settings/navigation/routes';
import { SUPER_USER } from 'settings/constants/roles';
import Categories from 'pages/Dashboard/ServiceDirectory/Categories';
import { CategoryForm } from 'pages/Dashboard/ServiceDirectory/Categories/Category';
import Partners from 'pages/Dashboard/ServiceDirectory/Partners';
import { PartnerForm } from 'pages/Dashboard/ServiceDirectory/Partners/Partner';
import TaskCategories from 'pages/Dashboard/Transaction/TaskCategories';
import TransactionRoles from 'pages/Dashboard/Transaction/Roles';
import NotificationTemplates from 'pages/Dashboard/NotificationTemplates';
import { LoginLogs } from 'pages/Dashboard/LoginLogs';
import { PdfEditor } from 'pages/Dashboard/PdfEditor';
import MlsAgentsOverview from 'pages/Dashboard/MlsAgents';
import MlsDashboard from 'pages/Dashboard/MlsDashboard';
import { Transactions } from 'pages/Templates/Transactions';
import { Timelines } from 'pages/Templates/Timelines';
import { Tasks } from 'pages/Templates/Tasks';
import { AreaClassifier } from 'pages/Dashboard/AreaClassifier';
import Kits from 'pages/Dashboard/Kits';
import { TransactionTemplateView } from 'pages/Templates/Transactions/TransactionTemplateView';
import { TransactionTemplateCreate } from 'pages/Templates/Transactions/TransactionTemplateCreate';
import { TransactionTemplateEdit } from 'pages/Templates/Transactions/TransactionTemplateEdit';
import AuditEvents from 'pages/Dashboard/AuditEvents';
import SalesLead from 'pages/Dashboard/SalesLead';
import ServiceTags from 'pages/Dashboard/ServiceDirectory/ServiceTags';
import { ServiceTagForm } from 'pages/Dashboard/ServiceDirectory/ServiceTags/ServiceTag';
import Teams from 'pages/Dashboard/Teams';
import TeamDetails from 'pages/Dashboard/TeamDetails/TeamDetails';
import { MasterTransactions } from 'pages/Templates/MasterTransactions';
import { TeamTransactionTemplateCreate } from 'pages/Dashboard/TeamDetails/Components/Templates/TeamTransactionTemplateCreate';
import { TeamTransactionTemplateView } from 'pages/Dashboard/TeamDetails/Components/Templates/TeamTransactionTemplateView';
import { TeamTransactionTemplateEdit } from 'pages/Dashboard/TeamDetails/Components/Templates/TeamTransactionTemplateEdit';

export default [
  {
    path: routes.dashboardServiceDirectoryCategories,
    component: Categories,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryCategoryCreate,
    component: CategoryForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryCategory,
    component: Categories,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryCategoryEdit,
    component: CategoryForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryPartners,
    component: Partners,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryPartnerCreate,
    component: PartnerForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryPartnerEdit,
    component: PartnerForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryPartner,
    component: Partners,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTimelineTemplates,
    component: Timelines,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTaskTemplates,
    component: Tasks,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTransactionsTemplates,
    component: Transactions,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTransactionTemplateCreate,
    component: TransactionTemplateCreate,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTransactionTemplateView,
    component: TransactionTemplateView,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTransactionTemplateEdit,
    component: TransactionTemplateEdit,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardProjectsTemplates,
    component: Transactions,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardProjectTemplateCreate,
    component: TransactionTemplateCreate,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardProjectTemplateView,
    component: TransactionTemplateView,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardProjectTemplateEdit,
    component: TransactionTemplateEdit,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTransactionTaskCategories,
    component: TaskCategories,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTransactionRoles,
    component: TransactionRoles,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardUsers,
    component: UsersOverview,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardMlsAgents,
    component: MlsAgentsOverview,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardMls,
    component: MlsDashboard,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardNotificationTemplates,
    component: NotificationTemplates,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardLoginLogs,
    component: LoginLogs,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardPdfEditor,
    component: PdfEditor,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.areaClassifier,
    component: AreaClassifier,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.kits,
    component: Kits,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.events,
    component: AuditEvents,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.salesLead,
    component: SalesLead,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryServiceTags,
    component: ServiceTags,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryServiceTagCreate,
    component: ServiceTagForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardServiceDirectoryServiceTagEdit,
    component: ServiceTagForm,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.teams,
    component: Teams,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.teamDetais,
    component: TeamDetails,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardMasterTemplates,
    component: MasterTransactions,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTeamTransactionTemplateCreate,
    component: TeamTransactionTemplateCreate,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTeamTransactionTemplateView,
    component: TeamTransactionTemplateView,
    exact: true,
    roles: [SUPER_USER],
  },
  {
    path: routes.dashboardTeamTransactionTemplateEdit,
    component: TeamTransactionTemplateEdit,
    exact: true,
    roles: [SUPER_USER],
  },
];
