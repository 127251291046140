import React, { memo, useMemo } from 'react';

const Users = memo(({ className, color }: React.HTMLAttributes<HTMLDivElement>) =>
  useMemo(
    () => (
      <div className={className}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5C9 6.65685 7.65685 8 6 8C4.34315 8 3 6.65685 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5Z"
            stroke={color || 'white'}
            strokeWidth="2"
          />
          <path
            d="M10.5 2.04102C11.9189 2.27905 13 3.51304 13 4.99954C13 6.48603 11.9189 7.72002 10.5 7.95806"
            stroke={color || 'white'}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M1.5 13.75C1.78045 12.1474 3.06987 10.75 4.49991 10.75H7.5C8.93004 10.75 10.2195 12.1474 10.5 13.75"
            stroke={color || 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5 10.75C12.93 10.75 14.2195 12.1474 14.5 13.75"
            stroke={color || 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ),
    [],
  ),
);

export default Users;
