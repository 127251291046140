import { useMemo, useEffect, useState, useRef } from 'react';
import { routes } from 'settings/navigation/routes';
import { BackLink, PageNotFound, Spinner } from 'components';
import { cloneDeep, split } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationService } from 'services';
import { useBackPage } from 'hooks';
import styles from './styles.module.scss';
import ComparisonTable from './Components/Table';
import Avatar from './Components/Avatar';
import StickyHeader from './Components/StickyHeader';
import { getCompareProperties, getClientPreferencesForComparison } from '../../api/feed';
import classNames from 'classnames';
import { Footer } from '../../components-antd/Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getUserRolesMapSelector, getUserSelector } from 'store/selectors/user';
import WishList from './Components/WishList';
import Commute from './Components/Commute';
import Icons from './Components/Icons';
import PhotosSection from './Components/PhotosSection';
import SearchClient from './Components/SearchClient';
import { isEmpty } from 'lodash-es';
import {
  KeyDetailsDataType,
  FinancialDataType,
  GeneralInfoDataType,
  InteriorDetails,
  ExteriorDetails,
  WishListDataType,
  Destinations,
  PhotoCompareSectionDataType,
  CompareDataType,
  ClientSearchInstanceDataType,
  ClientPreferencesDataType,
  ComparisonStateDataType,
  PhotoLabel,
} from './DataTypes';
import { getAllProperyImageProcessesSelector } from 'store/selectors/feed';
import { initialImageAnalysisAction } from 'store/actions/feed';
import { initiateImageAnalysisEffect, setRecipientsEffect } from 'store/effects';
import ImageCompareStatus from './Components/ImageCompareStatus';
import { CommentsDrawer } from 'components';
import { openShareDrawerEffect, setSharePropertyEffect } from 'store/effects';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { useReactToPrint } from 'react-to-print';
import { formatNumber, formatUsd } from '../../helpers/formatters';
import {
  daysFromToday,
  filterImagesOnlyFromPhotoUrls,
  getBathsTotalNum,
  getDaysOnMarket,
  processMediaToOrderedImages,
} from 'helpers/utils';
import { getMatchedCriteriasAndScoreByPropertyId } from 'store/selectors/feedv3';
import HighlightModal from 'pages/Properties/ListingDetail/components/HomeActions/HighlightModal';

type ListingsPropsType = {};

const locationService = new LocationService();

const roomVICodeToCompareLabelMap = {
  PH_RT_AERIAL_VIEW: 'Aerial',
  PH_RT_ATTIC: 'Attic',
  PH_RT_BACK_OF_STRUCTURE: 'Exterior',
  PH_RT_BALCONY: 'Outdoor',
  PH_RT_BAR: 'Flex Space(s)',
  PH_RT_BASEMENT: 'Basement',
  PH_RT_BASKETBALL_COURT: 'Outdoor',
  PH_RT_BATHROOM: 'Bathrooms',
  PH_RT_BEDROOM: 'Bedrooms',
  PH_RT_BONUS_ROOM: 'Flex Space(s)',
  PH_RT_CLOSET: 'Closets',
  PH_RT_COMMUNITY: 'Outdoor',
  PH_RT_DECK: 'Outdoor',
  PH_RT_DINING_AREA: 'Dining Area(s)',
  PH_RT_DOCK: 'Outdoor',
  PH_RT_ENTRANCE_FOYER: 'Foyer',
  PH_RT_ENTRY: 'Entry',
  PH_RT_EXERCISE_ROOM: 'Flex Space(s)',
  PH_RT_FENCE: 'Outdoor',
  PH_RT_FLOOR_PLAN: 'Floor Plan',
  PH_RT_FRONT_OF_STRUCTURE: 'Exterior',
  PH_RT_GAME_ROOM: 'Flex Space(s)',
  PH_RT_GARAGE: 'Garage',
  PH_RT_GYM: 'Flex Space(s)',
  PH_RT_HALLWAY: 'Hallway',
  PH_RT_KITCHEN: 'Kitchen',
  PH_RT_LAUNDRY: 'Laundry',
  PH_RT_LIVING_ROOM: 'Living Area(s)',
  PH_RT_LOBBY: 'Other',
  PH_RT_MAP: 'Other',
  PH_RT_MEDIA_ROOM: 'Flex Space(s)',
  PH_RT_MUD_ROOM: 'Mud Room',
  PH_RT_OFFICE: 'Flex Space(s)',
  PH_RT_OTHER: 'Other',
  PH_RT_OUT_BUILDINGS: 'Outdoor',
  PH_RT_PANTRY: 'Kitchen',
  PH_RT_PARKING: 'Garage',
  PH_RT_PATIO: 'Outdoor',
  PH_RT_PLAYGROUND: 'Outdoor',
  PH_RT_POOL: 'Outdoor',
  PH_RT_RECEPTION: 'Other',
  PH_RT_SAUNA: 'Other',
  PH_RT_SIDE_OF_STRUCTURE: 'Exterior',
  PH_RT_SITTING_ROOM: 'Living Area(s)',
  PH_RT_STABLE: 'Outdoor',
  PH_RT_STAIRS: 'Stairs',
  PH_RT_STORAGE: 'Other',
  PH_RT_SUN_ROOM: 'Other',
  PH_RT_TENNIS_COURT: 'Outdoor',
  PH_RT_UTILITY_ROOM: 'Other',
  PH_RT_VIEW: 'View',
  PH_RT_WALK_IN_CLOSETS: 'Closets',
  PH_RT_WINE_CELLAR: 'Other',
  PH_RT_YARD: 'Outdoor',
  PH_RT_ATRIUM: 'Other',
  PH_RT_BARN: 'Outdoor',
  PH_RT_BREAKFAST_AREA: 'Dining Area(s)',
  PH_RT_COURTYARD: 'Outdoor',
  PH_RT_DEN: 'Living Area(s)',
  PH_RT_DINING_ROOM: 'Dining Area(s)',
  PH_RT_FAMILY_ROOM: 'Living Area(s)',
  PH_RT_FIREPLACE: 'Fireplace',
  PH_RT_GARDEN: 'Outdoor',
  PH_RT_GOLF_COURSE: 'Outdoor',
  PH_RT_GREAT_ROOM: 'Living Area(s)',
  PH_RT_GUEST_QUARTERS: 'Bedrooms',
  PH_RT_HOBBY_ROOM: 'Flex Space(s)',
  PH_RT_INLAW: 'Bedrooms',
  PH_RT_LAKE: 'Outdoor',
  PH_RT_LIBRARY: 'Flex Space(s)',
  PH_RT_LOADING_DOCK: 'Other',
  PH_RT_LOFT: 'Flex Space(s)',
  PH_RT_LOT: 'Other',
  PH_RT_MASTER_BATHROOM: 'Bathrooms',
  PH_RT_MASTER_BEDROOM: 'Bedrooms',
  PH_RT_NURSERY: 'Bedrooms',
  PH_RT_PIER: 'Outdoor',
  PH_RT_PLAT_MAP: 'Plat Map',
  PH_RT_POND: 'Outdoor',
  PH_RT_RECREATION_ROOM: 'Flex Space(s)',
  PH_RT_SHOWROOM: 'Other',
  PH_RT_SPA: 'Other',
  PH_RT_STUDIO: 'Flex Space(s)',
  PH_RT_STUDY: 'Flex Space(s)',
  PH_RT_WATERFRONT: 'Outdoor',
  PH_RT_WORKSHOP: 'Flex Space(s)',
};

function Listings(props: ListingsPropsType) {
  let data: CompareDataType = {
    generalInfo: [],
    keyDetails: [],
    financial: [],
    needs: [],
    wants: [],
    interiorDetails: [],
    exteriorDetails: [],
    destinations: [],
    livingAreaPhotoUrls: [],
    kitchenPhotoUrls: [],
    bedroomsPhotoUrls: [],
    bathroomsPhotoUrls: [],
    otherRoomsPhotoUrls: [],
    outdoorPhotoUrls: [],
    flexSpacesPhotoUrls: [],
    aerialPhotoUrls: [],
    atticPhotoUrls: [],
    exteriorPhotoUrls: [],
    foyerPhotoUrls: [],
    entryPhotoUrls: [],
    floorPlanPhotoUrls: [],
    garagePhotoUrls: [],
    hallwayPhotoUrls: [],
    laundryPhotoUrls: [],
    mudRoomPhotoUrls: [],
    stairsPhotoUrls: [],
    viewPhotoUrls: [],
    platMapPhotoUrls: [],
    fireplacePhotoUrls: [],
    showEmptyPhotoCompare: false,
  };

  let preferencesData: ClientPreferencesDataType = {
    needs: [],
    wants: [],
    destinations: [],
  };

  const reInitializeData = () => {
    data = {
      generalInfo: [],
      keyDetails: [],
      financial: [],
      needs: [],
      wants: [],
      interiorDetails: [],
      exteriorDetails: [],
      destinations: [],
      livingAreaPhotoUrls: [],
      kitchenPhotoUrls: [],
      bedroomsPhotoUrls: [],
      bathroomsPhotoUrls: [],
      otherRoomsPhotoUrls: [],
      outdoorPhotoUrls: [],
      flexSpacesPhotoUrls: [],
      aerialPhotoUrls: [],
      atticPhotoUrls: [],
      exteriorPhotoUrls: [],
      foyerPhotoUrls: [],
      entryPhotoUrls: [],
      floorPlanPhotoUrls: [],
      garagePhotoUrls: [],
      hallwayPhotoUrls: [],
      laundryPhotoUrls: [],
      mudRoomPhotoUrls: [],
      stairsPhotoUrls: [],
      viewPhotoUrls: [],
      platMapPhotoUrls: [],
      fireplacePhotoUrls: [],
      showEmptyPhotoCompare: false,
    };
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  locationService.setLocation(location);
  const pdfRef = useRef(null);
  const query = locationService.setLocation(location).getQuery();
  const user = useSelector(getUserSelector);
  const { pathname } = location;
  const activeTab = query?.activeTab === 'undefined' ? 1 : query?.activeTab;
  const url = pathname;
  const feedBackUrl = `${routes.feed}${activeTab ? `?activeTab=${activeTab}` : ''}`;
  const searchBackUrl = `${routes.feed}${activeTab ? `?activeTab=${activeTab}` : ''}`;
  const [loading, setLoading] = useState<boolean>(true);
  const [showTopLabels, setShowTopLabels] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [photosToCompareExist, setPhotosToCompareExist] = useState<boolean>(true);
  const [hideFields, setHideFields] = useState<boolean>(false);
  const [clientPreferencesLoading, setClientPreferencesLoading] = useState<boolean>(false);
  const [clientPreferencesError, setClientPreferencesError] = useState<boolean>(false);
  const [agentClientSearchInstanceId, setAgentClientSearchInstanceId] = useState<number>(0);
  const [starredFieldsCount, setStarredFieldsCount] = useState<number>(0);
  const [starredFields, setStarredFields] = useState<string[]>([]);
  const [compareData, setCompareData] = useState<CompareDataType>(data);
  const [clientPreferencesData, setClientPreferencesData] =
    useState<ClientPreferencesDataType>(preferencesData);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const imageProcesses = useSelector(getAllProperyImageProcessesSelector);
  const [allImageProcessed, setAllImageProcessed] = useState(false);
  const [listingsResponse, setListingsResponse] = useState<any>([]);
  const [comparisonState, setComparisonState] = useState<ComparisonStateDataType>(
    ComparisonStateDataType.details,
  );
  //=================================================================> Search Component States
  const [agentAddClientClicked, setAgentAddClientClicked] = useState<boolean>(false);
  const [agentLoading, setAgentLoading] = useState<boolean>(true);
  const [agentError, setAgentError] = useState<boolean>(false);
  const [agentShowDropdown, setAgentShowDropdown] = useState<boolean>(false);
  const [agentSelectedClient, setAgentSelectedClient] =
    useState<ClientSearchInstanceDataType | null>(null);
  const [agentClientSearchInstances, setAgentClientSearchInstances] = useState<
    ClientSearchInstanceDataType[]
  >([]);
  const [agentFilteredClientSearchInstances, setAgentFilteredClientSearchInstances] = useState<
    ClientSearchInstanceDataType[]
  >([]);

  const matchedCriteriasMap = useSelector(getMatchedCriteriasAndScoreByPropertyId);

  const agentAddClientClickedSetter = (obj: boolean) => {
    setAgentAddClientClicked(obj);
  };
  const agentLoadingSetter = (obj: boolean) => {
    setAgentLoading(obj);
  };
  const agentErrorSetter = (obj: boolean) => {
    setAgentError(obj);
  };
  const agentShowDropdownSetter = (obj: boolean) => {
    setAgentShowDropdown(obj);
  };
  const agentSelectedClientSetter = (obj: ClientSearchInstanceDataType | null) => {
    setAgentSelectedClient(obj);
  };
  const agentClientSearchInstancesSetter = (obj: ClientSearchInstanceDataType[]) => {
    setAgentClientSearchInstances(obj);
  };
  const agentFilteredClientSearchInstancesSetter = (obj: ClientSearchInstanceDataType[]) => {
    setAgentFilteredClientSearchInstances(obj);
  };
  //=================================================================> Search Component States

  const { backUrl, isFeed } = useMemo(() => {
    const isItFeed = `${routes.properties}/${split(url, '/')[2]}` === routes.feed;
    return {
      isFeed: isItFeed,
      backUrl: isItFeed ? feedBackUrl : searchBackUrl,
    };
  }, [url]);

  const [onBack] = useBackPage({ defaultBackUrl: backUrl });

  function removeDuplicates(array: string[]) {
    try {
      if (array && array.length) {
        return array.filter((value, index) => array.indexOf(value) === index);
      }
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }

  const fetchPropertyListings = async () => {
    try {
      let ids = query?.ids.split(',');
      if (ids && ids.length > 1 && ids[0] && ids[1]) {
        if (ids.length > 5) {
          setError(true);
          setLoading(false);
        } else {
          ids = removeDuplicates(ids);
          if (ids.length > 1) {
            const response = await getCompareProperties({ ids });
            if (response.status === 200) {
              /**
               * Some matchscores are not calculated immediately because of asynchronous nature of the code
               * So we are levaraging dynamic search criteria to display wants and needs compare
               */
              setListingsResponse(
                response?.data?.result?.map((property) => ({
                  ...property,
                  MatchedCriterias:
                    matchedCriteriasMap?.[property.Id]?.MatchedCriterias ||
                    property.MatchedCriterias ||
                    [],
                  MatchScore:
                    matchedCriteriasMap?.[property.Id]?.MatchScore || property.MatchScore || 0,
                })),
              );
            } else {
              setError(true);
            }
            setLoading(false);
          } else {
            setError(true);
            setLoading(false);
          }
        }
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const structureData = (properties) => {
    try {
      reInitializeData();
      if (properties && properties.length) {
        for (let i = 0; i < properties.length; i++) {
          const photos = !isEmpty(properties[i]?.Media)
            ? processMediaToOrderedImages(properties[i]?.Media, properties[i]?.Market)
            : filterImagesOnlyFromPhotoUrls(properties[i]?.PhotoUrls, properties[i]?.Market);
          //=================================================================> General Info
          let generalInfoObj: GeneralInfoDataType = {
            Id: properties[i]?.Id,
            photo: photos[0],
            photoCount: properties[i]?.Media?.length ? properties[i]?.Media?.length : 0,
            address: properties[i]?.Address?.Line1 ? properties[i]?.Address?.Line1 : '',
            city: properties[i]?.Address?.City ? properties[i]?.Address?.City : '',
            state: properties[i]?.Address?.State ? properties[i]?.Address?.State : '',
            zip: properties[i]?.Address?.Zip ? properties[i].Address?.Zip : '',
          };
          data.generalInfo.push(generalInfoObj);
          //=================================================================> Photos
          let emptyPics: PhotoCompareSectionDataType = {
            photoData: [{ url: 'empty', labels: [], topLabels: [] }],
          };

          const categorizedPhotos: { [key: string]: PhotoCompareSectionDataType } = {};

          Object.values(roomVICodeToCompareLabelMap).forEach((category) => {
            categorizedPhotos[category] = { photoData: [] };
          });
          if (properties[i]?.PropertyImagesVisualInsights?.Images) {
            for (let j = 0; j < properties[i]?.PropertyImagesVisualInsights?.Images?.length; j++) {
              const visualInsights = properties[i].PropertyImagesVisualInsights.Images[j];
              const url = visualInsights.URL;
              const featureVICodes = visualInsights.FeatureVICodes;
              const predictedLabels = visualInsights.RoomVICodes;

              const photoEntry = {
                url,
                labels: predictedLabels,
                topLabels: featureVICodes,
              };

              predictedLabels.forEach((label) => {
                const category = roomVICodeToCompareLabelMap[label];
                if (category && categorizedPhotos[category]) {
                  categorizedPhotos[category].photoData.push(photoEntry);
                }
              });
            }

            Object.keys(categorizedPhotos).forEach((category) => {
              if (categorizedPhotos[category].photoData.length === 0) {
                categorizedPhotos[category] = emptyPics;
              }
            });

            data.livingAreaPhotoUrls.push(categorizedPhotos['Living Area(s)']);
            data.kitchenPhotoUrls.push(categorizedPhotos['Kitchen']);
            data.bedroomsPhotoUrls.push(categorizedPhotos['Bedrooms']);
            data.bathroomsPhotoUrls.push(categorizedPhotos['Bathrooms']);
            data.otherRoomsPhotoUrls.push(categorizedPhotos['Other']);
            data.outdoorPhotoUrls.push(categorizedPhotos['Outdoor']);
            data.flexSpacesPhotoUrls.push(categorizedPhotos['Flex Space(s)']);
            data.aerialPhotoUrls.push(categorizedPhotos['Aerial']);
            data.atticPhotoUrls.push(categorizedPhotos['Attic']);
            data.exteriorPhotoUrls.push(categorizedPhotos['Exterior']);
            data.foyerPhotoUrls.push(categorizedPhotos['Foyer']);
            data.entryPhotoUrls.push(categorizedPhotos['Entry']);
            data.floorPlanPhotoUrls.push(categorizedPhotos['Floor Plan']);
            data.garagePhotoUrls.push(categorizedPhotos['Garage']);
            data.hallwayPhotoUrls.push(categorizedPhotos['Hallway']);
            data.laundryPhotoUrls.push(categorizedPhotos['Laundry']);
            data.mudRoomPhotoUrls.push(categorizedPhotos['Mud Room']);
            data.stairsPhotoUrls.push(categorizedPhotos['Stairs']);
            data.viewPhotoUrls.push(categorizedPhotos['View']);
            data.platMapPhotoUrls.push(categorizedPhotos['Plat Map']);
            data.fireplacePhotoUrls.push(categorizedPhotos['Fireplace']);
          }

          //=================================================================> Key Details Section
          let keyDetailsObj: KeyDetailsDataType = {
            price: properties[i]?.SellingPrice
              ? '$' + properties[i]?.SellingPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : '-',
            status: properties[i]?.ActivityStatus ? properties[i].ActivityStatus : '-',
            propertyType: properties[i]?.HomeType
              ? properties[i].HomeType.replace(/([A-Z])/g, ' $1')
              : '-',
            daysOnMarket: getDaysOnMarket(properties[i]) ?? '-',
            beds: properties[i]?.NumBeds ? properties[i].NumBeds.toString() : '-',
            baths: properties[i]?.NumBathsTotal
              ? (getBathsTotalNum(properties[i].NumBathsTotal.toString()) as string)
              : '-',
            size: properties[i]?.SquareFeet
              ? properties[i].SquareFeet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' sq ft'
              : '-',
            yearBuilt: properties[i]?.YearBuilt ? properties[i].YearBuilt.toString() : '-',
          };
          data.keyDetails.push(keyDetailsObj);
          //=================================================================> Financial Section
          let financialObj: FinancialDataType = {
            pricePerSqFt:
              properties[i]?.SellingPrice && properties[i]?.SquareFeet
                ? formatUsd(properties[i].SellingPrice / properties[i].SquareFeet, {
                    maximumFractionDigits: 0,
                  }).toString()
                : '-',
            hoaDue:
              properties[i]?.AssociationInfo && properties[i]?.AssociationInfo?.Fee
                ? '$' +
                  properties[i]?.AssociationInfo?.Fee?.toString() +
                  (properties[i]?.AssociationInfo?.FeeFrequency
                    ? ' (' + properties[i]?.AssociationInfo?.FeeFrequency + ')'
                    : '')
                : '-',
            duesInclude:
              properties[i]?.AssociationInfo &&
              properties[i]?.AssociationInfo?.Fee &&
              properties[i]?.AssociationInfo?.FeaturesIncludedInFee
                ? properties[i]?.AssociationInfo?.FeaturesIncludedInFee?.replaceAll('"', '')
                    .replaceAll('\\', '')
                    .split(',')
                : ['-'],
            propertyTax: properties[i]?.TaxAmount
              ? '$' +
                (properties[i]?.TaxAmount?.toString() +
                  (properties[i]?.TaxYear ? ' (' + properties[i]?.TaxYear?.toString() + ')' : ''))
              : '-',
          };
          data?.financial?.push(financialObj);
          //=================================================================> Wish List Section
          if (properties[i]?.MatchedCriterias) {
            let needsObj: WishListDataType = {
              wishListData: [],
            };
            let wantsObj: WishListDataType = {
              wishListData: [],
            };
            //-------------------> Amenities
            if (
              properties[i]?.MatchedCriterias?.Amenities &&
              properties[i]?.MatchedCriterias?.Amenities?.length
            ) {
              for (let j = 0; j < properties[i]?.MatchedCriterias?.Amenities?.length; j++) {
                if (
                  properties[i]?.MatchedCriterias?.Amenities[j]?.Importance &&
                  properties[i]?.MatchedCriterias?.Amenities[j]?.Importance == 'Must'
                ) {
                  needsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.Amenities[j]?.Feature,
                    importance: properties[i]?.MatchedCriterias?.Amenities[j]?.Importance,
                    matchLevel: properties[i]?.MatchedCriterias?.Amenities[j]?.MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.Amenities[j]?.Feature,
                    importance: properties[i]?.MatchedCriterias?.Amenities[j]?.Importance,
                    matchLevel: properties[i]?.MatchedCriterias?.Amenities[j]?.MatchLevel,
                  });
                }
              }
            }
            //-------------------> Home Features
            if (
              properties[i]?.MatchedCriterias?.HomeFeatures &&
              properties[i]?.MatchedCriterias?.HomeFeatures?.length
            ) {
              for (let j = 0; j < properties[i]?.MatchedCriterias?.HomeFeatures?.length; j++) {
                if (
                  properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.Importance &&
                  properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.Importance == 'Must'
                ) {
                  needsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.Feature,
                    importance: properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.Importance,
                    matchLevel: properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.Feature,
                    importance: properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.Importance,
                    matchLevel: properties[i]?.MatchedCriterias?.HomeFeatures?.[j]?.MatchLevel,
                  });
                }
              }
            }
            //-------------------> Kitchen Features
            if (
              properties[i]?.MatchedCriterias?.KitchenFeatures &&
              properties[i]?.MatchedCriterias?.KitchenFeatures?.length
            ) {
              for (let j = 0; j < properties[i]?.MatchedCriterias?.KitchenFeatures?.length; j++) {
                if (
                  properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.Importance &&
                  properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.Importance == 'Must'
                ) {
                  needsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.Feature,
                    importance: properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.Importance,
                    matchLevel: properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.Feature,
                    importance: properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.Importance,
                    matchLevel: properties[i]?.MatchedCriterias?.KitchenFeatures?.[j]?.MatchLevel,
                  });
                }
              }
            }
            //-------------------> Neighborhood Features
            if (
              properties[i]?.MatchedCriterias?.NeighborhoodFeatures &&
              properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.length
            ) {
              for (
                let j = 0;
                j < properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.length;
                j++
              ) {
                if (
                  properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.Importance == 'Must'
                ) {
                  needsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.Feature,
                    importance:
                      properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.Importance,
                    matchLevel:
                      properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.Feature,
                    importance:
                      properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.Importance,
                    matchLevel:
                      properties[i]?.MatchedCriterias?.NeighborhoodFeatures?.[j]?.MatchLevel,
                  });
                }
              }
            }
            data?.needs?.push(needsObj);
            data?.wants?.push(wantsObj);
            //=================================================================> Exterior
          }
          let interiorObj: InteriorDetails = {
            livingRoom: [],
            kitchen: [],
            primaryBedroom: [],
            primaryBathroom: [],
            additionalRoom: [],
            interiorFeatures: [],
            equipment: [],
          };
          if (properties[i]?.Interior) {
            //-------------------> Living Room
            if (
              properties[i]?.Interior.Rooms &&
              properties[i]?.Interior?.Rooms?.LivingRoom &&
              properties[i]?.Interior?.Rooms?.LivingRoom?.Size
            ) {
              interiorObj?.livingRoom?.push(
                'Size: ' + properties[i]?.Interior?.Rooms?.LivingRoom?.Size,
              );
              if (properties[i]?.Interior?.Rooms?.LivingRoom?.Level) {
                interiorObj?.livingRoom?.push(
                  'On ' + properties[i]?.Interior?.Rooms?.LivingRoom?.Level + ' Level',
                );
              }
              if (properties[i]?.Interior?.Rooms?.LivingRoom?.Flooring) {
                interiorObj?.livingRoom?.push(
                  properties[i]?.Interior?.Rooms?.LivingRoom?.Flooring + ' Flooring',
                );
              }
            } else {
              interiorObj?.livingRoom?.push('-');
            }
            //-------------------> Kitchen
            if (
              properties[i]?.Interior?.Rooms &&
              properties[i]?.Interior?.Rooms?.Kitchen &&
              properties[i]?.Interior?.Rooms?.Kitchen?.Size
            ) {
              interiorObj?.kitchen?.push('Size: ' + properties[i]?.Interior?.Rooms?.Kitchen?.Size);
              if (properties[i]?.Interior?.Rooms?.Kitchen?.Level) {
                interiorObj?.kitchen?.push(
                  'On ' + properties[i]?.Interior?.Rooms?.Kitchen?.Level + ' Level',
                );
              }
              if (properties[i]?.Interior?.Rooms?.Kitchen?.Flooring) {
                interiorObj?.kitchen?.push(
                  properties[i]?.Interior?.Rooms?.Kitchen?.Flooring + ' Flooring',
                );
              }
            } else {
              interiorObj?.kitchen?.push('-');
            }
            //-------------------> Primary Bedroom
            if (
              properties[i]?.Interior.Rooms &&
              properties[i]?.Interior?.Rooms?.MasterBedroom &&
              properties[i]?.Interior?.Rooms?.MasterBedroom?.Size
            ) {
              interiorObj?.primaryBedroom?.push(
                'Size: ' + properties[i]?.Interior?.Rooms?.MasterBedroom?.Size,
              );
              if (properties[i]?.Interior?.Rooms?.MasterBedroom?.Level) {
                interiorObj?.primaryBedroom?.push(
                  'On ' + properties[i]?.Interior?.Rooms?.MasterBedroom?.Level + ' Level',
                );
              }
              if (properties[i]?.Interior?.Rooms?.MasterBedroom?.Flooring) {
                interiorObj?.primaryBedroom?.push(
                  properties[i]?.Interior?.Rooms?.MasterBedroom?.Flooring + ' Flooring',
                );
              }
            } else if (
              properties[i]?.Interior.Rooms &&
              properties[i]?.Interior?.Rooms?.PrimaryBedroom &&
              properties[i]?.Interior?.Rooms?.PrimaryBedroom?.Size
            ) {
              interiorObj?.primaryBedroom?.push(
                'Size: ' + properties[i]?.Interior?.Rooms?.PrimaryBedroom?.Size,
              );
              if (properties[i]?.Interior?.Rooms?.PrimaryBedroom?.Level) {
                interiorObj?.primaryBedroom?.push(
                  'On ' + properties[i]?.Interior?.Rooms?.PrimaryBedroom?.Level + ' Level',
                );
              }
              if (properties[i]?.Interior?.Rooms?.PrimaryBedroom?.Flooring) {
                interiorObj?.primaryBedroom?.push(
                  properties[i]?.Interior?.Rooms?.PrimaryBedroom?.Flooring + ' Flooring',
                );
              }
            } else {
              interiorObj?.primaryBedroom.push('-');
            }
            //-------------------> Primary Bathroom
            if (
              properties[i]?.Interior?.Rooms &&
              properties[i]?.Interior?.Rooms?.Bathroom &&
              (properties[i]?.Interior?.Rooms?.Bathroom?.Size ||
                properties[i]?.Interior?.Rooms?.Bathroom?.Features)
            ) {
              if (properties[i]?.Interior?.Rooms?.Bathroom?.Size) {
                interiorObj?.primaryBathroom?.push(
                  'Size: ' + properties[i]?.Interior?.Rooms?.Bathroom?.Size,
                );
              }
              if (properties[i]?.Interior?.Rooms?.Bathroom?.Level) {
                interiorObj?.primaryBathroom?.push(
                  'On ' + properties[i]?.Interior?.Rooms?.Bathroom?.Level + ' Level',
                );
              }
              if (properties[i]?.Interior?.Rooms?.Bathroom?.Flooring) {
                interiorObj?.primaryBathroom?.push(
                  properties[i]?.Interior?.Rooms?.Bathroom?.Flooring + ' Flooring',
                );
              }
              if (properties[i]?.Interior?.Rooms?.Bathroom?.Features?.length) {
                let featuresArray = properties[i]?.Interior?.Rooms?.Bathroom?.Features?.split(',');
                interiorObj.primaryBathroom = interiorObj?.primaryBathroom?.concat(featuresArray);
              }
            } else {
              interiorObj?.primaryBathroom?.push('-');
            }
            //-------------------> Additional Room
            if (
              properties[i]?.Interior?.Rooms &&
              properties[i]?.Interior?.Rooms?.Bedroom2 &&
              properties[i]?.Interior?.Rooms?.Bedroom2?.Size
            ) {
              interiorObj.additionalRoom.push(
                'Size: ' + properties[i]?.Interior?.Rooms?.Bedroom2?.Size,
              );
              if (properties[i]?.Interior?.Rooms?.Bedroom2?.Level) {
                interiorObj.additionalRoom.push(
                  'On ' + properties[i]?.Interior?.Rooms?.Bedroom2?.Level + ' Level',
                );
              }
              if (properties[i]?.Interior?.Rooms?.Bedroom2?.Flooring) {
                interiorObj.additionalRoom.push(
                  properties[i]?.Interior?.Rooms?.Bedroom2?.Flooring + ' Flooring',
                );
              }
            } else {
              interiorObj?.additionalRoom?.push('-');
            }
            //-------------------> Interior Features
            if (
              properties[i]?.Interior?.AdditionalRooms ||
              (properties[i]?.Interior?.OtherFeatures &&
                properties[i]?.Interior?.OtherFeatures?.length)
            ) {
              if (properties[i]?.Interior?.AdditionalRooms) {
                let arr = properties[i]?.Interior?.AdditionalRooms?.replaceAll('"', '').split(',');
                interiorObj.interiorFeatures = interiorObj?.interiorFeatures?.concat(arr);
              }
              if (
                properties[i]?.Interior?.OtherFeatures &&
                properties[i]?.Interior?.OtherFeatures?.length
              ) {
                for (let k = 0; k < properties[i]?.Interior?.OtherFeatures?.length; k++) {
                  if (properties[i]?.Interior?.OtherFeatures[k]) {
                    let arr = properties[i]?.Interior?.OtherFeatures[k]
                      .replaceAll('"', '')
                      .replaceAll('\\', '')
                      .split(',');
                    interiorObj.interiorFeatures = interiorObj?.interiorFeatures?.concat(arr);
                  }
                }
              }
            } else {
              interiorObj?.interiorFeatures?.push('-');
            }
            //-------------------> Equipment
            if (properties[i]?.Interior?.Equipment && properties[i]?.Interior?.Equipment?.length) {
              if (typeof properties[i].Interior.Equipment === 'string') {
                let arr = properties[i].Interior.Equipment.replaceAll('"', '')
                  .replaceAll('\\', '')
                  .split(',');
                interiorObj.equipment = interiorObj?.equipment?.concat(arr);
              } else if (Array.isArray(properties[i].Interior.Equipment)) {
                for (let k = 0; k < properties[i]?.Interior?.Equipment?.length; k++) {
                  if (properties[i]?.Interior?.Equipment?.[k]) {
                    let arr = properties[i].Interior.Equipment[k]
                      .replaceAll('"', '')
                      .replaceAll('\\', '')
                      .split(',');
                    interiorObj.equipment = interiorObj?.equipment?.concat(arr);
                  }
                }
                if (!interiorObj?.equipment || !interiorObj?.equipment?.length)
                  interiorObj?.equipment?.push('-');
              }
            } else {
              interiorObj?.equipment?.push('-');
            }
          } else {
            interiorObj?.livingRoom?.push('-');
            interiorObj?.kitchen?.push('-');
            interiorObj?.primaryBedroom?.push('-');
            interiorObj?.primaryBathroom?.push('-');
            interiorObj?.additionalRoom?.push('-');
            interiorObj?.interiorFeatures?.push('-');
            interiorObj?.equipment?.push('-');
          }
          data?.interiorDetails?.push(interiorObj);
          //=================================================================> Exterior
          let exteriorObj: ExteriorDetails = {
            features: [],
            exterior: [],
            lotDimensions: [],
            amenities: [],
          };
          if (properties[i]?.ExteriorAndLotDetails) {
            //-------------------> Exterior Features
            if (
              properties[i]?.ExteriorAndLotDetails?.ExteriorFeatures &&
              properties[i]?.ExteriorAndLotDetails?.ExteriorFeatures?.length
            ) {
              for (
                let k = 0;
                k < properties[i].ExteriorAndLotDetails.ExteriorFeatures.length;
                k++
              ) {
                if (properties[i]?.ExteriorAndLotDetails?.ExteriorFeatures?.[k]) {
                  let arr = properties[i].ExteriorAndLotDetails.ExteriorFeatures[k]
                    .replaceAll('"', '')
                    .split(',');
                  exteriorObj.features = exteriorObj.features.concat(arr);
                }
              }
            } else {
              exteriorObj?.features?.push('-');
            }
            //-------------------> Exterior
            if (properties[i]?.ExteriorAndLotDetails?.Exterior) {
              if (typeof properties[i]?.ExteriorAndLotDetails?.Exterior === 'string') {
                let arr = properties[i].ExteriorAndLotDetails.Exterior.split(',');
                exteriorObj.exterior = exteriorObj?.exterior?.concat(arr);
              }
            } else {
              exteriorObj?.exterior?.push('-');
            }
            //-------------------> Lot Dimensions
            if (properties[i]?.ExteriorAndLotDetails?.LotSizeDimensions) {
              let lotSizeDimensions = properties[i].ExteriorAndLotDetails.LotSizeDimensions;

              // if the string is numerical, format it and add `sqft`
              if (/^\d+$/.test(lotSizeDimensions)) {
                lotSizeDimensions = `${formatNumber(lotSizeDimensions)} sqft`;
              } else {
                // LotSizeDimensions to have spaces around 'x'
                lotSizeDimensions = lotSizeDimensions.replace(/x/gi, ' x ');
              }
              // Ensure the first character is in original case and the rest is in lowercase
              let lot = lotSizeDimensions.charAt(0) + lotSizeDimensions.substring(1).toLowerCase();

              exteriorObj?.lotDimensions?.push(lot);
            } else {
              exteriorObj?.lotDimensions?.push('-');
            }
            //-------------------> Amenities
          } else {
            exteriorObj?.features?.push('-');
            exteriorObj?.exterior?.push('-');
            exteriorObj?.lotDimensions?.push('-');
          }
          // Create a set to store unique amenities as strings
          const uniqueAmenities = new Set<string>();
          if (
            properties[i]?.BuildingInfo?.AssociationFeatures &&
            properties[i]?.BuildingInfo?.AssociationFeatures?.length
          ) {
            for (let k = 0; k < properties[i]?.BuildingInfo?.AssociationFeatures?.length; k++) {
              if (properties[i]?.BuildingInfo?.AssociationFeatures?.[k]) {
                const amenities = properties[i]?.BuildingInfo?.AssociationFeatures?.[k]
                  .replaceAll('\\', '')
                  .replace(/([A-Z])/g, ' $1')
                  .trim()
                  .split(',');
                amenities?.forEach((amenity) => {
                  uniqueAmenities.add(amenity.trim());
                });
              }
            }
          }
          if (
            properties[i]?.CommunityInformation?.AssociationAmenities &&
            properties[i]?.CommunityInformation?.AssociationAmenities?.length
          ) {
            const amenities = properties[i]?.CommunityInformation?.AssociationAmenities?.replaceAll(
              '\\',
              '',
            ).split(',');
            amenities?.forEach((amenity) => {
              uniqueAmenities.add(amenity.replace(/([A-Z])/g, ' $1').trim());
            });
          } else {
            exteriorObj?.amenities?.push('-');
          }
          // Convert the set to an array and assign it to exteriorObj.amenities
          exteriorObj.amenities = Array.from(uniqueAmenities);
          data?.exteriorDetails?.push(exteriorObj);
          //=================================================================> Commute
          if (properties[i]?.Commutes && properties[i]?.Commutes?.length) {
            //-------------------> Destinations
            for (let k = 0; k < properties[i]?.Commutes?.length; k++) {
              let destIndex = data?.destinations?.findIndex(
                (el) =>
                  el?.name == properties[i]?.Commutes?.[k]?.Name &&
                  el?.address == properties[i]?.Commutes?.[k].DestinationAddress?.PlaceName,
              );
              if (
                properties[i]?.Commutes[k]?.Name &&
                properties[i]?.Commutes[k]?.DestinationAddress &&
                properties[i]?.Commutes[k]?.DestinationAddress?.PlaceName &&
                destIndex === -1
              ) {
                let destinationObj: Destinations = {
                  name: properties[i]?.Commutes?.[k]?.Name,
                  address: properties[i]?.Commutes?.[k].DestinationAddress?.PlaceName,
                  carTimes: [],
                  transitTimes: [],
                };
                data?.destinations?.push(destinationObj);
              }
            }
          }
        }
        //-------------------> Commute Times
        if (data?.destinations && data?.destinations?.length > 0) {
          for (let i = 0; i < data?.destinations?.length; i++) {
            for (let j = 0; j < properties?.length; j++) {
              if (properties?.[j]?.Commutes && properties?.[j]?.Commutes?.length) {
                for (let k = 0; k < properties[j].Commutes.length; k++) {
                  if (
                    properties[j].Commutes[k]?.Name &&
                    properties[j].Commutes[k]?.DestinationAddress &&
                    properties[j].Commutes[k]?.DestinationAddress?.PlaceName &&
                    data?.destinations?.[i]?.name === properties?.[j]?.Commutes?.[k]?.Name &&
                    data?.destinations?.[i]?.address ===
                      properties[j]?.Commutes[k]?.DestinationAddress?.PlaceName
                  ) {
                    let carTime =
                      properties[j].Commutes[k]?.Times && properties[j].Commutes[k]?.Times?.Car
                        ? Math.round(properties[j].Commutes[k].Times.Car).toString() + ' min'
                        : 'Out of Range';
                    let transitTime =
                      properties[j]?.Commutes[k]?.Times &&
                      properties[j]?.Commutes[k]?.Times?.Transit
                        ? Math.round(properties[j].Commutes[k].Times.Transit).toString() + ' min'
                        : 'Out of Range';
                    data?.destinations[i]?.carTimes?.push(carTime);
                    data?.destinations[i]?.transitTimes?.push(transitTime);
                  } else {
                    data?.destinations[i]?.carTimes?.push('Out of Range');
                    data?.destinations[i]?.transitTimes?.push('Out of Range');
                  }
                }
              } else {
                data?.destinations?.[i]?.carTimes?.push('Out of Range');
                data?.destinations?.[i]?.transitTimes?.push('Out of Range');
              }
            }
          }
        }
        let photosExist = true;
        for (let i = 0; i < properties?.length || 0; i++) {
          if (properties[i]?.PhotoCount) break;
          else photosExist = false;
        }
        setPhotosToCompareExist(photosExist);
        setCompareData(data);
      }
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const fetchClientPreferences = async () => {
    try {
      setClientPreferencesLoading(true);
      let ids = query?.ids.split(',');
      if (ids && ids.length > 1 && ids[0] && ids[1]) {
        if (ids.length > 5) {
          setClientPreferencesError(true);
          setClientPreferencesLoading(false);
        }
        ids = removeDuplicates(ids);
        const response = await getClientPreferencesForComparison({
          instanceId: agentClientSearchInstanceId,
          ids,
        });
        if (response.status === 200) {
          structureClientPreferencesData(response?.data?.result);
        } else {
          setClientPreferencesError(true);
        }
        setClientPreferencesLoading(false);
      } else {
        setClientPreferencesError(true);
        setClientPreferencesLoading(false);
      }
    } catch (e) {
      setClientPreferencesError(true);
      setClientPreferencesLoading(false);
    }
  };

  const structureClientPreferencesData = (properties) => {
    try {
      if (properties && properties.length) {
        for (let i = 0; i < properties.length; i++) {
          //=================================================================> Wish List Section
          if (properties[i].MatchedCriterias) {
            let needsObj: WishListDataType = {
              wishListData: [],
            };
            let wantsObj: WishListDataType = {
              wishListData: [],
            };
            //-------------------> Amenities
            if (
              properties[i].MatchedCriterias.Amenities &&
              properties[i].MatchedCriterias.Amenities.length
            ) {
              for (let j = 0; j < properties[i].MatchedCriterias.Amenities.length; j++) {
                if (properties[i].MatchedCriterias.Amenities[j].Importance == 'Must') {
                  needsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.Amenities[j].Feature,
                    importance: properties[i].MatchedCriterias.Amenities[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.Amenities[j].MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.Amenities[j].Feature,
                    importance: properties[i].MatchedCriterias.Amenities[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.Amenities[j].MatchLevel,
                  });
                }
              }
            }
            //-------------------> Home Features
            if (
              properties[i].MatchedCriterias.HomeFeatures &&
              properties[i].MatchedCriterias.HomeFeatures.length
            ) {
              for (let j = 0; j < properties[i].MatchedCriterias.HomeFeatures.length; j++) {
                if (properties[i].MatchedCriterias.HomeFeatures[j].Importance == 'Must') {
                  needsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.HomeFeatures[j].Feature,
                    importance: properties[i].MatchedCriterias.HomeFeatures[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.HomeFeatures[j].MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.HomeFeatures[j].Feature,
                    importance: properties[i].MatchedCriterias.HomeFeatures[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.HomeFeatures[j].MatchLevel,
                  });
                }
              }
            }
            //-------------------> Kitchen Features
            if (
              properties[i].MatchedCriterias.KitchenFeatures &&
              properties[i].MatchedCriterias.KitchenFeatures.length
            ) {
              for (let j = 0; j < properties[i].MatchedCriterias.KitchenFeatures.length; j++) {
                if (properties[i].MatchedCriterias.KitchenFeatures[j].Importance == 'Must') {
                  needsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.KitchenFeatures[j].Feature,
                    importance: properties[i].MatchedCriterias.KitchenFeatures[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.KitchenFeatures[j].MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.KitchenFeatures[j].Feature,
                    importance: properties[i].MatchedCriterias.KitchenFeatures[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.KitchenFeatures[j].MatchLevel,
                  });
                }
              }
            }
            //-------------------> Neighborhood Features
            if (
              properties[i].MatchedCriterias.NeighborhoodFeatures &&
              properties[i].MatchedCriterias.NeighborhoodFeatures.length
            ) {
              for (let j = 0; j < properties[i].MatchedCriterias.NeighborhoodFeatures.length; j++) {
                if (properties[i].MatchedCriterias.NeighborhoodFeatures[j].Importance == 'Must') {
                  needsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.NeighborhoodFeatures[j].Feature,
                    importance: properties[i].MatchedCriterias.NeighborhoodFeatures[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.NeighborhoodFeatures[j].MatchLevel,
                  });
                } else {
                  wantsObj.wishListData.push({
                    feature: properties[i].MatchedCriterias.NeighborhoodFeatures[j].Feature,
                    importance: properties[i].MatchedCriterias.NeighborhoodFeatures[j].Importance,
                    matchLevel: properties[i].MatchedCriterias.NeighborhoodFeatures[j].MatchLevel,
                  });
                }
              }
            }
            preferencesData.needs.push(needsObj);
            preferencesData.wants.push(wantsObj);
          }

          //=================================================================> Commute
          if (properties[i].Commutes && properties[i].Commutes.length) {
            //-------------------> Destinations
            for (let k = 0; k < properties[i].Commutes.length; k++) {
              let destIndex = preferencesData.destinations.findIndex(
                (el) =>
                  el.name == properties[i].Commutes[k].Name &&
                  el.address == properties[i].Commutes[k].DestinationAddress.PlaceName,
              );
              if (
                properties[i].Commutes[k].Name &&
                properties[i].Commutes[k].DestinationAddress &&
                properties[i].Commutes[k].DestinationAddress.PlaceName &&
                destIndex === -1
              ) {
                let destinationObj: Destinations = {
                  name: properties[i].Commutes[k].Name,
                  address: properties[i].Commutes[k].DestinationAddress.PlaceName,
                  carTimes: [],
                  transitTimes: [],
                };
                preferencesData.destinations.push(destinationObj);
              }
            }
          }
        }
        //-------------------> Commute Times
        if (preferencesData.destinations && preferencesData.destinations.length > 0) {
          for (let i = 0; i < preferencesData.destinations.length; i++) {
            for (let j = 0; j < properties.length; j++) {
              if (properties[j].Commutes && properties[j].Commutes.length) {
                for (let k = 0; k < properties[j].Commutes.length; k++) {
                  if (
                    properties[j].Commutes[k].Name &&
                    properties[j].Commutes[k].DestinationAddress &&
                    properties[j].Commutes[k].DestinationAddress.PlaceName &&
                    preferencesData.destinations[i].name === properties[j].Commutes[k].Name &&
                    preferencesData.destinations[i].address ===
                      properties[j].Commutes[k].DestinationAddress.PlaceName
                  ) {
                    let carTime =
                      properties[j].Commutes[k].Times && properties[j].Commutes[k].Times.Car
                        ? Math.round(properties[j].Commutes[k].Times.Car).toString() + ' min'
                        : '-';
                    let transitTime =
                      properties[j].Commutes[k].Times && properties[j].Commutes[k].Times.Transit
                        ? Math.round(properties[j].Commutes[k].Times.Transit).toString() + ' min'
                        : '-';
                    preferencesData.destinations[i].carTimes.push(carTime);
                    preferencesData.destinations[i].transitTimes.push(transitTime);
                  } else {
                    preferencesData.destinations[i].carTimes.push('-');
                    preferencesData.destinations[i].transitTimes.push('-');
                  }
                }
              } else {
                preferencesData.destinations[i].carTimes.push('-');
                preferencesData.destinations[i].transitTimes.push('-');
              }
            }
          }
        }
        setClientPreferencesData(preferencesData);
      }
    } catch (e) {
      setClientPreferencesError(true);
    }
  };

  useEffect(() => {
    const ids = query?.ids.split(',');
    const allImageProcessedFlag =
      Object.keys(imageProcesses).filter((propertyId) => ids.includes(propertyId)).length > 0 &&
      Object.keys(imageProcesses)
        .filter((propertyId) => ids.includes(propertyId))
        .every((propertyId) => imageProcesses[propertyId] === true);
    if (allImageProcessedFlag) {
      if (
        window.location.href.indexOf('app.mosaik.io') > -1 &&
        !user.data.Email.includes('@mosaik.io')
      ) {
        setShowTopLabels(false);
      }
      setAllImageProcessed(true);
      fetchPropertyListings();
    } else {
      setAllImageProcessed(false);
    }
  }, [imageProcesses]);

  useEffect(() => {
    const ids = query?.ids.split(',');
    ids.forEach((propertyId) => {
      if (!Object.keys(imageProcesses).includes(propertyId)) {
        dispatch(initialImageAnalysisAction({ data: { [propertyId]: false } })); //start with default false
        dispatch(initiateImageAnalysisEffect({ Id: propertyId }));
      }
    });
  }, []);

  useEffect(() => {
    if (loading) fetchPropertyListings();
    if (agentClientSearchInstanceId) fetchClientPreferences();
  }, [loading, agentClientSearchInstanceId]);

  useEffect(() => {
    structureData(listingsResponse);
  }, [listingsResponse]);

  const photoCompareClickHandler = (e) => {
    if (comparisonState === ComparisonStateDataType.details) {
      setHideFields(false);
      setStarredFieldsCount(0);
      setStarredFields([]);
      setComparisonState(ComparisonStateDataType.photos);
    } else {
      setComparisonState(ComparisonStateDataType.details);
    }
  };

  const checkIfPhotoExists = (propertiesPhotos: PhotoCompareSectionDataType[]): boolean => {
    if (!propertiesPhotos.length) return false;
    return propertiesPhotos.some((item) => {
      return item.photoData.some((el) => el.url !== 'empty');
    });
  };

  const clientSearchChangeHandler = (instanceId) => {
    setAgentClientSearchInstanceId(instanceId);
  };

  const removeProperty = (id: string) => {
    if (listingsResponse.length && listingsResponse.length > 2) {
      const newArray = listingsResponse.filter((obj) => obj.Id !== id);
      setListingsResponse(newArray);
      history.push({
        pathname,
        search: `?ids=${newArray.map(({ Id }) => Id).join(',')}`,
      });
    }
  };

  const commentOnProperty = (id: string) => {
    const propertyInfo = listingsResponse.find((obj) => obj.Id === id);
    if (propertyInfo) {
      dispatch(setSharePropertyEffect(propertyInfo));
      dispatch(openCommentsDrawerEffect({ open: true }));
    }
  };

  const shareProperty = (id: string) => {
    const propertyInfo = listingsResponse.find((obj) => obj.Id === id);
    if (propertyInfo) {
      const recipients = propertyInfo?.RecommendedTo?.map((item) => ({
        Id: item.Id,
        Name: `${item.FirstName} ${item.LastName}`,
        CreatedDate: item.CreatedDate,
      }));
      dispatch(setRecipientsEffect(recipients ?? []));
      dispatch(setSharePropertyEffect(propertyInfo));
      dispatch(openShareDrawerEffect({ open: true }));
    }
  };

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    pageStyle: '@page {size: A2 portrait;}',
    copyStyles: true,
    documentTitle:
      comparisonState === ComparisonStateDataType.details
        ? 'Properties_Comparison_Data.pdf'
        : 'Properties_Comparison_Photos.pdf',
  });

  const starredFieldsCountHandler = (type: 'inc' | 'dec', key: string) => {
    if (type === 'inc') {
      setStarredFields((prev) => [...prev, key]);
      setStarredFieldsCount(starredFieldsCount + 1);
    } else {
      const index = starredFields.findIndex((k) => k === key);
      const newStarred = cloneDeep(starredFields);
      newStarred.splice(index, 1);
      setStarredFields(newStarred);
      setStarredFieldsCount(starredFieldsCount - 1);
    }
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setIsVisible(scrollY <= 300);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function checkAnyStarredRow(arr) {
    return starredFields.some((item) => arr.includes(item));
  }

  if (loading) {
    return <Spinner loaderClassName={classNames(styles.loader)} />;
  } else if (error) {
    return (
      <div>
        <PageNotFound backUrl={backUrl} />
        <div className={styles.Footer}>
          <Footer />
        </div>
      </div>
    );
  } else {
    const uniqueCategories = Array.from(new Set(Object.values(roomVICodeToCompareLabelMap)));
    return (
      <div className={styles.comparison} ref={pdfRef}>
        {!isVisible && (
          <div className={styles.stickyHeader}>
            <StickyHeader
              data={compareData.generalInfo}
              removePropertyHandler={removeProperty}
              commentOnProperty={commentOnProperty}
              shareProperty={shareProperty}
            />
          </div>
        )}
        <div className={styles.header}>
          <BackLink
            testid="comparison_back"
            onClick={onBack}
            className={styles.backComparison}
            to={backUrl}
            text=""
          />
          <h1 className={styles.heading}>Compare</h1>
          <div className={styles.icons}>
            <div
              className={styles.icon}
              onClick={() => {
                if (comparisonState === ComparisonStateDataType.details && starredFieldsCount)
                  setHideFields(!hideFields);
              }}
            >
              <Icons iconName={hideFields ? 'yellowCircledStar' : 'circledStar'} />
            </div>
            <div className={styles.icon} onClick={(e) => photoCompareClickHandler(e)}>
              <Icons
                iconName={
                  comparisonState === ComparisonStateDataType.details
                    ? 'photoCompare'
                    : 'photoCompareRed'
                }
              />
            </div>
            <div className={styles.icon} onClick={handlePrint}>
              <Icons iconName="download" />
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          <Avatar
            data={compareData.generalInfo}
            removePropertyHandler={removeProperty}
            commentOnProperty={commentOnProperty}
            shareProperty={shareProperty}
          />
          {comparisonState === ComparisonStateDataType.details ? (
            <div className={styles.tableContainer}>
              {hideFields &&
              !checkAnyStarredRow([
                'Price:',
                'Status:',
                'Property Type:',
                'Days On Market:',
                'Beds:',
                'Baths:',
                'Size:',
                'Year Built:',
              ]) ? null : (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Key Details</h2>
                  <ComparisonTable
                    rowsHeadings={[
                      'Price:',
                      'Status:',
                      'Property Type:',
                      'Days On Market:',
                      'Beds:',
                      'Baths:',
                      'Size:',
                      'Year Built:',
                    ]}
                    dataObjectKeys={[
                      'price',
                      'status',
                      'propertyType',
                      'daysOnMarket',
                      'beds',
                      'baths',
                      'size',
                      'yearBuilt',
                    ]}
                    arrayBasedData={false}
                    columnsData={compareData.keyDetails}
                    hideFields={hideFields}
                    countStarredFields={starredFieldsCount}
                    starredFieldsCountHandler={starredFieldsCountHandler}
                  />
                </div>
              )}
              {hideFields &&
              !checkAnyStarredRow(['Price / Sq Ft:', 'HOA Dues:', 'Property Tax:']) ? null : (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Financial</h2>
                  <ComparisonTable
                    rowsHeadings={['Price / Sq Ft:', 'HOA Dues:', 'Property Tax:']}
                    dataObjectKeys={['pricePerSqFt', 'hoaDue', 'propertyTax']}
                    arrayBasedData={false}
                    columnsData={compareData.financial}
                    childRow={{
                      index: 1,
                      heading: 'Dues Include:',
                      key: 'duesInclude',
                    }}
                    hideFields={hideFields}
                    countStarredFields={starredFieldsCount}
                    starredFieldsCountHandler={starredFieldsCountHandler}
                  />
                </div>
              )}
              {hideFields &&
              !checkAnyStarredRow([
                'Living Room:',
                'Kitchen:',
                'Primary Bedroom:',
                'Primary Bathroom:',
                'Additional Room #1:',
                'Interior Features:',
                'Equipment:',
              ]) ? null : (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Interior</h2>
                  <ComparisonTable
                    rowsHeadings={[
                      'Living Room:',
                      'Kitchen:',
                      'Primary Bedroom:',
                      'Primary Bathroom:',
                      'Additional Room #1:',
                      'Interior Features:',
                      'Equipment:',
                    ]}
                    dataObjectKeys={[
                      'livingRoom',
                      'kitchen',
                      'primaryBedroom',
                      'primaryBathroom',
                      'additionalRoom',
                      'interiorFeatures',
                      'equipment',
                    ]}
                    arrayBasedData={true}
                    columnsData={compareData.interiorDetails}
                    hideFields={hideFields}
                    countStarredFields={starredFieldsCount}
                    starredFieldsCountHandler={starredFieldsCountHandler}
                  />
                </div>
              )}
              {hideFields &&
              !checkAnyStarredRow([
                'Exterior Features:',
                'Exterior:',
                'Lot Dimensions:',
                'Amenities:',
              ]) ? null : (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Exterior</h2>
                  <ComparisonTable
                    rowsHeadings={[
                      'Exterior Features:',
                      'Exterior:',
                      'Lot Dimensions:',
                      'Amenities:',
                    ]}
                    dataObjectKeys={['features', 'exterior', 'lotDimensions', 'amenities']}
                    arrayBasedData={true}
                    columnsData={compareData.exteriorDetails}
                    hideFields={hideFields}
                    countStarredFields={starredFieldsCount}
                    starredFieldsCountHandler={starredFieldsCountHandler}
                  />
                </div>
              )}
              {isAgent && (
                <div className={styles.tableSection + ' ' + styles.searchSection}>
                  <SearchClient
                    mainClientSearchChangeHandler={clientSearchChangeHandler}
                    addClientClicked={agentAddClientClicked}
                    addClientClickedSetter={agentAddClientClickedSetter}
                    loading={agentLoading}
                    loadingSetter={agentLoadingSetter}
                    error={agentError}
                    errorSetter={agentErrorSetter}
                    showDropdown={agentShowDropdown}
                    showDropdownSetter={agentShowDropdownSetter}
                    selectedClient={agentSelectedClient}
                    selectedClientSetter={agentSelectedClientSetter}
                    clientSearchInstances={agentClientSearchInstances}
                    clientSearchInstancesSetter={agentClientSearchInstancesSetter}
                    filteredClientSearchInstances={agentFilteredClientSearchInstances}
                    filteredClientSearchInstancesSetter={agentFilteredClientSearchInstancesSetter}
                  />
                  {clientPreferencesLoading ? (
                    <Spinner loaderClassName={classNames(styles.loader)} />
                  ) : (
                    <></>
                  )}
                  {clientPreferencesError ? (
                    <div className={styles.preferencesError}>
                      Oops! something went wrong...Please try refreshing page
                    </div>
                  ) : (
                    <></>
                  )}
                  {agentClientSearchInstanceId &&
                  !clientPreferencesLoading &&
                  !clientPreferencesError ? (
                    <>
                      <h2 className={styles.tableHeading}>Client Wish List</h2>
                      <WishList
                        needs={clientPreferencesData.needs}
                        wants={clientPreferencesData.wants}
                        hideFields={hideFields}
                        countStarredFields={starredFieldsCount}
                        starredFieldsCountHandler={starredFieldsCountHandler}
                      />
                      <div className={styles.tableSection}>
                        {clientPreferencesData.destinations &&
                        clientPreferencesData.destinations.length ? (
                          <>
                            <h2 className={styles.tableHeading}>
                              Commutes/Proximity to Important Locations
                            </h2>
                            <Commute
                              destinations={clientPreferencesData.destinations}
                              hideFields={hideFields}
                              countStarredFields={starredFieldsCount}
                              starredFieldsCountHandler={starredFieldsCountHandler}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {isClient && (
                <div className={styles.tableSection}>
                  <WishList
                    needs={compareData.needs}
                    wants={compareData.wants}
                    hideFields={hideFields}
                    countStarredFields={starredFieldsCount}
                    starredFieldsCountHandler={starredFieldsCountHandler}
                  />
                </div>
              )}
              {isClient && compareData.destinations && compareData.destinations.length ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Commutes/Proximity to Important Locations</h2>
                  <Commute
                    destinations={compareData.destinations}
                    hideFields={hideFields}
                    countStarredFields={starredFieldsCount}
                    starredFieldsCountHandler={starredFieldsCountHandler}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : !allImageProcessed ? (
            <ImageCompareStatus
              propertiesCount={compareData.generalInfo.length}
              status={'analyzing'}
            />
          ) : (
            <div>
              {checkIfPhotoExists(compareData.livingAreaPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Living Area</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.livingAreaPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.kitchenPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Kitchen</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.kitchenPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.bedroomsPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Bedrooms</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.bedroomsPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.bathroomsPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Bathrooms</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.bathroomsPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.outdoorPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Outdoor Spaces</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.outdoorPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.flexSpacesPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Flex Spaces</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.flexSpacesPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.aerialPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Aerial</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.aerialPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.atticPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Attic</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.atticPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.exteriorPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Exterior</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.exteriorPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.foyerPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Foyer</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.foyerPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.entryPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Entry</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.entryPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.floorPlanPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Floor Plan</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.floorPlanPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.garagePhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Garage</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.garagePhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.hallwayPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Hallway</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.hallwayPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.laundryPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Laundry</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.laundryPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.mudRoomPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Mud Room</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.mudRoomPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.stairsPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Stairs</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.stairsPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.viewPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>View</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.viewPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.platMapPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Plat Map</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.platMapPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.fireplacePhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Fireplace</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.fireplacePhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {checkIfPhotoExists(compareData.otherRoomsPhotoUrls) ? (
                <div className={styles.tableSection}>
                  <h2 className={styles.tableHeading}>Other Rooms</h2>
                  <PhotosSection
                    showTopLabels={showTopLabels}
                    photoData={compareData.otherRoomsPhotoUrls}
                  />
                </div>
              ) : (
                <></>
              )}
              {!photosToCompareExist ? (
                <ImageCompareStatus
                  propertiesCount={compareData.generalInfo.length}
                  status={'empty'}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        <div className={styles.Footer}>
          <Footer />
        </div>
        <CommentsDrawer />
        <HighlightModal />
      </div>
    );
  }
}

export default Listings;
