import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageWrapper } from 'components-antd';
import { useHistory } from 'react-router-dom';

import { Wrapper } from 'components';
import { History, ChatInput, Message, EmptyMessages } from './components';
import { Icons } from './Icons';

import { getSessionMessagesEffect, requestAISessionsEffect } from 'store/effects';
import { getAISessionsList, getSessionMessages } from 'store/selectors/clarityAI';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';
export interface AISession {
  sessionId: string;
  title: string;
  createdat: string;
}

export const ClarityAI = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const conversationContainerRef = useRef<any>(null);
  const { data, isPending } = useSelector(getAISessionsList);
  const { messages, isPending: messagesPending } = useSelector(getSessionMessages);
  const [activeSessionTabId, setActiveSessionTabId] = useState('');

  useEffect(() => {
    if (data?.length) {
      // Set the first session as the active sidebar tab
      setActiveSessionTabId(data?.[0]?.sessionId);
    }
  }, [data]);

  useEffect(() => {
    if (activeSessionTabId) {
      fetchSessionMessages();
    }
  }, [activeSessionTabId]);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessionMessages = () => dispatch(getSessionMessagesEffect(activeSessionTabId, {}));

  const fetchSessions = (silent = false) =>
    dispatch(requestAISessionsEffect({}, { silent }, () => {}));

  return (
    <PageWrapper
      bgWhite
      version={2}
      pageContentClassName={styles.overflowHidden}
      mainPageContentStyle={styles.clarityPage}
    >
      <Wrapper isPending={isPending} className={styles.pageWrapper}>
        <History
          activeSessionTabId={activeSessionTabId}
          setActiveSessionTabId={(tabId) => setActiveSessionTabId(tabId)}
          fetchSessions={fetchSessions}
        />
        <div className={styles.chatSection}>
          <div className={styles.header}>
            <div className={styles.left}>
              <Icons variant={Icons.MOZZIE_RED} />
              <span className={styles.title}>Mozzie</span>
            </div>
            <div className={styles.right}>
              <Icons
                variant={Icons.CLOSE}
                className={styles.closeBtn}
                onClick={() => history.push(routes.clarity)}
              />
            </div>
          </div>
          <Wrapper isPending={messagesPending} className={styles.pageWrapper}>
            <div ref={conversationContainerRef} className={styles.conversationContainer}>
              {messages?.length ? (
                messages?.map((item, idx) => (
                  <Message
                    key={idx}
                    userMsg={item?.messageText}
                    responseFromAI={item?.aiResponse}
                    s3FilePath={item?.s3FilePath}
                  />
                ))
              ) : (
                <EmptyMessages sessions={data} fetchSessions={fetchSessions} />
              )}
            </div>
          </Wrapper>
          <ChatInput
            activeSessionTabId={activeSessionTabId}
            fetchSessionMessages={fetchSessionMessages}
          />
        </div>
      </Wrapper>
    </PageWrapper>
  );
};
