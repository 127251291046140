import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getRecommendedByMosaikAndAgentLists } from 'store/selectors/partners';
import PartnerListItem from './PartnerListItem';
import { Row } from 'antd';
import { getCtaData } from 'api/kits';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { Button } from 'components-antd';
import styles from './styles.module.scss';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { FavoriteIcon } from 'components/Icons';

const OthersRolesPartnersList = ({ categoryId: serviceCategoryId }) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const partnersIdsParam = queryParams.get('partnersIds');
  const sourceParam = queryParams.get('source');
  const uuidParam = queryParams.get('uuid');
  const categoryIdParam = queryParams.get('categoryId');
  const { isClient } = useSelector(getUserRolesMapSelector);
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const dispatch = useDispatch();
  const { recommendedByMosaik, recommendedByAgent } = useSelector(
    getRecommendedByMosaikAndAgentLists,
  );

  useEffect(() => {
    if (isClient) redirectToQuotesForm();
  }, []);

  const categoryId = useMemo(() => {
    return serviceCategoryId || categoryIdParam;
  }, [categoryIdParam, serviceCategoryId]);

  const redirectToQuotesForm = () => {
    if (sourceParam === 'kits' && uuidParam) {
      getCtaData(uuidParam);
    }
    if (partnersIdsParam && categoryId) {
      const recommendedByMosaikIds = recommendedByMosaik.length
        ? recommendedByMosaik.map((obj) => obj.Id)
        : [];
      const recommendedByAgentId = recommendedByAgent.length
        ? recommendedByAgent.map((obj) => obj.Id)
        : [];
      const allRecommendationsIds = recommendedByMosaikIds.concat(recommendedByAgentId);
      const targetRecommendationsIds = partnersIdsParam.split(',').map(Number);
      let validIds = targetRecommendationsIds.filter(
        (number) => !isNaN(number) && allRecommendationsIds.includes(number),
      );

      if (validIds.length) {
        const quoteFormRedirectUrl = `/services/category/${categoryId}/partner/${validIds.join()}/request-quote`;
        history.push(quoteFormRedirectUrl);
      }
    }
  };

  const handleSelectRequestQuoteClick = () => {
    dispatch(setMultipleRequestQuotesEffect({ modeEnable: !multiple?.modeEnable }));
  };

  const onSelectPartnerFromClient = (partner) => {
    if (multiple.partners.some(({ Id }) => partner.Id === Id)) {
      const partners = multiple.partners.filter(({ Id }) => Id !== partner.Id);
      dispatch(
        setMultipleRequestQuotesEffect({
          partners,
        }),
      );
    } else {
      dispatch(
        setMultipleRequestQuotesEffect({
          partners: [...multiple.partners, partner],
        }),
      );
    }
  };

  const getGroupedList = () => {
    let listCopy = [...recommendedByAgent];

    // Flatten and deduplicate categories
    const uniqueCategories = listCopy
      .flatMap((item) => item.RecommendingAgents)
      .reduce((acc, agent) => {
        if (!acc.has(agent.Id)) {
          acc.set(agent.Id, { ...agent, partners: [] });
        }
        return acc;
      }, new Map());

    // Populate partners for each unique category
    listCopy.forEach((item) => {
      item.RecommendingAgents.forEach((partner) => {
        const uniqueCategory = uniqueCategories.get(partner.Id);
        if (uniqueCategory && !uniqueCategory.partners.some((partner) => partner.Id === item.Id)) {
          uniqueCategory.partners.push(item);
        }
      });
    });

    // Convert the Map back to an array for rendering
    const groupedPartnersByAgent = Array.from(uniqueCategories.values());

    return (
      <>
        {groupedPartnersByAgent.map((agent, index) => {
          return (
            <div className={styles.recommendationsContainer}>
              <div
                className={styles.agentHeader}
                style={
                  index > 0
                    ? {
                        marginTop: 12,
                      }
                    : {}
                }
              >
                <FavoriteIcon color={'#fb913a'} />
                <div className={styles.text}>
                  {`${agent.FirstName} ${
                    agent.LastName ? ` ${agent.LastName}` : ''
                  }'s Recommendations`}
                </div>
              </div>
              <Row gutter={[20, 20]}>
                {agent?.partners.map((partner) => (
                  <PartnerListItem
                    agentId={agent.Id}
                    partner={partner}
                    key={partner?.Id}
                    isClient
                    onSelectPartnerFromClient={onSelectPartnerFromClient}
                  />
                ))}
              </Row>
            </div>
          );
        })}
      </>
    );
  };

  const showRequestQuoteButton = () => {
    return (
      recommendedByAgent?.length &&
      recommendedByAgent.some(
        (recommendation) =>
          !recommendation.IsStrictlyProfile &&
          recommendation?.ThirdParty?.User?.IsEmailVerified &&
          recommendation.RecommendingAgents?.length &&
          recommendation.RecommendingAgents.some((agent) => agent.IsInvited),
      )
    );
  };

  return (
    <div className={styles.wrapper}>
      <div testid="agent_recommendations" className={styles.block}>
        <Row gutter={[20, 20]}>
          {recommendedByAgent?.length ? (
            getGroupedList()
          ) : (
            <div testid="no_partners" className={styles.noPartners}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2881 51.9894L32.0036 44.7783L45.7192 51.9894L43.0992 36.7161L54.1947 25.9005M20.9081 36.7161L9.8125 25.9005L25.1458 23.6739L32.0036 9.77832C34.6817 15.2049 36.1833 18.2473 38.8614 23.6739"
                  stroke="#E8E9EA"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.95081 48.6789L55.0622 12.8036"
                  stroke="#E8E9EA"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
              <span className={styles.noPartnersText}>There are no agent recommendations</span>
            </div>
          )}
        </Row>
      </div>
      {showRequestQuoteButton() ? (
        <div className={styles.requestQuoteWrapper}>
          <div>
            {!multiple.modeEnable && (
              <Button
                variant="secondary"
                className={styles.actionBtn}
                onClick={handleSelectRequestQuoteClick}
              >
                Request Quote
              </Button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OthersRolesPartnersList;
