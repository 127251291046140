import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { appOpenModalEffect } from 'store/effects/app';
import { getModalSelector } from 'store/selectors/app';
import { Modal, Answers, Button } from 'components';
import NextButton from 'pages/OnBoardingWizard/components/NextButton';

import styles from './styles.module.scss';
import {
  SchoolsDistrictsSearch,
  SpecificSchoolOrDistrict,
} from './SchoolsDistrictsSearch/SchoolsDistrictsSearch';

const propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

const defaultProps = {
  className: '',
  onNext: () => {},
  value: {},
};

export enum SchoolType {
  Public = 'Public',
  Private = 'Private',
  Magnet = 'Magnet',
  Charter = 'Charter',
}

interface SpecificSchoolPref {
  Id: string;
  Name: string;
  State: string;
  County: string;
}

interface SpecificDistrictPref {
  Id: string;
  Name: string;
  State: string;
  County: string;
}

interface SchoolPrefConfig {
  SpecificSchools?: SpecificSchoolPref[];
  SpecificDistricts?: SpecificDistrictPref[];
  NonSpecificConfig?: {
    SchoolTypes: SchoolType[];
    DistanceInMiles?: number;
  };
}

type SchoolModalTypes = {
  className?: string;
  onNext: (value: SchoolPrefConfig) => void;
  value: SchoolPrefConfig;
  keepModalOpen?: string;
};

export interface SpecificSchoolOrDistrictValue {
  Id: string;
  Type: 'District' | 'School';
  Name: string;
  State: string;
  County: string;
}

export const SCHOOLS_MODAL_ID = 'schoolsModal';

const answers = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
];

const schoolTypeOptions = [
  { id: 'Public', label: 'Public' },
  { id: 'Private', label: 'Private' },
  { id: 'Magnet', label: 'Magnet' },
  { id: 'Charter', label: 'Charter' },
];

export const SchoolsModal: React.FC<SchoolModalTypes> = ({
  className,
  onNext,
  value,
  keepModalOpen = '',
}) => {
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    // Parse initial values
    const initialSpecificItems: SpecificSchoolOrDistrict[] = [];
    if (value.SpecificDistricts) {
      initialSpecificItems.push(
        ...value.SpecificDistricts.map((item) => ({
          label:
            item.State && item.County ? `${item.Name} (${item.County}, ${item.State})` : item.Name,
          value: JSON.stringify({
            Id: item.Id,
            Name: item.Name,
            State: item.State,
            County: item.County,
            Type: 'District',
          }),
        })),
      );
    }
    if (value.SpecificSchools) {
      initialSpecificItems.push(
        ...value.SpecificSchools.map((item) => ({
          label:
            item.State && item.County ? `${item.Name} (${item.County}, ${item.State})` : item.Name,
          value: JSON.stringify({
            Id: item.Id,
            Name: item.Name,
            State: item.State,
            County: item.County,
            Type: 'School',
          }),
        })),
      );
    }

    setInterestedInSpecific(
      Object.keys(value).length > 0 ? initialSpecificItems?.length !== 0 : null,
    );
    setSpecificItems(initialSpecificItems);
    setSchoolTypes(value.NonSpecificConfig?.SchoolTypes ?? []);
  }, [value]);

  const [isInterestedInSpecific, setInterestedInSpecific] = useState<boolean | null>(null);
  const [specificItems, setSpecificItems] = useState<SpecificSchoolOrDistrict[]>([]);
  const [schoolTypes, setSchoolTypes] = useState<string[]>([]);

  if (modal.id !== SCHOOLS_MODAL_ID) {
    return null;
  }

  const onClose = () => {
    const combinedValue = {} as any;

    if (isInterestedInSpecific) {
      if (specificItems?.length) {
        const selectedSpecifics = specificItems.map(
          (item) => JSON.parse(item.value) as SpecificSchoolOrDistrictValue,
        );
        combinedValue.SpecificSchools = selectedSpecifics
          .filter((item) => item.Type === 'School')
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
            State: item.State,
            County: item.County,
          }));
        combinedValue.SpecificDistricts = selectedSpecifics
          .filter((item) => item.Type === 'District')
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
            State: item.State,
            County: item.County,
          }));
      }
    } else {
      if (schoolTypes?.length) {
        combinedValue.NonSpecificConfig = {
          SchoolTypes: schoolTypes,
        };
      }
    }

    onNext!(combinedValue);
    dispatch(
      appOpenModalEffect(
        keepModalOpen.length ? { id: keepModalOpen, open: true } : { id: null, open: false },
      ),
    );
  };

  const onNextHandler = () => {
    onClose();
  };

  const onYesNoSelect = (val: string[]) => {
    setInterestedInSpecific(val.includes('yes'));
  };

  return (
    <Modal
      testid="parking_modal"
      className={classNames(className)}
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      label="Schools"
    >
      <p className={styles.subtitle}>
        Is there a specific school district that is important to you?
      </p>
      <div className={styles.wrapper}>
        <Answers
          value={
            isInterestedInSpecific === true
              ? ['yes']
              : isInterestedInSpecific === false
              ? ['no']
              : []
          }
          caseClassname={styles.caseClassname}
          cases={answers}
          onChange={onYesNoSelect}
          testid="schools_pref_answers"
          labelClassName={styles.label}
          className={styles.answersWrapper}
          direction="row"
          color="yellow"
        />
        {isInterestedInSpecific === true && (
          <div>
            <p className={styles.subtext}>Which ones?</p>
            <div className={styles.schoolsDistrictInputWrapper}>
              <SchoolsDistrictsSearch
                value={specificItems}
                onChange={(newValue) => {
                  setSpecificItems(newValue as SpecificSchoolOrDistrict[]);
                }}
              />
            </div>
          </div>
        )}
        {isInterestedInSpecific === false && (
          <div>
            <p className={classNames(styles.subtext, styles.typetext)}>
              What type of schools are you interested in?
            </p>
            <div>
              <Answers
                multiple
                unselectable
                cases={schoolTypeOptions}
                value={schoolTypes}
                onChange={(values) => {
                  setSchoolTypes(values);
                }}
                testid="school_pref_types"
                variant={Answers.CHECKBOX}
                checkboxOptionClassname={styles.checkboxOption}
                color="yellow"
              />
            </div>
          </div>
        )}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          className={classNames(styles.cancel, styles.button)}
          titleClassName={styles.titleClassName}
          title="Cancel"
          onClick={() => {
            onClose();
          }}
        />
        <Button
          className={styles.button}
          titleClassName={styles.titleClassName}
          title="Save"
          // isPending={isPending}
          onClick={onClose}
        />
      </div>
      {/* <NextButton
        title={undefined}
        testid="parking_continue"
        onClick={onNextHandler}
        disabled={false}
        iconArrow={undefined}
        className={classNames(styles.next, { [styles.overflow]: !isInterestedInSpecific })}
      /> */}
    </Modal>
  );
};

SchoolsModal.propTypes = propTypes;
SchoolsModal.defaultProps = defaultProps;
(SchoolsModal as any).id = SCHOOLS_MODAL_ID;
