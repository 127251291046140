import { useState } from 'react';
import { TimeInput } from 'components-antd/Input/Variants/TimeInput';
import { Options } from 'components';

import styles from './styles.module.scss';
import { Checkmark } from 'components/Icons';
import { useSelector } from 'react-redux';
import { getUserTimeZone } from 'store/selectors/user';

export const DueDateTime = ({ time, setTime, timezone, setTimezone, isViewMode }) => {
  const userTimezone = useSelector(getUserTimeZone);
  const userTimezoneMap = {
    'US/Eastern': 'America/New_York',
    'US/Central': 'America/Chicago',
    'US/Mountain': 'America/Denver',
    'US/Pacific': 'America/Los_Angeles',
    'US/Alaska': 'America/Anchorage',
    'US/Hawaii': 'Pacific/Honolulu',
  };

  const timezoneOptions = [
    ...(!userTimezoneMap?.[userTimezone]
      ? [
          {
            label: userTimezone,
            value: null,
          },
        ]
      : []),
    {
      label: 'Eastern Time Zone (EST)',
      value: 'America/New_York',
    },
    {
      label: 'Central Time Zone (CST)',
      value: 'America/Chicago',
    },
    {
      label: 'Mountain Time Zone (MST)',
      value: 'America/Denver',
    },
    {
      label: 'Pacific Time Zone (PST)',
      value: 'America/Los_Angeles',
    },
    {
      label: 'Alaska Time Zone (AKST)',
      value: 'America/Anchorage',
    },
    {
      label: 'Hawaii Time Zone (HST)',
      value: 'Pacific/Honolulu',
    },
  ];

  const formattedTimezoneOptions = timezoneOptions?.map((item) => ({
    label: (
      <span className={styles.label}>
        {item?.label} {(item?.value === timezone || (!item?.value && !timezone)) && <Checkmark />}
      </span>
    ),
    icon: null,
    onClick: () => setTimezone(item?.value),
  }));

  const getTitle = () => {
    if (timezone)
      return (
        timezoneOptions?.find((item) => item?.value === timezone)?.label ||
        timezoneOptions?.[0]?.label
      );

    return timezoneOptions?.[0]?.label;
  };

  return (
    <>
      <span className={styles.atText}>at</span>
      <TimeInput
        key={time || ''}
        value={time || null}
        onChange={(e: any) => {
          setTime(e);
        }}
        className={styles.inputTime}
        wrapperClassName={styles.timeInput}
        selectClassName={styles.timeSelect}
        popupClassName={styles.timeDropdown}
        getPopupContainer={(node) => node}
        disabled={isViewMode}
      />
      <div className={styles.timezoneContainer}>
        <Options
          title={getTitle()}
          placement="bottom"
          options={formattedTimezoneOptions}
          popoverClassName={styles.timezonePopover}
          titleClassName={styles.timezoneTitle}
          optionLabelClassName={styles.timezoneOptionLabelClassName}
          disabled={isViewMode}
          isArrow
        />
      </div>
    </>
  );
};
