import PropTypes from 'prop-types';

export const Icons = ({ className = '', variant = '', onClick = () => {} }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.MOZZIE_RED:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4C10.4 9.6 9.6 10.4 4 12C9.6 13.6 10.4 14.4 12 20C13.6 14.4 14.4 13.6 20 12C14.4 10.4 13.6 9.6 12 4Z"
              stroke="#262626"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 4C10.4 9.6 9.6 10.4 4 12C9.6 13.6 10.4 14.4 12 20C13.6 14.4 14.4 13.6 20 12C14.4 10.4 13.6 9.6 12 4Z"
              stroke="url(#paint0_linear_66394_463922)"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.4008 16.8008C21.2808 20.7208 20.7208 21.2808 16.8008 22.4008C20.7208 23.5208 21.2808 24.0808 22.4008 28.0008C23.5208 24.0808 24.0808 23.5208 28.0008 22.4008C24.0808 21.2808 23.5208 20.7208 22.4008 16.8008Z"
              stroke="#262626"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.4008 16.8008C21.2808 20.7208 20.7208 21.2808 16.8008 22.4008C20.7208 23.5208 21.2808 24.0808 22.4008 28.0008C23.5208 24.0808 24.0808 23.5208 28.0008 22.4008C24.0808 21.2808 23.5208 20.7208 22.4008 16.8008Z"
              stroke="url(#paint1_linear_66394_463922)"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_66394_463922"
                x1="0.900901"
                y1="21.6364"
                x2="24.0344"
                y2="17.9877"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.34" stopColor="#FF576D" />
                <stop offset="0.91" stopColor="#FB913A" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_66394_463922"
                x1="14.6314"
                y1="29.1462"
                x2="30.8248"
                y2="26.5922"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.34" stopColor="#FF576D" />
                <stop offset="0.91" stopColor="#FB913A" />
              </linearGradient>
            </defs>
          </svg>
        );
      case Icons.CLOSE:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="21.25" fill="white" stroke="#E8E9EA" strokeWidth="1.5" />
            <path d="M28 28L16 16" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 28L28 16" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.EXPAND_COLLAPSE:
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2222 1H2.77778C1.79594 1 1 1.79594 1 2.77778V15.2222C1 16.2041 1.79594 17 2.77778 17H15.2222C16.2041 17 17 16.2041 17 15.2222V2.77778C17 1.79594 16.2041 1 15.2222 1Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 1V17"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 12L10 9L13 6"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.EDIT:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_66394_464649)">
              <path
                d="M9 6.99976H6C5.46957 6.99976 4.96086 7.21047 4.58579 7.58554C4.21071 7.96061 4 8.46932 4 8.99976V17.9998C4 18.5302 4.21071 19.0389 4.58579 19.414C4.96086 19.789 5.46957 19.9998 6 19.9998H15C15.5304 19.9998 16.0391 19.789 16.4142 19.414C16.7893 19.0389 17 18.5302 17 17.9998V14.9998"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 15.0002H12L20.5 6.50023C20.8978 6.1024 21.1213 5.56284 21.1213 5.00023C21.1213 4.43762 20.8978 3.89805 20.5 3.50023C20.1022 3.1024 19.5626 2.87891 19 2.87891C18.4374 2.87891 17.8978 3.1024 17.5 3.50023L9 12.0002V15.0002Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 5.00024L19 8.00024"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_66394_464649">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );

      default:
        return null;
    }
  };
  return (
    <div onClick={onClick} className={className} style={{ display: 'flex' }}>
      {renderIcon()}
    </div>
  );
};

Icons.MOZZIE_RED = 'MozzieRed';
Icons.CLOSE = 'close';
Icons.EXPAND_COLLAPSE = 'expand_collapse';
Icons.EDIT = 'edit';
