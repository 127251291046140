import { Button, Input } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { showSuccessMessage } from 'helpers';
import { requestEditUpdateAddressEffect } from 'store/effects/intel';

export const LotSizeForm = ({ value, handleClose, addressId, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    lotSize: Yup.number().required(),
  });

  const { handleSubmit, handleChange, values, handleBlur } = useFormik({
    initialValues: { lotSize: value },
    validationSchema,
    onSubmit: async ({ lotSize }) => {
      setLoading(true);
      dispatch(
        requestEditUpdateAddressEffect({ Id: addressId, lotSize: Number(lotSize) }, {}, (err) => {
          if (!err) {
            onUpdate?.(lotSize);
            showSuccessMessage('Lot Size updated successfully');
            setLoading(false);
            handleClose();
          }
        }),
      );
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <span className={styles.input}>
        <Input
          name={'lotSize'}
          isNumberFormat={true}
          minNumber={0}
          variant={Input.LIGHT_ROUND}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.lotSize}
          label="Lot Size"
          placeholder="0"
        />
      </span>
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
