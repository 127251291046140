import { filter, get, forEach, values, isArray, isObject, groupBy, isEmpty } from 'lodash-es';
import { isFloatStr } from 'helpers';
import { matchPath } from 'react-router';
import moment from 'moment';
import 'moment-timezone';
import { quoteRequestStatus } from 'settings/constants/quotes';

export const fixURL = (urlString) => {
  let url = urlString;

  return getHrefLink(url);
};

export function setUnit(px) {
  return `${px / 16}rem`;
}

export function getImagesPreview(files, binary = false) {
  if (!files) {
    return Promise.reject();
  }

  const promises = [];
  const filesArray = Array.from(files);

  filesArray.forEach((file) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const { result } = e.target;
        resolve({
          url: result,
          fileName: file.name,
          contentType: file.type,
          size: file.size,
        });
      };

      reader.onerror = () => {
        reject();
      };

      if (!binary) {
        reader.readAsDataURL(file);
      }

      if (binary) {
        reader.readAsBinaryString(file);
      }
    });
    promises.push(promise);
  });

  return Promise.all(promises);
}

export const pushOrRemove = ({ arr, id, multiple = false }) =>
  arr.includes(id) ? filter(arr, (activeId) => activeId !== id) : [...(multiple ? arr : []), id];

export const getHrefLink = (link) => {
  const startFromHttps = /^(https:\/\/)|(http:\/\/)/i;
  if (startFromHttps.test(link)) {
    return link;
  }
  return `https://${link}`;
};

export const getGenericRoomsAsItems = ({ data, path, format = null, ...rest }) => {
  const rooms = get(data, path);
  return (
    rooms?.map((item) =>
      item !== undefined
        ? {
            value: format
              ? format(invokeReplaceStringFromMapGlobal(item))
              : invokeReplaceStringFromMapGlobal(item),
            title: formatAddSpace(item.Category ? item.Category : item.RoomType) ?? item.MlsName,
          }
        : {},
    ) || [{}]
  );
};

export const getArrayItemByPath = ({ data, path, format = null, ...rest }) => {
  const item = get(data, path);
  return item !== undefined
    ? [
        {
          value: format
            ? format(invokeReplaceStringFromMapGlobal(item))
            : invokeReplaceStringFromMapGlobal(item),
          ...rest,
        },
      ]
    : [];
};

export const getArrayByPath = ({ data, path, keyMapArray, initialTitle }) => {
  const items = get(data, path);
  if (!isEmpty(items)) {
    const result = items.map((item, index) => {
      return {
        title: `${initialTitle} ${index + 1}`,
        value: keyMapArray.reduce((result, formatObject) => {
          const { key, format, title } = formatObject;
          if (item[key]) {
            result.push(`${title}: ${format ? format(item[key]) : item[key]}`);
          }
          return result;
        }, []),
      };
    });
    return result;
  }
  return [];
};

export const getArrayItemStringByPath = ({ data, path, format = null }) => {
  const item = get(data, path);
  return item !== undefined
    ? [
        format
          ? format(invokeReplaceStringFromMapGlobal(item))
          : invokeReplaceStringFromMapGlobal(item),
      ]
    : [];
};

export const getArrayStringItemsByPaths = ({ data, paths = [], format }) => {
  const result = [];
  forEach(paths, (path) => {
    const val = get(data, path);
    if (val !== undefined) {
      result.push(format ? format(val) : val);
    }
  });

  return result;
};

export const getUtilitiesArrayStringItemsByPaths = ({ data, paths = [], format }) => {
  const result = [];
  forEach(paths, (path) => {
    const val = get(data, path);
    if (val !== undefined) {
      const utility = path.split('.')[1];
      switch (utility) {
        case 'Sewage':
          result.push(format ? `Sewage: ${format(val)}` : `Sewage: ${val}`);
          break;
        case 'Electricity':
          result.push(format ? `Electricity: ${format(val)}` : `Electricity: ${val}`);
          break;
        case 'Water':
          result.push(format ? `Water: ${format(val)}` : `Water: ${val}`);
          break;
        case 'Utilities':
          result.push(format ? `Utilities: ${format(val)}` : `Utilities: ${val}`);
          break;

        default:
          result.push(format ? format(val) : +val);
          break;
      }
    }
  });

  return result;
};

export const getFormattedArrayStringByPath = ({ data, path, format }) => {
  const item = get(data, path);
  return item ? [format(item)] : [];
};

export function cleanSearchRangeObj(obj, propName) {
  if (!Object.keys(obj?.[propName] || {}).length) return { [propName]: undefined };

  return Object.entries(obj?.[propName] || {}).reduce((acc, [key, val]) => {
    if (val || val === 0) {
      return {
        ...acc,
        [propName]: {
          ...acc?.[propName],
          [key]: isFloatStr(val) ? +val : val,
        },
      };
    }
    return {
      ...acc,
      ...(!Object.values(obj[propName] || {}).filter((value) => value || value === 0).length
        ? { [propName]: undefined }
        : {}),
    };
  }, {});
}

export const wrapInArray = (val) => (val ? [val] : val);

export const wrapInArrayWithFormat = (format) => (val) => val !== undefined ? [format(val)] : val;

export const groupByOwnerMessages = (messages) =>
  messages.reduce((acc, item) => {
    if (item?.SenderUserId) {
      const lastItemsIndex = acc.reduce((accIndex, obj, index) => {
        if (obj?.items?.[0].SenderUserId === item?.SenderUserId) {
          return index;
        }
        return -1;
      }, -1);

      if (lastItemsIndex === -1) return [...acc, { items: [item], id: item?.SenderUserId }];

      acc?.[lastItemsIndex]?.items?.push(item);

      return [...acc];
    }

    return [...acc, item];
  }, []);

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export const formatStringList = (val) => {
  if (typeof val === 'string') {
    val = val.replace(/null\s*,?/g, '').trim();
    if (val.includes('_')) {
      return val.split('_')[1];
    }
    return val.split(',').join(', ');
  }
  return val;
};
export const formatStringListWithLineBreak = (val) => {
  if (isArray(val)) {
    val = val[0];
  }
  if (typeof val === 'string') {
    val = val.replace(/null\s*,?/g, '').trim();
    if (val.includes('_')) {
      return val.split('_')[1];
    }
    return val.split(',');
  }
  return val;
};

export const formatAddSpace = (val) => {
  if (typeof val === 'string') {
    let spacedText = val.replace(/([a-zA-Z])(\d)/g, '$1 $2'); // Adds space between letters and numbers
    spacedText = spacedText.replace(/([a-z])([A-Z])/g, '$1 $2'); // Adds space between lowercase and uppercase letters
    return spacedText;
  }
};

export const formatEachArrayValue = (format) => (items) => {
  if (isArray(items)) {
    const filteredSet = new Set(items.filter((val) => val !== null));
    return Array.from(filteredSet).map((val) => format(val));
  }

  return format(items);
};

export const formatArrayToString = (items) =>
  isArray(items)
    ? items.filter((val) => val !== null).join(', ')
    : typeof items === 'string'
    ? items.split(',').join(', ')
    : items;

export const formatRoomInfoString = (input) => {
  const regex = /(\d+)\s+(nd|rd|th)\s+(Bedroom)/g;
  return input.replace(regex, '$1$2 $3');
};

export const formatEachObjectValue = (format) => (value) =>
  isObject(value) ? format(values(value)) : value;

export const getAttachmentType = (attachment) => {
  const [type] = attachment?.ContentType?.split('/') || [];
  return type;
};

export const groupByDate = ({ items, datePropName = 'CreatedDate', format = 'MMM Do, YYYY' }) => {
  if (!items) return [];

  return groupBy(items, (item) => {
    const messageDate = moment(item?.[datePropName]);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    const isToday = () => messageDate.isSame(today, 'd');
    const isYesterday = () => messageDate.isSame(yesterday, 'd');

    if (isToday()) {
      return 'Today';
    }
    if (isYesterday()) {
      return 'Yesterday';
    }
    return moment(item?.[datePropName]).format(format);
  });
};

export const convertTime = (timeInput, tz, keepLocalTime) => {
  if (!timeInput || !tz) return timeInput;
  const momentTime = moment.utc(timeInput, 'DD-MM-YYYY HH:mm').tz(tz, keepLocalTime);

  return momentTime;
};
export const getLocalDateTimeFromString = (timeStampString, includeTimeZone = true) => {
  try {
    const timeFormat = includeTimeZone ? 'h:mm A z' : 'h:mm A';
    const dateTime = moment(timeStampString);
    const browserTimeZone = moment.tz.guess();
    const localDateTime = dateTime.clone().tz(browserTimeZone);
    const localDate = localDateTime.format('YYYY-MM-DD');
    const localTime = localDateTime.format(timeFormat);
    const localDayOfWeek = localDateTime.format('dddd');
    return { localDateTime, localDate, localTime, localDayOfWeek };
  } catch (error) {
    return null;
  }
};

export const createDraftQuoteId = (draftQuoteId) => `${draftQuoteId}-${quoteRequestStatus.Draft}`;
export const extractDraftQuoteId = (draftQuoteId) => {
  if (typeof draftQuoteId === 'string') {
    const [extractedId] = draftQuoteId.split('-');
    return Number(extractedId);
  }
  return draftQuoteId;
};

export function readFiles(files, cb = (reader, file) => reader.readAsBinaryString(file)) {
  if (!files) {
    return Promise.reject();
  }

  const promises = [];
  const filesArray = Array.from(files);

  filesArray.forEach((file) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const { result } = e.target;
        resolve({
          contentType: file.type,
          filename: file.name,
          size: file.size,
          data: result,
          file,
        });
      };

      reader.onerror = () => {
        reject();
      };

      cb(reader, file);
    });
    promises.push(promise);
  });

  return Promise.all(promises);
}

export const truncateFilename = (filename, truncateLength) => {
  if (truncateLength) {
    if (typeof filename === 'string') {
      const truncatedFileName = filename.split('').slice(-truncateLength).join('');
      if (filename?.length > truncateLength) {
        return `...${truncatedFileName}`;
      }
      return truncatedFileName;
    }
    return '';
  }

  return filename;
};

export const getSimpleDate = (date, format = 'MMM D, YYYY') => moment(date).format(format);
export const getAggregatedDate = (date, format = 'M/DD') => moment(date).format(format);

export const getDateForDatePicker = (date, format = 'M d, Y') => moment(date).utc().format(format);

export const getDateOnly = (date, format = 'MMM D, YYYY') =>
  typeof date === 'string' || date instanceof String
    ? moment(date.split('T')[0]).format(format)
    : date
    ? moment(date).format(format)
    : date;

export const isPropertyAddress = (address) => {
  const { City, State, Line1, Zip } = address || {};
  return !!City && !!State && !!Line1 && !!Zip;
};

export const getLink = (link) => {
  if (link.startsWith('http') || link.startsWith('https')) {
    return link;
  }
  return `//${link}`;
};

export const getOptionalValue = (condition, value) => {
  return condition ? value : undefined;
};

export const emptyFunc = () => {};

export const matchComponentConfig = (pathname, config) => matchPath(pathname, config);

export const arrayMove = (items, startIndex, endIndex) => {
  if (endIndex >= items.length) {
    var k = endIndex - items.length + 1;
    while (k--) {
      items.push(undefined);
    }
  }
  items.splice(endIndex, 0, items.splice(startIndex, 1)[0]);

  return items;
};

export const simpleHash = (value) => {
  let hash = 0;
  for (let i = 0, len = value.length; i < len; i++) {
    let chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const daysFromToday = (inputDate) => {
  const currentTime = moment(); //.startOf('day');
  const inputMoment = moment(inputDate); //.startOf('day');
  const daysDifference = currentTime.diff(inputMoment, 'days');

  return Math.abs(daysDifference);
};

export const getDaysOnMarket = (property) => {
  const isRealtrac = property?.Market === 'realtrac';

  let relevantDate = property?.ListingDate;
  if (isRealtrac) {
    relevantDate = property?.OnMarketTimestamp || property?.OriginalEntryTimestamp;
  }

  return relevantDate ? daysFromToday(relevantDate).toString() : null;
};

export const isValidNumericValue = (value) => {
  if (value && !isNaN(Number(value))) {
    const numValue = Number(value);

    return numValue && numValue !== 0 && numValue !== 0.0;
  }

  return false;
};

export const formatDecimal = (value) => {
  const numberValue = parseFloat(value);
  return numberValue.toString();
};

export const globalReplacementMap = {
  RVParking: 'RV Parking',
  RVAccess: 'RV Access',
  Builtin: 'Built-in',
  Walkin: 'Walk-in',
  RVHookup: 'RV Hookup',
  RVBoat: 'RV Boat',
  ManufacturedHomeonRealProperty: 'Manufactured Home On Real Property',
  AFrame: 'A Frame',
  Garageon: 'Garage on',
  Wallto: 'Wall to',
};

export const invokeReplaceStringFromMapGlobal = (arrayOrStr) => {
  return isArray(arrayOrStr)
    ? arrayOrStr.map((val) => replaceStringFromMap(val))
    : replaceStringFromMap(arrayOrStr);
};

export const replaceStringFromMap = (arrayOrStr) => {
  if (typeof arrayOrStr !== 'string') {
    return arrayOrStr;
  }
  return Object.keys(globalReplacementMap)
    .reduce((stri, keyToReplace) => {
      return stri.toString().replace(keyToReplace, globalReplacementMap[keyToReplace]);
    }, arrayOrStr)
    .split(',')
    .join(', ');
};

function extractSrc(iframeString) {
  const regex = /<iframe.*?src="(.*?)"/;
  const match = iframeString.match(regex);
  return match ? match[1] : null;
}

export const convertToEmbededYoutubeUrl = (url) => {
  if (!url) return url;
  if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
    const match = (url?.includes('src=') ? extractSrc(url) : url)
      ?.toString()
      ?.match(/(?:\/|%3D|v=|vi=)([0-9A-Za-z_-]{11})(?:[%#?&]|$)/);
    url = match ? `https://www.youtube.com/embed/${match?.[1]}` : url;
  }
  return ensureHttps(url);
};

export const ensureHttps = (url) => {
  return url.replace(/^http:\/\//, 'https://');
};

export const filterImagesOnly = (files, provider) => {
  const { urls, mediaMap } = files;
  if (urls) {
    // Define a regex pattern for image file extensions
    const imagePattern = /\.(jpg|jpeg|png|gif|bmp)$/i;

    //temporary fix for nysmls, urls will be fixed later on
    if (['NYSMLS', 'GRAR'].includes(provider)) return files;

    // Filter the array, keeping only files that match the image pattern
    return urls.filter(
      (url) =>
        imagePattern.test(url) ||
        (mediaMap[url] &&
          ['jpeg', 'jpg', 'png', 'gif', 'bmp'].includes(mediaMap[url].toLowerCase())),
    );
  } else {
    return [];
  }
};
export const filterImagesOnlyFromPhotoUrls = (files, provider) => {
  if (files) {
    // Define a regex pattern for image file extensions
    const imagePattern = /\.(jpg|jpeg|png|gif|bmp)$/i;

    //temporary fix for nysmls, urls will be fixed later on
    if (['NYSMLS', 'GRAR'].includes(provider)) return files;
    // Filter the array, keeping only files that match the image pattern
    return files.filter((file) => imagePattern.test(file));
  } else {
    return [];
  }
};

export const getFirstImage = (Media) => {
  if (isEmpty(Media)) {
    return null;
  }
  const imagePattern = /\.(jpg|jpeg|png|gif|bmp)$/i;
  const orderedMedia = extractOrderedPhotos(Media, Media.length);
  const firstImage = orderedMedia.find((url) => url && imagePattern.test(url));
  return firstImage;
};

export const extractOrderedPhotos = (Media, photoCount) => {
  if (isEmpty(Media)) {
    return [];
  }
  const initialArray = new Array(photoCount).fill(null);
  const result = Media.reduce((accumulator, currentValue) => {
    let { MediaURL, Order } = currentValue;
    MediaURL = MediaURL ? MediaURL.replace(/#/g, '%23') : null;
    if (!accumulator[Order]) {
      accumulator[Order] = MediaURL;
    }
    return accumulator;
  }, initialArray);
  //first element is sometimes null
  if (result[0] === null) result.shift();
  return result;
};

export const extractOrderedPhotosForFiltering = (Media, photoCount) => {
  if (!Media || Media.length === 0) {
    return { urls: [], mediaMap: {} };
  }

  const initialArray = new Array(photoCount).fill(null);
  const result = Media.reduce(
    (accumulator, currentValue) => {
      let { MediaURL, Order, MediaType } = currentValue;
      MediaURL = MediaURL ? MediaURL.replace(/#/g, '%23') : null;

      if (!accumulator.urls[Order]) {
        accumulator.urls[Order] = MediaURL;
      }

      accumulator.mediaMap[MediaURL] = MediaType;

      return accumulator;
    },
    { urls: initialArray, mediaMap: {} },
  );
  result.urls = result.urls.filter(Boolean);

  return result;
};

const isImageFile = (url, mediaType) => {
  const imagePattern = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
  return (
    imagePattern.test(url) ||
    (mediaType && ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'].includes(mediaType.toLowerCase()))
  );
};

export const processMediaToOrderedImages = (Media, provider) => {
  // Early return for empty input or NYSMLS
  if (!Media?.length) return [];

  // Create array of image URLs
  const imageUrls = [];

  Media.forEach(({ MediaURL, MediaType }) => {
    // Skip non-image files
    if (!isImageFile(MediaURL, MediaType) && !['GRAR', 'FMLS'].includes(provider)) return;

    // Process URL
    const processedUrl = MediaURL ? MediaURL.replace(/#/g, '%23') : null;
    if (processedUrl) {
      imageUrls.push(processedUrl);
    }
  });

  return imageUrls;
};

export const sortObjectByDateKeys = (obj) => {
  const sortedKeys = Object.keys(obj).sort((a, b) => new Date(a) - new Date(b));

  const sortedObj = {};
  sortedKeys.forEach((key) => {
    sortedObj[key] = obj[key];
  });

  return sortedObj;
};
// Utility function to format the total number of bathrooms
export const getBathsTotalNum = (value) => {
  try {
    if (!value || value === 0) return null;

    // Round the value to the nearest hundredth
    let roundedValue = Math.round(value * 100) / 100;

    // If rounded value is less than 1, show as 1
    if (roundedValue < 1) return '1';

    // Define the rounding thresholds
    const thresholds = [0, 0.25, 0.5, 0.75];

    // Calculate the integer part and the fractional part
    const integerPart = Math.floor(roundedValue);
    let decimalPart = roundedValue - integerPart;
    decimalPart = Math.round(decimalPart * 100) / 100;

    // Find the closest threshold
    const closestThreshold = thresholds.reduce((prev, curr) =>
      Math.abs(curr - decimalPart) < Math.abs(prev - decimalPart) ? curr : prev,
    );
    if (thresholds.includes(decimalPart)) {
      let finalValue =
        closestThreshold === 0 && decimalPart > 0.75
          ? integerPart + 1
          : integerPart + closestThreshold;

      // Handle the case where the value ends in .50
      if (closestThreshold === 0.5) {
        finalValue = `${integerPart + 0.5}`;
      }
      if (closestThreshold === 0.75) {
        finalValue = `${integerPart + 0.75}`;
      }
      // Round finalValue to a precision of 1 decimal place

      return finalValue.toString();
    } else {
      roundedValue = Math.round(roundedValue * 10) / 10;
      return roundedValue.toString();
    }
  } catch (error) {
    console.error('Error processing bathroom total value:', error);
    return error;
  }
};
export const getTaxMapKey = (parcelNumber) => {
  try {
    // Ensure parcelNumber is a string
    const parcelString = parcelNumber.toString();

    // Extract the parts of the parcel number
    const part1 = parcelString[0]; // First digit
    const part2 = parcelString[1]; // Second digit
    const part3 = parcelString[2]; // Third digit
    const part4 = parseInt(parcelString.slice(3, 6), 10); // Next 3 digits, remove leading zeros
    const part5 = parseInt(parcelString.slice(6, 9), 10); // Next 3 digits, remove leading zeros

    // Construct the Tax Map Key
    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  } catch (error) {
    console.error('Error parsing Tax Map Key:', error);
    return parcelNumber; // Return unparced data if parsing fails
  }
};

export const getMilestoneById = (data, id) => {
  if (!data) return null;
  return data.find((milestone) => milestone?.Id === id);
};

export const combineMultipleObjects = (...objects) => {
  return objects.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      acc[key] = (acc[key] || []).concat(obj[key] || []);
    });
    return acc;
  }, {});
};

export const splitName = (label) => {
  if (typeof label !== 'string' || !label) {
    return { FirstName: '', LastName: '' }; // Handle cases where label is not a string
  }

  const [FirstName, ...lastNameParts] = label.split(' ');
  const LastName = lastNameParts.join(' ') || ''; // Join the rest to form the last name
  return { FirstName, LastName };
};
