import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getTestClock,
  createTestClock,
  resetTestClock,
  fastForwardTestClock,
} from 'store/effects/radarKits';
import { showErrorMessage } from 'helpers';

export const useCampaignKitTestClock = (clientId: string, campaignId: string) => {
  const dispatch = useDispatch();
  const [testClock, setTestClock] = useState<any>(null);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isFastForwarding, setIsFastForwarding] = useState<boolean>(false);
  const [retry, setShouldRetry] = useState<boolean>(false);

  const fetchTestClock = useCallback(() => {
    setIsFetching(true);
    return dispatch(
      getTestClock({ campaignId: campaignId, memberId: clientId }, { silent: true }, (err, res) => {
        setIsFetching(false);
        if (err) {
          showErrorMessage('Failed to fetch test clock.');
          return;
        }
        setTestClock(res.data || null);
      }),
    );
  }, [clientId, campaignId, dispatch]);

  const handleCreateTestClock = useCallback(() => {
    setIsCreating(true);
    dispatch(
      createTestClock(
        { campaignId: campaignId, memberId: clientId },
        { silent: false },
        (err, res) => {
          setIsCreating(false);
          if (err) {
            showErrorMessage('Failed to create test clock.');
            return;
          }
          setTestClock(res.data);
        },
      ),
    );
  }, [clientId, campaignId, dispatch]);

  const handleResetTestClock = useCallback(() => {
    setIsResetting(true);
    setShouldRetry(false);
    dispatch(
      resetTestClock({ campaignId: campaignId, memberId: clientId }, { silent: false }, (err) => {
        setIsResetting(false);
        if (err) {
          showErrorMessage('Failed to reset test clock.');
          return;
        }
        setTestClock(null);
        window.location.reload();
      }),
    );
  }, [clientId, campaignId, dispatch]);

  const handleFastForwardTestClock = useCallback(
    (tillDate) => {
      setIsFastForwarding(true);
      setShouldRetry(false);
      dispatch(
        fastForwardTestClock(
          { campaignId: campaignId, memberId: clientId, tillDate },
          { silent: false },
          (err) => {
            if (err) {
              setIsFastForwarding(false);
              showErrorMessage('Failed to fast forward test clock.');
              return;
            }
            void pollTestClock();
          },
        ),
      );
    },
    [clientId, campaignId, dispatch, fetchTestClock],
  );

  const pollTestClock = useCallback(() => {
    let retries = 0;
    const maxRetries = 20;

    const intervalId = setInterval(async () => {
      const res: any = await fetchTestClock();
      const testClockResponse = res?.data;
      if (testClockResponse && testClockResponse?.Status === 'Stopped') {
        setIsFastForwarding(false);
        clearInterval(intervalId);
        window.location.reload();
      } else {
        retries++;
        if (retries >= maxRetries) {
          setIsFastForwarding(false);
          setShouldRetry(true);
          clearInterval(intervalId);
          //Max retries reached. Stopping polling.
        }
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [fetchTestClock]);

  useEffect(() => {
    async function initTestClock() {
      const testClock: any = await fetchTestClock();
      if (testClock?.data?.Status === 'Active') {
        void pollTestClock();
      }
    }
    void initTestClock();
  }, [fetchTestClock]);

  return {
    pollTestClock,
    testClock,
    isFetching,
    isCreating,
    isResetting,
    isFastForwarding: isFastForwarding || (testClock?.Status === 'Active' && !retry),
    handleCreateTestClock,
    handleResetTestClock,
    handleFastForwardTestClock,
  };
};
