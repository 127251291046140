import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uniqBy } from 'lodash-es';
import { InputLabel, Select, Autocomplete } from 'components';
import { getTaskAggregateTransactionsSelector } from 'store/selectors/transactions';
import { getProjectsForTaskCreationSelector } from 'store/selectors/projects';
import { getTransactionEffect } from 'store/effects/transactions';
import { TaskEditableFormValuesType } from 'types/transactionTasks';

import styles from './styles.module.scss';

interface TransactionProps {
  values: TaskEditableFormValuesType;
  setValues: (value) => void;
}

export const Transaction = ({ values, setValues }: TransactionProps) => {
  const [transactionId, setTransactionId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const transactions = useSelector(getTaskAggregateTransactionsSelector);
  const projects = useSelector(getProjectsForTaskCreationSelector);
  const transactionsAndProjects = uniqBy([...transactions, ...projects], 'Id');
  const dispatch = useDispatch();

  const getOptions = () => {
    return transactionsAndProjects?.map((t: any) => ({ name: t.label, value: t.Id }));
  };

  const onTransactionOrProjectChange = ({ target: { value: val } }) => {
    setTransactionId(val.value);
    setSearchTerm('');

    const isProject = projects.find(({ Id }: any) => Id === val.value);

    dispatch(
      // This will fetch the projects as well since on backend its
      // the same db entry with an additional boolean flag i.e. isProject
      getTransactionEffect({ id: val.value }, null, (err) => {
        if (!err) setValues({ ...values, AssigneeList: [], CcList: [], isProject });
      }),
    );
  };

  const getValue = () => {
    const item: any = transactionsAndProjects?.find((t: any) => transactionId === t.Id);
    return {
      name: item?.label || '',
      value: item?.Id || '',
    };
  };

  const options = useMemo(() => {
    const searchedOptions = getOptions();

    if (searchTerm.trim().length > 0) {
      const filtered = searchedOptions.filter(({ name }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase()),
      );

      return filtered.length ? filtered : [];
    }

    return searchedOptions;
  }, [searchTerm]);

  return (
    <div className={styles.transactions}>
      <div className={styles.fieldWrapper}>
        <InputLabel label="Transaction / Project" className={styles.label} showAsterisk />
        <Select
          search
          className={{
            wrapper: styles.search,
            value: styles.value,
          }}
          variant={Select.LIGHT_ROUND}
          options={options}
          placeholder="Select Transaction or Project"
          onSelect={onTransactionOrProjectChange}
          value={getValue()}
          searchIconClassName={styles.searchIcon}
          emptyMessage={() => null}
          isArrowIcon
          onSearchInputChange={({ target }) => setSearchTerm(target.value)}
        />
      </div>
    </div>
  );
};
