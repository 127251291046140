import { useEffect, useState } from 'react';
import { Avatar, Modal } from 'components';

import { useSelector, useDispatch } from 'react-redux';
import { Icons } from 'pages/Properties/common/Icons';

import {
  getClientsEffect,
  openShareDrawerEffect,
  setSharePropertyEffect,
  shareDrawerSearchClientsEffect,
  sendSharePropsToRecipientsEffect,
  setRecipientsEffect,
  getHighlightsEffect,
} from 'store/effects';
import { Input, Wrapper as PendingWrapper, DrawerComparePhotos } from 'components';

import { IDLE, PENDING } from 'settings/constants/apiState';

import { getShareDrawerInfoSelector } from './selectors';

import styles from './styles.module.scss';
import { isArray } from 'lodash-es';
import { getInitials } from 'utils';
import classNames from 'classnames';
import moment from 'moment';

const Collapsable = ({ title, count, children }) => {
  const [collapsed, setIsCollapsed] = useState(false);
  return (
    <>
      <p className={styles.collapseTitle} onClick={() => setIsCollapsed((prev) => !prev)}>
        {title} <span>({count})</span>
      </p>
      {!collapsed && children}
    </>
  );
};

const ClientItem = ({ name = '', time, avatarUrl, isChecked = false, id, onSendShares }) => {
  const dispatch = useDispatch();
  const { recipients } = useSelector(getShareDrawerInfoSelector);

  const onCheckItem = () => {
    let newRecipients;
    if (isChecked) {
      newRecipients = recipients.filter((recipient) => recipient.Id !== id);
    } else {
      newRecipients = [...recipients, { Id: id, Name: name, CreatedDate: new Date() }];
    }
    dispatch(setRecipientsEffect(newRecipients));
    onSendShares(newRecipients);
  };

  return (
    <div className={styles.clientItemWrapper} onClick={onCheckItem}>
      <div className={styles.containerItem}>
        <Avatar avatarClassName={styles.avatar} placeholder={getInitials(name)} src={avatarUrl} />
        <div className={styles.textInfo}>
          <span className={styles.name}>{name}</span>
          {time && (
            <span className={styles.time}>Highlighted: {moment(time).format('M/D/YYYY')}</span>
          )}
        </div>
      </div>
      <Icons
        variant={Icons.STAR_2}
        className={classNames(styles.buttonIcon, {
          [styles.recommended]: isChecked,
        })}
      />
    </div>
  );
};

const HighlightModal = () => {
  const dispatch = useDispatch();
  const { drawerOpened, clients, searchValue, recipients, compares, shareProperty } = useSelector(
    getShareDrawerInfoSelector,
  );

  useEffect(() => {
    if (drawerOpened) {
      dispatch(getClientsEffect());
      dispatch(getHighlightsEffect());
    } else {
      dispatch(setSharePropertyEffect(null));
    }
  }, [drawerOpened]);

  const onSendShares = (recipients) => {
    if (shareProperty) {
      const cfg = { recipients, shares: isArray(shareProperty) ? shareProperty : [shareProperty] };
      return dispatch(
        sendSharePropsToRecipientsEffect(cfg, {
          clients,
        }),
      );
    }

    const cfg = { recipients, shares: compares?.data || [] };

    return dispatch(sendSharePropsToRecipientsEffect(cfg, {}));
  };

  const isPending = clients.state === PENDING;

  return (
    <Modal
      isOpen={drawerOpened}
      onClose={() => {
        dispatch(openShareDrawerEffect({ open: false }));
        dispatch(shareDrawerSearchClientsEffect({ search: '' }));
      }}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      headerClassName={styles.header}
      label={'Highlight'}
      className={styles.searchModalWrapper}
    >
      <div className={styles.drawerInner}>
        <div>
          <div className={styles.photoSection}>
            <DrawerComparePhotos
              compares={
                shareProperty ? (isArray(shareProperty) ? shareProperty : [shareProperty]) : []
              }
            />
          </div>
          <div className={styles.inputSection}>
            <Input
              className={styles.searchInput}
              placeholder="Search clients..."
              value={searchValue}
              onChange={(_, val) => dispatch(shareDrawerSearchClientsEffect({ search: val }))}
              type="search"
              variant={Input.LIGHT_ROUNDED}
            />
          </div>
          <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
            {[
              {
                title: 'Highlighted To',
                data: (clients?.data || [])?.filter(({ Id }) =>
                  recipients.some(({ Id: recipientId }) => recipientId === Id),
                ),
              },
              {
                title: 'All Clients',
                data: (clients?.data || [])?.filter(
                  ({ Id }) => !recipients.some(({ Id: recipientId }) => recipientId === Id),
                ),
              },
            ].map(({ title, data }) =>
              data?.length ? (
                <Collapsable title={title} count={data?.length}>
                  {data?.map((client, idx) => {
                    const { FirstName, LastName, AvatarUrl, Id } = client;
                    const index = recipients.findIndex(({ Id: recipientId }) => recipientId === Id);
                    const isChecked = index !== -1;
                    return (
                      <ClientItem
                        key={idx}
                        name={`${FirstName} ${LastName}`}
                        time={recipients?.[index]?.CreatedDate}
                        avatarUrl={AvatarUrl}
                        id={Id}
                        isChecked={isChecked}
                        onSendShares={onSendShares}
                      />
                    );
                  })}
                </Collapsable>
              ) : null,
            )}
          </PendingWrapper>
        </div>
      </div>
    </Modal>
  );
};

export default HighlightModal;
