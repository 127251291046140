import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCriteriaAction, setFilterAction } from 'store/actions/feedv3';
import { getSavedSearchPropertiesV3Effect } from 'store/effects/feedv3';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSubFilterTypeSelector } from 'store/selectors/feed';
import { getFeedSortSelector } from 'store/selectors/feed';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { LocalStorage } from 'services';
import { omitBy } from 'lodash-es';

export const useSearchInstanceV3Effect = () => {
  const dispatch = useDispatch();
  const sort = useSelector(getFeedSortSelector);
  const searchContext = useSelector(getCurrentContextSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);

  const DEFAULT_PAGE_SIZE = 350;

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        (abortControllerRef.current as AbortController).abort();
      }
    };
  }, []);

  const getSearchInstanceV3 = (item, cb?: any, subFilter?: string, isDraw = false) => {
    // Abort previous request if it exists
    if (abortControllerRef.current) {
      (abortControllerRef.current as AbortController).abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const cleanedCriteria = omitBy(cleanSearchQueryObj(item?.criterias), (value) => !value);

    const cfg = {
      queryParams: {
        sortField: sort?.fields?.[0] ?? 'MatchScore',
        sortOrder: sort?.order,
        first: DEFAULT_PAGE_SIZE,
        contextId: searchContext?.ContextKey,
        loadTotalCount: true,
        subFilterType: subFilter || subFilterType,
      },
      body: {
        searchQuery: {
          ...cleanedCriteria,
        },
      },
    };

    if (!isDraw) {
      dispatch(setCriteriaAction({ criteria: cleanedCriteria }));
      LocalStorage.setSearchCriteria(cleanedCriteria);
    }

    // Pass the signal to the options parameter
    const options = { isDraw, signal: abortController.signal };

    dispatch(
      getSavedSearchPropertiesV3Effect(cfg, options, (err, resp) => {
        // Remove the controller reference after the request completes
        abortControllerRef.current = null;
        if (!isDraw) dispatch(setFilterAction({ filter: {} }));
        if (cb) cb(err, resp);
      }),
    );
  };

  return getSearchInstanceV3;
};
