import PropTypes from 'prop-types';

export const Icons = ({ testid = '', className = '', variant, onClick = () => {} }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.DUE_DATE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.08" cx="16" cy="16" r="16" fill="#4673D1" />
            <path
              d="M21.25 10H10.75C9.7835 10 9 10.7835 9 11.75V22.25C9 23.2165 9.7835 24 10.75 24H21.25C22.2165 24 23 23.2165 23 22.25V11.75C23 10.7835 22.2165 10 21.25 10Z"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.5 8.5V11.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 8.5V11.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 15H23"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.DESCRIPTION:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="16" cy="16" r="16" fill="#FB913A" />
            <path d="M10 12H22" stroke="#FB913A" strokeWidth="2" strokeLinecap="round" />
            <path d="M10 16H22" stroke="#FB913A" strokeWidth="2" strokeLinecap="round" />
            <path d="M10 20H17" stroke="#FB913A" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.LINKS:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="16" cy="16" r="16" fill="#51BFE1" />
            <path
              d="M12.4651 15.2931C13.5981 14.1601 15.5741 14.1601 16.7071 15.2931L17.4141 16.0001L18.8281 14.5861L18.1211 13.8791C17.1781 12.9351 15.9221 12.4141 14.5861 12.4141C13.2501 12.4141 11.9941 12.9351 11.0511 13.8791L8.92905 16.0001C7.99384 16.939 7.46875 18.2103 7.46875 19.5356C7.46875 20.8608 7.99384 22.1321 8.92905 23.0711C9.39283 23.5357 9.9439 23.9041 10.5506 24.155C11.1572 24.4059 11.8075 24.5344 12.4641 24.5331C13.1207 24.5345 13.7712 24.4061 14.3781 24.1552C14.9849 23.9043 15.5362 23.5359 16.0001 23.0711L16.7071 22.3641L15.2931 20.9501L14.5861 21.6571C14.0225 22.2181 13.2597 22.533 12.4646 22.533C11.6694 22.533 10.9066 22.2181 10.3431 21.6571C9.78156 21.0938 9.46626 20.3309 9.46626 19.5356C9.46626 18.7402 9.78156 17.9773 10.3431 17.4141L12.4651 15.2931Z"
              fill="#51BFE1"
            />
            <path
              d="M15.9999 8.9307L15.2929 9.6377L16.7069 11.0517L17.4139 10.3447C17.9774 9.78369 18.7402 9.46873 19.5354 9.46873C20.3305 9.46873 21.0933 9.78369 21.6569 10.3447C22.2184 10.908 22.5337 11.6709 22.5337 12.4662C22.5337 13.2615 22.2184 14.0244 21.6569 14.5877L19.5349 16.7087C18.4019 17.8417 16.4259 17.8417 15.2929 16.7087L14.5859 16.0017L13.1719 17.4157L13.8789 18.1227C14.8219 19.0667 16.0779 19.5877 17.4139 19.5877C18.7499 19.5877 20.0059 19.0667 20.9489 18.1227L23.0709 16.0017C24.0061 15.0627 24.5312 13.7915 24.5312 12.4662C24.5312 11.1409 24.0061 9.86968 23.0709 8.9307C22.1324 7.99451 20.861 7.46875 19.5354 7.46875C18.2098 7.46875 16.9383 7.99451 15.9999 8.9307Z"
              fill="#51BFE1"
            />
          </svg>
        );
      case Icons.EMAIL_TEMPLATE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="16" cy="16" r="16" fill="#04A451" />
            <rect
              x="8"
              y="10"
              width="16"
              height="12"
              rx="2"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M8 11L15.4 16.55C15.7556 16.8167 16.2444 16.8167 16.6 16.55L24 11"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.TAGS:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="16" cy="16" r="16" fill="#928CDA" />
            <path d="M14.5 9L11 23" stroke="#928CDA" strokeWidth="2" strokeLinecap="round" />
            <path d="M21 9L17.5 23" stroke="#928CDA" strokeWidth="2" strokeLinecap="round" />
            <path d="M9.5 13H23.5" stroke="#928CDA" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.5 19H22.5" stroke="#928CDA" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.FILES:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="16" cy="16" r="16" fill="#4673D1" />
            <path
              d="M17.8008 7.90039V11.5004C17.8008 11.7391 17.8956 11.968 18.0644 12.1368C18.2332 12.3056 18.4621 12.4004 18.7008 12.4004H22.3008"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.4992 24.1004H11.4992C11.0218 24.1004 10.564 23.9107 10.2264 23.5732C9.88886 23.2356 9.69922 22.7778 9.69922 22.3004V9.70039C9.69922 9.223 9.88886 8.76516 10.2264 8.4276C10.564 8.09003 11.0218 7.90039 11.4992 7.90039H17.7992L22.2992 12.4004V22.3004C22.2992 22.7778 22.1096 23.2356 21.772 23.5732C21.4344 23.9107 20.9766 24.1004 20.4992 24.1004Z"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M13.5 15.5H18.5" stroke="#4673D1" strokeWidth="2" strokeLinecap="round" />
            <path d="M13.5 19.5H16.5" stroke="#4673D1" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.CHECKLIST:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#FF576D" />
            <g clipPath="url(#clip0_66439_31510)">
              <path
                d="M13.4993 10.166H11.8327C11.3907 10.166 10.9667 10.3416 10.6542 10.6542C10.3416 10.9667 10.166 11.3907 10.166 11.8327V21.8327C10.166 22.2747 10.3416 22.6986 10.6542 23.0112C10.9667 23.3238 11.3907 23.4993 11.8327 23.4993H20.166C20.608 23.4993 21.032 23.3238 21.3445 23.0112C21.6571 22.6986 21.8327 22.2747 21.8327 21.8327V11.8327C21.8327 11.3907 21.6571 10.9667 21.3445 10.6542C21.032 10.3416 20.608 10.166 20.166 10.166H18.4993"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8333 8.5H15.1667C14.2462 8.5 13.5 9.24619 13.5 10.1667C13.5 11.0871 14.2462 11.8333 15.1667 11.8333H16.8333C17.7538 11.8333 18.5 11.0871 18.5 10.1667C18.5 9.24619 17.7538 8.5 16.8333 8.5Z"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5 17.6667L15.1667 19.3333L18.5 16"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_66439_31510">
                <rect width="20" height="20" fill="white" transform="translate(6 6)" />
              </clipPath>
            </defs>
          </svg>
        );
      case Icons.TASK:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="16" cy="16" r="16" fill="#4673D1" />
            <path
              d="M8.91602 10.5833L10.166 11.8333L12.2493 9.75"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.91602 15.5833L10.166 16.8333L12.2493 14.75"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.91602 20.5833L10.166 21.8333L12.2493 19.75"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.166 11H22.666"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.166 16H22.666"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.166 21H22.666"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.FORM:
        return (
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 15V16.525C5.5 17.4087 6.21634 18.125 7.1 18.125H17.025C17.9087 18.125 18.625 17.4087 18.625 16.525V7.17559C18.625 6.98181 18.5547 6.79462 18.4271 6.64878L14.489 2.1482C14.3371 1.97458 14.1177 1.875 13.887 1.875H7.1C6.21634 1.875 5.5 2.59134 5.5 3.475V6.25"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.625 1.875V5.275C13.625 6.15866 14.3413 6.875 15.225 6.875H18"
              stroke="#FB913A"
              strokeWidth="2"
            />
            <path
              d="M9.875 7.5L13 10.625L9.875 13.75"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.75 10.625L3.625 10.625"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.SIGNIN:
        return (
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.332 14.3311H14.2195L21.8028 6.74721C22.1857 6.36431 22.4009 5.84498 22.4009 5.30347C22.4009 4.76196 22.1857 4.24264 21.8028 3.85973C21.4199 3.47683 20.9006 3.26172 20.3591 3.26172C19.8176 3.26172 19.2983 3.47683 18.9154 3.85973L11.332 11.4436V14.3311Z"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.748 5.58203L20.0814 7.91536"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.83398 14.332H6.08398C5.11749 14.332 4.33398 15.1155 4.33398 16.082V16.082C4.33398 17.0485 5.11524 17.832 6.08174 17.832C7.65748 17.832 10.0129 17.832 11.9186 17.832C12.8851 17.832 13.6673 18.6155 13.6673 19.582V19.582C13.6673 20.5485 12.8838 21.332 11.9173 21.332H7.83398"
              stroke="#04A451"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className} style={{ display: 'flex' }}>
      {renderIcon()}
    </div>
  );
};

Icons.DUE_DATE = 'dueDate';
Icons.DESCRIPTION = 'description';
Icons.LINKS = 'links';
Icons.EMAIL_TEMPLATE = 'email_template';
Icons.TAGS = 'tags';
Icons.FILES = 'files';
Icons.CHECKLIST = 'checklist';
Icons.TASK = 'task';
Icons.FORM = 'form';
Icons.SIGNIN = 'signin';
