import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Icons } from 'pages/Properties/common/Icons';
import { Wrapper as PendingWrapper } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getMlsScoreByIdWithStatus } from 'store/selectors/mlsScores';
import { requestGetMatchedClientsWithScoresByMlsEffect } from 'store/effects/mlsScores';
import { chunk } from 'lodash';
import { getHintColor } from 'components/Match';

const MatchFav: React.FC<{
  propertyInfo: {
    [z: string]: any;
  };
}> = ({ propertyInfo }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { isData, buyers } =
    useSelector(
      getMlsScoreByIdWithStatus(propertyInfo?.Id ? propertyInfo?.Id : propertyInfo?.id),
    ) || {};

  const hintProps =
    (propertyInfo?.AddedToFavoritesBy?.length || buyers?.length) && isAgent && !isLoading
      ? { 'data-rh': true }
      : {};
  const matchScoreHintProps =
    !!propertyInfo?.MatchedCriterias?.length && isAgent ? { 'data-rh': true } : {};

  const onGetData = () => {
    if (!isData) {
      setIsLoading(true);
      dispatch(
        requestGetMatchedClientsWithScoresByMlsEffect(
          { ids: [propertyInfo?.Id ? propertyInfo?.Id : propertyInfo?.id] },
          {},
          () => {
            setIsLoading(false);
          },
        ),
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      {propertyInfo.MatchScore === null || propertyInfo.MatchScore === undefined ? null : (
        <div
          {...matchScoreHintProps}
          data-match={JSON.stringify({
            matchedCriterias: chunk(propertyInfo?.MatchedCriterias, 2) || [],
            matchScore: propertyInfo?.MatchScore || 0,
          })}
        >
          <div
            className={classNames(
              styles.agentMatchScore,
              styles[getHintColor(Number(propertyInfo?.MatchScore))],
            )}
          >
            {propertyInfo.MatchScore}%
          </div>
        </div>
      )}
      <div
        {...hintProps}
        data-matchwithbuyers={JSON.stringify({
          buyers,
          addedToFavoritesBy: propertyInfo?.AddedToFavoritesBy || [],
        })}
        onMouseEnter={onGetData}
        className={classNames(styles.loadingWrapper)}
        onClick={(e) => e.stopPropagation()}
      >
        <PendingWrapper isPending={isLoading} loaderClassName={styles.loader}>
          <div className={styles.favMatch}>
            <Icons variant={Icons.MATCH_FAV} className={styles.icon} />
            <span>
              {(propertyInfo?.AddedToFavoritesBy?.length || 0) +
                (propertyInfo?.MatchedClientsCount || 0)}
            </span>
          </div>
        </PendingWrapper>
      </div>
    </div>
  );
};

export default MatchFav;
