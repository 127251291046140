import React from 'react';
import styles from './styles.module.scss';
import { Wrapper } from 'components';
import classNames from 'classnames';
import { htmlToPlainText } from 'pages/Radar/RadarKits/utils';

type Props = {
  details: string;
  videoURL?: string;
  onBack: () => void;
  onClose: () => void;
};

export const PreviewDetails: React.FC<Props> = ({ details, videoURL, onBack, onClose }) => {
  const compactDetails = htmlToPlainText(details);
  return (
    <Wrapper className={classNames(styles.contentWrapper, styles.details)} isPending={false}>
      <div className={styles.flexContainer}>
        {videoURL && (
          <div className={classNames(styles.videoContent, { [styles.start]: !!compactDetails })}>
            <iframe
              className={classNames(styles.videoIframe, { [styles.full]: !compactDetails })}
              src={videoURL}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
        {compactDetails && (
          <div
            className={styles.detailsContent}
            dangerouslySetInnerHTML={{ __html: details }}
          ></div>
        )}
      </div>
    </Wrapper>
  );
};
